import { gql } from 'graphql-request'

export const GET_DETAILS_FOR_LESSON = gql`
  query getTrailsDetails($idProjectTrack: Int!, $idClass: Int!) {
    scheduledLessonMomentByClassAndTrackId(idClass: $idClass, idProjectTrack: $idProjectTrack) {
      items {
        idMoment
        coMomentStatus
        txMomentStatus
        dtProcessed
        dtSchedule
        txTitle
        coGrade
        nuOrder
        dtSchedule
      }
    }
  }
`
