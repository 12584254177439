import React, { useState } from 'react'

import { UilArrowRight } from '@iconscout/react-unicons'
import { Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { IGetTrailListResponse, ProjectTrailInformationResponse, TrailList } from '~/services/Trails/types'

import {
  AvatarComponent,
  AvatarGroupComponent,
  ButtonComponent,
  ChipComponent,
  TooltipStatusComponent,
} from '~/components'

import { getFormattedLabelWithDay } from '~/validations'
import { statusCheck } from '~/validations/statusCheck'
import { statusIcon } from '~/validations/statusIcon'

import theme from '~/styles/theme'

import * as S from './TrailCalendar.styles'

interface ITrailCalendar {
  trails: IGetTrailListResponse | ProjectTrailInformationResponse
  actionManagementSchedule?: (trail: any) => void
  currentPeriod?: number
  period?: string
}

const TrailTooltip = ({
  trail,
  children,
  actionManagementSchedule,
}: {
  trail: TrailList
  children: React.ReactElement
  actionManagementSchedule?: (trail: any) => void
}) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const userInfo = JSON.parse(localStorage?.getItem('@LEKTO:user') as string)

  function validateProfessors(moments: TrailList, loggedInUserId: number) {
    let containsEmptyProfessor = false
    const allOccupiedByLoggedInUser = true
    const professor = moments?.professor
    const coStage = moments?.coStage
    const coStageCheck = coStage === 'F1' || coStage === 'F2'

    if (moments?.moment) {
      for (const moment of moments.moment) {
        if (!moment.professor) {
          containsEmptyProfessor = true
        } else if (moment.professor.idUser === loggedInUserId) {
          return true
        }
      }

      if (coStageCheck && professor) {
        for (const professorData of professor) {
          if (professorData.idUser === loggedInUserId) {
            return true
          }
          if (professorData.idUser !== loggedInUserId) {
            return false
          }
        }
      }

      if (professor) {
        for (const professorData of professor) {
          if (professorData.idUser === loggedInUserId) {
            return true
          } else if (professorData.idUser !== loggedInUserId && !containsEmptyProfessor && !coStageCheck) {
            return false
          } else if (professorData.idUser !== loggedInUserId && containsEmptyProfessor && !coStageCheck) {
            return true
          }
        }
      }
    }

    if (!allOccupiedByLoggedInUser && containsEmptyProfessor) {
      return true
    }

    if (containsEmptyProfessor) {
      return true
    }

    if (allOccupiedByLoggedInUser) {
      return true
    }

    return false
  }

  const TrailTutorList = trail.professor
  const allWithProfessor = validateProfessors(trail ? trail : ([] as unknown as TrailList), userInfo?.id_user)
  const renderTrail = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <S.InfoText>Informações da Trilha</S.InfoText>

        <S.TrailTitle>{trail.txTitle}</S.TrailTitle>
        <S.ConteinerProfessor>
          <AvatarGroupComponent images={TrailTutorList} size="xsmall" max={4} />
          <S.NameProfessor>
            {TrailTutorList.length
              ? `${TrailTutorList[0].txName} ${TrailTutorList.length > 1 ? '+' : ''}${
                  TrailTutorList.length > 1 ? TrailTutorList.length - 1 : ''
                }`
              : TrailTutorList[0].txName}
          </S.NameProfessor>
        </S.ConteinerProfessor>
        <S.OrderScheduleBox>
          {trail.moment?.map((moment, index: number) => (
            <TooltipStatusComponent key={moment?.idMoment} status={moment?.coMomentStatus}>
              <S.ContainerOrderSchedule>
                <S.ContentOrderSchedule key={moment?.idMoment}>
                  <S.TextOrderSchedule>{index + 1}</S.TextOrderSchedule>
                </S.ContentOrderSchedule>
                <AvatarComponent
                  size={'small'}
                  photoUrl={moment.professor ? moment?.professor?.txImagePath : trail.professor[0].txImagePath}
                />
                <ChipComponent
                  fullWidth
                  icon={statusIcon(moment?.coMomentStatus)}
                  type={statusCheck(moment?.coMomentStatus)}
                  label={getFormattedLabelWithDay(moment?.dtSchedule)}
                />
              </S.ContainerOrderSchedule>
            </TooltipStatusComponent>
          ))}
          {actionManagementSchedule && (
            <ButtonComponent
              onClick={() => {
                actionManagementSchedule(trail)
                setTooltipOpen(false)
              }}
              size="small"
              variant={'outline'}
              color={!allWithProfessor ? 'disabled' : undefined}
              disabled={!allWithProfessor}
              fontWeight="normal"
              text="Gerenciar datas"
              iconEnd={<UilArrowRight size={16} color={!allWithProfessor ? 'gray' : theme.colors.actionPure} />}
            />
          )}
        </S.OrderScheduleBox>
      </Box>
    )
  }
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  return (
    <S.TooltipContainerHover>
      {isMobile ? (
        <S.TooltipContainer>
          <Tooltip
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            title={renderTrail()}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '12px',
                  padding: '16px',
                  fontWeight: 'bold',
                  backgroundColor: theme.colors.midLight,
                  borderRadius: '8px',
                  boxShadow: theme.shadows.level3,
                },
              },
            }}
            placement={'bottom-start'}
            open={isTooltipOpen}
          >
            {children}
          </Tooltip>
        </S.TooltipContainer>
      ) : (
        <S.TooltipContainer>
          <Tooltip
            title={renderTrail()}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '12px',
                  padding: '16px',
                  fontWeight: 'bold',
                  backgroundColor: theme.colors.midLight,
                  boxShadow: theme.shadows.level3,
                },
              },
            }}
            placement={'right'}
          >
            {children}
          </Tooltip>
        </S.TooltipContainer>
      )}
    </S.TooltipContainerHover>
  )
}

const TrailCalendarGanttChartYear = ({ trails, actionManagementSchedule }: ITrailCalendar) => {
  const barWidth = 75

  return (
    <S.GanttContainer className="gantt-chart-container">
      {trails.map((item) => {
        const startDate = dayjs(item.moment[0].dtSchedule)
        const startMonth = startDate.month()

        const leftPosition = startMonth * barWidth + barWidth / 2 - barWidth / 2

        return (
          <S.GanttRow key={item.idTrack} className={'gantt-row'}>
            <TrailTooltip trail={item} actionManagementSchedule={actionManagementSchedule}>
              <S.GanttBar
                style={{
                  left: `${leftPosition}px`,
                  width: `${barWidth}px`,
                }}
              >
                <h4>Trilha: {item.txTitle}</h4>
                <p>{dayjs(startDate).format('DD/MM')}</p>
              </S.GanttBar>
            </TrailTooltip>
          </S.GanttRow>
        )
      })}
    </S.GanttContainer>
  )
}

const TrailCalendarGanttChartSemester = ({
  trails,
  actionManagementSchedule,
  currentPeriod,
}: ITrailCalendar & { currentPeriod: number }) => {
  const barWidth = 120
  const monthsToDisplay = getMonthsToDisplay('Semestre', currentPeriod)

  return (
    <S.GanttContainer className="gantt-chart-container">
      {trails.map((item) => {
        const startDate = dayjs(item.moment[0].dtSchedule)
        const startMonthIndex = monthsToDisplay.indexOf(startDate.format('MMM').toLowerCase())

        if (startMonthIndex === -1) return null

        const leftPosition = startMonthIndex * 153.5

        return (
          <S.GanttRow key={item.idTrack} className={'gantt-row'}>
            <TrailTooltip trail={item} actionManagementSchedule={actionManagementSchedule}>
              <S.GanttBar
                style={{
                  left: `${leftPosition}px`,
                  width: `${barWidth}px`,
                }}
              >
                <h4>Trilha: {item.txTitle}</h4>
                <p>{dayjs(startDate).format('DD/MM')}</p>
              </S.GanttBar>
            </TrailTooltip>
          </S.GanttRow>
        )
      })}
    </S.GanttContainer>
  )
}

const TrailCalendarGanttChartQuarter = ({ trails, actionManagementSchedule, currentPeriod }: ITrailCalendar) => {
  const barWidth = 240
  const monthsToDisplay = getMonthsToDisplay('Trimestre', currentPeriod || 0)

  return (
    <S.GanttContainer className="gantt-chart-container">
      {trails.map((item) => {
        const startDate = dayjs(item.moment[0].dtSchedule)
        const startMonthIndex = monthsToDisplay.indexOf(startDate.format('MMM').toLowerCase())

        if (startMonthIndex === -1) return null

        const leftPosition = startMonthIndex * 305

        return (
          <S.GanttRow key={item.idTrack} className={'gantt-row'}>
            <TrailTooltip trail={item} actionManagementSchedule={actionManagementSchedule}>
              <S.GanttBar
                style={{
                  left: `${leftPosition}px`,
                  width: `${barWidth}px`,
                }}
              >
                <h4>Trilha: {item.txTitle}</h4>
                <p>{dayjs(startDate).format('DD/MM')}</p>
              </S.GanttBar>
            </TrailTooltip>
          </S.GanttRow>
        )
      })}
    </S.GanttContainer>
  )
}

const getMonthsToDisplay = (period: string, currentPeriod: number) => {
  const allMonths = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']

  if (period === 'Semestre') {
    return currentPeriod === 1 ? allMonths.slice(0, 6) : allMonths.slice(6, 12)
  }

  if (period === 'Trimestre') {
    return allMonths.slice((currentPeriod - 1) * 3, currentPeriod * 3)
  }

  return allMonths
}

export const TrailCalendar = ({ trails, actionManagementSchedule, period, currentPeriod }: ITrailCalendar) => {
  return (
    <S.Table>
      <thead>
        <tr>
          {getMonthsToDisplay(period || '', currentPeriod || 0).map((month) => (
            <th key={month}>{month}</th>
          ))}
        </tr>
        <hr />
      </thead>
      <S.TBody>
        <tr>
          {getMonthsToDisplay(period || '', currentPeriod || 0).map((_, index: number) => (
            <td key={index}></td>
          ))}
        </tr>
        {period === 'Anual' && (
          <TrailCalendarGanttChartYear trails={trails} actionManagementSchedule={actionManagementSchedule} />
        )}
        {period === 'Semestre' && (
          <TrailCalendarGanttChartSemester
            trails={trails}
            actionManagementSchedule={actionManagementSchedule}
            currentPeriod={currentPeriod || 0}
          />
        )}
        {period === 'Trimestre' && (
          <TrailCalendarGanttChartQuarter
            trails={trails}
            actionManagementSchedule={actionManagementSchedule}
            currentPeriod={currentPeriod}
          />
        )}
      </S.TBody>
    </S.Table>
  )
}
