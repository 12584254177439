import { AppBarComponent, ContainerComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/BNCCRelations'

import { useLessonBNCCRelationsPageContext } from './context'
import * as S from './styles'

export const LessonBNCCRelationsView = () => {
  const { BNCCText } = useLessonBNCCRelationsPageContext()

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <HeaderComponent
          statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
          loading={false}
          isOffline
        />
        <S.ContentParagraph>
          <S.TextParagraph>{BNCCText}</S.TextParagraph>
        </S.ContentParagraph>
      </ContainerComponent>
    </>
  )
}
