import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '60px',
}))

export const ContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  borderBottom: '1px solid #E2E5EC',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const ContentAvatar = styledMUI(Box)(() => ({
  display: 'flex',
  cursor: 'pointer',
}))

export const WrapperComponents = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexFlow: 'row wrap',
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const Category = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#8C22BC',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
  maxWidth: '500px',
}))

export const TextLabel = styledMUI(Typography)<{ color: string }>(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
}))

export const ContentFixedSkill = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  borderBottom: '1px solid #E2E5EC',
}))

export const TextLabelSkill = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const ContentTabs = styledMUI(Box)(() => ({
  padding: '24px',
}))
