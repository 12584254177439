import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const CardProject = styled.li<{
  bgColor: string
  isTrail: boolean
}>`
  width: ${({ isTrail }) => (isTrail ? '206px' : '260px')};
  height: ${({ isTrail }) => (isTrail ? '304px' : '366px')};
  padding: 16px;
  list-style: none;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};

  .divider {
    margin: 16px 0;
  }
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const TextSpan = styled.span``

export const CardIndex = styled.div<{
  color: string
}>`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  display: flex;
  border-radius: 24px;
  background-color: ${({ color }) => color};
`

export const CardTime = styled.div<{
  color: string
  isTrail: boolean
}>`
  color: ${({ color }) => color};
  font-size: ${({ isTrail }) => (isTrail ? '14px' : '16px')};
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 700;
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;

  align-content: space-evenly;
  margin-top: 16px;
  gap: 16px;
`

export const CardStatus = styled.div<{
  color: string
}>`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 100%;
  }
`

export const CardProjectContentDescription = styled.div`
  min-height: 63px;
`
export const BtnExplorar = styled.button`
  display: flex;
  height: 24px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.actionPure};
  color: ${({ theme }) => theme.colors.actionPure};
  background-color: transparent;
  transition: background 0.3s, color 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.actionPure};
    color: ${({ theme }) => theme.colors.highPure};
  }
`

export const CardProjectDescription = styled.p`
  display: -webkit-box;
  font-size: 14px;
  line-height: 150%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CardTeacher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 700;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.lowLight};
  }
`
export const ContainetBtn = styled.div<{ isSameAUPL: boolean }>`
  margin-top: ${({ isSameAUPL }) => (isSameAUPL ? '-4px' : '0')};
`

export const CardProjectTitle = styledMUI(Typography)(() => ({
  height: '60px',
  fontSize: '20px',
  fontWeight: 700,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
  maxWidth: '200px',
}))

export const CardContainer = styledMUI(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '16px',
  backgroundColor: '#E8F6FF',
  borderRadius: '8px',
  boxShadow: 'none',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const WrapperBetween = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50px',
  padding: 0,
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))
