import { IDataTrailsClassProps, DataTrail, DataClassInfos } from './interfaces'

export const defaultDataTrailsClassState: IDataTrailsClassProps = {
  availableTrackForClass: {
    items: [
      {
        idTrack: 0,
        txDescription: '',
        txTitle: '',
        txGuidanceBNCC: '',
        component: [
          {
            coComponentType: '',
            txComponent: '',
          },
        ],
        lesson: [
          {
            idLesson: 0,
            idLessonTrackGroup: 0,
            txDescription: '',
            txTitle: '',
            component: [
              {
                coComponentType: '',
                txComponent: '',
              },
            ],
          },
        ],
      },
    ],
  },
}

export const defaultDataTrailState: DataTrail = {
  idTrack: 0,
  txTitle: '',
  txDescription: '',
  txGuidanceBNCC: '',
  coStage: '',
  component: [
    {
      coComponentType: '',
      coComponent: '',
      txComponent: '',
      txDescription: '',
    },
  ],
  lesson: [
    {
      idLesson: 0,
      idLessonTrackGroup: 0,
      coGrade: '',
      txTitle: '',
      txDescription: '',
      txGuidance: '',
      txGuidanceBncc: '',
      lessonTrackGroupNuOrder: 0,
      component: [
        {
          coComponent: '',
          txComponent: '',
          txDescription: '',
        },
      ],
    },
  ],
}

export const defaultDataClassInfosState: DataClassInfos = {
  class: {
    items: [
      {
        txName: '',
        classShift: {
          txClassShift: '',
        },
        schoolGrade: {
          grade: {
            coGrade: '',
            txGrade: '',
            stage: {
              coStage: '',
              txName: '',
            },
          },
        },
      },
    ],
  },
}
