import { useParams } from 'react-router-dom'

import { CircularProgress } from '@mui/material'

import { useGetStudentsGroupLesson } from '~/services/Students'

import { AssessmentComponent } from '~/components'

import { useAssessmentPageContext } from '../context'
import * as S from '../styles'

interface ITabThirdStep {
  idLessonMomentActivity: number
  txName: string
  evidenceDescription: string
  compentencyName: string
  idEvidence: number
  status: string
  txIconCompetency: string
  txIconSkill: string
  handleAssessmentBatch: any
  isClassInStatus: boolean
  handleAssessment(id: string, coAssessment: string, idEvidence: string): void
}

export const TabThirdStep = ({
  idLessonMomentActivity,
  handleAssessment,
  handleAssessmentBatch,
  txName,
  idEvidence,
  compentencyName,
  evidenceDescription,
  status,
  txIconCompetency,
  txIconSkill,
  isClassInStatus,
}: ITabThirdStep) => {
  const { idLessonMoment } = useParams()
  const { isSameUser } = useAssessmentPageContext()

  const { data: dataStudentsGroupL3, isFetching } = useGetStudentsGroupLesson(Number(idLessonMoment), Number(3))

  const isFinish = status === 'FINA'

  if (!dataStudentsGroupL3 || isFetching) {
    return (
      <S.Loading sx={{ color: '#fff', zIndex: 999999 }} open>
        <CircularProgress color="inherit" />
      </S.Loading>
    )
  }

  return (
    <AssessmentComponent
      disabled={isFinish || !isSameUser || !isClassInStatus}
      handleDrop={handleAssessment}
      handleDropBatch={handleAssessmentBatch}
      txEvidenceName={txName}
      students={dataStudentsGroupL3}
      idEvidence={idEvidence.toString()}
      txEvidenceDescription={evidenceDescription}
      txCompentencyName={compentencyName}
      idActivity={idLessonMomentActivity}
      txIconCompetency={txIconCompetency}
      txIconSkill={txIconSkill}
      type={'lesson'}
      skillType={'FIX'}
    />
  )
}
