import { useState } from 'react'

import { Box, MenuItem } from '@mui/material'

import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent, BackNavigationComponent, ModalUploadAvatar } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({ username, txImagePath, txGrade, studentId, txNameClass }: IHeaderComponentProps) => {
  const { width } = useWindowDimensions()
  const { user } = useAuth()
  const [openModalPictureStudent, setOpenModalPictureStudent] = useState<boolean>(false)

  const isResponsable = user?.role.includes('pais')

  const isMobile = width && width <= 600

  return (
    <>
      <S.HeaderContainer component="header">
        <S.WrapperContent>
          <BackNavigationComponent path={isResponsable ? '/responsable-students' : '/students'} />
          <Box display={'flex'} justifyContent={'space-between'}>
            <S.Wrapper onClick={() => !isResponsable && setOpenModalPictureStudent(!openModalPictureStudent)}>
              <AvatarComponent photoUrl={txImagePath} size="large" />
              <S.TitleContainer>
                <S.Title variant="h1">{username}</S.Title>
                <S.SubTitle>{`${txGrade} - ${txNameClass}`}</S.SubTitle>
              </S.TitleContainer>
            </S.Wrapper>
            {!isMobile && (
              <S.WrapperSelect>
                <S.SelectClass labelId="className" id="1" value={txGrade}>
                  <MenuItem value={txGrade}>
                    <S.SelectItem>{txGrade}</S.SelectItem>
                  </MenuItem>
                </S.SelectClass>
              </S.WrapperSelect>
            )}
          </Box>
        </S.WrapperContent>
      </S.HeaderContainer>
      {/* ESTATICO */}
      <ModalUploadAvatar
        open={openModalPictureStudent}
        title={`Adicionar foto`}
        selectUserId={studentId}
        actionCloseModal={() => setOpenModalPictureStudent(false)}
      />
    </>
  )
}
