import axios from 'axios'
import packageJson from 'package.json'

export const HTTPClient = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL_API,
})

HTTPClient.interceptors.request.use(
  async (config) => {
    const tokenStorage = localStorage.getItem('@LEKTO:token')

    if (tokenStorage) config.headers.Authorization = `Bearer ${tokenStorage}`

    config.headers['x-version'] = packageJson.version

    return config
  },
  (response) => Promise.reject(response),
)
