import { useParams } from 'react-router-dom'

import { AppBarComponent, ContainerComponent, ModalDocumentationComponent, SkeletonComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/BNCCRelations'

import { useLessonBNCCRelationsPageContext } from './context'
import * as S from './styles'

export const LessonBNCCRelationsView = () => {
  const { idLessonMoment, idLesson } = useParams()
  const { isLoading, BNCCText, status, idClass } = useLessonBNCCRelationsPageContext()

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          loading={isLoading}
          statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
          status={status}
        />

        <S.ContentParagraph>
          {isLoading ? <SkeletonComponent width={300} /> : <S.TextParagraph>{BNCCText}</S.TextParagraph>}
        </S.ContentParagraph>

        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && (
            <ModalDocumentationComponent
              queryKeys={['GetLessonDocumentation']}
              idLessonMoment={Number(idLessonMoment)}
              idLesson={Number(idLesson)}
              variant="floater"
            />
          )}
        </>
      </ContainerComponent>
    </>
  )
}
