import {
  AccordionTextComponent,
  AppBarComponent,
  ContainerComponent,
  DividerComponent,
  DropdownSkillComponent,
  QuickNavigationComponent,
} from '~/components'

import { CardGeneralActivityComponent, HeaderGeneralComponent } from '~/containers/private/Fund1/SecondStep'

import { useSecondStepPageContext } from './context'
import * as S from './styles'

export const SecondGeneralStepView = () => {
  const { filteredStepTwoGroups, lessonSteps, activities, activedTab, handleActiveNewTab } = useSecondStepPageContext()

  const status = lessonSteps?.[1]?.momentStatus.coMomentStatus
  const evidenceFixed = lessonSteps?.[1]?.lessonActivity?.[0]?.lessonActivityEvidence?.filter(
    (item) => item.coEvidenceType === 'FIX',
  )[0].evidenceGrade.evidence

  const handleActivities = () => {
    return (
      <>
        {activities?.map((activity) => {
          const avatars = filteredStepTwoGroups?.lessonActivity?.find(
            (item) => item.idLessonActivity === activity.idLessonActivity,
          )
          return (
            <>
              <CardGeneralActivityComponent
                isOffline
                key={activity?.idLessonActivity}
                txChallenge={activity?.txChallenge}
                txActivity={activity.txTitle}
                txSkill={activity?.category?.txName}
                icon={activity?.category?.txImagePath}
                activity={activity}
                variableSkill={
                  lessonSteps?.[1]?.lessonActivity
                    ?.find((item) => item.idLessonActivity === activity.idLessonActivity)
                    ?.lessonActivityEvidence?.find((evidence) => evidence.coEvidenceType === 'VAR')?.evidenceGrade
                    ?.evidence
                }
                avatarsPhotos={avatars}
              />
            </>
          )
        })}
      </>
    )
  }
  if (!lessonSteps) return null

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            stepActived="secondStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            isOffline
          />
        </S.NavContainer>

        <HeaderGeneralComponent
          isLoading={false}
          activedTab={activedTab}
          nuDuration={lessonSteps[1]?.nuDuration}
          actionSetActiveTab={(newActivedTab: number) => handleActiveNewTab(newActivedTab)}
          title={lessonSteps?.[1].txTitle ? lessonSteps?.[1].txTitle : 'Realizar'}
        />

        <S.SkillDetails>
          <S.SkillSpan>Habilidade Fixa</S.SkillSpan>
          {evidenceFixed?.txName && (
            <DropdownSkillComponent
              txSkillName={evidenceFixed?.ability?.txName}
              iconSkill={evidenceFixed?.ability?.txImagePath}
              txSkillDescription={evidenceFixed?.txName}
              txBncc=""
              txCompetency={evidenceFixed?.ability?.competence?.txName}
              iconCompetency={evidenceFixed?.ability?.competence?.txImagePath}
            />
          )}
        </S.SkillDetails>
        <DividerComponent />
        <S.ContentDescription>
          <AccordionTextComponent paragraph={lessonSteps?.[1]?.txGuidance || '...'} limit={120} />
        </S.ContentDescription>
        <DividerComponent />
        {handleActivities()}
      </ContainerComponent>
    </>
  )
}
