import { Box } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const BorderLinearProgress = styledMUI(LinearProgress)<{ competenceColor: string }>(({ competenceColor }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E2E5EC',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: competenceColor ? `#${competenceColor}` : '#308fe8',
  },
}))
export const List = styled.ul`
  display: grid;
  gap: 16px;
  list-style: none;
`
export const ContainerIcon = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.highPure};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 760px) {
    display: grid;
    gap: 8px;
    margin-bottom: 8px;
  }
`

export const Status = styled.span`
  background-color: ${({ theme }) => theme.colors.midMedium};
  color: ${({ theme }) => theme.colors.midDark};
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  max-width: 120px;
  width: fit-content;
`

export const ListItem = styled.li`
  background-color: ${({ theme }) => theme.colors.midLight};
  border-radius: 16px;
`

export const ContainerItem = styled.div`
  display: flex;
  gap: 8px;
  padding: 20px 16px;
  border-radius: 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.midLight};
`

export const CompetenceContainer = styled(Box)`
  display: grid;
  max-width: fit-content;

  align-content: center;
  align-items: center;
`

export const BoxButton = styled(Box)`
  button {
    font-size: 16px;
    font-weight: 400;
  }
`

export const BoxOverview = styled.div`
  background-color: #ffffff;
  padding: 12px;
  border-radius: 16px;

  h3 {
    padding: 4px 8px;
    font-weight: 400;
    font-size: 16px;
  }
`

export const MainContainerItem = styled.div`
  width: 85%;
  display: grid;
  gap: 8px;
`

export const ContainerAction = styled.div`
  cursor: pointer;
`

export const Arrow = styled.div`
  background-color: ${({ theme }) => theme.colors.midMedium};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #000;
`
