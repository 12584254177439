import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 8px;
`
export const ContainerEvidence = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};
`

export const WrapperAvatars = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    justifySelf: 'start',
    margin: '10px 0',
  },
}))

export const TitleContainer = styled.div`
  gap: 8px;
  display: flex;
`

export const TitleEvidence = styled(Typography)`
  font-size: 16px !important;
  gap: 8px;
  font-weight: 700 !important;
  margin-bottom: 15px;

  span {
    font-weight: 500;
  }

  @media (max-width: 450px) {
    font-size: 14px !important;
  }
`

export const Title = styled(Typography)`
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  margin-bottom: 8px;

  span {
    color: ${({ theme }) => theme.colors.quaternaryPure};
  }
`
