import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const WrapperButton = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#666',
}))
