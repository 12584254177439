import { createContext, useContext, useMemo } from 'react'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { IStudentsPageContextProps, IStudentsPageProviderProps } from '../Students/interfaces'
import { GET_CLASSES_BY_TUTOR } from './schemas.queries'

const StudentsPageContext = createContext<IStudentsPageContextProps>({
  data: {},
  isLoading: false,
})

const StudentsPageProvider = ({ children }: IStudentsPageProviderProps) => {
  const { isLoading, data } = useQueryGraphQLClient('network', 'GET_CLASSES_BY_TEACHER', GET_CLASSES_BY_TUTOR)

  const listClassesPageProviderValues = useMemo(() => {
    return {
      isLoading,
      data,
    }
  }, [isLoading, data])

  return <StudentsPageContext.Provider value={listClassesPageProviderValues}>{children}</StudentsPageContext.Provider>
}

const useStudentsPageContext = () => useContext(StudentsPageContext)

export { StudentsPageProvider, useStudentsPageContext }
