import { IDataClassInfosProps, IDataTrailsUnavaibleProps } from './interfaces'

export const defaultDataClassInfos: IDataClassInfosProps = {
  class: {
    items: [
      {
        txName: '',
        schoolGrade: {
          grade: {
            txGrade: '',

            stage: {
              coStage: '',
            },
          },
        },
      },
    ],
  },
}

export const defaultTrailsDataUnavaibleState: IDataTrailsUnavaibleProps = {
  value: [
    {
      start: new Date(),
      finish: new Date(),
    },
  ],
}
