import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const ButtonIconFull = styledMUI(IconButton)(() => ({
  height: '32px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  color: theme.colors.actionPure,
  backgroundColor: 'transparent',
  padding: 8,
  transition: 'all 0.5s',

  '&:hover': {
    borderColor: theme.colors.actionDark,
    backgroundColor: 'transparent',
    boxShadow: theme.shadows.level3,
    color: theme.colors.actionDark,
  },
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  color: theme.colors.actionPure,
  backgroundColor: 'transparent',
  padding: 0,
  transition: 'all 0.5s',

  '&:hover': {
    borderColor: theme.colors.actionDark,
    backgroundColor: 'transparent',
    boxShadow: theme.shadows.level3,
    color: theme.colors.actionDark,
  },
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  color: theme.colors.actionPure,

  '&:hover': {
    color: theme.colors.actionDark,
  },
}))

export const TitleLabel = styledMUI(Typography)<{ isTitle?: boolean }>(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: theme.colors.lowPure,

  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '@media (max-width: 768px)': {
    maxWidth: '300px',
  },
}))
