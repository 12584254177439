import { ModalDocumentationComponent, QuickNavigationComponent, SkeletonComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderProjectComponent = ({
  loading,
  txTitle,
  dataProject,
  statusBlock,
  isSameUser,
  idLesson,
  isClassInStatus,
}: IHeaderComponentProps) => {
  return (
    <S.HeaderContainer component="header">
      <S.ContentHeader>
        {loading ? (
          <S.HeaderLoading>
            <SkeletonComponent height={30} width={30} variant="circular" />

            <SkeletonComponent width={300} variant="rounded" />

            <SkeletonComponent height={35} width={35} variant="circular" />
            <SkeletonComponent height={35} width={35} variant="circular" />
            <SkeletonComponent height={35} width={35} variant="circular" />
            <SkeletonComponent height={35} width={35} variant="circular" />
            <SkeletonComponent height={35} width={35} variant="circular" />
            <SkeletonComponent height={35} width={35} variant="circular" />
            <SkeletonComponent height={35} width={35} variant="circular" />
          </S.HeaderLoading>
        ) : (
          <QuickNavigationComponent
            label={txTitle}
            stepActived="documentations"
            statusBlock={statusBlock}
            status={dataProject?.momentStatus?.coMomentStatus}
          />
        )}

        {loading ? <SkeletonComponent width={200} height={36} /> : <S.Title variant="h1">Documentações</S.Title>}

        {loading ? (
          <SkeletonComponent width={300} height={24} />
        ) : (
          <S.TextLabel variant="caption">Consulte aqui todos os registros criados durante a aula.</S.TextLabel>
        )}

        {/* {loading ? (
          <SkeletonComponent width={250} height={24} />
        ) : (
          <SelectComponent
            stepOption={stepOption}
            stepsSelected={stepsSelected}
            handleChangeFilters={handleChangeFilters}
          />
        )} */}
      </S.ContentHeader>
      <S.ProjectBtn>
        {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(dataProject?.momentStatus?.coMomentStatus) &&
          isSameUser &&
          isClassInStatus && (
            <ModalDocumentationComponent
              idProject={Number(dataProject?.idProjectMoment)}
              variant="fixed"
              idLesson={idLesson}
              isClassInStatus={isClassInStatus}
              queryKeys={'getDocumentation'}
            />
          )}
      </S.ProjectBtn>
    </S.HeaderContainer>
  )
}
