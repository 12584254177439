import { IGetLessonInformationGroup } from '~/services/LessonSteps/types'

export const initialDataActivityGroup: IGetLessonInformationGroup = {
  idLesson: 0,
  dtInserted: '',
  nuOrder: 0,
  idLessonStep: 0,
  lessonActivity: [
    {
      idLessonActivity: 0,
      nuOrder: 0,
      category: {
        txName: '',
        txImagePath: '',
      },
      coGrade: '',
      txChallenge: '',
      idLessonStep: 0,
      evidenceGrade: {
        dtInserted: '',
        evidence: {
          txName: '',
          dtInserted: '',
          idEvidence: 0,
          ability: {
            txName: '',
            txImagePath: '',
            idAbility: 0,
            dtInserted: '',
            competence: {
              txName: '',
              dtInserted: '',
              idCompetence: 0,
            },
            txBgPrimaryColor: '',
            txBgSecondaryColor: '',
          },
        },
      },
      txTitle: '',
      lessonActivityOrientation: [
        {
          idLessonActivity: 0,
          txTitle: '',
          dtInserted: '',
          txOrientationCode: '',
          idLessonActivityOrientation: 0,
          lessonActivitySupportReference: [
            {
              idLessonActivityOrientation: 0,
              dtInserted: '',
              idLessonActivitySupportReference: 0,
              txTitle: '',
              idMediaInformation: 0,
              coSupportReference: '',
              mediaInformation: {
                txName: '',
                txContentType: '',
                txPath: '',
                nuSize: 0,
                inPublic: false,
                coMediaLocator: '',
                txAbsoluteUrl: '',
              },
            },
          ],
        },
      ],
      lessonMomentActivity: [
        {
          idLessonActivity: 0,
          dtInserted: '',
          idLessonMoment: 0,
          idLessonMomentActivity: 0,
          lessonActivityDocumentation: [],
          idClass: 0,
          idNetwork: 0,
          idSchool: 0,
          idSchoolYear: 0,
          lessonMomentGroup: [
            {
              idLessonMomentActivity: 0,

              idLessonMomentGroup: 0,

              idSchool: 0,
              dtInserted: '',
              idNetwork: 0,
              idSchoolYear: 0,
              idClass: 0,
              studentClass: {
                idClass: 0,
                idStudent: 0,
                idNetwork: 0,
                idSchool: 0,
                idSchoolYear: 0,
                idUserStudent: 0,
                dtInserted: '',
                student: {
                  idSchool: 0,
                  idNetwork: 0,
                  dtInserted: '',
                  idStudent: 0,
                  idUserStudent: 0,
                  idUser: 0,
                  txImagePath: '',
                  txName: '',
                  inDeleted: false,
                },
              },
              idStudent: 0,
              idUserStudent: 0,
              inAttendance: false,
              assessment: {
                nuScore: 0,
                coAssessment: '',
                txAssessment: '',
                dtInserted: '',
              },
            },
          ],
        },
      ],
    },
  ],
}
