import { useNavigation } from '~/_context/Navigation'

import { AppBarComponent, ContainerComponent, MomentCardComponent, ValidateDataSessionComponent } from '~/components'

import { useSchedulePageContext } from './context'
import { HeaderComponent } from './Header/Header'
import * as S from './styles'

export const titleByNuOrder = (nuOrder: number) => {
  switch (nuOrder) {
    case 1:
      return 'Contextualização e Chuva de Ideias'
    case 2:
      return 'Ideação e Planejamento'
    case 3:
      return 'Execução do Planejamento'
    case 4:
      return 'Partilha e Escuta Coletiva'
  }
}

export const ScheduleView = () => {
  const { handleNavigation } = useNavigation()

  const {
    selectedDay,
    changeSelectedDay,
    isLoading,
    data,
    isRefetching,
    monthFormatted,
    itemsByMonth,
    monthRefs,
    changeMonth,
  } = useSchedulePageContext()

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          selectedDay={selectedDay}
          actionSetSelectedDay={changeSelectedDay}
          initialOptions={'day'}
          monthFormatted={monthFormatted}
          changeMonth={changeMonth}
        />

        <>
          {(!isLoading || !isRefetching) && !data?.scheduledMomentByProfessor?.items?.length ? (
            <S.ContentEmoji>
              <ValidateDataSessionComponent
                screen={'home'}
                title="Nenhuma aula para mostar"
                description=""
                space={100}
              />
            </S.ContentEmoji>
          ) : (
            <S.ContentCards component="ul">
              {isLoading || isRefetching ? (
                <>
                  {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
                    <MomentCardComponent key={index} loading={isLoading || isRefetching} />
                  ))}
                </>
              ) : (
                <>
                  <div>
                    {Object.keys(itemsByMonth).map((month, index) => (
                      <div key={index} ref={(el) => (monthRefs.current[month] = el)} data-month={index}>
                        <S.Wrapper>
                          <S.ContentSelectDate>
                            <S.RowTitle>
                              <S.Title variant="h1">{month}</S.Title>
                            </S.RowTitle>
                          </S.ContentSelectDate>
                        </S.Wrapper>

                        {itemsByMonth[month].length === 0 ? (
                          <S.ContentMessage>
                            <ValidateDataSessionComponent
                              screen={'home'}
                              title="Nenhuma aula para mostrar"
                              description=""
                              space={100}
                            />
                          </S.ContentMessage>
                        ) : (
                          itemsByMonth[month].map((group, subIndex) => (
                            <div key={subIndex}>
                              {group.isToday ? (
                                <S.ContentToday>
                                  <S.DateText variant="caption">{group.date}</S.DateText>
                                  <S.BoxChip>Hoje</S.BoxChip>
                                </S.ContentToday>
                              ) : (
                                <S.ContentToday key={subIndex}>
                                  <p>{group.date}</p>
                                </S.ContentToday>
                              )}
                              {group.items.map((item, itemIndex) => (
                                <MomentCardComponent
                                  key={itemIndex}
                                  totalClass={Number(item.nuTrackStages)}
                                  momentStatusCode={item.coMomentStatus}
                                  momentStatusText={item.txMomentStatus}
                                  momentTime={item.dtSchedule}
                                  nuOrder={item.nuOrder}
                                  txTrackTitle={item.txTrackTitle}
                                  idClass={item.class?.idClass}
                                  showChip={false}
                                  idMomentTrack={item.idMomentTrack}
                                  coStage={item.class?.schoolGrade?.grade?.coStage}
                                  momentTitle={
                                    item.class?.schoolGrade?.grade?.coStage === 'F2'
                                      ? (titleByNuOrder(item.nuOrder) as string)
                                      : (item.txTitle as string)
                                  }
                                  momentClassInfo={item.class}
                                  action={() =>
                                    handleNavigation(
                                      item.class?.schoolGrade?.grade?.coStage,
                                      item.idMoment,
                                      item.class?.idClass,
                                      '/schedule/mobile',
                                    )
                                  }
                                />
                              ))}
                            </div>
                          ))
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </S.ContentCards>
          )}
        </>
      </ContainerComponent>
    </>
  )
}
