import { ISizesProps } from '~/interfaces/Sizes'

export const sizesAvatars = (size: 'xsmall' | 'small' | 'medium' | 'mediumPlus' | 'mediumLarge' | 'large') => {
  const sizeAvatar: ISizesProps = {
    xsmall: '16px',
    small: '24px',
    medium: '32px',
    mediumPlus: '48px',
    mediumLarge: '56px',
    large: '64px',
  }

  return sizeAvatar[size as keyof ISizesProps]
}
