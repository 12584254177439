import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilArrowLeft, UilArrowRight, UilClockThree } from '@iconscout/react-unicons'
import { Box } from '@mui/material'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useTrailProjectInformation } from '~/services/Trails'

import {
  AppBarComponent,
  ButtonComponent,
  CardTrailComponent,
  ContainerComponent,
  DividerComponent,
  ModalBNCCComponent,
  ScheduleModalComponent,
  SkeletonComponent,
} from '~/components'

import { TrailsHeaderComponent } from '~/containers/private/EM'

import { GET_CLASS_INFOS_BY_CLASSID } from '~/pages/private/Trails/ListTrailsAvailable/schemas.queries'

import { validateType } from '~/validations/setTypeModal'

import * as S from './styles'

export const TrailsNotGeneratedEMView = () => {
  const { trailId, classId } = useParams()
  const [openModalBNCC, setOpenModalBNCC] = useState<boolean>(false)
  const [openModalTime, setOpenModalTime] = useState<boolean>(false)

  const {
    data: dataClassDetails,
    remove: removeClassDetails,
    isLoading: isLoadingTrailsDetails,
  } = useTrailProjectInformation(Number(classId), true)

  const handleCloseModalTime = useCallback(() => {
    setOpenModalTime(false)
  }, [])

  const handleOpenModalTime = useCallback(() => {
    setOpenModalTime(true)
  }, [])

  const { data: dataClassInfos, remove: removeClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(classId),
      showDisabledClasses: true,
    },
  )

  useEffect(() => {
    return () => {
      removeClassDetails()
      removeClassInfos()
    }
  }, [trailId, classId, removeClassDetails, removeClassInfos])

  const handleOpenModalBNCC = useCallback(() => {
    setOpenModalBNCC(true)
  }, [])

  const handleOpenModalSchedule = useCallback(() => {
    handleOpenModalSchedule()
  }, [])

  const handleCloseModalBNCC = useCallback(() => {
    setOpenModalBNCC(false)
  }, [])

  const moments = dataClassDetails?.moment
  const idTrack = dataClassDetails?.idTrack

  const [currentIndex, setCurrentIndex] = useState(0)
  const displayedMoments = moments?.slice(currentIndex, currentIndex + 3)

  const handleLeftClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 3)
    }
  }

  const handleRightClick = () => {
    if (moments && currentIndex < moments?.length - 3) {
      setCurrentIndex(currentIndex + 3)
    }
  }

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <TrailsHeaderComponent
          path={`/trail/${trailId}/project/${classId}`}
          txTitle={dataClassDetails?.txTitle}
          txClassShift={dataClassInfos?.class.items[0].classShift.txClassShift}
          txGrade={dataClassInfos?.class.items[0].schoolGrade.grade.txGrade as string}
          txSchoolGradeName={dataClassInfos?.class.items[0].txName as string}
          classGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}
          txDescription={dataClassDetails?.txDescription}
          component={dataClassDetails?.component}
          professor={dataClassDetails?.professor}
          moment={dataClassDetails?.moment?.sort(
            (a: any, b: any) => Number(new Date(a.dtSchedule)) - Number(new Date(b.dtSchedule)),
          )}
          loading={isLoadingTrailsDetails}
          IdLesson={Number(trailId)}
          stage={dataClassDetails?.stage}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionOpenModalSchedule={handleOpenModalSchedule}
          actionOpenModalTime={handleOpenModalTime}
        />
        <DividerComponent />

        <S.ContainerLesson>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <S.Title>Aulas da trilha</S.Title>
            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <ButtonComponent
                iconStart={<UilClockThree color={'#0095FF'} size={16} />}
                variant={'outline'}
                size={'small'}
                text={'Gerenciar'}
                fontWeight="normal"
                onClick={() => setOpenModalTime(true)}
              />
            </Box>
          </Box>
          <S.Wrapper>
            <S.ArrowButton onClick={handleLeftClick} enabled={currentIndex > 0}>
              <UilArrowLeft size="16" />
            </S.ArrowButton>
            <S.ContainerCardsList>
              {isLoadingTrailsDetails ? (
                <SkeletonComponent variant="rounded" width={130} />
              ) : (
                displayedMoments &&
                displayedMoments?.map((moment, index) => (
                  <CardTrailComponent
                    key={moment.idMoment}
                    idMoment={moment.idMoment}
                    professor={moment.professor}
                    idClass={Number(classId)}
                    index={currentIndex * 1 + index + 1}
                    description={moment.projectTrackStage.txDescription}
                    dtSchedule={moment.dtSchedule}
                    coMomentStatus={moment.coMomentStatus}
                    title={moment.projectTrackStage.txTitle}
                    openModal={handleOpenModalTime}
                    variant={'not-generated'}
                  />
                ))
              )}
            </S.ContainerCardsList>
            <S.ArrowButton onClick={handleRightClick} enabled={moments ? moments?.length - currentIndex > 3 : false}>
              <UilArrowRight size="16" />
            </S.ArrowButton>
          </S.Wrapper>
        </S.ContainerLesson>

        <DividerComponent />

        <S.Container>
          <S.TimeStatusDiv>
            <S.Title>Projetos da trilha</S.Title>
          </S.TimeStatusDiv>
        </S.Container>

        <ScheduleModalComponent
          open={openModalTime}
          actionCloseModal={handleCloseModalTime}
          idTrack={idTrack}
          classId={Number(classId)}
          moments={moments}
          type={validateType(dataClassDetails?.coStage as string, 'edit')}
          dataClassInfos={dataClassInfos}
          queryName={['TrailProjectInformation']}
          routing={true}
          title={dataClassDetails?.txTitle}
          router={`/class-details/${classId}`}
        />
        <ModalBNCCComponent
          open={openModalBNCC}
          txGuidanceBNCC={dataClassDetails?.txGuidanceBNCC as string}
          actionCloseModal={handleCloseModalBNCC}
        />
      </ContainerComponent>
    </>
  )
}
