import { useMutation, UseQueryOptions } from '@tanstack/react-query'
import { IApiError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

export const getStudentsGroupsPdf = async (idClass: number, idMoment: number) => {
  const { data } = await HTTPClient.get(`/moment/class/${idClass}/by-moment/${idMoment}/students-groups/pdf`, {
    responseType: 'blob',
  })

  return data
}

export const useServiceGetStudentsGroupsPdf = (
  idClass: number,
  idMoment: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) => useMutation<any, IApiError, void>(['GetStudentsGroupsPdf'], () => getStudentsGroupsPdf(idClass, idMoment), options)
