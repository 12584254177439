import { LessonPlanningPageProvider } from './context'
import { LessonPlanningView } from './view'

export const LessonPlanningPageOffline = () => {
  return (
    <LessonPlanningPageProvider>
      <LessonPlanningView />
    </LessonPlanningPageProvider>
  )
}
