import { StudentDashboardPageProvider } from './context'
import { StudentDashboardView } from './view'

export const StudentDashboardPage = () => {
  return (
    <StudentDashboardPageProvider>
      <StudentDashboardView />
    </StudentDashboardPageProvider>
  )
}
