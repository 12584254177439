import { Backdrop, Box, Divider, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'left',
  padding: '24px',
  gap: '10px',
}))

export const Loading = styledMUI(Backdrop)(() => ({}))

export const WrapperDatePicker = styledMUI(Box)(() => ({
  display: 'flex',
  textAlign: 'left',
  alignContent: 'center',
  '@media (max-width: 600px)': {
    display: 'grid',
    width: '50%',
  },
}))

export const DatePickerStyle = styledMUI(DesktopDatePicker)(() => ({
  width: '110px',
  borderRadius: '5px',
  '@media (max-width: 600px)': {
    width: '320px',
  },
}))

export const TitleCardProject = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  marginBottom: '16px',
  width: '100%',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'flex',
    gap: '8px',
  },
}))

export const Content = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '16px',
  width: '100%',
}))

export const Wrapper = styledMUI(Box)(() => ({
  width: '50%',
  gap: '16px',
}))

export const WrapperMoments = styledMUI(Box)(() => ({
  gap: '16px',
  width: '100%',
  overflowY: 'auto',
}))

export const WrapperBox = styledMUI(Box)(() => ({
  borderRadius: '10px',
  width: '100%',
  '@media (max-width: 600px)': {
    width: '370px',
  },
}))

export const TextTitleTrilhas = styledMUI(Typography)(() => ({
  width: '85%',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'left',
  color: '#0095FF',
}))

export const TextClass = styledMUI(Typography)(() => ({
  marginLeft: '4px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '120%',
  textAlign: 'left',
  color: '#000',
}))

export const Title = styledMUI(Typography)(() => ({
  width: '85%',
  fontStyle: 'bolder',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'left',
  color: '#000',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  display: 'flex',
  marginTop: '25px',
  justifyContent: 'space-between',
  gap: '8px',
}))

export const ContentButtonsEnd = styledMUI(Box)(() => ({
  display: 'flex',
  marginTop: '25px',
  justifyContent: 'end',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    gap: '12px',
    justifyContent: 'center',
    marginBottom: '25px',
  },
}))

export const FinalButton = styledMUI(Box)(() => ({
  display: 'flex',
  alignContent: 'flex-end',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    gap: '8px',
    display: 'grid',
    alignContent: 'center',
    marginBottom: '2rem',
  },
}))

export const BoxOverflow = styledMUI(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
  maxHeight: '500px',
  padding: 2,
}))

export const WrapperDivider = styledMUI(Box)(() => ({
  display: 'flex',
  marginTop: '12px',
  marginBottom: '12px',
  height: '99px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
  textAlign: 'left',
  '@media (max-width: 600px)': {
    display: 'flex',
    width: '100%',
    marginLeft: '0px',
    justifyItems: 'center',
  },
}))

export const WrapperNumber = styledMUI(Box)(() => ({
  marginLeft: '5px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'flex',
    width: '100%',
    marginLeft: 0,
    alignItems: 'center',
  },
}))

export const DividerLine = styledMUI(Divider)(() => ({
  gap: '12px',
  '@media (max-width: 600px)': {
    width: '100%',
    marginTop: '20px',
  },
}))

export const WrapperContent = styledMUI(Box)(() => ({
  marginLeft: '5px',
  display: 'flex',
}))

export const WrapperText = styledMUI(Box)(() => ({
  marginLeft: '5px',
  marginBottom: '10px',
  display: 'flex',
  marginTop: '10px',
  width: '100%',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'left',
}))

export const NumberBox = styledMUI(Box)(() => ({
  justifySelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: '#BDC3CF',
  color: 'white',
  '@media (max-width: 600px)': {
    width: '24px',
    height: '24px',
    padding: 10,
  },
}))

export const ContainerTrackClass = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #7c8189',
  width: 'fit-content',
  padding: '4px 6px',
  borderRadius: '4px',
  marginBottom: '4px',
}))

export const ClassSpanInfo = styledMUI(Box)(() => ({
  fontSize: '14px',
  padding: '0 6px',
  color: '#7c8189',
  borderRight: '1px solid #e2e5ec',
  '&:last-child': {
    borderRight: 0,
  },
}))

export const IconCircle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E8F6FF',
  height: '40px',
  width: '40px',
  borderRadius: '25px',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))
