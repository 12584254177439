import { Box, Button, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
  gap: '16px',
  backgroundColor: `${theme.colors.highPure}`,
  borderBottom: `1px solid ${theme.colors.midMedium}`,
}))

export const HeaderInfoGeneral = styledMUI(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: isMobile ? 'column' : 'row',
  gap: '16px',
  backgroundColor: `${theme.colors.highPure}`,
}))

export const HeaderInfo = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))

export const textTrack = styledMUI(Typography)(() => ({
  fontSize: '14px',
  color: `${theme.colors.lowLight}`,
  fontWeight: 400,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: `${theme.colors.lowPure}`,
}))

export const TitleSmall = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: `${theme.colors.lowPure}`,
}))

export const WrapperClass = styledMUI(Box)(() => ({
  borderRadius: '4px',
  border: `1px solid ${theme.colors.midDark}`,
  borderColor: `${theme.colors.midDark}`,
  color: `${theme.colors.midDark}`,
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))

export const WrapperStudents = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    gap: '16px',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: `${theme.colors.lowMedium}`,
}))

export const ComponentContentPopover = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const ContainerPopover = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  gap: '8px',
  width: '334px',
  marginTop: '26px',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: `${theme.colors.highPure}`,
  boxShadow: `0px 4px 4px ${theme.shadows.level4}`,
  transition: '0.8',
  zIndex: 9999,
}))

export const ContentTooltip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
export const TextTooltip = styled.span<{ popoverContentType?: string }>`
  flex-direction: row-reverse;
  gap: 8px;
  display: flex;
  font-size: 14px;
  font-weight: ${({ popoverContentType }) => (popoverContentType === 'PRI' ? '700' : '400')};
  &.last-item {
    margin-left: 5px;
  }
`
export const TitleTooltip = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.lowLight};
`
export const ButtonIcon = styledMUI(Button)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  backgroundColor: `${theme.colors.highPure}`,
  padding: 0,
}))

export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const TextInfoLabel = styledMUI(Typography)<{ isSameUser?: boolean }>(({ isSameUser }) => ({
  maxWidth: isSameUser ? '82px' : '135px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  WebkitLineClamp: 1,
  whiteSpace: 'nowrap',
  fontSize: '14px',
  color: `${theme.colors.lowPure}`,
  fontWeight: 700,
  alignItems: 'center',
  span: {
    color: `${theme.colors.lowLight}`,
    fontSize: '14px',
    fontWeight: 400,
  },
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const WrapperButton = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
}))

export const ContentAvatar = styled.div`
  display: flex;
  cursor: pointer;
`
