import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useGetDocumentation } from '~/services/Documentation'
import { useServiceGetLesson } from '~/services/Lesson'

import { IDocumentationsPageContextProps, IDocumentationsPageProviderProps } from './interfaces'

const DocumentationsPageContext = createContext<IDocumentationsPageContextProps>({
  isLoading: false,
  listDocumentation: [],
  handleSetDocumentation: () => undefined,
  idLessonMoment: 0,
  idClass: 0,
  idLesson: 0,
  status: '',
  isSameUser: false,
  isClassInStatus: true,
})

const DocumentationsPageProvider = ({ children }: IDocumentationsPageProviderProps) => {
  const { idLessonMoment, idLesson, idClass } = useParams()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(4)
  const [hasNext, setHasNext] = useState(false)

  const { user } = useAuth()

  const [listDocumentation, setListDocumentation] = useState<Array<any>>([{}])

  const { data: dataLessonInformation } = useServiceGetLesson(Number(idLessonMoment))

  const status = dataLessonInformation?.momentStatus.coMomentStatus

  const idUserProfessor = dataLessonInformation?.idUserProfessor
  const isSameUser = user?.id_user === idUserProfessor
  const isClassInStatus = dataLessonInformation?.class?.inStatus

  const {
    data,
    isLoading,
    refetch: refetchGetDocs,
  } = useGetDocumentation(Number(idClass), Number(idLessonMoment), page + 1, pageSize)

  useEffect(() => {
    if (data) {
      setListDocumentation(data)
    }
  }, [data])

  useEffect(() => {
    data
    const valueHasNext = data?.headers['x-pagination']?.length ? JSON.parse(data?.headers['x-pagination'])?.hasNext : ''
    setHasNext(valueHasNext)
    setPageSize(valueHasNext ? pageSize + 2 : pageSize)
    // eslint-disable-next-line
  }, [data?.headers, page])

  const fetchMoreData = useCallback(() => {
    setPage(page + 1)
    refetchGetDocs().then()
  }, [page, setPage, refetchGetDocs])

  const handleSetDocumentation = (documentation: Array<object>) => {
    setListDocumentation(documentation?.data)
  }

  const documentationsPageProviderValues = useMemo(() => {
    return {
      listDocumentation,
      handleSetDocumentation,
      isLoading,
      idLesson: Number(idLesson),
      idLessonMoment: Number(idLessonMoment),
      status,
      isSameUser,
      fetchMoreData,
      hasNext,
      isClassInStatus,
    }
  }, [
    idLesson,
    status,
    isSameUser,
    idLessonMoment,
    isLoading,
    listDocumentation,
    fetchMoreData,
    hasNext,
    isClassInStatus,
  ])

  return (
    <DocumentationsPageContext.Provider value={documentationsPageProviderValues}>
      {children}
    </DocumentationsPageContext.Provider>
  )
}

const useDocumentationsPageContext = () => useContext(DocumentationsPageContext)

export { DocumentationsPageProvider, useDocumentationsPageContext }
