import styled from 'styled-components'

export const Audio = styled.audio`
  height: 36px;
  width: 274px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  background-color: none;
  &::-webkit-media-controls-play-button {
    background-color: #90d1ff;
    border-radius: 50%;
  }
  &::-webkit-media-controls-panel {
    background-color: #e8f6ff;
    padding: 8px;
  }
  &::-webkit-media-controls-current-time-display {
    color: #0095ff;
  }
  &::-webkit-media-controls-time-remaining-display {
    color: #0095ff;
  }

  &::-webkit-media-controls-volume-control-container {
    display: none;
  }
  &::-webkit-media-controls-panel-internal-media-controls-overflow-button {
    display: none;
  }
  &::-webkit-media-controls-play-button.pause {
    color: #fff;
  }
`
