import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'
import { useSnackbar } from '~/_context/Snackbar'

import { useServiceChangePassword } from '~/services/ChangePassword'
import { useServiceForgotPassword } from '~/services/ForgotPassword'
import { useServiceLogin } from '~/services/Login'

import { ILoginPageContextProps, ILoginPageProviderProps } from './interfaces'

const LoginPageContext = createContext<ILoginPageContextProps>({
  showUpdatePasswordModal: false,
  handleCloseModalUpdatePassword: () => Object,
  currentStateLogin: Object,
  isLoadingChangePassword: false,
  handleChangePassword: () => Object,
  showPassword: false,
  showModalForgotPassword: false,
  showModalUpdatePasswordConfirm: false,
  isLoadingLogin: false,
  handleLogin: () => Object,
  isLoadingForgotPassword: false,
  handleForgotPassword: () => Object,
  handleValidInput: () => Object,
  handleShowPassword: () => Object,
  handleHidePassword: () => Object,
  handleOpenModalForgotPassword: () => Object,
  handleCloseModalForgotPassword: () => Object,
  handleCloseModalUpdatePasswordConfirm: () => Object,
})

const LoginPageProvider = ({ children }: ILoginPageProviderProps) => {
  const navigate = useNavigate()

  const { authenticatedUser } = useAuth()
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const [searchParams] = useSearchParams()

  // Acessando os parâmetros específicos da query
  const localeId = searchParams.get('locale_id')
  const returnTo = searchParams.get('return_to')

  const [valid, setValid] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showModalForgotPassword, setShowModalForgotPassword] = useState<boolean>(false)
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState<boolean>(false)
  const [showModalUpdatePasswordConfirm, setShowModalUpdatePasswordConfirm] = useState<boolean>(false)
  const [cancelModalUpdatePassword, setCancelModalUpdatePassword] = useState<boolean>(false)
  const [currentStateLogin, setCurrentStateLogin] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  })

  const {
    isError,
    isLoading: isLoadingLogin,
    data: dataLogin,
    error: errorLogin,
    mutate: mutateLogin,
  } = useServiceLogin()

  const {
    isLoading: isLoadingForgotPassword,
    isSuccess: isSuccessForgotPassword,
    mutate: handleForgotPassword,
  } = useServiceForgotPassword()

  const {
    isLoading: isLoadingChangePassword,
    error: errorChangePassword,
    mutate: handleChangePassword,
    isSuccess: isSuccessChangePassword,
  } = useServiceChangePassword()

  const tokenStorage = dataLogin?.access_token ?? localStorage.getItem('@LEKTO:token')

  useEffect(() => {
    if (localeId && returnTo && tokenStorage) {
      const url = `${
        import.meta.env.VITE_BASE_URL_API_SERVICES
      }auth/zendesk?accessToken=${tokenStorage}&localeId=${localeId}&returnUrl=${returnTo}`
      window.location.replace(url)
    }
  }, [localeId, returnTo, tokenStorage])
  if (!isLoadingLogin && dataLogin) {
    const userAuthenticated = authenticatedUser(dataLogin.access_token, false)

    if (userAuthenticated?.role.includes('pais')) {
      navigate('/responsable-students')
    } else {
      navigate('/home')
    }
  }

  const handleValidInput = () => setValid(true)

  const handleCloseModalUpdatePassword = useCallback(() => {
    setShowUpdatePasswordModal(false)
  }, [])

  const handleLogin = useCallback(
    (data: { username: string; password: string }) => {
      setCancelModalUpdatePassword(false)
      setCurrentStateLogin({ email: data.username, password: data.password })
      mutateLogin(data)
    },
    [mutateLogin],
  )

  useEffect(() => {
    if (!isLoadingChangePassword && errorChangePassword) {
      showSnackbarError(
        'Não foi possível alterar sua senha. Tente novamente ou entre em contato com a equipe da Lekto.',
      )
    }
  }, [errorChangePassword, isLoadingChangePassword, showSnackbarError])

  useEffect(() => {
    if (!isLoadingChangePassword && isSuccessChangePassword) {
      showSnackbarSuccess('Senha alterada com sucesso.')
      setShowUpdatePasswordModal(false)
    }
  }, [authenticatedUser, isLoadingChangePassword, isSuccessChangePassword, navigate, showSnackbarSuccess])

  useEffect(() => {
    if (
      !isLoadingLogin &&
      errorLogin?.response?.data?.required_actions?.length &&
      errorLogin?.response?.data?.required_actions[0] === 'UPDATE_PASSWORD' &&
      !showUpdatePasswordModal &&
      isError &&
      !cancelModalUpdatePassword
    ) {
      setCancelModalUpdatePassword(true)
      setShowUpdatePasswordModal(true)
    }

    if (!isLoadingLogin && !errorLogin?.response?.data?.required_actions && isError) {
      setCurrentStateLogin({ email: '', password: '' })
      showSnackbarError('Usuário ou senha inválidos. Tente novamente ou entre em contato com a equipe Lekto.')
    }
  }, [isLoadingLogin, errorLogin, showSnackbarError, showUpdatePasswordModal, isError, cancelModalUpdatePassword])

  useEffect(() => {
    if (!isLoadingForgotPassword && showModalForgotPassword && isSuccessForgotPassword && valid) {
      setShowModalUpdatePasswordConfirm(true)
      setShowModalForgotPassword(false)
      setValid(false)
    }
  }, [isLoadingForgotPassword, isSuccessForgotPassword, showModalForgotPassword, showSnackbarSuccess, valid])

  const handleShowPassword = useCallback(() => setShowPassword(true), [])

  const handleHidePassword = useCallback(() => setShowPassword(false), [])

  const handleOpenModalForgotPassword = useCallback(() => setShowModalForgotPassword(true), [])

  const handleCloseModalForgotPassword = useCallback(() => setShowModalForgotPassword(false), [])

  const handleCloseModalUpdatePasswordConfirm = useCallback(() => {
    setShowModalUpdatePasswordConfirm(false)
    setCurrentStateLogin({ email: '', password: '' })
  }, [])

  const loginPageProviderValues = useMemo(() => {
    return {
      showUpdatePasswordModal,
      handleCloseModalUpdatePassword,
      currentStateLogin,
      isLoadingChangePassword,
      handleChangePassword,
      showPassword,
      showModalForgotPassword,
      showModalUpdatePasswordConfirm,
      isLoadingLogin,
      handleLogin,
      isLoadingForgotPassword,
      handleForgotPassword,
      handleValidInput,
      handleShowPassword,
      handleHidePassword,
      handleOpenModalForgotPassword,
      handleCloseModalForgotPassword,
      handleCloseModalUpdatePasswordConfirm,
    }
  }, [
    showUpdatePasswordModal,
    handleCloseModalUpdatePassword,
    currentStateLogin,
    isLoadingChangePassword,
    handleChangePassword,
    showPassword,
    showModalForgotPassword,
    showModalUpdatePasswordConfirm,
    isLoadingLogin,
    handleLogin,
    isLoadingForgotPassword,
    handleForgotPassword,
    handleShowPassword,
    handleHidePassword,
    handleOpenModalForgotPassword,
    handleCloseModalForgotPassword,
    handleCloseModalUpdatePasswordConfirm,
  ])

  return <LoginPageContext.Provider value={loginPageProviderValues}>{children}</LoginPageContext.Provider>
}

const useLoginPageContext = () => useContext(LoginPageContext)

export { LoginPageProvider, useLoginPageContext }
