import { createContext, useCallback, useContext, useMemo } from 'react'

import { useOfflineMode } from '~/_context/Offline'

import { dateOptions } from './default.states'
import { IHomePageContextProps, IHomePageProviderProps } from './interfaces'

const HomePageContext = createContext<IHomePageContextProps>({
  extensiveCurrentDay: '',
  getOfflineInformation: [],
  disableOfflineMode: () => Object,
})

const HomePageProvider = ({ children }: IHomePageProviderProps) => {
  const { getOfflineInformation, disableOfflineMode } = useOfflineMode()

  const upperCaseFirstLetter = useCallback((date: string) => date.charAt(0).toUpperCase() + date.slice(1), [])
  const extensiveCurrentDay = upperCaseFirstLetter(new Date().toLocaleDateString('pt-br', dateOptions))

  const homePageProviderValues = useMemo(() => {
    return {
      extensiveCurrentDay,
      getOfflineInformation,
      disableOfflineMode,
    }
  }, [disableOfflineMode, extensiveCurrentDay, getOfflineInformation])

  return <HomePageContext.Provider value={homePageProviderValues}>{children}</HomePageContext.Provider>
}

const useHomePageContext = () => useContext(HomePageContext)

export { HomePageProvider, useHomePageContext }
