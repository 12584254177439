import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { colorsOpacityStepsAssessment, colorsStepsAssessment } from '~/validations'

export const NavContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '94px 24px 20px 24px',
  marginTop: '-70px',
  zIndex: 100,
  backgroundColor: '#fff',
}))

export const HeaderLoading = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}))

export const Content = styledMUI(Box)(() => ({
  padding: '24px 24px',
}))

export const ContentOrientations = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const ContentDocumentation = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '24px',
}))

export const ContentAvaliar = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const ContentInfos = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '24px',
  gap: '8px',
}))

export const ContentStepToStep = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '8px',
  gap: '8px',
  backgroundColor: '#E2E5EC',
  borderRadius: '64px',
}))

export const TextContentStepToStep = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '120%',
  color: '#7C8189',
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentStudents = styledMUI(Box)(() => ({
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  border: '1px dashed #BDC3CF',
  borderRadius: '8px',
}))

export const TextLabelEvidence = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const TextLabelStudents = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#7C8189',
  marginBottom: '12px',
}))

export const ListStudents = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  overflowX: 'auto',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  background: '#CCC',
  borderRadius: '50px',
}))

export const CardStudent = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '8px',
  borderRadius: '8px',
  backgroundColor: '#F9FAFC',
  cursor: 'grab',
}))

export const TextStudent = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentStepsAssessment = styledMUI(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2%',
}))

export const CardStepAssessment = styledMUI(Box)<{
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
}>(({ stepAssessment }) => ({
  minHeight: '150px',
  width: '49%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  gap: '8px',
  padding: '16px',
  marginBottom: stepAssessment === 'toDevelop' || stepAssessment === 'inEarlyDevelopment' ? '2%' : null,
  backgroundColor: colorsStepsAssessment(stepAssessment),
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#FFF',
}))

export const ContentDrop = styledMUI(Box)<{
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
  avatars: boolean
}>(({ stepAssessment, avatars }) => ({
  minHeight: '88px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: !avatars && 'center',
  alignItems: !avatars && 'center',
  padding: '8px',
  gap: '8px',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: colorsOpacityStepsAssessment(stepAssessment),
  borderRadius: '8px',
}))

export const TextDescriptionDrop = styledMUI(Typography)<{
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
}>(({ stepAssessment }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: colorsOpacityStepsAssessment(stepAssessment),
}))
