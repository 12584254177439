import { CustomDndProvider } from '~/components'

import { ListTrailsAvailablePageProvider } from './context'
import { ListTrailsAvailableClassView } from './view'

export const ListTrailsAvailableClassPage = () => {
  return (
    <ListTrailsAvailablePageProvider>
      <CustomDndProvider type={'group'}>
        <ListTrailsAvailableClassView />
      </CustomDndProvider>
    </ListTrailsAvailablePageProvider>
  )
}
