import { CustomDndProvider } from '~/components'

import { ProjectPageProvider } from './context'
import { SecondStageProjectView } from './view'

export const SecondStageProjectPageOffline = () => {
  return (
    <ProjectPageProvider>
      <CustomDndProvider>
        <SecondStageProjectView />
      </CustomDndProvider>
    </ProjectPageProvider>
  )
}
