import {
  AccordionOrientationsComponent,
  AppBarComponent,
  ContainerComponent,
  QuickNavigationComponent,
} from '~/components'

import { HeaderComponent } from '~/containers/private/Fund1/FirstStep'

import { useFirstStepPageContext } from './context'
import * as S from './styles'

export const FirstStepView = () => {
  const { activedTab, handleActiveNewTab, lesson, lessonSteps, firstStepGroup } = useFirstStepPageContext()

  const status = lesson?.coMomentStatus || ''
  const nuDuration = lessonSteps?.nuDuration

  const evidence = lessonSteps?.lessonActivity[0].lessonActivityEvidence[0].evidenceGrade.evidence

  const lessonOrientation = lessonSteps?.lessonActivity[0]?.lessonActivityOrientation

  const txTitle = lessonSteps?.txTitle

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            stepActived="firstStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            isOffline
          />
        </S.NavContainer>

        <>
          <HeaderComponent
            duration={nuDuration}
            activedTab={activedTab}
            avatars={firstStepGroup?.group}
            actionSetActiveTab={(newActivedTab: number) => handleActiveNewTab(newActivedTab)}
            evidence={evidence}
            status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
            title={txTitle || 'Revisar'}
          />
        </>

        <S.Content>
          {activedTab === 0 && (
            <S.ContentOrientations component="ul">
              {lessonOrientation?.map((orientation, index: number) => (
                <AccordionOrientationsComponent
                  key={index}
                  type="firstStep"
                  title={orientation?.txTitle}
                  txOrientationCode={orientation?.txOrientationCode}
                  supportReference={orientation?.lessonActivitySupportReference}
                />
              ))}
            </S.ContentOrientations>
          )}
        </S.Content>
      </ContainerComponent>
    </>
  )
}
