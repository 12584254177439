import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContentSkeleton = styledMUI(Box)(() => ({
  padding: '8px 24px',
  gap: '16px',
}))

export const Content = styledMUI(Box)(() => ({}))

export const ContentBack = styledMUI(Box)(() => ({
  padding: '24px 24px 0',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const WrapperBncc = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '16px',
}))

export const Wrapper = styledMUI(Box)(() => ({
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 24px',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'grid',
    gap: '8px',
    padding: '24px',
  },
}))
export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const ContentDescription = styledMUI(Box)(() => ({
  padding: '24px',
}))

export const ContentNavigation = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '24px',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: '1px solid #0095ff',
  backgroundColor: '#fff',
  padding: 0,
}))
