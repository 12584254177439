import React, { createContext, useContext, useEffect, useState } from 'react'

import { HubConnection, HubConnectionState } from '@microsoft/signalr'

import { createSignalRConnection } from '~/services/SignalR/SignalR'

import { INotification } from '~/components/ui/NotificationsMenu/NotificationsMenu.interface'

interface WebSocketContextType {
  connection: HubConnection | null
  notifications: INotification[]
  setNotifications: React.Dispatch<React.SetStateAction<any[]>>
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined)

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [connection, setConnection] = useState<HubConnection | null>(null)
  const [notifications, setNotifications] = useState<any[]>([])
  const [isConnecting, setIsConnecting] = useState(false)

  const authToken = localStorage.getItem('@LEKTO:token')
  useEffect(() => {
    const connectToHub = async () => {
      if (!authToken || isConnecting || (connection && connection.state !== HubConnectionState.Disconnected)) {
        return
      }

      setIsConnecting(true)
      const hubConnection = createSignalRConnection()

      try {
        await hubConnection.start()
        hubConnection.on('ReceiveNotifications', (message) => {
          setNotifications((prevNotifications) => {
            const notificationExists = prevNotifications.some(
              (notification) => notification.idNotification === message.idNotification,
            )
            if (!notificationExists) {
              return [message, ...prevNotifications]
            }
            return prevNotifications
          })
        })
        setConnection(hubConnection)
      } catch (err) {
        /* console.error('Erro ao conectar ao WebSocket', err) */
      } finally {
        setIsConnecting(false)
      }
    }

    connectToHub()

    return () => {
      if (connection) {
        connection.stop()
      }
    }
  }, [connection, isConnecting, authToken])

  return (
    <WebSocketContext.Provider value={{ connection, notifications, setNotifications }}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocket = () => {
  const context = useContext(WebSocketContext)
  if (context === undefined) {
    throw new Error('useWebSocket deve ser usado dentro de um WebSocketProvider')
  }
  return context
}
