import styled from 'styled-components'

interface IArrowProps {
  enabled: boolean
}

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.highPure};
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;
`
export const ContainerLesson = styled.div`
  padding: 24px;
  display: grid;
  gap: 16px;
`

export const ContainerCardsList = styled.ul`
  width: 100%;
  gap: 16px;
  padding: 8px;
  display: flex;
  overflow-y: auto;
`

export const CardProject = styled.li<{ bgColor: string }>`
  min-width: 260px;
  max-width: 260px;
  padding: 16px;
  list-style: none;
  border-radius: 8px;
  height: fit-content;
  background-color: ${({ bgColor }) => bgColor};

  .divider {
    margin: 16px 0;
  }
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardIndex = styled.div<{ color: string }>`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  display: flex;
  border-radius: 24px;
  background-color: ${({ color }) => color};
`

export const CardTime = styled.div<{ color: string }>`
  color: ${({ color }) => color};

  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 700;
`

export const InfoContainer = styled.div`
  display: grid;
  gap: 16px;
`

export const CardStatus = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 100%;
  }
`

export const CardProjectTitle = styled.h2<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 20px;
`

export const CardProjectDescription = styled.p`
  display: -webkit-box;
  font-size: 14px;
  line-height: 150%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CardTeacher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 700;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.lowLight};
  }
`

export const CardSlider = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.midLight};
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  cursor: pointer;
`

export const MomentTime = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.actionPure};
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.actionLight};
  border-radius: 4px;
`

export const TimeStatusDiv = styled.div`
  display: flex;
  gap: 8px;
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TitleInfo = styled.div`
  display: flex;
  gap: 12px;
`

export const CardPink = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  gap: 16px;
  cursor: pointer;
`

export const Arrow = styled.div<IArrowProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.actionPure : theme.colors.midMedium)};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.highPure};
  border-radius: 50%;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'normal')};
`

export const ArrowButton = styled.button<IArrowProps>`
  float: left;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.actionPure : theme.colors.midMedium)};
  width: 25.42px;
  height: 24px;
  color: #fff;
  border-radius: 50%;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
`
export const TittleBefore = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`

export const CardArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.midMedium};
`

export const TextParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
`

export const CardInfo = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const Avatars = styled.div`
  justify-self: flex-end;
  align-self: flex-end;
`
