import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useServiceGetLesson } from '~/services/Lesson'
import { useLessonStepInformation, useLessonStepInformationGroup } from '~/services/LessonSteps'

import { initialDataState } from '~/pages/private/Fund1/default.state'
import { initialDataActivityGroup } from '~/pages/private/Fund1/SecondGeneralStep/default.states'

import { ISecondStepPageContextProps, ISecondStepPageProviderProps } from './interfaces'

const SecondStepPageContext = createContext<ISecondStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  isLoading: false,
  activities: initialDataState.lessonActivity,
  data: initialDataState,
  idLessonMoment: 0,
  idLesson: 0,
  idClass: 0,
  dataActivityGroup: initialDataActivityGroup,
  isLoadingActivityGroup: false,
  isSameUser: false,
  isClassInStatus: true,
})

const SecondStepPageProvider = ({ children }: ISecondStepPageProviderProps) => {
  const { idLessonMoment, idLesson, idClass } = useParams()
  const { user } = useAuth()

  const [activedTab, setActivedTab] = useState<number>(0)

  const { data, isLoading } = useLessonStepInformation(Number(idLessonMoment), Number(idLesson), 2)
  const { data: dataModal } = useServiceGetLesson(Number(idLessonMoment))

  const idUserProfessor = dataModal?.idUserProfessor
  const isClassInStatus = dataModal?.class?.inStatus
  const isSameUser = user?.id === idUserProfessor

  const { data: dataActivityGroup, isLoading: isLoadingActivityGroup } = useLessonStepInformationGroup(
    Number(idLessonMoment),
    Number(idLesson),
  )

  const filteredActivities = useMemo(() => {
    const lessonActivityIds = dataActivityGroup?.lessonActivity?.map((itemStudents) => itemStudents.idLessonActivity)
    return data?.lessonActivity?.filter((item) => lessonActivityIds?.includes(item.idLessonActivity)) || []
  }, [data, dataActivityGroup])

  const activitiesAGEN = data?.lessonActivity

  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const secondStepPageProviderValues = useMemo(() => {
    return {
      activedTab,
      handleActiveNewTab,
      isLoading,
      data: data || initialDataState,
      dataModal,
      isSameUser,
      idClass,
      idLessonMoment: Number(idLessonMoment),
      idLesson: Number(idLesson),
      dataActivityGroup: dataActivityGroup || initialDataActivityGroup,
      isLoadingActivityGroup,
      activities: filteredActivities || initialDataState.lessonActivity,
      activitiesAGEN,
      isClassInStatus,
    }
  }, [
    filteredActivities,
    isSameUser,
    isLoadingActivityGroup,
    dataActivityGroup,
    idLessonMoment,
    dataModal,
    idClass,
    idLesson,
    data,
    activedTab,
    handleActiveNewTab,
    isLoading,
    activitiesAGEN,
    isClassInStatus,
  ])

  return (
    <SecondStepPageContext.Provider value={secondStepPageProviderValues}>{children}</SecondStepPageContext.Provider>
  )
}

const useSecondStepPageContext = () => useContext(SecondStepPageContext)

export { SecondStepPageProvider, useSecondStepPageContext }
