import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { gaEvents } from '~/events'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useAvailableTrail, useDetailTrails, useLesson } from '~/services/Trails'

import { GET_CLASS_INFOS_BY_CLASSID } from '../ListTrailsProject/schemas.queries'
import { defaultDataClassInfosState, defaultDataTrailsClassState, defaultDataTrailState } from './default.states'
import { ITrailDetailsClassPageContextProps, ITrailDetailsClassPageProviderProps, LessonDetail } from './interfaces'

const ListClassesPageContext = createContext<ITrailDetailsClassPageContextProps>({
  openModalBNCC: false,
  handleOpenModalBNCC: () => Object,
  handleCloseModalBNCC: () => Object,
  openModalSucess: false,
  handleOpenModalSucess: () => Object,
  handleCloseModalSucess: () => Object,
  openModalSchedule: false,
  handleOpenModalSchedule: () => Object,
  handleCloseModalSchedule: () => Object,
  isLoadingTrailsDetails: false,
  dataTrailsClass: defaultDataTrailsClassState,
  dataClassInfos: defaultDataClassInfosState,
  dataTrail: defaultDataTrailState,
  isLoadingTrails: false,
  isLoadingClassInfos: false,
  coStage: '',
  txGrade: '',
  txClassShift: '',
  track: '',
  txSchoolGradeName: '',
  setLesson: () => Object,
  dataLesson: [],
  isLoadingLesson: false,
  setLessonDetail: () => Object,
  lessonDetail: [],
  isLoadingDetail: false,
  dataDetail: [],
  handleBack: () => Object,
  setLessonDetailStepTwo: () => Object,
  dataDetailStepTwo: [],
  lessonDetailStepTwo: [],
  isLoadingDetailStepTwo: false,
})

const TrailDetailsClassPageProvider = ({ children }: ITrailDetailsClassPageProviderProps) => {
  const navigate = useNavigate()
  const { classId, trailId } = useParams()

  const [openModalBNCC, setOpenModalBNCC] = useState<boolean>(false)
  const [openModalSchedule, setOpenModalSchedule] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [lesson, setLesson] = useState(null)
  const [lessonDetail, setLessonDetail] = useState<LessonDetail | null>()
  const [lessonDetailStepTwo, setLessonDetailStepTwo] = useState<LessonDetail | null>()

  const { isLoading: isLoadingTrailsDetails, data: dataTrailsClass } = useAvailableTrail(
    Number(classId),
    Number(trailId),
    true,
  )

  const { data: dataClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(classId),
      showDisabledClasses: true,
    },
  )

  const coStage = dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage
  const track = dataTrailsClass
  const trackId = dataTrailsClass?.idTrack
  const txGrade = dataClassInfos?.class.items?.[0]?.schoolGrade?.grade?.txGrade
  const txClassShift = dataClassInfos?.class.items?.[0]?.classShift?.txClassShift
  const txSchoolGradeName = dataClassInfos?.class.items?.[0]?.txName

  const { isLoading: isLoadingDetail, data: dataDetail } = useDetailTrails(
    Number(lesson?.idLesson),
    lessonDetail?.nuOrder,
    !!lessonDetail?.idLessonStep && !!lesson,
  )
  const { isLoading: isLoadingDetailStepTwo, data: dataDetailStepTwo } = useDetailTrails(
    Number(lesson?.idLesson),
    lessonDetailStepTwo?.nuOrder,
    !!lessonDetailStepTwo?.idLessonStep && !!lesson,
  )
  const { isLoading: isLoadingLesson, data: dataLesson } = useLesson(Number(lesson?.idLesson), !!lesson?.idLesson)

  const handleBack = useCallback(() => {
    setLessonDetail(null)
    setLessonDetailStepTwo(null)
  }, [])

  const handleOpenModalBNCC = useCallback(() => {
    setOpenModalBNCC(true)
    gaEvents.eventBnccButton()
  }, [])

  const handleCloseModalBNCC = useCallback(() => {
    setOpenModalBNCC(false)
  }, [])

  const handleOpenModalSchedule = useCallback(() => {
    setOpenModalSchedule(true)
    gaEvents.eventSelectNewTrailButton()
  }, [])

  const handleCloseModalSchedule = useCallback(() => {
    setOpenModalSchedule(false)
  }, [])

  const handleOpenModalSucess = useCallback(() => {
    setOpenModalSucess(true)
  }, [])

  const handleSchedule = useCallback(() => {
    if (classId && trackId) {
      navigate(`/schedule/${classId}/track/${trackId}`)
    }
  }, [classId, trackId, navigate])

  const trailDetailsClassPageProviderValues = useMemo(() => {
    return {
      openModalBNCC,
      handleOpenModalBNCC,
      handleCloseModalBNCC,
      openModalSucess,
      handleOpenModalSucess,
      handleSchedule,
      openModalSchedule,
      handleOpenModalSchedule,
      handleCloseModalSchedule,
      isLoadingTrailsDetails,
      dataTrailsClass,
      dataClassInfos,
      txSchoolGradeName,
      coStage,
      txGrade,
      txClassShift,
      track,
      setLesson,
      dataLesson,
      isLoadingLesson,
      setLessonDetail,
      lessonDetail,
      isLoadingDetail,
      dataDetail,
      setLessonDetailStepTwo,
      lessonDetailStepTwo,
      isLoadingDetailStepTwo,
      dataDetailStepTwo,
      handleBack,
    }
  }, [
    openModalBNCC,
    handleOpenModalBNCC,
    handleCloseModalBNCC,
    openModalSucess,
    handleOpenModalSucess,
    handleSchedule,
    openModalSchedule,
    handleOpenModalSchedule,
    handleCloseModalSchedule,
    isLoadingTrailsDetails,
    dataTrailsClass,
    dataClassInfos,
    coStage,
    track,
    txGrade,
    txClassShift,
    txSchoolGradeName,
    setLesson,
    dataLesson,
    isLoadingLesson,
    setLessonDetail,
    lessonDetail,
    isLoadingDetail,
    dataDetail,
    setLessonDetailStepTwo,
    lessonDetailStepTwo,
    isLoadingDetailStepTwo,
    dataDetailStepTwo,
    handleBack,
  ])

  return (
    <ListClassesPageContext.Provider value={trailDetailsClassPageProviderValues}>
      {children}
    </ListClassesPageContext.Provider>
  )
}

const useTrailDetailsClassPageContext = () => useContext(ListClassesPageContext)

export { TrailDetailsClassPageProvider, useTrailDetailsClassPageContext }
