import React from 'react'

import { UilArrowDown } from '@iconscout/react-unicons'

import { DividerComponent, IconComponent } from '~/components'

import CardCarousel from '../Carrousell/CardCarousel'
import * as S from './StepAccordion.styles'

interface OrientationItem {
  txTitle?: string
  txOrientationCode: string
  lessonActivitySupportReference?: LessonActivitySupportReference[]
}

interface LessonActivitySupportReference {
  mediaInformation: {
    txAbsoluteUrl: string
    txName: string
  }
}

interface CustomHtmlProps {
  htmlContent: string
}

interface Props {
  title: string
  orientationItems: OrientationItem[]
  supportReferences: LessonActivitySupportReference[]
  lessonActivity?: any
  type: 'default' | 'firstStep' | 'secondStep' | 'thirdStep'
  accordionRef: React.RefObject<HTMLDivElement>
  key: string
  txGuidance?: string
  expaded?: boolean
  onToggle?: () => void
}

export const StepAccordion = ({
  title,
  txGuidance,
  orientationItems,
  type,
  accordionRef,
  lessonActivity,
  key,
  supportReferences,
  expaded = true,
  onToggle,
}: Props) => {
  const CustomHtml: React.FC<CustomHtmlProps> = ({ htmlContent }) => {
    const modifiedHtml = htmlContent.replace(/color: rgb\(\d+, \d+, \d+\)/g, 'color: black')

    return <S.AccordionText dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
  }

  return (
    <S.AccordionContainer key={key} expanded={expaded} onChange={onToggle} ref={accordionRef}>
      <S.AccordionHeader
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <S.AccordionTitle type={type}>{title}</S.AccordionTitle>
      </S.AccordionHeader>
      <DividerComponent />
      {type === 'secondStep' && txGuidance ? (
        <CustomHtml htmlContent={String(txGuidance)} />
      ) : (
        <>
          {orientationItems.map((item: OrientationItem, index: number) => (
            <React.Fragment key={index}>
              <S.StepOrientationContainer>
                <S.AccordionTitle type={type}>{item.txTitle}</S.AccordionTitle>
                <CustomHtml htmlContent={String(item.txOrientationCode)} />
                {item.lessonActivitySupportReference && item.lessonActivitySupportReference.length > 0 && (
                  <S.ReferenceContainer>
                    <DividerComponent />
                    <S.AccordionTitle type={type} className={'reference'}>
                      Referências de Apoio
                    </S.AccordionTitle>
                    <CardCarousel key={index} items={item.lessonActivitySupportReference} type={type} />
                  </S.ReferenceContainer>
                )}
              </S.StepOrientationContainer>
              <DividerComponent />
            </React.Fragment>
          ))}
        </>
      )}
      {type === 'secondStep' &&
        lessonActivity?.map((activity: any) => (
          <S.ActivityContainer key={activity.idLessonActivity}>
            <S.AccordionTitle type={'secondStep'}>
              <IconComponent
                color="card"
                fontWeight="normal"
                code={activity.categoryGrade?.category?.txImagePath || activity.category.txImagePath}
                size="xsmall"
              />
              {activity.categoryGrade?.category?.txName || activity.category.txName} - {activity.txTitle}
            </S.AccordionTitle>
            {activity?.lessonActivityOrientation?.map((item: any, index: number) => (
              <>
                <S.StepOrientationContainer>
                  <S.AccordionTitle type={'secondStep'}>{item.txTitle}</S.AccordionTitle>
                  <CustomHtml htmlContent={String(item.txOrientationCode)} />
                  {item?.lessonActivitySupportReference?.length > 0 && (
                    <S.ReferenceContainer>
                      <DividerComponent />

                      <S.AccordionTitle type={'secondStep'} className={'reference'}>
                        Referências de Apoio
                      </S.AccordionTitle>

                      <CardCarousel key={index} items={item.lessonActivitySupportReference} type={type} />
                    </S.ReferenceContainer>
                  )}
                </S.StepOrientationContainer>
                <DividerComponent />
              </>
            ))}
          </S.ActivityContainer>
        ))}
    </S.AccordionContainer>
  )
}
