import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const StatusTextContainer = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  textWrap: 'nowrap',
  gap: '4px',
}))

export const TextStatus = styledMUI(Typography, {
  shouldForwardProp: (prop) => prop !== 'activeTextOverflow',
})<{
  color: string
  size?: string
  activeTextOverflow: boolean
}>(({ color, size, activeTextOverflow }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: size ? size : '14px',
  lineHeight: '100%',
  color: color,
  overflowX: activeTextOverflow ? 'clip' : 'visible',
  maxWidth: activeTextOverflow ? '122px' : 'none',
  textOverflow: activeTextOverflow ? 'ellipsis' : 'none',
}))
