import { useState } from 'react'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ICompetence } from '~/services/Dashboard/types'

import Tooltip from '~/containers/private/StudentDashboard/OverviewBySkill/Tooltip'

import { formatLabel } from '../OverviewByCompetence/OverviewByCompetence'
import * as S from './OverviewBySkill.styles'
import { BorderLinearProgress } from './OverviewBySkill.styles'

interface OverviewBySkillProps {
  competences: ICompetence[] | ICompetence
  type?: 'card' | 'default'
}

export const OverviewBySkillComponent = ({ competences, type = 'default' }: OverviewBySkillProps) => {
  const competenceArray = Array.isArray(competences) ? competences : [competences]
  const { width } = useWindowDimensions()

  const isMobile = width && width < 768

  const [activeAbility, setActiveAbility] = useState(null)
  const [clickedAbilities, setClickedAbilities] = useState({})

  const handleItemClick = (abilityId: number) => {
    document.querySelectorAll(`.ability-item`).forEach((item) => {
      if (item.classList.contains('clicked') && !item.classList.contains(`ability-${abilityId}`)) {
        item.classList.remove('clicked')
      }
    })

    if (!clickedAbilities[abilityId]) {
      setClickedAbilities((prevState) => ({
        ...prevState,
        [abilityId]: true,
      }))
    }

    if (abilityId === activeAbility) {
      setActiveAbility(null)
    } else {
      setActiveAbility(abilityId)
    }
  }

  const competenceFormatted = competenceArray.map((item) => ({
    ...item,
    totalCompetenceAssessment: item?.totalCompetenceAssessment === undefined ? 0 : item.totalCompetenceAssessment,
    abilities: item?.abilities?.map((ability) => ({
      ...ability,
      abilityAverage: ability.abilityAverage === undefined ? 0 : ability.abilityAverage,
    })),
  }))

  if (isMobile)
    return (
      <>
        {competenceFormatted?.length && (
          <S.MobileContainer>
            {competenceFormatted.map((competence) =>
              competence.abilities.map((ability) => (
                <S.MobileItem key={ability.idAbility}>
                  <S.MobileItemHeader>
                    <S.MobileTitle>{ability.abilityName}</S.MobileTitle>
                    <S.StatusTooltip>{formatLabel(ability.abilityAverage)}</S.StatusTooltip>
                  </S.MobileItemHeader>
                  <BorderLinearProgress
                    backgroundColor="#E2E5EC"
                    variant="determinate"
                    competenceColor={competence.competenceColor}
                    value={ability.abilityAverage === 0 ? 1 : ability.abilityAverage}
                  />
                </S.MobileItem>
              )),
            )}
          </S.MobileContainer>
        )}
      </>
    )

  return (
    <>
      {competenceFormatted?.length ? (
        <S.Table>
          <S.TableHeader type={type}>
            <tr>
              <th></th>
              <th>A desenvolver</th>
              <th>Em início de desenvolvimento</th>
              <th>Em desenvolvimento</th>
              <th>Desenvolvido</th>
            </tr>
          </S.TableHeader>
          <S.TableBody type={type}>
            {competenceFormatted.map((competence) =>
              competence?.abilities?.map((ability: any) => (
                <S.Tr
                  className={`fade-in ${ability.idAbility === activeAbility ? 'active' : ''} ${
                    clickedAbilities[ability.idAbility] ? 'clicked' : ''
                  } `}
                  key={ability.idAbility}
                  type={type}
                  onClick={() => handleItemClick(ability.idAbility)}
                >
                  <td className={`skill `}>{ability.abilityName}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan={4} className={ability.abilityAverage === 0 ? 'barEmpty' : 'bar'}>
                    <Tooltip
                      key={ability.idAbility}
                      competence={competence}
                      abilityName={ability.abilityName}
                      isVisible={ability.idAbility === activeAbility}
                      assessmentAvarage={ability.abilityAverage === 0 ? 1 : ability.abilityAverage}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        competenceColor={competence.competenceColor}
                        value={ability.abilityAverage === 0 ? 1 : ability.abilityAverage}
                      />
                      {ability.abilityAverage === 0 && <p>Não observado</p>}
                    </Tooltip>
                  </td>
                </S.Tr>
              )),
            )}
          </S.TableBody>
        </S.Table>
      ) : (
        <h1>Skeleton</h1>
      )}
    </>
  )
}
