import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { IApiError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

import { Documentation, IGetLessonDocumentationActivity } from '~/services/Documentation/types'

import { Documents } from '~/components/Modals/ModalDocumentation/ModalDocumentation.interfaces'

interface IVariablesDesengageTrail {
  body: FormData
  lessonId: number
}

interface IVariablesUpdateDocumentation {
  body: FormData
  idLessonDocumentation: number
}

export const getDocumentationInformation = async (idLessonMoment: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment/evidence/${idLessonMoment}`)

  return data
}

export const useServiceGetDocumentationInformation = (idLessonMoment: number, enabled?: boolean) =>
  useQuery<Documents>(['GetDocumentationInformation'], () => getDocumentationInformation(idLessonMoment), {
    enabled: enabled,
  })

export const sendDocumentation = async (prop: any) => {
  const { data } = await HTTPClient.post(
    `/moment/class/${prop.idClass}/by-moment/${prop.idMoment}/documentation`,
    prop.body,
  )

  return data
}
export const useServiceEngageTrail = () =>
  useMutation<any, IApiError, IVariablesDesengageTrail>(['DesengageTrail'], sendDocumentation)

export const editDocumentation = async (prop: any) => {
  const { data } = await HTTPClient.put(
    `/moment/class/${prop.idClass}/by-moment/${prop.idMoment}/documentation/${prop.idDocumentation}`,
    prop.body,
  )

  return data
}

const getDocumentation = async (idClass: number, idMoment: number, pageNumber?: number, pageSize?: number) => {
  const { data, headers } = await HTTPClient.get(
    `/moment/class/${idClass}/by-moment/${idMoment}/documentations${
      pageNumber ? `?pageNumber=${1}&pageSize=${pageSize}` : ``
    }`,
  )
  return { data, headers }
}

export const useGetDocumentation = (
  idClass: number,
  idMoment: number,
  pageNumber?: number,
  pageSize?: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<Documentation>(
    ['getDocumentation'],
    () => getDocumentation(idClass, idMoment, pageNumber, pageSize),
    options,
  )

export const deleteDocumentation = async (prop: any) => {
  const { data } = await HTTPClient.delete(
    `/moment/class/${prop.idClass}/by-moment/${prop.idMoment}/documentation/${prop.idMomentDocumentation}`,
  )
  return data
}
export const useServiceDeleteDocumentation = (options?: Omit<UseMutationOptions<any>, 'queryKey' | 'queryFn'>) =>
  useMutation<
    any,
    any,
    {
      idClass: number
      idMoment: number
      idMomentDocumentation: number
    },
    any
  >(['DeleteDocumentation'], deleteDocumentation, options)

interface IVariablesSendDocumentation {
  body: any
  idMoment: number
  idClass: number
}

interface IVariablesUpdateDocumentation {
  body: any
  idDocumentation: number
  idClass: number
  idMoment: number
}

export const useServiceSendDocumentation = () =>
  useMutation<any, IApiError, IVariablesSendDocumentation>(['SendDocumentation'], sendDocumentation)

export const useServiceUpdateDocumentation = (options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) =>
  useMutation<any, IApiError, IVariablesUpdateDocumentation>(['EditDocumentation'], editDocumentation, options)

export const sendProjectDocumentation = async (prop: any) => {
  const { data } = await HTTPClient.post(`/project-documentation/for-moment/${prop.projectId}`, prop.body)

  return data
}
export const useServiceProjectDocumentation = () => useMutation(['ProjectDoc'], sendProjectDocumentation)

export const editProjectDocumentation = async (prop: any) => {
  const { data } = await HTTPClient.put(`/project-documentation/${prop.idProjectDocumentation}`, prop.body)

  return data
}
export const useServiceEditProjectDocumentation = () => useMutation(['ProjectDoc'], editProjectDocumentation)

export const removeProjectDocumentation = async (idProjectDocumentation: number) => {
  const { data } = await HTTPClient.delete(`/project-documentation/${idProjectDocumentation}`)

  return data
}

export const useServiceRemoveProjectDocumentation = (
  options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>,
) => useMutation(['RemoveProjectDoc'], removeProjectDocumentation, options)

const getLessonDocumentationActivity = async (idLessonMoment: number, idLessonActivity: number) => {
  const { data } = await HTTPClient.get(
    `/lesson-documentation/by-moment/${idLessonMoment}/activity/${idLessonActivity}`,
  )

  return data
}
export const useGetLessonDocumentationActivity = (idLessonMoment: number, idLessonActivity: number) =>
  useQuery<IGetLessonDocumentationActivity>(['GetLessonDocumentationActivity'], () =>
    getLessonDocumentationActivity(idLessonMoment, idLessonActivity),
  )
