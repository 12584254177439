import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CircularProgress } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import {
  AccordionOrientationsComponent,
  AppBarComponent,
  AssessmentComponent,
  CardDocumentationComponent,
  ContainerComponent,
  ModalDocumentationComponent,
  ModalStudentManagementComponent,
  QuickNavigationComponent,
  ValidateDataSessionComponent,
} from '~/components'
import { AlertAssessment } from '~/components/Forms/Assessment/Assessment.styles'

import { HeaderStepComponent } from '~/containers/private/Fund1/SecondStep'

import { useSecondStepPageContext } from './context'
import * as S from './styles'

export const SecondStepView = () => {
  const { idLessonMoment, idLesson, idLessonActivity } = useParams()
  const {
    data,
    isLoadingDocumentation,
    mutateAssessment,
    mutateAssessmentBatch,
    isLoadingAssessment,
    isLoadingAssessmentBatch,
    isLoading,
    dataDocumentationFiltered,
    filteredActivityGroup,
    dataEvidence,
    dataStudentsGroup,
    dataModal,
    refetch,
    isSameUser,
    idClass,
    isClassInStatus,
  } = useSecondStepPageContext()

  const queryClient = useQueryClient()

  const [activedTab, setActivedTab] = useState<number>(0)

  const handleAssessment = (id: string, coAssessment: string, idEvidence: string) => {
    mutateAssessment({
      body: { idMomentGroup: id, coAssessment, idEvidence },
      idLessonMoment: String(idLessonMoment),
      idClass: String(idClass),
    })
  }

  const handleAssessmentBatch = (body: { idLessonMoment: string }) => {
    body.idLessonMoment = String(idLessonMoment)
    body.idClass = String(idClass)
    mutateAssessmentBatch(body)
  }

  const [showModalStudentManagement, setShowModalStudentManagement] = useState(false)
  const handleCloseModalStudentManagement = () => setShowModalStudentManagement(false)

  const handleOpenModalStudentManagement = () => {
    if (dataModal) {
      setShowModalStudentManagement(true)
    }
  }

  useEffect(() => {
    queryClient.invalidateQueries('getStudentsGroupLesson').then()
  }, [activedTab, queryClient])

  const step = data

  const status = data.momentStatus.coMomentStatus

  const isFinished = status === 'FINA'

  const idLessonMomentActivity = filteredActivityGroup?.idLessonMomentActivity

  const avatars =
    dataStudentsGroup?.group?.filter(
      (item: { idLessonMomentActivity: number | undefined }) => item.idLessonMomentActivity === idLessonMomentActivity,
    ) || []

  const lessonActivity = dataEvidence?.lessonActivityById[0]?.lessonStep?.lessonActivity?.find(
    (activity: any) => activity.idLessonActivity === Number(idLessonActivity),
  )

  const evidences = lessonActivity?.lessonActivityEvidence

  const handleEvidence = (coEvidenceType: 'FIX' | 'VAR') => {
    return lessonActivity?.lessonActivityEvidence?.filter((ev: any) => ev.coEvidenceType === coEvidenceType)?.[0]
      ?.evidenceGrade?.evidence
  }

  const handleMediaList = (mediaList: any) => {
    return mediaList?.map((doc: any) => {
      return {
        ...doc,
        coMediaLocator: doc.mediaInformation.coMediaLocator,
        inPublic: doc.mediaInformation.inPublic,
        nuSize: doc.mediaInformation.nuSize,
        txAbsoluteUrl: doc.mediaInformation.txAbsoluteUrl,
        txContentType: doc.mediaInformation.txContentType,
        txName: doc.mediaInformation.txName,
        dtInserted: doc.mediaInformation.dtInserted,
      }
    })
  }

  const studentsFiltered = filteredActivityGroup?.lessonMomentGroup

  return (
    <>
      {isLoadingAssessment ||
        (isLoadingAssessmentBatch && (
          <AlertAssessment
            iconMapping={{
              info: <CircularProgress color="inherit" size={16} />,
            }}
            severity={'info'}
          >
            Estamos processando a sua avaliação.
          </AlertAssessment>
        ))}
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            idLessonActivity={Number(idLessonActivity)}
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            status={status}
          />
        </S.NavContainer>

        <HeaderStepComponent
          isLoading={isLoading}
          activedTab={activedTab}
          txTitle={step?.txTitle}
          avatars={avatars}
          nuOrder={data?.nuOrder}
          fixedSkill={handleEvidence('FIX')}
          variableSkill={handleEvidence('VAR')}
          txContext={step?.category?.txName}
          iconContext={step?.category?.txImagePath}
          actionSetActiveTab={(newActivedTab: number) => setActivedTab(newActivedTab)}
          txDescription={step.txChallenge}
          status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
          openModal={handleOpenModalStudentManagement}
          title={dataModal?.lesson?.lessonStep[1].txTitle ? dataModal?.lesson?.lessonStep[1].txTitle : 'Realizar'}
        />

        <S.Content>
          {activedTab === 0 && (
            <S.ContentOrientations>
              {step?.lessonActivityOrientation?.map((orientation, index: number) => (
                <AccordionOrientationsComponent
                  key={index}
                  type="secondStep"
                  title={orientation.txTitle}
                  txOrientationCode={orientation.txOrientationCode}
                  supportReference={orientation?.lessonActivitySupportReference}
                />
              ))}
            </S.ContentOrientations>
          )}

          {activedTab === 1 &&
            (dataDocumentationFiltered?.length > 0 ? (
              <S.ContentDocumentation component="ul">
                {dataDocumentationFiltered?.map((doc: any, index: number) => (
                  <CardDocumentationComponent
                    queriesKeys={['GetDocumentation']}
                    documentation={{
                      ...doc,
                      lessonDocumentationMediaList: handleMediaList(doc.lessonDocumentationMedia),
                      lessonEvidenceDocumentationList: doc.lessonEvidenceDocumentation,
                      lessonStudentDocumentationList: doc.lessonStudentDocumentation,
                    }}
                    isSameUser={isSameUser}
                    status={status}
                    key={index}
                    type="step"
                    isLoading={isLoadingDocumentation}
                    isProject={false}
                    isClassInStatus={isClassInStatus}
                  />
                ))}
              </S.ContentDocumentation>
            ) : (
              <ValidateDataSessionComponent
                space={1}
                height={50}
                screen="documentation"
                title="Nenhuma documentação cadastrada."
                description="Até o momento, nenhuma documentação foi cadastrada."
              />
            ))}

          {activedTab === 2 &&
            studentsFiltered &&
            idLessonMomentActivity &&
            evidences?.map((item: any, index: number) => {
              return (
                <AssessmentComponent
                  disabled={isFinished || !isSameUser || !isClassInStatus}
                  key={item.evidenceGrade.idEvidence}
                  handleDrop={handleAssessment}
                  handleDropBatch={handleAssessmentBatch}
                  txEvidenceName={item.evidenceGrade.evidence.ability.txName}
                  txEvidenceDescription={item.evidenceGrade.evidence.txName}
                  txCompentencyName={item.evidenceGrade.evidence.ability.competence.txName}
                  txIconSkill={item.evidenceGrade.evidence.ability.txImagePath}
                  txIconCompetency={item.evidenceGrade.evidence.ability.competence.txImagePath}
                  students={dataStudentsGroup}
                  idEvidence={item.evidenceGrade.idEvidence}
                  indexEvidence={index + 1}
                  lengthEvidence={evidences.length}
                  skillType={item.coEvidenceType}
                  type={'lesson'}
                  idActivity={idLessonMomentActivity}
                />
              )
            })}
        </S.Content>

        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && isSameUser && (
            <ModalDocumentationComponent
              idLessonMoment={Number(idLessonMoment)}
              idLesson={Number(idLesson)}
              variant="floater"
              queryKeys={['GetLessonDocumentationActivity']}
              isClassInStatus={isClassInStatus}
            />
          )}
        </>

        {dataModal && ['AGEN'].includes(!status) && (
          <ModalStudentManagementComponent
            openModal={showModalStudentManagement}
            actionCloseModal={handleCloseModalStudentManagement}
            dataClass={dataModal}
            classID={dataModal?.class?.idClass}
            idLessonMoment={Number(idLessonMoment)}
            type={'F1'}
            dataStudentsGroup={dataStudentsGroup}
            refetchStudents={refetch}
          />
        )}
      </ContainerComponent>
    </>
  )
}
