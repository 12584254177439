import { GetLessonMomentDocumentationsByLessonMomentIdQuery } from '~/queries/autogenerate/operations'

import { ILessonDocumentation } from './interfaces'

export const defaultDataState: ILessonDocumentation = {
  idLessonDocumentation: 0,
  nuOrder: 0,
  txTitle: '',
  txMomentNotes: '',
  evidence: [
    {
      txName: '',
      ability: {
        txName: '',
        txImagePath: '',
      },
    },
  ],
  student: [
    {
      txImagePath: '',
      txName: '',
    },
  ],
}

export const defaultDataDocumentation: GetLessonMomentDocumentationsByLessonMomentIdQuery = {
  lessonMomentDocumentationsByLessonMomentId: {
    lessonMomentDocumentation: null,
  },
}
