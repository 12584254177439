import React, { useState } from 'react'

import { UilArrowUpRight, UilArrowRight, UilArrowLeft } from '@iconscout/react-unicons'

import { colorsStepsActived } from '~/validations/colorsStepsActived'

import * as S from '../StepAccordion/StepAccordion.styles'

interface CardCarouselProps {
  items: unknown
  type: string
  total?: number
}

const CardCarousel: React.FC<CardCarouselProps> = ({ items, type, total = 4 }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = total
  const totalCards = items?.length

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (currentIndex < totalCards - itemsPerPage) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <S.ContentCards countItens={items?.length}>
      {totalCards >= itemsPerPage && (
        <S.PutStart>
          <S.Arrow onClick={handlePrevClick} enabled={currentIndex <= 0}>
            <UilArrowLeft size="20" />
          </S.Arrow>
        </S.PutStart>
      )}
      {items?.slice(currentIndex, currentIndex + itemsPerPage).map((reference, index) => (
        <S.CardLink
          key={index}
          to={reference?.mediaInformation?.txAbsoluteUrl || reference?.txAbsoluteUrl}
          target="_blank"
        >
          <S.Card type={type}>
            <UilArrowUpRight size={25} color={colorsStepsActived(type)} />
            <S.TextCard variant="caption" type={type}>
              {reference?.mediaInformation?.txName || reference?.txTitle}
            </S.TextCard>
          </S.Card>
        </S.CardLink>
      ))}
      {totalCards >= itemsPerPage && (
        <S.PutEnd>
          <S.Arrow onClick={handleNextClick} enabled={currentIndex >= totalCards - itemsPerPage}>
            <UilArrowRight size="20" />
          </S.Arrow>
        </S.PutEnd>
      )}
    </S.ContentCards>
  )
}

export default CardCarousel
