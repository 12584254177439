import styled, { css, keyframes } from 'styled-components'

import theme from '~/styles/theme'

export const ContainerTable = styled.div<{ isContentReady: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  ${({ isContentReady }) => css`
    visibility: ${isContentReady ? 'visible' : 'hidden'};
    max-height: ${isContentReady ? '600px' : '0'};
    @media (max-width: 1400px) {
      height: ${isContentReady ? '540px' : '0'};
    }
  `}
`

export const ButtonLoadContainer = styled.button`
  top: 80px;
  border: none;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: -webkit-fill-available;
  margin-right: 16px;
  background: transparent;
`

export const ButtonLoadFutureContainer = styled.button`
  bottom: 80px;
  border: none;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: -webkit-fill-available;
  margin-right: 16px;
  background: transparent;
`

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: block;
    text-align: center;
  }
`
export const ButtonLoadMore = styled.button`
  width: 220px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid ${theme.colors.actionPure};
  color: ${theme.colors.actionPure};
  background: ${theme.colors.highPure};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #e2e5ec;
  }

  tr:first-child th {
    border-top: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 20;

    th {
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      color: #7c8189;
      padding: 4px;
    }

    th:last-child {
      border-right: none;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e2e5ec; /* Add border between rows (horizontally) */
    }

    td {
      border: 1px solid #e2e5ec; /* Add border around each cell */
      border-radius: 10px;
    }

    .current-day {
    }
  }
`

export const MonthContainer = styled.div``

export const Container = styled.div`
  padding: 16px;
  border: 1px solid #0095ff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`
export const LoadingCalendar = styled.div`
  padding: 16px;
  border: 1px solid #0095ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  border-collapse: collapse;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
`

export const TitleLoading = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
`

export const Title = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
`

export const Td = styled.td`
  width: 80px;
  height: 88px;
  text-align: right;
  vertical-align: top;
`

export const DayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 4px;
  height: 24px;
  gap: 4px;

  .today {
    color: ${theme.colors.primaryPure};
    font-weight: 700;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: ${theme.colors.midDark};

    span {
      color: ${theme.colors.actionPure};
      font-weight: 400;
    }
  }
`

export const TrailContainer = styled.div`
  background-color: rgba(0, 149, 255, 0.16);
  width: 100%;
  margin-top: 4px;
  padding: 4px;
  min-height: 24px;

  &.first-lesson {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 100%;
  }

  &.last-lesson {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 96%;
  }

  &.disabled {
    background-color: transparent;
  }
`

export const TagContainer = styled.div<{ disabled: boolean }>`
  background-color: ${theme.colors.actionPure};
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 16px;
  padding: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: grab;
  background-color: ${({ disabled }) => (disabled ? theme.colors.midMedium : theme.colors.actionPure)};
`

export const LessonTag = styled.span`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: ${theme.colors.highPure};
`

export const CurrentDayTag = styled.div`
  font-size: 8px;
  font-weight: 700;
  background-color: ${theme.colors.primaryPure};
  padding: 2px;
  color: ${theme.colors.highPure};
  width: fit-content;
  border-radius: 2px;
`
const fadeOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 4px;
  cursor: pointer;
`

export const TitleYear = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #0095ff;
  transition: all 0.5s;

  &:hover {
    color: #0093ffd1;
  }

  line-height: 120%;
  margin-bottom: 8px;
`
export const TitleYearInside = styled.h3`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: #0095ff;
  z-index: 9999;
  transition: all 0.5s;

  &:hover {
    color: #0093ffd1;
  }

  opacity: 0.8;
  animation: ${fadeOut} 2s ease-in-out;
  line-height: 120%;
`

export const OtherClassTrail = styled.div<{
  isTrackDay: boolean
  isFirstTrackDay: boolean
  isLastTrackDay: boolean
}>`
  background-color: ${({ isTrackDay }) => (isTrackDay ? theme.colors.midMedium : 'transparent')};
  position: relative;
  width: 100%;
  height: 16px;
  margin: 4px 0;
  padding: 4px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;

  ${({ isFirstTrackDay }) =>
    isFirstTrackDay &&
    css`
      margin-left: 5px;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    `}

  ${({ isLastTrackDay }) =>
    isLastTrackDay &&
    css`
      width: 95%;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    `}
`

export const OtherClassTag = styled.div<{ daysSpan: number }>`
  position: absolute;
  left: 0;
  right: 4px;
  cursor: context-menu;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 4px;
  z-index: 10;
  text-align: left;
  width: calc(78px * ${(props) => props.daysSpan}); /* Calcula a largura com base no número de dias */

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: ${theme.colors.midDark};
`
