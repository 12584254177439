import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const ModalClassesAvailableContainer = styledMUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  textAlign: 'center',
  gap: '24px',
})

export const ModalTitle = styledMUI('strong')({
  fontSize: '20px',
})

export const ClassesCardContainer = styledMUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: theme.colors.midLight,
  width: '100%',
})
export const ClassesTitleSection = styledMUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '8px',

  '& span': {
    fontSize: '20px',
  },
})

export const ContentContainer = styledMUI(Box)({
  overflowY: 'auto',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
})
