import styled from 'styled-components'

import theme from '~/styles/theme'

export const TrackTrailTagItem = styled.div``

export const CardClass = styled.div`
  width: 49%;
  height: fit-content;
  padding: 16px 0px 0px 0px;
  border-radius: 8px;
  background: ${theme.colors.midLight};
  padding: 16px;
  display: grid;
  gap: 16px;

  :hover {
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
`

export const ContainerTrailsClass = styled.div`
  background: ${theme.colors.highPure};
  width: 100%;
  height: 136px;
  padding: 8px;
  gap: 4px;
  border-radius: 8px;
  display: grid;
`

export const NoTrailsTitle = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: ${theme.colors.midDark};
`

export const MoreTrailsTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  font-style: normal;
  text-align: center;
  color: ${theme.colors.midDark};
`

export const MoreTrailsContainer = styled.div`
  background: #f4f7fe;
  width: 100%;
  height: 100%;
  min-height: 32px;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardTrailsClass = styled.div`
  background: ${theme.colors.midLight};
  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TagIconNext = styled.div<{ isActive?: boolean; statusColor?: string }>`
  width: 14px;
  height: 14px;
  border-radius: 50px;

  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isActive }) => (isActive ? `${theme.colors.highPure}` : `${theme.colors.midDark}`)};

  background-color: ${({ statusColor }) => statusColor || 'transparent'};
`

export const ContainerTrackTrailTag = styled.div`
  padding: 4px;
  background: ${theme.colors.highPure};
  border-radius: 50px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 2px;
`

export const TitleTrail = styled.p`
  width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${theme.colors.lowPure};

  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
`

export const TitleClass = styled.h5`
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;

  color: ${theme.colors.lowPure};
`

export const Tag = styled.div`
  max-width: 130px;
  height: 24px;
  gap: 0px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.midDark};

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    min-width: fit-content;
    color: ${theme.colors.midDark};
  }
`

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
`
