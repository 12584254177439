import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import { formatDayOfWeek } from '~/validations/typeWeek'

import { IDatePickerComponentProps } from './DatePicker.interfaces'
import * as S from './DatePicker.styles'

export const DatePickerComponent = ({ label, changeValue, value, type = 'day' }: IDatePickerComponentProps) => {
  let inputValue = dayjs(value)
    .locale('pt-br')
    .format(type === 'month' ? 'MMMM/YYYY' : 'DD/MM/YYYY')

  if (type === 'week') {
    const sunday = dayjs(value).startOf('week').format('DD/MM/YYYY')
    const saturday = dayjs(value).endOf('week').format('DD/MM/YYYY')

    inputValue = `${sunday} - ${saturday}`
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
      <DesktopDatePicker
        label={label}
        inputFormat={type === 'month' ? 'MM/YYYY' : 'DD/MM/YYYY'}
        showDaysOutsideCurrentMonth
        className={type === 'week' ? 'week' : 'day'}
        value={value}
        views={type === 'month' ? ['month'] : ['day']}
        dayOfWeekFormatter={formatDayOfWeek}
        onChange={(date: { $d: Date } | null) => date?.$d && changeValue(date.$d)}
        PaperProps={{
          sx: {
            '& .MuiPickersDay-root': {
              '&.Mui-selected': {
                backgroundColor: '#0095ff',
              },
            },
          },
        }}
        renderInput={(params) => (
          <S.InputTextDate
            {...params}
            format={type}
            inputProps={{
              value: inputValue,
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
