import { CustomDndProvider } from '~/components'

import { LessonPageProvider } from './context'
import { LessonView } from './view'

export const LessonPage = () => {
  return (
    <LessonPageProvider>
      <CustomDndProvider type={'group'}>
        <LessonView />
      </CustomDndProvider>
    </LessonPageProvider>
  )
}
