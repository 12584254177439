import { Box, Tooltip } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import { ITrailStatusBarStyle } from '~/components/Bars/TrailStatusBar/TrailStatusBar.interfaces'

import theme from '~/styles/theme'

export const StatusBarContainer = styledMUI(Box)(() => ({
  display: 'flex',
  backgroundColor: theme.colors.highPure,
  color: theme.colors.midDark,
  border: `1px solid ${theme.colors.midDark}`,
  fontSize: '14px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '50px',
  padding: '4px',
}))

export const StatusInfo = styledMUI(Box)(
  ({ isActive = false, statusColor, isProfessorExistent = true }: ITrailStatusBarStyle) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '4px 12px',
    maxWidth: isActive ? 'fit-content' : '100px',
    width: '100%',
    backgroundColor: isActive ? statusColor : 'unset',
    color: isActive ? theme.colors.highPure : 'inherit',
    borderRadius: '50px',
    position: 'relative',
    transition: 'background-color 0.3s',

    '&:hover': {
      backgroundColor: !isProfessorExistent && theme.colors.highDark,
    },

    '& span, & strong': {
      whiteSpace: 'nowrap',
    },

    '& span:not(:last-child), & strong': {
      padding: '0 8px 0 0',
      borderRight: isActive && `1px solid ${theme.colors.midPure}`,
    },
  }),
)

export const StatusBarTooltip = styledMUI(Tooltip)(() => ({}))

export const StatusBarWithoutProfessorTooltip = styledMUI(Tooltip)(() => ({}))

export const occupyStepButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${theme.colors.actionPure};
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${theme.colors.actionDark};
  }
`
