import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IBodyLoginProps } from './Login.interfaces'

const changePasswordAPI = async (body: IBodyLoginProps) => {
  const { data } = await HTTPClient.put('/auth/change-password', body)

  return data
}

export const useServiceChangePassword = (
  options?: Omit<UseMutationOptions<any, unknown, IBodyLoginProps, unknown>, 'mutationFn' | 'mutationKey'>,
) => useMutation(['changePasswordAPI'], changePasswordAPI, options)
