import { UilClock } from '@iconscout/react-unicons'

import { ChipComponent, SkeletonComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderGeneralComponent = ({ nuDuration, title }: IHeaderComponentProps) => {
  return (
    <S.HeaderContainer component="header">
      <S.ContentHeader>
        <S.Wrapper>
          <S.TitleLight variant="h1">Passo 2</S.TitleLight>

          <S.TitleBold variant="h1">{title}</S.TitleBold>
        </S.Wrapper>

        {nuDuration ? (
          <ChipComponent type="light" icon={<UilClock size={20} />} label={`${nuDuration} minutos`} />
        ) : (
          <SkeletonComponent variant="rounded" width={100} height={20} />
        )}
      </S.ContentHeader>
    </S.HeaderContainer>
  )
}
