import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '20px',
  padding: '120px 24px 24px 24px',
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    display: 'grid',
    padding: '150px 24px 24px 24px',
  },
}))

export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '340px',
  },
}))

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 10,
  padding: '70px 0 0 0',
  marginTop: '-70px',
  backgroundColor: '#fff',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 24px 24px',
  borderBottom: '1px solid #E2E5EC',
  '@media (max-width: 600px)': {
    display: 'grid',
    gap: '8px',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const DateText = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#000',
}))

export const ContentPagination = styledMUI(Box)(() => ({
  alignItems: 'end',
  width: '100%',
}))

export const ContentIcon = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  width: '64px',
  height: '64px',
  background: '#E8F6FF',
  borderRadius: '64px',
}))

export const ContentModalPicture = styledMUI(Box)(() => ({
  padding: '32px',
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const TitleModalPicture = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))

export const TextDesciptionModal = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))
