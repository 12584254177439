import { QuickNavigationComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({ title, description, statusBlock, isOffline, status }: IHeaderComponentProps) => {
  return (
    <S.HeaderContainer component="header">
      <QuickNavigationComponent
        status={status}
        isOffline={isOffline}
        label="Voltar"
        stepActived="BNCCRelations"
        statusBlock={statusBlock}
      />

      <S.Title variant="h1">{title}</S.Title>

      <S.TextLabel variant="caption">{description}</S.TextLabel>
    </S.HeaderContainer>
  )
}
