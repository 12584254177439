import { IColorsStepsProps } from '~/interfaces/ColorsSteps'

export const colorsStepsDisabled = (step: 'default' | 'firstStep' | 'secondStep' | 'thirdStep') => {
  const colorStep: IColorsStepsProps = {
    default: '#E8F6FF',
    firstStep: '#F7E5FF',
    secondStep: '#FFDBE9',
    thirdStep: '#FFE7E3',
  }

  return colorStep[step as keyof IColorsStepsProps]
}
