import { useState } from 'react'

import { Box } from '@mui/material'

import {
  AvatarGroupComponent,
  BackNavigationComponent,
  PedagogicalPracticesComponent,
  SkeletonComponent,
} from '~/components'
import { ModalAvatarList } from '~/components/Modals/ModalAvatarList/ModalAvatarList'
import InactiveTag from '~/components/ui/InactiveTag/InactiveTag'

import { mapClassShift } from '~/validations/mapClassShift'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  loading,
  schoolGrade,
  className,
  avatas,
  classShift,
  coGrade,
  goBackPath,
  dataClassInfos,
  isDisabledClasses,
}: IHeaderComponentProps) => {
  const [openViewAvatar, setOpenViewAvatar] = useState(false)

  const stage = dataClassInfos?.class?.items?.[0]?.schoolGrade?.grade?.stage?.txName

  return (
    <S.HeaderContainer component="header">
      <S.Wrapper>
        <BackNavigationComponent path={goBackPath ? goBackPath : '/classes'} name="Todas as turmas" />

        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <PedagogicalPracticesComponent coGrade={coGrade} schoolGrade={schoolGrade} />
        </Box>
      </S.Wrapper>

      {loading ? (
        <S.Title variant="h1">
          <SkeletonComponent width={350} />
        </S.Title>
      ) : (
        <>
          <S.Title variant="h1">
            <b>{schoolGrade} -</b> {className}
          </S.Title>
          <S.Row>
            <S.ContainerTrackClass>
              <S.ClassSpanInfo>{stage}</S.ClassSpanInfo>
              <S.ClassSpanInfo>{mapClassShift(classShift || '')}</S.ClassSpanInfo>
              {isDisabledClasses === false && <InactiveTag />}
            </S.ContainerTrackClass>

            <S.Column>
              {loading ? (
                <SkeletonComponent variant="circular" width={40} height={40} />
              ) : (
                <AvatarGroupComponent size="small" images={avatas} max={4} />
              )}
              <S.VisualizeStudentsButton onClick={() => setOpenViewAvatar(true)}>
                Visualizar Alunos
              </S.VisualizeStudentsButton>
            </S.Column>
          </S.Row>
        </>
      )}
      {openViewAvatar && (
        <ModalAvatarList open={openViewAvatar} actionCloseModal={() => setOpenViewAvatar(false)} avatarList={avatas} />
      )}
    </S.HeaderContainer>
  )
}
