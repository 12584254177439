import { Box, Button, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const ContainerHome = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
}))

export const ContentHome = styledMUI(Box)(() => ({
  height: 'auto',
  width: '923px',
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 900px)': {
    width: '100%',
  },
}))

export const CardsContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  borderRadius: '16px',
  padding: '24px',
  gap: '16px',
}))

export const CardsContainerRow = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  '@media (max-width: 600px)': {
    gap: '16px',
    overflow: 'auto',
    overflowY: 'hidden',
  },
}))

export const Line = styledMUI(Box)(() => ({
  width: '1px',
  backgroundColor: theme.colors.lowLight,
}))

export const WelcomeModalBox = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
}))

export const WelcomeIconBox = styledMUI(Box)(() => ({
  display: 'flex',
  width: '64px',
  height: '64px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  borderRadius: '50%',
  backgroundColor: theme.colors.actionLight,
}))

export const WelcomeTitle = styledMUI(Typography)(() => ({
  textAlign: 'center',

  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
}))

export const WelcomeParagraph = styledMUI(Typography)(() => ({
  textAlign: 'center',

  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '120%',
}))

export const TrackButton = styledMUI(Button)(() => ({
  marginTop: '8px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  height: '48px',
  borderRadius: '50px',
  color: theme.colors.highPure,

  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '100%',

  backgroundColor: theme.colors.actionPure,
  textTransform: 'none',

  '&:hover': {
    backgroundColor: theme.colors.actionPure,
  },
}))

export const LatterButton = styledMUI(Button)(() => ({
  width: '100%',
  height: '48px',

  padding: '8px 16px',

  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%',

  borderRadius: '50px',

  textTransform: 'none',

  color: theme.colors.lowPure,
}))
