import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetProjectInformation } from '~/services/Project'

import { IProjectBNCCRelationsContextProps, IProjectBNCCRelationsPageProviderProps } from './interfaces'

const ProjectBNCCRelationsPageContext = createContext<IProjectBNCCRelationsContextProps>({
  isLoading: false,
  data: undefined,
})

const ProjectBNCCRelationsPageProvider = ({ children }: IProjectBNCCRelationsPageProviderProps) => {
  const { projectId } = useParams()

  const { isLoading, data } = useGetProjectInformation(Number(projectId))

  const projectBNCCRelationsPageProviderValues = useMemo(() => {
    return { isLoading, data }
  }, [isLoading, data])

  return (
    <ProjectBNCCRelationsPageContext.Provider value={projectBNCCRelationsPageProviderValues}>
      {children}
    </ProjectBNCCRelationsPageContext.Provider>
  )
}

const useProjectBNCCRelationsPageContext = () => useContext(ProjectBNCCRelationsPageContext)

export { ProjectBNCCRelationsPageProvider, useProjectBNCCRelationsPageContext }
