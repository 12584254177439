import { UilArrowDown } from '@iconscout/react-unicons'
import { UilArrowUpRight, UilArrowLeft } from '@iconscout/react-unicons'
import { Box, Divider } from '@mui/material'
import parse from 'html-react-parser'

import {
  ButtonComponent,
  DividerComponent,
  DropdownSkillComponent,
  IconComponent,
  SkeletonComponent,
} from '~/components'

import CardCarousel from '~/containers/private/Fund1/Planning/Carrousell/CardCarousel'

import { truncateText } from '~/validations/truncateText'

import { IAccordionOrientationsComponentProps } from './ExploreCard.interfaces'
import * as S from './ExploreCard.styles'

export const ExploreCard = ({
  type,
  evidences,
  handleBack,
  activityOrientations,
  index,
  description,
  challenge,
  variant,
  title,
  categories,
  mediaInfo,
  isLoading,
}: IAccordionOrientationsComponentProps) => {
  const fixedEvidence = evidences?.find((e) => e.coEvidenceType === 'FIX')
  const variableEvidence = evidences?.find((e) => e.coEvidenceType === 'VAR')

  const returnVariant = (type: string) => {
    switch (type) {
      case 'one':
        return 'firstStep'
      case 'two':
        return 'secondStep'
      case 'three':
        return 'thirdStep'
      default:
        return 'default'
    }
  }
  if (isLoading) {
    return (
      <>
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <SkeletonComponent variant="text" width={20} height={32} />
          <SkeletonComponent variant="text" width={200} height={32} />
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <SkeletonComponent variant="text" width={200} height={32} />
          <SkeletonComponent variant="text" width={250} height={42} />
        </Box>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {Array.from({ length: 2 }).map((_, index) => (
            <Box key={index} display="flex" flexDirection="column" gap={2} width="100%">
              <Box>
                <SkeletonComponent variant="rounded" width={'100%'} height={50} />
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </>
    )
  }
  return (
    <S.bgBox>
      {variant === 'P2' ? (
        <S.BoxEM>
          <S.TitleWrapper>
            <ButtonComponent
              type="button"
              onClick={handleBack}
              text={'Voltar'}
              iconStart={<UilArrowLeft size={16} />}
              variant={'outline'}
              size={'xsmall'}
              fontWeight={'normal'}
            />

            <S.Row type={type}>
              <IconComponent color={'primary'} fontWeight="normal" code={categories[0]?.txImagePath} size="xsmall" />
              <S.TextStepDescriptionActivity color="card">
                <S.textLesson> {categories[0]?.txCategoryName}</S.textLesson>
                <S.textLesson weight={700}> {truncateText(title, 35)}</S.textLesson>
              </S.TextStepDescriptionActivity>
            </S.Row>
            {mediaInfo && (
              <S.BtnBox>
                <ButtonComponent
                  type="button"
                  onClick={() => window.open(mediaInfo?.txAbsoluteUrl, '_blank')}
                  text="Roteiro do Aluno"
                  iconEnd={<UilArrowUpRight size={16} />}
                  variant={'outline'}
                  size={'xsmall'}
                  fullWidth
                  fontWeight={'normal'}
                />
              </S.BtnBox>
            )}
          </S.TitleWrapper>
        </S.BoxEM>
      ) : (
        <S.BoxTitle>
          <ButtonComponent
            type="button"
            onClick={handleBack}
            text={'Voltar'}
            iconStart={<UilArrowLeft size={16} />}
            variant={'outline'}
            size={'xsmall'}
            fontWeight={'normal'}
          />

          <S.RowTitle>
            <S.TextMain weight={500} type={type}>
              Passo {index}
            </S.TextMain>
            <S.TextMain weight={700} type={type}>
              {truncateText(title, 30)}
            </S.TextMain>
          </S.RowTitle>
        </S.BoxTitle>
      )}

      <S.HeaderBox>
        <S.BoxSkill>
          {description && (
            <S.Wrapper>
              <S.TextDescription>
                <b>Desafio de Projeto - </b>
                {description}
              </S.TextDescription>
            </S.Wrapper>
          )}

          {variableEvidence && (
            <S.Wrapper>
              <S.TextLabel color="#000" variant="caption">
                Habilidade Variável
              </S.TextLabel>

              <DropdownSkillComponent
                txSkillName={variableEvidence?.txAbilityName}
                txBncc={variableEvidence?.txAbilityName}
                iconCompetency={variableEvidence?.txAbilityImagePath}
                txSkillDescription={variableEvidence?.txEvidenceName}
                iconSkill={variableEvidence?.txCompetenceImagePath}
                txCompetency={variableEvidence?.txCompetenceName}
              />
            </S.Wrapper>
          )}

          {fixedEvidence && (
            <>
              <S.Wrapper>
                <S.TextLabel color="#000" variant="caption">
                  Habilidade Fixa
                </S.TextLabel>

                <DropdownSkillComponent
                  txSkillName={fixedEvidence?.txAbilityName}
                  txSkillDescription={fixedEvidence?.txEvidenceName}
                  txBncc={fixedEvidence?.txAbilityName}
                  txCompetency={fixedEvidence?.txCompetenceName}
                  iconSkill={fixedEvidence?.txAbilityImagePath}
                  iconCompetency={fixedEvidence?.txCompetenceImagePath}
                />
              </S.Wrapper>
            </>
          )}
          {challenge && variant === 'P2' && (
            <S.Wrapper>
              <S.TextDescription>
                <b>Desafio da Etapa -</b>
                {challenge}
              </S.TextDescription>
            </S.Wrapper>
          )}
        </S.BoxSkill>
      </S.HeaderBox>

      {activityOrientations?.map((info, index) => (
        <S.AccordionContainer key={index}>
          <S.AccordionSummaryContent
            expandIcon={
              <S.CircleIcon>
                <UilArrowDown />
              </S.CircleIcon>
            }
          >
            <S.Title variant="caption" type={type ?? 'default'}>
              {info.txTitle}
            </S.Title>
          </S.AccordionSummaryContent>

          <S.AccordionDetailsContent>
            <S.ContentParagraphs>
              <S.Paragraph>
                <S.TextParagraph>{parse(String(info.txOrientationCode))}</S.TextParagraph>
              </S.Paragraph>
            </S.ContentParagraphs>

            {info.supportReferences && info.supportReferences.length > 0 && (
              <>
                <DividerComponent />
                <S.Title variant="caption" type={type}>
                  Referências de Apoio
                </S.Title>
                <S.ContentCards>
                  <CardCarousel key={index} items={info.supportReferences} total={3} type={returnVariant(type)} />
                </S.ContentCards>
              </>
            )}
          </S.AccordionDetailsContent>
        </S.AccordionContainer>
      ))}
    </S.bgBox>
  )
}
