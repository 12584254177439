import { useState } from 'react'

import { CircularProgress } from '@mui/material'

import {
  AccordionOrientationsComponent,
  AppBarComponent,
  AssessmentComponent,
  CardDocumentationComponent,
  ContainerComponent,
  ModalDocumentationComponent,
  ModalStudentManagementComponent,
  QuickNavigationComponent,
  ValidateDataSessionComponent,
} from '~/components'
import { AlertAssessment } from '~/components/Forms/Assessment/Assessment.styles'

import { HeaderComponent } from '~/containers/private/Fund1/ThirdStep'

import { useThirdStepPageContext } from './context'
import * as S from './styles'

export const ThirdStepView = () => {
  const {
    activedTab,
    handleActiveNewTab,
    isLoading,
    data,
    isLoadingDocumentation,
    dataDocumentationFiltered,
    mutateAssessment,
    mutateAssessmentBatch,
    idLessonMoment,
    idLesson,
    isSameUser,
    isLoadingAssessment,
    isLoadingAssessmentBatch,
    dataStudents,
    dataModal,
    refetchStudents,
    dataStudentsL2,
    refetchL2,
    idClass,
    isClassInStatus,
  } = useThirdStepPageContext()

  const [showModalStudentManagement, setShowModalStudentManagement] = useState(false)
  const handleCloseModalStudentManagement = () => setShowModalStudentManagement(false)

  const handleAssessment = (id: string, coAssessment: string, idEvidence: string) => {
    mutateAssessment({
      body: { idMomentGroup: id, coAssessment, idEvidence },
      idLessonMoment: String(idLessonMoment),
      idClass: String(idClass),
    })
  }

  const handleAssessmentBatch = (body) => {
    if (evidence) {
      body.idLessonMoment = String(idLessonMoment)
      body.idClass = String(idClass)
      mutateAssessmentBatch(body)
    }
  }

  const handleOpenModalStudentManagement = () => {
    if (dataModal) {
      setShowModalStudentManagement(true)
    }
  }

  const status = data.momentStatus.coMomentStatus
  const nuDuration = data.nuDuration
  const idLessonMomentActivity = data?.lessonActivity
    ? data?.lessonActivity[0]?.lessonMomentActivity?.[0]?.idLessonMomentActivity
    : null
  const evidence = data.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence
  const idEvidence = data.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.idEvidence
  const lessonOrientation = data?.lessonActivity ? data?.lessonActivity[0]?.lessonActivityOrientation : null
  const isFinish = status === 'FINA'

  return (
    <>
      {isLoadingAssessment && (
        <AlertAssessment
          iconMapping={{
            info: <CircularProgress color="inherit" size={16} />,
          }}
          severity={'info'}
        >
          Estamos processando a sua avaliação.
        </AlertAssessment>
      )}
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            stepActived="thirdStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            status={status}
          />
        </S.NavContainer>

        <>
          {dataStudents && dataStudents.group && (
            <HeaderComponent
              loading={isLoading}
              duration={nuDuration}
              activedTab={activedTab}
              avatars={dataStudents.group}
              actionSetActiveTab={(newActivedTab: number) => handleActiveNewTab(newActivedTab)}
              evidence={evidence}
              status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
              openModal={handleOpenModalStudentManagement}
              title={dataModal?.lesson?.lessonStep[2].txTitle ? dataModal?.lesson?.lessonStep[2].txTitle : 'Revisar'}
            />
          )}
          {data && status === 'AGEN' && (
            <HeaderComponent
              loading={isLoading}
              duration={nuDuration}
              activedTab={activedTab}
              actionSetActiveTab={(newActivedTab: number) => handleActiveNewTab(newActivedTab)}
              evidence={evidence}
              status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
              openModal={handleOpenModalStudentManagement}
              title={dataModal?.lesson?.lessonStep[2].txTitle ? dataModal?.lesson?.lessonStep[2].txTitle : 'Revisar'}
            />
          )}
        </>

        <S.Content>
          {activedTab === 0 && (
            <S.ContentOrientations component="ul">
              {lessonOrientation?.map((orientation, index: number) => (
                <AccordionOrientationsComponent
                  key={index}
                  type="thirdStep"
                  title={orientation?.txTitle}
                  txOrientationCode={orientation?.txOrientationCode}
                  supportReference={orientation?.lessonActivitySupportReference}
                />
              ))}
            </S.ContentOrientations>
          )}

          {activedTab === 1 &&
            (dataDocumentationFiltered?.length > 0 ? (
              <S.ContentDocumentation component="ul">
                {dataDocumentationFiltered?.map((doc: any, index: number) => (
                  <CardDocumentationComponent
                    queriesKeys={['GetLessonDocumentation']}
                    key={index}
                    status={status}
                    isSameUser={isSameUser}
                    type="step"
                    documentation={doc}
                    isLoading={isLoadingDocumentation}
                    isProject={false}
                    isClassInStatus={isClassInStatus}
                  />
                ))}
              </S.ContentDocumentation>
            ) : (
              <ValidateDataSessionComponent
                space={1}
                height={50}
                screen="documentation"
                title="Nenhuma documentação cadastrada."
                description="Até o momento, nenhuma documentação foi cadastrada."
              />
            ))}

          {activedTab == 2 && dataStudents && idLessonMomentActivity && (
            <AssessmentComponent
              handleDrop={handleAssessment}
              handleDropBatch={handleAssessmentBatch}
              disabled={isFinish || !isSameUser || !isClassInStatus}
              txEvidenceName={evidence.ability.txName}
              txEvidenceDescription={evidence.txName}
              txCompentencyName={evidence.ability.competence.txName}
              txIconCompetency={evidence.ability.txImagePath}
              txIconSkill={evidence.ability.competence.txImagePath}
              students={dataStudents}
              refetchStudents={refetchStudents}
              idEvidence={idEvidence.toString()}
              skillType={'FIX'}
              type={'lesson'}
              idActivity={idLessonMomentActivity}
            />
          )}
        </S.Content>

        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && isSameUser && (
            <ModalDocumentationComponent
              queryKeys={['GetLessonDocumentation']}
              idLessonMoment={idLessonMoment}
              idLesson={idLesson}
              variant="floater"
              isClassInStatus={isClassInStatus}
            />
          )}
        </>
        {dataModal && (
          <ModalStudentManagementComponent
            openModal={showModalStudentManagement}
            actionCloseModal={handleCloseModalStudentManagement}
            dataClass={dataModal}
            classID={dataModal?.class?.idClass}
            idLessonMoment={Number(idLessonMoment)}
            type={'F1'}
            dataStudentsGroup={dataStudentsL2}
            refetchStudents={refetchL2}
          />
        )}
      </ContainerComponent>
    </>
  )
}
