import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '94px 24px 24px',
  marginTop: '-70px',
  zIndex: 1,
  gap: '16px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #E2E5EC',
}))

export const HeaderLoading = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))
