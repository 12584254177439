import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;

  h3 {
    padding: 4px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px;
  }
`

export const ContainerChart = styled.div<{ isMobile: boolean }>`
  display: ${({ isMobile }) => (isMobile ? 'grid' : 'flex')};
  justify-content: center;
  align-items: center;
  justify-items: center;
`
export const Tooltip = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.highPure};
  box-shadow: 6px 7px 10px 0px rgba(0, 0, 0, 0.5);
  display: grid;
  gap: 8px;
`

export const AbilityContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: left;
  align-items: center;
`

export const TitleTooltip = styled.h3`
  font-size: 16px;
  color: #000;
  width: 200px;
  font-weight: 700;
`

export const TitleAbility = styled.h4`
  font-size: 14px;
  color: #000;
  font-weight: 400;
`
export const TooltipMonth = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.midDark};
`

export const StatusTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.midMedium};
  color: ${({ theme }) => theme.colors.midDark};
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  max-width: 120px;
`
export const SkeletonContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;

  h3 {
    padding: 4px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .center {
    width: 80%;
  }

  .buttons {
    width: 5%;
  }
`

export const IconButton = styled.button<{ color: string; disabled: boolean }>`
  width: 24px;
  border: 2px solid transparent;
  height: 24px;
  cursor: pointer;
  border-radius: 50px;
  background-color: ${({ color, disabled }) => (disabled ? '#ccc' : color)};

  :hover {
    scale: 1.1;
  }
`
export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`
