import { SyntheticEvent } from 'react'

import { UilMultiply } from '@iconscout/react-unicons'

import { IChipComponentProps } from './Chip.interfaces'
import * as S from './Chip.styles'
import { colorChip } from './Chip.validations'

export const ChipComponent = ({
  label,
  type = 'primary',
  icon,
  fullWidth,
  isRemove,
  count,
  isSametag,
  isThin,
  actionRemove,
}: IChipComponentProps) => {
  return (
    <S.ChipContainer
      icon={icon}
      type={
        colorChip(type) as
          | 'primary'
          | 'light'
          | 'danger'
          | 'disabled'
          | 'play'
          | 'gray'
          | 'warning'
          | 'toDo'
          | 'normal'
          | 'cancel'
      }
      isSametag={isSametag}
      isThin={isThin}
      label={count ? `${label} +${count}` : label}
      full_width={fullWidth ? 1 : 0}
      deleteIcon={isRemove ? <UilMultiply size={18} color="#fff" /> : <></>}
      onDelete={isRemove && actionRemove ? (e: SyntheticEvent<any>) => actionRemove(e) : undefined}
    />
  )
}
