import { ProjectBNCCRelationsPageProvider } from './context'
import { ProjectBNCCRelationsView } from './view'

export const ProjectBNCCRelationsPage = () => {
  return (
    <ProjectBNCCRelationsPageProvider>
      <ProjectBNCCRelationsView />
    </ProjectBNCCRelationsPageProvider>
  )
}
