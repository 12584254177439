import { useMutation, useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

export const getNotification = async () => {
  const { data } = await HTTPClient.get(`/notification/my?coNotificationSource=${'PLAT'}`)
  return data
}
export const useGetNotifications = () => useQuery<any>(['getNotifications'], () => getNotification())

export const markAllRead = async () => {
  const { data } = await HTTPClient.post(`/notification/mark-all-read`)

  return data
}
export const useServiceMarkAllRead = () => useMutation<any>(['MarkAllRead'], markAllRead)

export const markAsRead = async (id: number) => {
  const { data } = await HTTPClient.patch(`/notification/${id}/mark-as-read`)

  return data
}

export const useMarkAsRead = () => useMutation(['MarkAsRead'], markAsRead)
