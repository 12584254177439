import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const CardStudent = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '8px',
  borderRadius: '8px',
  backgroundColor: '#F9FAFC',
  cursor: 'grab',
}))

export const TextStudent = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '150%',
  color: '#000',
}))
