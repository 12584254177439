import { TrailsGeneratedEMPageProvider } from '~/pages/private/TrailsGenerated/context'

import { TrailsNotGeneratedEMView } from './view'

export const TrailsNotGeneratedPage = () => {
  return (
    <TrailsGeneratedEMPageProvider>
      <TrailsNotGeneratedEMView />
    </TrailsGeneratedEMPageProvider>
  )
}
