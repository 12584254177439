import { useState } from 'react'

import { UilArrowUpRight } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarGroupComponent, ButtonComponent, ChipComponent, IconComponent, StepsTabsComponent } from '~/components'
import { ModalAvatarList } from '~/components/Modals/ModalAvatarList/ModalAvatarList'

import { HeaderSkillComponent } from '../HeaderSkill/HeaderSkill'
import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  type,
  activedTab,
  actionSetActiveTab,
  txCategory,
  txTitleProject,
  txGroup,
  components,
  students,
  txChallengeStage,
  evidenceFixed,
  evidenceVariable,
  studentGuidePath,
  coMomentStatus,
  status,
  txChallengeProject,
  icon,
}: IHeaderComponentProps) => {
  const mainColor =
    type === 'firstStep' ? '#8C22BC' : type === 'secondStep' ? '#E14983' : type === 'thirdStep' ? '#E66251' : '#fcba03'
  const { width } = useWindowDimensions()

  const isMobile = width && width < 768

  const studentsFiltered = students?.filter((student: any) => student.inAttendance === true)
  const avatarList = students?.map((student: any) => student.studentClass.student)
  function getPrimaryComponent() {
    return components?.map((component, index) => {
      if (component.coComponentType === 'PRI') {
        return <ChipComponent type="primary" key={index} label={component?.component?.txComponent} />
      }
    })
  }

  function getSecondaryComponent() {
    return components?.map((component, index) => {
      if (component.coComponentType === 'SEC') {
        return <ChipComponent type="disabled" key={index} label={component?.component?.txComponent} />
      }
    })
  }

  // Deixar componente primário em primeiro

  function getComponents() {
    return getPrimaryComponent()?.concat(getSecondaryComponent())
  }

  const [openViewAvatar, setOpenViewAvatar] = useState<boolean>(false)
  const showOpenViewAvatar = () => {
    setOpenViewAvatar(true)
  }

  return (
    <S.HeaderContainer component="header">
      <S.ContentHeader>
        <S.Wrapper>
          {icon && <IconComponent color={mainColor} fontWeight="normal" code={icon} size="xsmall" />}
          <S.Category style={{ color: mainColor }}>{txCategory}</S.Category>
        </S.Wrapper>
        <S.WrapperTitle>
          <S.Title variant="h1" style={{ color: mainColor }}>
            {txTitleProject}
          </S.Title>
          <ButtonComponent
            variant="outline"
            fullWidth={false}
            style={{ width: isMobile ? '50%' : 'auto' }}
            size={isMobile ? 'xsmall' : 'small'}
            text="Roteiro do Aluno"
            iconEnd={<UilArrowUpRight size={16} />}
            onClick={() => window.open(studentGuidePath, '_blank')}
          />
        </S.WrapperTitle>

        <S.WrapperComponents>{getComponents()}</S.WrapperComponents>

        <S.Wrapper>
          {coMomentStatus !== 'AGEN' && (
            <S.TextLabel color="#666" variant="caption">
              Grupo
            </S.TextLabel>
          )}
          <S.ContentAvatar onClick={showOpenViewAvatar}>
            <AvatarGroupComponent images={studentsFiltered} size="small" max={6} />
          </S.ContentAvatar>
        </S.Wrapper>
      </S.ContentHeader>

      <HeaderSkillComponent
        txChallengeProject={txChallengeProject}
        txChallengeStage={txChallengeStage}
        fixedSkill={{
          txSkillName: evidenceFixed?.ability?.txName,
          txSkillDescription: evidenceFixed?.txName,
          txBncc: '',
          txCompetency: evidenceFixed?.ability?.competence?.txName,
          txImageSkill: evidenceFixed?.ability?.txImagePath,
          txImageCompetence: evidenceFixed?.ability?.competence?.txImagePath,
        }}
        variableSkill={{
          txSkillName: evidenceVariable?.ability?.txName,
          txSkillDescription: evidenceVariable?.txName,
          txBncc: '',
          txCompetency: evidenceVariable?.ability?.competence?.txName,
          txImageSkill: evidenceVariable?.ability?.txImagePath,
          txImageCompetence: evidenceVariable?.ability?.competence.txImagePath,
        }}
      />

      <S.ContentTabs>
        <StepsTabsComponent
          status={status}
          type={type}
          mobile={isMobile}
          activedTab={activedTab}
          actionSetActiveTab={(newActivedTab: number) => actionSetActiveTab(newActivedTab)}
        />
      </S.ContentTabs>

      {openViewAvatar && (
        <ModalAvatarList
          open={openViewAvatar}
          actionCloseModal={() => setOpenViewAvatar(false)}
          avatarList={avatarList}
        />
      )}
    </S.HeaderContainer>
  )
}
