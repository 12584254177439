import { Chip } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { IChipContainerProps } from './Chip.interfaces'
import { bgColorsTypes, colorsTypes } from './Chip.validations'

export const ChipContainer = styledMUI(Chip, {
  shouldForwardProp: (prop) => prop !== 'isSametag' && prop !== 'isThin',
})<IChipContainerProps>(({ type, full_width, isSametag, isThin }) => ({
  height: isSametag ? '24px' : '26px',
  width: full_width ? '100%' : 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  borderRadius: '4px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: colorsTypes(type),
  backgroundColor: bgColorsTypes(type),
  padding: isThin ? '0px' : '0 8px',
  '.MuiSvgIcon-root': { color: '#fff' },
  border: isSametag ? '1px solid' : 'none',
  cursor: isSametag ? 'pointer' : 'default',
}))
