import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Content = styledMUI(Box)(() => ({
  height: '100vh',
  width: '100vw',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  textAlign: 'center',
  color: '#000',
}))

export const Text = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontSize: '20px',
  textAlign: 'center',
  color: '#A3A3A3',
}))

export const ActionContainer = styledMUI(Box)(() => ({
  marginTop: '10px',
}))
