import { useEffect, useState } from 'react'

import { UilArrowRight, UilArrowLeft } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { SkeletonComponent } from '~/components/Basic/Skeleton/Skeleton'

import { Lesson } from '~/pages/private/Fund1/Lesson/interfaces'

import { ILessonStepsProps } from './StageSteps.interfaces'
import * as S from './StageSteps.styles'

interface IData {
  lesson?: Lesson[]
}

export default function StageSteps({
  data,
  onChangeTrailIndex,
  handleAllLessons,
  handlePrevClick,
  handleNextClick,
  isPrevDisabled,
  hasLesson,
  isNextDisabled,
  adjustedCurrentIndex,
  currentIndex,
  itemsPerPage,
  isTrailGenerated,
  handleSelect,
}: ILessonStepsProps) {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (data) {
      setIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    setActiveIndex(onChangeTrailIndex)
  }, [onChangeTrailIndex])

  return (
    <S.Container>
      {isLoading && (
        <>
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
        </>
      )}

      {!isLoading && data?.stage && (
        <>
          {data?.stage.length >= 5 && (
            <S.BtnArrow onClick={handlePrevClick} active={!isPrevDisabled}>
              <UilArrowLeft />
            </S.BtnArrow>
          )}
          <S.StepsContainer>
            <S.BtnAction onClick={handleAllLessons} active={activeIndex === null}>
              {isMobile ? 'Todas' : 'Todas as Etapas'}
            </S.BtnAction>
            {data?.stage?.map((item: any, index: number) => (
              <S.StepsContent
                key={index}
                isViewTheme={data?.stage.length <= 4}
                isTrailGenerated={isTrailGenerated}
                isNull={activeIndex !== null}
                onClick={() => handleSelect(index)}
                active={
                  hasLesson
                    ? index === currentIndex - 1
                    : index >= adjustedCurrentIndex && index < adjustedCurrentIndex + itemsPerPage
                }
              >
                {`${index + 1}`}
              </S.StepsContent>
            ))}
          </S.StepsContainer>
          {data?.stage.length >= 5 && (
            <S.BtnArrow onClick={handleNextClick} active={!isNextDisabled}>
              <UilArrowRight color={'#FFF'} />
            </S.BtnArrow>
          )}
        </>
      )}
    </S.Container>
  )
}
