import { UilArrowDown } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import { DividerComponent, DropdownSkillComponent, IconComponent } from '~/components'

import CardCarousel from '~/containers/private/Fund1/Planning/Carrousell/CardCarousel'

import { ActivityOrientation } from '~/pages/private/Trails/TrailDetailsClass/SecondStep/interfaces'

import * as S from './styles'

export default function CardActivityDetailContext({ activity }: any) {
  const evidenceData = activity.evidences.filter((item: any) => item?.coEvidenceType === 'VAR')
  const returnVariant = (type: string) => {
    switch (type) {
      case 'one':
        return 'firstStep'
      case 'two':
        return 'secondStep'
      case 'three':
        return 'thirdStep'
      default:
        return 'default'
    }
  }

  return (
    <S.CardActivity>
      <S.Row>
        <IconComponent color="card" fontWeight="normal" code={activity?.txCategoryImagePath} size="xsmall" />
        <S.TextStepDescriptionActivity color="card">
          <S.textLesson>{activity?.txCategoryName}</S.textLesson> - <S.textLesson>{activity?.txTitle}</S.textLesson>
        </S.TextStepDescriptionActivity>
      </S.Row>
      <S.WrapperEvidence>
        <S.Label>Habilidade variável</S.Label>
        {evidenceData?.map((item: any, index: number) => (
          <DropdownSkillComponent
            key={index}
            txSkillName={item?.txAbilityName}
            iconCompetency={item?.txAbilityImagePath}
            txSkillDescription={item?.txEvidenceName}
            iconSkill={item?.txCompetenceImagePath}
            txCompetency={item?.txCompetenceName}
          />
        ))}
      </S.WrapperEvidence>
      <S.WrapperEvidence>
        <S.Label>Desafio</S.Label>
        <S.TextChallenge>{activity?.txChallenge}</S.TextChallenge>
      </S.WrapperEvidence>

      {activity.activityOrientations?.map((info: ActivityOrientation, index: number) => (
        <S.AccordionContainer key={index}>
          <S.AccordionSummaryContent
            expandIcon={
              <S.CircleIcon>
                <UilArrowDown />
              </S.CircleIcon>
            }
          >
            <S.LabelWrapper>{info.txTitle}</S.LabelWrapper>
          </S.AccordionSummaryContent>

          <S.AccordionDetailsContent>
            <S.ContentParagraphs>
              <S.Paragraph>
                <S.TextParagraph>{parse(String(info.txOrientationCode))}</S.TextParagraph>
              </S.Paragraph>
            </S.ContentParagraphs>

            {info.supportReferences && info.supportReferences.length > 0 && (
              <>
                <DividerComponent />
                <S.LabelWrapper>Referências de Apoio</S.LabelWrapper>
                <CardCarousel key={index} items={info.supportReferences} type={returnVariant('two')} />
              </>
            )}
          </S.AccordionDetailsContent>
        </S.AccordionContainer>
      ))}
    </S.CardActivity>
  )
}
