import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const ContentTabs = styledMUI(Box)(() => ({
  padding: '24px',
  marginTop: '170px',
  display: 'grid',
  width: '100%',
  gap: '24px',
  justifyItems: 'center',
  '@media (max-width: 760px)': {
    marginTop: '230px',
  },
}))

export const ExtraDataContainer = styled.div`
  background-color: #f9fafc;
  padding: 8px;
  border-radius: 8px;
  width: 100%;

  h3 {
    color: ${theme.colors.lowPure};
  }

  p {
    color: ${theme.colors.lowMedium};
    margin-top: 4px;
  }
`

export const SkeletonHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 12px;

  align-items: center;
  position: fixed;
  padding: 90px 18px 18px 18px;
  margin-top: -70px;
  z-index: 10;

  div {
    display: grid;
    gap: 8px;
  }
`
export const SkeletonInfo = styled.div`
  margin-top: 120px;
  padding: 24px;
  display: grid;
  gap: 24px;
  justify-items: center;
`

export const Container = styled.div`
  padding: 12px;
`

export const EmptyContainer = styled.div`
  padding: 12px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  p {
    font-weight: 700;
    font-size: 24px;
    color: #000;
  }
`

export const ContentHistory = styledMUI(Box)(() => ({
  borderRadius: '8px',
  padding: '16px',
  width: '100%',
  gap: '16px',
  justifyItems: 'center',
  backgroundColor: '#F9FAFC',
  display: 'flex',
  flexDirection: 'column',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '16px',
  color: '#000',
}))
