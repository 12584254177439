import { Box, Menu, MenuItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const ContentBack = styled.div`
  gap: 16px;
  display: flex;
  justify-content: space-between;
`

export const ButtonMenuItem = styledMUI(MenuItem)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: '#f2f2f2',
    opacity: 0.8,
  },
}))

export const MenuContainer = styledMUI(Menu)(() => ({
  margin: '0px 0 0 0',
  '.MuiPaper-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
}))

export const RowBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`

export const RowEnd = styled.div`
  width: 50px;
  display: flex;
  justify-content: end;
  gap: 4px;
  cursor: pointer;
  justify-self: flex-end;
`

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  position: 'fixed',
  zIndex: 100,
  marginBottom: '200px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #E2E5EC',
  '@media (max-width: 600px)': {
    width: '100%',
    top: 0,
    left: 0,
  },
}))
export const ContainerTitle = styledMUI(Box)(() => ({
  padding: '24px',
  display: 'grid',
  gap: '16px',
}))

export const ContainerStep = styledMUI(Box)(() => ({
  borderTop: '1px solid #E2E5EC',
  padding: '16px 24px',
}))
