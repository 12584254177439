import { ResponsableStudentsPageProvider } from './context'
import { ResponsableStudentsView } from './view'

export const ResponsableStudentsPage = () => {
  return (
    <ResponsableStudentsPageProvider>
      <ResponsableStudentsView />
    </ResponsableStudentsPageProvider>
  )
}
