import { Box, Button, Link, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const LinkCardContainer = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

const getBackgroundColor = (type: string): string => {
  switch (type) {
    case 'toPlan':
      return '#0095FF'
    case 'toPerform':
      return '#8C22BC'
    case 'pending':
      return '#D44333'
    case 'toDocumentAndEvaluate':
      return '#FF8A00'
    default:
      return '#F9FAFC'
  }
}

const getBorderColor = (type: string): string => {
  switch (type) {
    case 'toPlan':
      return 'solid 1px #005B9C'
    case 'toPerform':
      return 'solid 1px #410B3C'
    case 'pending':
      return 'solid 1px #5A0A00'
    case 'toDocumentAndEvaluate':
      return 'solid 1px #6A3900'
    default:
      return '#F9FAFC'
  }
}

const getInfoColor = (type: string): string => {
  switch (type) {
    case 'toPlan':
      return '#005B9C'
    case 'toPerform':
      return '#410B3C'
    case 'pending':
      return '#5A0A00'
    case 'toDocumentAndEvaluate':
      return '#6A3900'
    default:
      return '#F9FAFC'
  }
}

const getIconColor = (type: string): string => {
  switch (type) {
    case 'toPlan':
      return '#90D1FF'
    case 'toPerform':
      return '#E4A7FF'
    case 'pending':
      return '#F27B6C'
    case 'toDocumentAndEvaluate':
      return '#FFC989'
    default:
      return '#F9FAFC'
  }
}

export const CardContainer = styledMUI(Button)<{ selected: boolean; variant: string }>(({ selected, variant }) => ({
  width: '250px',
  height: '82px',
  display: 'flex',
  borderRadius: '15px',
  padding: '6px',
  cursor: 'pointer',
  position: 'relative',
  backgroundColor: selected ? getBackgroundColor(variant) : '#F9FAFC',
  border: selected ? getBorderColor(variant) : 'solid 1px #E2E5EC',
  boxShadow: selected ? '0px 16px 48px 0px rgba(0, 0, 0, 0.24)' : 'none',
  overflow: 'hidden',
  transition: 'box-shadow 0.5s',

  fontSize: '14px',
  textTransform: 'lowercase',

  '&:hover': {
    backgroundColor: selected ? getBackgroundColor(variant) : '#F9FAFC',
    boxShadow: theme.shadows.level3,
  },
  '@media (max-width: 600px)': {
    minWidth: '250px',
    maxWidth: '250px',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  overflow: 'hidden',
  position: 'absolute',
  right: '10px',
  top: '8px',
  width: '140px',
  zIndex: 2,
}))
export const WrapperAbsolute = styledMUI(Box)(() => ({
  display: 'flex',
  position: 'relative',
  top: '4px',
}))

export const WrapperInfo = styledMUI(Box)<{ selected: boolean; variant: string }>(({ selected, variant }) => ({
  display: 'flex',
  position: 'absolute',
  right: '10px',
  top: '10px',
  color: selected ? getInfoColor(variant) : theme.colors.midDark,
  '@media (max-width: 600px)': {
    right: '-20px',
    top: '-2px',
  },
}))

export const ContentText = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const TextLabel = styledMUI(Typography)<{ selected: boolean }>(({ selected }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  width: '120px',
  fontSize: '14px',
  lineHeight: '100%',
  color: selected ? 'white' : theme.colors.midDark,
}))

export const TextDescription = styledMUI(Typography)<{ selected: boolean }>(({ selected }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
  color: selected ? 'white' : theme.colors.midDark,
}))

export const ContentIcon = styledMUI(Box)(() => ({}))

export const IconBox = styledMUI(Box)<{ selected: boolean; variant: string }>(({ selected, variant }) => ({
  overflow: 'hidden',
  alignSelf: 'start',
  color: selected ? getIconColor(variant) : '#E2E5EC',
}))
