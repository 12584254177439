import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 10,
  padding: '70px 0 0 0',
  marginTop: '-70px',
  backgroundColor: '#fff',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 24px 24px',
  borderBottom: '1px solid #E2E5EC',
  '@media (max-width: 600px)': {
    display: 'grid',
    gap: '8px',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const WrapperButtons = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFF',
  border: '1px solid #0095FF',
  borderRadius: '50px',
  padding: 0,
}))

export const BoxContainer = styledMUI(Box)<{ width?: string }>(({ width }) => ({
  width: width ? width : 'auto',
}))

export const ButtonOrder = styledMUI(Menu)(() => ({
  margin: '10px 0 0 0',
}))

export const ButtonSelectClass = styledMUI(Menu)(() => ({
  margin: '14px 0 0 0',
  '.MuiPaper-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
  '.styled-scroll-s': {
    '::-webkit-scrollbar': {
      width: '5px',
    },

    '::-webkit-scrollbar-track': {
      borderRadius: '8px',
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: '#CCCCCC',
      border: '1px solid #CCCCCC',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
      width: '4px',
    },
    Maxheight: '350px',
  },
}))

export const ButtonMenuItem = styledMUI(MenuItem)<{ actived: boolean }>(({ actived }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  color: actived ? '#0095FF' : '#666',
}))

export const BoxOverflow = styledMUI(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
  maxHeight: '500px',
  padding: 1,
  '::-webkit-scrollbar': {
    width: '5px',
  },

  '::-webkit-scrollbar-track': {
    borderRadius: '8px',
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#CCCCCC',
    border: '1px solid #CCCCCC',
  },

  '::-webkit-scrollbar-thumb:hover': {
    background: '#aaa',
    width: '4px',
  },
}))

export const FilterContainer = styledMUI(Box)(() => ({
  position: 'sticky',
  top: '0',
  zIndex: 999,
  backgroundColor: '#fff',
  padding: '12px',
}))

export const LabelMenuItemOrder = styledMUI(Typography)(() => ({
  margin: '0 0 8px 12px',
  width: '124px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '100%',
}))

export const LabelMenuItemFilter = styledMUI(Box)(() => ({
  margin: '0 0 8px 12px',
  width: '270px',
  display: 'flex',
  alignItems: 'start',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '100%',
}))

export const WrapperMenuItemFilter = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))
