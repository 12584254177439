import { IGetActivityInformation } from '~/services/LessonSteps/types'

export const defaultDataState: IGetActivityInformation = {
  idLessonActivity: 0,
  nuOrder: 0,
  txTitle: '',
  idLessonStep: 0,
  lessonMomentActivity: [],
  momentStatus: {
    txMomentStatus: '',
    coMomentStatus: '',
    dtInserted: '',
  },
  txChallenge: '',
  lessonActivityOrientation: [
    {
      idLessonActivity: 0,
      dtInserted: '',
      idLessonActivityOrientation: 0,
      txTitle: '',
      txOrientationCode: '',
      lessonActivitySupportReference: [
        {
          idLessonActivitySupportReference: 0,
          coSupportReference: '',
          idLessonActivityOrientation: 0,
          txTitle: '',
          idMediaInformation: 0,
          mediaInformation: {
            txName: '',
            nuSize: 0,
            inPublic: false,
            coMediaLocator: '',
            txPath: '',
            txAbsoluteUrl: '',
            txContentType: '',
          },
        },
      ],
    },
  ],
  evidenceGrade: {
    dtInserted: '',
    evidence: {
      idEvidence: 0,
      dtInserted: '',
      txName: '',
      ability: {
        txName: '',
        idAbility: 0,
        dtInserted: '',
        txBgSecondaryColor: '',
        txImagePath: '',
        txBgPrimaryColor: '',
        competence: {
          txName: '',
          idCompetence: 0,
          dtInserted: '',
        },
      },
    },
  },
  category: {
    txName: '',
    txImagePath: '',
  },
}
