import { AvatarComponent } from '~/components'

import { IAvatarRandomComponentProps } from './AvatarRandom.interfaces'
import * as S from './AvatarRandom.styles'

export const AvatarRandomComponent = ({ images, max, size = 'medium' }: IAvatarRandomComponentProps) => {
  return (
    <S.AvatarGroupContainer component="ul" max={max} size={size} style={{ position: 'relative', height: '50px' }}>
      {images?.map((image, index) => {
        const offsetX = Math.random() * 10 - 5
        const overlap = 30
        const positionX = index * overlap + offsetX

        return (
          <AvatarComponent
            key={index}
            size={size}
            label={image ? image?.studentClass?.student?.txName || image?.txName : ''}
            photoUrl={image?.studentClass?.student?.txImagePath || image?.txImagePath}
            style={{
              position: 'absolute',
              left: `${positionX}px`,
              zIndex: images.length - index,
            }}
          />
        )
      })}
    </S.AvatarGroupContainer>
  )
}
