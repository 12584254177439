import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { colorsStepsActived, colorsStepsDisabled } from '~/validations'

import { IStyledNavigateProps, IStyledProps } from './StepsTabs.interfaces'

export const Tabs = styledMUI(Box)<IStyledProps>(({ type }) => ({
  height: '60px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorsStepsDisabled(type),
  borderRadius: '50px',
  padding: '8px',
  textAlign: 'center',
}))

export const TabsNavigate = styledMUI(Box)<IStyledNavigateProps>(({ typeNavigation, maxWidth }) => ({
  height: '60px',
  width: maxWidth || '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorsStepsDisabled(typeNavigation),
  borderRadius: '50px',
  padding: '8px',
  textAlign: 'center',
}))

export const TabNavigate = styledMUI(Box)<IStyledNavigateProps>(({ actived, typeNavigation, length }) => ({
  height: '40px',
  width: `calc(100% / ${length}) `,
  display: 'flex',
  padding: '0px 4px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: actived === 'true' ? colorsStepsActived(typeNavigation) : colorsStepsDisabled(typeNavigation),
  borderRadius: '50px',
  gap: typeNavigation === 'default' ? 'none' : '8px',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    width: actived ? '105%' : '33%',
    padding: '0px 8px',
  },
}))

export const TextTabNavigate = styledMUI(Typography)<IStyledNavigateProps>(({ actived, typeNavigation }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  marginLeft: '4px',
  color: actived === 'true' ? '#fff' : colorsStepsActived(typeNavigation ?? 'default'),
}))

export const Tab = styledMUI(Box)<IStyledProps>(({ actived, type, status }) => ({
  height: '40px',
  width: status ? '10%' : '33%',
  display: 'flex',
  padding: '0px 4px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: actived === 'true' ? colorsStepsActived(type) : colorsStepsDisabled(type),
  borderRadius: '50px',
  gap: type === 'default' ? 'none' : '8px',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    width: actived ? '105%' : '33%',
    padding: '0px 8px',
  },
}))

export const TabDefault = styledMUI(Box)<IStyledProps>(({ actived, type }) => ({
  height: '40px',
  display: 'flex',
  padding: '0px 4px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: actived === 'true' ? colorsStepsActived(type) : colorsStepsDisabled(type),
  borderRadius: '50px',
  gap: type === 'default' ? 'none' : '8px',
  flexGrow: '3',
  cursor: 'pointer',
}))

export const TextTab = styledMUI(Typography)<IStyledProps>(({ actived, type }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  marginLeft: '4px',
  color: actived === 'true' ? '#fff' : colorsStepsActived(type),
}))

export const TextTabStep = styledMUI(Typography)<IStyledProps>(({ actived, type }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '100%',
  color: actived === 'true' ? '#fff' : colorsStepsActived(type),
}))
