import { useNavigate, useLocation } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'
import { Box } from '@mui/material'
import EmptyTrail from '~/assets/images/Trilha.png'

import { TrackMomentList } from '~/services/Class/types'

import { ButtonComponent } from '~/components'

import { ClassTrack } from '~/pages/private/Classes/ListClasses/interfaces'

import { statusColor } from '~/validations/statusColorsEM'
import { statusIcon } from '~/validations/statusIcon'

import TagClass from '../TagClass/TagClass'
import * as S from './CardClass.styles'

interface CardClassProps {
  classTrack: ClassTrack
  textNoTrails: string
}

export const CardClass = ({ classTrack, textNoTrails }: CardClassProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const pathSelect = classTrack?.isInProjectGrade
    ? `/projects-available/${classTrack?.idClass}`
    : `/trails-available/${classTrack?.idClass}`

  const trackTrailTag = (track: TrackMomentList[]) => {
    const handleTagIcon = (nuTrackOrder: number, status: string, isNext: boolean) => {
      const nextTrackOrder = track.find((t) => t.isNext)?.nuTrackOrder

      if (nextTrackOrder && nuTrackOrder > nextTrackOrder) {
        return <S.TagIconNext>{nuTrackOrder}</S.TagIconNext>
      }
      if (isNext) {
        return (
          <S.TagIconNext statusColor={statusColor(status).indexColor} isActive>
            {nuTrackOrder}
          </S.TagIconNext>
        )
      }
      if (status) {
        return statusIcon(status, 14)
      }
      return <S.TagIconNext>{nuTrackOrder}</S.TagIconNext>
    }
    return (
      <S.ContainerTrackTrailTag>
        {track.map((track) => (
          <S.TrackTrailTagItem key={track.nuTrackOrder}>
            {handleTagIcon(track.nuTrackOrder, track.coMomentStatus, track.isNext)}
          </S.TrackTrailTagItem>
        ))}
      </S.ContainerTrackTrailTag>
    )
  }

  return (
    <S.CardClass
      key={classTrack?.coGrade}
      onClick={
        textNoTrails === 'Escolher uma trilha' && classTrack.inStatus
          ? () => navigate(pathSelect, { state: { from: location.pathname } })
          : () => navigate(`/class-details/${classTrack?.idClass}`)
      }
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'grid'} gap={'8px'}>
          <S.TitleClass>{classTrack?.txName}</S.TitleClass>

          <S.TagsContainer>
            <TagClass texts={[classTrack?.txClassShift]} variant="default" />

            {!classTrack?.inStatus && <TagClass texts={['Desativada']} variant="default" />}
          </S.TagsContainer>
        </Box>
        <ButtonComponent
          onClick={
            textNoTrails === 'Escolher uma trilha' && classTrack.inStatus
              ? () => navigate(pathSelect)
              : () => navigate(`/class-details/${classTrack?.idClass}`)
          }
          text={textNoTrails}
          iconEnd={<UilArrowRight size={16} />}
          variant="solid"
          size={'xsmall'}
          fontWeight={'normal'}
        />
      </Box>
      <S.ContainerTrailsClass>
        {classTrack?.classTrackList && classTrack?.classTrackList?.length > 0 ? (
          <>
            {classTrack?.classTrackList?.slice(0, 2).map((trail) => (
              <S.CardTrailsClass key={trail.idTrack}>
                <S.TitleTrail>{trail.txTitle}</S.TitleTrail>
                {trackTrailTag(trail.trackMomentList)}
              </S.CardTrailsClass>
            ))}
            {classTrack?.classTrackList.length > 2 && (
              <S.MoreTrailsContainer>
                <S.MoreTrailsTitle> + {classTrack?.classTrackList?.length - 2} trilhas</S.MoreTrailsTitle>
              </S.MoreTrailsContainer>
            )}
          </>
        ) : (
          <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            textAlign={'center'}
            justifyContent={'center'}
          >
            <Box>
              <img src={EmptyTrail} alt={'No trails'} />
              <S.NoTrailsTitle>Nenhuma trilha</S.NoTrailsTitle>
            </Box>
          </Box>
        )}
      </S.ContainerTrailsClass>
    </S.CardClass>
  )
}
