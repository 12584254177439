import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { ILessonPlanningPageContextProps, ILessonPlanningPageProviderProps } from './interface'

const LessonPlanningPageContext = createContext<ILessonPlanningPageContextProps>({
  evidencesByActivityOnStepTwo: undefined,
  fixedEvidences: undefined,
  guidance: '',
  steps: {},
  txTitleLesson: '',
  stepTwoMomentActivites: undefined,
})

const LessonPlanningPageProvider = ({ children }: ILessonPlanningPageProviderProps) => {
  const { idLessonMoment } = useParams()

  const { getOfflineInformation } = useOfflineMode()

  const fixedEvidences = getOfflineInformation?.[0]?.lessonSteps
    ?.filter((step) => step.idLessonMoment === Number(idLessonMoment))
    ?.map((step) => {
      return {
        idLessonStep: step?.idLessonStep,
        nuOrder: step?.nuOrder,
        lessonActivityEvidence: step?.lessonActivity?.[0]?.lessonActivityEvidence?.find(
          (item: any) => item.coEvidenceType === 'FIX',
        )?.evidenceGrade,
      }
    })

  const guidance =
    getOfflineInformation?.[0]?.lessonMomentsDetails?.find((moment) => moment.idLessonMoment === Number(idLessonMoment))
      ?.lesson.txGuidance || ''

  const evidencesByActivityOnStepTwo = getOfflineInformation?.[0]?.lessonSteps?.find(
    (step) => step.idLessonMoment === Number(idLessonMoment) && step.nuOrder === 2,
  )?.lessonActivity

  const stepTwoMomentActivites = getOfflineInformation?.[0]?.lessonMomentActivities?.filter(
    (step) => step.idLessonMoment === Number(idLessonMoment),
  )

  const steps = getOfflineInformation?.[0]?.lessonSteps?.filter(
    (step) => step.idLessonMoment === Number(idLessonMoment),
  )

  const txTitleLesson =
    getOfflineInformation?.[0]?.moments.find((step) => step.idMoment === Number(idLessonMoment) && step.nuOrder === 2)
      ?.txTitle || ''

  const lessonPlanningPageProviderValues = useMemo(() => {
    return {
      evidencesByActivityOnStepTwo,
      fixedEvidences,
      guidance,
      steps,
      stepTwoMomentActivites,
      txTitleLesson,
    }
  }, [stepTwoMomentActivites, evidencesByActivityOnStepTwo, fixedEvidences, guidance, steps, txTitleLesson])

  return (
    <LessonPlanningPageContext.Provider value={lessonPlanningPageProviderValues}>
      {children}
    </LessonPlanningPageContext.Provider>
  )
}

const useLessonPlanningPageContext = () => useContext(LessonPlanningPageContext)

export { LessonPlanningPageProvider, useLessonPlanningPageContext }
