import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  minHeight: '300px',
  minWidth: '400px',
  maxWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  padding: '28px',
  gap: '24px',
}))

export const ContainerDeep = styledMUI(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '16px',
}))

export const ContentIcon = styledMUI(Box)(() => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.negativeLight,
  borderRadius: '50px',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  color: theme.colors.lowMedium,
  textAlign: 'center',
}))

export const Row = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  backgroundColor: theme.colors.highPure,
  padding: 0,
}))

export const TextLabelBack = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: theme.colors.lowPure,
}))

export const Title = styledMUI(Typography)(() => ({
  width: '85%',
  fontWeight: 750,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'center',
  color: theme.colors.lowPure,
}))

export const TitleLesson = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'left',
  color: theme.colors.lowPure,
}))

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: '16px',
  padding: '16px',
  backgroundColor: theme.colors.midLight,
  borderRadius: '8px',
}))

export const Wrapper = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  textAlign: 'left',
  color: '#000',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
}))

export const WrapperClass = styledMUI(Box)(() => ({
  width: '100%',
  borderRadius: '4px',
  border: `1px solid ${theme.colors.midDark}`,
  borderColor: theme.colors.midDark,
  color: theme.colors.midDark,
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
  gap: '8px',
}))
