import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

const impersonatedTokenAPI = async (token: string) => {
  const { data } = await HTTPClient.get(`auth/impersonated-token/${token}`)

  return data
}

export const useServiceImpersonatedToken = (
  token: string,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) => useQuery(['impersonatedToken', token], () => impersonatedTokenAPI(token), options)
