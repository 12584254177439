/* eslint-disable no-console */
import React, { useState } from 'react'

import { UilArrowLeft, UilArrowRight, UilPlay } from '@iconscout/react-unicons'
import { Box } from '@mui/material'

import { MediaProps } from '../MediaInformation/MediaInformation'
import * as S from './Carousel.styles'

interface Props {
  length: number
  children: JSX.Element[] | React.ReactNode
  fullScreen?: boolean
  mediaImageAndVideoList?: MediaProps[]
}

export const CarouselComponent: React.FC<Props> = ({ length, children, fullScreen, mediaImageAndVideoList }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const goToNextIndex = (): void => {
    const nextIndex = currentIndex + 1
    if (nextIndex === length) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(nextIndex)
    }
  }

  const goToPrevMedia = (): void => {
    const prevIndex = currentIndex - 1
    if (prevIndex < 0) {
      setCurrentIndex(length - 1)
    } else {
      setCurrentIndex(prevIndex)
    }
  }
  const handleImageClick = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <>
      <S.Container fullScreen={!!fullScreen}>
        <S.Button onClick={goToPrevMedia} enabled={currentIndex !== 0} disabled={currentIndex === 0}>
          <UilArrowLeft size={22} />
        </S.Button>
        <S.Content fullScreen={!!fullScreen}>
          <S.Media fullScreen={!!fullScreen}>{(children as JSX.Element[])[currentIndex]}</S.Media>
        </S.Content>
        <S.Button onClick={goToNextIndex} enabled={currentIndex !== length - 1} disabled={currentIndex === length - 1}>
          <UilArrowRight size={22} />
        </S.Button>
      </S.Container>

      {fullScreen && (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'4px'}>
          {mediaImageAndVideoList?.map((media, index) => (
            <>
              {media?.mediaType?.includes('video') ? (
                <S.ContainerVideo key={media.srcMedia}>
                  <S.IconPlay>
                    <UilPlay size={12} />
                  </S.IconPlay>
                  <S.Video src={media.srcMedia} key={media.srcMedia} onClick={() => handleImageClick(index)} />
                </S.ContainerVideo>
              ) : (
                <>
                  <S.Image src={media?.srcMedia} key={media.srcMedia} onClick={() => handleImageClick(index)} />
                </>
              )}
            </>
          ))}
        </Box>
      )}
    </>
  )
}
