import { Box, BoxProps } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

interface ClassSpanInfoProps extends BoxProps {
  variant: 'default' | 'underline' | 'inactive'
  isFirst?: boolean | false
}

export const ContainerTrackClass = styledMUI(Box)<ClassSpanInfoProps>(({ variant }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.colors.lowLight}`,
  width: 'fit-content',
  height: '24px',
  padding: '4px',
  borderRadius: '4px',
  '&:last-child': {
    padding: '4px',
  },

  ...(variant === 'underline' && {
    border: `1px solid ${theme.colors.actionPure}`,
  }),

  ...(variant === 'inactive' && {
    border: `1px solid ${theme.colors.lowLight}`,
    backgroundColor: theme.colors.lowLight,
  }),
}))

export const ClassSpanInfo = styledMUI(Box)<ClassSpanInfoProps>(({ variant, isFirst }) => ({
  fontSize: '14px',
  padding: '0 6px',
  color: '#7c8189',
  borderRight: `1px solid ${theme.colors.lowLight}`,
  '&:last-child': {
    borderRight: '0px solid',
  },
  ...(isFirst &&
    variant === 'underline' && {
      color: `${theme.colors.actionPure}`,
      fontWeight: 'bolder',
    }),

  ...(variant === 'inactive' && {
    color: `${theme.colors.highPure}`,
    fontWeight: 'bolder',
  }),
}))
