import { createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useDashboardInformation } from '~/services/Dashboard'

import { IStudentDashboardPageContextProps, IStudentDashboardPageProviderProps, ResponseStudent } from './interfaces'
import { GET_STUDENT } from './schemas.queries'

const StudentDashboardPageContext = createContext<IStudentDashboardPageContextProps>({
  isLoading: false,
  dataDashboard: undefined,
  student: undefined,
  coGrade: '',
  studentImage: '',
  studentID: 0,
  isLoadingStudentInfo: false,
  txGrade: '',
  currentStage: '',
  txNameClass: '',
})

const StudentDashboardPageProvider = ({ children }: IStudentDashboardPageProviderProps) => {
  const { studentId } = useParams()

  const queryClient = useQueryClient()

  const {
    data: dataStudent,
    isLoading: isLoadingStudentInfo,
    remove: removeStudentInfo,
  } = useQueryGraphQLClient<ResponseStudent>('user', 'GET_STUDENT_INFO', GET_STUDENT, {
    idUser: Number(studentId),
  })
  const coGrade = dataStudent?.userById?.items[0].student[0].studentClass[0].class.schoolGrade.grade.coGrade as string

  const txNameClass = dataStudent?.userById?.items[0].student[0].studentClass[0].class.txName as string

  const { data: dataDashboard, isLoading, remove, refetch } = useDashboardInformation(Number(studentId), coGrade)

  useEffect(() => {
    if (coGrade) {
      refetch().then()
    }
  }, [coGrade, refetch])

  useEffect(() => {
    queryClient.invalidateQueries('GET_STUDENT_INFO').then()
    remove()
    removeStudentInfo()
    refetch().then()
  }, [queryClient, refetch, remove, removeStudentInfo, studentId])

  const studentID = Number(studentId)

  const studentImage = dataStudent?.userById?.items[0]?.txImagePath as string

  const txGrade = dataStudent?.userById?.items[0]?.student[0]?.studentClass?.filter((i: any) => i.inStatus === true)[0]
    .class.schoolGrade.grade.txGrade as string

  const currentStage = dataDashboard?.classes?.find((c) => c.coGrade === coGrade)?.coStage

  const student = dataStudent?.userById?.items[0]
  const studentDashboardPageProviderValues = useMemo(() => {
    return {
      dataDashboard,
      isLoading,
      student,
      coGrade,
      txNameClass,
      studentID,
      isLoadingStudentInfo,
      studentImage,
      txGrade,
      currentStage,
    }
  }, [
    isLoading,
    dataDashboard,
    student,
    coGrade,
    txNameClass,
    studentID,
    isLoadingStudentInfo,
    studentImage,
    txGrade,
    currentStage,
  ])

  return (
    <StudentDashboardPageContext.Provider value={studentDashboardPageProviderValues}>
      {children}
    </StudentDashboardPageContext.Provider>
  )
}

const useStudentDashboardPageContext = () => useContext(StudentDashboardPageContext)

export { StudentDashboardPageProvider, useStudentDashboardPageContext }
