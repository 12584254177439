import { gql } from 'graphql-request'

export const GET_CLASSES_WEEK = gql`
  query getClassesWeek($dateStart: FlexDateTime!, $dateEnd: FlexDateTime!) {
    scheduledMomentByProfessor(
      dtScheduleStart: $dateStart
      dtScheduleEnd: $dateEnd
      where: { coMomentStatus: { neq: "AGEN" } }
    ) {
      items {
        idMoment
        coMomentStatus
        txMomentStatus
        nuOrder
        dtSchedule
        txTitle
        txTrackTitle
        idMomentTrack

        class {
          txName
          idClass

          schoolGrade {
            grade {
              txGrade
              coStage
            }
          }
        }
      }
    }
  }
`
