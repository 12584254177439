import { CustomDndProvider } from '~/components'

import { ThirdStepPageProvider } from './context'
import { ThirdStepView } from './view'

export const ThirdStepPageOffline = () => {
  return (
    <ThirdStepPageProvider>
      <CustomDndProvider>
        <ThirdStepView />
      </CustomDndProvider>
    </ThirdStepPageProvider>
  )
}
