import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import {
  IGetTrailListResponse,
  IGetTrailResponse,
  ProjectTrailInformationResponse,
  IGetListOfTrailsResponse,
} from './types'

export const getTrailLessonInformation = async (classId: number) => {
  const { data } = await HTTPClient.get(`/track/class/${classId}`)
  return data
}

export const useTrailLessonInformation = (classId: number, enabled?: boolean) =>
  useQuery<IGetTrailListResponse>(['TrailLessonInformation'], () => getTrailLessonInformation(classId), {
    enabled: enabled ? enabled : false,
  })

export const getTrailById = async (classId: number, idTrack: number) => {
  const { data } = await HTTPClient.get(`/track/class/${classId}/track/${idTrack}`)

  return data
}

export const useGetTrailById = (classId: number, idTrack: number) =>
  useQuery<IGetTrailResponse>(['TrailLessonInformationByTrack'], () => getTrailById(classId, idTrack))

export const getTrailProjectInformation = async (classId: number) => {
  const { data } = await HTTPClient.get(`/track/class/${classId}`)

  return data
}

export const useTrailProjectInformation = (classId: number, enabled?: boolean) =>
  useQuery<ProjectTrailInformationResponse>(['TrailProjectInformation'], () => getTrailProjectInformation(classId), {
    enabled: enabled ? enabled : false,
  })

export const getAvailableTrails = async (classId: number) => {
  const { data } = await HTTPClient.get(`/track/class/${classId}/available-track`)

  return data
}

export const useAvailableTrails = (classId: number, enabled: boolean) =>
  useQuery<IGetListOfTrailsResponse>(['AvailableTrails'], () => getAvailableTrails(classId), {
    enabled: enabled,
  })

export const getAvailableTrail = async (classId: number, idTrack: number) => {
  const { data } = await HTTPClient.get(`/track/class/${classId}/available-track/${idTrack}`)

  return data
}

export const useAvailableTrail = (classId: number, idTrack: number, enabled: boolean) =>
  useQuery<IGetListOfTrailsResponse>(['AvailableTrail'], () => getAvailableTrail(classId, idTrack), {
    enabled: enabled,
  })

export const getTrailDetails = async (idLesson: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/lesson/${idLesson}/details/for-stage/${nuOrder}`)

  return data
}

export const useDetailTrails = (idLesson: number, nuOrder: number, enabled: boolean) =>
  useQuery<IGetListOfTrailsResponse>(['TrailDetailsProject'], () => getTrailDetails(idLesson, nuOrder), {
    enabled: enabled,
  })

export const getLesson = async (idLesson: number) => {
  const { data } = await HTTPClient.get(`/lesson/${idLesson}`)

  return data
}

export const useLesson = (idLesson: number, enabled: boolean) =>
  useQuery<IGetListOfTrailsResponse>(['LessonGet'], () => getLesson(idLesson), {
    enabled: enabled,
  })

// PROJECT

export const getProjectTrailDetails = async (idProjectTrack: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/project-track/${idProjectTrack}/stage/${nuOrder}`)

  return data
}

export const useProjectDetailTrails = (idProjectTrack: number, nuOrder: number, enabled: boolean) =>
  useQuery<IGetListOfTrailsResponse>(['TrailDetails'], () => getProjectTrailDetails(idProjectTrack, nuOrder), {
    enabled: enabled,
  })

export const getExploreData = async (idProject: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/project/${idProject}/details/for-stage/${nuOrder}`)

  return data
}

export const useExploreData = (idProject: number, nuOrder: number, enabled: boolean) =>
  useQuery<IGetListOfTrailsResponse>(['exploreData'], () => getExploreData(idProject, nuOrder), {
    enabled: enabled,
  })
