import { ScheduleByWeekPageProvider } from './context'
import { ScheduleByWeekView } from './view'

export const ScheduleByWeekPage = () => {
  return (
    <ScheduleByWeekPageProvider>
      <ScheduleByWeekView />
    </ScheduleByWeekPageProvider>
  )
}
