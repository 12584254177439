import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '70px 0 0 0',
  marginTop: '-70px',
  backgroundColor: '#fff',
  zIndex: '1',
  '@media (max-width: 600px)': {
    position: 'initial',
    padding: '0',
    marginTop: '0',
    width: '100%',
  },
}))

export const Chips = styled.ul`
  display: grid;
`

export const Chip = styled.div`
  background-color: ${({ theme }) => theme.colors.actionPure};
  border-radius: 4px;
  color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ContentSelectSchedule = styled.div``

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 24px 24px',
  borderBottom: '1px solid #E2E5EC',
  '@media (max-width: 600px)': {
    marginTop: '0',
    gap: '16px',
    padding: '24px',
    display: 'grid',
    width: '100%',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const ContentSelectDate = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
}))

export const ContentSelectDay = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #0095FF',
  borderRadius: '50%',
  padding: 0,
}))

export const ContentDatePicker = styledMUI(Box)(() => ({
  width: 155,
}))
