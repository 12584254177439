import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { gaEvents } from '~/events'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'
import { useSnackbar } from '~/_context/Snackbar'

import { useServiceUpdateProjectStatus } from '~/services/Lesson'
import { useServiceUpdateTrail, useServiceUpdateTrailProject } from '~/services/TrailEdit'
import { useServiceEngageTrail } from '~/services/TrailSchedule'
import { useServiceDesengageTrailProject } from '~/services/TrailsVacate'

import { IScheduleDataComponentProps, IScheduleModalPageProvider, Moment } from './ScheduleModal.interfaces'

const ScheduleModalPageContext = createContext<IScheduleDataComponentProps>({
  dates: [],
  handleDateChange: () => Object,
  disabledDate: () => Object,
  handleSelectFormChange: () => Object,
  datesFormatted: '',
  validateMoment: () => Object,
  isEM: false,
  setDates: () => Object,
  handleCloseModal: () => Object,
  buttonEnabled: false,
  message: '',
  handleConfirmDesengage: () => Object,
  handleConfirm: () => Object,
  buttonEnabledDesengage: false,
  handleEngageTrail: () => Object,
  confirmModal: false,
  loadingUpdateTrailProject: false,
  loadingOccupyTrail: false,
  loadingData: false,
  loadingRequestSchedule: false,
  loadingRequestScheduleProject: false,
  handleCloseConfirm: () => Object,
  handleCloseSuccess: () => Object,
  handleDesengageTrail: () => Object,
  openModalSucess: false,
  confirmModalDesengage: false,
  handleCloseConfirmDesengage: () => Object,
  loadingDataLeaveProject: false,
  loadingDataLeave: false,
  openModalSucessDesengage: false,
  handleCloseSuccessDesengage: () => Object,
  messageDesengage: '',
  idMoment: 0,
  status: '',
  idTrack: 0,
  open: false,
})
const ScheduleModalPageProvider = ({
  children,
  moments,
  queryName,
  actionCloseModal,
  type,
  coStage,
  classId,
  routing,
  router,
  stage,
  lesson,
  refetch,
  idTrack,
  idMoment,
  open,
  status,
}: IScheduleModalPageProvider) => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { showSnackbarError } = useSnackbar()
  const navigate = useNavigate()
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [message, setMessage] = useState('')
  const [buttonEnabledDesengage, setButtonEnabledDesengage] = useState(false)
  const [messageDesengage, setMessageDesengage] = useState('')
  const [dates, setDates] = useState<Moment[]>([])
  const [isSame, setIsSame] = useState<boolean>(false)
  const isProject = dates?.find((item) => item.idProjectTrackStage)
  const isEM = dates?.find((item) => item.professor)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [confirmModalDesengage, setConfirmModalDesengage] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [openModalSucessDesengage, setOpenModalSucessDesengage] = useState<boolean>(false)

  const idUser = user?.id_user

  const { mutate: handleChangeStatus } = useServiceUpdateProjectStatus('AUPL', Number(idMoment), Number(classId), {
    onSuccess: () => queryClient.invalidateQueries(['getProjectByID']),
  })
  const {
    mutate: requestScheduleProject,
    error: errorRequestScheduleProject,
    isLoading: loadingRequestScheduleProject,
    data: dataRequestScheduleProject,
  } = useServiceEngageTrail()

  useEffect(() => {
    if (!loadingRequestScheduleProject && errorRequestScheduleProject) {
      showSnackbarError(errorRequestScheduleProject?.response?.data?.message)
    }
  }, [errorRequestScheduleProject, loadingRequestScheduleProject, showSnackbarError])

  useEffect(() => {
    if (!loadingRequestScheduleProject && dataRequestScheduleProject) {
      handleCloseConfirm()
      handleOpenSuccess()
    }
    // eslint-disable-next-line
  }, [dataRequestScheduleProject, loadingRequestScheduleProject])

  const {
    mutate: requestSchedule,
    error: errorRequestSchedule,
    isLoading: loadingRequestSchedule,
    data: dataRequestSchedule,
  } = useServiceEngageTrail()

  useEffect(() => {
    if (!loadingRequestSchedule && errorRequestSchedule) {
      showSnackbarError((errorRequestSchedule as any)?.response?.data?.message)
    }
  }, [errorRequestSchedule, loadingRequestSchedule, showSnackbarError])

  useEffect(() => {
    if (!loadingRequestSchedule && dataRequestSchedule) {
      handleCloseConfirm()
      handleOpenSuccess()
    }
    // eslint-disable-next-line
  }, [dataRequestSchedule, loadingRequestSchedule])

  const {
    mutate: UpdateTrail,
    error: errorData,
    isLoading: loadingData,
    data: dataResponse,
  } = useServiceUpdateTrail({
    onSuccess: () => {
      queryClient.invalidateQueries(['TrailLessonInformation'])
      queryClient.invalidateQueries(['TrailProjectInformation'])
      queryClient.invalidateQueries(['get-trails-by-class-ID'])
      queryClient.invalidateQueries(['DesengageTrail'])
      queryClient.invalidateQueries(['get-class-infos-by-class-ID'])
    },
  })
  const isIncluded = Array.isArray(queryName)
    ? queryName.some((name) => ['TrailProjectF2'].includes(name))
    : ['TrailProjectF2'].includes(queryName)

  useEffect(() => {
    if (!loadingData && errorData) {
      showSnackbarError(errorData?.response?.data?.message)
    }
  }, [errorData, loadingData, showSnackbarError])

  useEffect(() => {
    if (!loadingData && dataResponse) {
      handleCloseConfirm()
      if (refetch) {
        refetch()
      }
      if (routing) {
        navigate(router)
      }
      handleOpenSuccess()
    }
    // eslint-disable-next-line
  }, [dataResponse, loadingData])

  const {
    mutate: updateTrailProject,
    error: errorUpdateTrailProject,
    isLoading: loadingUpdateTrailProject,
    data: dateUpdateTrailProject,
  } = useServiceUpdateTrailProject()

  useEffect(() => {
    if (!loadingUpdateTrailProject && errorUpdateTrailProject) {
      showSnackbarError(errorUpdateTrailProject.response.data?.message || 'Ocorreu um Erro')
    }
  }, [errorUpdateTrailProject, loadingUpdateTrailProject, showSnackbarError])

  useEffect(() => {
    if (!loadingUpdateTrailProject && dateUpdateTrailProject) {
      if (['PEND', 'AUPE'].includes(status as string) && isSame) {
        handleChangeStatus()
      }
      handleCloseConfirm()
      if (refetch) {
        refetch()
      }

      handleOpenSuccess()
    }
    // eslint-disable-next-line
  }, [dateUpdateTrailProject, loadingUpdateTrailProject])

  const {
    mutate: handleLeaveTrailProject,
    error: errorDataLeaveProject,
    isLoading: loadingDataLeaveProject,
    data: dataResponseLeaveProject,
  } = useServiceDesengageTrailProject({
    onSuccess: () => {
      queryClient.invalidateQueries(['TrailLessonInformation'])
      queryClient.invalidateQueries(['TrailProjectInformation'])
      queryClient.invalidateQueries(['get-trails-by-class-ID'])
      queryClient.invalidateQueries(['DesengageTrail'])
      queryClient.invalidateQueries(['get-class-infos-by-class-ID'])
    },
  })

  useEffect(() => {
    if (!loadingDataLeaveProject && errorDataLeaveProject) {
      showSnackbarError(errorDataLeaveProject?.response?.data?.message || 'Ocorreu um Erro')
    }
  }, [errorDataLeaveProject, loadingDataLeaveProject, showSnackbarError])

  useEffect(() => {
    if (!loadingDataLeaveProject && dataResponseLeaveProject) {
      handleCloseConfirmDesengage()
      if (routing && isIncluded) {
        navigate(router)
      }
      handleOpenSuccessDesengage()
    }
    // eslint-disable-next-line
  }, [dataResponseLeaveProject, loadingDataLeaveProject])

  const {
    mutate: handleOccupyTrail,
    error: errorOccupyTrail,
    isLoading: loadingOccupyTrail,
    data: dataOccupyTrail,
  } = useServiceEngageTrail()

  useEffect(() => {
    if (!loadingOccupyTrail && errorOccupyTrail) {
      errorOccupyTrail.response.data.errors?.moments.map((error) => {
        showSnackbarError(error?.response?.data?.message || 'Ocorreu um Erro')
      })
      showSnackbarError(errorOccupyTrail?.response?.data?.message || 'Ocorreu um Erro')
    }
  }, [errorOccupyTrail, loadingOccupyTrail, showSnackbarError])

  useEffect(() => {
    if (!loadingOccupyTrail && dataOccupyTrail) {
      handleCloseConfirm()
      handleOpenSuccess()
    }
    // eslint-disable-next-line
  }, [dataOccupyTrail, loadingOccupyTrail, queryClient, queryName])

  const handleConfirm = useCallback(() => {
    setConfirmModal(true)
  }, [setConfirmModal])

  const handleCloseConfirm = useCallback(() => {
    setConfirmModal(false)
  }, [setConfirmModal])

  const handleOpenSuccess = useCallback(() => {
    setOpenModalSucess(true)
  }, [setOpenModalSucess])

  const handleCloseSuccess = useCallback(() => {
    queryClient.removeQueries({ queryKey: queryName, exact: true })

    if (refetch) {
      refetch()
    }
    if (routing && !isIncluded) {
      navigate(router)
    }
    setOpenModalSucess(false)
    actionCloseModal()
  }, [queryClient, queryName, refetch, routing, isIncluded, actionCloseModal, navigate, router])

  const handleConfirmDesengage = useCallback(() => {
    setConfirmModalDesengage(true)
  }, [setConfirmModalDesengage])

  const handleCloseConfirmDesengage = useCallback(() => {
    setConfirmModalDesengage(false)
  }, [setConfirmModalDesengage])

  const handleOpenSuccessDesengage = useCallback(() => {
    setOpenModalSucessDesengage(true)
  }, [setOpenModalSucessDesengage])

  const handleCloseSuccessDesengage = useCallback(() => {
    queryClient.removeQueries({ queryKey: queryName, exact: true })
    if (routing) {
      navigate(router)
    }
    setOpenModalSucessDesengage(false)
    actionCloseModal()
  }, [queryClient, queryName, routing, navigate, router, setOpenModalSucessDesengage, actionCloseModal])

  useEffect(() => {
    if (moments && type === 'EM-edit') {
      const initialDates = moments?.map((moment: Moment) => ({
        dtSchedule: moment?.dtSchedule,
        nuOrder: moment?.projectTrackStage?.nuOrder,
        idMoment: moment?.idMoment,
        title: moment?.projectTrackStage?.txTitle || '',
        idProjectTrackStage: moment?.projectTrackStage?.idProjectTrackStage,
        idProfessor: moment?.professor?.idUser || moment?.professor?.idUserProfessor,
        professor: moment?.professor,
        coMomentStatus: moment?.coMomentStatus,
      }))
      setDates(initialDates as [])
    }

    if (stage && type === 'EM-schedule') {
      const initialDates = stage?.map((stage) => ({
        dtSchedule: '',
        nuOrder: stage.nuOrder,
        title: stage.txTitle,
        idProjectTrackStage: stage.idProjectTrackStage,
        idProfessor: undefined,
      }))
      setDates(initialDates as [])
    }

    if (moments && type === 'edit') {
      const initialDates = moments
        ?.map((moment: Moment) => {
          const commonFields = {
            dtSchedule: moment?.dtSchedule,
            idMoment: moment?.idMoment,
            coMomentStatus: moment?.coMomentStatus,
          }

          if ('lessonTrackGroup' in moment) {
            return {
              ...commonFields,
              nuOrder: moment.lessonTrackGroup.nuOrder,
              title: moment.txMomentTitle || '',
              idLessonTrackGroup: moment.lessonTrackGroup.idLessonTrackGroup,
            }
          } else if ('projectTrackStage' in moment) {
            return {
              ...commonFields,
              nuOrder: moment.projectTrackStage.nuOrder,
              title: moment.txMomentTitle || '',
              idProjectTrackStage: moment.projectTrackStage.idProjectTrackStage,
            }
          }

          return null
        })
        .filter(Boolean)

      setDates(initialDates as [])
    }

    if (lesson && type === 'schedule') {
      const initialDates = lesson?.map((lesson) => ({
        dtSchedule: '',
        nuOrder: lesson.lessonTrackGroupNuOrder,
        title: lesson.txTitle,
        idLessonTrackGroup: lesson.idLessonTrackGroup,
      }))
      setDates(initialDates as [])
    }

    if (stage && type === 'schedule') {
      const initialDates = stage?.map((stage) => ({
        dtSchedule: '',
        nuOrder: stage.nuOrder,
        title: stage.txTitle || '',
        idProjectTrackStage: stage.idProjectTrackStage,
        idProjectTrack: stage.idProjectTrack,
      }))
      setDates(initialDates as [])
    }
    // eslint-disable-next-line
  }, [moments, stage])

  const handleDateChange = useCallback(
    ({ index, newDate, type }: { index: number; newDate: string; type?: string }) => {
      const newDates = [...dates]
      const stageIndex = stage?.length || 0

      if (index === 0) {
        if (type === 'time') {
          const newTime = dayjs(newDate)

          for (let i = 1; i <= stageIndex - 1; i++) {
            const nextIndex = index + i

            while (newDates.length <= nextIndex) {
              newDates.push({ dtSchedule: '' })
            }

            if (newDates[nextIndex].dtSchedule) {
              const currentDateTime = dayjs(newDates[nextIndex].dtSchedule)
              const newDateTime = currentDateTime
                .set('hour', newTime.hour())
                .set('minute', newTime.minute())
                .set('second', newTime.second())
              newDates[nextIndex].dtSchedule = newDateTime.format('YYYY-MM-DD')
            }

            newDates[nextIndex].nuOrder = nextIndex + 1
          }
        } else {
          for (let i = 1; i <= stageIndex - 1; i++) {
            const nextIndex = index + i

            while (newDates.length <= nextIndex) {
              newDates.push({ dtSchedule: '' })
            }

            if (!newDates[nextIndex].dtSchedule) {
              const currentDate = dayjs(newDate)
              const newDateIndex = currentDate.add(7 * i, 'days').format('YYYY-MM-DD')
              newDates[nextIndex].dtSchedule = newDateIndex
            }

            newDates[nextIndex].nuOrder = nextIndex + 1
          }
        }
      }

      newDates[index].dtSchedule = newDate
      setDates(newDates)
      gaEvents.eventInputCalendar()
    },
    [dates, stage, setDates],
  )

  const validateMoment = useCallback(
    (moment: Moment) => {
      if (['EM-edit'].includes(type)) {
        const condition2 = moment.idProfessor !== idUser
        const condition3 = ['FINA', 'AVPE'].includes(moment.coMomentStatus)
        return condition2 || condition3
      }
      if (['edit'].includes(type)) {
        const condition3 = ['FINA', 'AVPE'].includes(moment.coMomentStatus)
        return condition3
      }
    },
    [type, idUser],
  )

  const datesFormatted = dates?.map((date) => {
    const dtSchedule = date.dtSchedule
    const disabled = validateMoment(date)
    return { dtSchedule, disabled }
  })

  const disabledDate = useCallback(
    (date: dayjs.Dayjs, order: number) => {
      const numDates = dates?.length
      const today = dayjs()
      const dateList = Array.from({ length: numDates }, (_, i) => dayjs(dates[i].dtSchedule).startOf('day'))

      if (date.isBefore(today.startOf('day'))) {
        return true
      }
      for (let i = 1; i <= numDates; i++) {
        if (order === i) {
          if (date.isAfter(dateList[i - 1]) && date.isBefore(dateList[i])) {
            return false
          }
          if (date.isBefore(dateList[i - 2]) && i !== numDates && dateList[i - 2]) {
            return true
          }
          if (date.isAfter(dateList[i]) && i !== numDates) {
            return true
          }
          if (date.isBefore(dateList[i - 2]) && i === numDates) {
            return true
          }
          if (date.isSame(dateList[i]) && i === numDates) {
            return false
          }
        }
      }

      return false
    },
    [dates],
  )

  const handleEngageTrail = useCallback(() => {
    const newDataToConfirmProject = {
      coStage: coStage ? coStage : 'EM',
      moments: dates?.map((item) => ({
        idTrackGroup: item.idProjectTrackStage,
        idMoment: item.idMoment,
        nuOrder: item.nuOrder,
        dtSchedule: item.dtSchedule,
        idUserProfessor: item.idProfessor,
      })),
    }

    const newDataToConfirmLesson = {
      moments: dates?.map((item) => ({
        idTrackStage: item.idLessonTrackGroup,
        idTrackGroup: item.idLessonTrackGroup,
        idMoment: item.idMoment,
        nuOrder: item.nuOrder,
        dtSchedule: item.dtSchedule,
      })),
    }

    handleConfirmTrail(isProject ? newDataToConfirmProject : newDataToConfirmLesson, type)
    // eslint-disable-next-line
  }, [coStage, dates, isProject, type])

  const handleConfirmTrail = (data: any, type: string) => {
    if (data && data.moments) {
      data.moments.sort((a, b) => a.nuOrder - b.nuOrder)
    }
    const rightData = data?.moments?.find((data) => data.idMoment === Number(idMoment))
    const rightItem = moments?.find((moment) => moment.idMoment === Number(idMoment))
    const dtScheduleRightData = dayjs(rightData?.dtSchedule)
    const dtScheduleRightItem = dayjs(rightItem?.dtSchedule)
    const foundItem = !dtScheduleRightData.isSame(dtScheduleRightItem)
    setIsSame(foundItem ? true : false)
    gaEvents.eventSaveNewTrailButton()

    switch (type) {
      case 'EM-schedule':
        handleOccupyTrail({
          body: data,
          classId: Number(classId),
          projectId: Number(idTrack),
        })
        break
      case 'EM-edit':
        updateTrailProject({
          body: data,
          classId: Number(classId),
          lessonId: Number(idTrack),
        })
        break
      case 'edit':
        if (isProject) {
          updateTrailProject({
            body: data,
            classId: Number(classId),
            lessonId: Number(idTrack),
          })
        } else {
          UpdateTrail({ body: data, classId: Number(classId), lessonId: Number(idTrack) })
        }
        break
      case 'schedule':
        if (isProject) {
          requestScheduleProject({
            body: data,
            classId: Number(classId),
            projectId: Number(idTrack),
          })
        } else {
          requestSchedule({ body: data, classId: Number(classId), lessonId: Number(idTrack) })
        }
        break
      default:
        type
    }
  }

  const handleDesengageTrail = useCallback(() => {
    handleLeaveTrailProject({ classId: Number(classId), lessonId: Number(idTrack) })
  }, [classId, idTrack, handleLeaveTrailProject])

  const handleCloseModal = useCallback(() => {
    if (['EM-edit'].includes(type)) {
      const initialDates = moments?.map((moment) => ({
        dtSchedule: moment.dtSchedule,
        nuOrder: moment.projectTrackStage?.nuOrder,
        idMoment: moment.idMoment,
        coMomentStatus: moment.coMomentStatus,
        title: moment.projectTrackStage?.txTitle,
        idProjectTrackStage: moment.projectTrackStage?.idProjectTrackStage,
        idProfessor: moment?.professor?.idUser || moment?.professor?.idUserProfessor,
        professor: moment?.professor,
      }))
      setDates(initialDates as [])
    }

    if (['EM-schedule'].includes(type)) {
      const initialDates = stage?.map((stage) => ({
        dtSchedule: '',
        nuOrder: stage.nuOrder,
        title: stage.txTitle,
        idProjectTrackStage: stage.idProjectTrackStage,
        idProfessor: undefined,
      }))
      setDates(initialDates as [])
    }

    actionCloseModal()
  }, [type, moments, stage, setDates, actionCloseModal])

  const handleSelectFormChange = useCallback(
    (e: number, momentID: number, index: number) => {
      const updatedDates = [...dates]
      const targetIndexEdit = updatedDates.findIndex((date) => date.idMoment === momentID)
      const targetIndexSchedule = updatedDates.findIndex((date) => date.nuOrder === index + 1)
      const targetIndex = ['EM-edit'].includes(type) ? targetIndexEdit : targetIndexSchedule

      if (targetIndex !== -1) {
        if (updatedDates[targetIndex].idProfessor !== undefined) {
          updatedDates[targetIndex].idProfessor = undefined
        } else {
          updatedDates[targetIndex].idProfessor = e
        }

        setDates(updatedDates as [])
      }
    },
    [dates, type, setDates],
  )

  useEffect(() => {
    const allDatesFilled = dates?.every((date: Moment) => date.dtSchedule !== '')
    const atLeastOneProfessorAllocated = dates?.some((date) => {
      if (['EM-edit', 'EM-schedule'].includes(type)) {
        if (typeof date.idProfessor === 'number') {
          return !isNaN(date.idProfessor)
        }
        return false
      } else {
        return true
      }
    })

    const equalArrays = (arr1: any[], arr2: any[]): boolean => {
      if (arr1?.length !== arr2?.length) {
        return false
      }

      for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i]
        const obj2 = arr2[i]

        if (!equalObj(obj1, obj2)) {
          return false
        }
      }

      return true
    }

    const equalObj = (obj1: any, obj2: any): boolean => {
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false
        }
      }

      return true
    }

    let initialDates: any[] = []

    if (moments && type === 'EM-edit') {
      initialDates = moments?.map((moment: Moment) => ({
        dtSchedule: moment?.dtSchedule,
        nuOrder: moment?.projectTrackStage?.nuOrder,
        idMoment: moment?.idMoment,
        title: moment?.projectTrackStage?.txTitle || '',
        idProjectTrackStage: moment?.projectTrackStage?.idProjectTrackStage,
        idProfessor: moment?.professor?.idUser || moment?.professor?.idUserProfessor,
        professor: moment?.professor,
        coMomentStatus: moment?.coMomentStatus,
      }))
    }

    if (stage && type === 'EM-schedule') {
      initialDates = stage?.map((stage) => ({
        dtSchedule: '',
        nuOrder: stage.nuOrder,
        title: stage.txTitle,
        idProjectTrackStage: stage.idProjectTrackStage,
        idProfessor: undefined,
      }))
    }

    if (moments && type === 'edit') {
      initialDates = moments
        ?.map((moment: Moment) => {
          const commonFields = {
            dtSchedule: moment?.dtSchedule,
            idMoment: moment?.idMoment,
            coMomentStatus: moment?.coMomentStatus,
          }

          if ('lessonTrackGroup' in moment) {
            return {
              ...commonFields,
              nuOrder: moment.lessonTrackGroup.nuOrder,
              title: moment.txMomentTitle || '',
              idLessonTrackGroup: moment.lessonTrackGroup.idLessonTrackGroup,
            }
          } else if ('projectTrackStage' in moment) {
            return {
              ...commonFields,
              nuOrder: moment.projectTrackStage.nuOrder,
              title: moment.txMomentTitle || '',
              idProjectTrackStage: moment.projectTrackStage.idProjectTrackStage,
            }
          }

          return null
        })
        .filter(Boolean)
    }

    const hasEdit = equalArrays(initialDates, dates)

    let message = ''

    if (message === '' && !allDatesFilled && !atLeastOneProfessorAllocated) {
      message = 'Preencha os Horários e Escolha um Professor'
    } else if (message === '' && !allDatesFilled) {
      message = 'Preencha todas as datas'
    } else if (message === '' && !atLeastOneProfessorAllocated) {
      message = 'Pelo menos um Professor deve ser selecionado'
    } else if (message === '' && hasEdit) {
      message = 'Não houveram alterações'
    }

    setButtonEnabled(allDatesFilled && atLeastOneProfessorAllocated && message === '')
    setMessage(message)
    // eslint-disable-next-line
  }, [dates])

  useEffect(() => {
    if (!dates) {
      return
    }

    const professorIds = dates?.map((date) => date.idProfessor).filter((id) => id !== undefined && !isNaN(id))

    const status = dates
      .map((status) => status.coMomentStatus)
      .filter((status) => status === 'AGEN' || status === 'PEND' || status === 'AGUA' || status === 'AUPE')

    const uniqueProfessorIds = [...new Set(professorIds)]

    const isSingleProfessor = uniqueProfessorIds.length === 1

    const isValidStatus = status.length > 0

    setButtonEnabledDesengage(isSingleProfessor && uniqueProfessorIds[0] === idUser && isValidStatus)

    if (!isValidStatus) {
      setMessageDesengage('Não é possível desocupar aula já iniciada')
    } else if (!isSingleProfessor) {
      setMessageDesengage('A aula só pode ter um profesor')
    }
  }, [dates, idUser])

  const NotFoundPageProviderValues = useMemo(() => {
    return {
      dates,
      handleDateChange,
      disabledDate,
      handleSelectFormChange,
      datesFormatted,
      validateMoment,
      isEM,
      setDates,
      handleCloseModal,
      buttonEnabled,
      message,
      handleConfirmDesengage,
      handleConfirm,
      buttonEnabledDesengage,
      handleEngageTrail,
      confirmModal,
      loadingUpdateTrailProject,
      loadingOccupyTrail,
      loadingData,
      loadingRequestSchedule,
      loadingRequestScheduleProject,
      handleCloseConfirm,
      handleCloseSuccess,
      handleDesengageTrail,
      openModalSucess,
      confirmModalDesengage,
      handleCloseConfirmDesengage,
      loadingDataLeaveProject,
      openModalSucessDesengage,
      handleCloseSuccessDesengage,
      messageDesengage,
      idTrack,
      open,
    }
  }, [
    dates,
    open,
    handleDateChange,
    disabledDate,
    handleSelectFormChange,
    datesFormatted,
    validateMoment,
    isEM,
    setDates,
    handleCloseModal,
    buttonEnabled,
    message,
    handleConfirmDesengage,
    handleConfirm,
    buttonEnabledDesengage,
    handleEngageTrail,
    confirmModal,
    loadingUpdateTrailProject,
    loadingOccupyTrail,
    loadingData,
    loadingRequestSchedule,
    loadingRequestScheduleProject,
    handleCloseConfirm,
    handleCloseSuccess,
    handleDesengageTrail,
    openModalSucess,
    confirmModalDesengage,
    handleCloseConfirmDesengage,
    loadingDataLeaveProject,
    openModalSucessDesengage,
    handleCloseSuccessDesengage,
    messageDesengage,
    idTrack,
  ])

  return (
    <ScheduleModalPageContext.Provider value={NotFoundPageProviderValues}>{children}</ScheduleModalPageContext.Provider>
  )
}
const useScheduModalContext = () => useContext(ScheduleModalPageContext)

export { ScheduleModalPageProvider, useScheduModalContext }
