import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-x: none;
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.span`
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #0095ff;
  margin-bottom: 0.5rem;
`

export const ButtonCloseModal = styled(IconButton)`
  padding: 0;
  width: 40px;
  height: 40px;
  position: relative;
  top: -2px;
  cursor: pointer;
`

export const Input = styled.input`
  background-color: #fff;
  text-decoration: none;
  border: 19px;
  border-radius: 15px;
  height: 58px;
  padding-left: 12px;
  width: 100%;
`

export const PasswordRequirementsLabel = styled.span`
  font-size: 16px;
  color: #000;
  font-weight: 700;
`

export const ListRulesContainer = styled.ul`
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const Password = styled.div`
  border: 1px solid #b6b6b6;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding-right: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  *:focus {
    outline: none;
  }
`

export const ListRules = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  margin-top: 15px;
`
export const ListItemRules = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  list-style: none;
`
