import { Alert, Box, Menu, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'
export const MenuContainer = styledMUI(Menu)(() => ({
  margin: '14px 0 0 0',

  '.MuiPaper-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
  '.styled-scroll-s': {
    '::-webkit-scrollbar': {
      width: '5px',
    },

    '::-webkit-scrollbar-track': {
      borderRadius: '8px',
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: `${theme.colors.highDark}`,
      border: `1px solid ${theme.colors.highDark}`,
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
      width: '4px',
    },
  },
}))

export const ContentNotifications = styledMUI(Box)(() => ({
  width: '417px',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
  gap: '8px',
}))

export const ContainerCardNotifications = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '450px',
  overflowY: 'auto',
  gap: '8px',
}))

export const WrapperDivider = styledMUI(Box)(() => ({
  padding: '8px 0',
}))

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'baseline',
  justifyContent: 'space-between',
}))

export const UserFullName = styledMUI(Typography)(() => ({
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
}))

export const ActionLidas = styledMUI(Typography)<{ active: boolean }>(({ active }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%',
  color: active ? `${theme.colors.actionPure}` : `${theme.colors.midDark}`,
  cursor: active ? 'pointer' : `block`,
  '&:hover': {
    textDecoration: active ? 'underline' : `none`,
  },
}))

export const CardNotification = styledMUI(Box)<{ active: boolean }>(({ active }) => ({
  width: '100%',
  display: 'flex',
  gap: '8px',
  height: !active ? '64px' : '40px',
  backgroundColor: `${theme.colors.midLight}`,
  padding: '8px',
  alignItems: 'center',
  borderRadius: '8px',
}))

export const BarNotification = styledMUI(Box)<{ active: boolean }>(({ active }) => ({
  display: 'flex',
  width: '4px',
  height: !active ? '48px' : '24px',
  backgroundColor: !active ? `${theme.colors.midDark}` : `${theme.colors.midMedium}`,
  alignSelf: 'streatch',
  borderRadius: '8px',
}))

export const BtnAction = styledMUI(Box)(() => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  backgroundColor: `${theme.colors.midMedium}`,
  borderRadius: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}))

export const ContainerTextCard = styledMUI(Box)(() => ({
  display: 'flex',
  width: '325px',
}))
export const TextCard = styledMUI(Typography)<{ active?: boolean }>(({ active }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '150%',
  color: active ? `${theme.colors.lowLight}` : `${theme.colors.lowPure}`,
}))

export const InfoAlert = styledMUI(Alert)(() => ({
  backgroundColor: `${theme.colors.feedback.error.light}`,
  color: `${theme.colors.feedback.error.main}`,
  '& .MuiAlert-icon': {
    color: `${theme.colors.feedback.error.main}`,
  },
}))
