import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const CardAlert = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'space-between',
  width: '100%',
}))

export const ContentTrail = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginRight: '20px',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#000',
}))

export const TextAlert = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  textAlign: 'center',
  color: '#7C8189',
}))

export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
}))
