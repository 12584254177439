import React from 'react'

import { UilCheck } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent } from '~/components/Forms/Button/Button'

import * as S from '../ScheduleModal.styles'

interface FormEMProps {
  buttonEnabled: boolean
  message: string
  handleCloseModal: () => void
  handleConfirm: () => void
  handleDesengageTrail: () => void
  disabled: boolean
  type: string
  messageDesengage: string
}

const FooterModal: React.FC<FormEMProps> = ({
  buttonEnabled,
  message,
  handleCloseModal,
  handleConfirm,
  handleDesengageTrail,
  disabled,
  type,
  messageDesengage,
}) => {
  const { width } = useWindowDimensions()
  const isMobile = Number(width) < 600

  return (
    <>
      {!isMobile && (
        <S.ContentButtons>
          {['EM-edit', 'edit'].includes(type) && (
            <S.StartButton>
              <>
                <S.TooltipLabel
                  title={messageDesengage}
                  hidden={disabled}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#000',
                        '& .MuiTooltip-arrow': {
                          color: 'common.black',
                        },
                      },
                    },
                  }}
                >
                  <ButtonComponent
                    variant="solid"
                    color={disabled ? 'disabled' : 'error'}
                    size="large"
                    text="Desocupar trilha"
                    fontWeight="normal"
                    fontSize="large"
                    onClick={disabled ? null : () => handleDesengageTrail()}
                  />
                </S.TooltipLabel>
              </>
            </S.StartButton>
          )}
          {/* nao remover vai entrar na proxima sprint, falta só puxar o text*/}
          {/* {!['EM-edit', 'edit'].includes(type) && (
            <S.StartInfoMoments>
              <S.InfoMoments status={buttonEnabled}>
                {buttonEnabled ? <UilCheckCircle /> : <UilExclamationCircle />}
                <span>teste</span>
              </S.InfoMoments>
            </S.StartInfoMoments>
          )} */}
          <S.FinalButton>
            <ButtonComponent
              text={'Cancelar'}
              fontColor={'black'}
              fontWeight={'normal'}
              variant="text"
              size="large"
              onClick={handleCloseModal}
            />
            {!buttonEnabled && (
              <>
                <S.TooltipLabel
                  title={message}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#000',
                        '& .MuiTooltip-arrow': {
                          color: 'common.black',
                        },
                      },
                    },
                  }}
                >
                  <ButtonComponent
                    text={'Salvar datas da Trilha'}
                    variant="disabled"
                    fullWidth={true}
                    size={isMobile ? 'extra-large' : 'large'}
                    iconStart={<UilCheck />}
                  />
                </S.TooltipLabel>
              </>
            )}
            {buttonEnabled && (
              <ButtonComponent
                text={'Salvar datas da Trilha'}
                variant="solid"
                fullWidth={true}
                size={isMobile ? 'extra-large' : 'large'}
                iconStart={<UilCheck />}
                onClick={() => handleConfirm()}
              />
            )}
          </S.FinalButton>
        </S.ContentButtons>
      )}

      {isMobile && (
        <S.ContentButtons>
          <S.StartButton>
            {!buttonEnabled && (
              <>
                <S.TooltipLabel
                  title={message}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#000',
                        '& .MuiTooltip-arrow': {
                          color: 'common.black',
                        },
                      },
                    },
                  }}
                >
                  <ButtonComponent
                    text={'Salvar datas da Trilha'}
                    variant="disabled"
                    fullWidth={true}
                    size="large-plus"
                    iconStart={<UilCheck />}
                  />
                </S.TooltipLabel>
              </>
            )}
            {buttonEnabled && (
              <ButtonComponent
                text={'Salvar datas da Trilha'}
                variant="solid"
                fullWidth={true}
                size="large-plus"
                iconStart={<UilCheck />}
                onClick={() => handleConfirm()}
              />
            )}
          </S.StartButton>
          <ButtonComponent text={'Cancelar'} variant="outline" size="large" onClick={handleCloseModal} />
        </S.ContentButtons>
      )}
    </>
  )
}

export default FooterModal
