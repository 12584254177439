import { ProjectPlanningPageProvider } from './context'
import { ProjectPlanningView } from './view'

export const ProjectPlanningPage = () => {
  return (
    <ProjectPlanningPageProvider>
      <ProjectPlanningView />
    </ProjectPlanningPageProvider>
  )
}
