import { UilMultiply, UilCheck, UilFileDownload } from '@iconscout/react-unicons'
import { LoadingButton } from '@mui/lab'
import { CircularProgress, IconButton } from '@mui/material'

import { ButtonComponent } from '~/components/Forms/Button/Button'

import { mapInfoIcons } from '~/validations/mapInfoIcons'

import { AlertAssessment } from '../../Forms/Assessment/Assessment.styles'
import { IModalComponentProps } from './Modal.interfaces'
import * as S from './Modal.styles'

export const ModalComponent = ({
  open,
  header,
  footer,
  title,
  buttonActionText,
  buttonActionIcon,
  actionCloseModal,
  actionCancelModal,
  checkGroupSizes,
  children,
  loading,
  maxWidth,
  maxHeight,
  height,
  width,
  onSubmit,
  padding,
  countStudents,
  isManageStudentsModal = false,
  isStudentsGroupsPdfRequestLoading,
  handleDownloadStudentsGroupsPdf,
  isClassInStatus = true,
}: IModalComponentProps) => {
  return (
    <S.ContainerModal open={open} onClose={actionCloseModal}>
      {header || footer ? (
        <>
          <S.ContentModalHeader>
            {header && (
              <S.ModalHeader>
                <S.TextTitle>{title}</S.TextTitle>

                {isManageStudentsModal ? (
                  <S.WrapperHeaderButton>
                    <ButtonComponent
                      variant={'outline'}
                      size="small"
                      fontWeight="normal"
                      fontSize="medium"
                      sizeLoading={16}
                      text="Baixar Listagem dos Grupos"
                      iconEnd={<UilFileDownload size={20} />}
                      loading={isStudentsGroupsPdfRequestLoading}
                      onClick={() => handleDownloadStudentsGroupsPdf()}
                    />
                    <IconButton onClick={actionCloseModal}>
                      <UilMultiply size={20} color="#000" />
                    </IconButton>
                  </S.WrapperHeaderButton>
                ) : (
                  <IconButton onClick={actionCloseModal}>
                    <UilMultiply size={16} color="#000" />
                  </IconButton>
                )}
              </S.ModalHeader>
            )}
            <S.ModalBody className="styled-scroll-s">{children}</S.ModalBody>

            <S.ModalFooter>
              {isManageStudentsModal && (
                <>
                  {countStudents ? (
                    <S.InfoAlert severity="info" iconMapping={mapInfoIcons}>
                      {countStudents} alunos selecionados.
                    </S.InfoAlert>
                  ) : (
                    <>
                      {checkGroupSizes !== undefined && checkGroupSizes === 'warning' ? (
                        <S.WarnningAlert severity="warning" iconMapping={mapInfoIcons}>
                          Existem grupos com mais de 7 ou menos de 3 alunos.
                        </S.WarnningAlert>
                      ) : (
                        <S.SuccessAlert severity="success" iconMapping={mapInfoIcons}>
                          Tudo certo por aqui!
                        </S.SuccessAlert>
                      )}
                    </>
                  )}
                </>
              )}

              <S.WrapperFooterButtons>
                <ButtonComponent
                  type="button"
                  variant="outline"
                  onClick={actionCancelModal || actionCloseModal}
                  size="large"
                  text="Cancelar"
                />
                {!loading ? (
                  <ButtonComponent
                    variant="solid"
                    type="submit"
                    size="large"
                    disabled={!isClassInStatus}
                    color={!isClassInStatus ? 'unavaible' : undefined}
                    onClick={onSubmit}
                    loading={loading}
                    iconEnd={buttonActionIcon || <UilCheck size="20" color="#fff" />}
                    text={buttonActionText || 'Salvar'}
                  />
                ) : (
                  <LoadingButton
                    loading
                    size="large"
                    variant="contained"
                    sx={{
                      height: '48px',
                      borderRadius: '25px',
                    }}
                  />
                )}
              </S.WrapperFooterButtons>
            </S.ModalFooter>
          </S.ContentModalHeader>
        </>
      ) : (
        <>
          <S.ContentModal maxWidth={maxWidth} maxHeight={maxHeight} height={height} width={width} padding={padding}>
            {children}
          </S.ContentModal>

          {loading && (
            <AlertAssessment
              iconMapping={{
                info: <CircularProgress color="inherit" size={16} />,
              }}
              severity={'info'}
            >
              Estamos processando o seu gerenciamento.
            </AlertAssessment>
          )}
        </>
      )}
    </S.ContainerModal>
  )
}
