import { useDrag, DragPreviewImage } from 'react-dnd'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent } from '~/components'

import { ICardStudentComponentProps } from './CardStudent.interfaces'
import * as S from './CardStudent.styles'

export const CardStudentComponent = ({
  id,
  photoUrl,
  txName,
  object,
  students,
  length,
}: ICardStudentComponentProps) => {
  const [isDragging, drag, preview] = useDrag(() => {
    return {
      type: 'CARD',
      item: { id, photoUrl, txName, object: object, students: students },
      canDrag: length !== 1,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }
  })

  const { width } = useWindowDimensions()
  const shouldChangeAppearance = isDragging && students.length > 1
  const isMobile = width && width <= 600

  const transformFullName = (fullName: string): string => {
    const firstName = fullName?.split(' ')[0]
    const capitalizedFirstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1).toLowerCase()
    return capitalizedFirstName
  }
  return (
    <>
      {shouldChangeAppearance && (
        <DragPreviewImage
          connect={preview}
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='red'/%3E%3C/svg%3E"
        />
      )}

      <S.CardStudent selected={object.isSelected} ref={drag}>
        <AvatarComponent photoUrl={photoUrl} label={txName} size={isMobile ? 'medium' : 'mediumPlus'} />
        <S.TextStudent>{transformFullName(txName?.split(/(\s).+\s/).join(''))}</S.TextStudent>
      </S.CardStudent>
    </>
  )
}
