import { IUserProps } from './Auth.interfaces'

export const defaultUserState: IUserProps = {
  aud: '',
  azp: '',
  email: '',
  email_verified: true,
  exp: 0,
  family_name: '',
  given_name: '',
  iat: 0,
  id_network: 0,
  id_user: 0,
  iss: '',
  jti: '',
  name: '',
  preferred_username: '',
  realm_access: { roles: [''] },
  resource_access: { account: { roles: [''] }, lekto: { roles: [''] } },
  role: [''],
  scope: '',
  session_state: '',
  sid: '',
  sub: '',
  picture: '',
  typ: '',
}
