import { TextField } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const InputTextDate = styledMUI(TextField)<{ format: string }>(({ format }) => ({
  '& .MuiDayCalendar-weekContainer': {
    background: 'red !important',
  },

  '.MuiOutlinedInput-root': {
    height: '32px',
    width: format === 'week' ? '250px' : format === 'month' ? '180px' : '160px',
    borderColor: '#0095FF',
    textTransform: 'capitalize',

    '& fieldset': {
      borderColor: '#0095FF',
      borderRadius: '5px',
    },
    '&:hover fieldset': {
      borderColor: '#0095FF',
      borderRadius: '5px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0095FF',
      borderRadius: '5px',
    },
  },

  '.MuiFormLabel-root': {
    color: '#0095FF',
  },
  'select-week': {
    background: '#000',
  },

  '.MuiInputBase-input': {
    height: 'inherit',
    paddingTop: '0px',
    paddingBottom: '0px',
    borderRight: '1px solid #0095FF',
    color: '#0095FF',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#0095FF !important',
  },

  '.MuiIconButton-root': {
    color: '#0095FF',
    padding: '3px',

    marginRight: '-7px',
  },
}))
