import { AvatarGroup } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { sizesAvatars } from '~/validations'

export const AvatarGroupContainer = styledMUI(AvatarGroup)<{ size: 'small' | 'medium' | 'large' }>(({ size }) => ({
  '.MuiAvatarGroup-avatar': {
    height: sizesAvatars(size),
    width: sizesAvatars(size),
    backgroundColor: '#ccc',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: size === 'small' ? '10px' : '14px',
    lineHeight: '14px',
    color: '#fff',
    padding: 0,
  },
}))
