import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLessonActivityEvidencesByIdQuery } from '~/queries/autogenerate/hooks'

import { useAuth } from '~/_context/Auth'

import { graphqlRequestClient } from '~/server/GraphQLClient'

import { useServiceAssessment, useServiceAssessmentBatch } from '~/services/Assessment'
import { useGetDocumentation } from '~/services/Documentation'
import { useServiceGetLesson } from '~/services/Lesson'
import { useLessonStepActivityInformation, useLessonStepInformationGroup } from '~/services/LessonSteps'
import { useGetStudentsGroupLesson } from '~/services/Students'

import { dataLessonDocumentationActivity } from '~/pages/private/Fund1/default.state'

import { defaultDataState } from './default.states'
import { ISecondStepPageContextProps, ISecondStepPageProviderProps } from './interfaces'

const SecondStepPageContext = createContext<ISecondStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  isLoading: false,
  data: defaultDataState,
  mutateAssessment: () => Object,
  isLoadingAssessment: false,
  dataDocumentation: dataLessonDocumentationActivity,
  dataDocumentationFiltered: [],
  isLoadingDocumentation: false,
  filteredActivityGroup: undefined,
  isSameUser: false,
  dataEvidence: {},
  dataStudentsGroup: {},
  dataModal: defaultDataState,
  isClassInStatus: true,
})

const SecondStepPageProvider = ({ children }: ISecondStepPageProviderProps) => {
  const { idLessonMoment, idLesson, idLessonActivity, idClass } = useParams()
  const { user } = useAuth()

  const [activedTab, setActivedTab] = useState<number>(0)

  const {
    isLoading,
    data,
    refetch: refetchLessonActivity,
    remove,
  } = useLessonStepActivityInformation(Number(idLessonMoment), Number(idLessonActivity))

  const { data: dataEvidence } = useLessonActivityEvidencesByIdQuery(graphqlRequestClient('track'), {
    idLessonActivity: Number(idLessonActivity),
  })

  const { mutate: mutateAssessment, isLoading: isLoadingAssessment } = useServiceAssessment()
  const { mutate: mutateAssessmentBatch, isLoading: isLoadingAssessmentBatch } = useServiceAssessmentBatch()
  const { data: dataModal } = useServiceGetLesson(Number(idLessonMoment))

  const {
    data: dataDocumentation,
    isLoading: isLoadingDocumentation,
    remove: removeDocumentation,
  } = useGetDocumentation(Number(idClass), Number(idLessonMoment))

  useEffect(() => {
    remove()
    refetchLessonActivity()
    removeDocumentation()
  }, [remove, refetchLessonActivity, removeDocumentation, idLessonActivity])

  const { data: dataActivityGroup } = useLessonStepInformationGroup(Number(idLessonMoment), Number(idLesson))

  const { data: dataStudentsGroup, refetch } = useGetStudentsGroupLesson(Number(idLessonMoment), 2)

  const idUserProfessor = dataModal?.idUserProfessor

  const isClassInStatus = dataModal?.class?.inStatus

  const isSameUser = user?.id_user === idUserProfessor

  const filteredActivityGroup = dataActivityGroup?.lessonActivity?.find(
    (item) => item.idLessonActivity === Number(idLessonActivity),
  )?.lessonMomentActivity[0]

  const dataDocumentationFiltered = dataDocumentation?.data
    ?.filter((item) => item?.lessonActivity)
    .sort(function (a, b) {
      return new Date(b.dtInserted).getDate() - new Date(a.dtInserted).getDate()
    })

  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const secondStepPageProviderValues = useMemo(() => {
    return {
      activedTab,
      filteredActivityGroup,
      handleActiveNewTab,
      isLoading,
      isSameUser,
      data: data || defaultDataState,
      dataDocumentation,
      isLoadingDocumentation,
      dataDocumentationFiltered,
      mutateAssessment,
      mutateAssessmentBatch,
      isLoadingAssessment,
      isLoadingAssessmentBatch,
      dataEvidence,
      dataStudentsGroup,
      dataModal,
      refetch,
      idClass,
      isClassInStatus,
    }
  }, [
    dataStudentsGroup,
    dataEvidence,
    isSameUser,
    filteredActivityGroup,
    activedTab,
    handleActiveNewTab,
    isLoading,
    data,
    dataDocumentation,
    isLoadingDocumentation,
    dataDocumentationFiltered,
    mutateAssessment,
    mutateAssessmentBatch,
    isLoadingAssessment,
    isLoadingAssessmentBatch,
    dataModal,
    refetch,
    idClass,
    isClassInStatus,
  ])

  return (
    <SecondStepPageContext.Provider value={secondStepPageProviderValues}>{children}</SecondStepPageContext.Provider>
  )
}

const useSecondStepPageContext = () => useContext(SecondStepPageContext)

export { SecondStepPageProvider, useSecondStepPageContext }
