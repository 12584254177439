import { useEffect, useState } from 'react'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { SkeletonComponent } from '~/components/Basic/Skeleton/Skeleton'

import { Lesson } from '~/pages/private/Fund1/Lesson/interfaces'

import { ILessonStepsProps } from './LessonSteps.interfaces'
import * as S from './LessonSteps.styles'

interface IData {
  lesson?: Lesson[]
}

export default function LessonSteps({
  data,
  onChangeTrailIndex,
  handleAllLessons,
  handleActiveCard,
}: ILessonStepsProps) {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (data) {
      setIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    setActiveIndex(onChangeTrailIndex)
  }, [onChangeTrailIndex])

  return (
    <S.Container>
      {isLoading && (
        <>
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
        </>
      )}

      {!isLoading && data && (
        <S.StepsContainer>
          <S.BtnAction onClick={() => handleAllLessons()} active={activeIndex === null}>
            {isMobile ? 'Todas' : 'Todas as Aulas'}
          </S.BtnAction>
          {(data as IData).lesson?.map((item: any, index: number) => (
            <S.StepsContent
              key={index}
              isNull={activeIndex !== null}
              active={index === activeIndex}
              onClick={() => {
                handleActiveCard(index)
              }}
            >
              {`${index + 1}`}
            </S.StepsContent>
          ))}
        </S.StepsContainer>
      )}
    </S.Container>
  )
}
