import { useEffect, useState } from 'react'

import { UilEye, UilEyeSlash, UilKeySkeleton, UilMultiply } from '@iconscout/react-unicons'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box } from '@mui/material'
import backgroundLogin1 from '~/assets/images/login_1.png'
import backgroundLogin2 from '~/assets/images/login_2.png'
import backgroundLogin3 from '~/assets/images/login_3.png'
import logo from '~/assets/images/logo_white.png'
import { Field, Form, Formik } from 'formik'
import { object, string } from 'yup'

import { ModalActionComponent, ModalComponent } from '~/components'

import { ModalUpdatePasswordComponent } from '~/containers/private/Settings/ModalUpdatePassword/ModalUpdatePassword'

import { useLoginPageContext } from './context'
import { IInitialValuesFormForgotPasswordProps, IInitialValuesFormLoginProps } from './interfaces'
import * as S from './styles'

const backgroundImages = [backgroundLogin1, backgroundLogin2, backgroundLogin3]

export const LoginView = () => {
  const {
    currentStateLogin,
    handleCloseModalUpdatePassword,
    isLoadingChangePassword,
    showUpdatePasswordModal,
    handleChangePassword,
    showPassword,
    showModalForgotPassword,
    showModalUpdatePasswordConfirm,
    isLoadingLogin,
    handleLogin,
    isLoadingForgotPassword,
    handleForgotPassword,
    handleValidInput,
    handleShowPassword,
    handleHidePassword,
    handleOpenModalForgotPassword,
    handleCloseModalForgotPassword,
    handleCloseModalUpdatePasswordConfirm,
  } = useLoginPageContext()

  const [indexImage, setIndexImage] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndexImage((prevIndex) => (prevIndex + 1) % backgroundImages.length)
    }, 4000)

    return () => clearInterval(intervalId)
  }, [])

  const initialValuesFormLogin: IInitialValuesFormLoginProps = {
    username: '',
    password: '',
  }

  const initialValuesFormForgotPassword: IInitialValuesFormForgotPasswordProps = {
    email: '',
  }

  return (
    <S.Container>
      <S.ContainerLogin>
        <S.ContentLogin>
          <S.ContainerImage>
            <S.Image
              src={backgroundImages[indexImage]}
              key={backgroundImages[indexImage]}
              className="image-fade"
              alt="imagem de login"
            />
          </S.ContainerImage>
          <S.Credentials>
            <S.Logo src={logo} height={51} width={103} alt="Logo" />

            <Formik
              validationSchema={object().shape({
                username: string().email('Endereço de email inválido.').required('Email obrigatório.'),
                password: string().min(8, 'Deve ter no mínimo 8 caracteres.').required('Senha obrigatória.'),
              })}
              initialValues={initialValuesFormLogin}
              onSubmit={(values, { resetForm }) => {
                handleLogin(values)
                resetForm()
              }}
            >
              {({ errors, touched, handleSubmit }) => (
                <Form>
                  <S.Gap>
                    <S.ColumnFlex>
                      <S.InputLabel>Seu email</S.InputLabel>
                      <Field
                        data-testid="input-email"
                        name="username"
                        type="text"
                        placeholder="Seu e-mail"
                        disabled={isLoadingLogin}
                        as={S.Input}
                      />
                      {errors.username && touched.username && (
                        <S.GreyErrorFeedback>{errors.username}</S.GreyErrorFeedback>
                      )}
                    </S.ColumnFlex>

                    <S.ColumnFlex>
                      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <S.InputLabel>Senha</S.InputLabel>

                        <S.ForgotPassword
                          data-testid="forgot-password"
                          type={'button'}
                          onClick={handleOpenModalForgotPassword}
                        >
                          Esqueceu sua senha?
                        </S.ForgotPassword>
                      </Box>
                      <S.Password>
                        <Field
                          name="password"
                          data-testid="input-password"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Sua senha"
                          disabled={isLoadingLogin}
                          as={S.Input}
                        />

                        {showPassword ? (
                          <UilEye onClick={handleHidePassword} size="25" color="#9b9b9b" />
                        ) : (
                          <UilEyeSlash onClick={handleShowPassword} size="25" color="#9b9b9b" />
                        )}
                      </S.Password>
                      {errors.password && touched.password && (
                        <S.GreyErrorFeedback>{errors.password}</S.GreyErrorFeedback>
                      )}
                    </S.ColumnFlex>

                    {isLoadingLogin ? (
                      <LoadingButton
                        loading
                        variant="contained"
                        sx={{
                          width: '100%',
                          height: '48px',
                          borderRadius: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        Entrar
                      </LoadingButton>
                    ) : (
                      <S.SignButton data-testid="submit-login" type="submit">
                        Entrar
                      </S.SignButton>
                    )}
                  </S.Gap>
                </Form>
              )}
            </Formik>

            {showModalForgotPassword && (
              <ModalComponent
                open={showModalForgotPassword}
                actionCloseModal={handleCloseModalForgotPassword}
                maxWidth="456px"
              >
                <S.ForgotModal>
                  <S.ModalHeader>
                    <S.ForgotTitle>Recuperação de senha</S.ForgotTitle>
                    <S.ButtonCloseModal data-testid="close-modal-password" onClick={handleCloseModalForgotPassword}>
                      <UilMultiply size={20} />
                    </S.ButtonCloseModal>
                  </S.ModalHeader>

                  <S.ForgotDescription>
                    Insira seu endereço de e-mail e lhe enviaremos instruções para redefinir sua senha.
                  </S.ForgotDescription>

                  <Formik
                    validationSchema={object().shape({
                      email: string().email('Endereço de email inválido.').required('Email obrigatório.'),
                    })}
                    initialValues={initialValuesFormForgotPassword}
                    onSubmit={(values) => {
                      handleValidInput()
                      handleForgotPassword(values)
                    }}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <S.ForgotFormBox>
                          <S.ForgotInputLabel>Seu email</S.ForgotInputLabel>
                          <Field
                            name="email"
                            type="email"
                            data-testid="email-modal-password"
                            placeholder="Insira seu e-mail"
                            label="Endereço de e-mail"
                            disabled={isLoadingForgotPassword}
                            as={S.ForgotInput}
                          />
                          {errors.email && touched.email && <S.ErrorFeedback>{errors.email}</S.ErrorFeedback>}

                          {!isSubmitting ? (
                            <S.ActionButton
                              data-testid="submit-modal-password"
                              type={'submit'}
                              disabled={isLoadingForgotPassword}
                            >
                              Confirmar
                            </S.ActionButton>
                          ) : (
                            <S.LoadingActionButton loading variant="contained" />
                          )}
                        </S.ForgotFormBox>
                      </Form>
                    )}
                  </Formik>
                </S.ForgotModal>
              </ModalComponent>
            )}
          </S.Credentials>

          <ModalUpdatePasswordComponent
            currentPassword={currentStateLogin?.password}
            loading={isLoadingChangePassword}
            open={showUpdatePasswordModal}
            actionCloseModal={handleCloseModalUpdatePassword}
            actionSendData={(data) =>
              handleChangePassword({
                username: currentStateLogin?.email,
                currentPassword: data.oldPassword,
                newPassword: data.newPassword,
              })
            }
          />

          <ModalActionComponent
            title="Se o seu e-mail estiver cadastrado na Lekto, você receberá as instruções de redefinição de senha em alguns minutos."
            open={showModalUpdatePasswordConfirm}
            confirmCancelText="Finalizar"
            actionCloseModal={handleCloseModalUpdatePasswordConfirm}
            icon={<UilKeySkeleton size={25} color="#0095FF" />}
            infoWidth={700}
            isFullModal={false}
          />
        </S.ContentLogin>
      </S.ContainerLogin>
    </S.Container>
  )
}
