import { UilCheck, UilCheckCircle, UilInfoCircle, UilPlay, UilStar, UilTimesCircle } from '@iconscout/react-unicons'

export const handleStatusIcon = (status: string, color?: string, size?: number) => {
  const sizeState = size || 20
  const colorState = color || 'white'
  switch (status) {
    case 'AGEN':
      return <UilInfoCircle size={sizeState} color={colorState} />
    case 'AGUA':
      return <UilPlay size={sizeState} color={colorState} />
    case 'AUPL':
      return <UilPlay size={sizeState} color={colorState} />
    case 'FINA':
      return <UilCheckCircle size={sizeState} color={colorState} />
    case 'AVPE':
      return <UilStar size={sizeState} color={colorState} />
    case 'INIC':
      return <UilCheck size={sizeState} color={colorState} />
    case 'PEND':
      return <UilCheck size={sizeState} color={colorState} />
    case 'AUPE':
      return <UilCheck size={sizeState} color={colorState} />
    case 'BLOCK':
      return <UilInfoCircle size={sizeState} color={colorState} />
    case 'CANC':
      return <UilTimesCircle size={sizeState} color={colorState} />
    default:
      return null
  }
}
