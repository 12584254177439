import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { dateOptions, defaultDataState } from './default.states'
import { IDataProps, IScheduleByWeekPageContextProps, IScheduleByWeekPageProviderProps, ItemsByDay } from './interfaces'
import { GET_CLASSES_WEEK } from './schemas.queries'

interface WeekDay {
  formattedDay: string
  className: string
}

const ScheduleByWeekPageContext = createContext<IScheduleByWeekPageContextProps>({
  currentDay: new Date(),
  changeCurrentDay: () => Object,
  selectedDay: new Date(),
  changeSelectedDay: () => Object,
  isLoading: false,
  data: defaultDataState,
  isRefetching: false,
  extensiveSelectedDay: '',
  weekArray: [{ formattedDay: '', className: '' }],
  itemsByDay: {
    Sunday: [],
  },
  daysOfWeekEN: [''],
  formattedMonthWithUppercase: '',
})

dayjs().locale('pt-br')

const ScheduleByWeekPageProvider = ({ children }: IScheduleByWeekPageProviderProps) => {
  const [currentDay, setCurrentDay] = useState<Date>(new Date())
  const [selectedDay, setSelectedDay] = useState<Date>(new Date())

  const today = dayjs(currentDay)

  const sunday = today.startOf('week').add(0, 'day')

  const saturday = today.startOf('week').add(6, 'day')

  const { isLoading, data, remove, isRefetching, refetch } = useQueryGraphQLClient<IDataProps>(
    'moment',
    'GET_CLASSES_WEEK',
    GET_CLASSES_WEEK,
    {
      dateStart: sunday.format('YYYY-MM-DDTHH:mm:ss'),
      dateEnd: saturday.format('YYYY-MM-DDTHH:mm:ss'),
      showScheduledClasses: true,
    },
  )

  const daysOfWeekPtBR = useMemo(() => ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'], [])

  const weekArray = useMemo<WeekDay[]>(() => {
    const array: WeekDay[] = []

    for (let i = 0; i < 7; i++) {
      const day = sunday.add(i, 'day')
      const dayOfWeek = daysOfWeekPtBR[day.day()]
      const NowDay = dayjs()

      const formattedDay = `${dayOfWeek}, ${day.format('D')}`
      const isActiveDay = day.isSame(NowDay, 'day')
      const className = isActiveDay ? 'active-day' : ''

      array.push({ formattedDay, className })
    }

    return array
  }, [daysOfWeekPtBR, sunday])

  const daysOfWeekEN = useMemo(() => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], [])

  const formattedMonth = dayjs(currentDay).locale('pt-br').format('MMMM') + ' de ' + dayjs(currentDay).format('YYYY')
  const formattedMonthWithUppercase = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)

  const changeCurrentDay = useCallback((date: Date) => setCurrentDay(date), [])

  const changeSelectedDay = useCallback((date: Date) => setSelectedDay(date), [])

  const upperCaseFirstLetter = useCallback((date: string) => date.charAt(0).toUpperCase() + date.slice(1), [])

  const extensiveSelectedDay = upperCaseFirstLetter(selectedDay.toLocaleDateString('pt-br', dateOptions))

  const itemsByDay = useMemo(() => {
    const itemsByDay: ItemsByDay = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    }

    if (data && data.scheduledMomentByProfessor && data.scheduledMomentByProfessor.items) {
      data.scheduledMomentByProfessor.items.forEach((item) => {
        const scheduleDate = dayjs(item.dtSchedule)
        const dayOfWeek = scheduleDate.locale('en').format('dddd')

        if (itemsByDay[dayOfWeek]) {
          itemsByDay[dayOfWeek].push(item)
        }
      })
    }

    return itemsByDay
    // eslint-disable-next-line
  }, [data, selectedDay])

  useEffect(() => {
    remove()
    refetch()
    // eslint-disable-next-line
  }, [selectedDay, refetch])

  const schedulePageProviderValues = useMemo(() => {
    return {
      currentDay,
      changeCurrentDay,
      selectedDay,
      changeSelectedDay,
      isLoading,
      itemsByDay,
      weekArray,
      data,
      isRefetching,
      extensiveSelectedDay,
      daysOfWeekEN,
      formattedMonthWithUppercase,
    }
  }, [
    currentDay,
    formattedMonthWithUppercase,
    itemsByDay,
    changeCurrentDay,
    selectedDay,
    daysOfWeekEN,
    changeSelectedDay,
    weekArray,
    isLoading,
    data,
    isRefetching,
    extensiveSelectedDay,
  ])

  return (
    <ScheduleByWeekPageContext.Provider value={schedulePageProviderValues}>
      {children}
    </ScheduleByWeekPageContext.Provider>
  )
}

const useScheduleByWeekPageContext = () => useContext(ScheduleByWeekPageContext)

export { ScheduleByWeekPageProvider, useScheduleByWeekPageContext }
