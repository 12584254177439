import { Box, Card, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const CardContainer = styledMUI(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  boxShadow: 'none',

  animation: 'fadeIn 0.4s ease-out forwards',
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

export const TitleRow = styledMUI(Card)(() => ({
  display: 'flex',
  marginTop: '20px',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  boxShadow: 'none',
}))

export const NuOrder = styledMUI(Card)(() => ({
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${theme.colors.midDark}`,
  color: 'white',
  fontSize: '12px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '150%',
  color: '#000',
}))

export const Content = styledMUI(Box)(() => ({
  backgroundColor: '#fff',
  padding: '16px 0 8px',
  zIndex: 999,
  marginBottom: '180px',
}))

export const CardBox = styledMUI(Box)(() => ({
  width: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '15px',
  gap: '16px',
  cursor: 'pointer',
  backgroundColor: `${theme.colors.midLight}`,
  marginTop: '18px',
}))

export const RowCard = styledMUI(Box)(() => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const TitleCard = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  display: 'flow',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const TextParagraph = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '120%',
}))

export const CardInfo = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  gap: '8px',
}))

export const Component = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#666',
  backgroundColor: '#FFF',
  borderRadius: '4px',
  padding: '4px 8px',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
}))
