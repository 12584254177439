import { Modal, Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContainerModal = styledMUI(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ContentModal = styledMUI(Box)(() => ({
  maxWidth: '1300px',
  maxHeight: '92%',
  overflowY: 'auto',
  backgroundColor: '#fff',
  borderRadius: 16,
  display: 'flex',
  outline: 'none',
}))

export const Content = styledMUI(Box)(() => ({ overflowY: 'auto' }))
