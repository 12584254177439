import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { ITrack } from '~/pages/private/Trails/TrailSchedule/interfaces'

const getTrackSchedule = async (idClass: number, trackId: number) => {
  const { data } = await HTTPClient.get(`moment/class/${idClass}/track-schedule/${trackId}`)

  return data
}

export const useTrackSchedule = (idClass: number, trackId: number, enabled?: boolean) =>
  useQuery<ITrack>(['TrackSchedule'], () => getTrackSchedule(idClass, trackId), { enabled })
