import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IGetDocumentationList } from '~/pages/private/Documentations/interfaces'
import { ResponseProjectInfo } from '~/pages/private/Fund2/Project/interfaces'

export const getProjectDocumentation = async (projectId: number) => {
  const { data } = await HTTPClient.get(`/project-documentation/by-moment/${projectId}`)

  return data
}

export const useProjectDocumentation = (projectId: number) =>
  useQuery<IGetDocumentationList>(['getProjectDocumentation'], () => getProjectDocumentation(projectId))

export const getProjectInformation = async (projectId: number) => {
  const { data } = await HTTPClient.get(`/project-moment/${projectId}`)

  return data
}

export const useGetProjectInformation = (
  projectId: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) => useQuery<ResponseProjectInfo>(['getProjectInformation'], () => getProjectInformation(projectId), options)

export const getProjectsGenerated = async (idProjectTrack: number, idClass: number) => {
  const { data } = await HTTPClient.get(`/project-track/${idProjectTrack}/class/${idClass}/students`)

  return data
}

export const useGetProjectGenerated = (idProjectTrack: number, idClass: number) =>
  useQuery<ResponseProjectInfo>(['getProjectsGenerated'], () => getProjectsGenerated(idProjectTrack, idClass))
