import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const SpanInactive = styledMUI(Box)(() => ({
  fontSize: '10px',
  fontWeight: 700,
  padding: '4px 8px',
  color: `${theme.colors.highLight}`,
  borderRight: `1px solid ${theme.colors.lowLight}`,
  backgroundColor: `${theme.colors.lowLight}`,
  borderRadius: '4px',
  marginLeft: '5px',
  '&:last-child': {
    borderRight: 0,
  },
}))
