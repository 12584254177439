import { UilMultiply } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent } from '~/components'

import { ModalComponentBase } from '../ModalBase/Modal'
import { IModalComponentProps, IStudentsProps } from './Modal.interfaces'
import * as S from './ModalAvatarList.styles'

export const ModalAvatarList = ({ open, actionCloseModal, avatarList }: IModalComponentProps) => {
  const { width } = useWindowDimensions()

  const isMobile = width && width <= 600

  const transformFullName = (fullName: string): string => {
    const firstName = fullName?.split(' ')[0]
    const capitalizedFirstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1).toLowerCase()
    return capitalizedFirstName
  }

  return (
    <ModalComponentBase open={open} actionCloseModal={actionCloseModal} maxWidth={'1088px'} width={'80%'}>
      <S.Container>
        <S.ContentBox>
          <S.Title>Visualizar {avatarList.length} Alunos</S.Title>
          <S.BtnClose onClick={actionCloseModal}>
            <UilMultiply size={16} color="#000" />
          </S.BtnClose>
        </S.ContentBox>
        <S.Divider />
        <S.SmallView>
          <S.AvatarContainer className="styled-scroll-s">
            {avatarList.map((student: IStudentsProps) => (
              <S.CardStudent key={student.idStudent || student.studentId}>
                <AvatarComponent
                  photoUrl={student.txImagePath || student.url}
                  label={student.txName || student.label}
                  size={isMobile ? 'medium' : 'mediumPlus'}
                />
                {isMobile ? (
                  <S.TextStudent>
                    {transformFullName((student.txName || student.label)?.split(/(\s).+\s/).join(''))}
                  </S.TextStudent>
                ) : (
                  <S.TextStudent>{student.txName || student.label}</S.TextStudent>
                )}
              </S.CardStudent>
            ))}
          </S.AvatarContainer>
        </S.SmallView>
      </S.Container>
    </ModalComponentBase>
  )
}
