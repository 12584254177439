export const formatDayOfWeek = (day: string) => {
  switch (day) {
    case 'Do':
      return 'D'
    case '2ª':
      return 'S'
    case '3ª':
      return 'T'
    case '4ª':
      return 'Q'
    case '5ª':
      return 'Q'
    case '6ª':
      return 'S'
    case 'Sá':
      return 'S'

    default:
      return ''
  }
}
