import { gql } from 'graphql-request'

export const GET_CLASSES_BY_TUTOR = gql`
  query ClassByTutor {
    classByTutor(order: { coGrade: ASC }) {
      items {
        idClass
        txName
        coGrade
        schoolGrade {
          grade {
            txGrade
            coStage
          }
          school {
            txName
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
