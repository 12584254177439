import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IGetAgendaCounter, IGetAgendaMoments } from './types'

export const getMomentProfessor = async () => {
  const { data } = await HTTPClient.get(`/moment/professor/agenda-moments`)

  return data
}

export const useGetMomentProfessor = () =>
  useQuery<IGetAgendaMoments>(['getProfessorMoment'], () => getMomentProfessor())

export const getMomentCounter = async () => {
  const { data } = await HTTPClient.get(`/moment/professor/agenda-counters`)

  return data
}

export const useGetMomentCounter = () => useQuery<IGetAgendaCounter>(['getCounters'], () => getMomentCounter())

export const getProfessorHasAnyMoments = async () => {
  const { data } = await HTTPClient.get(`/moment/professor/has-moments`)

  return data.value
}

export const useGetProfessorHasAnyMoments = () =>
  useQuery<boolean>(['getProfessorHasAnyMoments'], () => getProfessorHasAnyMoments())
