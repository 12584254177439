import { useNavigate } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'
import { Box, Tooltip } from '@mui/material'

import { TrailList } from '~/services/Trails/types'

import { ButtonComponent, IconComponent } from '~/components'

import theme from '~/styles/theme'

import { IActiveMomentCardComponentProps, UrlMap } from './ActiveMomentCard.interfaces'
import * as S from './ActiveMomentCard.styles'

export const ActiveMomentCardComponent = ({
  color,
  lessons,
  thisLesson,
  idLessonMoment,
  idLesson,
  lessonActivity,
  navigationState,
  txTrackTitle,
  idClass,
  isOffline = 'false',
  idMomentTrack,
  coStage,
  handleNavigation,
  status,
}: IActiveMomentCardComponentProps) => {
  const navigate = useNavigate()
  const lessonArray = Array.from({ length: lessons || 0 }, (_, index) => index + 1)
  switch (color) {
    case 'AGEN':
      color = theme.colors.midDark
      break
    case 'AUPL':
      color = theme.colors.primaryPure
      break
    case 'AVPE':
      color = theme.colors.warningPure
      break
    case 'FINA':
      color = theme.colors.positivePure
      break
    case 'PEND':
      color = theme.colors.actionPure
      break
    case 'AUPE':
      color = theme.colors.negativePure
      break
    case 'CANC':
      color = theme.colors.lowLight
      break
  }

  const addOffline = (to: string) => {
    if (isOffline === 'true') {
      return `/offline${to}`
    }
    return to
  }

  const urlMap: UrlMap = {
    IN: '/trail/:idTrack/occupied/:idClass',
    F1: '/trail/:idTrack/occupied/:idClass',
    M2: '/trail/:idTrack/occupied/:idClass',
    F2: '/trail/:idTrack/project/:idClass',
    EM: '/trail/:idTrack/project/:idClass',
    M3: '/trail/:idTrack/project/:idClass',
    M4: '/trail/:idTrack/project/:idClass',
  }

  const validateTrailRouter = (coStage: string, idTrack: string, idClass: number) => {
    const url = urlMap[coStage] || ''
    if (url) {
      const formattedUrl = url.replace(':idTrack', idTrack).replace(':idClass', idClass.toString())
      navigate(formattedUrl, { state: { from: window.location.pathname + window.location.search } })
    }

    return url
  }

  const TrailTooltip = ({
    lessonOrder,
    children,
  }: {
    trail: TrailList
    children: React.ReactElement
    lessonOrder: number
  }) => {
    const renderTrail = () => {
      return (
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <S.InfoText>Essa é a {lessonOrder}ª aula da trilha:</S.InfoText>

          <S.TrailTitle>{txTrackTitle ? txTrackTitle : ''}</S.TrailTitle>

          <S.OrderScheduleBox>
            <ButtonComponent
              onClick={(event) => {
                event.stopPropagation(), validateTrailRouter(coStage, idMomentTrack, idClass)
              }}
              size="small"
              variant="outline"
              fontWeight="normal"
              text="Acessar Trilha"
              iconEnd={<UilArrowRight size={16} color={theme.colors.actionPure} />}
            />
          </S.OrderScheduleBox>
        </Box>
      )
    }
    return (
      <S.TooltipContainerHover>
        <S.TooltipContainer>
          <Tooltip
            title={renderTrail()}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '12px',
                  padding: '16px',
                  fontWeight: 'bold',
                  backgroundColor: theme.colors.highPure,
                  width: '334px',
                  top: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
                  zIndex: '99999999',
                },
              },
            }}
            placement={'bottom-start'}
          >
            {children}
          </Tooltip>
        </S.TooltipContainer>
      </S.TooltipContainerHover>
    )
  }

  return (
    <>
      {navigationState ? (
        <S.Tabs
          actived={
            navigationState.secondStep ||
            navigationState.secondStepA ||
            navigationState.secondStepB ||
            navigationState.secondStepC ||
            navigationState.secondStepD
              ? 'true'
              : 'false'
          }
          type="default"
        >
          <S.ButtonSecondStepNavigation
            variantnavigate="secondStep"
            active={navigationState.secondStep ? 1 : 0}
            to={addOffline(`${isOffline ? '/' : `/class/${idClass}/`}second-step/${idLessonMoment}/lesson/${idLesson}`)}
            onClick={() => handleNavigation('secondStep')}
          >
            2
          </S.ButtonSecondStepNavigation>
          <S.ButtonSecondStepNavigation
            variantnavigate="secondStepA"
            active={navigationState.secondStepA ? 1 : 0}
            to={addOffline(
              `${
                isOffline ? '/' : `/class/${idClass}/`
              }second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${
                lessonActivity?.[0]?.idLessonActivity
              }`,
            )}
            onClick={() => handleNavigation('secondStepA')}
          >
            <IconComponent code={lessonActivity?.[0]?.category?.txImagePath} size="xxsmall" fontWeight="normal" />
          </S.ButtonSecondStepNavigation>
          <S.ButtonSecondStepNavigation
            variantnavigate="secondStepB"
            active={navigationState.secondStepB ? 1 : 0}
            to={addOffline(
              `${
                isOffline ? '/' : `/class/${idClass}/`
              }second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${
                lessonActivity?.[1]?.idLessonActivity
              }`,
            )}
            onClick={() => handleNavigation('secondStepB')}
          >
            <IconComponent code={lessonActivity?.[1]?.category?.txImagePath} size="xxsmall" fontWeight="normal" />
          </S.ButtonSecondStepNavigation>
          <S.ButtonSecondStepNavigation
            variantnavigate="secondStepC"
            active={navigationState.secondStepC ? 1 : 0}
            to={addOffline(
              `${
                isOffline ? '/' : `/class/${idClass}/`
              }second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${
                lessonActivity?.[2]?.idLessonActivity
              }`,
            )}
            onClick={() => handleNavigation('secondStepC')}
          >
            <IconComponent code={lessonActivity?.[2]?.category?.txImagePath} size="xxsmall" fontWeight="normal" />
          </S.ButtonSecondStepNavigation>
          {status === 'AGEN' && (
            <S.ButtonSecondStepNavigation
              variantnavigate="secondStepD"
              active={navigationState.secondStepD ? 1 : 0}
              to={addOffline(
                `${
                  isOffline ? '/' : `/class/${idClass}/`
                }second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${
                  lessonActivity?.[3]?.idLessonActivity
                }`,
              )}
              onClick={() => handleNavigation('secondStepD')}
            >
              <IconComponent code={lessonActivity?.[3]?.category?.txImagePath} size="xxsmall" fontWeight="normal" />
            </S.ButtonSecondStepNavigation>
          )}
        </S.Tabs>
      ) : (
        <>
          {isOffline === 'false' && (
            <TrailTooltip trail={lessonArray} lessonOrder={thisLesson} txTrackTitle={txTrackTitle}>
              <S.ContainerDivider>
                <>
                  {lessonArray?.map((lesson) => (
                    <S.RowNumbers key={lesson} color={color} isHighlighted={lesson === thisLesson}>
                      {lesson}
                    </S.RowNumbers>
                  ))}
                </>
              </S.ContainerDivider>
            </TrailTooltip>
          )}

          {isOffline === 'true' && (
            <S.ContainerDivider>
              <>
                {lessonArray?.map((lesson) => (
                  <S.RowNumbers key={lesson} color={color} isHighlighted={lesson === thisLesson}>
                    {lesson}
                  </S.RowNumbers>
                ))}
              </>
            </S.ContainerDivider>
          )}
        </>
      )}
    </>
  )
}
