import { CustomDndProvider } from '~/components'

import { ListClassDetailsPageProvider } from './context'
import { ListClassDetailsView } from './view'

export const ListClassDetailsPage = () => {
  return (
    <ListClassDetailsPageProvider>
      <CustomDndProvider>
        <ListClassDetailsView />
      </CustomDndProvider>
    </ListClassDetailsPageProvider>
  )
}
