import React from 'react'

import Tooltip from '@mui/material/Tooltip'

interface DSTooltipProps {
  text: string
  position?: 'top' | 'bottom' | 'left' | 'right'
  children: React.ReactElement
}

const DSTooltip: React.FC<DSTooltipProps> = ({ text, position = 'top', children }) => {
  return (
    <Tooltip title={text} placement={position} arrow>
      {children}
    </Tooltip>
  )
}

export default DSTooltip
