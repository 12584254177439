import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  gap: 24px;
  padding: 24px;
  grid-template-columns: 1fr;
`
