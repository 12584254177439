import { UilClockThree } from '@iconscout/react-unicons'
import dayjs from 'dayjs'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AbilityAssessment, Moment } from '~/services/Dashboard/types'

import { ChipComponent } from '~/components'

import { formatLabel } from '../OverviewByCompetence/OverviewByCompetence'
import * as S from './LessonPerfomanceChart.styles'

dayjs.locale('pt-br')

const formatDate = (value: number) => {
  return dayjs(value).format('DD/MM/YY')
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <S.Tooltip>
        <ChipComponent
          type="light"
          icon={<UilClockThree size={14} color="#0095FF" />}
          label={formatDate(label) ?? ''}
        />
        <S.TitleTooltip>{payload[0].payload.txMoment}</S.TitleTooltip>
        {payload[0].payload.abilityAssessments.map((item: AbilityAssessment, index: number) => (
          <S.AbilityContainer key={`ability-containe-${index}`}>
            <S.TitleAbility>{item.abilityName}</S.TitleAbility>
            <S.StatusTooltip>{item.txAssessment}</S.StatusTooltip>
          </S.AbilityContainer>
        ))}
      </S.Tooltip>
    )
  }

  return null
}

type LessonPerfomanceChartProps = {
  competenceId: number
  color: string
  moments: Moment[]
}

export const LessonPerfomanceChart = ({ moments, color }: LessonPerfomanceChartProps) => {
  const { width } = useWindowDimensions()

  const isMobile = width && width <= 768

  const chartData = moments.map((item) => ({
    ...item,
    dtSchedule: item.dtSchedule,
    uv: item.momentAssessmentAverage === undefined ? 1 : item.momentAssessmentAverage,
  }))

  return (
    <S.Container>
      <h3>Desempenho nas aulas</h3>
      <S.ContainerChart isMobile={isMobile ? true : false}>
        <ResponsiveContainer width={isMobile ? 280 : 750} height={230}>
          <BarChart
            width={isMobile ? 280 : 750}
            height={230}
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 10,
            }}
            barSize={24}
          >
            <Tooltip content={<CustomTooltip />} position={isMobile ? { x: 30, y: 180 } : undefined} />
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="dtSchedule"
              tickFormatter={formatDate}
              fontSize={10}
              axisLine={false}
              tickMargin={10}
              tick={{ strokeWidth: 0, stroke: '#000' }}
              tickLine={false}
              angle={-90}
              textAnchor="end"
              height={50}
            />
            <YAxis
              tickFormatter={formatLabel}
              tickLine={false}
              fontSize={12}
              domain={[0, 100]}
              fontWeight={700}
              dataKey={'performance'}
              axisLine={false}
              padding={{ bottom: 0 }}
              width={80}
            />
            <Bar dataKey="uv" fill={`#${color}`} radius={5} />
          </BarChart>
        </ResponsiveContainer>
      </S.ContainerChart>
    </S.Container>
  )
}
