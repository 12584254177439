export const validateType = (coStage: string): number => {
  switch (coStage) {
    case 'IN':
      return 1
    case 'F1':
      return 1
    case 'F2':
      return 2
    case 'EM':
      return 2
    case 'M4':
      return 2
    case 'M3':
      return 1
    case 'M2':
      return 1
    default:
      return 0
  }
}
