import { createContext, useContext, useMemo } from 'react'

import { useOfflineMode } from '~/_context/Offline'

import { IBNCCRelationsProjectPageProviderProps, IBNCCRelationsProjectContextProps } from './interfaces'

const LessonBNCCRelationsProjectPageContext = createContext<IBNCCRelationsProjectContextProps>({
  projects: [],
})

const ProjectBNCCRelationsPageProvider = ({ children }: IBNCCRelationsProjectPageProviderProps) => {
  const { getOfflineInformation } = useOfflineMode()
  const projects = getOfflineInformation?.[0]?.projectMomentsDetails?.[0]?.projectMomentStage

  const projectBNCCRelationsProjectPageProviderValues = useMemo(() => {
    return { projects }
  }, [projects])

  return (
    <LessonBNCCRelationsProjectPageContext.Provider value={projectBNCCRelationsProjectPageProviderValues}>
      {children}
    </LessonBNCCRelationsProjectPageContext.Provider>
  )
}

const useLessonBNCCRelationsProjectPageContext = () => useContext(LessonBNCCRelationsProjectPageContext)

export { ProjectBNCCRelationsPageProvider, useLessonBNCCRelationsProjectPageContext }
