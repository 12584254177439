import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { IApiError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

import { IDataProps, LessonMoment, UpdateStatusBatchProps } from '~/pages/private/Fund1/Lesson/interfaces'

export const getLesson = async (lessonId: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment/${lessonId}`)

  return data
}

export const useServiceGetLesson = (lessonId: number, options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) =>
  useQuery<IDataProps>(['getLesson'], () => getLesson(lessonId), options)

export const UpdateStatus = async (prop: any) => {
  const { data } = await HTTPClient.patch(`/lesson-moment/set-status/${prop.status}/for-moment/${prop.lessonId}`)

  return data
}

export const useServiceUpdateLessonStatus = () => useMutation<any, IApiError, any>(['UpdateStatus'], UpdateStatus)

export const UpdateStatusBatch = async (prop: UpdateStatusBatchProps) => {
  const moments: LessonMoment[] = (prop?.idLessonMoments ?? prop?.idProjectMoments ?? []).map((id) => ({
    idClass: prop.idClass,
    idMoment: id,
  }))

  const { data } = await HTTPClient.patch(`/moment/set-status-batch/${prop.status}`, moments)

  return data
}

export const useServiceUpdateLessonStatusBatch = () =>
  useMutation<any, IApiError, any>(['UpdateStatusBatch'], UpdateStatusBatch)

export const useServiceUpdateProjectStatusBatch = () =>
  useMutation<any, IApiError, any>(['UpdateStatus'], UpdateStatusBatch)

export const UpdateStatusProject = async (status: string, lessonId: number, idClass: number) => {
  const { data } = await HTTPClient.patch(`/moment/class/${idClass}/moment/${lessonId}/set-status/${status}`)

  return data
}

export const useServiceUpdateProjectStatus = (
  status: string,
  lessonId: number,
  idClass: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) => useMutation<any, IApiError, void>(['UpdateStatus'], () => UpdateStatusProject(status, lessonId, idClass), options)
