import { CustomDndProvider } from '~/components'

import { SecondStepPageProvider } from './context'
import { SecondStepView } from './view'

export const SecondStepPage = () => {
  return (
    <SecondStepPageProvider>
      <CustomDndProvider>
        <SecondStepView />
      </CustomDndProvider>
    </SecondStepPageProvider>
  )
}
