import { Box, Typography, InputAdornment, TextField, Chip, IconButton } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'
export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '94px 24px 24px',
  marginTop: '-70px',
  gap: '16px',
  borderBottom: '1px solid #E2E5EC',
  backgroundColor: '#fff',
  zIndex: 100,
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const WrapperTittle = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    height: '100%',
    display: 'grid',
  },
}))

export const HeaderTypograph = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'cenlefter',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    height: '100%',
    display: 'grid',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const TitleClass = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: `${theme.colors.actionPure}`,
}))

export const ChipLabel = styledMUI(Chip)(() => ({}))
export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#666',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  border: '1px solid #0095FF',
  borderRadius: '50px',
  padding: 0,
}))

export const InputText = styledMUI(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    height: '40px',
    width: '200px',
    color: '#666',

    '& fieldset': {
      borderColor: '#666',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#666',
    },
  },
}))

export const InputAdornmentIcon = styledMUI(InputAdornment)(() => ({}))
