import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import loadingMan from '~/assets/images/thinking_man.png'

export const Content = styledMUI(Box)(() => ({
  height: '100vh',
  width: '100vw',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

export const Text = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '100%',
  color: '#000',
}))

export const ImageMan = styledMUI(Box)(() => ({
  backgroundImage: `url(${loadingMan})`,
  backgroundRepeat: 'no-repeat',
  width: '350px',
  height: '400px',
}))

export const Progress = styledMUI(Box)(() => ({
  marginBottom: '20px',
}))
