import { UilFavorite, UilNotes, UilSignAlt } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { IconComponent } from '~/components'

import { colorsStepsActived } from '~/validations'

import { IStepsTabsComponentProps } from './StepsTabs.interfaces'
import * as S from './StepsTabs.styles'

export const StepsTabsComponent = ({
  type = 'default',
  typeNavigation,
  steps,
  activedTab,
  actionSetActiveTab,
  status = true,
  mobile,
  stages,
  isNavigate,
  length,
  maxWidth,
}: IStepsTabsComponentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  if (isNavigate === true)
    return (
      <S.TabsNavigate typeNavigation={typeNavigation || 'default'} maxWidth={maxWidth}>
        {steps?.map((step, index: number) => (
          <S.TabNavigate
            key={index}
            length={length}
            actived={String(activedTab === index)}
            typeNavigation={typeNavigation || 'default'}
            onClick={() => actionSetActiveTab(index)}
          >
            {step.icon}
            <S.TextTabNavigate
              variant="caption"
              actived={String(activedTab === index)}
              typeNavigation={typeNavigation || 'default'}
            >
              {step.label}
            </S.TextTabNavigate>
          </S.TabNavigate>
        ))}
      </S.TabsNavigate>
    )
  else if (type === 'planning')
    return (
      <S.Tabs type={'default'}>
        <>
          <S.Tab actived={String(activedTab === 0)} type={'default'} onClick={() => actionSetActiveTab(0)}>
            <UilSignAlt size={20} color={activedTab === 0 ? '#fff' : colorsStepsActived('default')} />

            <S.TextTab variant="caption" actived={String(activedTab === 0)} type={'default'}>
              {!isMobile || activedTab === 0 ? 'Orientações Gerais' : ''}
            </S.TextTab>
          </S.Tab>

          {stages?.map((stage, index) => (
            <S.Tab
              actived={String(activedTab === index + 1)}
              type={'default'}
              key={stage.idProjectMomentStage}
              onClick={() => actionSetActiveTab(index + 1)}
            >
              <IconComponent
                color={activedTab === index + 1 ? '#FFF' : 'primary'}
                fontWeight="normal"
                code={stage?.projectStage?.project?.projectCategory?.[0]?.category?.txImagePath}
                size="xsmall"
              />

              <S.TextTab variant="caption" actived={String(activedTab === index + 1)} type={'default'}>
                {!isMobile || activedTab === index + 1
                  ? stage.projectStage?.project?.projectCategory?.[0]?.category?.txName
                  : ''}
              </S.TextTab>
            </S.Tab>
          ))}
        </>
      </S.Tabs>
    )

  return (
    <S.Tabs type={type}>
      {status && type !== 'default' && (
        <>
          <S.Tab actived={String(activedTab === 0)} type={type} onClick={() => actionSetActiveTab(0)}>
            <UilSignAlt size={20} color={activedTab === 0 ? '#fff' : colorsStepsActived(type)} />
            <S.TextTab variant="caption" actived={String(activedTab === 0)} type={type}>
              {(!mobile || activedTab === 0) && 'Orientações'}
            </S.TextTab>
          </S.Tab>

          <S.Tab actived={String(activedTab === 1)} type={type} onClick={() => actionSetActiveTab(1)}>
            <UilNotes size={20} color={activedTab === 1 ? '#fff' : colorsStepsActived(type)} />
            <S.TextTab variant="caption" actived={String(activedTab === 1)} type={type}>
              {(!mobile || activedTab === 1) && 'Documentações'}
            </S.TextTab>
          </S.Tab>

          <S.Tab actived={String(activedTab === 2)} type={type} onClick={() => actionSetActiveTab(2)}>
            <UilFavorite size={20} color={activedTab === 2 ? '#fff' : colorsStepsActived(type)} />
            <S.TextTab variant="caption" actived={String(activedTab === 2)} type={type}>
              {(!mobile || activedTab === 2) && 'Avaliação'}
            </S.TextTab>
          </S.Tab>
        </>
      )}

      {!status && type !== 'default' && (
        <>
          <S.Tab
            actived={String(activedTab === 0)}
            type={type}
            onClick={() => actionSetActiveTab(0)}
            status={status ? 1 : 0}
          >
            <UilSignAlt size={20} color={activedTab === 0 ? '#fff' : colorsStepsActived(type)} />

            <S.TextTab variant="caption" actived={String(activedTab === 0)} type={type}>
              Orientações
            </S.TextTab>
          </S.Tab>
        </>
      )}

      {status && type === 'default' && (
        <>
          {steps?.map((step: { label: string; labelDescription?: string }, index: number) => (
            <>
              {mobile ? (
                <S.TabDefault
                  actived={String(activedTab === index)}
                  type={type}
                  key={index}
                  onClick={() => actionSetActiveTab(index)}
                >
                  <S.TextTab variant="caption" actived={String(activedTab === index)} type={type}>
                    {activedTab === index ? step.label : index + 1}
                  </S.TextTab>
                  <S.TextTab variant="caption" actived={String(activedTab === index)} type={type}>
                    {activedTab === index ? step.labelDescription : ''}
                  </S.TextTab>
                </S.TabDefault>
              ) : (
                <S.Tab
                  actived={String(activedTab === index)}
                  type={type}
                  key={index}
                  onClick={() => actionSetActiveTab(index)}
                >
                  <S.TextTab variant="caption" actived={String(activedTab === index)} type={type}>
                    {step.label}
                  </S.TextTab>
                  <S.TextTab variant="caption" actived={String(activedTab === index)} type={type}>
                    {step.labelDescription}
                  </S.TextTab>
                </S.Tab>
              )}
            </>
          ))}
        </>
      )}
    </S.Tabs>
  )
}
