import { AuthThirdPartyPageProvider } from './context'
import { AuthThirdPartyView } from './view'

export const AuthThirdPartyPage = () => {
  return (
    <AuthThirdPartyPageProvider>
      <AuthThirdPartyView />
    </AuthThirdPartyPageProvider>
  )
}
