import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { IFirstStepPageContextProps, IFirstStepPageProviderProps } from './interfaces'

const FirstStepPageContext = createContext<IFirstStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  lesson: undefined,
  lessonSteps: undefined,
  firstStepGroup: undefined,
})

const FirstStepPageProvider = ({ children }: IFirstStepPageProviderProps) => {
  const { idLessonMoment } = useParams()
  const { getOfflineInformation } = useOfflineMode()

  const [activedTab, setActivedTab] = useState<number>(0)
  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const lessonSteps = getOfflineInformation?.[0].lessonSteps[2]
  const lesson = getOfflineInformation?.[0]?.moments.find((moment) => moment.idMoment === Number(idLessonMoment))
  const firstStepGroup = getOfflineInformation?.[0]?.lessonMomentGroups.find(
    (groups) => groups.idLessonMoment === Number(idLessonMoment) && groups.nuOrder === 3,
  )

  const firstStepPageProviderValues = useMemo(() => {
    return {
      activedTab,
      handleActiveNewTab,
      lesson,
      lessonSteps,
      firstStepGroup,
    }
  }, [firstStepGroup, lesson, activedTab, handleActiveNewTab, lessonSteps])

  return <FirstStepPageContext.Provider value={firstStepPageProviderValues}>{children}</FirstStepPageContext.Provider>
}

const useFirstStepPageContext = () => useContext(FirstStepPageContext)

export { FirstStepPageProvider, useFirstStepPageContext }
