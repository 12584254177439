import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IDashboardInformation, IDashboardResponse } from '~/services/Dashboard/types'

export const getDashboard = async (userID: number) => {
  const { data } = await HTTPClient.get(`/diagnostic-survey/result/by-user/${userID}`)

  return data
}

interface IGetDashboardInformation {
  userID: number
  coGrade: string
}

export const getDashboardInformation = async ({ userID, coGrade }: IGetDashboardInformation) => {
  const { data } = await HTTPClient.get(`/dashboard/v2/student/${userID}?grade=${coGrade}`)

  return data
}

export const useDashboardInformation = (userID: number, coGrade: string) =>
  useQuery<IDashboardInformation>(
    ['DashboardDataInformation'],
    async () =>
      coGrade !== undefined &&
      getDashboardInformation({
        userID,
        coGrade,
      }),
    {
      enabled: false,
    },
  )
export const useDashboard = (userID: number) =>
  useQuery<IDashboardResponse>(['DashboardData'], () => getDashboard(userID), { enabled: !!userID })
