import { CustomDndProvider } from '~/components'

import { TrailDetailsProjectPageProvider } from './context'
import { TrailDetailsProjectView } from './view'

export const TrailDetailsProjectPage = () => {
  return (
    <TrailDetailsProjectPageProvider>
      <CustomDndProvider>
        <TrailDetailsProjectView />
      </CustomDndProvider>
    </TrailDetailsProjectPageProvider>
  )
}
