import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const Content = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  borderBottom: '1px solid #E2E5EC',
}))

export const TextLabel = styledMUI(Typography)<{ color: string }>(({ color }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: color,
}))

export const TextChallenge = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
  span: {
    fontWeight: 700,
  },
}))
