import { HomePageProvider } from './context'
import { HomeView } from './view'

export const HomePageOffline = () => {
  return (
    <HomePageProvider>
      <HomeView />
    </HomePageProvider>
  )
}
