import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { ILessonPageContextProps, ILessonPageProviderProps } from './interfaces'

const LessonPageContext = createContext<ILessonPageContextProps>({
  lesson: undefined,
  idLesson: 0,
  lessonMomentDetails: undefined,
  lessonMomentsByTrack: undefined,
})

const LessonPageProvider = ({ children }: ILessonPageProviderProps) => {
  const { idLessonMoment } = useParams()
  const { getOfflineInformation } = useOfflineMode()

  const lesson = getOfflineInformation?.[0]?.moments.find((moment) => moment.idMoment === Number(idLessonMoment))
  const lessonStep = getOfflineInformation?.[0]?.lessonSteps.find(
    (moment) => moment.idLessonMoment === Number(idLessonMoment),
  )
  const lessonMomentDetails = getOfflineInformation?.[0]?.lessonMomentsDetails.find(
    (moment) => moment.idLessonMoment === Number(idLessonMoment),
  )

  const nuOrder = lesson?.nuOrder
  const totalClass = lesson?.nuTrackStages
  const txGrade = lesson?.class?.schoolGrade?.grade?.txGrade || ''
  const lessonMomentsByTrack = getOfflineInformation?.[0]?.lessonMomentsByTrack

  const lessonPageProviderValues = useMemo(() => {
    return {
      lesson,
      idLesson: lessonStep?.idLesson || 0,
      lessonMomentDetails,
      lessonMomentsByTrack,
      nuOrder,
      totalClass,
      txGrade,
    }
  }, [lessonMomentsByTrack, lessonMomentDetails, lesson, lessonStep?.idLesson, nuOrder, totalClass, txGrade])

  return <LessonPageContext.Provider value={lessonPageProviderValues}>{children}</LessonPageContext.Provider>
}

const useLessonPageContext = () => useContext(LessonPageContext)

export { LessonPageProvider, useLessonPageContext }
