import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { INotFoundPageContextProps, INotFoundPageProvider } from './interface'

const NotFoundPageContext = createContext<INotFoundPageContextProps>({
  handleGoToHome: () => Object,
})
const NotFoundPageProvider = ({ children }: INotFoundPageProvider) => {
  const navigate = useNavigate()

  const NotFoundPageProviderValues = useMemo(() => {
    const handleGoToHome = () => {
      navigate('/home')
    }
    return { handleGoToHome }
    // eslint-disable-next-line
  }, [])

  return <NotFoundPageContext.Provider value={NotFoundPageProviderValues}>{children}</NotFoundPageContext.Provider>
}
const useNotFoundContext = () => useContext(NotFoundPageContext)

export { NotFoundPageProvider, useNotFoundContext }
