import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import {
  AccordionTextComponent,
  AppBarComponent,
  ContainerComponent,
  DividerComponent,
  DropdownSkillComponent,
  ModalDocumentationComponent,
  QuickNavigationComponent,
  SkeletonComponent,
} from '~/components'

import { CardGeneralActivityComponent, HeaderGeneralComponent } from '~/containers/private/Fund1/SecondStep'

import { useSecondStepPageContext } from './context'
import * as S from './styles'

export const SecondGeneralStepView = () => {
  const {
    dataActivityGroup,
    isLoadingActivityGroup,
    isLoading,
    activedTab,
    handleActiveNewTab,
    data,
    dataModal,
    activities,
    activitiesAGEN,
    idLessonMoment,
    idLesson,
    isSameUser,
    isClassInStatus,
  } = useSecondStepPageContext()

  const { idClass } = useParams()

  const status = data.momentStatus.coMomentStatus
  const evidenceFixed = data.lessonActivity?.[0]?.lessonActivityEvidence?.filter(
    (item) => item.coEvidenceType === 'FIX',
  )[0].evidenceGrade.evidence

  const handleActivities = () => {
    return (
      <>
        {isLoadingActivityGroup ? (
          <Box p={'20px'} gap={'12px'} display={'grid'}>
            <SkeletonComponent variant="rounded" width={'100%'} height={120} />
            <SkeletonComponent variant="rounded" width={'100%'} height={120} />
            <SkeletonComponent variant="rounded" width={'100%'} height={120} />
          </Box>
        ) : (
          activities?.map((activity) => {
            const avatars = dataActivityGroup.lessonActivity.find(
              (item) => item.idLessonActivity === activity.idLessonActivity,
            )
            return (
              <>
                <CardGeneralActivityComponent
                  key={activity?.idLessonActivity}
                  txChallenge={activity?.txChallenge}
                  txActivity={activity.txTitle}
                  txSkill={activity?.category?.txName}
                  icon={activity?.category?.txImagePath}
                  activity={activity}
                  isLoading={isLoadingActivityGroup}
                  variableSkill={
                    data?.lessonActivity
                      ?.find((item) => item.idLessonActivity === activity.idLessonActivity)
                      ?.lessonActivityEvidence?.find((evidence) => evidence.coEvidenceType === 'VAR')?.evidenceGrade
                      ?.evidence
                  }
                  avatarsPhotos={avatars}
                />
              </>
            )
          })
        )}
      </>
    )
  }

  const handleActivitiesAgen = () => {
    return (
      <>
        {activitiesAGEN?.map((activity) => {
          return (
            <>
              <CardGeneralActivityComponent
                key={activity?.idLessonActivity}
                txChallenge={activity?.txChallenge}
                txActivity={activity.txTitle}
                txSkill={activity?.category?.txName}
                icon={activity?.category?.txImagePath}
                activity={activity}
                isLoading={isLoadingActivityGroup}
                variableSkill={
                  data?.lessonActivity
                    ?.find((item) => item.idLessonActivity === activity.idLessonActivity)
                    ?.lessonActivityEvidence?.find((evidence) => evidence.coEvidenceType === 'VAR')?.evidenceGrade
                    ?.evidence
                }
              />
            </>
          )
        })}
      </>
    )
  }

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            stepActived="secondStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            status={status}
          />
        </S.NavContainer>

        <HeaderGeneralComponent
          isLoading={isLoading}
          activedTab={activedTab}
          nuDuration={data.nuDuration}
          actionSetActiveTab={(newActivedTab: number) => handleActiveNewTab(newActivedTab)}
          title={dataModal?.lesson?.lessonStep[1].txTitle ? dataModal?.lesson?.lessonStep[1].txTitle : 'Realizar'}
        />

        <S.SkillDetails>
          <S.SkillSpan>Habilidade Fixa</S.SkillSpan>
          {isLoading ? (
            <SkeletonComponent variant="rounded" width={150} />
          ) : (
            evidenceFixed?.txName && (
              <DropdownSkillComponent
                txSkillName={evidenceFixed.ability?.txName}
                iconSkill={evidenceFixed.ability?.txImagePath}
                txSkillDescription={evidenceFixed.txName}
                txBncc=""
                txCompetency={evidenceFixed.ability?.competence?.txName}
                iconCompetency={evidenceFixed.ability?.competence?.txImagePath}
              />
            )
          )}
        </S.SkillDetails>
        <DividerComponent />
        <S.ContentDescription>
          {isLoading ? (
            <SkeletonComponent variant="rounded" width={200} />
          ) : (
            <AccordionTextComponent paragraph={data.txGuidance} typePlus={'bottom'} limit={510} />
          )}
        </S.ContentDescription>
        <DividerComponent />

        {['AGEN'].includes(status) ? <div>{handleActivitiesAgen()}</div> : <div>{handleActivities()}</div>}

        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && isSameUser && (
            <ModalDocumentationComponent
              idLesson={idLesson}
              idLessonMoment={idLessonMoment}
              variant="floater"
              isClassInStatus={isClassInStatus}
            />
          )}
        </>
      </ContainerComponent>
    </>
  )
}
