import React from 'react'

import { AccordionDetails, Box } from '@mui/material'

import { AssessmentComponent, SkeletonComponent, StepsTabsComponent } from '~/components'

import { IStudenClassProps } from '~/containers/private/Fund2/Project/ProjectCard/ProjectCard.interfaces'

import * as S from '../styles'

interface ProjectFormProps {
  tabActived: number
  idClass: number
  handleActiveNewTab: () => object
  status: string
  variablePayload: unknown
  fixedPayload: unknown
  firstEvidence: unknown
  isSameUser: boolean
  idActivity: number
  idProject: number
  mutateAssessment: () => object
  mutateAssessmentBatch: () => object
  students: IStudenClassProps[]
  isLoading: boolean
  isClassInStatus: boolean
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  tabActived,
  handleActiveNewTab,
  isSameUser,
  status,
  variablePayload,
  fixedPayload,
  idActivity,
  idProject,
  mutateAssessment,
  mutateAssessmentBatch,
  students,
  isLoading,
  idClass,
  isClassInStatus,
}) => {
  const isFinish = status === 'FINA'
  const handleAssessment = (id: string, coAssessment: string, idEvidence: string) => {
    mutateAssessment({ projectId: String(idProject), idClass: idClass }, [
      {
        idMomentGroup: id,
        coAssessment,
        idEvidence,
      },
    ])
  }

  const handleAssessmentBatch = (body) => {
    body.projectId = String(idProject)
    body.idClass = String(idClass)
    mutateAssessmentBatch(body)
  }

  return (
    <>
      {isLoading && (
        <>
          <Box mt={25} p={2} display="flex" flexDirection="column" gap={2}>
            <SkeletonComponent variant="rounded" width={'100%'} height={52} />

            <Box mt={2} display="flex" flexDirection="row" gap={2} justifyItems={'center'}>
              <SkeletonComponent variant="rounded" width={60} height={20} />
              <SkeletonComponent variant="rounded" width={200} height={20} />
              <SkeletonComponent variant="rounded" width={200} height={20} />
            </Box>
            <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
              <SkeletonComponent variant="rounded" width={'100%'} height={20} />
              <SkeletonComponent variant="rounded" width={'30%'} height={20} />
            </Box>

            <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
              <SkeletonComponent variant="rounded" width={'100%'} height={150} />
            </Box>
          </Box>
          <Box display="flex" p={2} flexWrap="wrap" gap={2}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Box key={index} display="flex" flexDirection="column" gap={2} width="49%">
                <Box>
                  <SkeletonComponent variant="rounded" width={'100%'} height={200} />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      {!isLoading && (
        <S.Content>
          <StepsTabsComponent
            type={'default'}
            steps={[
              { label: 'Projeto 1', labelDescription: '' },
              { label: 'Projeto 2', labelDescription: '' },
              { label: 'Projeto 3', labelDescription: '' },
            ]}
            activedTab={tabActived}
            actionSetActiveTab={handleActiveNewTab}
          />
          {tabActived === 0 && variablePayload && fixedPayload && (
            <S.ListAccordions>
              <>
                <S.ContentAccordion>
                  <AccordionDetails sx={{ marginTop: '-30px' }}>
                    <>
                      <AssessmentComponent
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={variablePayload?.ability?.txName}
                        txEvidenceDescription={variablePayload?.txName}
                        indexEvidence={1}
                        lengthEvidence={2}
                        txCompentencyName={variablePayload?.ability?.competence?.txName}
                        txIconSkill={variablePayload?.ability?.txImagePath}
                        txIconCompetency={variablePayload?.ability?.competence?.txImagePath}
                        students={students}
                        idEvidence={variablePayload?.idEvidence}
                        skillType={'VAR'}
                        idClass={idClass}
                        type={'project'}
                        idActivity={idActivity}
                      />
                      <AssessmentComponent
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={fixedPayload?.ability?.txName}
                        txEvidenceDescription={fixedPayload?.txName}
                        indexEvidence={2}
                        lengthEvidence={2}
                        txCompentencyName={fixedPayload?.ability?.competence?.txName}
                        txIconSkill={fixedPayload?.ability?.txImagePath}
                        txIconCompetency={fixedPayload?.ability?.competence?.txImagePath}
                        students={students}
                        idEvidence={fixedPayload?.idEvidence}
                        skillType={'FIX'}
                        idClass={idClass}
                        type={'project'}
                        idActivity={idActivity}
                      />
                    </>
                  </AccordionDetails>
                </S.ContentAccordion>
              </>
            </S.ListAccordions>
          )}
          {tabActived === 1 && variablePayload && fixedPayload && (
            <S.ListAccordions>
              <>
                <S.ContentAccordion>
                  <AccordionDetails sx={{ marginTop: '-30px' }}>
                    <>
                      <AssessmentComponent
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={variablePayload?.ability?.txName}
                        txEvidenceDescription={variablePayload?.txName}
                        indexEvidence={1}
                        lengthEvidence={2}
                        txCompentencyName={variablePayload?.ability?.competence?.txName}
                        txIconSkill={variablePayload?.ability?.txImagePath}
                        txIconCompetency={variablePayload?.ability?.competence?.txImagePath}
                        students={students}
                        idEvidence={variablePayload?.idEvidence.toString()}
                        skillType={'VAR'}
                        type={'project'}
                        idClass={idClass}
                        idActivity={idActivity}
                      />
                      <AssessmentComponent
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={fixedPayload?.ability?.txName}
                        txEvidenceDescription={fixedPayload?.txName}
                        indexEvidence={2}
                        lengthEvidence={2}
                        txCompentencyName={fixedPayload?.ability?.competence?.txName}
                        txIconSkill={fixedPayload?.ability?.txImagePath}
                        txIconCompetency={fixedPayload?.ability?.competence?.txImagePath}
                        students={students}
                        idEvidence={fixedPayload?.idEvidence?.toString()}
                        skillType={'FIX'}
                        idClass={idClass}
                        type={'project'}
                        idActivity={idActivity}
                      />
                    </>
                  </AccordionDetails>
                </S.ContentAccordion>
              </>
            </S.ListAccordions>
          )}
          {tabActived === 2 && variablePayload && fixedPayload && (
            <S.ListAccordions>
              <>
                <S.ContentAccordion>
                  <AccordionDetails sx={{ marginTop: '-30px' }}>
                    <>
                      <AssessmentComponent
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={variablePayload?.ability?.txName}
                        txEvidenceDescription={variablePayload?.txName}
                        indexEvidence={1}
                        lengthEvidence={2}
                        txCompentencyName={variablePayload?.ability?.competence?.txName}
                        txIconSkill={variablePayload?.ability?.txImagePath}
                        txIconCompetency={variablePayload?.ability?.competence?.txImagePath}
                        students={students}
                        idEvidence={variablePayload?.idEvidence.toString()}
                        skillType={'VAR'}
                        type={'project'}
                        idClass={idClass}
                        idActivity={idActivity}
                      />
                      <AssessmentComponent
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={fixedPayload?.ability?.txName}
                        txEvidenceDescription={fixedPayload?.txName}
                        indexEvidence={2}
                        lengthEvidence={2}
                        txCompentencyName={fixedPayload?.ability?.competence?.txName}
                        txIconSkill={fixedPayload?.ability?.txImagePath}
                        txIconCompetency={fixedPayload?.ability?.competence?.txImagePath}
                        students={students}
                        idEvidence={fixedPayload?.idEvidence?.toString()}
                        skillType={'FIX'}
                        idClass={idClass}
                        type={'project'}
                        idActivity={idActivity}
                      />
                    </>
                  </AccordionDetails>
                </S.ContentAccordion>
              </>
            </S.ListAccordions>
          )}
        </S.Content>
      )}
    </>
  )
}

export default ProjectForm
