import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '240px 24px 24px 24px',
  '@media (max-width: 600px)': {
    padding: '24px',
    marginTop: '0',
  },
}))

export const Container = styled.div`
  width: 95%;
  height: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  margin-top: 100px;
  height: 100%;
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`
