import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { gaEvents } from '~/events'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useAvailableTrails } from '~/services/Trails'

import { defaultDataClassInfos, defaultTrailsDataUnavaibleState } from './default.states'
import { IListTrailsAvailablePageContextProps, IListTrailsAvailablePageProviderProps } from './interfaces'
import { GET_CLASS_INFOS_BY_CLASSID } from './schemas.queries'

const ListClassesPageContext = createContext<IListTrailsAvailablePageContextProps>({
  isLoadingClassInfos: false,
  dataClassInfos: defaultDataClassInfos,
  isLoadingTrailsAvailable: false,
  dataTraisDataUnavaible: defaultTrailsDataUnavaibleState,
  listOfAvailableTrailsResponse: undefined,
  isLoadingTrailsDataUnavaible: false,
  openModalSchedules: false,
  handleOpenModalSchedules: () => Object,
  handleOpenModalInfo: () => Object,
  handleCloseModalSchedules: () => Object,
  openModalSucess: false,
  handleOpenModalSucess: () => Object,
  handleCloseModalSucess: () => Object,
  openModalInfo: false,
})

const ListTrailsAvailablePageProvider = ({ children }: IListTrailsAvailablePageProviderProps) => {
  const { classId } = useParams()
  const navigate = useNavigate()
  const [openModalSchedules, setOpenModalSchedules] = useState<boolean>(false)
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false)

  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)

  const { isLoading: isLoadingClassInfos, data: dataClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(classId),
      showDisabledClasses: true,
    },
  )

  const { isLoading: isLoadingTrailsAvailable, data: listOfAvailableTrailsResponse } = useAvailableTrails(
    Number(classId),
    true,
  )

  const handleOpenModalSchedules = useCallback(() => {
    setOpenModalSchedules(true)
    gaEvents.eventSelectNewTrailAvailableButton()
  }, [])

  const handleCloseModalSchedules = useCallback(() => {
    setOpenModalSchedules(false)
  }, [])

  const handleOpenModalInfo = useCallback(() => {
    setOpenModalInfo(true)
  }, [])

  const handleCloseModalInfo = useCallback(() => {
    setOpenModalInfo(false)
  }, [])

  const handleOpenModalSucess = useCallback(() => {
    setOpenModalSucess(true)
  }, [])

  const handleCloseModalSucess = useCallback(() => {
    setOpenModalSucess(false)
    navigate(-1)
  }, [navigate])

  const listTrailsAvailablePageProviderValues = useMemo(() => {
    return {
      isLoadingClassInfos,
      dataClassInfos,
      isLoadingTrailsAvailable,
      openModalSchedules,
      listOfAvailableTrailsResponse,
      handleOpenModalSchedules,
      handleCloseModalSchedules,
      openModalSucess,
      handleOpenModalSucess,
      handleCloseModalSucess,
      handleOpenModalInfo,
      openModalInfo,
    }
  }, [
    isLoadingClassInfos,
    dataClassInfos,
    isLoadingTrailsAvailable,
    openModalSchedules,
    handleOpenModalSchedules,
    listOfAvailableTrailsResponse,
    handleCloseModalSchedules,
    openModalSucess,
    handleOpenModalSucess,
    handleCloseModalSucess,
    handleOpenModalInfo,
    openModalInfo,
  ])

  return (
    <ListClassesPageContext.Provider value={listTrailsAvailablePageProviderValues}>
      {children}
    </ListClassesPageContext.Provider>
  )
}

const useListTrailsAvailablePageContext = () => useContext(ListClassesPageContext)

export { ListTrailsAvailablePageProvider, useListTrailsAvailablePageContext }
