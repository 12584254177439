import { CustomDndProvider } from '~/components'

import { ScheduleTrailProvider } from './context'
import { ScheduleTrail } from './view'

export const ScheduleTrailPage = () => {
  return (
    <ScheduleTrailProvider>
      <CustomDndProvider>
        <ScheduleTrail />
      </CustomDndProvider>
    </ScheduleTrailProvider>
  )
}
