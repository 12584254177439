import { UilCalender, UilCheck, UilExclamationTriangle } from '@iconscout/react-unicons'
import { LoadingButton } from '@mui/lab'

import { ButtonComponent, ChipComponent, StatusTextComponent } from '~/components'
import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { getFormattedLabelWithDay } from '~/validations/formattLabelWithDay'
import { mapClassShift } from '~/validations/mapClassShift'
import { mapCoStage } from '~/validations/mapCoStage'

import { ModalComponentBase } from '../ModalBase/Modal'
import * as S from './ModalChangeTime..styles'
import { IModalChangeTimeProps } from './ModalChangeTime.interfaces'

export const ModalChangeTimeComponent = ({
  open,
  actionCloseModal,
  actionCofirm,
  titleLabel,
  icon,
  color,
  returnBtnText = 'Remarcar aula',
  confirmCancelText = 'Aula foi realizada',
  actionScheduleModal,
  loadingConfirm,
  statusCode,
  statusText,
  dateHour,
  lessonYear,
  classShift,
  coStage,
  txGrade,
}: IModalChangeTimeProps) => {
  const goBack = () => {
    actionCloseModal()
  }
  return (
    <ModalComponentBase open={open} actionCloseModal={actionCloseModal}>
      <S.Container>
        <S.ContainerDeep>
          <S.ContentIcon>{icon ? icon : <UilExclamationTriangle size={30} color="#fe933e" />}</S.ContentIcon>

          <S.Title variant="h1">Percebemos que você não confirmou a realização dessa aula</S.Title>
          <S.TextLabel variant="h2">
            Se você já realizou essa aula, confirme sua realização. Caso não tenha realizado, marque uma nova data.
          </S.TextLabel>
        </S.ContainerDeep>

        <S.HeaderContainer>
          <S.Wrapper>
            <StatusTextComponent statusCode={statusCode} statusText={statusText} />
            <ChipComponent icon={<UilCalender size={15} />} type="light" label={getFormattedLabelWithDay(dateHour)} />
          </S.Wrapper>
          <DSTooltip text={titleLabel as string} position="top">
            <S.TitleLesson variant="h1">
              {titleLabel && titleLabel?.length > 40 ? titleLabel?.substring(0, 40) + '...' : titleLabel}
            </S.TitleLesson>
          </DSTooltip>
          <DSTooltip text={lessonYear}>
            <S.WrapperClass>
              {mapClassShift(classShift || '')} | {mapCoStage(coStage || '')} | {txGrade} -{' '}
              {lessonYear && lessonYear.length > 17 ? lessonYear?.substring(0, 17) + '...' : lessonYear}
            </S.WrapperClass>
          </DSTooltip>
        </S.HeaderContainer>

        <S.ContentButtons>
          {loadingConfirm ? (
            <LoadingButton
              fullWidth={true}
              loading
              variant="contained"
              sx={{
                width: '47%',
                height: '50px',
                borderRadius: '25px',
              }}
            ></LoadingButton>
          ) : (
            <ButtonComponent
              fullWidth={true}
              iconEnd={<UilCheck />}
              text={confirmCancelText}
              variant="solid"
              size="large"
              color={color}
              onClick={actionCofirm}
            />
          )}

          <ButtonComponent
            variant="outline"
            size="large"
            text={returnBtnText}
            iconEnd={<UilCalender />}
            fontWeight="normal"
            fontSize="large"
            fullWidth={true}
            onClick={actionScheduleModal}
          />
          <ButtonComponent
            variant="text"
            size="large"
            text={'Cancelar'}
            fontWeight="normal"
            fontSize="large"
            fontColor="black"
            fullWidth={true}
            onClick={goBack}
          />
        </S.ContentButtons>
      </S.Container>
    </ModalComponentBase>
  )
}
