import { IContainerComponentProps } from './Container.interfaces'
import * as S from './Container.styles'

export const ContainerComponent = ({ children, ref, onScroll }: IContainerComponentProps) => {
  return (
    <S.Container ref={ref} onScroll={onScroll}>
      <S.Content>{children}</S.Content>
    </S.Container>
  )
}
