import { Backdrop, Box, Select } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const ContentList = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '181px',
  padding: '24px',
  '@media (max-width: 600px)': {
    marginTop: '220px',
    marginBottom: '50px',
  },
}))

export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    width: 'none',
    marginTop: '340px',
  },
}))

export const Loading = styledMUI(Backdrop)(() => ({}))
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 50px;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};

  span {
    font-weight: 500;
    font-size: 14px;
  }
`
export const ContainerBoxBtnCalendar = styled.div<{ active: boolean }>`
  gap: 8px;
  display: flex;
  align-items: center;
`

export const SwitchViewListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.actionLight};
  padding: 8px;
  border-radius: 50px;
  gap: 8px;
  display: flex;
  align-items: center;
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
  align-items: center;
`

export const ContentSelectSchedule = styled.div``

export const ContainerButtonsActions = styled.div`
  display: flex;
  gap: 4px;
`

export const SwitchViewButton = styled.button<{ active: boolean }>`
  border: none;
  cursor: pointer;
  border-radius: 50px;
  color: ${({ theme, active }) => (active ? theme.colors.highPure : theme.colors.actionPure)};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) => (active ? theme.colors.actionPure : 'transparent')};
`
export const ContainerSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.actionLight};
  border: 1px solid ${({ theme }) => theme.colors.actionPure};
`

export const SelectContent = styledMUI(Select)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '4px 8px',
  height: '24px',
  minWidth: '130px',
  border: 'none',
  color: theme.colors.actionPure,
  fontWeight: 700,
  fontSize: '14px',
  '&:hover': {
    border: 'none',
    cursor: 'normal',
  },
}))

export const ButtonSelected = styled.button<{ active: boolean }>`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  color: ${({ theme, active }) => (active ? theme.colors.actionPure : theme.colors.highDark)};
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.actionPure : theme.colors.highDark)};
  background-color: ${({ theme }) => theme.colors.highPure};
  cursor: pointer;
`
