export const statusCheck = (status: string) => {
  switch (status) {
    case 'AGEN':
      return 'disabled'
    case 'AGUA':
      return 'toDo'
    case 'AUPL':
      return 'toDo'
    case 'FINA':
      return 'play'
    case 'INIC':
      return 'toDo'
    case 'AVPE':
      return 'warning'
    case 'PEND':
      return 'light'
    case 'AUPE':
      return 'danger'
    case 'CANC':
      return 'cancel'
    default:
      return 'disabled'
  }
}
