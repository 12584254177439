import { gql } from 'graphql-request'

export const GetGradesService = gql`
  query getGradesService {
    grades(order: { stage: { nuOrder: ASC } }) {
      items {
        coGrade
        txGrade
        coStage
        stage {
          txName
        }
      }
    }
  }
`
