import { StudentsPageProvider } from './context'
import { StudentsView } from './view'

export const StudentsPage = () => {
  return (
    <StudentsPageProvider>
      <StudentsView />
    </StudentsPageProvider>
  )
}
