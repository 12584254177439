import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { IProjectPageContextProps, IProjectPageProviderProps } from './interfaces'

const ProjectPageContext = createContext<IProjectPageContextProps>({
  dataStudentsGroup: undefined,
  status: '',
  step: undefined,
})

const ProjectPageProvider = ({ children }: IProjectPageProviderProps) => {
  const { projectId } = useParams()

  const { getOfflineInformation } = useOfflineMode()

  const dataStudentsGroup = getOfflineInformation?.[0]?.projectMomentGroups.find(
    (group) => group.idProjectMoment === Number(projectId),
  )?.group

  const step = getOfflineInformation?.[0]?.projectMomentsDetails.find(
    (item) => item.idProjectMoment === Number(projectId),
  )?.projectMomentStage[2]

  const status =
    getOfflineInformation?.[0]?.moments.find((item) => item.idMoment === Number(projectId))?.coMomentStatus || ''

  const projectPageProviderValues = useMemo(() => {
    return { dataStudentsGroup, status, step }
  }, [dataStudentsGroup, status, step])

  return <ProjectPageContext.Provider value={projectPageProviderValues}>{children}</ProjectPageContext.Provider>
}

const useProjectPageContext = () => useContext(ProjectPageContext)

export { ProjectPageProvider, useProjectPageContext }
