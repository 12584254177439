import { NotFoundPageProvider } from '~/pages/public/NotFound/context'
import { NotFoundView } from '~/pages/public/NotFound/view'

export const NotFoundPage = () => {
  return (
    <NotFoundPageProvider>
      <NotFoundView />
    </NotFoundPageProvider>
  )
}
