import { CircularProgress } from '@mui/material'

import { AppBarComponent, ContainerComponent } from '~/components'
import { AlertAssessment } from '~/components/Forms/Assessment/Assessment.styles'

import { HeaderComponent } from '~/containers/private/Fund1/LessonAssessment'

import { useAssessmentPageContext } from './context'
import LessonForm from './Forms/LessonForm'
import ProjectForm from './Forms/ProjectForm'

export const LessonAssessmentView = () => {
  const {
    tabActived,
    handleActiveNewTab,
    mutateAssessment,
    mutateAssessmentBatch,
    isLoadingAssessment,
    stage,
    idActivity,
    fixedPayload,
    variablePayload,
    firstEvidence,
    lessonStep,
    status,
    projectId,
    isSameUser,
    students,
    isLoading,
    mutateAssessmentProject,
    mutateAssessmentBatchProject,
    isLoadingAssessmentProject,
    isLoadingAssessmentBatch,
    isLoadingAssessmentBatchProject,
    isProject,
    idLessonMoment,
    idProjectStage,
    idClass,
    isClassInStatus,
  } = useAssessmentPageContext()

  const anyLoading =
    isLoadingAssessment || isLoadingAssessmentProject || isLoadingAssessmentBatch || isLoadingAssessmentBatchProject

  return (
    <>
      {anyLoading && (
        <AlertAssessment
          iconMapping={{
            info: <CircularProgress color="inherit" size={16} />,
          }}
          severity={'info'}
        >
          Estamos processando a sua avaliação.
        </AlertAssessment>
      )}
      <AppBarComponent />
      <ContainerComponent>
        <HeaderComponent title={''} statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status as string)} />

        {!isProject ? (
          <>
            <LessonForm
              idClass={idClass}
              lessonStep={lessonStep}
              tabActived={tabActived}
              handleActiveNewTab={handleActiveNewTab}
              mutateAssessment={mutateAssessment}
              isFinished={['FINA'].includes(status as string)}
              mutateAssessmentBatch={mutateAssessmentBatch}
              status={status}
              idLessonMoment={idLessonMoment}
              firstEvidence={firstEvidence}
              isClassInStatus={isClassInStatus}
            />
          </>
        ) : (
          <>
            <ProjectForm
              idClass={idClass}
              idActivity={idActivity}
              fixedPayload={fixedPayload}
              variablePayload={variablePayload}
              isSameUser={isSameUser}
              status={status}
              isFinished={['FINA'].includes(status as string)}
              handleActiveNewTab={handleActiveNewTab}
              tabActived={tabActived}
              idProject={projectId}
              students={students}
              stage={stage}
              idProjectStage={idProjectStage}
              isLoading={isLoading}
              mutateAssessment={mutateAssessmentProject}
              mutateAssessmentBatch={mutateAssessmentBatchProject}
              isClassInStatus={isClassInStatus}
            />
          </>
        )}
      </ContainerComponent>
    </>
  )
}
