export const mapCoStage = (coStage: string): string => {
  switch (coStage) {
    case 'IN':
      return 'Infantil'
    case 'F1':
      return 'Fundamental 1'
    case 'F2':
      return 'Fundamental 2'
    case 'EM':
      return 'Ensino médio'
    default:
      return coStage
  }
}
