import { UilTimes } from '@iconscout/react-unicons'

import { AccordionTextComponent, CarouselComponent, ModalComponent } from '~/components'

import { MediaProps } from '../../../ui/MediaInformation/MediaInformation'
import * as S from '../../../ui/MediaInformation/MediaInformation.styles'

interface IModalMediaList {
  modalVisible: boolean
  mediaImageAndVideoList: MediaProps[]
  handleImageClick: (imageSrc: string) => void
  exitFullscreen: () => void
  txMomentNotes?: string | undefined
}

export const ModalMediaList = ({
  modalVisible,
  mediaImageAndVideoList,
  handleImageClick,
  exitFullscreen,
  txMomentNotes,
}: IModalMediaList) => {
  return (
    <ModalComponent
      open={modalVisible}
      actionCloseModal={exitFullscreen}
      height={'725px'}
      width={'initial'}
      padding={'20px'}
    >
      <S.BoxModal>
        <S.BoxEnd>
          <S.ButtonLeaveFull onClick={exitFullscreen}>
            <UilTimes size={22} />
          </S.ButtonLeaveFull>
        </S.BoxEnd>

        {mediaImageAndVideoList.length > 0 && (
          <CarouselComponent
            length={mediaImageAndVideoList.length}
            fullScreen
            mediaImageAndVideoList={mediaImageAndVideoList}
          >
            {mediaImageAndVideoList.map((media) => (
              <>
                {media?.mediaType?.includes('video') ? (
                  <S.ContainerVideoModal key={media.srcMedia}>
                    <S.ModalVideo
                      controls
                      controlsList="nodownload noplaybackrate"
                      src={media.srcMedia}
                      key={media.srcMedia}
                    />
                  </S.ContainerVideoModal>
                ) : (
                  <>
                    <S.ModalImage src={media?.srcMedia} key={media.srcMedia}></S.ModalImage>
                  </>
                )}
                <S.BoxText>
                  <AccordionTextComponent paragraph={txMomentNotes || '...'} limit={195} />
                </S.BoxText>
              </>
            ))}
          </CarouselComponent>
        )}
      </S.BoxModal>
    </ModalComponent>
  )
}
