import { HomePageProvider } from './context'
import { HomeView } from './view'

export const HomePage = () => {
  return (
    <HomePageProvider key={'homePageProvider'}>
      <HomeView key={'homeView'} />
    </HomePageProvider>
  )
}
