import { Typography } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 24px;
`
export const ContainerEvidence = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};

  @media (max-width: 600px) {
    display: grid;
  }

  .step-1 {
    color: ${({ theme }) => theme.colors.primaryPure};
  }

  .step-2 {
    color: ${({ theme }) => theme.colors.quaternaryPure};
  }

  .step-3 {
    color: ${({ theme }) => theme.colors.secondaryPure};
  }
`

export const TitleEvidence = styled(Typography)`
  color: ${({ theme }) => theme.colors.quaternaryPure};
  font-size: 16px;
  font-weight: 700 !important;

  span {
    font-weight: 500;
  }
`

export const Title = styled(Typography)`
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  margin-bottom: 8px;

  span {
    color: ${({ theme }) => theme.colors.quaternaryPure};
  }
`
