import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '50px',
  '@media (max-width: 768px)': {
    marginTop: '100px',
  },
}))

export const ContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  borderBottom: '1px solid #E2E5EC',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const WrapperStudents = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const TitleLight = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#7C8189',
}))

export const TitleBold = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#E66251',
}))

export const TextLabel = styledMUI(Typography)<{ color: string }>(({ color }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: color,
}))

export const ContentFixedSkill = styledMUI(Box)(() => ({
  padding: '24px',
  borderBottom: '1px solid #E2E5EC',
}))

export const ContentTabs = styledMUI(Box)(() => ({
  padding: '24px',
  borderBottom: '1px solid #E2E5EC',
}))
