import { useEffect, useState } from 'react'

import { UilArrowLeft, UilArrowRight, UilSearch } from '@iconscout/react-unicons'
import { Box, FormControl, InputAdornment, MenuItem, TextField, useTheme } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { IStudentsProjectList, StudentsGroupLessonData } from '~/services/Students/types'

import { AvatarComponent } from '~/components'

import * as S from './MultiSelectStudent.styles'

export const getStyles = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    fontSize: '12px',
    lineHeight: '20px',
  }
}

interface IMultiSelectStudentProps {
  handleSelectStudent: (idUserStudent: string) => void
  handleClearSelectStudent: () => void
  selectedStudents: string[]
  id?: string
  type?: 'project' | 'lesson'
  students: StudentsGroupLessonData | IStudentsProjectList
}

interface ISelectStudent {
  idUserStudent: string
  txName: string
  txImagePath: string
}

export const MultiSelectStudentComponent = ({
  handleSelectStudent,
  handleClearSelectStudent,
  selectedStudents,
  students,
}: IMultiSelectStudentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 600

  const theme = useTheme()

  const [studentsFiltered, setStudentsFiltered] = useState<ISelectStudent[]>()
  const [originalStudentsList, setOriginalStudentsList] = useState<ISelectStudent[]>()

  useEffect(() => {
    const studentsAttendance = students?.group?.filter((student) => student.inAttendance === true)
    const studentsMap = new Map()

    const studentsAttendanceFiltered = studentsAttendance
      ?.map((item) => item.studentClass.student)
      .filter((student) => {
        return !studentsMap.has(student.idUserStudent) && studentsMap.set(student.idUserStudent, student)
      })

    setStudentsFiltered([
      {
        idUserStudent: 'allstudents',
        txName: 'Todos Alunos',
        txImagePath: '',
      },
      ...studentsAttendanceFiltered,
    ])
    setOriginalStudentsList([
      {
        idUserStudent: 'allstudents',
        txName: 'Todos Alunos',
        txImagePath: '',
      },
      ...studentsAttendanceFiltered,
    ])
  }, [students])
  const pageSize = 5
  const [currentPage, setCurrentPage] = useState(1)

  const startIndex = (currentPage - 1) * pageSize
  const endIndex = startIndex + pageSize
  const pageStudents = studentsFiltered
    ? studentsFiltered
        .slice(startIndex, endIndex)
        .filter((student, index, self) => index === self.findIndex((t) => t.idUserStudent === student.idUserStudent))
    : []

  const totalPages = Math.ceil(studentsFiltered?.length / pageSize)

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const isPreviousDisabled = currentPage === 1
  const isNextDisabled = currentPage === totalPages

  const handleInputFilter = (e) => {
    const searchTerm = e.target.value.toLowerCase()

    if (!searchTerm) {
      setStudentsFiltered([...originalStudentsList])
    } else {
      const filtered = originalStudentsList?.filter((student) => student.txName.toLowerCase().includes(searchTerm))
      setStudentsFiltered(filtered)
    }
  }

  const transformFullName = (fullName: string): string => {
    const firstName = fullName?.split(' ')[0]
    const capitalizedFirstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1).toLowerCase()
    return capitalizedFirstName
  }

  return (
    <S.Container>
      <S.WrapperInputFilter>
        <S.WrapperLabel>
          <S.Label>Selecione alunos</S.Label>
          <S.LabelOption>(opcional)</S.LabelOption>
        </S.WrapperLabel>
        <TextField
          placeholder="Comece a digitar o nome de um aluno"
          size="small"
          sx={{ width: isMobile ? '250px' : '340px' }}
          onChange={(event) => handleInputFilter(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {' '}
                <UilSearch />
              </InputAdornment>
            ),
          }}
        />
      </S.WrapperInputFilter>
      <S.WrapperStudentList>
        <S.PutStart>
          <S.Arrow
            onClick={() => !isPreviousDisabled && handlePageChange(currentPage - 1)}
            disabled={isPreviousDisabled}
          >
            <UilArrowLeft size="20" />
          </S.Arrow>
        </S.PutStart>
        <FormControl sx={{ m: 0, width: '100%', height: 100, display: 'flex' }}>
          <Box
            sx={{
              fontWeight: theme.typography.fontWeightRegular,
              display: 'flex',
              overflowY: 'auto',
              padding: '0',
              height: '90px',
              overflow: 'hidden',
            }}
          >
            {pageStudents?.map((student: any) => (
              <MenuItem
                sx={{ padding: '4px', borderRadius: '8px' }}
                key={student.idUserStudent}
                value={student.idUserStudent}
                style={getStyles(theme)}
                onClick={() => handleSelectStudent(student.idUserStudent)}
              >
                <S.CardStudent selected={selectedStudents.includes(student.idUserStudent)}>
                  <AvatarComponent size={'mediumPlus'} photoUrl={student.txImagePath} label={student.txName} />
                  {transformFullName(student?.txName.split(/(\s).+\s/).join(''))}
                </S.CardStudent>
              </MenuItem>
            ))}
          </Box>
        </FormControl>
        <S.PutEnd>
          <S.Arrow onClick={() => !isNextDisabled && handlePageChange(currentPage + 1)} disabled={isNextDisabled}>
            <UilArrowRight size="20" />
          </S.Arrow>
        </S.PutEnd>
      </S.WrapperStudentList>
    </S.Container>
  )
}
