import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '240px 24px 24px 24px',
  '@media (max-width: 600px)': {
    padding: '24px',
    marginTop: '0',
  },
}))
export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
}))

export const DateText = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#000',
}))
