import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { gaEvents } from '~/events'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useAvailableTrail, useExploreData, useProjectDetailTrails } from '~/services/Trails'

import { GET_CLASS_INFOS_BY_CLASSID } from '../ListTrailsProject/schemas.queries'
import { ITrailDetailsProjectContextProps, ITrailDetailsProjectPageProviderProps, LessonTrack } from './interfaces'

const TrailDetailsProjectContext = createContext<ITrailDetailsProjectContextProps>({
  openModalBNCC: false,
  handleOpenModalBNCC: () => Object,
  handleCloseModalBNCC: () => Object,
  openModalSucess: false,
  handleOpenModalSucess: () => Object,
  handleCloseModalSucess: () => Object,
  openModalSchedule: false,
  handleOpenModalSchedule: () => Object,
  handleCloseModalSchedule: () => Object,
  isLoading: false,
  data: undefined,
  dataClassInfos: undefined,
  setLesson: () => Object,
  lesson: undefined,
  dataProject: undefined,
  isLoadingProject: false,
  setExplore: () => Object,
  exploreData: undefined,
  setOpenDetail: () => Object,
  openDetail: false,
})

const TrailDetailsProjectPageProvider = ({ children }: ITrailDetailsProjectPageProviderProps) => {
  const { trailId, classId } = useParams()
  const navigate = useNavigate()
  const [openModalBNCC, setOpenModalBNCC] = useState<boolean>(false)
  const [openModalSchedule, setOpenModalSchedule] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)

  const [openModalSchedulesEM, setOpenModalSchedulesEM] = useState<boolean>(false)
  const [lesson, setLesson] = useState<LessonTrack | null>(null)
  const [openDetail, setOpenDetail] = useState(false)
  const [explore, setExplore] = useState({
    project: null,
    nuOrder: null,
  })

  const handleCloseModalSchedulesEM = useCallback(() => {
    setOpenModalSchedulesEM(false)
  }, [])

  const handleOpenModalSchedulesEM = useCallback(() => {
    setOpenModalSchedulesEM(true)
  }, [])

  const { isLoading, data } = useAvailableTrail(Number(classId), Number(trailId), true)

  const handleOpenModalBNCC = useCallback(() => {
    setOpenModalBNCC(true)
    gaEvents.eventBnccButton()
  }, [])

  const handleCloseModalBNCC = useCallback(() => {
    setOpenModalBNCC(false)
  }, [])

  const handleOpenModalSchedule = useCallback(() => {
    if (classId && trailId) {
      navigate(`/schedule/${classId}/track/${trailId}`)
      gaEvents.eventSelectNewTrailButton()
    }
  }, [classId, trailId, navigate])

  const handleCloseModalSchedule = useCallback(() => {
    setOpenModalSchedule(false)
  }, [])

  const handleOpenModalSucess = useCallback(() => {
    setOpenModalSucess(true)
  }, [])

  const handleCloseModalSucess = useCallback(() => {
    setOpenModalSucess(false)
  }, [])

  const { data: dataClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(classId),
      showDisabledClasses: true,
    },
  )

  const {
    isLoading: isLoadingProject,
    data: dataProject,
    refetch,
  } = useProjectDetailTrails(
    Number(lesson?.idProjectTrack),
    Number(lesson?.nuOrder),
    !!lesson?.idProjectTrack && !!lesson,
  )

  const {
    isLoading: isLoadingExplore,
    data: exploreData,
    refetch: refetchExplore,
  } = useExploreData(
    Number(explore?.project?.idProject),
    Number(lesson?.nuOrder),
    !!explore?.project?.idProject && !!lesson,
  )

  useEffect(() => {
    if (lesson) {
      refetch().then()
    }
  }, [lesson, refetch])

  useEffect(() => {
    if (explore?.nuOrder) {
      refetchExplore().then()
    }
  }, [explore, refetchExplore])

  const trailDetailsProjectPageProviderValues = useMemo(() => {
    return {
      openModalBNCC,
      handleOpenModalBNCC,
      handleCloseModalBNCC,
      openModalSucess,
      handleOpenModalSucess,
      handleCloseModalSucess,
      openModalSchedule,
      handleOpenModalSchedule,
      handleCloseModalSchedule,
      isLoading,
      data,
      openModalSchedulesEM,
      handleOpenModalSchedulesEM,
      handleCloseModalSchedulesEM,
      dataClassInfos,
      setLesson,
      lesson,
      dataProject,
      isLoadingProject,
      setExplore,
      exploreData,
      isLoadingExplore,
      setOpenDetail,
      openDetail,
    }
  }, [
    openModalBNCC,
    handleOpenModalBNCC,
    handleCloseModalBNCC,
    openModalSucess,
    handleOpenModalSucess,
    handleCloseModalSucess,
    openModalSchedule,
    handleOpenModalSchedule,
    handleCloseModalSchedule,
    isLoading,
    data,
    openModalSchedulesEM,
    handleOpenModalSchedulesEM,
    handleCloseModalSchedulesEM,
    dataClassInfos,
    setLesson,
    lesson,
    dataProject,
    isLoadingProject,
    setExplore,
    exploreData,
    isLoadingExplore,
    setOpenDetail,
    openDetail,
  ])

  return (
    <TrailDetailsProjectContext.Provider value={trailDetailsProjectPageProviderValues}>
      {children}
    </TrailDetailsProjectContext.Provider>
  )
}

const useListTrailDetailProjectPageContext = () => useContext(TrailDetailsProjectContext)

export { TrailDetailsProjectPageProvider, useListTrailDetailProjectPageContext }
