import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 8.188rem;
  max-width: 100vw;
  @media (max-width: 450px) {
    margin-top: 260px;
  }
`

export const VariableEvidencesContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e2e5ec;
`
export const FixedEvidencesContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e2e5ec;
`
