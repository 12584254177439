import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { ISecondStepPageContextProps, ISecondStepPageProviderProps } from './interfaces'

const SecondStepPageContext = createContext<ISecondStepPageContextProps>({
  activities: undefined,
  activedTab: 0,
  handleActiveNewTab: () => Object,
  lessonSteps: undefined,
  filteredStepTwoGroups: undefined,
})

const SecondStepPageProvider = ({ children }: ISecondStepPageProviderProps) => {
  const { idLessonMoment, idLesson } = useParams()

  const { getOfflineInformation } = useOfflineMode()

  const [activedTab, setActivedTab] = useState<number>(0)

  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const lessonSteps = getOfflineInformation?.[0].lessonSteps

  const filteredActivities = lessonSteps?.[1]?.lessonActivity.filter((item) =>
    lessonSteps?.[1]?.lessonActivity
      .map((itemStudents) => itemStudents.idLessonActivity)
      .includes(item.idLessonActivity),
  )

  const filteredStepTwoGroups = getOfflineInformation?.[0]?.lessonStepTwoGroups.find(
    (item) => item.idLessonMoment === Number(idLessonMoment) && item.idLesson === Number(idLesson),
  )

  const secondStepPageProviderValues = useMemo(() => {
    return {
      activities: filteredActivities,
      handleActiveNewTab,
      activedTab,
      lessonSteps,
      filteredStepTwoGroups,
    }
  }, [filteredStepTwoGroups, lessonSteps, activedTab, handleActiveNewTab, filteredActivities])

  return (
    <SecondStepPageContext.Provider value={secondStepPageProviderValues}>{children}</SecondStepPageContext.Provider>
  )
}

const useSecondStepPageContext = () => useContext(SecondStepPageContext)

export { SecondStepPageProvider, useSecondStepPageContext }
