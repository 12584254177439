import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { IPageProps } from '~/components/Navigation/AppBar/AppBar.interfaces'

import { IAppBarContextProps, IAppBarProviderProps } from './AppBar.interfaces'

const AppBarContext = createContext<IAppBarContextProps>({
  tabActived: 1,
  handleActiveNewTab: () => Object,
})

const AppBarProvider = ({ children }: IAppBarProviderProps) => {
  const [tabActived, setTabActived] = useState<number>(1)
  const [isOfflineMode, setIsOfflineMode] = useState<boolean>(false)
  const modifyPath = useMemo(() => {
    return (path: string) => {
      return isOfflineMode ? `/offline${path}` : path
    }
    // eslint-disable-next-line
  }, [isOfflineMode])

  const pages: IPageProps[] = useMemo(
    () => [
      { id: 1, icon: 'UilEstate', label: 'Início', path: modifyPath('/home') },
      { id: 2, icon: 'UilRocket', label: 'Trilhas', path: '/classes' },
      { id: 3, icon: 'UilCalender', label: 'Agenda', path: '/schedule/week' },
      { id: 4, icon: 'UilUsersAlt', label: 'Painel', path: '/students' },
    ],
    [modifyPath],
  )

  const handleActiveNewTab = useCallback((newValue: number) => {
    localStorage.removeItem('@LEKTO:Navigation')
    localStorage.setItem('@LEKTO:tabActived', String(newValue))
    setTabActived(newValue)
  }, [])

  useEffect(() => {
    const storageTabActived = localStorage.getItem('@LEKTO:tabActived')
    const currentPath = window.location.pathname
    const matchedPage = pages?.find((page) => page.path === currentPath)

    if (storageTabActived && !matchedPage) {
      handleActiveNewTab(Number(storageTabActived))
    } else if (matchedPage) {
      handleActiveNewTab(matchedPage.id)
    } else {
      handleActiveNewTab(1)
    }
    // eslint-disable-next-line
  }, [handleActiveNewTab])

  const appBarProviderValues = useMemo(() => ({ tabActived, handleActiveNewTab }), [tabActived, handleActiveNewTab])

  return <AppBarContext.Provider value={appBarProviderValues}>{children}</AppBarContext.Provider>
}

const useAppBar = () => useContext(AppBarContext)

export { AppBarProvider, useAppBar }
