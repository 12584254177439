import { ITextReductionComponentComponentProps } from './TextReduction.interfaces'
import * as S from './TextReduction.styles'

export const TextReductionComponent = ({ text, maxLength }: ITextReductionComponentComponentProps) => {
  const truncatedText = text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text
  return (
    <div>
      {text?.length > maxLength ? (
        <S.Paragraph>
          <S.TooltipLabel
            title={text}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#000',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                },
              },
            }}
          >
            <span>{truncatedText}</span>
          </S.TooltipLabel>
        </S.Paragraph>
      ) : (
        <S.Paragraph>{text}</S.Paragraph>
      )}
    </div>
  )
}
