import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Container = styledMUI(Box)(() => ({
  minHeight: '250px',
  minWidth: '350px',
  maxWidth: '552px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px',
  gap: '24px',
}))

export const IconBox = styledMUI(Box)(() => ({
  backgroundColor: '#e8f6ff',
  padding: '8px',
  width: '50px',
  borderRadius: '50%',
  alignItems: 'center',
}))

export const Icon = styledMUI(Box)(() => ({
  color: '#0095FF',
  alignItems: 'center',
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const Topic = styledMUI(Typography)(() => ({
  fontWeight: '750',
  fontSize: '20px',
  lineHeight: '150%',
  color: '#000',
}))

export const Content = styledMUI(Box)(() => ({
  overflowY: 'auto',
  maxHeight: '100vh',
  borderRadius: '8px',
}))

export const FinalButton = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignContent: 'flex-end',
  gap: '8px',
}))
