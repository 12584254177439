import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

export const BorderLinearProgress = styledMUI(LinearProgress)<{ competenceColor: string; backgroundColor?: string }>(
  ({ competenceColor, backgroundColor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor ? backgroundColor : 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: competenceColor ? `#${competenceColor}` : '#308fe8',
    },
  }),
)

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 10px;
  display: table;
`

export const TableHeader = styled.thead<{ type: 'card' | 'default' }>`
  text-align: right;
  font-size: 11px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.midDark};

  th:not(:first-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.midMedium};
    width: 180px;
    padding: 0 10px;
    vertical-align: sub;
  }
`

export const Container = styled.div`
  position: relative;
  display: contents;
  width: 100%;
`
export const Tooltip = styled.div`
  heigth: 100px;
  padding: 16px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 6px 7px 10px 0 rgba(0, 0, 0, 0.3);
  display: grid;
  position: absolute;
  gap: 8px;
  z-index: 99999999;
  left: 50%;
`

export const TooltipMonth = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.midDark};
`

export const TooltipTotalClasses = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lowPure};
`

export const TooltipCompetence = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.lowPure};
`
export const ClassesText = styled.p`
  font-size: 12px;
  font-weight: 00;
  color: #7c8189;
`
export const CompetenceText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
`

export const StatusTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.midMedium};
  color: ${({ theme }) => theme.colors.midDark};
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  max-width: 120px;
`

export const BoxTooltip = styled.div`
  padding: 4px 6px 4px 6px;
  display: grid;
  background-color: #e7effc;
  border-radius: 8px;
  color: red;
  font-size: 16px;
  font-weight: 500;
`

export const RowTooltip = styled.div`
  display: flex;
  max-width: fit-content;
  font-weight: 500;
`

export const TableBody = styled.tbody<{ type: 'card' | 'default' }>`
  .skill {
    text-align: right;
    font-size: 14px;
    align-items: center;
    padding: 0 10px;
    width: ${({ type }) => (type === 'card' ? '130px' : '180px')};
    min-width: ${({ type }) => (type === 'card' ? '130px' : '180px')};
    max-width: ${({ type }) => (type === 'card' ? '130px' : '180px')};

    color: ${({ theme }) => theme.colors.lowPure};

    ${({ type }) =>
      type === 'card' &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
  }

  td:not(.skill, .bar) {
    border-right: 1px solid ${({ theme }) => theme.colors.midMedium};
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    opacity: 1;
  }
`

export const Tr = styled.tr<{ type: 'default' | 'card' }>`
  position: relative;
  cursor: pointer;

  height: 40px;

  .bar {
    position: absolute;
    left: ${({ type }) => (type === 'card' ? '130px' : '180px')};
    width: 720px;
    top: 16px;

    span {
      min-width: 10px;
    }
  }

  .barEmpty {
    position: absolute;
    border: none !important;
    left: ${({ type }) => (type === 'card' ? '130px' : '180px')};
    top: 14px;
    width: 455px;
    display: flex;
    align-items: center;

    p {
      margin-left: 4px;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.midDark};
    }

    span {
      min-width: 10px;

      span {
        transform: none !important;
        min-width: 10px;
      }
    }
  }
`

export const MobileContainer = styled.div`
  display: grid;
  gap: 8px;
`

export const MobileItem = styled.div`
  display: grid;
  gap: 8px;

  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};
`

export const MobileItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MobileTitle = styled.h3`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lowPure};
  font-weight: 400;
`
