import { CustomDndProvider } from '~/components'

import { AssessmentPageProvider } from './context'
import { LessonAssessmentView } from './view'

export const AssessmentPage = () => {
  return (
    <AssessmentPageProvider>
      <CustomDndProvider>
        <LessonAssessmentView />
      </CustomDndProvider>
    </AssessmentPageProvider>
  )
}
