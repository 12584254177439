import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styledMUI(Box)(() => ({
  minWidth: '350px',
  maxWidth: '552px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px',
  gap: '24px',
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const Content = styledMUI(Box)(() => ({
  display: 'grid',
  gap: '8px',
}))

export const DownloadButton = styled.button<{ variant: 'default' | 'disabled' }>`
  border: none;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  min-width: 502px;
  max-width: 510px;
  border-radius: 8px;

  background-color: ${({ theme, variant }) => {
    if (variant === 'disabled') return theme.colors.highLight
    return theme.colors.actionLight
  }};

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  span {
    color: ${({ theme, variant }) => {
      if (variant === 'disabled') return theme.colors.lowLight
      return theme.colors.actionPure
    }};
    font-size: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 280px;
  }
`

export const DownloadedText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lowLight};
`

export const ContainerIcon = styled.div<{ variant: 'disabled' | 'default' }>`
  padding: 4px;
  border-radius: 50px;
  background-color: ${({ theme, variant }) => {
    if (variant === 'disabled') return theme.colors.highMedium
    return theme.colors.actionMedium
  }};
`

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: '1px solid #0095ff',
  backgroundColor: '#fff',
  padding: 0,
}))
