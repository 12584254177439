import { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { UilUserTimes } from '@iconscout/react-unicons'
import { tooltipClasses } from '@mui/material/Tooltip'
import dayjs from 'dayjs'

import { ITrailStatusBarProps } from '~/components/Bars/TrailStatusBar/TrailStatusBar.interfaces'

import isMobile from '~/validations/isMobile'
import { statusColor } from '~/validations/statusColorsEM'
import { statusIcon } from '~/validations/statusIcon'

import theme from '~/styles/theme'

import * as S from './TrailStatusBar.styles'

export const TrailStatusBar = ({ moments, momentActive }: ITrailStatusBarProps) => {
  const finishedStatus = ['CANC', 'FINA']
  let isPreviousMomentFinished = false
  const navigate = useNavigate()
  const location = useLocation()
  const { classId } = useParams()
  const handleSchedule = useCallback(
    (trailId: number) => {
      if (classId && trailId) {
        sessionStorage.setItem('previousPath', location.pathname)
        navigate(`/schedule/${classId}/track/${trailId}`)
      }
    },
    [classId, location.pathname, navigate],
  )

  const renderOccupyStepTooltip = (trailId: number) => (
    <S.occupyStepButton
      onClick={(e) => {
        e.stopPropagation(), handleSchedule(trailId)
      }}
    >
      <UilUserTimes size={16} />
      <span>Ocupar Etapa</span>
    </S.occupyStepButton>
  )

  return (
    <S.StatusBarContainer>
      {moments &&
        moments.map((moment, index) => {
          if (!moment.professor && !moment.lessonTrackGroup) {
            return (
              <>
                <S.StatusBarWithoutProfessorTooltip
                  arrow
                  placement={'top'}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: theme.colors.lowPure,
                        backgroundColor: theme.colors.highPure,
                        borderRadius: '4px',
                        boxShadow: theme.shadows.level3,
                        padding: '4px 8px',
                        [`& .${tooltipClasses.arrow}`]: {
                          color: theme.colors.highPure,
                        },
                      },
                    },
                  }}
                  title={renderOccupyStepTooltip(moment.projectTrackStage.idProjectTrack)}
                >
                  <S.StatusInfo isProfessorExistent={false}>
                    <span>{<UilUserTimes size={16} color={theme.colors.warningPure} />}</span>
                  </S.StatusInfo>
                </S.StatusBarWithoutProfessorTooltip>
              </>
            )
          } else if (
            finishedStatus.includes(moment.coMomentStatus) ||
            (dayjs(moment.dtSchedule).isBefore(dayjs(), 'day') && momentActive !== moment.nuTrackOrder)
          ) {
            isPreviousMomentFinished = true
            return (
              <S.StatusBarTooltip
                title={`${moment.txMomentStatus} | ${dayjs(moment.dtSchedule).format('DD/MM')}`}
                key={moment.coMomentStatus + index}
              >
                <S.StatusInfo>
                  <span>{statusIcon(moment.coMomentStatus, 16)}</span>
                </S.StatusInfo>
              </S.StatusBarTooltip>
            )
          } else if (momentActive === moment.nuTrackOrder || isPreviousMomentFinished) {
            isPreviousMomentFinished = false
            return (
              <S.StatusInfo
                key={moment.nuTrackOrder + index}
                isActive
                statusColor={statusColor(moment.coMomentStatus).indexColor}
              >
                {isMobile() ? (
                  <S.StatusBarTooltip title={`${moment.txMomentStatus} | ${dayjs(moment.dtSchedule).format('DD/MM')}`}>
                    <span>{statusIcon(moment.coMomentStatus, 16, theme.colors.highPure)}</span>
                  </S.StatusBarTooltip>
                ) : (
                  <>
                    <strong>Aula {moment.nuTrackOrder}</strong>
                    <span>{moment.txMomentStatus}</span>
                    <span>{dayjs(moment.dtSchedule).format('DD/MM')}</span>
                  </>
                )}
              </S.StatusInfo>
            )
          } else {
            return (
              <S.StatusBarTooltip
                title={`${moment.txMomentStatus} | ${dayjs(moment.dtSchedule).format('DD/MM')}`}
                key={moment.dtSchedule + index}
              >
                <S.StatusInfo>
                  <span>{moment.nuTrackOrder}</span>
                </S.StatusInfo>
              </S.StatusBarTooltip>
            )
          }
        })}
    </S.StatusBarContainer>
  )
}
