import styled from 'styled-components'

import theme from '~/styles/theme'

import { ButtonClose } from './../../Modals/PedagogicalPractices/PedagogicalPractices.styles'

interface IButton {
  enabled: boolean
}

export const ContentLoad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 224px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.highMedium};
  border-radius: 8px;
`

export const LinearProgressContainer = styled.div`
  width: 50.9%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SpanLoad = styled.span`
  display: flex;
  margin-left: 35%;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const Image = styled.div<{ src: string }>`
  width: 329.5px;
  height: 200px;
  background-image: url(${({ src }) => `${src}`});
  background-size: cover;
  border-radius: 8px;
`

export const ButtonCloseModal = styled(ButtonClose)`
  color: ${({ theme }) => theme.colors.lowPure};
  z-index: 999999;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
`

export const ModalImage = styled.img`
  width: 859px;
  height: 504px;
  max-height: 504px;
  object-fit: contain;
  background-color: ${({ theme }) => theme.colors.midMedium};
  border-radius: 8px;
  display: flex;
  margin-top: 0px;
  @media (max-width: 600px) {
    width: 328px;
    height: 197px;
  }
`

export const ModalVideo = styled.video`
  width: 859px;
  height: 573px;
  object-fit: contain;
  background-color: ${({ theme }) => theme.colors.midMedium};
  border-radius: 8px;
  display: flex;
  margin-top: 0px;
`

export const Images = styled.div`
  display: flex;
`
export const ContainerVideo = styled.div`
  width: 329.5px;
  height: 200px;
  background-color: ${({ theme }) => theme.colors.lowLight};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`
export const ContainerVideoModal = styled.div`
  width: 859px;
  height: 573px;
  background-color: ${({ theme }) => theme.colors.lowLight};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`

export const BoxText = styled.div`
  width: 859px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`
export const BoxEnd = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

export const BoxModal = styled.div`
  width: 100%;
  display: grid;
  justify-content: end;
`

export const Video = styled.video`
  display: flex;
  width: 100%;
  height: 100%;
`

export const ContainerAudio = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ContainerDocument = styled.div`
  width: 100%;
`

export const Document = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  border-radius: 8px;
  background: var(--Feedback-Color-Accent-Light, #e8f6ff);

  cursor: pointer;
`

export const DocumentInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors.actionPure};

  /* Text/Regular/Heading/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`

export const DocumentViewer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 4px;

  border-radius: 50px;
  background: ${({ theme }) => theme.colors.actionMedium};

  color: ${({ theme }) => theme.colors.actionDark};
`

export const DocumentText = styled.span``

export const ModalCarouselMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const txDocumentation = styled.text`
  width: 859px;
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 400;
  color: ${theme.colors.lowPure};
  gap: 8px;

  @media (max-width: 600px) {
    gap: 0px;
    padding: 8px 16px 8px 16px;
    width: 328px;
  }
`

export const ThumbnailImage = styled.img<{ selected: boolean }>`
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  border: ${({ selected }) => (selected ? `3px solid ${theme.colors.actionPure}` : 'none')};
  border-radius: 4px;
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`

export const ContainerButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 0px;
  cursor: pointer;
  z-index: 2147483650;
  bottom: 110px;
  position: absolute;
  right: 32%;
`

export const ButtonCloseMobileModal = styled(ButtonClose)`
  color: ${({ theme }) => theme.colors.highPure};
  z-index: 999999;
  width: 48px;
  height: 48px;
  font-size: 18px;
  background-color: ${({ theme }) => theme.colors.actionPure};
`
export const Button = styled.button<IButton>`
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'normal')};
  background-color: ${({ theme }) => theme.colors.highPure};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.actionPure};
  padding: 0;
`
export const ButtonLeave = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ theme }) => theme.colors.actionPure};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 48px;
  height: 48px;
  padding: 0;
`

export const ButtonLeaveFull = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: #e2e5ec;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 28px;
  height: 28px;
  padding: 0;
`
