import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { IApiError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

export interface IServiceDesengageTrail {
  classId: number
  lessonId: number
}

export const DesengageTrail = async (prop: IServiceDesengageTrail) => {
  const { data } = await HTTPClient.delete(`/moment/class/${prop.classId}/disengage-track/${prop.lessonId}`)

  return data
}

export const useServiceDesengageTrail = (options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>) =>
  useMutation<unknown, IApiError, IServiceDesengageTrail, unknown>(['ServiceDesengageTrail'], DesengageTrail, options)

export const DesengageTrailProject = async (prop: IServiceDesengageTrail) => {
  const { data } = await HTTPClient.delete(`/moment/class/${prop.classId}/disengage-track/${prop.lessonId}`)

  return data
}
export const useServiceDesengageTrailProject = (
  options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>,
) => useMutation<any, IApiError, any>(['ServiceDesengageTrailProject'], DesengageTrailProject, options)
