import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import parse from 'html-react-parser'

import { useOfflineMode } from '~/_context/Offline'

import { IBNCCRelationsContextProps, IBNCCRelationsPageProviderProps } from './interfaces'

const LessonBNCCRelationsPageContext = createContext<IBNCCRelationsContextProps>({
  isLoading: false,
  BNCCText: '',
  status: '',
})

const LessonBNCCRelationsPageProvider = ({ children }: IBNCCRelationsPageProviderProps) => {
  const { getOfflineInformation } = useOfflineMode()
  const { idLessonMoment } = useParams()
  const lessonBncc = getOfflineInformation?.[0]?.lessonMomentsBncc.find(
    (moment) => moment.idLessonMoment === Number(idLessonMoment),
  )
  const BNCCText = parse(String(lessonBncc?.lessonTrackGroup?.lessonTrack?.grade?.lesson?.[0]?.txGuidanceBncc))

  const lessonBNCCRelationsPageProviderValues = useMemo(() => {
    return { BNCCText }
  }, [BNCCText])

  return (
    <LessonBNCCRelationsPageContext.Provider value={lessonBNCCRelationsPageProviderValues}>
      {children}
    </LessonBNCCRelationsPageContext.Provider>
  )
}

const useLessonBNCCRelationsPageContext = () => useContext(LessonBNCCRelationsPageContext)

export { LessonBNCCRelationsPageProvider, useLessonBNCCRelationsPageContext }
