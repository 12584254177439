import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContainerHome = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
}))

export const ContentHome = styledMUI(Box)(() => ({
  height: 'auto',
  width: '923px',
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 900px)': {
    width: '100%',
  },
}))

export const CardsContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  borderRadius: '16px',
  padding: '24px',
  gap: '16px',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#000',
}))

export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
}))
