import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '240px 24px 24px 24px',
  '@media (max-width: 600px)': {
    padding: '24px',
    marginTop: '0',
  },
}))
export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
}))

export const DateText = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#000',
}))

export const Container = styled.div`
  width: 100%;
  height: auto;
`

export const ButtonSeeMore = styled.button`
  background-color: #f9fafc;
  border-radius: 4px;
  border: none;
  color: #666666;
  padding: 4px;
  cursor: pointer;
`

export const Content = styled.div`
  background: #ffffff;
  padding: 120px 0 24px 24px;
  min-height: 70vh;
`

export const ScheduleTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 12px;
`

export const ScheduleTable = styled.table`
  width: 100%;
  table-layout: fixed; /* Use a fixed layout for equal column widths */
  border-collapse: collapse;
  min-height: 75vh;

  thead {
    .active-day {
      color: ${({ theme }) => theme.colors.primaryPure};
      font-weight: 700;
    }

    th {
      border-right: 1px solid #e2e5ec;
      text-align: left;
      font-size: 16px;
      color: #000;
      font-weight: 400;
    }

    th:last-child {
      border-right: none;
    }
  }

  td {
    border-right: 1px solid #e2e5ec;
  }

  td {
    vertical-align: top;
  }

  th {
    white-space: nowrap;
    text-align: center;
    padding: 8px;
  }

  tbody {
    tr {
      min-height: 152px;
      height: 152px;
    }

    tr:first-child {
      td {
        border-top: none;
      }
    }

    td {
      min-height: 152px;
      height: 152px;
      padding: 12px;
      width: 100%;
      border-top: 1px solid #e2e5ec;

      .day {
        text-align: end;
        display: block;
        margin-bottom: 8px;
      }

      .empty {
        color: #666666;
      }

      .active-day {
        color: #fff;
        background-color: ${({ theme }) => theme.colors.primaryPure};
        font-weight: 700;
        padding: 5px;
        border-radius: 50%;
      }
    }
  }

  thead {
    th:first-child {
      padding: 0;
    }
  }
`
