import { ScheduleByMonthPageProvider } from './context'
import { ScheduleByMonthView } from './view'

export const ScheduleByMonthPage = () => {
  return (
    <ScheduleByMonthPageProvider>
      <ScheduleByMonthView />
    </ScheduleByMonthPageProvider>
  )
}
