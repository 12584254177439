import { Box, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const AccordionContainer = styledMUI(Box)<{ large: number }>(({ large }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: large ? 'pointer' : 'auto',
}))

export const Paragraph = styledMUI(Box)<{ fontSize?: string }>(({ fontSize }) => ({
  textAlign: 'left',
  fontStyle: 'normal',
  fontSize: fontSize ? fontSize : '20px',
  lineHeight: '120%',
  fontWeight: 700,
  color: '#0095FF',
}))

export const ContentAction = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  margin: '24px 0 0',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const TextAction = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#0095ff',
}))
