import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IGetResponsableStudents } from '~/services/User/types'

const getResponsableStudents = async () => {
  const { data } = await HTTPClient.get('/user/relationships')

  return data
}

export const useGetResponsableStudents = () =>
  useQuery<IGetResponsableStudents>(['responsableStudents'], getResponsableStudents)
