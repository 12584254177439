import { useRef, useState } from 'react'

import { UilCheck, UilNotes } from '@iconscout/react-unicons'

import { ModalActionComponent, ModalComponent } from '~/components'

import { Buttons } from './components/Buttons'
import { DocumentForm } from './components/FormLesson'
import { DocumentFormProject } from './components/FormProject'
import { IModalDocumentationCommomProps } from './ModalDocumentation.interfaces'

export const ModalDocumentationComponent = ({
  idMoment,
  idLesson,
  type = 'add',
  values,
  queryKeys = ['getDocumentation'],
  variant,
  idProject,
  isClassInStatus,
}: IModalDocumentationCommomProps) => {
  const isLesson = idLesson ? true : false
  const formRef = useRef()
  const [showDocumentModal, setShowDocumentModal] = useState(false)

  const [showSubmittedModal, setShowSubmittedModal] = useState<boolean>(false)
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)

  const handleDiscardBtn = () => {
    setShowCancelModal(false)
    setShowDocumentModal(false)
  }

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const handleLoadingSubmitChange = (isLoading: boolean) => {
    setIsLoadingSubmit(isLoading)
  }

  const handleReturnBtn = () => {
    setShowCancelModal(false)
  }

  const handleShowDocumentModal = (value: boolean) => {
    setShowDocumentModal(value)
  }

  return (
    <>
      <Buttons
        variant={variant}
        handleShowDocumentModal={handleShowDocumentModal}
        showDocumentModal={showDocumentModal}
      />

      {showDocumentModal && (
        <ModalComponent
          header={true}
          footer={true}
          loading={isLoadingSubmit}
          title={type === 'add' ? 'Documentar' : 'Editar documentação'}
          buttonActionText={'Salvar Documentação'}
          buttonActionIcon={<UilNotes size="20" />}
          open={showDocumentModal}
          actionCloseModal={() => handleShowDocumentModal(false)}
          actionCancelModal={setShowCancelModal}
          onSubmit={() => formRef.current?.submitForm()}
          isClassInStatus={isClassInStatus}
        >
          {isLesson ? (
            <DocumentForm
              ref={formRef}
              idLesson={Number(idLesson)}
              queryKeys={queryKeys}
              values={values}
              type={type}
              setShowCancelModal={setShowCancelModal}
              setShowSubmittedModal={setShowSubmittedModal}
              handleShowDocumentModal={handleShowDocumentModal}
              onLoadingSubmitChange={handleLoadingSubmitChange}
              isClassInStatus={isClassInStatus}
            />
          ) : (
            <DocumentFormProject
              ref={formRef}
              projectId={Number(idProject)}
              type={type}
              values={values}
              setShowSubmittedModal={setShowSubmittedModal}
              handleShowDocumentModal={handleShowDocumentModal}
              setShowCancelModal={setShowCancelModal}
              onLoadingSubmitChange={handleLoadingSubmitChange}
            />
          )}
        </ModalComponent>
      )}
      <ModalActionComponent
        open={showCancelModal}
        confirmCancelText="Excluir Documentação"
        title="Tem certeza que deseja excluir a documentação?"
        actionCofirm={handleDiscardBtn}
        actionCloseModal={handleReturnBtn}
        returnBtnText="Voltar"
        infoWidth={700}
        isFullModal={true}
      />

      <ModalActionComponent
        open={showSubmittedModal}
        title="Documentação salva com sucesso!"
        actionCloseModal={() => setShowSubmittedModal(false)}
        icon={<UilCheck size="30" />}
        infoWidth={700}
        isFullModal={false}
      />
    </>
  )
}
