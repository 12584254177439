import styled, { css } from 'styled-components'

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  align-items: flex-start;
  flex: none;
  flex-grow: 1;
  gap: 8px;
  width: 48.59%;
  max-width: 48.59%;
  min-height: 230px;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.midLight};
  order: 0;
  border-radius: 8px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const CardHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #e2e5ec;
`

export const AbilitydHeader = styled.div`
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 6px;
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  border-bottom: 1px solid #e2e5ec;
`

export const EvidenceChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
`

export const WrapperEditIcons = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const WrapperChip = styled.div`
  width: 70%;
  display: flex;
  padding: 5px 0;
  align-items: center;
  gap: 8px;
`

export const EditIcons = styled.div`
  color: ${({ theme }) => theme.colors.actionPure};
  cursor: pointer;
  margin-left: 16px;
  gap: 8px;
  display: flex;
`

export const CardContent = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``

export const Chip = styled.span<{ variant: 'light' | 'primary' }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: max-content;

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.colors.actionPure};
      background-color: ${({ theme }) => theme.colors.actionLight};
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.colors.quaternaryPure};
      background-color: ${({ theme }) => theme.colors.quaternaryLight};
    `}
`

export const TextParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.lowPure};
  align-self: stretch;
  margin-bottom: 0.5rem;
`
export const Audio = styled.audio`
  height: 32px;
  width: 250px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  background-color: none;

  &::-webkit-media-controls-play-button {
    background-color: #0095ff;
    border-radius: 50%;
  }

  &::-webkit-media-controls-panel {
    background-color: #e8f6ff;
  }

  &::-webkit-media-controls-current-time-display {
    color: #0095ff;
  }

  &::-webkit-media-controls-time-remaining-display {
    color: #0095ff;
  }

  &::-webkit-media-controls-volume-control-container {
    display: none;
  }

  &::-webkit-media-controls-panel-internal-media-controls-overflow-button {
    display: none;
  }

  &::-webkit-media-controls-play-button.pause {
    color: #fff;
  }
`
