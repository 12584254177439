import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import { colorsStepsActivedVariant } from '~/validations'

import theme from '~/styles/theme'

export const CardActivity = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  border-radius: 8px;
`
export const ContentActivity = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`
export const ContentLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  transition: all 0.5s;
`
export const TextStepDescriptionActivity = styledMUI(Typography)<{ color: string }>(({ variant }) => ({
  fontSize: '14px',
  border: 'none',
  color: variant,
  lineHeight: '120%',
  '& span:first-child': {
    fontWeight: 400,
  },
  '& span': {
    fontWeight: 700,
    maxWidth: '90%',
  },
}))

export const textLesson = styled.span``

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`
export const Arrow = styled.div`
  flex-direction: column;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.quaternaryDark};
  }

  @media (max-width: 768px) {
    margin-top: -14%;
    margin-left: 55%;
  }
`

export const WrapperEvidence = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  gap: 8px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const Label = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
`
export const TextChallenge = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
`
export const WrapperCollapse = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.highPure};
  gap: 12px;
  border-radius: 8px;
  flex-direction: column;
`
export const LabelWrapper = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.quaternaryPure};
`

export const AccordionContainer = styledMUI(Accordion)(() => ({
  backgroundColor: `${theme.colors.highPure}`,
  borderRadius: '8px',
  boxShadow: 'none',
  marginTop: '5px',
  width: '100%',
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

export const AccordionSummaryContent = styledMUI(AccordionSummary)(() => ({
  padding: '0px 16px',
  margin: '0px',
}))

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50%',
}))

export const Title = styledMUI(Typography)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '120%',
    color: colorsStepsActivedVariant(type),
  }),
)

export const AccordionDetailsContent = styledMUI(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0px 16px 16px',
  margin: '0px',
}))

export const ContentParagraphs = styledMUI(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const Paragraph = styledMUI(ListItem)(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
}))

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentCards = styledMUI(List)(() => ({
  width: '950px',
  display: 'flex',
  alignItems: 'stretch',
  gap: '8px',
  overflowX: 'hidden',
}))
