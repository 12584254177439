import { UilArrowRight, UilArrowLeft } from '@iconscout/react-unicons'

import { ICarouselCalendarComponentProps } from './CarouselCalendar.interfaces'
import { daysInitials } from './CarouselCalendar.mocks'
import * as S from './CarouselCalendar.styles'

export const CarouselCalendarComponent = ({
  currentDay,
  actionSetCurrentDay,
  selectedDay,
  actionSetSelectedDay,
}: ICarouselCalendarComponentProps) => {
  const getSunday = (date: Date) => {
    const filteredDate = new Date(date.setHours(0, 0, 0, 0))

    filteredDate.setDate(filteredDate.getDate() - filteredDate.getDay())

    return filteredDate.toISOString()
  }

  const getWeek = (date: Date) => {
    const sunday = new Date(getSunday(new Date(date)))
    sunday.setHours(0, 0, 0, 0)

    const arr = [sunday]

    for (let i = 1; i < 7; i++) {
      arr.push(new Date(sunday.getTime() + i * 86400000))
    }

    return arr
  }

  const handlePrevWeek = () => {
    const date = new Date(currentDay)
    date.setHours(0, 0, 0, 0)

    actionSetCurrentDay(new Date(date.getTime() - 604800000))
  }

  const handleNextWeek = () => {
    const date = new Date(currentDay)
    date.setHours(0, 0, 0, 0)

    actionSetCurrentDay(new Date(date.getTime() + 604800000))
  }

  return (
    <S.ContainerSlider>
      <S.ButtonIcon onClick={() => handlePrevWeek()}>
        <UilArrowLeft data-testid="carousel-prev-btn" size={22} color="#0095FF" />
      </S.ButtonIcon>

      {getWeek(currentDay).map((date) => {
        const currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)
        const elemDate = new Date(date)
        const selecteDayTreated = selectedDay.setHours(0, 0, 0, 0)

        return (
          <S.ContentDays key={date.getDate()} onClick={() => actionSetSelectedDay(date)}>
            <S.TextLabel variant="caption">{daysInitials[elemDate.getDay()]}</S.TextLabel>

            <S.ButtonDay
              data-testid="carousel-select-day-btn"
              selected={new Date(selecteDayTreated).getTime() == elemDate.getTime()}
              current_day={currentDate.getTime() == elemDate.getTime() ? 1 : 0}
            >
              {elemDate.getDate()}
            </S.ButtonDay>
          </S.ContentDays>
        )
      })}

      <S.ButtonIcon onClick={() => handleNextWeek()}>
        <UilArrowRight data-testid="carousel-next-btn" size={22} color="#0095FF" />
      </S.ButtonIcon>
    </S.ContainerSlider>
  )
}
