import { Avatar, Tooltip } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { sizesAvatars } from '~/validations'

export const AvatarContainer = styledMUI(Avatar)<{
  size: 'xsmall' | 'small' | 'medium' | 'mediumPlus' | 'mediumLarge' | 'large'
  selected?: boolean
}>(({ size, selected }) => ({
  position: 'static',
  height: sizesAvatars(size),
  width: sizesAvatars(size),
  fontSize: size === 'small' ? 10 : size === 'large' ? 50 : 15,
  border: selected ? '2px solid #0095FF' : 'none',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))
