import { gql } from 'graphql-request'

export const GET_CLASSES_WEEK = gql`
  query getClassesWeek($dateStart: FlexDateTime!, $dateEnd: FlexDateTime!, $showScheduledClasses: Boolean!) {
    scheduledMomentByProfessor(
      dtScheduleStart: $dateStart
      dtScheduleEnd: $dateEnd
      showScheduledClasses: $showScheduledClasses
    ) {
      items {
        idMoment
        coMomentStatus
        txMomentStatus
        nuOrder
        nuTrackStages
        dtSchedule
        txTitle
        txTrackTitle
        idMomentTrack

        class {
          txName
          idClass

          schoolGrade {
            grade {
              txGrade
              coStage
            }
          }
        }
      }
    }
  }
`
