import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'

import { AppBarComponent, MomentCardComponent, ValidateDataSessionComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Home'

import { useHomePageContext } from './context'
import * as S from './styles'

export const HomeView = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const titleByNuOrder = (nuOrder: number) => {
    switch (nuOrder) {
      case 1:
        return 'Contextualização e Chuva de Ideias'
      case 2:
        return 'Ideação e Planejamento'
      case 3:
        return 'Execução do Planejamento'
      case 4:
        return 'Partilha e Escuta Coletiva'
      default:
        return ''
    }
  }

  function checkDate(date: Date): string {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (date.toDateString() === today.toDateString()) {
      return 'Hoje'
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Ontem'
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return 'Amanhã'
    } else {
      return dayjs(date).format('DD/MM/YYYY')
    }
  }

  const { extensiveCurrentDay, getOfflineInformation } = useHomePageContext()

  return (
    <>
      <AppBarComponent />

      <S.ContainerHome>
        <S.ContentHome>
          <HeaderComponent currentDay={extensiveCurrentDay} username={user?.given_name ?? ''} />

          <S.CardsContainer component="ul">
            {getOfflineInformation?.length > 0 ? (
              <>
                {getOfflineInformation[0]?.moments?.map((scheduleMoment) => (
                  <MomentCardComponent
                    isOffline
                    key={scheduleMoment?.idMoment}
                    momentTime={scheduleMoment?.dtSchedule}
                    momentStatusCode={scheduleMoment?.coMomentStatus as string}
                    momentStatusText={scheduleMoment?.txMomentStatus as string}
                    nuOrder={scheduleMoment?.nuOrder}
                    totalClass={Number(scheduleMoment?.nuTrackStages)}
                    momentTitle={
                      scheduleMoment?.class?.schoolGrade?.grade?.coStage === 'F2'
                        ? (titleByNuOrder(scheduleMoment?.nuOrder) as string)
                        : (scheduleMoment?.txTitle as string)
                    }
                    momentClassInfo={scheduleMoment?.class}
                    action={
                      ['F2', 'EM', 'M3', 'M4'].includes(scheduleMoment?.class?.schoolGrade?.grade?.coStage as string)
                        ? () => navigate(`/offline/project/${scheduleMoment?.idMoment}`, { state: { from: '/home' } })
                        : () => navigate(`/offline/lesson/${scheduleMoment?.idMoment}`, { state: { from: '/home' } })
                    }
                  />
                ))}
              </>
            ) : (
              <S.ContentEmoji>
                <ValidateDataSessionComponent
                  screen="home"
                  title="Você não tem nenhuma aula disponível."
                  description="Acesse a agenda para planejar suas próximas aulas"
                />
              </S.ContentEmoji>
            )}
          </S.CardsContainer>
        </S.ContentHome>
      </S.ContainerHome>
    </>
  )
}
