import React, { useEffect, useRef, useState } from 'react'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AppBarComponent, ContainerComponent } from '~/components'

import {
  FixedEvidencesComponent,
  HeaderPlanning,
  SkeletonLoading,
  StepAccordion,
  VariableEvidencesComponent,
} from '~/containers/private/Fund1/Planning'

import { useLessonPlanningPageContext } from '~/pages/private/Fund1/Planning/context'

import * as S from './styles'

export const LessonPlanningView = () => {
  const { evidencesByActivityOnStepTwo, isLoading, fixedEvidences, guidance, steps, txTitleLesson } =
    useLessonPlanningPageContext()

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const variablesRefContainer = useRef<HTMLDivElement>(null)
  const overallStepRef = useRef<HTMLDivElement>(null)
  const firstStepRef = useRef<HTMLDivElement>(null)
  const secondStepRef = useRef<HTMLDivElement>(null)
  const thirdStepRef = useRef<HTMLDivElement>(null)

  const [expadedGeneralOrientation, setExpadedGeneralOrientation] = useState(true)
  const [expadedFirstStep, setExpadedFirstStep] = useState(true)
  const [expadedSecondStep, setExpadedSecondStep] = useState(true)
  const [expadedThirdStep, setExpadedThirdStep] = useState(true)

  const [activedTab, setActivedTab] = useState(0)

  const scrollToNextStep = (ref: React.RefObject<HTMLDivElement>) => {
    const headerHeight = document.getElementById('header')?.clientHeight ?? 0
    const position = isMobile ? headerHeight - 20 : headerHeight + 50
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - position,
      })
    }
  }

  const handleSetActiveTab = (newActivedTab: number) => {
    switch (newActivedTab) {
      case 0:
        scrollToNextStep(variablesRefContainer)
        break
      case 1:
        setExpadedFirstStep(true)
        setTimeout(
          () => {
            scrollToNextStep(firstStepRef)
          },
          expadedFirstStep ? 0 : 400,
        )
        break
      case 2:
        setExpadedSecondStep(true)
        setTimeout(
          () => {
            scrollToNextStep(secondStepRef)
          },
          expadedSecondStep ? 0 : 400,
        )
        break
      case 3:
        setExpadedThirdStep(true)
        setTimeout(
          () => {
            scrollToNextStep(thirdStepRef)
          },
          expadedThirdStep ? 0 : 400,
        )
        break
      default:
        break
    }
    setActivedTab(newActivedTab)
  }

  const handleScroll = () => {
    const overallStepOffset = overallStepRef.current?.offsetTop ?? 0
    const firstStepOffset = firstStepRef.current?.offsetTop ?? 0
    const secondStepOffset = secondStepRef.current?.offsetTop ?? 0
    const thirdStepOffset = thirdStepRef.current?.offsetTop ?? 0

    const scrollPosition = window.scrollY + 280

    if (scrollPosition >= overallStepOffset && scrollPosition < firstStepOffset) {
      setActivedTab(0)
    } else if (scrollPosition >= firstStepOffset && scrollPosition < secondStepOffset) {
      setActivedTab(1)
    } else if (scrollPosition >= secondStepOffset && scrollPosition < thirdStepOffset) {
      setActivedTab(2)
    } else if (scrollPosition >= thirdStepOffset) {
      setActivedTab(3)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            <HeaderPlanning
              txNameLesson={txTitleLesson}
              handleSetActiveTab={handleSetActiveTab}
              activedTab={activedTab}
              steps={steps}
              guidance={guidance}
              fixedEvidences={fixedEvidences}
              evidencesByActivityOnStepTwo={evidencesByActivityOnStepTwo}
            />
            <S.Container>
              <S.VariableEvidencesContainer ref={variablesRefContainer}>
                <VariableEvidencesComponent steps={evidencesByActivityOnStepTwo} />
              </S.VariableEvidencesContainer>
              <S.FixedEvidencesContainer>
                <FixedEvidencesComponent steps={fixedEvidences} />
              </S.FixedEvidencesContainer>
              {guidance.length > 0 && (
                <StepAccordion
                  key={'default'}
                  title="Orientações Gerais"
                  expaded={expadedGeneralOrientation}
                  onToggle={() => setExpadedGeneralOrientation(!expadedGeneralOrientation)}
                  orientationItems={[
                    {
                      txOrientationCode: guidance,
                      lessonActivitySupportReference: [],
                    },
                  ]}
                  supportReferences={[]}
                  accordionRef={overallStepRef}
                  type="default"
                />
              )}
              <StepAccordion
                key={'firstStep'}
                title="Passo 1"
                expaded={expadedFirstStep}
                onToggle={() => setExpadedFirstStep(!expadedFirstStep)}
                orientationItems={steps?.[0]?.lessonActivity?.[0]?.lessonActivityOrientation || []}
                supportReferences={
                  steps?.[0]?.lessonActivity?.[0]?.lessonActivityOrientation?.[0]?.lessonActivitySupportReference || []
                }
                type="firstStep"
                accordionRef={firstStepRef}
              />
              <StepAccordion
                key={'secondStep'}
                title="Passo 2"
                expaded={expadedSecondStep}
                onToggle={() => setExpadedSecondStep(!expadedSecondStep)}
                txGuidance={steps?.[1]?.txGuidance}
                orientationItems={steps?.[1]?.lessonActivity?.[0]?.lessonActivityOrientation || []}
                lessonActivity={steps?.[1]?.lessonActivity}
                supportReferences={
                  steps?.[1]?.lessonActivity?.[0]?.lessonActivityOrientation?.[0]?.lessonActivitySupportReference || []
                }
                type="secondStep"
                accordionRef={secondStepRef}
              />
              <StepAccordion
                key={'thirdStep'}
                title="Passo 3"
                expaded={expadedThirdStep}
                onToggle={() => setExpadedThirdStep(!expadedThirdStep)}
                orientationItems={steps?.[2]?.lessonActivity?.[0]?.lessonActivityOrientation || []}
                supportReferences={
                  steps?.[2]?.lessonActivity?.[0]?.lessonActivityOrientation?.[0]?.lessonActivitySupportReference || []
                }
                type="thirdStep"
                accordionRef={thirdStepRef}
              />
            </S.Container>
          </>
        )}
      </ContainerComponent>
    </>
  )
}
