import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

interface IArrowProps {
  enabled: boolean
}

interface ICardContainerProps {
  isMobile: boolean
}

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.highPure};
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

export const TrackDescription = styledMUI(Box)(() => ({
  padding: '6px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const ContainerLesson = styled.div<{ hasScrolled?: boolean }>`
  padding: 24px 24px 16px 24px;
  margin-top: ${({ hasScrolled }) => (hasScrolled ? '200px' : '0')};
  display: grid;
  gap: 16px;
`

export const CardSliderResumeContainer = styled.div<ICardContainerProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`

export const ContainerScroll = styled.div`
  overflow-y: auto;
  height: 100vh;
`

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 50px;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};

  span {
    font-weight: 500;
    font-size: 14px;
  }
`

export const SwitchViewListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.actionLight};
  padding: 8px;
  border-radius: 50px;
  gap: 8px;
  display: flex;
  align-items: center;
`

export const SwitchViewButton = styled.button<{ active: boolean }>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) => (active ? theme.colors.actionPure : 'transparent')};
`

export const ArrowButton = styled.button<IArrowProps>`
  float: left;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.actionPure : theme.colors.midMedium)};
  width: 25.42px;
  height: 24px;
  color: #fff;
  border-radius: 50%;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerCardsList = styled.ul<{ displayMode: boolean }>`
  width: 100%;
  gap: 16px;
  padding: 8px 8px;
  ${({ displayMode }) =>
    !displayMode
      ? css`
          display: flex;
          overflow-y: auto;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        `}
`

export const CardProject = styled.li<{ bgColor: string }>`
  min-width: 260px;
  max-width: 260px;
  padding: 16px;
  list-style: none;
  border-radius: 8px;
  height: fit-content;
  background-color: ${({ bgColor }) => bgColor};

  .divider {
    margin: 16px 0;
  }
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardIndex = styled.div<{ color: string }>`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  display: flex;
  border-radius: 24px;
  background-color: ${({ color }) => color};
`

export const CardTime = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 700;
`

export const InfoContainer = styled.div`
  display: grid;
  gap: 16px;
`

export const CardStatus = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 100%;
  }
`

export const CardProjectTitle = styled.h2<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 20px;
`

export const CardProjectDescription = styled.p`
  display: -webkit-box;
  font-size: 14px;
  line-height: 150%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CardTeacher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 700;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.lowLight};
  }
`

export const CardSlider = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.midLight};
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  cursor: pointer;
`

export const MomentTime = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.actionPure};
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.actionLight};
  border-radius: 4px;
`

export const TimeStatusDiv = styled.div`
  display: flex;
  gap: 8px;
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TitleInfo = styled.div`
  display: flex;
  gap: 12px;
`

export const CardPink = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  gap: 16px;
  cursor: pointer;
`

export const Arrow = styled.div<IArrowProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.actionPure : theme.colors.midMedium)};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.highPure};
  border-radius: 50%;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'normal')};
`

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
`

export const TittleBefore = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`

export const CardArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.midMedium};
`

export const TextParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
`

export const CardInfo = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const Avatars = styled.div`
  justify-self: flex-end;
  align-self: flex-end;
`
