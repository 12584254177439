import { useEffect, useState } from 'react'

import { UilArrowDown, UilArrowUp } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import { IAccordionTextComponentProps } from './AccordionText.interfaces'
import * as S from './AccordionText.styles'

export const AccordionTextComponent = ({
  paragraph,
  fontSize,
  typePlus = 'default',
  limit,
}: IAccordionTextComponentProps) => {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(paragraph?.length < limit)
  }, [paragraph?.length, limit])

  const handleClick = () => {
    if (paragraph?.length >= limit) {
      setOpen(!open)
    }
  }

  return (
    <S.AccordionContainer onClick={handleClick} large={paragraph && paragraph?.length >= limit ? 1 : 0}>
      <S.Paragraph fontSize={fontSize}>
        {open
          ? paragraph?.length
            ? parse(paragraph)
            : parse('<span>Carregando...</span>')
          : parse(paragraph?.slice(0, limit) + '...')}

        {typePlus === 'default' && paragraph?.length > limit && (
          <S.TextAction variant="caption">{open ? 'Ver menos' : 'Ver mais'}</S.TextAction>
        )}
      </S.Paragraph>

      {paragraph?.length > limit && typePlus === 'bottom' && (
        <S.ContentAction>
          <S.TextAction variant="caption">{open ? 'Ver menos' : 'Ver mais'}</S.TextAction>
          {open ? <UilArrowUp size={18} color={'#0095FF'} /> : <UilArrowDown size={18} color={'#0095FF'} />}
        </S.ContentAction>
      )}
    </S.AccordionContainer>
  )
}
