import { useEffect, useState } from 'react'
import React from 'react'

import { UilArrowLeft } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { DropdownSkillComponent, SkeletonComponent } from '~/components'
import CardActivityDetailContext from '~/components/Cards/CardActivityDetailContext/CardActivityDetailContext'
import CardActivityDetailTrail from '~/components/Cards/CardActivityDetailTrail/CardActivityDetailTrail'

import { ISecondStepProps, LessonActivity } from './interfaces'
import * as S from './styles'

export default function SecondStep({
  isLoading,
  evidences,
  index,
  title,
  description,
  trailIndex,
  lessonActivity,
  handleBackStep,
}: ISecondStepProps) {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [activeItem, setActiveItem] = useState<any>(null)

  const fixedEvidence = evidences ? evidences[trailIndex]?.evidences?.find((e) => e.coEvidenceType === 'FIX') : []

  function handleLessonStepTwo() {
    setActiveIndex(null)
  }

  const handleActiveIndex = (item: any, index: number) => () => {
    setActiveIndex(index)
    setActiveItem(item)
  }

  useEffect(() => {
    setActiveIndex(null)
  }, [])

  const convertHtmlToString = (html: string) => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  const plainText = convertHtmlToString(description)

  const convertFormatHtmlToString = (html: unknown) => {
    if (typeof html === 'string') {
      return parse(html)
    } else {
      return ''
    }
  }

  const formattedContent = convertFormatHtmlToString(description)

  const [verTexto, setVerTexto] = React.useState(false)

  function handleVerTexto() {
    setVerTexto(!verTexto)
  }
  const truncatedText =
    plainText.length > (isMobile ? 148 : 597) ? `${plainText?.substring(0, isMobile ? 148 : 560)}... ` : plainText
  return (
    <S.StepContainer>
      <S.StepContent>
        <S.StepContentHeader>
          <S.BtnBack onClick={handleBackStep}>
            <UilArrowLeft size="16" />
            Voltar
          </S.BtnBack>
          <S.TitleStep>
            <p>Passo {index}</p>{' '}
            <strong>
              <p>{title}</p>
            </strong>
          </S.TitleStep>
        </S.StepContentHeader>
        <S.StepAbilityContent>
          <S.SkillSpan>Habilidade Fixa</S.SkillSpan>

          <DropdownSkillComponent
            txSkillName={fixedEvidence?.txAbilityName}
            txSkillDescription={fixedEvidence?.txEvidenceName}
            iconSkill={fixedEvidence?.txCompetenceImagePath}
            iconCompetency={fixedEvidence?.txAbilityImagePath}
            txBncc={fixedEvidence?.txAbilityName}
            txCompetency={fixedEvidence?.txCompetenceName}
          />
        </S.StepAbilityContent>
        <S.StepDescriptionContent>
          {isLoading && (
            <>
              <SkeletonComponent width={900} height={180} />
            </>
          )}
          {!isLoading && (
            <>
              <S.Description onClick={() => handleVerTexto()}>
                <strong>Sobre a trilha: </strong>
                {verTexto ? formattedContent : truncatedText}
                <S.BtnActionDescription onClick={() => handleVerTexto()}>
                  {verTexto ? ' Ver menos' : ' Ver mais'}
                </S.BtnActionDescription>
              </S.Description>
            </>
          )}
        </S.StepDescriptionContent>

        <S.StepsContainer>
          <S.BtnAction onClick={() => handleLessonStepTwo()} active={activeIndex === null}>
            {isMobile ? 'Contextos' : 'Todos os contextos'}
          </S.BtnAction>
          {isLoading && (
            <>
              <SkeletonComponent width={170} height={40} />
              <SkeletonComponent width={170} height={40} />
              <SkeletonComponent width={170} height={40} />
              <SkeletonComponent width={170} height={40} />
            </>
          )}
          {!isLoading && (
            <>
              {lessonActivity?.map((item: LessonActivity, index: number) => (
                <S.StepsContent key={index} active={index === activeIndex} onClick={handleActiveIndex(item, index)}>
                  {item?.txCategoryName}
                </S.StepsContent>
              ))}
            </>
          )}
        </S.StepsContainer>
        {activeIndex === null ? (
          <>
            {isLoading && (
              <>
                <SkeletonComponent width={780} height={100} />
                <SkeletonComponent width={780} height={100} />
                <SkeletonComponent width={780} height={100} />
                <SkeletonComponent width={780} height={100} />
              </>
            )}
            {!isLoading && (
              <>
                {lessonActivity?.map((item, index: number) => (
                  <CardActivityDetailTrail key={index} activity={item} isTrail={true} />
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <CardActivityDetailContext activity={activeItem} />
          </>
        )}
      </S.StepContent>
    </S.StepContainer>
  )
}
