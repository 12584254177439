import { useMutation, useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IAvailableClassesToSchedule } from '~/services/TrailSchedule/Trail.interfaces'

export interface IServiceEngageTrail {
  classId: number
  lessonId?: number
  projectId?: number
  body: Record<string, never>
}

export const EngageTrail = async (prop: IServiceEngageTrail) => {
  const { data } = await HTTPClient.post(
    `/moment/class/${prop.classId}/engage-track/${prop.lessonId || prop.projectId}`,
    prop.body,
  )

  return data
}

export const useServiceEngageTrail = () => useMutation(['EngageTrail'], EngageTrail)

export const AvailableClassesToSchedule = async (idTrack: number, coGrade: string) => {
  const { data } = await HTTPClient.get(`class/track/${idTrack}/coGrade/${coGrade}/available-classes`)
  return data
}

export const useAvailableClassesToSchedule = (idTrack: number, coGrade: string, enabled = false) =>
  useQuery<IAvailableClassesToSchedule[]>(
    ['AvailableClassesToSchedule'],
    () => AvailableClassesToSchedule(idTrack, coGrade),
    { enabled },
  )
