import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '24px 24px 16px 24px',
  gap: '16px',
}))

export const TextContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const DateTimeInfo = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#666',
}))
