import { IModalComponentProps } from './Modal.interfaces'
import * as S from './Modal.styles'

export const ModalComponentBase = ({ open, actionCloseModal, children, maxWidth, width }: IModalComponentProps) => {
  return (
    <S.ContainerModal open={open} onClose={actionCloseModal}>
      <S.ContentModal width={width || 'initial'} maxWidth={maxWidth || '700px'}>
        {children}
      </S.ContentModal>
    </S.ContainerModal>
  )
}
