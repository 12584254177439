import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { GetClassesResponse } from '~/services/Class/types'

export const getClasses = async (
  coGradeSelected: Array<string>,
  txName: string,
  showDisableClasses: boolean,
  showPreviousYears: boolean,
) => {
  const coGradeString =
    coGradeSelected !== undefined ? coGradeSelected.map((coGrade) => `&coGrade=${coGrade}`).join('&') : ``
  const txNameString = txName.length > 0 ? `&txName=${txName}` : ``
  const showDisableClassesString = showDisableClasses === true ? `&showDisabled=${showDisableClasses}` : ``
  const showPreviousYearsString = showDisableClasses === true ? `&showPreviousYears=${showPreviousYears}` : ``
  const { data } = await HTTPClient.get(
    `/class/professor-listing?${coGradeString}${txNameString}${showDisableClassesString}${showPreviousYearsString}`,
  )

  return data
}

export const useGetClasses = (
  coGradeSelected: Array<string>,
  txName: string,
  showDisableClasses: boolean,
  showPreviousYears: boolean,
) =>
  useQuery<GetClassesResponse>(['GetClasses'], () =>
    getClasses(coGradeSelected, txName, showDisableClasses, showPreviousYears),
  )
