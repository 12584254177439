import { useNavigate } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'

import { Evidence, IGetStudentsGroupLesson } from '~/services/Students/types'

import { AvatarGroupComponent, DropdownSkillComponent, IconComponent, SkeletonComponent } from '~/components'

import { LessonActivity } from '~/pages/private/Fund1/Lesson/interfaces'

import * as S from './styles'

interface CardActivityDetailTrailProps {
  activity: LessonActivity | any
  isOffline?: boolean | undefined
  idLesson?: number | undefined
  isLoadingStudents?: boolean | undefined
  students?: IGetStudentsGroupLesson | undefined
  idLessonMoment?: string | undefined
  idClass?: string | undefined
  isTrail?: boolean | undefined
}

export default function CardActivityDetailTrail({
  activity,
  isOffline,
  idLesson,
  isLoadingStudents,
  students,
  idLessonMoment,
  idClass,
  isTrail,
}: CardActivityDetailTrailProps) {
  const navigate = useNavigate()
  const evidenceData = activity?.evidences?.filter((item: Evidence) => item?.coEvidenceType === 'VAR')
  return (
    <S.CardActivity key={activity?.idLessonActivity ?? ''} isTrail={!isTrail}>
      <S.ContentActivity>
        {!isTrail ? (
          <>
            <S.ContentLabels
              onClick={() =>
                navigate(
                  isOffline
                    ? `/offline/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`
                    : `/class/${idClass}/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`,
                )
              }
            >
              <S.Row isTrail={!isTrail}>
                <IconComponent
                  color="card"
                  fontWeight="normal"
                  code={isTrail ? activity.txCategoryImagePath : activity.category?.txImagePath}
                  size="xsmall"
                />
                <S.TextStepDescriptionActivity color="card">
                  <S.textLesson>{activity.category?.txName}</S.textLesson> -{' '}
                  <S.textLesson>{activity?.txTitle}</S.textLesson>
                </S.TextStepDescriptionActivity>
              </S.Row>
            </S.ContentLabels>
          </>
        ) : (
          <>
            <S.Row isTrail={!isTrail}>
              <IconComponent
                color="card"
                fontWeight="normal"
                code={isTrail ? activity.txCategoryImagePath : activity.category?.txImagePath}
                size="xsmall"
              />
              <S.TextStepDescriptionActivity color="card">
                <S.textLesson>{isTrail ? activity.txCategoryName : activity.category?.txName}</S.textLesson> -{' '}
                <S.textLesson>{activity?.txTitle}</S.textLesson>
              </S.TextStepDescriptionActivity>
            </S.Row>
          </>
        )}

        <S.Wrapper>
          {isLoadingStudents ? (
            <SkeletonComponent width={300} />
          ) : (
            <AvatarGroupComponent
              inAttendance
              max={4}
              images={students?.group?.filter(
                (i) => activity && i.idLessonMomentActivity === activity.lessonMomentActivity[0].idLessonMomentActivity,
              )}
              size="small"
            />
          )}
          {!isTrail && (
            <S.Arrow
              onClick={() =>
                navigate(
                  isOffline
                    ? `/offline/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`
                    : `/class/${idClass}/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`,
                )
              }
            >
              <UilArrowRight size={20} />
            </S.Arrow>
          )}
        </S.Wrapper>
      </S.ContentActivity>
      <S.WrapperEvidence>
        <S.Label>Habilidade variável</S.Label>
        {!isTrail ? (
          <>
            {evidenceData?.map((item: any, index: number) => (
              <DropdownSkillComponent
                key={index}
                txSkillName={item?.ability?.txName}
                iconSkill={item?.ability?.txImagePath}
                txSkillDescription={item?.txName}
                txCompetency={item?.ability?.competence?.txName}
                iconCompetency={item?.ability?.competence?.txImagePath}
              />
            ))}
          </>
        ) : (
          <>
            {evidenceData?.map((item: Evidence, index: number) => (
              <DropdownSkillComponent
                key={index}
                txSkillName={item?.txAbilityName}
                iconCompetency={item?.txAbilityImagePath}
                txSkillDescription={item?.txEvidenceName}
                iconSkill={item?.txCompetenceImagePath}
                txCompetency={item?.txCompetenceName}
              />
            ))}
          </>
        )}
      </S.WrapperEvidence>

      <S.WrapperEvidence>
        <S.Label>Desafio</S.Label>
        <S.TextChallenge>{activity?.txChallenge}</S.TextChallenge>
      </S.WrapperEvidence>
    </S.CardActivity>
  )
}
