import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createTransition, MouseTransition, MultiBackend } from 'react-dnd-multi-backend'
import { usePreview } from 'react-dnd-preview'
import withScrolling from 'react-dnd-scrolling'
import { TouchBackend } from 'react-dnd-touch-backend'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { getTypeSpecificId } from '~/components/Forms/Assessment/Assessment'

import { CardStudentComponent } from '~/containers/private/Fund1/LessonAssessment'

interface IPreviewStudent {
  type: 'group' | 'assessment'
}

interface ICustomDndProvider {
  children: React.ReactNode
  type?: 'group' | 'assessment'
}

const ScrollingComponent = withScrolling('div')

const TouchTransition = createTransition('touchstart', (event) => {
  return event.touches != null
})

export const CustomHTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: false },
      preview: true,
      transition: TouchTransition,
      skipDispatchOnTransition: true,
    },
  ],
}

const PreviewStudent = ({ type }: IPreviewStudent) => {
  const preview = usePreview()
  if (!preview.display) {
    return null
  }
  const { item, style } = preview
  return (
    // eslint-disable-next-line no-inline-styles/no-inline-styles
    <div className="item-list__item" style={{ ...style, zIndex: 100000 }}>
      {type === 'group' ? (
        <CardStudentComponent
          key={item?.id}
          id={item?.id}
          photoUrl={item?.object?.txImagePath}
          txName={item?.object?.txName}
          object={item.object}
        />
      ) : (
        <CardStudentComponent
          key={getTypeSpecificId(item, 'project')}
          object={item.object}
          id={getTypeSpecificId(item, 'project')}
          photoUrl={item.object.studentClass?.student?.txImagePath}
          txName={item.object.studentClass?.student?.txName}
        />
      )}
    </div>
  )
}

export const CustomDndProvider = ({ children, type = 'assessment' }: ICustomDndProvider) => {
  const { width } = useWindowDimensions()

  const isMobile = width && width <= 600
  return (
    <DndProvider backend={MultiBackend} options={CustomHTML5toTouch}>
      <ScrollingComponent className={'ScrollApp'}>
        {isMobile && <PreviewStudent type={type} />}
        {children}
      </ScrollingComponent>
    </DndProvider>
  )
}
