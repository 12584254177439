import { useMutation } from '@tanstack/react-query'
import { IApiError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

export interface IServiceMedia {
  body: Record<string, never>
}

export const sendMedia = async (prop: IServiceMedia): Promise<string> => {
  const { data } = await HTTPClient.post(`/media/upload/temporary-media`, prop.body)

  return data
}

export const renderMedia = async (id: number): Promise<File> => {
  const { data } = await HTTPClient.get(`/media/${id}/render`, { responseType: 'blob' })

  return data
}

export const useServiceSendMedia = () => useMutation<any, IApiError, any>(['SendMedia'], sendMedia)

export const useServiceRenderMedia = () => useMutation<any, IApiError, any>(['RenderMedia'], renderMedia)
