import { IDataProps } from './interfaces'

export const defaultDataState: IDataProps = {
  dtSchedule: new Date(),
  idLessonMoment: 0,
  momentStatus: {
    txMomentStatus: '',
    coMomentStatus: '',
    dtInserted: new Date(),
  },
  lesson: {
    txTitle: '',
    lessonTrackGroupNuOrder: 0,
    txGuidance: '',
    idLesson: 0,
    idLessonTrackGroup: 0,
    lessonStep: [
      {
        nuOrder: 0,
        idLessonStep: 0,
        idLesson: 0,
        dtInserted: new Date(),
        lessonActivity: [
          {
            nuOrder: 0,
            idLessonActivity: 0,
            idLessonStep: 0,
            category: {
              txName: '',
              txImagePath: '',
            },
            txTitle: '',
            txChallenge: '',
            lessonMomentActivity: [
              {
                idClass: 0,
                idLessonActivity: 0,
                idLessonMoment: 0,
                dtInserted: new Date(),
                idLessonMomentActivity: 0,
                idNetwork: 0,
                idSchool: 0,
                idSchoolYear: 0,
                lessonMomentGroup: [
                  {
                    idClass: 0,
                    idLessonMomentActivity: 0,
                    idLessonMomentGroup: 0,
                    dtInserted: new Date(),
                    idNetwork: 0,
                    idSchool: 0,
                    idSchoolYear: 0,
                    idStudent: 0,
                    idUserStudent: 0,
                    inAttendance: false,
                    studentClass: {
                      idClass: 0,
                      dtInserted: new Date(),
                      idNetwork: 0,
                      idSchool: 0,
                      idSchoolYear: 0,
                      idStudent: 0,
                      idUserStudent: 0,
                      student: {
                        txName: '',
                        dtInserted: new Date(),
                        idNetwork: 0,
                        idStudent: 0,
                        idSchool: 0,
                        idUserStudent: 0,
                        idUser: 0,
                        inDeleted: false,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    lessonComponent: [
      {
        coComponent: '',
        dtInserted: new Date(),
        component: {
          txComponent: '',
          txDescription: '',
        },
        idLesson: 0,
      },
    ],
  },
  class: {
    idClass: 0,
    schoolGrade: {
      grade: {
        txGrade: '',
        dtInserted: new Date(),
        coGrade: '',
        coStage: '',
      },
      idSchool: 0,
      idNetwork: 0,
    },
    txName: '',
    dtInserted: new Date(),
    idNetwork: 0,
    idSchool: 0,
    idSchoolYear: 0,
  },
}
