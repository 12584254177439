import { gql } from 'graphql-request'

export const GET_CLASS_INFOS_BY_CLASSID = gql`
  query getClassInfosByClassID($classId: Int!, $showDisabledClasses: Boolean) {
    class(where: { idClass: { eq: $classId } }, showDisabledClasses: $showDisabledClasses) {
      items {
        txName
        idClass
        classShift {
          txClassShift
        }
        schoolGrade {
          grade {
            coGrade
            txGrade
            stage {
              coStage
              txName
            }
          }
        }
      }
    }
  }
`
