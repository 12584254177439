import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { IThirdStepPageContextProps, IThirdStepPageProviderProps } from './interfaces'

const ThirdStepPageContext = createContext<IThirdStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  lesson: undefined,
  lessonSteps: undefined,
  thirdStepGroup: undefined,
})

const ThirdStepPageProvider = ({ children }: IThirdStepPageProviderProps) => {
  const { idLessonMoment, idLesson } = useParams()
  const { getOfflineInformation } = useOfflineMode()

  const [activedTab, setActivedTab] = useState<number>(0)
  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const lessonSteps = getOfflineInformation?.[0].lessonSteps[2]
  const lesson = getOfflineInformation?.[0]?.moments.find((moment) => moment.idMoment === Number(idLessonMoment))
  const thirdStepGroup = getOfflineInformation?.[0]?.lessonMomentGroups.find(
    (groups) => groups.idLessonMoment === Number(idLessonMoment) && groups.nuOrder === 3,
  )

  const thirdStepPageProviderValues = useMemo(() => {
    return {
      activedTab,
      handleActiveNewTab,
      lesson,
      lessonSteps,
      thirdStepGroup,
    }
  }, [thirdStepGroup, lesson, activedTab, handleActiveNewTab, lessonSteps])

  return <ThirdStepPageContext.Provider value={thirdStepPageProviderValues}>{children}</ThirdStepPageContext.Provider>
}

const useThirdStepPageContext = () => useContext(ThirdStepPageContext)

export { ThirdStepPageProvider, useThirdStepPageContext }
