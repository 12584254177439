import React from 'react'

import { ICompetence } from '~/services/Dashboard/types'

import { IconComponent } from '~/components'

import { formatLabel } from '../OverviewByCompetence/OverviewByCompetence'
import * as S from './OverviewBySkill.styles'

interface TooltipProps {
  children: React.ReactNode
  competence: ICompetence
  abilityName: string
  assessmentAvarage: number
  isVisible: boolean
}

const Tooltip: React.FC<TooltipProps> = ({ children, competence, isVisible, assessmentAvarage, abilityName }) => {
  return (
    <S.Container className="tooltip-container">
      {children}
      {isVisible && (
        <S.Tooltip>
          <S.TooltipTotalClasses>{abilityName}</S.TooltipTotalClasses>
          <S.ClassesText>
            {competence.totalMomentCount} {competence.totalMomentCount > 1 ? 'aulas realizadas' : 'aula realizada'}
          </S.ClassesText>
          <S.StatusTooltip>{formatLabel(assessmentAvarage)}</S.StatusTooltip>
          <S.BoxTooltip>
            <S.TooltipCompetence>Competência:</S.TooltipCompetence>
            <S.RowTooltip>
              <IconComponent
                code={competence.competenceIcon}
                size="xxsmall"
                color={`#${competence.competenceColor}`}
                fontWeight="normal"
              />
              <S.CompetenceText color={`#${competence.competenceColor}`}>{competence.competenceName}</S.CompetenceText>
            </S.RowTooltip>
          </S.BoxTooltip>
        </S.Tooltip>
      )}
    </S.Container>
  )
}

export default Tooltip
