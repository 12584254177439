import { useNavigate } from 'react-router-dom'

import { AppBarComponent, ContainerComponent, SkeletonComponent, ValidateDataSessionComponent } from '~/components'

import { CardResponsableStudentComponent } from '~/containers/private/ResponsableStudents'

import { useResponsableStudentsPageContext } from './context'
import * as S from './styles'

export const ResponsableStudentsView = () => {
  const { isLoading, data } = useResponsableStudentsPageContext()

  const navigate = useNavigate()

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <S.HeaderContainer>
          <S.Wrapper>
            <S.Title variant="h1">Painel do responsável</S.Title>
          </S.Wrapper>
        </S.HeaderContainer>

        {isLoading ? (
          <S.ContentCards>
            {[...Array(10)].map((_, index) => (
              <SkeletonComponent key={index} width={400} height={90} />
            ))}
          </S.ContentCards>
        ) : data?.length ? (
          <S.ContentCards>
            {data?.map((student, index: number) => {
              return (
                <CardResponsableStudentComponent
                  key={index}
                  student={student}
                  onClickCard={() => navigate(`/students/${student.idUser}`)}
                />
              )
            })}
          </S.ContentCards>
        ) : (
          <S.ContentEmoji>
            <ValidateDataSessionComponent
              screen="students"
              title="Nenhum aluno encontrado."
              description=""
              space={100}
            />
          </S.ContentEmoji>
        )}
      </ContainerComponent>
    </>
  )
}
