import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import * as Sentry from '@sentry/react'

import { onLocalHost } from '~/validations/onLocalHost'

import packageJson from '../../../package.json'

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://85818d312da64516a2d19611bca03d85@sentry.lekto.com.br/2',
    release: 'plataforma-v2@' + packageJson.version,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    enabled: onLocalHost(),
    sendDefaultPii: true,
  })

  // eslint-disable-next-line no-console
  console.log('Sentry enabled')
}
