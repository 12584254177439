import { IColorsStepsProps, IColorsVariantsProps } from '~/interfaces/ColorsSteps'

export const colorsStepsActived = (step: 'default' | 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep') => {
  const colorStep: IColorsStepsProps = {
    default: '#0095FF',
    firstStep: '#8C22BC',
    secondStep: '#E14983',
    thirdStep: '#E66251',
    fourthStep: '#0095FF',
  }

  return colorStep[step as keyof IColorsStepsProps]
}

export const colorsStepsActivedVariant = (variant: 'default' | 'one' | 'two' | 'three') => {
  const colorStep: IColorsVariantsProps = {
    default: '#0095FF',
    one: '#8C22BC',
    two: '#E14983',
    three: '#E66251',
  }

  return colorStep[variant as keyof IColorsVariantsProps]
}

export const backgroundColorsStepsActived = (
  step: 'default' | 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep',
) => {
  const backgroundColorStep: IColorsStepsProps = {
    default: '#F9FAFC',
    firstStep: '#F7E5FF',
    secondStep: '#FFDBE9',
    thirdStep: '#FFE7E3',
    fourthStep: '#E8F6FF',
  }

  return backgroundColorStep[step as keyof IColorsStepsProps]
}
