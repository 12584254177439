import { SkeletonComponent } from '~/components'

import * as S from './SkeletonLoading.styles'

export const SkeletonLoading = () => {
  return (
    <S.Container>
      <SkeletonComponent variant="rounded" width={400} height={50} />
      <SkeletonComponent variant="rounded" width={'100%'} height={80} />
      <SkeletonComponent variant="rounded" width={150} />
      <SkeletonComponent variant="rounded" width={'100%'} height={56} />
      <SkeletonComponent variant="rounded" width={'100%'} height={56} />
      <SkeletonComponent variant="rounded" width={'100%'} height={56} />
      <SkeletonComponent variant="rounded" width={150} />
      <SkeletonComponent variant="rounded" width={'100%'} height={56} />
      <SkeletonComponent variant="rounded" width={'100%'} height={56} />
      <SkeletonComponent variant="rounded" width={'100%'} height={56} />
    </S.Container>
  )
}
