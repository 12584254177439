import { useState } from 'react'

import { UilMultiply, UilCheckCircle, UilEyeSlash, UilEye } from '@iconscout/react-unicons'
import { Formik, Form, Field } from 'formik'
import { object, string } from 'yup'

import { ButtonComponent, ModalComponent } from '~/components'

import { IModalUpdatePasswordProps } from './ModalUpdatePassword.interfaces'
import * as S from './ModalUpdatePassword.styles'

export const ModalUpdatePasswordComponent = ({
  currentPassword,
  open,
  loading,
  actionCloseModal,
  actionSendData,
}: IModalUpdatePasswordProps) => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false)

  const initialValuesFormLogin = {
    oldPassword: currentPassword?.length ? currentPassword : '',
    newPassword: '',
    confirmNewPassword: '',
  }

  // eslint-disable-next-line no-useless-escape
  const regexSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  const regexNumber = /\d+/
  const regexCapitalLetter = /[A-Z]/
  const regexLowerCase = /[a-z]/

  return (
    <ModalComponent open={open} actionCloseModal={actionCloseModal}>
      <S.Container>
        <S.ModalHeader>
          <S.Title>Alteração de senha</S.Title>
          <S.ButtonCloseModal>
            <UilMultiply size={20} onClick={actionCloseModal} />
          </S.ButtonCloseModal>
        </S.ModalHeader>

        <Formik
          validationSchema={object().shape({
            oldPassword: string().min(8, 'Deve ter no mínimo 8 caracteres').required('Senha obrigatória'),
            newPassword: string().min(8, 'Deve ter no mínimo 8 caracteres').required('Senha obrigatória'),
            confirmNewPassword: string().min(8, 'Deve ter no mínimo 8 caracteres').required('Senha obrigatória'),
          })}
          initialValues={initialValuesFormLogin}
          onSubmit={(values) => {
            actionSendData(values)
          }}
        >
          {({ values }) => {
            return (
              <Form>
                <S.Password>
                  <Field
                    name="oldPassword"
                    type={showOldPassword ? 'text' : 'password'}
                    placeholder="Digite sua senha atual"
                    disabled={loading}
                    as={S.Input}
                  />

                  {showOldPassword ? (
                    <UilEye onClick={() => setShowOldPassword(false)} size="20" />
                  ) : (
                    <UilEyeSlash onClick={() => setShowOldPassword(true)} size="20" />
                  )}
                </S.Password>

                <S.Password>
                  <Field
                    name="newPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="Nova senha"
                    disabled={loading}
                    as={S.Input}
                  />

                  {showNewPassword ? (
                    <UilEye onClick={() => setShowNewPassword(false)} size="20" />
                  ) : (
                    <UilEyeSlash onClick={() => setShowNewPassword(true)} size="20" />
                  )}
                </S.Password>

                <S.Password>
                  <Field
                    name="confirmNewPassword"
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    placeholder="Confirmar nova senha"
                    disabled={loading}
                    as={S.Input}
                  />

                  {showConfirmNewPassword ? (
                    <UilEye onClick={() => setShowConfirmNewPassword(false)} size="20" />
                  ) : (
                    <UilEyeSlash onClick={() => setShowConfirmNewPassword(true)} size="20" />
                  )}
                </S.Password>

                <S.ListRulesContainer>
                  <S.PasswordRequirementsLabel>Requisitos de senha:</S.PasswordRequirementsLabel>
                  <S.ListRules>
                    <S.ListItemRules>
                      <UilCheckCircle size={20} color={values.newPassword?.length >= 8 ? '#29CC5F' : '#666'} />
                      Pelo menos 8 Caracteres
                    </S.ListItemRules>

                    <S.ListItemRules>
                      <UilCheckCircle
                        size={20}
                        color={regexSpecialCharacter.test(values?.newPassword) ? '#29CC5F' : '#666'}
                      />
                      Pelo menos 1 Caractere Especial (Ex.: !@#$%&*)
                    </S.ListItemRules>

                    <S.ListItemRules>
                      <UilCheckCircle size={20} color={regexNumber.test(values?.newPassword) ? '#29CC5F' : '#666'} />
                      Pelo menos 1 Número (0-9)
                    </S.ListItemRules>

                    <S.ListItemRules>
                      <UilCheckCircle
                        size={20}
                        color={regexCapitalLetter.test(values?.newPassword) ? '#29CC5F' : '#666'}
                      />
                      Pelo menos 1 Letras Minúsculas (A-Z)
                    </S.ListItemRules>

                    <S.ListItemRules>
                      <UilCheckCircle size={20} color={regexLowerCase.test(values?.newPassword) ? '#29CC5F' : '#666'} />
                      Pelo menos 1 Letras Minúscula (a-z)
                    </S.ListItemRules>

                    <S.ListItemRules>
                      <UilCheckCircle
                        size={20}
                        color={
                          values.newPassword?.length &&
                          values.confirmNewPassword?.length &&
                          values.newPassword === values.confirmNewPassword
                            ? '#29CC5F'
                            : '#666'
                        }
                      />
                      Senhas coincidem
                    </S.ListItemRules>
                  </S.ListRules>
                </S.ListRulesContainer>

                {loading ? (
                  <ButtonComponent
                    variant="solid"
                    size="large"
                    fullWidth={true}
                    color="unavaible"
                    fontSize="medium"
                    loading={loading}
                    text="Entrar"
                  />
                ) : (
                  <>
                    {values?.oldPassword?.length >= 8 &&
                    values?.newPassword?.length >= 8 &&
                    values?.confirmNewPassword?.length >= 8 &&
                    values?.newPassword === values?.confirmNewPassword &&
                    regexSpecialCharacter.test(values?.newPassword) &&
                    regexNumber.test(values?.newPassword) &&
                    regexCapitalLetter.test(values?.newPassword) &&
                    regexLowerCase.test(values?.newPassword) ? (
                      <ButtonComponent
                        type="submit"
                        variant="solid"
                        size="large"
                        fullWidth={true}
                        fontSize="medium"
                        loading={loading}
                        text="Confirmar"
                      />
                    ) : (
                      <ButtonComponent
                        variant="solid"
                        color="unavaible"
                        size="large"
                        fullWidth={true}
                        fontSize="medium"
                        text="Confirmar"
                      />
                    )}
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </S.Container>
    </ModalComponent>
  )
}
