import { GetLessonByIdQuery } from '~/queries/autogenerate/operations'

export const defaultState: Omit<GetLessonByIdQuery, '__typename'> = {
  lessonMomentById: [
    {
      idLessonMoment: 0,
      momentStatus: {
        coMomentStatus: '',
        txMomentStatus: '',
      },
      lessonTrackGroup: {
        lessonTrack: {
          grade: {
            lesson: [
              {
                idLesson: 0,
                txTitle: '',
                lessonStep: [],
              },
            ],
          },
        },
      },
    },
  ],
}
