import { useNavigate } from 'react-router-dom'

import { UilInfoCircle, UilRocket } from '@iconscout/react-unicons'

import { useNavigation } from '~/_context/Navigation'

import { ButtonComponent, MomentCardComponent, ValidateDataSessionComponent } from '~/components'

import { IMoment } from '../interfaces'
import * as S from './styles'
type MomentTypes = 'planningPendingMoments' | 'plannedMoments' | 'pendingAssessmentsMoments' | 'pendencyMoments'
interface MessageInfo {
  message: string
  description: string
}

export const MomentTabView = ({
  isLoading,
  moments,
  type,
}: {
  isLoading: boolean
  moments: IMoment[]
  type: MomentTypes
}) => {
  const messages: Record<MomentTypes, MessageInfo> = {
    planningPendingMoments: {
      message: 'Você ainda não tem nenhuma aula para planejar',
      description: 'Suas aulas aparecerão aqui depois que você escolher uma das trilhas.',
    },
    plannedMoments: {
      message: 'Você ainda não tem nenhuma aula para realizar e documentar',
      description:
        'Suas aulas para realizar aparecerão aqui depois que você concluir o planejamento delas. Que tal explorar suas trilhas para visualizar as próximas aulas ou definir datas para uma nova trilha?',
    },
    pendingAssessmentsMoments: {
      message: 'Você ainda não tem nenhuma aula para avaliar',
      description:
        'Suas aulas para avaliar aparecerão aqui depois que você concluir a realização e documentação delas. Que tal explorar suas trilhas para visualizar todas as aulas ou definir datas para uma nova trilha?',
    },
    pendencyMoments: {
      message: 'Parabéns! Você não tem nenhuma aula para atualizar',
      description:
        'Suas aulas estão correndo dentro do programado. Que tal explorar suas trilhas para visualizar todas as aulas e se manter em dia?',
    },
  }

  const labelSwitch = (type) => {
    return messages[type] || { message: 'null', description: '' }
  }
  const navigate = useNavigate()
  const { handleNavigation } = useNavigation()
  const { message, description } = labelSwitch(type)

  return (
    <>
      {isLoading ? (
        <>
          {Object.keys(new Array(4).fill(null)).map((_, index: number) => (
            <MomentCardComponent loading={isLoading} key={'momentCard' + index} />
          ))}
        </>
      ) : (
        <>
          {moments?.length === 0 ? (
            <S.ContentEmoji>
              <ValidateDataSessionComponent
                height={50}
                screen="home"
                title={message}
                description={description}
                space={-50}
                action={() => navigate(`/classes`, { state: { from: '/home' } })}
                textAction={'Escolher Trilha'}
              />
            </S.ContentEmoji>
          ) : (
            <>
              {moments
                ?.sort((a, b) => {
                  const dateA = new Date(a?.dtSchedule ?? '').getTime()
                  const dateB = new Date(b?.dtSchedule ?? '').getTime()

                  return dateA - dateB
                })
                .map((moment) => (
                  <MomentCardComponent
                    totalClass={Number(moment?.nuTrackStages)}
                    key={`${moment?.idMoment}-${moment?.class?.idClass}`}
                    momentTime={moment?.dtSchedule}
                    momentStatusCode={moment?.coMomentStatus as string}
                    momentStatusText={moment?.txMomentStatus as string}
                    nuOrder={moment?.nuOrder}
                    txTrackTitle={moment?.txTrackTitle}
                    idClass={moment?.class?.idClass}
                    idMomentTrack={moment?.idMomentTrack}
                    coGrade={moment?.class?.schoolGrade?.grade?.coGrade}
                    coStage={moment?.class?.schoolGrade?.grade?.coStage}
                    momentTitle={moment?.txTitle as string}
                    momentClassInfo={moment?.class}
                    action={() =>
                      handleNavigation(
                        moment?.class?.schoolGrade?.grade?.coStage,
                        moment?.idMoment,
                        Number(moment?.class?.idClass),
                        '/home',
                      )
                    }
                  />
                ))}
              <S.CardAlert>
                <S.ContentTrail>
                  <UilInfoCircle size={24} color="#7C8189" />
                  <S.TextAlert> Para ver suas aulas finalizadas, visite suas trilhas</S.TextAlert>
                </S.ContentTrail>

                <S.ContentTrail>
                  <ButtonComponent
                    variant={'outline'}
                    size={'xsmall'}
                    title={'Trilhas'}
                    fontWeight="normal"
                    text={'Trilhas  '}
                    data-testid="visit-trails-button"
                    onClick={() => navigate(`/classes`, { state: { from: '/home' } })}
                    iconStart={<UilRocket size={16} />}
                  />
                </S.ContentTrail>
              </S.CardAlert>
            </>
          )}
        </>
      )}
    </>
  )
}
