import { Backdrop, IconButton } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const ContentBack = styled.div`
  padding: 24px 24px 0;
  display: flex;
  justify-content: space-between;
`
export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const ContentSkeleton = styled.div`
  padding: 24px 24px;
  gap: 10px;
`

export const Loading = styledMUI(Backdrop)(() => ({}))

export const Content = styled.div``

export const ContentDescription = styled.div`
  padding: 24px;
  display: grid;
  gap: 16px;
`

export const ContentSteps = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`

export const ContentNavigation = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`
export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: '1px solid #0095ff',
  backgroundColor: '#fff',
  padding: 0,
}))
