import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const CardStudent = styledMUI(Box)<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: `${selected ? '#E8F6FF' : 'none'}`,
  opacity: `${selected ? '0.5' : '1.0'}`,
  borderRadius: '8px',
  width: '122px',
  cursor: 'grab',
  mixBlendMode: 'multiply',
  height: 'auto',
  padding: '7px 0px 3px 0px',
  border: `${selected ? '2px solid #0095FF' : 'none'}`,
  transition: 'background 0.5s ease, opacity 0.5s ease, border 0.5s ease, transform 0.5s ease',
  '@media (max-width: 600px)': {
    textAlign: 'center',
    minWidth: '60px',
    padding: '8px 4px',
    gap: '4px',
    mixBlendMode: 'multiply',
    transition: 'background 0.5s ease, opacity 0.5s ease, border 0.5s ease, transform 0.5s ease',
  },
}))

export const CardStudentDrag = styledMUI(Box)<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'grabbing',
  justifyContent: 'center',
  opacity: `${selected ? '50%' : '100%'}`,
  borderRadius: '8px',
  width: '122px',
  height: 'auto',
  padding: '7px 0px 3px 0px',
  zIndex: '999',
  mixBlendMode: 'multiply',
  border: `${selected && '2px solid #0095FF'}`,
  '@media (max-width: 600px)': {
    textAlign: 'center',
    minWidth: '60px',
    padding: '8px 4px',
    mixBlendMode: 'multiply',
    gap: '4px',
  },
}))

export const TextStudent = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '150%',
  color: '#000',
}))
