import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const NavContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '94px 24px 20px 24px',
  marginTop: '-70px',
  backgroundColor: '#fff',
  zIndex: 100,
  '@media (max-width: 600px)': {
    width: '100%',
    marginTop: 0,
    top: 0,
    left: 0,
    padding: '24px 24px',
  },
}))

export const HeaderLoading = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}))

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '60px',
}))

export const ContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  borderBottom: '1px solid #e2e5ec',
}))

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '0',
  background: '#F9FAFC',
}))

export const Content = styledMUI(Box)(() => ({
  height: 'auto',
  width: '40%',
  background: '#fff',
}))

export const Title = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '6px',
}))

export const TitleSpan = styledMUI(Typography)(() => ({
  color: '#E14983',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
}))

export const TitleSpanGrey = styledMUI(Typography)(() => ({
  color: '#7C8189',
  fontSize: '32px',
  fontWeight: 400,
  lineHeight: '120%',
}))

export const SkillDetails = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  padding: '24px',
  cursor: 'pointer',
}))

export const SkillSpan = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
}))

export const ContentDescription = styledMUI(Box)(() => ({
  padding: '24px',
}))

export const DescriptionCardList = styledMUI(Typography)(() => ({
  color: '#666666',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  marginTop: '1rem',
  padding: '16px 24px 0',
}))
