import { styled as styledMUI } from '@mui/material/styles'

interface ItemProps {
  x: number
  y: number
  centerX: number
  centerY: number
}

export const Layer = styledMUI('div')(() => ({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}))

export const CustomDiv = styledMUI('div')(() => ({
  position: 'fixed',
  left: '5%',
  top: '70px',
  zIndex: 99999,
}))

export const Item = styledMUI('div')<ItemProps>(({ x, y, centerX, centerY }) => ({
  pointerEvents: 'none',
  transform: `translate(${x + centerX}px, ${y + centerY}px)`,
  WebkitTransform: `translate(${x + centerX}px, ${y + centerY}px)`,
}))
