import ReactGA from 'react-ga4'

const formLogin = () => ReactGA.event('form-login', { method: 'input-login' })

const authLogin = () => ReactGA.event('auth-third-party', { method: 'auth-third-party' })

const newTrailButton = () =>
  ReactGA.event('Botão: Gerenciar Trilhas (HOME)', {
    method: 'new-trail-button',
    page: 'home',
  })

const trailButton = () => ReactGA.event('Navegador: Trilhas', { method: 'trail-button' })

const selectTrailButton = () =>
  ReactGA.event('Botão: Selecionar Trilha (TRILHAS)', {
    method: 'select-trail-button',
    page: 'Listagem de trilhas',
  })

const selectTrailHeaderButton = () =>
  ReactGA.event('Botão de cabeçalho: Selecionar Trilha (TRILHAS)', {
    method: 'select-trail-header-button',
    page: 'Listagem de trilhas',
  })

const seeOnlyMyTrailsSwitch = () =>
  ReactGA.event('Switch: Ver somente minhas trilhas (TRILHAS)', {
    method: 'see-only-my-trails',
    page: 'Listagem de trilhas',
  })

const seeMoreTrailButton = () =>
  ReactGA.event('Botão: Ver mais detalhes (TRILHAS DISPONIVEIS)', {
    method: 'see-more-trail-button',
    page: 'trilhas disponíveis',
  })
const selectNewTrailAvailableButton = () =>
  ReactGA.event('Botão: Selecionar Trilha (TRILHAS DISPONIVEIS)', {
    method: 'select-new-trail-button',
    page: 'trilhas disponíveis',
  })

const bnccButton = () => ReactGA.event('Botão: integração com o currículo (NOVA TRILHA)', { method: 'bncc-button' })

const selectNewTrailButton = () =>
  ReactGA.event('Botão: Selecionar Trilha (NOVA TRILHA)', {
    method: 'select-new-trail-button',
    page: 'nova trilha',
  })

const inputCalendar = () =>
  ReactGA.event('Input de data (Modal de agendamento)', {
    method: 'input-calendar',
    page: 'modal de trilha',
  })
const saveNewTrailButton = () =>
  ReactGA.event('Botão: Salvar (Modal de agendamento)', {
    method: 'input-calendar',
    page: 'modal de agendamento',
  })

const backNavigationButton = () =>
  ReactGA.event('Botão: Voltar (TRILHA GERADA)', {
    method: 'back-navigation-button',
    page: 'Trilha gerada',
  })

const accessLessonButton = () =>
  ReactGA.event('Botão: Acessar aula (TRILHA GERADA)', {
    method: 'acess-lesson-button',
    page: 'Trilha gerada',
  })

const openFloaterDocumentationButton = () =>
  ReactGA.event('Botão: Documentar (FLUTUANTE)', {
    method: 'acess-documentation-floater-button',
    page: 'all',
  })

const gaEvents = {
  eventFormLogin: formLogin,
  eventAuthLogin: authLogin,
  eventOpenFloaterDocumentationButton: openFloaterDocumentationButton,
  eventNewTrailButton: newTrailButton,
  eventTrailButton: trailButton,
  eventSelectTrailButton: selectTrailButton,
  eventSelectTrailHeaderButton: selectTrailHeaderButton,
  eventSeeMoreTrailButton: seeMoreTrailButton,
  eventSelectNewTrailAvailableButton: selectNewTrailAvailableButton,
  eventBnccButton: bnccButton,
  eventSelectNewTrailButton: selectNewTrailButton,
  eventInputCalendar: inputCalendar,
  eventSaveNewTrailButton: saveNewTrailButton,
  eventSeeOnlyMyTrailsSwitch: seeOnlyMyTrailsSwitch,
  eventBackNavigationButton: backNavigationButton,
  eventAccessLessonButton: accessLessonButton,
}

export { gaEvents }
