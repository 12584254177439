import { useParams } from 'react-router-dom'

import { UilArrowDown } from '@iconscout/react-unicons'
import { AccordionSummary, CircularProgress } from '@mui/material'

import { useGetStudentsGroupLesson } from '~/services/Students'

import { AssessmentComponent } from '~/components'

import { useAssessmentPageContext } from '../context'
import * as S from '../styles'

interface ITabThirdStep {
  lessonStep: any
  status: string
  handleAssessmentBatch: any
  isClassInStatus: boolean
  handleAssessment(id: string, coAssessment: string, idEvidence: string): void
}

export const TabSecondStep = ({
  lessonStep,
  handleAssessment,
  status,
  handleAssessmentBatch,
  isClassInStatus,
}: ITabThirdStep) => {
  const { idLessonMoment } = useParams()
  const { isSameUser } = useAssessmentPageContext()

  const isFinish = status === 'FINA'
  const { data: dataStudentsGroup, isFetching } = useGetStudentsGroupLesson(Number(idLessonMoment), Number(2))

  if (!dataStudentsGroup || isFetching) {
    return (
      <S.Loading sx={{ color: '#fff', zIndex: 999999 }} open>
        <CircularProgress color="inherit" />
      </S.Loading>
    )
  }

  return (
    <>
      {lessonStep?.lessonActivity.map((activity: any) => (
        <S.ListAccordions key={activity.idLessonActivity}>
          <S.ContentAccordion>
            <AccordionSummary
              expandIcon={
                <S.CircleIcon>
                  <UilArrowDown />
                </S.CircleIcon>
              }
            >
              <S.TextLabelAccordion>
                {activity.category?.txName}- <b>{activity.txTitle}</b>
              </S.TextLabelAccordion>
            </AccordionSummary>

            <S.AssessmentContainer>
              {activity.lessonActivityEvidence.map((evidence: any, index: number) => (
                <AssessmentComponent
                  disabled={isFinish || !isSameUser || !isClassInStatus}
                  key={evidence.evidenceGrade?.idEvidence}
                  idEvidence={evidence.evidenceGrade?.idEvidence}
                  handleDrop={handleAssessment}
                  handleDropBatch={handleAssessmentBatch}
                  txEvidenceName={evidence?.evidenceGrade?.evidence?.ability?.txName as string}
                  txEvidenceDescription={evidence?.evidenceGrade?.evidence?.txName as string}
                  txCompentencyName={evidence?.evidenceGrade?.evidence?.ability?.competence?.txName as string}
                  txIconSkill={evidence?.evidenceGrade?.evidence?.ability?.txImagePath as string}
                  txIconCompetency={evidence?.evidenceGrade?.evidence?.ability?.competence?.txImagePath as string}
                  students={dataStudentsGroup}
                  idActivity={activity.lessonMomentActivity?.[0]?.idLessonMomentActivity as number}
                  type={'lesson'}
                  skillType={evidence.coEvidenceType}
                  indexEvidence={index + 1}
                  lengthEvidence={activity.lessonActivityEvidence.length}
                />
              ))}
            </S.AssessmentContainer>
          </S.ContentAccordion>
        </S.ListAccordions>
      ))}
    </>
  )
}
