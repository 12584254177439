import Dexie, { Table } from 'dexie'

import {
  LessonMomentActivity4,
  LessonMomentGroup2,
  LessonMomentsBncc,
  LessonMomentsByTrack,
  LessonMomentsDetail,
  LessonStep2,
  LessonStepTwoGroup,
  Moment,
  ProjectMomentGroup,
  ProjectMomentsDetail,
  ProjectTrack,
  ProjectTracksDetail,
} from '~/services/Offline/interfaces'

export interface Lesson {
  id?: number
  moments: Moment[]
  lessonMomentsDetails: LessonMomentsDetail[]
  lessonMomentGroups: LessonMomentGroup2[]
  lessonSteps: LessonStep2[]
  lessonStepTwoGroups: LessonStepTwoGroup[]
  lessonMomentsByTrack: LessonMomentsByTrack[]
  lessonMomentsBncc: LessonMomentsBncc[]
  projectMomentGroups: ProjectMomentGroup[]
  projectMomentsDetails: ProjectMomentsDetail[]
  projectTracks: ProjectTrack[]
  projectTracksDetails: ProjectTracksDetail[]
  lessonMomentActivities: LessonMomentActivity4[]
}

export class OfflineDatabase extends Dexie {
  lessons!: Table<Lesson>

  constructor() {
    super('offline')
    this.version(1).stores({
      lessons:
        '++id, moments, lessonMomentsDetails, lessonMomentGroups, lessonSteps, lessonStepTwoGroups, lessonMomentsByTrack, lessonMomentsBncc',
    })
  }
}

export const dbWeb = new OfflineDatabase()
