import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useGetDocumentation } from '~/services/Documentation'
import { useServiceGetLesson } from '~/services/Lesson'
import { useGetProjectInformation } from '~/services/Project'

import { IDocumentationsPageContextProps, IDocumentationsPageProviderProps } from './interfaces'

const DocumentationsPageContext = createContext<IDocumentationsPageContextProps>({
  isLoadingProject: false,
  dataProject: undefined,
  documentations: [],
  hasDocumentation: false,
  status: '',
  projectMoment: 0,
  isLoadingProjectDocumentation: false,
  isSameUser: false,
  classStudentsCount: 0,
  isClassInStatus: true,
})

const DocumentationsPageProvider = ({ children }: IDocumentationsPageProviderProps) => {
  const { projectId, idClass, idLessonMoment, idLesson } = useParams()
  const { user } = useAuth()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(4)
  const [hasNext, setHasNext] = useState(false)

  function useProjectOrLessonData(projectId: string, idLessonMoment: string) {
    const projectData = useGetProjectInformation(Number(projectId), { enabled: !!projectId })
    const lessonData = useServiceGetLesson(Number(idLessonMoment), { enabled: !!idLessonMoment })

    return projectId ? projectData : lessonData
  }

  const { data: dataProject, isLoading: isLoadingProject } = useProjectOrLessonData(
    projectId as string,
    idLessonMoment as string,
  )

  const {
    data: dataProjectDocumentation,
    isLoading: isLoadingProjectDocumentation,
    refetch: refetchDataProjectDocumentation,
    remove: removeDataProjectDocumentation,
  } = useGetDocumentation(Number(idClass), projectId ? Number(projectId) : Number(idLessonMoment), page + 1, pageSize)

  useEffect(() => {
    removeDataProjectDocumentation()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const valueHasNext = dataProjectDocumentation?.headers['x-pagination']?.length
      ? JSON.parse(dataProjectDocumentation?.headers['x-pagination'])?.hasNext
      : ''
    setHasNext(valueHasNext)
    setPageSize(valueHasNext ? pageSize + 2 : pageSize)
  }, [dataProjectDocumentation, dataProjectDocumentation?.headers, page, pageSize])

  const idUserProfessor = dataProject?.idUserProfessor

  const isSameUser = user?.id_user === idUserProfessor

  const isClassInStatus = dataProject?.class?.inStatus

  const hasDocumentation = dataProjectDocumentation && dataProjectDocumentation?.data?.length > 0

  const documentations = dataProjectDocumentation?.data

  const status = dataProject?.momentStatus?.coMomentStatus

  const fetchMoreData = useCallback(() => {
    setPage(page + 1)
    refetchDataProjectDocumentation().then()
  }, [page, setPage, refetchDataProjectDocumentation])

  const documentationsPageProviderValues = useMemo(() => {
    return {
      isLoadingProject,
      dataProject,
      idLesson,
      isLoadingProjectDocumentation,
      hasDocumentation,
      documentations,
      status,
      isSameUser,
      fetchMoreData,
      refetchDataProjectDocumentation,
      hasNext,
      isClassInStatus,
    }
  }, [
    isLoadingProjectDocumentation,
    isSameUser,
    idLesson,
    hasDocumentation,
    documentations,
    status,
    isLoadingProject,
    dataProject,
    fetchMoreData,
    refetchDataProjectDocumentation,
    hasNext,
    isClassInStatus,
  ])

  return (
    <DocumentationsPageContext.Provider value={documentationsPageProviderValues}>
      {children}
    </DocumentationsPageContext.Provider>
  )
}

const useDocumentationsPageContext = () => useContext(DocumentationsPageContext)

export { DocumentationsPageProvider, useDocumentationsPageContext }
