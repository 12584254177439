import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'

import dayjs from 'dayjs'

import { OtherClassTrack } from '~/services/Track/types'

import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import theme from '~/styles/theme'

import * as S from './TimeLesson.styles'
import { TrailContainer } from './TrailContainer'

interface IDayCell {
  dates: {
    dtSchedule: string
    disabled: boolean
    order: number
  }[]
  day: dayjs.Dayjs
  onReady: () => void
  dayIndex: number
  month: dayjs.Dayjs
  handleDrop: (lessonIndex: number, newDay: dayjs.Dayjs) => void
  visibleMonth: string
  visibleMonthPlus: string
  disabledDate: (date: dayjs.Dayjs, order: number) => boolean
  isFirstDayOfNextMonth: boolean
  containsDifferentMonths: boolean
  idTrack: number
  projectType: number | null
  otherClassTracks: OtherClassTrack[]
}

const DayCell = ({
  dates,
  day,
  month,
  handleDrop,
  dayIndex,
  disabledDate,
  visibleMonth,
  visibleMonthPlus,
  isFirstDayOfNextMonth,
  containsDifferentMonths,
  onReady,
  idTrack,
  otherClassTracks,
  projectType,
}: IDayCell) => {
  const [datesState, setDatesState] = useState<
    {
      dtSchedule: string
      disabled: boolean
      order: number
    }[]
  >([])
  const [backgroundColor, setBackgroundColor] = useState(theme.colors.highPure)
  const [isDroppable, setIsDroppable] = useState(true)
  const [isLessonDay, setIsLessonDay] = useState(false)
  const [isFirstLesson, setIsFirstLesson] = useState(false)
  const [isLastLesson, setIsLastLesson] = useState(false)
  const [isTrailDay, setIsTrailDay] = useState(false)
  const [isSameDay, setIsSameDay] = useState(false)
  const [isFirstDayOfMonth, setIsFirstDayOfMonth] = useState(false)
  const [tracksForDay, setTracksForDay] = useState<
    {
      track: OtherClassTrack
      isTrackDay: boolean
      isFirstTrackDay: boolean
      repeatTitleOnSunday: boolean
      isLastTrackDay: boolean
      daysSpan: number
    }[]
  >([])

  useEffect(() => {
    setDatesState(dates)
  }, [dates])

  useEffect(() => {
    setIsLessonDay(datesState.some((date) => day.isSame(dayjs(date.dtSchedule), 'day')))
    setIsFirstLesson(datesState.length > 0 && day.isSame(dayjs(datesState[0].dtSchedule), 'day'))
    setIsLastLesson(datesState.length > 0 && day.isSame(dayjs(datesState[datesState.length - 1].dtSchedule), 'day'))
    setIsTrailDay(
      datesState.length > 0 &&
        day.isAfter(dayjs(datesState[0].dtSchedule)) &&
        day.isBefore(dayjs(datesState[datesState.length - 1].dtSchedule)),
    )
    setIsSameDay(day.isSame(dayjs(), 'day'))
    setIsFirstDayOfMonth(day.date() === 1)
  }, [datesState, day, idTrack])

  useEffect(() => {
    if (otherClassTracks) {
      const filteredTracks = otherClassTracks
        .filter((track) => track.idTrack !== idTrack)
        .map((track) => {
          const firstDate = dayjs(track.firstMomentDtSchedule)
          const lastDate = dayjs(track.lastMomentDtSchedule)
          const isTrackDay = day.isAfter(firstDate.subtract(1, 'day')) && day.isBefore(lastDate.add(1, 'day'))
          const isFirstTrackDay = day.isSame(firstDate, 'day')
          const isLastTrackDay = day.isSame(lastDate, 'day')
          const repeatTitleOnSunday = day.day() === 0 && day.isAfter(firstDate) && day.isBefore(lastDate)
          const daysSpan = lastDate.diff(day, 'day') + 1

          return {
            track,
            isTrackDay,
            isFirstTrackDay,
            repeatTitleOnSunday,
            isLastTrackDay,
            daysSpan: Math.max(1, daysSpan),
          }
        })

      setTracksForDay(filteredTracks)
      onReady()
    }
  }, [day, idTrack, onReady, otherClassTracks])

  const [{ isOver }, dropDay] = useDrop(() => ({
    accept: 'CARD',
    drop: (item: { lessonIndex: number }) => {
      const newDay = day
      handleDrop(item.lessonIndex, newDay)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: (item: { lessonIndex: number }) => {
      const isDisabled = disabledDate(day, item.lessonIndex + 1)
      const canDrop = !isDisabled

      setIsDroppable(canDrop)
      return canDrop
    },
  }))

  useEffect(() => {
    if (isOver) {
      setBackgroundColor(isDroppable ? theme.colors.midMedium : 'rgba(242, 123, 108, 0.5)')
    } else {
      setBackgroundColor(theme.colors.highPure)
    }
  }, [isDroppable, isOver, day])

  return (
    <S.Td
      key={dayIndex}
      data-date={day.format('YYYY-MM-DD')}
      className={
        day.isSame(dayjs(), 'day') ? 'current-day' : day.isSame(month, 'month') ? 'current-month' : 'other-month'
      }
      style={{ backgroundColor }}
      ref={dropDay}
    >
      <S.DayContainer>
        {isSameDay && <S.CurrentDayTag>HOJE</S.CurrentDayTag>}
        <p className={isSameDay ? 'today' : ''}>
          {isFirstDayOfMonth && <span className="month-label">{day.locale('pt-br').format('MMM')} </span>}
          {day.date()}
        </p>
      </S.DayContainer>

      <S.DayContainer>
        <p className={isSameDay ? 'today' : ''}>
          {isFirstDayOfNextMonth && !isLessonDay && (
            <>
              <S.TitleYearInside>{containsDifferentMonths ? visibleMonthPlus : visibleMonth}</S.TitleYearInside>
            </>
          )}
        </p>
      </S.DayContainer>

      <TrailContainer
        isLessonDay={isLessonDay}
        projectType={projectType}
        isFirstLesson={isFirstLesson}
        isLastLesson={isLastLesson}
        isTrailDay={isTrailDay}
        dates={datesState}
        day={day}
      />
      {tracksForDay.map((_, index) => (
        <S.OtherClassTrail
          key={tracksForDay[index].track.idTrack}
          isTrackDay={tracksForDay[index].isTrackDay}
          isFirstTrackDay={tracksForDay[index].isFirstTrackDay}
          isLastTrackDay={tracksForDay[index].isLastTrackDay}
        >
          {(tracksForDay[index].isFirstTrackDay || tracksForDay[index].repeatTitleOnSunday) && (
            <S.OtherClassTag daysSpan={tracksForDay[index].daysSpan}>
              <DSTooltip
                title={tracksForDay[index].track.txTrackTitle}
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: theme.colors.lowPure,
                      color: theme.colors.highPure,
                      '& .MuiTooltip-arrow': {
                        color: theme.colors.lowPure,
                      },
                    },
                  },
                }}
              >
                <span>{tracksForDay[index].track.txTrackTitle}</span>
              </DSTooltip>
            </S.OtherClassTag>
          )}
        </S.OtherClassTrail>
      ))}
    </S.Td>
  )
}

function areEqual(prevProps: Readonly<IDayCell>, nextProps: Readonly<IDayCell>) {
  return prevProps.dates === nextProps.dates
}

export default React.memo(DayCell, areEqual)
