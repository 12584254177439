import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { ISecondStepPageContextProps, ISecondStepPageProviderProps } from './interfaces'

const SecondStepPageContext = createContext<ISecondStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  filteredActivityGroup: undefined,
  lessonSteps: undefined,
  secondStepGroup: undefined,
})

const SecondStepPageProvider = ({ children }: ISecondStepPageProviderProps) => {
  const { idLessonMoment, idLesson, idLessonActivity } = useParams()
  const { getOfflineInformation } = useOfflineMode()

  const filteredActivityGroup = getOfflineInformation?.[0].lessonMomentActivities?.find(
    (item) => item.idLessonActivity === Number(idLessonActivity),
  )

  const secondStepGroup = getOfflineInformation?.[0]?.lessonStepTwoGroups.find(
    (item) => item.idLessonMoment === Number(idLessonMoment) && item.idLesson === Number(idLesson),
  )

  const lessonSteps = getOfflineInformation?.[0].lessonSteps.find(
    (item) => item.nuOrder === 2 && item.idLessonMoment === Number(idLessonMoment),
  )

  const [activedTab, setActivedTab] = useState<number>(0)

  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const secondStepPageProviderValues = useMemo(() => {
    return {
      activedTab,
      handleActiveNewTab,
      filteredActivityGroup,
      lessonSteps,
      secondStepGroup,
    }
  }, [secondStepGroup, lessonSteps, filteredActivityGroup, activedTab, handleActiveNewTab])

  return (
    <SecondStepPageContext.Provider value={secondStepPageProviderValues}>{children}</SecondStepPageContext.Provider>
  )
}

const useSecondStepPageContext = () => useContext(SecondStepPageContext)

export { SecondStepPageProvider, useSecondStepPageContext }
