import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilExclamationTriangle, UilTrash } from '@iconscout/react-unicons'
import { useQueryClient } from '@tanstack/react-query'

import { useServiceDeleteDocumentation } from '~/services/Documentation'

import {
  AccordionTextComponent,
  AvatarGroupComponent,
  MediaInformationComponent,
  ModalActionComponent,
  ModalDocumentationComponent,
  SkeletonComponent,
} from '~/components'

import { ICardDocumentationProps } from './CardDocumentation.interfaces'
import * as S from './CardDocumentation.styles'

export const CardDocumentationComponent = ({
  status,
  documentation,
  isLoading,
  queriesKeys,
  isSameUser,
  projectDocumentation,
  disabled,
  isProject,
  refetch,
  isClassInStatus,
}: ICardDocumentationProps) => {
  const { idLessonMoment, idLesson, idClass, projectId } = useParams()
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const [showSubmittedModal, setShowSubmittedModal] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const [errorOnDelete, setErrorOnDelete] = useState<boolean>(false)
  const { mutate: handleDelete } = useServiceDeleteDocumentation({
    onSuccess: () => (
      refetch().then(),
      queryClient.invalidateQueries(queriesKeys),
      setShowSubmittedModal(true),
      setShowCancelModal(false)
    ),
    onError: () => (setErrorOnDelete(true), setShowCancelModal(false)),
  })

  const handleRemoveDocumentation = () => {
    setShowCancelModal(true)
  }

  const handleCancelDocumentation = () => {
    setShowCancelModal(false)
  }
  const handleFinishDelete = () => {
    setShowSubmittedModal(false)
    setErrorOnDelete(false)
  }

  const handleConfirmDelete = async () => {
    if (!isProject) {
      await handleDelete({
        idClass: Number(idClass),
        idMoment: Number(idLessonMoment),
        idMomentDocumentation: Number(documentation?.idMomentDocumentation),
      })
    } else {
      await handleDelete({
        idClass: Number(idClass),
        idMoment: Number(projectId),
        idMomentDocumentation: Number(projectDocumentation?.idMomentDocumentation),
      })
    }
    return
  }

  const renderTitleLabel = () => {
    if (documentation?.lessonActivity) {
      return `Passo 2 - ${documentation.lessonActivity?.txTitle}`
    }
    if (documentation?.lessonStep) {
      if (documentation.lessonStep.nuOrder === 1) {
        return `Passo ${documentation.lessonStep.nuOrder} - Projetar`
      }
      return `Passo ${documentation.lessonStep.nuOrder} - Revisar`
    }
  }

  const handleDataToEditDocumentation = () => {
    if (documentation?.lessonActivity?.idLessonActivity) {
      return {
        txMomentNotes: documentation?.txMomentNotes,
        txTitle: documentation?.lessonActivity?.txTitle,
        idLessonStep: documentation?.lessonActivity?.idLessonStep,
        idLessonActivity: documentation?.lessonActivity?.idLessonActivity,
        evidenceIds: documentation?.momentEvidenceDocumentation?.map((ev) => ev.idEvidence),
        studentId: documentation?.momentStudentDocumentation,
        documentFiles: documentation?.momentDocumentationMedia?.map((m) => ({
          idMediaInformation: m.idMediaInformation,
          type: m.txContentType,
          webkitRelativePath: m.txAbsoluteUrl,
          name: m.txName,
          size: m.nuSize,
        })),
        idLessonDocumentation: documentation?.idMomentDocumentation,
      }
    } else {
      return {
        txMomentNotes: documentation?.txMomentNotes,
        idLessonStep: documentation?.lessonStep?.idLessonStep,
        evidenceIds: documentation?.momentEvidenceDocumentation?.map((ev) => ev.idEvidence),
        studentId: documentation?.momentStudentDocumentation,
        documentFiles: documentation?.momentDocumentationMedia?.map((m) => ({
          idMediaInformation: m.idMediaInformation,
          type: m.txContentType,
          webkitRelativePath: m.txAbsoluteUrl,
          name: m.txName,
          size: m.nuSize,
        })),
        idLessonDocumentation: documentation?.idMomentDocumentation,
      }
    }
  }

  const {
    idMomentDocumentation,
    txMomentNotes,
    momentEvidenceDocumentation,
    momentStudentDocumentation,
    projectStage,
    momentDocumentationMedia,
  } = projectDocumentation || {}
  const dataToEditDocumentation = {
    idProjectStage: projectStage?.idProjectStage,
    evidenceIds: momentEvidenceDocumentation?.map((ev) => ev.idEvidence),
    txMomentNotes: txMomentNotes,
    idProjectDocumentation: idMomentDocumentation,
    projectStudentDocumentationList: momentStudentDocumentation,
    documentFiles: momentDocumentationMedia?.map((m) => ({
      idMediaInformation: m.idMediaInformation,
      type: m.txContentType,
      webkitRelativePath: m.txAbsoluteUrl,
      name: m.txName,
      size: m.nuSize,
    })),
  }

  return (
    <S.Container>
      <S.CardHeader>
        {!isProject && (
          <S.WrapperChip>
            {isLoading ? (
              <SkeletonComponent variant="rounded" width={130} />
            ) : (
              <>
                {documentation?.lessonStep ||
                documentation?.lessonActivity ||
                documentation?.lessonActivity?.idLessonActivity ? (
                  <S.Chip variant="primary">{renderTitleLabel()}</S.Chip>
                ) : (
                  <S.Chip variant="light">Turma inteira</S.Chip>
                )}
              </>
            )}
          </S.WrapperChip>
        )}
        {isProject && (
          <S.WrapperChip>
            {isLoading ? (
              <SkeletonComponent variant="rounded" width={130} />
            ) : projectStage ? (
              <S.Chip variant="primary">{projectStage?.txProjectTitle}</S.Chip>
            ) : (
              <S.Chip variant="light">Turma inteira</S.Chip>
            )}
          </S.WrapperChip>
        )}

        <S.WrapperEditIcons>
          {!isProject ? (
            <S.EditIcons>
              {status !== 'FINA' && isSameUser && (
                <>
                  <ModalDocumentationComponent
                    variant={'icon'}
                    queryKeys={queriesKeys}
                    type={'edit'}
                    values={handleDataToEditDocumentation()}
                    idLesson={Number(idLesson)}
                    idMoment={Number(idLessonMoment)}
                    isClassInStatus={isClassInStatus}
                  />
                  <UilTrash size={18} onClick={handleRemoveDocumentation} />
                </>
              )}
            </S.EditIcons>
          ) : (
            <S.EditIcons>
              {status !== 'FINA' && !disabled && (
                <>
                  <ModalDocumentationComponent
                    values={dataToEditDocumentation}
                    type={'edit'}
                    variant={'icon'}
                    idProject={Number(projectId)}
                    idMoment={Number(projectId)}
                    isClassInStatus={isClassInStatus}
                  />
                  <UilTrash size={18} onClick={handleRemoveDocumentation} />
                </>
              )}
            </S.EditIcons>
          )}
        </S.WrapperEditIcons>
      </S.CardHeader>

      {!isProject && (
        <>
          <S.AbilitydHeader>
            {isLoading && (documentation?.momentStudentDocumentation?.length ?? 0) > 0 ? (
              <SkeletonComponent variant="circular" width={30} />
            ) : (
              <AvatarGroupComponent
                images={documentation?.momentStudentDocumentation?.map((student) => {
                  return {
                    txName: student?.txName as string,
                    txImagePath: student?.txImagePath as string,
                  }
                })}
                max={4}
              />
            )}
          </S.AbilitydHeader>
          {(documentation?.momentEvidenceDocumentation?.length ?? 0) > 0 && (
            <S.AbilitydHeader>
              {documentation?.momentEvidenceDocumentation?.map((ev: any, index: number) => (
                <S.Chip variant="light" key={index}>
                  {ev.txAbilityName}
                </S.Chip>
              ))}
            </S.AbilitydHeader>
          )}
          {documentation?.momentDocumentationMedia && (
            <MediaInformationComponent
              medias={documentation?.momentDocumentationMedia}
              txMomentNotes={documentation?.txMomentNotes}
            />
          )}
          {isLoading ? (
            <SkeletonComponent variant="rounded" width={130} />
          ) : (
            <S.CardContent>
              <AccordionTextComponent limit={120} paragraph={documentation?.txMomentNotes as string} />
            </S.CardContent>
          )}
        </>
      )}

      {isProject && (
        <>
          <S.AbilitydHeader>
            {isLoading && (momentEvidenceDocumentation?.length ?? 0) > 0 ? (
              <SkeletonComponent variant="circular" width={30} />
            ) : (
              <AvatarGroupComponent
                images={momentStudentDocumentation?.map((student: any) => {
                  return {
                    txName: student?.txName as string,
                    txImagePath: student?.txImagePath as string,
                  }
                })}
                max={4}
              />
            )}
            {(momentEvidenceDocumentation?.length ?? 0) > 0 && (
              <S.EvidenceChips>
                {momentEvidenceDocumentation?.map((evidence) => (
                  <S.Chip key={evidence.idEvidenceDocumentation} variant="light">
                    {evidence.txAbilityName}
                  </S.Chip>
                ))}
              </S.EvidenceChips>
            )}
          </S.AbilitydHeader>

          {momentDocumentationMedia && (
            <MediaInformationComponent medias={momentDocumentationMedia} txMomentNotes={txMomentNotes} />
          )}

          {isLoading ? (
            <SkeletonComponent variant="rounded" width={130} />
          ) : (
            <S.CardContent>
              <AccordionTextComponent limit={120} paragraph={txMomentNotes || '...'} />
            </S.CardContent>
          )}
        </>
      )}

      <ModalActionComponent
        actionCofirm={handleConfirmDelete}
        title="Tem certeza que deseja excluir a documentação?"
        open={showCancelModal}
        description=""
        actionCloseModal={handleCancelDocumentation}
        confirmCancelText="Excluir Documentação"
        returnBtnText="Voltar"
        infoWidth={700}
        isFullModal={true}
      />

      <ModalActionComponent
        open={showSubmittedModal}
        title="Documentação excluída com sucesso"
        actionCloseModal={handleFinishDelete}
        icon={<UilTrash />}
        infoWidth={700}
        isFullModal={false}
      />

      <ModalActionComponent
        open={errorOnDelete}
        title="Erro ao excluir documentação"
        actionCloseModal={handleFinishDelete}
        icon={<UilExclamationTriangle />}
        infoWidth={700}
        isFullModal={false}
      />
    </S.Container>
  )
}
