/* eslint-disable no-inline-comments */
import theme from '~/styles/theme'

import { IChipTypeProps, IColorsTypeProps } from './Chip.interfaces'

export const colorsTypes = (type: string) => {
  const colorType: IColorsTypeProps = {
    primary: theme.colors.highPure,
    light: theme.colors.actionPure,
    danger: theme.colors.negativePure,
    disabled: theme.colors.midDark,
    play: theme.colors.positivePure,
    gray: theme.colors.midDark,
    warning: theme.colors.warningPure,
    toDo: theme.colors.primaryPure,
    normal: theme.colors.actionPure,
    cancel: theme.colors.lowLight,
  }

  return colorType[type as keyof IColorsTypeProps]
}

export const bgColorsTypes = (type: string) => {
  const colorType: IColorsTypeProps = {
    primary: theme.colors.actionPure,
    light: theme.colors.actionLight,
    danger: theme.colors.negativeLight,
    disabled: theme.colors.highMedium,
    play: theme.colors.positiveLight,
    warning: theme.colors.warningLight,
    gray: theme.colors.highPure,
    toDo: theme.colors.primaryLight,
    normal: theme.colors.highPure,
    cancel: theme.colors.highLight,
  }

  return colorType[type as keyof IColorsTypeProps]
}
export const colorChip = (type: string) => {
  const chipType: IChipTypeProps = {
    AGUA: 'toDo',
    INIC: 'toDo',
    PEND: 'light', //Para Planejar
    AGEN: 'disabled', //Agendada
    AUPL: 'toDo', //Para Realizar e Documentar
    AUPE: 'danger', //Para Atualizar
    AVPE: 'warning', //Para Avaliar
    FINA: 'play', //Finalizada
    CANC: 'danger', //Cancerlada
  }
  return chipType[type as keyof IChipTypeProps] || type
}
