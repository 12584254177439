import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Container = styledMUI(Box)<{ height?: number }>(({ height }) => ({
  height: `${height}vh`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    height: 'initial',
  },
}))

export const Content = styledMUI(Box)<{ space?: number }>(({ space }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: `${space}px`,
  '@media (max-width: 600px)': {
    height: 'initial',
    marginTop: 0,
    width: '80%',
    padding: '0 32px',
    textAlign: 'center',
  },
}))

export const TextEmoji = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontSize: '30px',
  lineHeight: '120%',
  whiteSpace: 'nowrap',
  color: '#000',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  whiteSpace: 'nowrap',
  color: '#000',
  marginTop: '8px',
  '@media (max-width: 600px)': {
    whiteSpace: 'initial',
  },
}))

export const Description = styledMUI(Typography)(() => ({
  maxWidth: '100%',
  fontStyle: 'normal',
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
  margin: '8px 0 24px',
}))
