import { DropdownSkillComponent } from '~/components'

import { IHeaderSkillComponentProps } from './HeaderSkill.interfaces'
import * as S from './HeaderSkill.styles'

export const HeaderSkillComponent = ({
  txChallengeProject,
  txChallengeStage,
  variableSkill,
  fixedSkill,
}: IHeaderSkillComponentProps) => {
  return (
    <S.Content>
      {txChallengeProject && (
        <S.Wrapper>
          <S.TextChallenge>
            <span>Desafio do projeto -</span> {txChallengeProject}
          </S.TextChallenge>
        </S.Wrapper>
      )}
      {variableSkill && (
        <S.Wrapper>
          <S.TextLabel color="#000" variant="caption">
            Habilidade Variável
          </S.TextLabel>

          <DropdownSkillComponent
            icon={variableSkill.icon}
            iconCompetency={variableSkill.txImageCompetence}
            iconSkill={variableSkill.txImageSkill}
            txSkillName={variableSkill.txSkillName}
            txSkillDescription={variableSkill.txSkillDescription}
            txBncc={variableSkill.txBncc}
            txCompetency={variableSkill.txCompetency}
          />
        </S.Wrapper>
      )}

      {fixedSkill && (
        <S.Wrapper>
          <S.TextLabel color="#000" variant="caption">
            Habilidade Fixa
          </S.TextLabel>

          <DropdownSkillComponent
            icon={fixedSkill.icon}
            iconCompetency={fixedSkill.txImageCompetence}
            iconSkill={fixedSkill.txImageSkill}
            txSkillName={fixedSkill.txSkillName}
            txSkillDescription={fixedSkill.txSkillDescription}
            txBncc={fixedSkill.txBncc}
            txCompetency={fixedSkill.txCompetency}
          />
        </S.Wrapper>
      )}
      {txChallengeStage && (
        <S.Wrapper>
          <S.TextChallenge>
            <span>Desafio da etapa -</span> {txChallengeStage}
          </S.TextChallenge>
        </S.Wrapper>
      )}
    </S.Content>
  )
}
