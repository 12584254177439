import { useEffect, useState } from 'react'

import { CircularProgress } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import {
  AccordionOrientationsComponent,
  AppBarComponent,
  AssessmentComponent,
  CardDocumentationComponent,
  ContainerComponent,
  ModalDocumentationComponent,
  ModalStudentManagementComponent,
  QuickNavigationComponent,
  ValidateDataSessionComponent,
} from '~/components'
import { AlertAssessment } from '~/components/Forms/Assessment/Assessment.styles'

import { HeaderComponent } from '~/containers/private/Fund1/FirstStep'

import { useFirstStepPageContext } from './context'
import * as S from './styles'

export const FirstStepView = () => {
  const {
    activedTab,
    handleActiveNewTab,
    isLoading,
    data,
    idClass,
    isLoadingDocumentation,
    dataDocumentationFiltered,
    isSameUser,
    mutateAssessment,
    mutateAssessmentBatch,
    idLessonMoment,
    idLesson,
    dataStudents,
    isLoadingManageStudents,
    isLoadingManageStudentsBatch,
    dataModal,
    refetch,
    dataStudentsL2,
    refetchL2,
    isClassInStatus,
  } = useFirstStepPageContext()

  const queryClient = useQueryClient()
  const [showModalStudentManagement, setShowModalStudentManagement] = useState(false)

  const handleCloseModalStudentManagement = () => setShowModalStudentManagement(false)

  const handleOpenModalStudentManagement = () => {
    if (dataModal) {
      setShowModalStudentManagement(true)
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch, activedTab, queryClient])

  const status = data.momentStatus.coMomentStatus
  const nuDuration = data.nuDuration
  const evidence = data.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence
  const idLessonMomentActivity = data?.lessonActivity
    ? data?.lessonActivity[0]?.lessonMomentActivity?.[0]?.idLessonMomentActivity
    : null

  const lessonOrientation = data?.lessonActivity ? data?.lessonActivity[0]?.lessonActivityOrientation : null
  const isFinish = status === 'FINA'

  const handleAssessment = (id: string, coAssessment: string) => {
    if (evidence) {
      mutateAssessment({
        body: {
          idMomentGroup: id,
          coAssessment,
          idEvidence: evidence?.idEvidence,
        },
        idLessonMoment: String(idLessonMoment),
        idClass: String(idClass),
      })
    }
  }

  const handleAssessmentBatch = (body) => {
    if (evidence) {
      body.idLessonMoment = String(idLessonMoment)
      body.idClass = String(idClass)
      mutateAssessmentBatch(body)
    }
  }

  return (
    <>
      {isLoadingManageStudents ||
        (isLoadingManageStudentsBatch && (
          <AlertAssessment
            iconMapping={{
              info: <CircularProgress color="inherit" size={16} />,
            }}
            severity={'info'}
          >
            Estamos processando a sua avaliação.
          </AlertAssessment>
        ))}
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            stepActived="firstStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            status={status}
          />
        </S.NavContainer>

        <HeaderComponent
          loading={isLoading}
          duration={nuDuration}
          activedTab={activedTab}
          avatars={dataStudents.group}
          actionSetActiveTab={(newActivedTab: number) => handleActiveNewTab(newActivedTab)}
          evidence={evidence}
          status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
          openModal={handleOpenModalStudentManagement}
          title={dataModal?.lesson?.lessonStep[0].txTitle ? dataModal?.lesson?.lessonStep[0].txTitle : 'Projetar'}
        />

        <S.Content>
          {activedTab === 0 && lessonOrientation && (
            <S.ContentOrientations component="ul">
              {lessonOrientation?.map((orientation, index: number) => {
                return (
                  <AccordionOrientationsComponent
                    key={index}
                    type="firstStep"
                    title={orientation.txTitle}
                    txOrientationCode={orientation.txOrientationCode}
                    supportReference={orientation?.lessonActivitySupportReference}
                  />
                )
              })}
            </S.ContentOrientations>
          )}

          {activedTab === 1 &&
            (dataDocumentationFiltered?.length > 0 ? (
              <S.ContentDocumentation component="ul">
                {dataDocumentationFiltered?.map((doc: any, index: number) => (
                  <CardDocumentationComponent
                    queriesKeys={['GetDocumentation']}
                    documentation={doc}
                    isSameUser={isSameUser}
                    status={status}
                    key={index}
                    type="step"
                    isLoading={isLoadingDocumentation}
                    isProject={false}
                    isClassInStatus={isClassInStatus}
                  />
                ))}
              </S.ContentDocumentation>
            ) : (
              <>
                <S.ContentMessage component="ul">
                  <ValidateDataSessionComponent
                    space={0}
                    screen="documentation"
                    height={50}
                    title="Nenhuma documentação cadastrada."
                    description="Até o momento, nenhuma documentação foi cadastrada."
                  />
                </S.ContentMessage>
              </>
            ))}

          {activedTab == 2 && (
            <AssessmentComponent
              disabled={isFinish || !isSameUser || !isClassInStatus}
              handleDrop={handleAssessment}
              handleDropBatch={handleAssessmentBatch}
              txEvidenceName={evidence.ability.txName}
              txEvidenceDescription={evidence.txName}
              txCompentencyName={evidence.ability.competence.txName}
              txIconCompetency={evidence?.ability.competence.txImagePath}
              txIconSkill={evidence?.ability.txImagePath}
              students={dataStudents}
              skillType={'FIX'}
              idEvidence={evidence?.idEvidence?.toString()}
              type={'lesson'}
              idActivity={idLessonMomentActivity}
            />
          )}
          <>
            {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && isSameUser && (
              <ModalDocumentationComponent
                queryKeys={['GetLessonDocumentation']}
                idLessonMoment={idLessonMoment}
                idLesson={idLesson}
                variant="floater"
                isClassInStatus={isClassInStatus}
              />
            )}
          </>
        </S.Content>

        {dataModal && (
          <ModalStudentManagementComponent
            openModal={showModalStudentManagement}
            actionCloseModal={handleCloseModalStudentManagement}
            dataClass={dataModal}
            classID={idClass}
            idLessonMoment={Number(idLessonMoment)}
            type={'F1'}
            dataStudentsGroup={dataStudentsL2}
            refetchStudents={refetch}
          />
        )}
      </ContainerComponent>
    </>
  )
}
