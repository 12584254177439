import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useServiceAssessment, useServiceAssessmentBatch } from '~/services/Assessment'
import { useGetDocumentation } from '~/services/Documentation'
import { useServiceGetLesson } from '~/services/Lesson'
import { useLessonStepInformation } from '~/services/LessonSteps'
import { useGetStudentsGroupLesson, useGetStudentsGroupLessonL3 } from '~/services/Students'

import {
  dataLessonDocumentation,
  initialDataState,
  initialDataStudentsGroup,
} from '~/pages/private/Fund1/default.state'

import { IFirstStepPageContextProps, IFirstStepPageProviderProps } from './interfaces'

const FirstStepPageContext = createContext<IFirstStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  isLoading: false,
  data: initialDataState,
  dataDocumentation: dataLessonDocumentation,
  dataDocumentationFiltered: {},
  isLoadingDocumentation: false,
  mutateAssessment: () => Object,
  idLessonMoment: 0,
  idLesson: 0,
  idClass: 0,
  dataStudents: initialDataStudentsGroup,
  isLoadingManageStudents: false,
  errorDataStudents: {},
  isFetchingStudents: false,
  refetch: () => '',
  isSameUser: false,
  isClassInStatus: true,
})

const FirstStepPageProvider = ({ children }: IFirstStepPageProviderProps) => {
  const { idLessonMoment, idLesson, idClass } = useParams()

  const [activedTab, setActivedTab] = useState<number>(0)

  const { data, isLoading } = useLessonStepInformation(Number(idLessonMoment), Number(idLesson), 1)

  const { mutate: mutateAssessment, isLoading: isLoadingManageStudents } = useServiceAssessment()
  const { mutate: mutateAssessmentBatch, isLoading: isLoadingAssessmentBatch } = useServiceAssessmentBatch()

  const {
    data: dataStudents,
    error: errorDataStudents,
    isFetching: isFetchingStudents,
    refetch,
  } = useGetStudentsGroupLesson(Number(idLessonMoment), Number(1))

  const { data: dataStudentsL2, refetch: refetchL2 } = useGetStudentsGroupLessonL3(Number(idLessonMoment), Number(2))

  const { data: dataModal } = useServiceGetLesson(Number(idLessonMoment))

  const isClassInStatus = dataModal?.class?.inStatus

  const { data: dataDocumentation, isLoading: isLoadingDocumentation } = useGetDocumentation(
    Number(idClass),
    Number(idLessonMoment),
  )

  const dataDocumentationFiltered = dataDocumentation?.data
    ?.filter((item) => item?.lessonStep?.nuOrder === 1)
    .sort(function (a, b) {
      return new Date(b.dtInserted).getDate() - new Date(a.dtInserted).getDate()
    })

  const { user } = useAuth()

  const idUserProfessor = dataModal?.idUserProfessor
  const idUser = user?.id_user

  const isSameUser = idUserProfessor === idUser

  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const firstStepPageProviderValues = useMemo(() => {
    return {
      activedTab,
      handleActiveNewTab,
      isSameUser,
      isLoading,
      data: data || initialDataState,
      dataDocumentation,
      isLoadingDocumentation,
      dataDocumentationFiltered,
      mutateAssessment,
      mutateAssessmentBatch,
      idLessonMoment: Number(idLessonMoment),
      idLesson: Number(idLesson),
      dataStudents: dataStudents || initialDataStudentsGroup,
      isLoadingManageStudents,
      isLoadingAssessmentBatch,
      errorDataStudents,
      isFetchingStudents,
      dataModal,
      idClass,
      refetch,
      dataStudentsL2,
      refetchL2,
      isClassInStatus,
    }
  }, [
    refetch,
    isSameUser,
    isFetchingStudents,
    dataStudents,
    isLoadingManageStudents,
    isLoadingAssessmentBatch,
    errorDataStudents,
    activedTab,
    handleActiveNewTab,
    dataDocumentation,
    isLoading,
    data,
    isLoadingDocumentation,
    dataDocumentationFiltered,
    mutateAssessment,
    mutateAssessmentBatch,
    idLessonMoment,
    idLesson,
    dataModal,
    idClass,
    dataStudentsL2,
    refetchL2,
    isClassInStatus,
  ])

  return <FirstStepPageContext.Provider value={firstStepPageProviderValues}>{children}</FirstStepPageContext.Provider>
}

const useFirstStepPageContext = () => useContext(FirstStepPageContext)

export { FirstStepPageProvider, useFirstStepPageContext }
