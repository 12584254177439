import { UilMultiply } from '@iconscout/react-unicons'
import { Box } from '@mui/material'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'
import { useNavigation } from '~/_context/Navigation'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent, IconComponent, ModalComponent, MomentCardComponent, SkeletonComponent } from '~/components'

import { formatLabel } from '~/containers/private/StudentDashboard/OverviewByCompetence/OverviewByCompetence'

import { useStudentDashboardPageContext } from '~/pages/private/StudentDashboard/context'

import { renderStatusText } from '~/validations/renderStatusText'

import { ModalClassHistoryProps } from './ModalClassHistory.interface'
import * as S from './ModalClassHistory.styles'

const renderStageText = (stage: string) => {
  switch (stage) {
    case 'F1':
      return 'Fundamental 1'
    case 'F2':
      return 'Fundamental 2'
    case 'EM':
      return 'Ensino Médio'
    case 'IN':
      return 'Ensino Infantil'
  }
}

export const ModalClassHistory = ({ open, competenceId, onClose, moments, competence }: ModalClassHistoryProps) => {
  const { user } = useAuth()

  const { handleNavigation } = useNavigation()
  const { dataDashboard, studentImage, studentClass, currentStage } = useStudentDashboardPageContext()

  const { width } = useWindowDimensions()
  const isMobile = width && width <= 768

  function checkDate(date: Date): string {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (date.toDateString() === today.toDateString()) {
      return 'Hoje'
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Ontem'
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return 'Amanhã'
    } else {
      return dayjs(date).format('DD/MM/YYYY')
    }
  }

  if (!moments || !dataDashboard)
    return (
      <ModalComponent open={open} actionCloseModal={onClose} maxHeight={'668px'}>
        <S.Container>
          <Box display={'flex'} alignItems={'center'} gap={8}>
            <SkeletonComponent width={48} height={48} variant={'circular'} />
            <SkeletonComponent width={'400px'} height={50} variant={'rounded'} />
          </Box>
          <Box width={'100%'} display={'grid'} justifyContent={'center'} mt={4} gap={4}>
            <SkeletonComponent width={'550px'} height={100} variant={'rounded'} />
            <SkeletonComponent width={'550px'} height={100} variant={'rounded'} />
            <SkeletonComponent width={'550px'} height={100} variant={'rounded'} />
            <SkeletonComponent width={'550px'} height={100} variant={'rounded'} />
          </Box>
        </S.Container>
      </ModalComponent>
    )

  const isResponsable = user?.role.includes('pais')

  return (
    <ModalComponent open={open} actionCloseModal={onClose} maxHeight={isMobile ? '100%' : '92%'} height={'100vh'}>
      <S.Container>
        <S.Header>
          <S.TitleCardProject>
            <S.HeaderTitle>
              <AvatarComponent photoUrl={studentImage} size="mediumLarge" />
              <div>
                <p>Competência de </p>
                <S.Competence color={`#${competence?.competenceColor}`}>
                  {' '}
                  <IconComponent
                    color={`#${competence?.competenceColor}`}
                    fontWeight="normal"
                    code={competence?.competenceIcon}
                    size="xsmall"
                  />
                  {competence?.competenceName}
                </S.Competence>
              </div>
            </S.HeaderTitle>

            <S.Icon>
              <UilMultiply onClick={onClose} size={30} color="#666" />
            </S.Icon>
          </S.TitleCardProject>
          <S.StatusList>
            <S.StatusItem>{renderStageText(currentStage)}</S.StatusItem>
            {studentClass && <S.StatusItem>{studentClass}</S.StatusItem>}
          </S.StatusList>
          <S.ProgressContainer>
            <S.Progress>
              {competence?.competenceAverage}% {formatLabel(competence?.competenceAverage)}
            </S.Progress>
            <S.BorderLinearProgress
              variant="determinate"
              competenceColor={competence?.competenceColor}
              value={competence?.competenceAverage}
            />
          </S.ProgressContainer>
        </S.Header>
        <S.LessonList className="styled-scroll-s">
          {moments.map((moment) => (
            <MomentCardComponent
              key={moment.idMoment}
              cardbackgroundcolor={'#FFFFFF'}
              momentTitle={moment.txMoment as string}
              momentStatusCode={'FINA'}
              momentStatusText={renderStatusText('FINA')}
              momentAbilities={moment.abilityAssessments}
              totalClass={Number(moment?.nuTrackCount)}
              nuOrder={moment.nuTrackOrder}
              chipOld
              coGrade={moment?.coGrade}
              txGrade={moment?.txGrade}
              txClassName={moment?.txClassName}
              txTrackTitle={moment?.txTrackTitle}
              idMomentTrack={moment?.idMomentTrack}
              idClass={moment?.idClass}
              coStage={moment?.coStage}
              momentTime={moment?.dtSchedule}
              action={() =>
                !isResponsable &&
                handleNavigation(moment.coStage, moment?.idMoment, Number(moment.idClass), '/dashboard')
              }
            />
          ))}
        </S.LessonList>
      </S.Container>
    </ModalComponent>
  )
}
