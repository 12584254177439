import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const NavContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '94px 24px 20px 24px',
  marginTop: '-70px',
  zIndex: 100,
  backgroundColor: '#fff',
}))

export const Content = styledMUI(Box)(() => ({
  padding: '0 24px 24px',
}))

export const ContentDocumentation = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '24px',
}))

export const ContentOrientations = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const HeaderLoading = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  position: 'relative',
  left: '18px',
  padding: '24px',
  marginTop: '70px',
}))

export const ContentLoading = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
}))
