import { useState } from 'react'
import { pdfjs } from 'react-pdf'
import { useParams } from 'react-router-dom'

import { UilArrowUpRight, UilBookReader, UilFileDownloadAlt } from '@iconscout/react-unicons'
import { Link, Tooltip } from '@mui/material'
import { pdf } from '@react-pdf/renderer'

import { BackNavigationComponent, ButtonComponent } from '~/components'

import { ProjectMomentStage } from '~/pages/private/Fund2/Project/interfaces'

import { PDFDocument } from '../DocumentPDF/PDFDocument'
import * as S from './Header.styles'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IHeaderPlanning {
  activedTab: number
  txNameLesson: string
  isOffline?: boolean
  txOrientation: string
  stages: ProjectMomentStage[]
  txTitleLesson: string
  firstStep: string
  secondStep: string
  thirdStep: string
}

interface TruncateStringProps {
  inputString: string
  maxLength: number
}

export const HeaderPlanning = ({
  txNameLesson,
  isOffline,
  stages,
  txTitleLesson,
  firstStep,
  secondStep,
  thirdStep,
  txOrientation,
}: IHeaderPlanning) => {
  const { projectId, idClass } = useParams()
  const [anchorElSupportsMenu, setAnchorElSupportsMenu] = useState<null | HTMLElement>(null)
  const handleOpenSupportsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSupportsMenu(event.currentTarget)
  }
  const LinksMap = stages?.map((stage) => {
    return stage.projectStage.mediaInformation
  })

  const generatePdfDocument = async (fileName: string) => {
    const blob = await pdf(
      <PDFDocument steps={stages} txOrientation={txOrientation} title={txNameLesson} txTitleLesson={txTitleLesson} />,
    ).toBlob()
    const data = (window.webkitURL || window.URL).createObjectURL(blob)
    const link = document.createElement('a')
    link.href = data
    link.download = fileName?.replace(/"/g, '')
    link.click()

    setTimeout(function () {
      ;(window.webkitURL || window.URL).revokeObjectURL(data)
    }, 100)
  }
  const supports = [
    {
      label: `Projeto 1 - ${firstStep}`,
      href: LinksMap[0].txAbsoluteUrl,
    },
    {
      label: `Projeto 2 - ${secondStep}`,
      href: LinksMap[1].txAbsoluteUrl,
    },
    {
      label: `Projeto 3 - ${thirdStep}`,
      href: LinksMap[2].txAbsoluteUrl,
    },
  ]

  const truncateString = ({ inputString, maxLength }: TruncateStringProps): string => {
    if (inputString.length > maxLength) {
      return inputString.slice(0, maxLength) + '...'
    }
    return inputString
  }

  const handleCloseSupportsMenu = () => setAnchorElSupportsMenu(null)
  return (
    <S.HeaderContainer id={'header'}>
      <S.ContainerTitle>
        <S.ContentBack>
          <BackNavigationComponent
            small
            label={txNameLesson}
            path={isOffline ? `/offline/project/${projectId}` : `/class/${idClass}/project/${projectId}`}
          />
        </S.ContentBack>
        <S.ContentBack>
          <S.Title>Orientações da Aula</S.Title>
          <S.RowBtn>
            <div>
              <ButtonComponent
                variant="outline"
                iconStart={<UilBookReader size="20" />}
                size="small"
                fontWeight={'normal'}
                fontSize={'large'}
                fullWidth={false}
                text="Roteiros dos Alunos"
                onClick={(event) => {
                  handleOpenSupportsMenu(event)
                }}
              />
              <S.MenuContainer
                anchorEl={anchorElSupportsMenu}
                open={Boolean(anchorElSupportsMenu)}
                onClose={handleCloseSupportsMenu}
              >
                {supports.map((support: any) => (
                  <S.ButtonMenuItem key={support.id}>
                    <>
                      <Link
                        onClick={(e) => e.stopPropagation()}
                        target="_blank"
                        href={support.href}
                        underline="none"
                        color="inherit"
                        sx={{ mb: '5px', ml: '5px', fontWeight: 450, width: '350px' }}
                      >
                        {truncateString({ inputString: support.label, maxLength: 45 })}
                      </Link>
                      <S.RowEnd>
                        <UilArrowUpRight size={18} color={'black'} />
                      </S.RowEnd>
                    </>
                  </S.ButtonMenuItem>
                ))}
              </S.MenuContainer>
            </div>
            <Tooltip title={'Download em PDF'} placement="top">
              <div>
                <ButtonComponent
                  variant="outline"
                  iconEnd={<UilFileDownloadAlt size="20" />}
                  size="small"
                  fontWeight={'normal'}
                  fullWidth={false}
                  onClick={() => generatePdfDocument('planejamento')}
                  text="Download"
                />
              </div>
            </Tooltip>
          </S.RowBtn>
        </S.ContentBack>
      </S.ContainerTitle>
    </S.HeaderContainer>
  )
}
