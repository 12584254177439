import { TextField } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const InputTextTime = styledMUI(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    height: '55px',
    width: '100%',
    color: '#7C8189',

    '& fieldset': {
      border: '1px solid #7C8189',
    },

    '&:hover fieldset': {
      borderColor: '#7C8189',
    },

    '&.Mui-focused fieldset': {
      borderColor: '#7C8189',
    },
  },

  '.MuiIconButton-root': {
    color: '#7C8189',
  },
}))
