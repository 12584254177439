import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styledMUI(Box)(() => ({
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  gap: '24px',
  width: '100%',
}))

export const ContentIcon = styledMUI(Box)<{ bgColor: string }>(({ bgColor }) => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: bgColor,
  borderRadius: '50px',
}))

export const Title = styledMUI(Typography)(() => ({
  width: '100%',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  width: '100%',
  lineHeight: '150%',
  textAlign: 'center',
  color: '#000',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  display: 'grid',
  alignItems: 'center',
  width: '100%',
  gap: '8px',

  '@media (max-width: 700px)': {
    flexDirection: 'column-reverse',
  },
}))

export const FormCheckboxList = styled.form`
  display: grid;
  width: 100%;
  gap: 8px;
`

export const FormCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f8faff;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  gap: 8px;
`

export const Checkbox = styled.input`
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:checked {
    accent-color: #8c22bc;
  }
`

export const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #7c8189;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;

  span:last-child {
    border-right: none;
  }

  & .resume {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 145px;
    overflow: hidden;
  }
`

export const CheckboxSpan = styled.span`
  font-size: 14px;
  padding: 0 6px;
  color: #7c8189;
  border-right: 1px solid #e2e5ec;
`

export const CheckboxDate = styled.span``
