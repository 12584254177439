import styled, { css } from 'styled-components'

function startsWithHash(input: string): boolean {
  return input?.startsWith('#')
}

interface IIconProps {
  color?: 'card' | 'primary' | 'unavaible' | string
  size: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large'
  fontWeight?: 'normal' | 'bold'
  fontSize?: 'large' | 'medium' | 'small'
}

export const textStyled = styled.div<IIconProps>`
   {
    font-family: 'unicons-line';
    src: url("../../fonts/unicons-line.eot'");
    src: url('../../fonts/unicons-line.eot') format('embedded-opentype'),
      url('../../fonts/unicons-line.woff2') format('woff2'), url('../../fonts/unicons-line.woff') format('woff'),
      url('../../fonts/unicons-line.ttf') format('truetype'), url('../../fonts/unicons-line.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  ${({ color }) =>
    startsWithHash(color) &&
    css`
      color: ${color};
    `}

  ${({ fontWeight }) =>
    fontWeight == 'bold'
      ? css`
          font-weight: 700;
        `
      : css`
          font-weight: 400;
        `}

  ${({ color }) =>
    color == 'card' &&
    css`
      color: #e14983;
    `}

  ${({ color }) =>
    color == 'unavaible' &&
    css`
      color: #bdbdbd;
    `}

  ${({ color }) =>
    color == 'primary' &&
    css`
      color: #0095ff;
    `}

  ${({ color }) =>
    color == 'unavaible' &&
    css`
      color: #d44333;
    `}

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize === 'large' ? '20px' : fontSize === 'medium' ? '16px' : '14px'};
    `}

  ${({ size }) =>
    size == 'xxsmall' &&
    css`
      height: 14px;
      font-size: 14px;
      line-height: 100%;
    `}

  ${({ size }) =>
    size == 'xsmall' &&
    css`
      height: 24px;
      font-size: 24px;
      line-height: 100%;
    `}

  ${({ size }) =>
    size == 'small' &&
    css`
      height: 32px;
      font-style: normal;
      font-size: 14px;
      line-height: 100%;
    `}


  ${({ size }) =>
    size == 'medium' &&
    css`
      height: 32px;
      padding: 0 10px;
      font-style: normal;
      font-size: 20px;
      line-height: 100%;
    `}

  ${({ size }) =>
    size == 'large' &&
    css`
      height: 48px;
      padding: 8px 16px;
      font-style: normal;
      font-size: 20px;
      line-height: 100%;
    `}
`
