import { Link } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import { colorsStepsActived, colorsStepsDisabled } from '~/validations'

export const AccordionContainer = styled(Accordion)`
  padding: 24px;
  box-shadow: none;
  border-top: 1px solid ${({ theme }) => theme.colors.midMedium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.midMedium};
  margin: 0 !important;

  .general {
    padding: 18px;
  }

  .overall-guidance {
    padding: 18px;
  }

  & .muiaccordion-root:before {
    display: none;
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`

export const ActivityContainer = styled.div`
  padding: 24px 18px;
`

export const AccordionHeader = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
`

export const StepOrientationContainer = styled.div`
  padding: 18px;
`

export const AccordionDetailsContent = styledMUI(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0px 16px 16px',
  margin: '0px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
  padding: '24px',
}))

export const ContentParagraphs = styledMUI(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const Paragraph = styledMUI(ListItem)(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
  color: 'black !important',

  // Define a cor para todos os elementos filhos
  '& *': {
    color: 'black !important',
  },
}))

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: 'black',
  '&.default-color': {
    color: 'black',
  },
}))

export const AccordionTitle = styled(Typography)`
  font-weight: 700 !important;
  font-size: 20px !important;
  display: flex;
  align-items: baseline;
  gap: 8px;
  line-height: 120%;
`

export const AccordionText = styled.div`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000;

  p {
    margin: 12px 0;
  }
`

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50%',
}))

export const ReferenceContainer = styled.div`
  .reference {
    margin-top: 15px;
  }
`

export const ContentCards = styledMUI(List)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  overflowX: 'hidden',
}))

export const CardLink = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

export const Arrow = styledMUI(Box)<{ enabled: boolean }>(({ enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: enabled ? '#ccc' : '#0095FF',
  width: '24px',
  height: '24px',
  color: '#fff',
  borderRadius: '50%',
}))

export const PutStart = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  paddingBottom: '20px',
}))

export const PutEnd = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '20px',
}))

export const CardReference = styledMUI(ListItem)<{ type: 'firstStep' | 'secondStep' | 'thirdStep'; maxHeight: number }>(
  ({ type, maxHeight }) => ({
    minHeight: '108px',
    height: maxHeight ? `${maxHeight}px` : 'none',
    width: '192px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorsStepsDisabled(type),
    borderRadius: '8px',
    gap: '8px',
  }),
)

export const Card = styledMUI(ListItem)<{ type: 'default' | 'firstStep' | 'secondStep' | 'thirdStep' }>(({ type }) => ({
  height: '106px',
  width: '192px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorsStepsDisabled(type),
  borderRadius: '8px',
  gap: '8px',
}))

export const Loading = styledMUI(Backdrop)(() => ({}))

export const TextCard = styledMUI(Typography)<{
  type: 'default' | 'firstStep' | 'secondStep' | 'thirdStep'
}>(({ type }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  color: colorsStepsActived(type),
  textAlign: 'center',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
}))

export const TextCardProject = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  textAlign: 'center',
}))
