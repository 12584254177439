import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import { useSnackbar } from '~/_context/Snackbar'

import {
  useServiceGetDocumentationInformation,
  useServiceSendDocumentation,
  useServiceUpdateDocumentation,
} from '~/services/Documentation'
import { useGetStudentsForDocument } from '~/services/Students'
import { StudentsGroupLessonData } from '~/services/Students/types'

import { MultiSelectComponent, MultiSelectStudentComponent, SkeletonComponent } from '~/components'

import { truncateText } from '~/validations/truncateText'

import { Evidence, IDocument, IDocumentForm, IValuesLesson } from '../ModalDocumentation.interfaces'
import * as S from '../ModalDocumentation.styles'
import { FileUpload } from './FileUpload'

interface DocumentFormProps {
  values?: IValuesLesson
  type?: 'add' | 'edit'
  idLesson: number
  handleShowDocumentModal: (value: boolean) => void
  queryKeys?: string[]
  setShowSubmittedModal: (value: boolean) => void
  setShowCancelModal: (value: boolean) => void
  onLoadingSubmitChange: (value: boolean) => void
  isClassInStatus?: boolean
}

interface DocumentFormRef {
  submitForm: () => void
  isLoadingSubmit: boolean
}

// eslint-disable-next-line react/display-name
export const DocumentForm = forwardRef<DocumentFormRef, DocumentFormProps>(
  (
    {
      values,
      type = 'add',
      queryKeys,
      handleShowDocumentModal,
      setShowSubmittedModal,
      setShowCancelModal,
      onLoadingSubmitChange,
      isClassInStatus,
    },
    ref,
  ) => {
    const { showSnackbarError } = useSnackbar()
    const queryClient = useQueryClient()
    const { idClass, idLessonMoment } = useParams()

    const [studentsIdLessonActivity, setStudentsIdLessonActivity] = useState<number | null>(null)
    const [totalExceed, setTotalExceed] = useState<boolean>(false)
    const [totalSize, setTotalSize] = useState(0)
    const [isLoadingSendMedia, setIsLoadingSendMedia] = useState(false)
    const [documentFiles, setDocumentFiles] = useState<any>([])
    const [steps, setSteps] = useState<{ label: string; value: string }[] | undefined>([])
    const [evidences, setEvidences] = useState()
    const [selectedStudents, setSelectedStudents] = useState<Array<string>>([])
    const [nuOrder, setNuOrder] = useState<number>(2)

    const {
      data: documentInformation,
      isLoading: isLoadingDocumentInformation,
      isRefetching: isRefetchingDocumentInformation,
    } = useServiceGetDocumentationInformation(Number(idLessonMoment))

    const {
      data: dataGroupStudents,
      refetch,
      isRefetching,
    } = useGetStudentsForDocument(Number(idLessonMoment), Number(nuOrder))
    const [studentsState, setStudentsState] = useState<StudentsGroupLessonData | undefined>(dataGroupStudents)

    const {
      mutate: handleSendDocs,
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorDataSubmit,
    } = useServiceSendDocumentation()

    useEffect(() => {
      onLoadingSubmitChange(isLoadingSubmit)
    }, [isLoadingSubmit, onLoadingSubmitChange])

    const {
      mutate: handleUpdateDoc,
      isLoading: isLoadingUpdateDoc,
      isSuccess: isSuccessUpdateDoc,
      error: errorDataUpdateDoc,
    } = useServiceUpdateDocumentation()

    useEffect(() => {
      refetch().then((res) => {
        if (studentsIdLessonActivity && res.data) {
          setStudentsState({
            allAssessments: res?.data?.allAssessments,
            group: res?.data?.group?.filter((student) => student.idLessonActivity === studentsIdLessonActivity),
          })
        } else {
          setStudentsState(res.data)
        }
      })
    }, [nuOrder, refetch, studentsIdLessonActivity])

    const handleChangeStep = (nuOrder: number, idLessonActivity?: number) => {
      setNuOrder(nuOrder)

      if (idLessonActivity) {
        setStudentsIdLessonActivity(idLessonActivity)
      } else {
        setStudentsIdLessonActivity(null)
      }
    }

    const studentsAttendance = dataGroupStudents?.group?.filter((student) => student.inAttendance === true)
    const studentsAttendanceFiltered = studentsAttendance?.map((item) => item.studentClass.student)

    const handleSelectStudent = (idUserStudent: string) => {
      if (selectedStudents?.length === studentsAttendanceFiltered?.length - 1) {
        setSelectedStudents(['allstudents'])
      } else {
        setSelectedStudents((prevSelectedStudents) => {
          const isSelected = prevSelectedStudents.includes(idUserStudent)
          if (isSelected) {
            return prevSelectedStudents.filter((student) => student !== idUserStudent)
          } else {
            const updatedSelectedStudents = prevSelectedStudents.filter((student) => student !== 'allstudents')
            return [...updatedSelectedStudents, idUserStudent]
          }
        })
      }
    }

    const handleClearSelectStudent = () => {
      setSelectedStudents([])
    }

    useEffect(() => {
      const allEvidences: Evidence[] = documentInformation?.flatMap((document: IDocument) => document.evidence) || []
      const uniqueEvidenceIds = new Set<number>()

      const evidenceList = allEvidences?.reduce((acc: any[], evidence: Evidence) => {
        const { idEvidence, ability } = evidence
        const newTxName = ability.txName

        if (!uniqueEvidenceIds.has(idEvidence)) {
          uniqueEvidenceIds.add(idEvidence)
          acc.push({
            evidenceId: idEvidence,
            txImagePath: ability.txImagePath,
            txName: newTxName,
          })
        }
        return acc
      }, [])
      setEvidences(evidenceList)
    }, [documentInformation])
    useEffect(() => {
      setSteps(
        documentInformation?.map((item) => {
          return {
            label: `Passo ${item.lessonStepNuOrder} - ${item.lessonStepTxTitle} ${
              item.lessonActivityTxTitle.length > 0 ? ' - ' + item.lessonActivityTxTitle : ''
            }`,
            value: `${item.idLessonStep}-${item.lessonStepNuOrder === 2 ? item.idLessonActivity : null}`,
          }
        }),
      )
    }, [documentInformation])

    const [evidencesSelected, setEvidencesSelected] = useState<number[]>(() => {
      if (type === 'edit' && values?.evidenceIds) {
        return values?.evidenceIds
      }
      return []
    })

    const [documentForm, setDocumentForm] = useState<IDocumentForm>(() => {
      return {
        txMomentNotes: '',
        idLessonStep: 0,
        skill: '',
        media: [],
        idLessonActivity: 0,
        studentId: [],
      }
    })

    const isTxMomentNotesEmpty = documentForm.txMomentNotes.trim() === ''
    const [errorTxMomentNotes, setErrorTxMomentNotes] = useState<string | null>(null)

    useEffect(() => {
      if (type === 'add') {
        setSelectedStudents(['allstudents'])
      }
      if (type === 'edit' && values) {
        setDocumentForm({
          txMomentNotes: values?.txMomentNotes || '',
          idLessonStep: values?.idLessonStep,
          skill: '',
          media: [],
          studentId: values?.studentId?.map((student) => student.idUserStudent),
          idLessonActivity: values?.idLessonActivity || 0,
        })
        if (values.evidenceIds) {
          setEvidencesSelected(values?.evidenceIds)
        }
        if (values?.studentId) {
          if (studentsAttendanceFiltered?.length === values.studentId.length) {
            setSelectedStudents(['allstudents'])
          } else {
            setSelectedStudents(values?.studentId.map((student) => student.idUserStudent))
          }
        }
        if (values.documentFiles) {
          setDocumentFiles(values.documentFiles)
        }
        setStudentsIdLessonActivity(Number(values.idLessonActivity))
      }
    }, [setSelectedStudents, studentsAttendanceFiltered?.length, type, values])

    useEffect(() => {
      if ((!isLoadingSubmit && errorDataSubmit) || (!isLoadingUpdateDoc && errorDataUpdateDoc)) {
        const errorMessage =
          errorDataSubmit?.response?.data.message || errorDataUpdateDoc?.response?.data.message || 'Ocorreu um erro.'
        showSnackbarError(errorMessage)
      }
    }, [isLoadingSubmit, errorDataSubmit, isLoadingUpdateDoc, errorDataUpdateDoc, showSnackbarError])

    useEffect(() => {
      const handleCloseModal = () => {
        queryClient.invalidateQueries(['getDocumentation']).then()
        handleShowDocumentModal(false)

        setEvidencesSelected([])
      }
      if (!isLoadingUpdateDoc && isSuccessUpdateDoc) {
        handleCloseModal()
        setDocumentForm({
          txMomentNotes: '',
          idLessonStep: 0,
          skill: '',
          media: [],
          studentId: [],
          idLessonActivity: 0,
        })
        setDocumentFiles([])
        setTotalSize(0)
        setShowSubmittedModal(true)
      }
      if (!isLoadingSubmit && isSuccessSubmit) {
        handleCloseModal()
        setDocumentForm({
          txMomentNotes: '',
          idLessonStep: 0,
          skill: '',
          media: [],
          studentId: [],
          idLessonActivity: 0,
        })
        setDocumentFiles([])
        setTotalSize(0)
        setShowSubmittedModal(true)
      }
    }, [
      isLoadingUpdateDoc,
      isSuccessUpdateDoc,
      isLoadingSubmit,
      queryClient,
      isSuccessSubmit,
      queryKeys,
      handleShowDocumentModal,
      setShowSubmittedModal,
    ])

    const handleTotalExceed = (value: boolean) => {
      setTotalExceed(value)
    }

    const handleSelect = (e: React.ChangeEvent<{ value: Array<number> }>) => {
      setEvidencesSelected(e.target.value)
    }

    const handleSelectDelete = (value: number) => {
      setEvidencesSelected((prevValues) =>
        prevValues.filter((prevValue) => {
          return prevValue != value
        }),
      )
    }

    const handleSelectFormChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const [idLessonStep, idLessonActivity] = e.target.value.split('-')

      if (Number(idLessonStep) && Number(idLessonActivity)) {
        handleChangeStep(2, Number(idLessonActivity))
        setDocumentForm((prevFormData: any) => {
          return {
            ...prevFormData,
            idLessonActivity,
            idLessonStep: null,
          }
        })
      } else if (Number(idLessonStep) && !Number(idLessonActivity)) {
        handleChangeStep(2)
        setDocumentForm((prevFormData: any) => {
          return {
            ...prevFormData,
            [e.target.name]: idLessonStep,
            idLessonActivity: null,
          }
        })
      } else {
        setDocumentForm((prevFormData: any) => {
          return {
            ...prevFormData,
            idLessonStep: '',
            idLessonActivity: null,
          }
        })
      }
      setSelectedStudents(['allstudents'])
    }

    const validateStudents = (selectedStudents: string[]) =>
      (selectedStudents?.length === 0 || selectedStudents?.length === 1) && selectedStudents[0] === 'allstudents'
        ? []
        : selectedStudents

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDocumentForm((prevFormData: any) => {
        return {
          ...prevFormData,
          [e.target.name]: e.target.value,
        }
      })
    }

    const formRef = useRef()

    const handleSubmit = (event: any) => {
      event.preventDefault()
      const { txMomentNotes, idLessonStep, idLessonActivity } = documentForm || {}
      const mediaInformationToPersist = documentFiles
        .filter((file: any) => file.idMedia !== null && file.idMedia !== undefined)
        .map((file: any) => file.idMedia)

      const mediaIdsToPersist = documentFiles
        .filter((file: any) => file.idMediaInformation !== null && file.idMediaInformation !== undefined)
        .map((file: any) => file.idMediaInformation)

      const form = {
        txMomentNotes,
        idMomentStage: idLessonStep !== 0 && !idLessonActivity ? idLessonStep : null,
        idMomentActivity: idLessonActivity || null,
        evidenceId: evidencesSelected.length === 0 ? null : evidencesSelected,
        userStudentId: validateStudents(selectedStudents),
        coTemporaryMediaList: mediaInformationToPersist.length > 0 ? mediaInformationToPersist : null,
        idMediaToPersist: mediaIdsToPersist.length > 0 ? mediaIdsToPersist : null,
      }

      if (type === 'edit') {
        handleUpdateDoc({
          body: form,
          idDocumentation: values?.idLessonDocumentation as number,
          idMoment: idLessonMoment,
          idClass: Number(idClass),
        })
      } else {
        handleSendDocs({
          body: form,
          idMoment: idLessonMoment,
          idClass: Number(idClass),
        })
      }

      setSelectedStudents([])
    }

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        if (isTxMomentNotesEmpty) {
          setErrorTxMomentNotes('Por favor, preencha este campo.')
          return
        }

        setErrorTxMomentNotes(null)

        setIsLoadingSendMedia(true)

        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
      },
      isLoadingSubmit: isLoadingSubmit,
    }))

    const handleCancelBtn = () => {
      if (
        documentForm?.txMomentNotes ||
        documentForm?.idLessonStep ||
        documentForm?.idLessonActivity ||
        documentForm?.media ||
        documentForm?.skill?.length > 0
      ) {
        setShowCancelModal(true)
      } else {
        handleShowDocumentModal(false)
        setDocumentForm({
          txMomentNotes: '',
          idLessonActivity: 0,
          skill: '',
          media: [],
          idLessonStep: 0,
          studentId: [],
        })
        setDocumentFiles([])
        setTotalSize(0)
        setSelectedStudents([])
      }
    }

    if (isLoadingDocumentInformation || !documentInformation) {
      return (
        <Box gap={'20px'} display={'grid'}>
          <SkeletonComponent variant={'text'} width={'100%'} height={50} />
          <SkeletonComponent variant={'text'} width={'100%'} height={50} />
          <SkeletonComponent variant={'text'} width={'100%'} height={50} />
          <SkeletonComponent variant={'text'} width={'100%'} height={200} />
        </Box>
      )
    }

    return (
      <S.Form onSubmit={(event) => handleSubmit(event)} ref={formRef}>
        <S.InputDiv>
          <S.InputLabel htmlFor="idLessonStep">Selecione um Passo (opcional)</S.InputLabel>

          {!isRefetchingDocumentInformation ? (
            <S.SelectInput
              id="idLessonStep"
              onChange={(e) => handleSelectFormChange(e)}
              name="idLessonStep"
              defaultValue={`${values?.idLessonStep}-${values?.idLessonActivity ? values.idLessonActivity : null}`}
            >
              <option value="">Selecione um passo</option>

              {steps?.map((step, index) => {
                return (
                  <option key={index} value={step.value}>
                    {truncateText(step.label, 90)}
                  </option>
                )
              })}
            </S.SelectInput>
          ) : (
            <Box>
              <SkeletonComponent variant={'rounded'} width={'100%'} height={80} />
            </Box>
          )}
        </S.InputDiv>

        <S.InputDiv>
          <S.InputLabel>Selecione uma ou mais Habilidades (opcional)</S.InputLabel>

          <MultiSelectComponent
            placeHolder="Selecione uma habilidade"
            values={evidencesSelected}
            options={evidences}
            handleDelete={handleSelectDelete}
            handleChange={handleSelect}
          />
        </S.InputDiv>

        {!isRefetching && studentsState ? (
          <MultiSelectStudentComponent
            selectedStudents={selectedStudents}
            handleSelectStudent={handleSelectStudent}
            type={'lesson'}
            students={studentsState}
            handleClearSelectStudent={handleClearSelectStudent}
          />
        ) : (
          <Box my={5}>
            <SkeletonComponent variant={'rounded'} width={'100%'} height={168} />
          </Box>
        )}

        <S.InputDiv>
          <S.InputLabel htmlFor="document-text">Escreva a sua documentação</S.InputLabel>

          <S.TextInput
            required
            className={'styled-scroll-s'}
            id="document-text"
            placeholder="Escreva sua documentação"
            onChange={(e) => handleTextAreaChange(e)}
            name="txMomentNotes"
            value={documentForm?.txMomentNotes}
          />
          {errorTxMomentNotes && <S.ErrorMessage>{errorTxMomentNotes}</S.ErrorMessage>}
        </S.InputDiv>

        <FileUpload
          documentFiles={documentFiles}
          setDocumentFiles={setDocumentFiles}
          handleTotalExceed={handleTotalExceed}
          totalExceed={totalExceed}
          setTotalSize={setTotalSize}
          setIsLoadingSendMedia={setIsLoadingSendMedia}
          isLoadingSendMedia={isLoadingSendMedia}
          totalSize={totalSize}
          isClassInStatus={isClassInStatus}
        />
      </S.Form>
    )
  },
)
