import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'
export const ContentList = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '170px',
  padding: '24px',
  '@media (max-width: 600px)': {
    marginTop: '250px',
    marginBottom: '100px',
  },
}))

export const Description = styled.h3`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
`
