import { Box, Select, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 10,
  padding: '70px 0 0 0',

  marginTop: '-70px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #E2E5EC',
  p: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',

  cursor: 'pointer',
  '@media (max-width: 600px)': {
    display: 'grid',
    gap: '8px',
  },
}))

export const TitleContainer = styledMUI(Box)(() => ({
  display: 'block',
  marginLeft: '16px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '28.8px',
  color: '#000',
}))

export const SubTitle = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19.2px',
  color: '#666666',
}))

export const WrapperContent = styledMUI(Box)(() => ({
  width: '100%',
  padding: '32px 24px 24px',
}))

export const WrapperSelect = styledMUI(Box)(() => ({
  minWidth: '150px',

  width: '200px',
  maxWidth: '250px',
  display: 'flex',
  alignItems: 'center',
  padding: '24px 24px 24px 0',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    display: 'grid',
    gap: '8px',
  },
}))

export const SelectClass = styledMUI(Select)(() => ({
  width: '100%',
  height: '40px',
  display: 'flex',
  borderRadius: '50px',
  padding: '8px, 8px, 8px, 16px',
  gap: '8px',
  backgroundColor: '#F9FAFC',
  '& .MuiSvgIcon-root': {
    icon: '<UilArrowUp />',
    color: '##7C8189',
    backgroundColor: '#E2E5EC',
    borderRadius: '50px',
  },
}))

export const SelectItem = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: '#000',
}))
