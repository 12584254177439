import { SecondStepPageProvider } from './context'
import { SecondGeneralStepView } from './view'

export const SecondGeneralStepPage = () => {
  return (
    <SecondStepPageProvider>
      <SecondGeneralStepView />
    </SecondStepPageProvider>
  )
}
