import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AccordionTextComponent,
  AvatarGroupComponent,
  DropdownSkillComponent,
  IconComponent,
  SkeletonComponent,
} from '~/components'

import { ICardActivityComponentProps } from './CardActivity.interfaces'
import * as S from './CardActivity.styles'

export const CardGeneralActivityComponent = ({
  txSkill,
  txActivity,
  variableSkill,
  txChallenge,
  avatarsPhotos,
  activity,
  isLoading,
  icon,
  isOffline,
}: ICardActivityComponentProps) => {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const { idLessonMoment, idLesson, idClass } = useParams()

  const avatarsPhotosFiltered = avatarsPhotos?.lessonMomentActivity[0]?.lessonMomentGroup?.filter(
    (item: any) => item.inAttendance === true,
  )
  return (
    <S.Card>
      <S.CardHeader>
        <S.CardTitleContainer>
          <S.CardSkill>
            <S.CardSkillWrapper>
              <IconComponent color="card" fontWeight="normal" code={icon} size="xsmall" />
              {txSkill} {isMobile ? '' : '-'}
              <S.CardTitle>{txActivity}</S.CardTitle>
            </S.CardSkillWrapper>
          </S.CardSkill>
        </S.CardTitleContainer>

        <S.OpenBtn
          onClick={() =>
            navigate(
              isOffline
                ? `/offline/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`
                : `/class/${idClass}/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`,
            )
          }
        >
          <UilArrowRight />
        </S.OpenBtn>
      </S.CardHeader>
      {isLoading ? <SkeletonComponent width={300} /> : <AvatarGroupComponent images={avatarsPhotosFiltered} max={4} />}

      {variableSkill && (
        <S.VariableSkillContainer>
          <S.VariableSkill>Habilidade variável</S.VariableSkill>
          <DropdownSkillComponent
            iconSkill={variableSkill?.ability?.txImagePath}
            txSkillName={variableSkill?.ability?.txName}
            txSkillDescription={variableSkill?.txName}
            txCompetency={variableSkill?.ability?.competence?.txName}
            iconCompetency={variableSkill?.ability?.competence?.txImagePath}
          />
        </S.VariableSkillContainer>
      )}

      <S.ChallengeContainer>
        <S.ChallengeTx>Desafio</S.ChallengeTx>

        <S.ChallengeDescription>
          {txChallenge.length < 150 ? (
            txChallenge
          ) : (
            <AccordionTextComponent paragraph={txChallenge || '...'} limit={120}></AccordionTextComponent>
          )}
        </S.ChallengeDescription>
      </S.ChallengeContainer>
    </S.Card>
  )
}
