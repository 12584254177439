import { createContext, useContext } from 'react'

import {
  ITrailsGeneratedEMPageContextProps,
  ITrailsGeneratedEMPageProviderProps,
} from '~/pages/private/TrailsGenerated/interfaces'

const TrailsGeneratedEMPageContext = createContext<ITrailsGeneratedEMPageContextProps>({
  data: {},
  isLoading: false,
})

const TrailsGeneratedEMPageProvider = ({ children }: ITrailsGeneratedEMPageProviderProps) => {
  return (
    <TrailsGeneratedEMPageContext.Provider value={{ isLoading: false, data: {} }}>
      {children}
    </TrailsGeneratedEMPageContext.Provider>
  )
}

const useTrailsGeneratedEMPageContext = () => useContext(TrailsGeneratedEMPageContext)

export { TrailsGeneratedEMPageProvider, useTrailsGeneratedEMPageContext }
