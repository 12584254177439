import { BottomNavigation, BottomNavigationAction, Box, Menu, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
export const MenuContainer = styledMUI(Menu)(() => ({
  margin: '14px 0 0 0',

  '.MuiPaper-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
  '.styled-scroll-s': {
    '::-webkit-scrollbar': {
      width: '5px',
    },

    '::-webkit-scrollbar-track': {
      borderRadius: '8px',
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: '#CCCCCC',
      border: '1px solid #CCCCCC',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
      width: '4px',
    },
    Maxheight: '350px',
  },
}))

export const ContentSettings = styledMUI(Box)(() => ({
  width: '400px',
  height: '83vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '8px',
}))

export const WrapperDivider = styledMUI(Box)(() => ({
  padding: '8px 0',
}))

export const UserName = styledMUI(Typography)<{ actived: string }>(({ actived }) => ({
  fontStyle: 'normal',
  fontWeight: actived === 'true' ? 700 : 400,
  lineHeight: '100%',
  fontSize: '14px',
  color: actived === 'true' ? '#8C22BC' : '#a3a3a3',
}))

export const UserFullName = styledMUI(Typography)(() => ({
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const VersionText = styledMUI(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '120%',
  color: '#666666',
}))

export const InfoContainer = styledMUI(Box)(() => ({
  height: '40px',
  bottom: '0',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  padding: '16px 24px',
  backgroundColor: '#8C22BC',
  gap: '8px',
  zIndex: '9999',
  '@media (max-width: 600px)': {
    position: 'sticky',
    top: '0',
  },
}))

export const TextInfo = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#fff',
}))

export const BottomNavigationBox = styledMUI(BottomNavigation)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const BottomNavAction = styledMUI(BottomNavigationAction)(() => ({
  '@media (max-width: 400px)': {
    marginLeft: '-14px',
    marginRight: '-14px',
  },
}))
