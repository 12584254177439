import { ProjectBNCCRelationsPageProvider } from './context'
import { LessonBNCCRelationsView } from './view'

export const BnccProjectPageOffline = () => {
  return (
    <ProjectBNCCRelationsPageProvider>
      <LessonBNCCRelationsView />
    </ProjectBNCCRelationsPageProvider>
  )
}
