export const translateCoStage = (coStage: string) => {
  switch (coStage) {
    case 'F1':
      return 'Ensino Fundamental'
    case 'F2':
      return 'Ensino Fundamental'
    case 'EM':
      return 'Ensino Médio'
    case 'EMA1':
      return 'Ensino Médio'
    case 'IN':
      return 'Ensino Infantil'
    case 'M1':
      return 'Multiseries Infantil'
    case 'M2':
      return 'Multiseries Fundamental'
    case 'M3':
      return 'Multiseries Fundamental'
    case 'M4':
      return 'Multiseries Médio'
    default:
      'Ensino'
      break
  }
}
