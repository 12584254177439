import { MouseEvent, useState } from 'react'

import { UilArrowDown, UilSearch, UilFilter } from '@iconscout/react-unicons'
import { TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import InputAdornment from '@mui/material/InputAdornment'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  children,
  classes,
  handleInputFilter,
  handleOrderByStudentName,
  handleOrderByClassName,
  handleOrderByShift,
  handleFilterByClassName,
  orderActived,
  handleChangeClassesSelected,
  isLoadingFilterClasses,
}: IHeaderComponentProps) => {
  const { width } = useWindowDimensions()

  const isMobile = width && width <= 600
  const [anchorElOrderMenu, setAnchorElOrderMenu] = useState<null | HTMLElement>(null)

  const [anchorElFilterMenu, setAnchorElFilterMenu] = useState<null | HTMLElement>(null)

  const handleCloseOrderMenu = () => setAnchorElOrderMenu(null)

  const handleOpenOrderMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElOrderMenu(event.currentTarget)
  }

  const handleOpenFilterMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElFilterMenu(event.currentTarget)
  }

  const handleCloseFilterMenu = () => setAnchorElFilterMenu(null)

  const handleDelayedInput = (event) => {
    setTimeout(() => {
      handleInputFilter(event)
    }, 3000)
  }

  return (
    <S.HeaderContainer component="header">
      <S.Wrapper>
        <S.Title variant="h1">Painel</S.Title>

        <S.WrapperButtons>
          <TextField
            placeholder="Busque por um aluno"
            size="small"
            sx={{ width: isMobile ? '250px' : 'initial' }}
            onChange={(event) => handleDelayedInput(event)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {' '}
                  <UilSearch />
                </InputAdornment>
              ),
            }}
          />
          <S.BoxContainer>
            <S.ButtonIcon onClick={handleOpenOrderMenu}>
              <UilArrowDown size={20} color="#0095FF" />
            </S.ButtonIcon>

            <S.ButtonOrder
              anchorEl={anchorElOrderMenu}
              open={Boolean(anchorElOrderMenu)}
              onClose={handleCloseOrderMenu}
            >
              <S.LabelMenuItemOrder>Ordenar por</S.LabelMenuItemOrder>
              <S.ButtonMenuItem actived={orderActived === 3} onClick={handleOrderByStudentName}>
                Nome
              </S.ButtonMenuItem>

              <S.ButtonMenuItem actived={orderActived === 1} onClick={handleOrderByClassName}>
                Turma
              </S.ButtonMenuItem>

              <S.ButtonMenuItem actived={orderActived === 2} onClick={handleOrderByShift}>
                Turno
              </S.ButtonMenuItem>
            </S.ButtonOrder>
          </S.BoxContainer>

          <S.BoxContainer>
            <S.ButtonIcon onClick={handleOpenFilterMenu}>
              <UilFilter size={18} color="#0095FF" />
            </S.ButtonIcon>
            <S.ButtonSelectClass
              anchorEl={anchorElFilterMenu}
              open={Boolean(anchorElFilterMenu)}
              onClose={handleCloseFilterMenu}
            >
              <S.BoxOverflow>
                <S.FilterContainer>
                  <S.LabelMenuItemFilter>Filtrar por turmas</S.LabelMenuItemFilter>
                  <S.LabelMenuItemFilter>
                    <TextField
                      placeholder="Busque por uma turma"
                      size="small"
                      onChange={(event) => handleFilterByClassName(event)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {' '}
                            <UilSearch />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </S.LabelMenuItemFilter>
                </S.FilterContainer>
                {classes?.map((item: any) => {
                  return (
                    <S.LabelMenuItemFilter key={item.idClass}>
                      <S.WrapperMenuItemFilter>
                        <Checkbox
                          disabled={isLoadingFilterClasses}
                          checked={item.checked}
                          onChange={(event) => handleChangeClassesSelected(event, item.idClass)}
                        />
                        {item.className}
                      </S.WrapperMenuItemFilter>
                    </S.LabelMenuItemFilter>
                  )
                })}
              </S.BoxOverflow>
            </S.ButtonSelectClass>
          </S.BoxContainer>
        </S.WrapperButtons>
      </S.Wrapper>

      {children}
    </S.HeaderContainer>
  )
}
