import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  UilChatBubbleUser,
  UilChatInfo,
  UilKeySkeleton,
  UilQuestionCircle,
  UilSignout,
  UilSync,
  UilWhatsapp,
  UilWifi,
  UilWifiSlash,
} from '@iconscout/react-unicons'
import { useRegisterSW } from 'virtual:pwa-register/react'

import { useAuth } from '~/_context/Auth'
import { useOfflineMode } from '~/_context/Offline'
import { useSnackbar } from '~/_context/Snackbar'

import { useServiceChangePassword } from '~/services/ChangePassword'
import { useGetOfflineInfomartion } from '~/services/Offline'

import { DividerComponent, ModalUploadAvatar, ModalActionComponent } from '~/components'

import { CardSettingsComponent } from '~/containers/private/Settings'
import { ModalUpdatePasswordComponent } from '~/containers/private/Settings/ModalUpdatePassword/ModalUpdatePassword'

import { getZendeskRedirectUrl } from '~/validations/routeZendesk'

import packageJson from '../../../../package.json'
import { ISettingsMenuProps } from './SettingsMenu.interfaces'
import * as S from './SettingsMenu.styles'

export const SettingsMenuComponent = ({
  setIsOfflineMode,
  anchorElSettingsMenu,
  setStateNewAvatar,
  setStateNewAvatarHome,
  setAnchorElSettingsMenu,
}: ISettingsMenuProps) => {
  const offlineMode = localStorage.getItem('@LEKTO:offline') || 'false'
  const { authThirdParty, user, handleLogout } = useAuth()
  const { refetch } = useGetOfflineInfomartion(user?.id_user ?? 0)
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar()
  const [isOpenModalOffline, setIsOpenModalOffline] = useState<boolean>(false)
  const [loadingOffline, setLoadingOffline] = useState<boolean>(false)

  const { activeOfflineMode, disableOfflineMode } = useOfflineMode()

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState<boolean>(false)
  const [isOpenModalUpdateAvatar, setIsOpenModalUpdateAvatar] = useState<boolean>(false)

  const { mutate, isLoading } = useServiceChangePassword({
    onError: (error) => {
      if (error?.response?.data?.error === 'Invalid user credentials') {
        showSnackbarError('Senha atual inválida, tente novamente.')
      } else {
        showSnackbarError('Não foi possível alterar a senha, tente novamente mais tarde.')
      }
    },
    onSuccess: () => {
      showSnackbarSuccess('Senha alterada com sucesso')
      setShowUpdatePasswordModal(false)
    },
  })

  const handleOpenOfflineModal = () => setIsOpenModalOffline(true)
  const handleCancelOfflineModal = () => {
    setIsOpenModalOffline(false)
    navigate('/offline/home')
  }
  const handleOpenUpdatePasswordModal = () => {
    setAnchorElSettingsMenu(null)
    setShowUpdatePasswordModal(true)
  }
  const handleCancelUpdatePasswordModal = () => setShowUpdatePasswordModal(false)

  const handleOpenModalUploadAvatar = () => {
    setAnchorElSettingsMenu(null)
    setIsOpenModalUpdateAvatar(true)
  }

  const handleCloseModalUploadAvatar = () => {
    setIsOpenModalUpdateAvatar(false)
  }

  const handleCloseSettingsMenu = () => setAnchorElSettingsMenu(null)

  function formatName(fullName: string) {
    const parts = fullName.split(' ')
    const firstName = parts[0]
    const lastName = parts.length > 1 ? parts[parts.length - 1] : ''

    const formattedFirstName = firstName.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())
    const formattedLastName = lastName.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())

    if (lastName) {
      return `${formattedFirstName} ${formattedLastName}!`
    } else {
      return `${formattedFirstName}!`
    }
  }

  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration>()

  const { updateServiceWorker } = useRegisterSW({
    onRegisteredSW(_swUrl, swr) {
      setServiceWorkerRegistration(swr)
    },
  })

  const handleRefreshSystem = async () => {
    localStorage.setItem('reloading', 'true')
    await serviceWorkerRegistration?.update()
    await updateServiceWorker(true)
    window.location.reload(true)
  }

  const handleChangePassword = async (data: any) => {
    mutate({
      username: String(user?.preferred_username),
      currentPassword: data.oldPassword,
      newPassword: data.newPassword,
    })
  }

  const [checked, setChecked] = useState(() => {
    return JSON.parse(offlineMode) !== true
  })

  const handleToggleOfflineMode = useCallback(() => {
    if (!checked) {
      setLoadingOffline(true)

      refetch()
        .then((fetchedData: any) => {
          if (fetchedData.data) {
            localStorage.setItem('@LEKTO:offline', 'true')
            activeOfflineMode(fetchedData.data)
            setLoadingOffline(false)
            localStorage.setItem('@LEKTO:offline', 'true')
            handleOpenOfflineModal()
          } else {
            showSnackbarError(
              'Para ativar o modo offline é necessário ter aulas com status "Para realizar e Documentar" no dia de hoje.',
            )
            setLoadingOffline(false)
            setChecked(false)
            localStorage.setItem('@LEKTO:offline', 'false')
          }
          if (path === '/home' && fetchedData.data) {
            navigate('/offline/home')
          }
        })
        .catch(() => {
          showSnackbarError('Aconteceu algum problema para ativar o modo offline, entre em contato com o suporte')
        })
    } else {
      disableOfflineMode()

      navigate('/home')
    }
  }, [activeOfflineMode, checked, disableOfflineMode, navigate, path, refetch, showSnackbarError])

  useEffect(() => {
    setChecked(JSON.parse(offlineMode) === true)
    setIsOfflineMode(JSON.parse(offlineMode) === true)
  }, [offlineMode, setIsOfflineMode])

  useEffect(() => {
    const reloading = localStorage.getItem('reloading')
    if (reloading) {
      localStorage.removeItem('reloading')
      showSnackbarSuccess('Sistema atualizado com sucesso.')
    }
  }, [showSnackbarSuccess])

  return (
    <>
      <S.MenuContainer
        anchorEl={anchorElSettingsMenu}
        open={Boolean(anchorElSettingsMenu)}
        onClose={handleCloseSettingsMenu}
      >
        <S.ContentSettings className="styled-scroll-s">
          <S.UserFullName>Olá, {user?.name ? formatName(user.name) : ''}</S.UserFullName>
          <S.WrapperDivider>
            <DividerComponent />
          </S.WrapperDivider>
          {!user?.role.includes('pais') && (
            <>
              <CardSettingsComponent
                icon={checked ? <UilWifiSlash size={25} color="#8C22BC" /> : <UilWifi size={25} color="#8C22BC" />}
                type="offline"
                loading={loadingOffline}
                label="Modo Offline"
                description="Baixar conteúdo da aplicação para utilização sem internet"
                action={handleToggleOfflineMode}
                checked={checked}
                setChecked={setChecked}
              />
              {!authThirdParty && (
                <CardSettingsComponent
                  icon={<UilKeySkeleton size={25} color="#8C22BC" />}
                  label="Alterar Senha"
                  description="Criar uma nova senha"
                  action={handleOpenUpdatePasswordModal}
                />
              )}
              <CardSettingsComponent
                icon={<UilChatBubbleUser size={25} color="#8C22BC" />}
                label="Alterar foto de perfil"
                description="Inserir ou editar foto de perfil do sistema"
                action={handleOpenModalUploadAvatar}
              />
              <CardSettingsComponent
                icon={<UilSync size={25} color="#8C22BC" />}
                label={`Verificar Atualizações`}
                description="Limpar Dados e verificar se há novas atualização."
                action={handleRefreshSystem}
              />
            </>
          )}
          <CardSettingsComponent
            icon={<UilSignout size={25} color="#8C22BC" />}
            label="Sair"
            description="Fazer logout da aplicação"
            action={handleLogout}
          />
          <S.WrapperDivider>
            <DividerComponent />
          </S.WrapperDivider>
          {!user?.role.includes('pais') && (
            <>
              <S.TextLabel>Suporte Lekto</S.TextLabel>
              <CardSettingsComponent
                icon={<UilQuestionCircle size={25} color="#8C22BC" />}
                label="Manual de Navegação | FAQ"
                href="https://suporte.lekto.com.br/hc/pt-br"
              />
              <CardSettingsComponent
                icon={<UilWhatsapp size={25} color="#8C22BC" />}
                label="Falar com Especialista Lekto"
                href="https://api.whatsapp.com/send?phone=556195228083&text=Ola!"
              />
              <CardSettingsComponent
                icon={<UilChatInfo size={25} color="#8C22BC" />}
                label="Abrir um chamado"
                href={getZendeskRedirectUrl()}
              />
            </>
          )}
          <S.VersionText>Versão {packageJson.version}</S.VersionText>
        </S.ContentSettings>
      </S.MenuContainer>

      <ModalUpdatePasswordComponent
        loading={isLoading}
        open={showUpdatePasswordModal}
        actionCloseModal={handleCancelUpdatePasswordModal}
        actionSendData={handleChangePassword}
      />
      <ModalUploadAvatar
        setStateNewAvatar={setStateNewAvatar}
        setStateNewAvatarHome={setStateNewAvatarHome}
        open={isOpenModalUpdateAvatar}
        title={'Adicionar foto de perfil'}
        selectUserId={Number(user?.id_user)}
        actionCloseModal={handleCloseModalUploadAvatar}
        type={'teacher'}
      />

      <ModalActionComponent
        open={isOpenModalOffline}
        actionCloseModal={handleCancelOfflineModal}
        icon={<UilWifiSlash color="#0095FF" />}
        textButton="Continuar"
        title="Modo Offline"
        description="No modo offline você terá acesso as orientações das aulas. Como você estará sem internet, lembre-se de fazer download dos materiais de apoio.
        Para fazer o gerenciamento de grupos, e realizar documentações e avaliações, você precisa estar conectado a internet novamente, com o modo offline desligado."
        infoWidth={700}
        isFullModal={false}
      />
    </>
  )
}
