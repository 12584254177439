import styled from 'styled-components'

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  margin: 24px;
  padding: 12px;
  border-radius: 0.5rem;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.2rem 0 1rem;
`

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const CardSkill = styled.p`
  color: ${({ theme }) => theme.colors.quaternaryPure};
  font-weight: 400;
  font-size: 16px;
  margin-right: 0.2rem;
`

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.quaternaryPure};
  font-weight: 700;
  font-size: 16px;
`

export const OpenBtn = styled.div`
  color: ${({ theme }) => theme.colors.quaternaryPure};
  text-align: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

export const CardContent = styled.div`
  margin: 20px;
`

export const VariableSkillContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.72rem;
`

export const VariableSkill = styled.p`
  color: ${({ theme }) => theme.colors.lowPure};
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin-right: 0.5rem;
`

export const ChallengeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.72rem;
`

export const ChallengeTx = styled.div`
  color: ${({ theme }) => theme.colors.lowPure};
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin-right: 0.5rem;
`

export const ChallengeDescription = styled.div`
  color: ${({ theme }) => theme.colors.lowPure};
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin-right: 0.5rem;
`
