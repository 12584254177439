import { useState } from 'react'

import { DropdownSkillComponent } from '~/components'

import * as S from './FixedEvidences.styles'

interface IFixedEvidenceProps {
  isOpen: boolean
  onToggle: () => void
  fixedEvidence: {
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }
}

interface IFixedEvidenceComponentProps {
  fixedEvidence: {
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }
}

const FixedEvidence = ({ fixedEvidence, isOpen, onToggle }: IFixedEvidenceProps) => {
  // Verifica se é um array
  const evidenceArray = Array.isArray(fixedEvidence) ? fixedEvidence : [fixedEvidence]

  return (
    <S.ContainerEvidence>
      {evidenceArray?.map((evidence, index) => (
        <DropdownSkillComponent
          key={index}
          isOpen={isOpen}
          onToggle={onToggle}
          txSkillName={evidence.txAbility}
          iconCompetency={evidence.txIconCompetency}
          iconSkill={evidence.txIconSkill}
          txSkillDescription={evidence.txEvidence}
          txBncc=""
          txCompetency={evidence.txCompetency}
        />
      ))}
    </S.ContainerEvidence>
  )
}

export const FixedEvidencesComponent = ({ fixedEvidence }: IFixedEvidenceComponentProps) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)

  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown)
  }

  return (
    <S.Container>
      <S.Title>ASETs da Aula</S.Title>
      <FixedEvidence isOpen={openDropdown} onToggle={() => handleDropdownToggle()} fixedEvidence={fixedEvidence} />
    </S.Container>
  )
}
