import { useAuth } from '~/_context/Auth'

import { AvatarComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({ currentDay, username }: IHeaderComponentProps) => {
  const { user } = useAuth()
  const newAvatar = localStorage.getItem('@LEKTO:avatar')
  return (
    <S.HeaderContainer component="header">
      <AvatarComponent photoUrl={newAvatar || user?.picture || ''} label={username} size="large" />

      <S.TextContentHeader>
        <S.DateTimeInfo variant="caption">{currentDay}</S.DateTimeInfo>

        <S.Title variant="h1">Olá, {username}</S.Title>
      </S.TextContentHeader>
    </S.HeaderContainer>
  )
}
