import { LessonBNCCRelationsPageProvider } from './context'
import { LessonBNCCRelationsView } from './view'

export const LessonBNCCRelationsPage = () => {
  return (
    <LessonBNCCRelationsPageProvider>
      <LessonBNCCRelationsView />
    </LessonBNCCRelationsPageProvider>
  )
}
