import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IMomentProps } from './moment.interfaces'

export const assessmentAPI = async (prop: any) => {
  const { data } = await HTTPClient.patch(
    `/lesson-moment-group/student-score/by-moment/${prop.idLessonMoment}`,
    prop.body,
  )

  return data
}

export const useServiceAssessment = () => useMutation(['assessment'], assessmentAPI)

export const projectAssessmentAPI = async (prop: any) => {
  const { data } = await HTTPClient.patch(
    `/moment/class/${prop.idClass}/students-assessments/by-moment/${String(prop.projectId)}`,
    prop,
  )

  return data
}

export const useProjectServiceAssessment = () => useMutation(['project-assessment'], projectAssessmentAPI)

export const assessmentBatchAPI = async (prop: any) => {
  const { data } = await HTTPClient.patch(
    `/moment/class/${prop.idClass}/students-assessments/by-moment/${String(prop.idLessonMoment)}`,
    prop,
  )

  return data
}

export const useServiceAssessmentBatch = (options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>) =>
  useMutation(['assessmentBatch'], assessmentBatchAPI, options)

/// BATCH PROJECT

export const projectAssessmentBatchAPI = async (prop: any) => {
  const { data } = await HTTPClient.patch(
    `/moment/class/${prop.idClass}/students-assessments/by-moment/${String(prop.projectId)}`,
    prop,
  )

  return data
}
export const useProjectServiceAssessmentBatch = (
  options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>,
) => useMutation(['project-assessment'], projectAssessmentBatchAPI, options)

export const getAssessmentCount = async (idClass: number, idMoment: number) => {
  const { data } = await HTTPClient.get(`/moment/class/${idClass}/assessments-counter/${idMoment}`)

  return data
}

export const useGetAssessmentCount = (idClass: number, idMoment: number) =>
  useQuery<IMomentProps>(['MomentRealization'], () => getAssessmentCount(idClass, idMoment), {
    enabled: !!idClass && !!idMoment,
  })
