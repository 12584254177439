import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import dayjs from 'dayjs'

import { ButtonComponent, DatePickerComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  children,
  actionSetCurrentDay,
  selectedDay,
  actionSetSelectedDay,
  initialOptions,
}: IHeaderComponentProps) => {
  const navigate = useNavigate()
  const changeDatePicker = useCallback(
    (date: Date) => {
      if (!isNaN(Date.parse(String(date)))) {
        actionSetSelectedDay(date)
        actionSetCurrentDay(date)
      }
    },
    [actionSetSelectedDay, actionSetCurrentDay],
  )

  const handleSelectPrev = useCallback(() => {
    if (initialOptions === 'week') {
      const newSelectedDay = dayjs(selectedDay).subtract(7, 'day').toDate()
      actionSetSelectedDay(newSelectedDay)

      actionSetCurrentDay(newSelectedDay)
    } else if (initialOptions === 'day') {
      actionSetSelectedDay(new Date(selectedDay.getTime() - 86400000))
      actionSetCurrentDay(new Date(selectedDay.getTime() - 86400000))
    } else if (initialOptions === 'month') {
      const newSelectedDay = dayjs(selectedDay).subtract(1, 'month').toDate()
      actionSetSelectedDay(newSelectedDay)

      actionSetCurrentDay(newSelectedDay)
    }
  }, [initialOptions, selectedDay, actionSetSelectedDay, actionSetCurrentDay])

  const handleSelectNext = useCallback(() => {
    if (initialOptions === 'week') {
      const newSelectedDay = dayjs(selectedDay).add(7, 'day').toDate()
      actionSetSelectedDay(newSelectedDay)

      actionSetCurrentDay(newSelectedDay)
    } else if (initialOptions === 'day') {
      actionSetSelectedDay(new Date(selectedDay.getTime() + 86400000))
      actionSetCurrentDay(new Date(selectedDay.getTime() + 86400000))
    } else if (initialOptions === 'month') {
      const newSelectedDay = dayjs(selectedDay).add(1, 'month').toDate()
      actionSetSelectedDay(newSelectedDay)

      actionSetCurrentDay(newSelectedDay)
    }
  }, [initialOptions, selectedDay, actionSetSelectedDay, actionSetCurrentDay])

  const handleSelectToday = useCallback(() => {
    actionSetSelectedDay(new Date())
    actionSetCurrentDay(new Date())
  }, [actionSetSelectedDay, actionSetCurrentDay])

  const [options, setOptions] = useState(initialOptions)

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'day') {
      setOptions('day')
      return navigate('/schedule')
    }
    if (event.target.value === 'week') {
      setOptions('week')
      return navigate('/schedule/week')
    }
    if (event.target.value === 'month') {
      setOptions('month')
      return navigate('/schedule/month')
    }
  }

  return (
    <S.HeaderContainer component="header">
      <S.Wrapper>
        <S.Title variant="h1">Agenda</S.Title>

        <S.ContentSelectDate>
          <S.ContentSelectDay>
            <S.ButtonIcon data-testid="btn-back-date" onClick={handleSelectPrev}>
              <UilArrowLeft size={22} color="#0095FF" />
            </S.ButtonIcon>

            <ButtonComponent
              text="Hoje"
              data-testid="btn-today-date"
              variant="outline"
              size="small"
              onClick={handleSelectToday}
            />

            <S.ButtonIcon data-testid="btn-next-date" onClick={handleSelectNext}>
              <UilArrowRight size={22} color="#0095FF" />
            </S.ButtonIcon>
          </S.ContentSelectDay>

          <S.ContentSelectSchedule>
            <Select
              autoWidth={true}
              multiple={false}
              value={options}
              data-testid="type-date-select"
              onChange={handleChange}
              id="schedule-select"
              sx={{
                width: 'fit-content',
                height: '32px',
                color: '#0095FF',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0095FF',
                  borderWidth: '1px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0095FF',
                  borderWidth: '1px',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0095FF',
                  borderWidth: '1px',
                },
                '.MuiSvgIcon-root ': {
                  fill: '#0095FF !important',
                },
              }}
            >
              <MenuItem data-testid="day-picker" key={'day'} value={'day'}>
                Dia
              </MenuItem>
              <MenuItem data-testid="week-picker" key={'week'} value={'week'}>
                Semana
              </MenuItem>
              <MenuItem data-testid="month-picker" key={'month'} value={'month'}>
                Mês
              </MenuItem>
            </Select>
          </S.ContentSelectSchedule>

          <DatePickerComponent
            data-testid="date-picker"
            type={initialOptions}
            value={selectedDay}
            changeValue={(date: Date) => changeDatePicker(date)}
          />
        </S.ContentSelectDate>
      </S.Wrapper>

      {children}
    </S.HeaderContainer>
  )
}
