import { useState } from 'react'

import { UilArrowDown, UilArrowUp, UilHistory } from '@iconscout/react-unicons'

import { ICompetence } from '~/services/Dashboard/types'

import { ButtonComponent, IconComponent, ModalClassHistory } from '~/components'

import {
  HistoricEvolutionChart,
  LessonPerfomanceChart,
  OverviewBySkillComponent,
} from '~/containers/private/StudentDashboard'

import * as S from './OverviewByCompetence.styles'
import { BorderLinearProgress } from './OverviewByCompetence.styles'

interface OverviewBySkillProps {
  competences: ICompetence[]
}

export const formatLabel = (value: any) => {
  if (value === 0) {
    return 'Não observado'
  } else if (value >= 25 && value < 50) {
    return 'A desenvolver'
  } else if (value >= 50 && value < 75) {
    return 'Em início de desenvolvimento'
  } else if (value >= 75 && value < 99) {
    return 'Em desenvolvimento'
  } else if (value === 100) {
    return 'Desenvolvido'
  }
  return 'Não observado'
}

export const OverviewByCompetenceComponent = ({ competences }: OverviewBySkillProps) => {
  const [openItems, setOpenItems] = useState<number[]>([])
  const [modalStates, setModalStates] = useState<{ [competenceId: number]: boolean }>({})

  const competencesFormatted = competences.map((item) => ({
    ...item,
    competenceAverage: item.competenceAverage === undefined ? 0 : item.competenceAverage,
  }))

  const toggleItem = (idCompetence: number) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(idCompetence)) {
        return prevOpenItems.filter((item) => item !== idCompetence)
      } else {
        return [...prevOpenItems, idCompetence]
      }
    })
  }

  const toggleModalHistory = (idCompetence: number) => {
    setModalStates((prevModalStates) => ({
      ...prevModalStates,
      [idCompetence]: !prevModalStates[idCompetence],
    }))
  }

  const renderStatus = (assessment: number) => {
    if (assessment === 0) {
      return 'Não observado'
    } else if (assessment < 50) {
      return 'A desenvolver'
    } else if (assessment >= 50 && assessment < 75) {
      return 'Em início de desenvolvimento'
    } else if (assessment >= 75 && assessment < 99) {
      return 'Em desenvolvimento'
    } else if (assessment === 100) {
      return 'Desenvolvido'
    }
  }

  return (
    <>
      <S.List>
        {competencesFormatted.map((competence) => (
          <>
            <S.ListItem key={competence.idCompetence}>
              {modalStates[competence.idCompetence] && (
                <ModalClassHistory
                  competenceId={competence.idCompetence}
                  open={modalStates[competence.idCompetence]}
                  moments={competence.moments}
                  competence={competence}
                  onClose={() => toggleModalHistory(competence.idCompetence)}
                />
              )}
              <S.ContainerItem onClick={() => toggleItem(competence.idCompetence)}>
                <S.ContainerIcon>
                  <IconComponent
                    color={`#${competence.competenceColor}`}
                    fontWeight="normal"
                    code={competence.competenceIcon}
                    size="xsmall"
                  />
                </S.ContainerIcon>
                <S.MainContainerItem>
                  <S.ContainerTitle>
                    <S.Title>{competence.competenceName}</S.Title>
                    <S.Status>{renderStatus(competence.competenceAverage)}</S.Status>
                  </S.ContainerTitle>
                  <BorderLinearProgress
                    variant="determinate"
                    competenceColor={competence.competenceColor}
                    value={competence.competenceAverage}
                  />
                </S.MainContainerItem>
                <S.ContainerAction>
                  {openItems.includes(competence.idCompetence) ? (
                    <S.Arrow>
                      <UilArrowUp size={24} color="#7C8189" />
                    </S.Arrow>
                  ) : (
                    <S.Arrow>
                      <UilArrowDown size={24} color="#7C8189" />
                    </S.Arrow>
                  )}
                </S.ContainerAction>
              </S.ContainerItem>
              {openItems.includes(competence.idCompetence) && (
                <S.CompetenceContainer gap={5} display={'grid'} paddingX={2} paddingY={2} maxWidth={'100%'}>
                  <S.BoxOverview>
                    <h3>Habilidades</h3>
                    <OverviewBySkillComponent competences={competence} type={'card'} />
                  </S.BoxOverview>
                  <HistoricEvolutionChart data={competence.historicalEvolution} color={competence.competenceColor} />
                  <LessonPerfomanceChart
                    competenceId={competence.idCompetence}
                    moments={competence.moments}
                    color={competence.competenceColor}
                  />
                  <S.BoxButton>
                    <ButtonComponent
                      variant={'outline'}
                      size={'medium'}
                      fullWidth
                      onClick={() => toggleModalHistory(competence.idCompetence)}
                      text={'Históricos de aulas'}
                      iconStart={<UilHistory size={16} color={'#0095FF'} />}
                    />
                  </S.BoxButton>
                </S.CompetenceContainer>
              )}
            </S.ListItem>
          </>
        ))}
      </S.List>
    </>
  )
}
