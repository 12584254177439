import { Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const CardActivity = styled.div<{ isTrail: boolean }>`
  flex-direction: row;
  height: 100%;
  width: 100%;
  display: block;
  align-items: center;
  padding: 8px;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  border-radius: 8px;

  &:hover {
    box-shadow: ${({ isTrail, theme }) => (isTrail ? theme.shadows['shadow-level-1'] : '')};
  }
`
export const ContentActivity = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`
export const ContentLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`
export const Row = styled.div<{ isTrail: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  cursor: ${({ isTrail }) => (isTrail ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.colors.quaternaryPure};
  transition: all 0.5s;

  &:hover {
    color: ${({ isTrail, theme }) => (isTrail ? theme.colors.quaternaryDark : '')};
  }
`
export const TextStepDescriptionActivity = styledMUI(Typography)<{ color: string }>(({ variant }) => ({
  fontSize: '14px',
  border: 'none',
  color: variant,
  lineHeight: '120%',
  '& span:first-child': {
    fontWeight: 400,
  },
  '& span': {
    fontWeight: 700,
    maxWidth: '90%',
  },
}))

export const textLesson = styled.span``

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`
export const Arrow = styled.div`
  flex-direction: column;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.quaternaryDark};
  }

  @media (max-width: 768px) {
    margin-top: -14%;
    margin-left: 55%;
  }
`

export const WrapperEvidence = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  gap: 6px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const Label = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
`
export const TextChallenge = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
`
