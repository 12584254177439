import { useState } from 'react'

import { UilMultiply } from '@iconscout/react-unicons'

import { useNavigation } from '~/_context/Navigation'

import { ModalComponent, MomentCardComponent } from '~/components'

import { titleByNuOrder } from '~/pages/private/Schedule/view'
import { Item } from '~/pages/private/ScheduleByMonth/interfaces'

import * as S from './ModalScheduleList.styles'

interface IModalScheduleList {
  momentTime: string
  remainingMoments: Item[]
}

export const ModalScheduleList = ({ momentTime, remainingMoments }: IModalScheduleList) => {
  const { handleNavigation } = useNavigation()

  const [showModalScheduleList, setShowModalScheduleList] = useState<boolean>(false)

  const handleCloseModal = () => {
    setShowModalScheduleList(false)
  }

  return (
    <>
      <S.ButtonSeeMore onClick={() => setShowModalScheduleList(true)} size="small">
        Ver mais {remainingMoments.length} {remainingMoments.length === 1 ? 'aula' : 'aulas'}
      </S.ButtonSeeMore>

      {showModalScheduleList && (
        <ModalComponent open={showModalScheduleList} actionCloseModal={handleCloseModal} maxHeight={'90vh'}>
          <S.Container>
            <S.WrapperTitle>
              <S.Title variant="h1">Aulas restantes do dia: {momentTime}</S.Title>

              <S.ButtonClose onClick={handleCloseModal}>{<UilMultiply size={15} color="#000" />}</S.ButtonClose>
            </S.WrapperTitle>

            <S.Content className={'styled-scroll-s'}>
              {remainingMoments.map((scheduleMoment) => (
                <MomentCardComponent
                  key={scheduleMoment?.idMoment}
                  type={'default'}
                  momentStatusCode={scheduleMoment?.coMomentStatus}
                  momentStatusText={scheduleMoment?.txMomentStatus}
                  totalClass={Number(scheduleMoment?.nuTrackStages)}
                  nuOrder={scheduleMoment?.nuOrder}
                  momentTime={scheduleMoment?.dtSchedule}
                  idClass={scheduleMoment?.class?.idClass}
                  coStage={scheduleMoment?.class?.schoolGrade?.grade?.coStage}
                  txTrackTitle={scheduleMoment?.txTrackTitle}
                  idMomentTrack={scheduleMoment?.idMomentTrack}
                  momentTitle={
                    scheduleMoment?.class?.schoolGrade?.grade?.coStage === 'F2'
                      ? (titleByNuOrder(scheduleMoment?.nuOrder) as string)
                      : (scheduleMoment?.txTitle as string)
                  }
                  momentClassInfo={scheduleMoment?.class}
                  action={() =>
                    handleNavigation(
                      scheduleMoment?.class?.schoolGrade?.grade?.coStage,
                      scheduleMoment?.idMoment,
                      scheduleMoment?.class?.idClass,
                      '/home',
                    )
                  }
                />
              ))}
            </S.Content>
          </S.Container>
        </ModalComponent>
      )}
    </>
  )
}
