import { UilArrowRight, UilCalender } from '@iconscout/react-unicons'
import { Box } from '@mui/material'

import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent, ChipComponent, SkeletonComponent, StatusTextComponent } from '~/components'

import { getFormattedLabelWithDay } from '~/validations/formattLabelWithDay'
import { translateCoStage } from '~/validations/translateCoStage'

import { ActiveMomentCardComponent } from '../ActiveMomentCard/ActiveMomentCard'
import { IMomentCardComponentProps } from './MomentCard.interfaces'
import * as S from './MomentCard.styles'

export const MomentCardComponent = ({
  loading,
  momentStatusCode,
  momentStatusText,
  momentTitle,
  momentClassInfo,
  momentTime,
  action,
  momentAbilities,
  cardbackgroundcolor,
  nuOrder,
  totalClass,
  type = 'default',
  chipOld,
  coGrade,
  txGrade,
  coStage,
  txClassName,
  txTrackTitle,
  idClass,
  idMomentTrack,
  isOffline,
  showChip,
}: IMomentCardComponentProps) => {
  const { user } = useAuth()
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 600
  let typeChip = ''
  switch (momentStatusCode) {
    case 'AGEN':
      typeChip = 'disabled'
      break

    case 'AUPL':
      typeChip = 'toDo'
      break

    case 'AVPE':
      typeChip = 'warning'
      break

    case 'INIC':
      typeChip = 'toDo'
      break

    case 'AGUA':
      typeChip = 'toDo'
      break

    case 'FINA':
      typeChip = 'play'
      break

    case 'PEND':
      typeChip = 'light'
      break

    case 'AUPE':
      typeChip = 'danger'
      break
  }

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '..'
    }
    return text
  }
  const widthSize = width || 0
  const isResponsable = user?.role.includes('pais')

  return (
    <S.Container
      onClick={!isResponsable ? action : undefined}
      data-testid="join-class"
      cardbackgroundcolor={cardbackgroundcolor}
      type={type}
      isResponsable={isResponsable}
    >
      {type !== 'month' && !isMobile && (
        <S.Wrapper type={type}>
          <S.ContentTimeStatus type={type}>
            {loading ? (
              <SkeletonComponent width={120} />
            ) : (
              <>
                {type !== 'week' && (
                  <ActiveMomentCardComponent
                    txTrackTitle={txTrackTitle}
                    idMomentTrack={idMomentTrack}
                    coStage={coStage}
                    isOffline={isOffline?.toString()}
                    idClass={idClass}
                    lessons={totalClass}
                    thisLesson={Number(nuOrder)}
                    color={momentStatusCode}
                  />
                )}
                <StatusTextComponent
                  size={type === 'week' ? '12px' : undefined}
                  activeTextOverflow={type === 'week' ? true : false}
                  statusCode={momentStatusCode}
                  statusText={momentStatusText}
                />
              </>
            )}
          </S.ContentTimeStatus>

          {loading ? (
            <SkeletonComponent variant="circular" height={24} width={24} />
          ) : (
            <>
              <S.Wrapper>
                <S.WrapperChip>
                  {type !== 'week' && (
                    <>
                      <ChipComponent
                        icon={<UilCalender size={15} />}
                        type={typeChip}
                        label={getFormattedLabelWithDay(String(momentTime))}
                      />
                      {!isResponsable && (
                        <ButtonComponent
                          variant={'outline'}
                          size={'xsmall'}
                          title={'Acessar aula'}
                          data-testid="join-class-button"
                          fontWeight="normal"
                          text={'Acessar aula'}
                          onClick={() => action}
                          iconEnd={<UilArrowRight size={16} />}
                        />
                      )}
                    </>
                  )}
                  {type === 'week' && (
                    <>
                      <S.ButtonIcon data-testid="join-class-button" onClick={() => action}>
                        <UilArrowRight size={20} color="#7C8189" />
                      </S.ButtonIcon>
                    </>
                  )}
                </S.WrapperChip>
              </S.Wrapper>
            </>
          )}
        </S.Wrapper>
      )}
      {type !== 'month' && isMobile && (
        <S.Wrapper type={type}>
          <S.ContentTimeStatus type={type}>
            {loading ? (
              <SkeletonComponent width={120} />
            ) : (
              <>
                {type !== 'week' && (
                  <>
                    <S.RowBetween>
                      <ActiveMomentCardComponent
                        lessons={totalClass}
                        isOffline={isOffline}
                        thisLesson={Number(nuOrder)}
                        color={momentStatusCode}
                        txTrackTitle={txTrackTitle}
                        idMomentTrack={idMomentTrack}
                        coStage={momentClassInfo?.schoolGrade?.grade?.coStage}
                        idClass={idClass}
                      />
                      {!isResponsable && (
                        <S.ButtonIconMobile data-testid="join-class-button" onClick={() => action}>
                          <UilArrowRight size={20} color="#0095FF" />
                        </S.ButtonIconMobile>
                      )}
                    </S.RowBetween>
                  </>
                )}
                <StatusTextComponent
                  size={type === 'week' ? '12px' : undefined}
                  statusCode={momentStatusCode}
                  statusText={momentStatusText}
                />
                {type !== 'week' && showChip && (
                  <>
                    <ChipComponent
                      icon={<UilCalender size={15} />}
                      type={typeChip}
                      label={getFormattedLabelWithDay(String(momentTime))}
                    />
                  </>
                )}
              </>
            )}
          </S.ContentTimeStatus>

          {loading ? (
            <SkeletonComponent variant="circular" height={24} width={24} />
          ) : (
            <>
              <S.Wrapper>
                <S.WrapperChip>
                  {type === 'week' && (
                    <>
                      <S.ButtonIcon data-testid="join-class-button" onClick={() => action}>
                        <UilArrowRight size={20} color="#7C8189" />
                      </S.ButtonIcon>
                    </>
                  )}
                </S.WrapperChip>
              </S.Wrapper>
            </>
          )}
        </S.Wrapper>
      )}
      <Box display={'flex'} justifyContent={'left'} gap={1}>
        {type === 'month' && (
          <StatusTextComponent type={'pointer'} statusCode={momentStatusCode} statusText={momentStatusText} />
        )}

        <Box display={'grid'}>
          <S.Title type={type}>
            {loading ? <SkeletonComponent width={widthSize > 600 ? 400 : 100} /> : momentTitle}
          </S.Title>
        </Box>
      </Box>
      {momentAbilities && (
        <S.ContainerAbilities>
          {momentAbilities.map((ability) => (
            <S.Ability key={ability.idAbility}>
              <S.TextAbility>{ability.txName}</S.TextAbility>
              <S.StatusAbility>{ability.txAssessment}</S.StatusAbility>
            </S.Ability>
          ))}
        </S.ContainerAbilities>
      )}
      {loading ? (
        <SkeletonComponent variant="text" height={24} width={100} />
      ) : (
        <>
          {type !== 'month' && type !== 'week' && (
            <S.Wrapper>
              <S.ContainerTrackClass>
                {!chipOld && (
                  <>
                    <S.ClassSpanInfo>
                      {truncateText(translateCoStage(momentClassInfo?.schoolGrade?.grade?.coStage), 18)}
                    </S.ClassSpanInfo>
                    <S.ClassSpanInfo>{truncateText(momentClassInfo?.schoolGrade?.grade?.txGrade, 9)}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>
                      <S.ClassSpanInfo>{truncateText(momentClassInfo?.txName, 7)}</S.ClassSpanInfo>
                    </S.ClassSpanInfo>
                  </>
                )}
                {chipOld && (
                  <>
                    <S.ClassSpanInfo>{translateCoStage(coGrade)}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>{txGrade}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>{txClassName}</S.ClassSpanInfo>
                  </>
                )}
              </S.ContainerTrackClass>
            </S.Wrapper>
          )}
        </>
      )}
      {type === 'week' && (
        <>
          <S.TextDescription>{momentClassInfo}</S.TextDescription>
        </>
      )}
    </S.Container>
  )
}
