import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { gaEvents } from '~/events'

import { useAuth } from '~/_context/Auth'

import { useGQLServiceGetClassInfosByClassID } from '~/graphql/Classes/queries'

import { useServiceGetLesson } from '~/services/Lesson'
import { useGetTrailById } from '~/services/Trails'

import {
  ITrails,
  ITrailsGeneratedContextProps,
  ITrailsGeneratedProviderProps,
} from '~/pages/private/Fund1/TrailsGenerated/interface'

const TrailsGeneratedContext = createContext<ITrailsGeneratedContextProps>({
  isSameUser: false,
} as ITrailsGeneratedContextProps)

const TrailsGeneratedProvider = ({ children }: ITrailsGeneratedProviderProps) => {
  const { trailId, classId, idLessonMoment } = useParams()
  const location = useLocation()
  const [openModalBNCC, setOpenModalBNCC] = useState<boolean>(false)
  const [openModalSchedule, setOpenModalSchedule] = useState<boolean>(false)
  const [trailIndex, setTrailIndex] = useState(0)

  const { user } = useAuth()

  const { data } = useServiceGetLesson(Number(idLessonMoment), { enabled: !!idLessonMoment })

  const idUserProfessor = data?.idUserProfessor

  const isSameUser = user?.id_user === idUserProfessor

  const {
    isLoading: isLoadingTrailsNotGenerated,
    data: dataClassDetails,
    refetch: refetchDataClass,
  } = useGetTrailById(Number(classId), Number(trailId))

  const { data: dataClassInfos } = useGQLServiceGetClassInfosByClassID(Number(classId), false)

  const trails: ITrails[] = [
    {
      title: 'Ideação e Planejamento',
      status: 'FINA',
      time: '29/03/2023 - 14:30',
    },
    {
      title: 'Title 2',
      status: 'FINA',
      time: '29/03/2023 - 14:30',
    },
    {
      title: 'Title 3',
      status: 'AGUA',
      time: '29/03/2023 - 14:30',
    },
    {
      title: 'Title 4',
      status: 'PEND',
      time: '29/03/2023 - 14:30',
    },
  ]

  const navigate = useNavigate()

  const handleOpenModalBNCC = useCallback(() => {
    setOpenModalBNCC(true)
  }, [])

  const handleOpenModalSchedule = useCallback(() => {
    if (classId && trailId) {
      sessionStorage.setItem('previousPath', location.pathname)
      navigate(`/schedule/${classId}/track/${trailId}`)
    }
    gaEvents.eventSelectNewTrailButton()
  }, [classId, trailId, location.pathname, navigate])

  const handleCloseModalBNCC = useCallback(() => {
    setOpenModalBNCC(false)
  }, [])

  const handleCloseModalSchedule = useCallback(() => {
    setOpenModalSchedule(false)
  }, [])

  const handleSliderPrev = useCallback(() => {
    if (trailIndex > 0) {
      setTrailIndex(trailIndex - 1)
    }
  }, [trailIndex])

  const handleSliderNext = useCallback(() => {
    if (trailIndex < trails.length - 1) {
      setTrailIndex(trailIndex + 1)
    }
  }, [trailIndex, trails.length])

  const major = dataClassDetails?.component
  const removePrincipal = major?.filter((item: any) => item.coComponentType === 'PRI')
  const componentsFilter = dataClassDetails?.component?.filter(
    (item: any) => item.txComponent !== removePrincipal?.[0]?.txComponent,
  )

  const handleClickArrow = useCallback(
    (moment: any) => {
      navigate(`/class/${classId}/lesson/${moment}`, { state: { from: location.pathname } })
      gaEvents.eventAccessLessonButton()
    },
    // eslint-disable-next-line
    [navigate, location.pathname],
  )

  const trailsGeneratedProviderValues = useMemo(() => {
    return {
      openModalBNCC,
      handleOpenModalBNCC,
      handleCloseModalBNCC,
      openModalSchedule,
      handleOpenModalSchedule,
      handleCloseModalSchedule,
      handleClickArrow,
      handleSliderPrev,
      handleSliderNext,
      trailIndex,
      isLoadingTrailsNotGenerated,
      dataClassDetails,
      componentsFilter,
      dataClassInfos,
      refetchDataClass,
      isSameUser,
    }
  }, [
    openModalBNCC,
    handleOpenModalBNCC,
    handleCloseModalBNCC,
    openModalSchedule,
    handleOpenModalSchedule,
    handleCloseModalSchedule,
    handleClickArrow,
    handleSliderPrev,
    handleSliderNext,
    trailIndex,
    isLoadingTrailsNotGenerated,
    dataClassDetails,
    componentsFilter,
    dataClassInfos,
    refetchDataClass,
    isSameUser,
  ])

  return (
    <TrailsGeneratedContext.Provider value={trailsGeneratedProviderValues}>{children}</TrailsGeneratedContext.Provider>
  )
}

const useTrailsGeneratedContext = () => useContext(TrailsGeneratedContext)

export { TrailsGeneratedProvider, useTrailsGeneratedContext }
