import { useDrag } from 'react-dnd'

import { Box } from '@mui/material'
import dayjs from 'dayjs'

import * as S from '~/components/Modals/TimelineLesson/TimeLesson.styles'

interface ITagLessonContainer {
  day: dayjs.Dayjs
  lessonIndex: number | null
  projectType: number | null
  disabled: boolean
}

export const TagLessonContainer = ({ lessonIndex, disabled, projectType }: ITagLessonContainer) => {
  const [, lessonDrag] = useDrag(() => {
    return {
      type: 'CARD',
      item: () => ({
        lessonIndex: Number(lessonIndex) - 1,
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: lessonIndex !== null && lessonIndex >= 0 && !disabled,
    }
  }, [lessonIndex, disabled])

  return (
    <S.TagContainer ref={lessonDrag} disabled={disabled}>
      <S.LessonTag>
        {projectType === 1 ? 'Aula' : 'Etapa'} {lessonIndex}{' '}
      </S.LessonTag>
    </S.TagContainer>
  )
}

export const TrailContainer = ({
  isLessonDay,
  isFirstLesson,
  isLastLesson,
  isTrailDay,
  dates,
  day,
  projectType,
}: {
  isLessonDay: boolean
  projectType: number | null
  isFirstLesson: boolean
  dates: { dtSchedule: string; disabled: boolean; order: number }[]
  isLastLesson: boolean
  isTrailDay: boolean
  day: dayjs.Dayjs
}) => {
  let className = ''
  const lessonDates = dates?.filter((date) => day.isSame(dayjs(date.dtSchedule), 'day'))

  if (isFirstLesson) {
    className = 'first-lesson'
  } else if (isLastLesson) {
    className = 'last-lesson'
  } else if (isLessonDay) {
    className = 'lesson'
  } else if (isTrailDay) {
    className = 'trail'
  } else {
    className = 'disabled'
  }

  if (!isLessonDay && !isFirstLesson && !isLastLesson && !isTrailDay) {
    return (
      <Box
        display={isFirstLesson || isLastLesson ? 'flex' : 'initial'}
        justifyContent={isFirstLesson ? 'right' : isLastLesson ? 'left' : 'initial'}
      >
        <S.TrailContainer className={className}></S.TrailContainer>
      </Box>
    )
  }

  return (
    <Box
      display={isFirstLesson || isLastLesson ? 'flex' : 'initial'}
      justifyContent={isFirstLesson ? 'right' : isLastLesson ? 'left' : 'initial'}
    >
      <S.TrailContainer className={className}>
        {(className?.includes('lesson') ||
          className?.includes('first-lesson') ||
          className?.includes('last-lesson')) && (
          <Box display={'grid'} gap={'8px'}>
            {lessonDates &&
              lessonDates?.map((date) => (
                <TagLessonContainer
                  projectType={projectType}
                  key={`tag-line-${date.order}`}
                  lessonIndex={date.order + 1}
                  day={day}
                  disabled={date.disabled}
                />
              ))}
          </Box>
        )}
      </S.TrailContainer>
    </Box>
  )
}
