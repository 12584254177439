import { IDataClassInfosProps } from './interfaces'

export const defaultDataInfosState: IDataClassInfosProps = {
  class: {
    items: [
      {
        idClass: 0,
        txName: '',

        schoolGrade: {
          grade: {
            coGrade: '',
            txGrade: '',

            stage: {
              coStage: '',
              txName: '',
            },
          },

          school: {
            idSchool: 0,
            txName: '',
          },
        },

        studentClass: {
          student: {
            idStudent: 0,

            lektoUser: {
              txName: '',
              txImagePath: '',
            },
          },
        },
      },
    ],
  },
}
