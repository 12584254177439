import { UilClock } from '@iconscout/react-unicons'

import {
  AvatarGroupComponent,
  ChipComponent,
  DropdownSkillComponent,
  SkeletonComponent,
  StepsTabsComponent,
} from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  duration,
  activedTab,
  actionSetActiveTab,
  avatars,
  evidence,
  status,
  openModal,
  title,
}: IHeaderComponentProps) => {
  return (
    <S.HeaderContainer component="header">
      <S.ContentHeader>
        <S.Wrapper>
          <S.TitleLight variant="h1">Passo 3</S.TitleLight>

          <S.TitleBold variant="h1">{title}</S.TitleBold>
        </S.Wrapper>

        {duration ? (
          <ChipComponent type="light" icon={<UilClock size={20} />} label={duration + ' minutos'} />
        ) : (
          <SkeletonComponent width={80} height={40} />
        )}

        {avatars && (
          <S.Wrapper>
            <S.TextLabel color="#666" variant="caption">
              Turma inteira
            </S.TextLabel>

            <S.WrapperStudents onClick={openModal}>
              <AvatarGroupComponent images={avatars} size="small" max={4} />
            </S.WrapperStudents>
          </S.Wrapper>
        )}
      </S.ContentHeader>

      <S.ContentFixedSkill>
        <S.Wrapper>
          <S.TextLabel color="#000" variant="caption">
            Habilidade Fixa
          </S.TextLabel>

          <DropdownSkillComponent
            txSkillName={evidence?.ability?.txName}
            iconSkill={evidence?.ability?.txImagePath}
            txSkillDescription={evidence?.txName}
            iconCompetency={evidence?.ability?.competence?.txImagePath}
            txCompetency={evidence?.ability?.competence?.txName}
          />
        </S.Wrapper>
      </S.ContentFixedSkill>

      <S.ContentTabs>
        <StepsTabsComponent
          status={status}
          type={'thirdStep'}
          activedTab={activedTab}
          actionSetActiveTab={(newActivedTab: number) => actionSetActiveTab(newActivedTab)}
        />
      </S.ContentTabs>
    </S.HeaderContainer>
  )
}
