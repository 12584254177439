import { CustomDndProvider } from '~/components'

import { TrailsGeneratedProvider } from '~/pages/private/Fund1/TrailsGenerated/context'

import { TrailsGeneratedView } from './view'

export const TrailsGeneratedPageF1 = () => {
  return (
    <TrailsGeneratedProvider>
      <CustomDndProvider>
        <TrailsGeneratedView />
      </CustomDndProvider>
    </TrailsGeneratedProvider>
  )
}
