import { createContext, useContext, useMemo } from 'react'

import { useGetResponsableStudents } from '~/services/User'

import { IResponsableStudentsPageContextProps, IResponsableStudentsPageProviderProps } from './interfaces'

const ResponsableStudentsPageContext = createContext<IResponsableStudentsPageContextProps>({
  isLoading: false,
  data: undefined,
})

const ResponsableStudentsPageProvider = ({ children }: IResponsableStudentsPageProviderProps) => {
  const { data, isLoading } = useGetResponsableStudents()

  const listClassesPageProviderValues = useMemo(() => {
    return {
      data,
      isLoading,
    }
  }, [data, isLoading])

  return (
    <ResponsableStudentsPageContext.Provider value={listClassesPageProviderValues}>
      {children}
    </ResponsableStudentsPageContext.Provider>
  )
}

const useResponsableStudentsPageContext = () => useContext(ResponsableStudentsPageContext)

export { ResponsableStudentsPageProvider, useResponsableStudentsPageContext }
