import CircularProgress from '@mui/material/CircularProgress'

import { IButtonComponentProps } from './Button.interfaces'
import * as S from './Button.styles'

export const ButtonComponent = ({
  color,
  text,
  iconStart,
  iconEnd,
  variant = 'outline',
  fullWidth = false,
  fontWeight = 'bold',
  size,
  fontColor,
  fontSize,
  loading,
  sizeLoading = 25,
  ...rest
}: IButtonComponentProps) => {
  return (
    <>
      {loading ? (
        <S.Button
          {...rest}
          variant={variant}
          size={size}
          fullWidth={fullWidth}
          fontWeight={fontWeight}
          fontSize={fontSize}
          fontColor={fontColor}
          color={color}
        >
          {iconStart}
          <CircularProgress size={sizeLoading} color="inherit" />
        </S.Button>
      ) : (
        <S.Button
          {...rest}
          variant={variant}
          size={size}
          fullWidth={fullWidth}
          fontWeight={fontWeight}
          fontSize={fontSize}
          fontColor={fontColor}
          color={color}
        >
          {iconStart}
          {variant === 'animated' ? <span className="animate-text">{text}</span> : text}
          {iconEnd}
        </S.Button>
      )}
    </>
  )
}
