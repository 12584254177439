import { UilEye } from '@iconscout/react-unicons'

import { ButtonComponent, SkeletonComponent } from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'
import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import useIsMobile from '~/validations/isMobile'

import { ICardTrailComponentProps } from './CardTrail.interfaces'
import * as S from './CardTrail.styles'

export const CardTrailComponent = ({ id, loading, title, subjects, actionShowDetails }: ICardTrailComponentProps) => {
  const isMobile = useIsMobile()

  const combinedTexts = subjects?.map((subject) => subject.txComponent)
  return (
    <S.CardContainer>
      <S.WrapperBetween>
        {loading || title === undefined ? (
          <SkeletonComponent height={30} variant={'rounded'} width={340} />
        ) : (
          <DSTooltip text={title} position="top">
            <S.Title variant="caption"> {title}</S.Title>
          </DSTooltip>
        )}

        <S.WrapperChip>
          {loading ? (
            <SkeletonComponent height={30} variant={'rounded'} width={200} />
          ) : (
            <ButtonComponent
              size="xsmall"
              text="Explorar trilha"
              fontWeight="normal"
              fontSize="small"
              data-testid={'exploration-trail-button-' + id}
              fullWidth={isMobile ? true : false}
              variant="solid"
              iconEnd={<UilEye size={16} color="white" />}
              onClick={actionShowDetails}
            />
          )}
        </S.WrapperChip>
      </S.WrapperBetween>

      <>
        {loading ? (
          <>
            {Object.keys(new Array(1).fill(null)).map((_, index: number) => (
              <SkeletonComponent key={index} variant="rounded" height={26} width={70} />
            ))}
          </>
        ) : (
          <>
            <S.WrapperOverflow className="styled-scroll">
              <TagClass variant={'underline'} texts={combinedTexts || []} />
            </S.WrapperOverflow>
          </>
        )}
      </>
    </S.CardContainer>
  )
}
