import { pdfjs } from 'react-pdf'
import { useParams } from 'react-router-dom'

import { UilFileDownloadAlt } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import { pdf } from '@react-pdf/renderer'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import { BackNavigationComponent, ButtonComponent, StepsTabsComponent } from '~/components'

import { PDFDocument } from '../../../../../pages/private/Fund1/Planning/DocumentPDF/PDFDocument'
import * as S from './Header.styles'

interface IHeaderPlanning {
  handleSetActiveTab: (newActivedTab: number) => void
  activedTab: number
  txNameLesson: string
  guidance: string
  steps: any
  evidencesByActivityOnStepTwo: any
  fixedEvidences: any
  isOffline?: boolean
}

export const HeaderPlanning = ({
  handleSetActiveTab,
  activedTab,
  txNameLesson,
  guidance,
  steps,
  evidencesByActivityOnStepTwo,
  fixedEvidences,
  isOffline,
}: IHeaderPlanning) => {
  const { idLessonMoment, idClass } = useParams()
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const tabs = [
    { label: 'Habilidades' },
    { label: isMobile && activedTab !== 1 ? '1' : activedTab === 1 && isMobile ? 'Passo 1' : 'Passo 1 Projetar' },
    { label: isMobile && activedTab !== 2 ? '2' : activedTab === 2 && isMobile ? 'Passo 2' : 'Passo 2 Realizar' },
    { label: isMobile && activedTab !== 3 ? '3' : activedTab === 3 && isMobile ? 'Passo 3' : 'Passo 3 Revisar' },
  ]

  const generatePdfDocument = async (fileName: string) => {
    const blob = await pdf(
      <PDFDocument
        guidance={guidance}
        steps={steps}
        txTitleLesson={txNameLesson}
        evidencesByActivityOnStepTwo={evidencesByActivityOnStepTwo}
        fixedEvidences={fixedEvidences}
      />,
    ).toBlob()
    const data = (window.webkitURL || window.URL).createObjectURL(blob)
    const link = document.createElement('a')
    link.href = data
    link.download = fileName?.replace(/"/g, '')
    link.click()

    setTimeout(function () {
      ;(window.webkitURL || window.URL).revokeObjectURL(data)
    }, 100)
  }
  return (
    <S.HeaderContainer id={'header'}>
      <S.ContainerTitle>
        <S.ContentBack>
          <BackNavigationComponent
            small
            label={txNameLesson}
            path={isOffline ? `/offline/lesson/${idLessonMoment}` : `/class/${idClass}/lesson/${idLessonMoment}`}
          />
        </S.ContentBack>
        <S.ContentBack>
          <S.Title>Planejamento da aula</S.Title>
          {!isOffline && (
            <Tooltip title={'Download em PDF'} placement="top">
              <div>
                <ButtonComponent
                  variant="outline"
                  iconEnd={<UilFileDownloadAlt size="20" />}
                  size="small"
                  fontWeight={'normal'}
                  fullWidth={false}
                  onClick={() => generatePdfDocument('planejamento')}
                  text="Download"
                />
              </div>
            </Tooltip>
          )}
        </S.ContentBack>
      </S.ContainerTitle>
      <S.ContainerStep>
        <StepsTabsComponent
          type={'default'}
          steps={tabs}
          activedTab={activedTab}
          actionSetActiveTab={handleSetActiveTab}
        />
      </S.ContainerStep>
    </S.HeaderContainer>
  )
}
