import { useEffect, useRef, useState } from 'react'

import { UilFile, UilEye } from '@iconscout/react-unicons'
import LinearProgress from '@mui/material/LinearProgress'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { renderMedia } from '~/services/Media'

import { AudioComponent, CarouselComponent } from '~/components'
import { IMediaInformation } from '~/components/Cards/CardDocumentation/CardDocumentation.interfaces'

import * as S from './MediaInformation.styles'
import { ModalMediaList } from './ModalMediaList/ModalMediaList'
import { ModalMediaListMobile } from './ModalMediaList/ModalMediaListMobile'

type IMediaInformationProps = {
  medias: IMediaInformation[]
  displayMode?: 'fullScreen' | 'modal'
  txMomentNotes?: string | undefined
}

export type MediaProps = {
  srcMedia: string
  blob: Blob
  mediaType: string
}

export const MediaInformationComponent = ({ medias, txMomentNotes }: IMediaInformationProps) => {
  const [mediaList, setMediaList] = useState<MediaProps[]>([])
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [modalVisible, setModalVisible] = useState(false)

  const mediasToRender = medias.filter(
    (m) =>
      m.txContentType.includes('image') ||
      m.txContentType.includes('video') ||
      m.txContentType === 'application/octet-stream',
  )

  const prevIdListRef = useRef<IMediaInformation[]>()

  const documents = medias.filter((m) => m.txContentType === 'application/pdf')

  const [mediaLoading, setMediaLoading] = useState<boolean>(true)

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc)
    setModalVisible(true)
  }

  const exitFullscreen = () => {
    setModalVisible(false)
    setSelectedImage(null)
  }

  const handleDocumentClick = (txAbsoluteUrl: string) => {
    window.open(txAbsoluteUrl, '_blank')
  }

  useEffect(() => {
    async function handleImageFetch() {
      const multipleImages = await Promise.all(
        mediasToRender.map(async (media) => {
          return renderMedia(media.idMediaInformation).then((response) => {
            return {
              srcMedia: URL.createObjectURL(response),
              blob: response,
              mediaType: media.txContentType,
            }
          })
        }),
      )

      setMediaList(multipleImages)
      setMediaLoading(false)
    }

    if (JSON.stringify(prevIdListRef.current) !== JSON.stringify(mediasToRender)) {
      prevIdListRef.current = mediasToRender
      setMediaLoading(true)
      handleImageFetch()
    }
  }, [mediasToRender])

  const mediaImageAndVideoList = mediaList.filter(
    (m) => m.mediaType !== 'application/octet-stream' && !m.mediaType.includes('pdf'),
  )

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  return (
    <>
      {mediaLoading && mediasToRender.length > 0 ? (
        <>
          <S.ContentLoad>
            <S.LinearProgressContainer>
              <S.SpanLoad>Carregando...</S.SpanLoad>
              <LinearProgress />
            </S.LinearProgressContainer>
          </S.ContentLoad>
        </>
      ) : (
        <>
          {modalVisible && selectedImage && (
            <>
              {isMobile ? (
                <ModalMediaListMobile
                  modalVisible={modalVisible}
                  mediaImageAndVideoList={mediaImageAndVideoList}
                  handleImageClick={handleImageClick}
                  exitFullscreen={exitFullscreen}
                  txMomentNotes={txMomentNotes}
                />
              ) : (
                <ModalMediaList
                  modalVisible={modalVisible}
                  mediaImageAndVideoList={mediaImageAndVideoList}
                  handleImageClick={handleImageClick}
                  exitFullscreen={exitFullscreen}
                  txMomentNotes={txMomentNotes}
                />
              )}
            </>
          )}
          {mediaImageAndVideoList && mediaImageAndVideoList.length > 0 && (
            <CarouselComponent length={mediaImageAndVideoList.length}>
              {mediaImageAndVideoList.map((media) => (
                <>
                  {media?.mediaType?.includes('video') ? (
                    <S.ContainerVideo key={media.srcMedia}>
                      <S.Video
                        controls
                        controlsList="nodownload noplaybackrate"
                        src={media.srcMedia}
                        key={media.srcMedia}
                        onClick={() => handleImageClick(media?.srcMedia)}
                      />
                    </S.ContainerVideo>
                  ) : (
                    <S.Image
                      src={media?.srcMedia}
                      key={media.srcMedia}
                      onClick={() => handleImageClick(media?.srcMedia)}
                    />
                  )}
                </>
              ))}
            </CarouselComponent>
          )}
          <S.ContainerAudio>
            {mediaList &&
              mediaList?.map(
                (media) =>
                  media?.mediaType === 'application/octet-stream' && (
                    <AudioComponent file={media.srcMedia} key={media.srcMedia} />
                  ),
              )}
          </S.ContainerAudio>
        </>
      )}

      {documents && documents.length > 0 && (
        <S.ContainerDocument>
          {documents.map((document) => (
            <S.Document key={document.idMediaInformation} onClick={() => handleDocumentClick(document.txAbsoluteUrl)}>
              <S.DocumentInfo>
                <UilFile size={20} />
                <S.DocumentText>{document.txName}</S.DocumentText>
              </S.DocumentInfo>

              <S.DocumentViewer>
                <UilEye size={17} />
              </S.DocumentViewer>
            </S.Document>
          ))}
        </S.ContainerDocument>
      )}
    </>
  )
}
