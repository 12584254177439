import { SchedulePageProvider } from './context'
import { ScheduleView } from './view'

export const ScheduleMobilePage = () => {
  return (
    <SchedulePageProvider>
      <ScheduleView />
    </SchedulePageProvider>
  )
}
