import { useMemo, createContext, useContext, useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useGQLServiceGetClassInfosByClassID } from '~/graphql/Classes/queries'

import { useTrailLessonInformation } from '~/services/Trails'

import { defaultDataInfosState } from './default.states'
import {
  IListClassDetailsPageContextProps,
  IListClassDetailsPageProviderProps,
  IModalState,
  LessonTrack,
} from './interfaces'

const ListClassesPageContext = createContext<IListClassDetailsPageContextProps>({
  isLoadingClassInfos: false,
  dataClassInfos: defaultDataInfosState,
  isLoadingClassDetails: false,
  dataLesson: undefined,
  refetchDataClass: () => Object,
  openModal: () => Object,
  closeModal: () => Object,
  modalInfo: '',
  modalState: false,
  refetchTrail: () => Object,
})

const ListClassDetailsPageProvider = ({ children }: IListClassDetailsPageProviderProps) => {
  const { classId } = useParams()
  const getInitialShowDisabledClasses = () => {
    const savedValue = localStorage.getItem('showDisabledClasses')
    return savedValue !== null ? JSON.parse(savedValue) : false
  }
  const [showDisabledClasses, setShowDisabledClasses] = useState(getInitialShowDisabledClasses || true)

  const {
    isLoading: isLoadingClassInfos,
    data: dataClassInfos,
    refetch: refetchDataClass,
  } = useGQLServiceGetClassInfosByClassID(Number(classId), showDisabledClasses)

  useEffect(() => {
    if (showDisabledClasses) {
      refetchDataClass().then()
    }
  }, [refetchDataClass, showDisabledClasses])
  const coStage = dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage

  const [modalInfo, setModalInfo] = useState<any>(null)
  const [isRefetchingLesson, setIsRefetchingLesson] = useState(true)
  const [modalState, setModalState] = useState<IModalState>({
    isOpen: false,
  })

  const openModal = useCallback(
    (trail: LessonTrack) => {
      setModalInfo(trail)
      if (!modalState.isOpen) {
        setModalState({
          isOpen: true,
        })
      }
    },
    [modalState, setModalInfo, setModalState],
  )

  const closeModal = useCallback(() => {
    if (modalState.isOpen) {
      setModalState({
        isOpen: false,
      })
    }
  }, [modalState, setModalState])

  const {
    refetch: refetchLesson,
    data: dataLesson,
    remove: removeLesson,
    isRefetching,
    isLoading: isLoadingLesson,
    refetch: refetchTrail,
  } = useTrailLessonInformation(Number(classId), true)

  useEffect(() => {
    setIsRefetchingLesson(true)
    removeLesson()
    refetchLesson().finally(() => {
      setIsRefetchingLesson(false)
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (coStage === 'F1' || coStage === 'M1' || coStage === 'M2') {
      removeLesson()
      refetchLesson().then()
    }
  }, [coStage, refetchLesson, classId]) // eslint-disable-line

  const listClassDetailsPageProviderValues = useMemo(() => {
    return {
      isLoadingClassInfos,
      dataClassInfos,
      isLoadingClassDetails: isLoadingLesson,
      dataLesson,
      refetchDataClass,
      openModal,
      closeModal,
      modalInfo,
      modalState,
      refetchTrail,
      showDisabledClasses,
      isRefetchingLesson,
    }
  }, [
    isLoadingClassInfos,
    dataClassInfos,
    isLoadingLesson,
    dataLesson,
    refetchDataClass,
    openModal,
    closeModal,
    modalInfo,
    modalState,
    refetchTrail,
    showDisabledClasses,
    isRefetchingLesson,
  ])

  return (
    <ListClassesPageContext.Provider value={listClassDetailsPageProviderValues}>
      {children}
    </ListClassesPageContext.Provider>
  )
}

const useListClassDetailsPageContext = () => useContext(ListClassesPageContext)

export { ListClassDetailsPageProvider, useListClassDetailsPageContext }
