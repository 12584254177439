import { IIconComponentProps } from './Icon.interfaces'
import * as S from './Icon.styles'

export const IconComponent = ({ color, fontWeight = 'bold', size, fontSize, code }: IIconComponentProps) => {
  return (
    <>
      <S.textStyled fontWeight={fontWeight} size={size} fontSize={fontSize} color={color}>
        {String.fromCharCode(code)}
      </S.textStyled>
    </>
  )
}
