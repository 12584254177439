import { useNavigation } from '~/_context/Navigation'

import { AppBarComponent, ContainerComponent, MomentCardComponent, ValidateDataSessionComponent } from '~/components'

import { CarouselCalendarComponent, HeaderComponent } from '~/containers/private/Schedule'

import { useSchedulePageContext } from './context'
import { IScheduledMomentProps } from './interfaces'
import * as S from './styles'

export const titleByNuOrder = (nuOrder: number) => {
  switch (nuOrder) {
    case 1:
      return 'Contextualização e Chuva de Ideias'
    case 2:
      return 'Ideação e Planejamento'
    case 3:
      return 'Execução do Planejamento'
    case 4:
      return 'Partilha e Escuta Coletiva'
  }
}

export const ScheduleView = () => {
  const { handleNavigation } = useNavigation()

  const {
    currentDay,
    changeCurrentDay,
    selectedDay,
    changeSelectedDay,
    isLoading,
    data,
    isRefetching,
    extensiveSelectedDay,
  } = useSchedulePageContext()

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          actionSetCurrentDay={changeCurrentDay}
          selectedDay={selectedDay}
          actionSetSelectedDay={changeSelectedDay}
          initialOptions={'day'}
        >
          <CarouselCalendarComponent
            currentDay={currentDay}
            actionSetCurrentDay={changeCurrentDay}
            selectedDay={selectedDay}
            actionSetSelectedDay={changeSelectedDay}
          />
        </HeaderComponent>

        <>
          {(!isLoading || !isRefetching) && !data?.scheduledMomentByProfessor?.items?.length ? (
            <S.ContentEmoji>
              <ValidateDataSessionComponent
                screen={'home'}
                title="Você não tem nenhuma aula programada para este dia."
                description="Que tal aproveitar para programar aulas dos próximos dias?"
                space={100}
              />
            </S.ContentEmoji>
          ) : (
            <S.ContentCards component="ul">
              <S.DateText variant="caption">{extensiveSelectedDay}</S.DateText>

              {isLoading || isRefetching ? (
                <>
                  {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
                    <MomentCardComponent key={index} loading={isLoading || isRefetching} />
                  ))}
                </>
              ) : (
                <>
                  {data?.scheduledMomentByProfessor?.items?.map((scheduleMoment: IScheduledMomentProps) => (
                    <MomentCardComponent
                      key={scheduleMoment?.idMoment}
                      totalClass={Number(scheduleMoment?.nuTrackStages)}
                      momentStatusCode={scheduleMoment?.coMomentStatus}
                      momentStatusText={scheduleMoment?.txMomentStatus}
                      momentTime={scheduleMoment?.dtSchedule}
                      nuOrder={scheduleMoment?.nuOrder}
                      txTrackTitle={scheduleMoment?.txTrackTitle}
                      idClass={scheduleMoment?.class?.idClass}
                      idMomentTrack={scheduleMoment?.idMomentTrack}
                      coStage={scheduleMoment?.class?.schoolGrade?.grade?.coStage}
                      momentTitle={
                        scheduleMoment?.class?.schoolGrade?.grade?.coStage === 'F2'
                          ? (titleByNuOrder(scheduleMoment?.nuOrder) as string)
                          : (scheduleMoment?.txTitle as string)
                      }
                      momentClassInfo={scheduleMoment?.class}
                      action={() =>
                        handleNavigation(
                          scheduleMoment?.class?.schoolGrade?.grade?.coStage,
                          scheduleMoment?.idMoment,
                          scheduleMoment?.class?.idClass,
                          '/schedule',
                        )
                      }
                    />
                  ))}
                </>
              )}
            </S.ContentCards>
          )}
        </>
      </ContainerComponent>
    </>
  )
}
