import { Box, IconButton } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const BorderLinearProgress = styledMUI(LinearProgress)<{ competenceColor: string }>(({ competenceColor }) => ({
  height: 10,
  borderRadius: 5,
  width: '68%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E2E5EC',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: competenceColor ? `#${competenceColor}` : '#308fe8',
  },
}))

export const Container = styledMUI(Box)(() => ({
  maxWidth: '718px',
  gap: '24px',
}))

export const Header = styled.div`
  margin-bottom: 24px;
  display: grid;
  gap: 16px;
`

export const StatusList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: left;
`
export const StatusItem = styled.span`
  background-color: ${({ theme }) => theme.colors.actionLight};
  color: ${({ theme }) => theme.colors.actionPure};
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 760px) {
    display: grid;
  }

  .competence {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }

  p {
    min-width: fit-content;
    font-size: 12px;
    font-weight: 400;
  }
`

export const HeaderText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const Competence = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: baseline;
  min-width: 50px;
  gap: 8px;
`

export const Progress = styled.span`
  padding: 4px 8px 4px 8px;
  background-color: ${({ theme }) => theme.colors.midMedium};
  border-radius: 4px;
  width: 30%;
  max-width: fit-content;
  color: ${({ theme }) => theme.colors.midDark};
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 760px) {
    width: 55%;
  }
`

export const ContentPagination = styledMUI(Box)(() => ({
  alignItems: 'end',
  width: '100%',
}))

export const Icon = styledMUI(Box)(() => ({
  cursor: 'pointer',
  position: 'static',
  alignSelf: 'center',
}))

export const TitleCardProject = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  width: '100%',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'flex',
    gap: '8px',
  },
}))

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const LessonList = styled.ul`
  width: 100%;
  min-width: 100%;
  padding: 24px;
  display: grid;
  max-height: 84%;
  margin-bottom: 20px;
  overflow-y: scroll;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.midLight};

  @media (max-width: 760px) {
    max-height: 400px;
  }
`
