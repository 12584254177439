import InfiniteScroll from 'react-infinite-scroll-component'

import { Box } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AppBarComponent,
  ContainerComponent,
  ModalDocumentationComponent,
  SkeletonComponent,
  ValidateDataSessionComponent,
} from '~/components'

import { HeaderProjectComponent } from '~/containers/private/Documentations'

import { CardDocumentationComponent } from '../../../components/Cards/CardDocumentation/CardDocumentation'
import { useDocumentationsPageContext } from './context'
import * as S from './styles'

export const DocumentationsView = () => {
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 600
  const {
    isLoadingProject,
    dataProject,
    idLesson,
    isLoadingProjectDocumentation,
    documentations,
    hasDocumentation,
    status,
    isSameUser,
    isClassInStatus,
    fetchMoreData,
    refetchDataProjectDocumentation,
    hasNext,
  } = useDocumentationsPageContext()

  const isProject = idLesson ? false : true

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderProjectComponent
          loading={isLoadingProject}
          txTitle={'Voltar'}
          dataProject={dataProject}
          idLesson={idLesson}
          statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
          isSameUser={isSameUser}
          isClassInStatus={isClassInStatus}
        />

        {isLoadingProjectDocumentation ? (
          <>
            <Box display="flex" p={4} flexWrap="wrap" gap={2}>
              {Array.from({ length: 6 }).map((_, index) => (
                <Box key={index} display="flex" flexDirection="column" gap={2} width="48%">
                  <Box>
                    <SkeletonComponent variant="rounded" width={'100%'} height={200} />
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        ) : hasDocumentation ? (
          <InfiniteScroll
            dataLength={documentations?.length}
            hasMore={hasNext}
            scrollThreshold={'500px'}
            next={fetchMoreData}
            loader={
              <S.ContentSkeletonInfinite>
                <SkeletonComponent variant={'rounded'} width={300} height={150} />
              </S.ContentSkeletonInfinite>
            }
          >
            <S.ContentDocumentation component="ul">
              {documentations && (
                <>
                  {documentations?.map((documentation, index) => {
                    return (
                      <CardDocumentationComponent
                        projectDocumentation={documentation}
                        documentation={documentation}
                        disabled={!isSameUser}
                        status={status}
                        key={index}
                        isLoading={isLoadingProject}
                        isProject={isProject}
                        isSameUser={isSameUser}
                        queryKeys={['getDocumentation']}
                        refetch={refetchDataProjectDocumentation}
                        isClassInStatus={isClassInStatus}
                      />
                    )
                  })}
                </>
              )}
            </S.ContentDocumentation>
          </InfiniteScroll>
        ) : (
          <S.NoDocContainer>
            <ValidateDataSessionComponent
              screen="documentation"
              height={50}
              title="Nenhuma documentação cadastrada."
              description="Até o momento, nenhuma documentação foi cadastrada."
            />
          </S.NoDocContainer>
        )}
        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && !isMobile && isSameUser && (
            <ModalDocumentationComponent
              variant="floater"
              idProject={Number(dataProject?.idProjectMoment)}
              idLesson={Number(idLesson)}
              queryKeys={'getDocumentation'}
              isClassInStatus={isClassInStatus}
            />
          )}
        </>
      </ContainerComponent>
    </>
  )
}
