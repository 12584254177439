import { useLocation } from 'react-router'

import { BackNavigationComponent, PedagogicalPracticesComponent, SkeletonComponent } from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'

import useIsMobile from '~/validations/isMobile'
import { truncateText } from '~/validations/truncateText'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'
export const HeaderComponent = ({
  loading,
  path,
  textGrade,
  classShift,
  textName,
  coStage,
  schoolGrade,
  coGrade,
}: IHeaderComponentProps) => {
  const isMobile = useIsMobile()
  const titleTruncate = truncateText(textName, isMobile ? 20 : 8)
  const location = useLocation()

  const getPrevPage = (): string | null => {
    if (!location.state?.from) {
      return localStorage.getItem('@LEKTO:Navigation')
    }
    return location.state?.from
  }

  return (
    <S.HeaderContainer component="header">
      <S.WrapperTittle>
        <BackNavigationComponent path={!getPrevPage() ? path : undefined} />
        <PedagogicalPracticesComponent coGrade={coGrade} schoolGrade={schoolGrade} />
      </S.WrapperTittle>

      <S.WrapperTittle>
        <S.HeaderTypograph>
          {loading ? (
            <SkeletonComponent variant="rounded" height={20} width={'70%'} />
          ) : (
            <>
              <S.Title variant="h4">Trilhas disponíveis para</S.Title>
              <S.TitleClass variant="h4">
                {textGrade && titleTruncate && (
                  <>
                    {textGrade} - {titleTruncate}
                  </>
                )}
              </S.TitleClass>
            </>
          )}
        </S.HeaderTypograph>
      </S.WrapperTittle>

      <S.Wrapper>
        {loading ? (
          <SkeletonComponent variant="rounded" height={20} width={300} />
        ) : (
          <>{coStage && classShift && <TagClass texts={[coStage, classShift]} variant="default" />}</>
        )}
      </S.Wrapper>
    </S.HeaderContainer>
  )
}
