import { Alert, Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { colorsOpacityStepsAssessment, colorsStepsAssessment } from '~/validations'

export const ContentAvaliar = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
  },
}))

export const ContentInfos = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '24px',
  gap: '8px',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
    display: 'grid',
  },
}))

export const ContentStepToStep = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '8px',
  minWidth: '60px',
  maxWidth: '60px',
  gap: '8px',
  backgroundColor: '#E2E5EC',
  borderRadius: '64px',
}))

export const TextLabelEvidence = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const TextContentStepToStep = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '120%',
  color: '#7C8189',
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentStudents = styledMUI(Box)<{ disabled: boolean | undefined }>(({ disabled }) => ({
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  border: '1px dashed #BDC3CF',
  borderRadius: '8px',
  backgroundColor: disabled ? '#F9FAFC' : 'initial',
  opacity: disabled ? 0.5 : 1,
}))

export const WrapperStudentList = styledMUI(Box)(() => ({
  height: '80px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}))

export const TextLabelStudents = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#7C8189',
  marginBottom: '12px',
}))

export const Arrow = styledMUI(Box)<{ enabled: boolean }>(({ enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: enabled ? '#ccc' : '#0095FF',
  width: '24px',
  height: '24px',
  color: '#fff',
  borderRadius: '50%',
  cursor: 'pointer',
}))

export const PutStart = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  paddingBottom: '20px',
}))

export const PutEnd = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '20px',
}))

export const ListStudents = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  overflowX: 'hidden',
}))

export const CardStudent = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '8px',
  borderRadius: '8px',
  backgroundColor: '#F9FAFC',
  cursor: 'grab',
}))

export const TextStudent = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentStepsAssessment = styledMUI(Box)<{ disabled: boolean | undefined }>(({ disabled }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2%',
  opacity: disabled ? 0.5 : 1,
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'grid',
    gap: '12px',
  },
}))

export const CardStepAssessment = styledMUI(Box)<{
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
}>(({ stepAssessment }) => ({
  minHeight: '150px',
  width: '49%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  gap: '8px',
  padding: '16px',
  marginBottom: stepAssessment === 'toDevelop' || stepAssessment === 'inEarlyDevelopment' ? '2%' : 'initial',
  backgroundColor: colorsStepsAssessment(stepAssessment),
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#FFF',
}))

interface IContentDrop {
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
  avatars: boolean
}

export const AlertAssessment = styledMUI(Alert)(() => ({
  position: 'fixed',
  bottom: '16px',
  left: '16px',
  zIndex: '9999',
  '@media (max-width: 600px)': {
    bottom: '60px',
  },
}))

export const ContentDrop = styledMUI(Box)<IContentDrop>(({ stepAssessment, avatars }) => ({
  minHeight: '88px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',

  justifyContent: !avatars ? 'center' : 'initial',
  alignItems: !avatars ? 'center' : 'initial',
  padding: '8px',
  gap: '8px',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: colorsOpacityStepsAssessment(stepAssessment),
  borderRadius: '8px',
}))

export const TextDescriptionDrop = styledMUI(Typography)<{
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
}>(({ stepAssessment }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: colorsOpacityStepsAssessment(stepAssessment),
}))
