import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { TrailsGeneratedPage } from 'src/pages/private/TrailsGenerated'

import { useAuth } from '~/_context/Auth'
import { NavigationProvider } from '~/_context/Navigation'

import { BnccPageOffline } from '~/pages/offline/Fund1/Bncc'
import { FirstStepPageOffline } from '~/pages/offline/Fund1/FirstStep'
import { LessonPageOffline } from '~/pages/offline/Fund1/Lesson'
import { LessonPlanningPageOffline } from '~/pages/offline/Fund1/Planning'
import { SecondGeneralStepPageOffline } from '~/pages/offline/Fund1/SecondGeneralStep'
import { SecondStepPageOffline } from '~/pages/offline/Fund1/SecondStep'
import { ThirdStepPageOffline } from '~/pages/offline/Fund1/ThirdStep'
import { BnccProjectPageOffline } from '~/pages/offline/Fund2/Bncc'
import { FirstStageProjectPageOffline } from '~/pages/offline/Fund2/FirstStageProject'
import { ProjectPageOffline } from '~/pages/offline/Fund2/Project'
import { SecondStageProjectPageOffline } from '~/pages/offline/Fund2/SecondStageProject'
import { ThirdStageProjectPageOffline } from '~/pages/offline/Fund2/ThirdStageProject'
import { HomePageOffline } from '~/pages/offline/Home'
import { AssessmentPage } from '~/pages/private/Assessment'
import { ListClassDetailsPage } from '~/pages/private/Classes/ListClassDetails'
import { ListClassesPage } from '~/pages/private/Classes/ListClasses'
import { ProjectDocumentationsPage } from '~/pages/private/Documentations'
import { FirstStepPage } from '~/pages/private/Fund1/FirstStep'
import { LessonPage } from '~/pages/private/Fund1/Lesson'
import { LessonBNCCRelationsPage } from '~/pages/private/Fund1/LessonBNCCRelations'
import { LessonPlanningPage } from '~/pages/private/Fund1/Planning'
import { SecondGeneralStepPage } from '~/pages/private/Fund1/SecondGeneralStep'
import { SecondStepPage } from '~/pages/private/Fund1/SecondStep'
import { ThirdStepPage } from '~/pages/private/Fund1/ThirdStep'
import { TrailsGeneratedPageF1 } from '~/pages/private/Fund1/TrailsGenerated'
import { ProjectPlanningPage } from '~/pages/private/Fund2/Planning'
import { ProjectPage } from '~/pages/private/Fund2/Project'
import { ProjectBNCCRelationsPage } from '~/pages/private/Fund2/ProjectBNCCRelations'
import { HomePage } from '~/pages/private/Home'
import { ResponsableStudentsPage } from '~/pages/private/Responsable/ResponsableStudents'
import { SchedulePage } from '~/pages/private/Schedule'
import { ScheduleByMonthPage } from '~/pages/private/ScheduleByMonth'
import { ScheduleByWeekPage } from '~/pages/private/ScheduleByWeek'
import { ScheduleMobilePage } from '~/pages/private/ScheduleMobile'
import { SettingsPage } from '~/pages/private/Settings'
import { StudentDashboardPage } from '~/pages/private/StudentDashboard'
import { StudentsPage } from '~/pages/private/Students'
import { ListTrailsAvailableClassPage } from '~/pages/private/Trails/ListTrailsAvailable'
import { ListTrailsAvailableProjectPage } from '~/pages/private/Trails/ListTrailsProject'
import { TrailDetailsClassPage } from '~/pages/private/Trails/TrailDetailsClass'
import { TrailDetailsProjectPage } from '~/pages/private/Trails/TrailDetailsProject'
import { ScheduleTrailPage } from '~/pages/private/Trails/TrailSchedule'
import { TrailsNotGeneratedPage } from '~/pages/private/TrailsNotGenerated'
import { AuthThirdPartyPage } from '~/pages/public/AuthThirdParty'
import { LoginPage } from '~/pages/public/Login'
import { NotFoundPage } from '~/pages/public/NotFound'

interface IPublicRoutesProps {
  lastRoute: string
}

const PublicRoutes = ({ lastRoute }: IPublicRoutesProps) => {
  const { isAuthenticated, user } = useAuth()

  const isResponsable = user?.role.includes('pais')

  const validHomePage = isResponsable ? '/responsable-students' : '/home'

  return isAuthenticated ? <Navigate to={lastRoute === '/' ? validHomePage : lastRoute} /> : <Outlet />
}

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />
}

export const Router = () => {
  return (
    <BrowserRouter>
      <NavigationProvider>
        <Routes>
          <Route element={<PublicRoutes lastRoute={document.location.pathname} />}>
            <Route path="/auth-third-party" element={<AuthThirdPartyPage />} />

            <Route path="/" element={<LoginPage />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            {/* OFFLINE ROUTES*/}

            <Route path="/offline/home" element={<HomePageOffline />} />
            <Route path="/offline/lesson/:idLessonMoment" element={<LessonPageOffline />} />
            <Route path="/offline/first-step/:idLessonMoment/lesson/:idLesson" element={<FirstStepPageOffline />} />
            <Route
              path="/offline/lesson-bncc-relations/:idLessonMoment/lesson/:idLesson"
              element={<BnccPageOffline />}
            />
            <Route
              path="/offline/second-step/:idLessonMoment/lesson/:idLesson"
              element={<SecondGeneralStepPageOffline />}
            />
            <Route
              path="/offline/second-step/:idLessonMoment/lesson/:idLesson/activity-details/:idLessonActivity"
              element={<SecondStepPageOffline />}
            />
            <Route path="/offline/third-step/:idLessonMoment/lesson/:idLesson" element={<ThirdStepPageOffline />} />
            <Route path="/offline/project/:projectId" element={<ProjectPageOffline />} />
            <Route path="/offline/lesson/:idLessonMoment/planning/:idLesson" element={<LessonPlanningPageOffline />} />

            <Route path="/offline/project-firstStep/:projectId" element={<FirstStageProjectPageOffline />} />
            <Route path="/offline/project-secondStep/:projectId" element={<SecondStageProjectPageOffline />} />
            <Route path="/offline/project-thirdStep/:projectId" element={<ThirdStageProjectPageOffline />} />
            <Route path="/offline/project-bncc-relations/:projectId" element={<BnccProjectPageOffline />} />

            {/* END OF OFFLINE ROUTES*/}

            <Route path="/home" element={<HomePage />} />

            <Route path="/students" element={<StudentsPage />} />
            <Route path="/students/:studentId" element={<StudentDashboardPage />} />

            <Route path="/settings" element={<SettingsPage />} />

            <Route path="/schedule" element={<SchedulePage />} />
            <Route path="/schedule/mobile" element={<ScheduleMobilePage />} />
            <Route path="/schedule/week" element={<ScheduleByWeekPage />} />
            <Route path="/schedule/mobile" element={<ScheduleByWeekPage />} />

            <Route path="/schedule/month" element={<ScheduleByMonthPage />} />

            <Route path="/class/:idClass/lesson/:idLessonMoment" element={<LessonPage />} />

            <Route path="/class/:idClass/lesson/:idLessonMoment/planning/:idLesson" element={<LessonPlanningPage />} />

            <Route path="/class/:idClass/first-step/:idLessonMoment/lesson/:idLesson" element={<FirstStepPage />} />

            <Route
              path="/class/:idClass/second-step/:idLessonMoment/lesson/:idLesson"
              element={<SecondGeneralStepPage />}
            />

            <Route
              path="/class/:idClass/second-step/:idLessonMoment/lesson/:idLesson/activity-details/:idLessonActivity"
              element={<SecondStepPage />}
            />

            <Route path="/class/:idClass/third-step/:idLessonMoment/lesson/:idLesson" element={<ThirdStepPage />} />

            <Route
              path="/class/:idClass/documentations/:idLessonMoment/lesson/:idLesson"
              element={<ProjectDocumentationsPage />}
            />

            <Route
              path="/class/:idClass/lesson-bncc-relations/:idLessonMoment/lesson/:idLesson"
              element={<LessonBNCCRelationsPage />}
            />

            <Route
              path="/class/:idClass/lesson-assessment/:idLessonMoment/lesson/:idLesson"
              element={<AssessmentPage />}
            />
            <Route path="/class/:idClass/project-assessment/:projectId" element={<AssessmentPage />} />

            <Route path="/class/:idClass/project/:projectId" element={<ProjectPage />} />

            <Route path="/class/:idClass/project/:projectId/planning" element={<ProjectPlanningPage />} />

            <Route path="/class/:idClass/project-documentations/:projectId" element={<ProjectDocumentationsPage />} />

            <Route path="/class/:idClass/project-bncc-relations/:projectId" element={<ProjectBNCCRelationsPage />} />

            <Route path="/classes" element={<ListClassesPage />} />

            <Route path="/schedule/:classId/track/:trackId" element={<ScheduleTrailPage />} />

            <Route path="/class-details/:classId" element={<ListClassDetailsPage />} />

            <Route path="/trails-available/:classId" element={<ListTrailsAvailableClassPage />} />
            <Route path="/projects-available/:classId" element={<ListTrailsAvailableProjectPage />} />

            <Route path="/trail-details/:trailId/class/:classId" element={<TrailDetailsClassPage />} />

            <Route path="/trail-details/:trailId/project/:classId" element={<TrailDetailsProjectPage />} />

            <Route path="/trail/:trailId/not-occupied/:classId" element={<TrailsNotGeneratedPage />} />
            <Route path="/trail/:trailId/occupied/:classId" element={<TrailsGeneratedPageF1 />} />

            <Route path="/trail/:trailId/project/:classId" element={<TrailsGeneratedPage />} />
            <Route path="/trail/:trailId/not-generated/:classId" element={<TrailsNotGeneratedPage />} />

            <Route path="/responsable-students" element={<ResponsableStudentsPage />} />

            <Route path="/classe2" element={<SecondGeneralStepPage />} />
          </Route>

          <Route path="/404" element={<NotFoundPage />} />
        </Routes>
      </NavigationProvider>
    </BrowserRouter>
  )
}
