import { Badge, Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Container = styledMUI(Box)(() => ({
  height: 'fit-content',
  width: 'fit-content',
  borderRadius: '50%',
  transform: 'translate(0, 0)',
  cursor: 'grab',
}))

export const ContainerSelect = styledMUI(Box)<{ selected: boolean }>(({ selected }) => ({
  opacity: selected ? '50%' : '100%',
  borderRadius: '50%',
}))

export const CardStudentDrag = styledMUI(Box)<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'grabbing',
  justifyContent: 'center',
  borderRadius: '8px',
  width: '122px',
  height: '90px',
  zIndex: '999',
  border: `${selected && '2px solid #0095FF'}`,
  '@media (max-width: 600px)': {
    textAlign: 'center',
    minWidth: '60px',
    padding: '8px 4px',
    mixBlendMode: 'multiply',
    gap: '4px',
  },
}))

export const BadgeAvatar = styledMUI(Badge)(() => ({
  cursor: 'grab',
}))
