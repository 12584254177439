import { Box, Modal } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContainerModal = styledMUI(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ContentModal = styledMUI(Box)<{ width: string; maxWidth: string }>((props) => ({
  backgroundColor: '#fff',
  borderRadius: 16,
  maxWidth: props.maxWidth || '700px',
  width: props.width || '700px',
  display: 'flex',
  maxHeight: '90%',
  outline: 'none',
}))
