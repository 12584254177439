import { IColorsStepsAssessmentProps } from '~/interfaces/ColorsStepsAssessment'

export const colorsOpacityStepsAssessment = (
  step: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed',
) => {
  const colorStep: IColorsStepsAssessmentProps = {
    toDevelop: '#5A0A00',
    inEarlyDevelopment: '#6A3900',
    underDevelopment: '#007025',
    developed: '#005B9C',
  }

  return colorStep[step as keyof IColorsStepsAssessmentProps]
}
