import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UilArrowLeft } from '@iconscout/react-unicons'

import { Group } from '~/services/Students/types'

import {
  AccordionTextComponent,
  AvatarGroupComponent,
  ContainerComponent,
  DropdownSkillComponent,
  IconComponent,
  SkeletonComponent,
  StepsTabsComponent,
} from '~/components'
import { ModalAvatarList } from '~/components/Modals/ModalAvatarList/ModalAvatarList'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderStepComponent = ({
  activedTab,
  actionSetActiveTab,
  txTitle,
  fixedSkill,
  txDescription,
  variableSkill,
  avatars,
  txContext,
  iconContext,
  status,
  nuOrder,
  isLoading,
  openModal,
  title,
}: IHeaderComponentProps) => {
  const convertNuOrder = (nuOrder: number) => {
    switch (nuOrder) {
      case 1:
        return 'Grupo A'
      case 2:
        return 'Grupo B'
      case 3:
        return 'Grupo C'
      case 4:
        return 'Grupo D'
      default:
        'Turma Inteira'
        break
    }
  }

  const navigate = useNavigate()

  const avatarsFiltered = avatars?.filter((item: Group) => item.inAttendance === true)
  const avatarList = avatars?.map((student: any) => student.studentClass.student)

  const [openViewAvatar, setOpenViewAvatar] = useState<boolean>(false)
  const showOpenViewAvatar = () => {
    setOpenViewAvatar(true)
  }

  return (
    <S.HeaderContainer component="header">
      {isLoading ? (
        <ContainerComponent>
          <SkeletonComponent width={'70%'} height={50} />
          <SkeletonComponent width={'60%'} height={50} variant={'text'} />

          <SkeletonComponent width={'50%'} height={50} variant={'text'} />

          <SkeletonComponent width={'100%'} height={150} variant={'text'} />
          <SkeletonComponent width={'100%'} height={100} variant={'text'} />
          <SkeletonComponent width={'100%'} height={100} variant={'text'} />
          <SkeletonComponent width={'100%'} height={100} variant={'text'} />
        </ContainerComponent>
      ) : (
        <>
          <S.ContentHeader>
            <S.BackContainer>
              <S.StepBack onClick={() => navigate(-1)}>
                <UilArrowLeft size={20} />
                Passo 2 - {title}
              </S.StepBack>
              {txContext && (
                <S.Wrapper>
                  <IconComponent color="card" size="xsmall" code={iconContext} /> <S.Context>{txContext}</S.Context>
                </S.Wrapper>
              )}
            </S.BackContainer>
            <S.Wrapper>
              <S.TitleBold variant="h1">{txTitle}</S.TitleBold>
            </S.Wrapper>
            {avatars && avatars?.length > 0 && (
              <S.RowContainer onClick={openModal}>
                <S.TextLabel color="#666" variant="caption">
                  {convertNuOrder(nuOrder)}
                </S.TextLabel>
                <S.ContentAvatar onClick={showOpenViewAvatar}>
                  <AvatarGroupComponent images={avatarsFiltered} size="small" max={4} />
                </S.ContentAvatar>
              </S.RowContainer>
            )}
          </S.ContentHeader>

          <S.ContentFixedSkill>
            {variableSkill && (
              <S.Wrapper>
                <S.TextLabel color="#000" variant="caption">
                  Habilidade Variável
                </S.TextLabel>

                <DropdownSkillComponent
                  txSkillName={variableSkill?.ability?.txName}
                  txSkillDescription={variableSkill?.txName}
                  txBncc=""
                  iconSkill={variableSkill?.ability?.txImagePath}
                  txCompetency={variableSkill?.ability?.competence?.txName}
                  iconCompetency={variableSkill?.ability?.competence?.txImagePath}
                />
              </S.Wrapper>
            )}

            {fixedSkill && (
              <S.Wrapper>
                <S.TextLabel color="#000" variant="caption">
                  Habilidade Fixa
                </S.TextLabel>

                <DropdownSkillComponent
                  txSkillName={fixedSkill?.ability?.txName}
                  txSkillDescription={fixedSkill?.txName}
                  txBncc=""
                  iconSkill={fixedSkill?.ability?.txImagePath}
                  txCompetency={fixedSkill?.ability?.competence?.txName}
                  iconCompetency={variableSkill?.ability?.competence?.txImagePath}
                />
              </S.Wrapper>
            )}
          </S.ContentFixedSkill>

          {txDescription && (
            <S.ContentChallenge>
              <S.TextLabel color="#000" variant="caption">
                Desafio
              </S.TextLabel>

              <AccordionTextComponent paragraph={txDescription || '...'} limit={120} />
            </S.ContentChallenge>
          )}

          <S.ContentTabs>
            <StepsTabsComponent
              status={status}
              type={'secondStep'}
              activedTab={activedTab}
              actionSetActiveTab={(newActivedTab: number) => actionSetActiveTab(newActivedTab)}
            />
          </S.ContentTabs>

          {openViewAvatar && (
            <ModalAvatarList
              open={openViewAvatar}
              actionCloseModal={() => setOpenViewAvatar(false)}
              avatarList={avatarList}
            />
          )}
        </>
      )}
    </S.HeaderContainer>
  )
}
