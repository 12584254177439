import { Box, Card, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const CardContainer = styledMUI(Card)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: `${theme.colors.midLight}`,
  padding: '16px',
  gap: '16px',
  borderRadius: '8px',
  boxShadow: 'none',

  animation: 'fadeIn 0.3s ease-out forwards',

  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))

export const RowChips = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'start',
  gap: '8px',
  alignItems: 'start',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))
export const ContainerTrackClass = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.colors.lowLight}`,
  width: 'fit-content',
  height: '24px',
  gap: '8px',
  padding: '4px 8px 4px 8px',
  borderRadius: '4px',
}))

export const ContainerTrackInactive = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: `${theme.colors.lowLight}`,
  width: 'fit-content',
  height: '24px',
  padding: '4px 6px',
  borderRadius: '4px',
}))

export const ButtonContent = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    marginTop: '16px',
    width: '100%',
  },
}))

export const ContentInfo = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}))

export const Title = styledMUI(Typography)<{ bold?: boolean }>(({ bold }) => ({
  fontStyle: 'normal',
  fontWeight: bold ? 700 : 400,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#000',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#666',
}))

export const ClassSpanInfo = styledMUI(Box)(() => ({
  fontSize: '14px',
  padding: '0 6px',
  color: '#7c8189',
  borderRight: `1px solid ${theme.colors.lowLight}`,
  '&:last-child': {
    borderRight: 0,
  },
}))

export const spanInactive = styledMUI(Box)(() => ({
  fontSize: '14px',
  padding: '0 6px',
  color: `${theme.colors.highLight}`,
  borderRight: `1px solid ${theme.colors.lowLight}`,
  '&:last-child': {
    borderRight: 0,
  },
}))
