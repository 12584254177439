import { useParams } from 'react-router-dom'

import { CircularProgress } from '@mui/material'

import { useGetStudentsGroupLesson } from '~/services/Students'

import { AssessmentComponent } from '~/components'

import { useAssessmentPageContext } from '../context'
import * as S from '../styles'

interface ITabFirstStep {
  idLessonMomentActivity: number
  txName: string
  evidenceDescription: string
  compentencyName: string
  idEvidence: string
  status: string
  txIconCompetency: string
  txIconSkill: string
  handleAssessmentBatch: any
  isClassInStatus: boolean
  handleAssessment(id: string, coAssessment: string, idEvidence: string): void
}

export const TabFirstStep = ({
  idLessonMomentActivity,
  handleAssessment,
  handleAssessmentBatch,
  txName,
  idEvidence,
  compentencyName,
  evidenceDescription,
  status,
  txIconCompetency,
  txIconSkill,
  isClassInStatus,
}: ITabFirstStep) => {
  const { idLessonMoment } = useParams()
  const { isSameUser } = useAssessmentPageContext()
  const { data: dataStudentsGroupL1, isFetching } = useGetStudentsGroupLesson(Number(idLessonMoment), Number(1))

  const isFinish = status === 'FINA'

  if (!dataStudentsGroupL1 || isFetching) {
    return (
      <S.Loading sx={{ color: '#fff', zIndex: 999999 }} open>
        <CircularProgress color="inherit" />
      </S.Loading>
    )
  }

  return (
    <AssessmentComponent
      handleDrop={handleAssessment}
      handleDropBatch={handleAssessmentBatch}
      txEvidenceName={txName}
      idEvidence={idEvidence}
      txEvidenceDescription={evidenceDescription}
      txCompentencyName={compentencyName}
      students={dataStudentsGroupL1}
      disabled={isFinish || !isSameUser || !isClassInStatus}
      txIconCompetency={txIconCompetency}
      txIconSkill={txIconSkill}
      idActivity={idLessonMomentActivity}
      type={'lesson'}
      skillType={'FIX'}
    />
  )
}
