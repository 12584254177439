import { IGetLessonDocumentation, IGetLessonDocumentationActivity } from '~/services/Documentation/types'
import { ILessonStepInformation } from '~/services/LessonSteps/types'
import { IGetStudentsGroupLesson } from '~/services/Students/types'

export const initialDataState: ILessonStepInformation = {
  idLesson: 0,
  txTitle: '',
  txGuidance: '',
  idLessonStep: 0,
  txGuidanceBncc: '',
  nuOrder: 0,
  momentStatus: {
    txMomentStatus: '',
    coMomentStatus: '',
  },
  nuDuration: 0,
  lessonActivity: [
    {
      idLessonActivity: 0,
      txTitle: '',
      txChallenge: '',
      category: {},
      coGrade: '',
      lessonActivityOrientation: [
        {
          txTitle: '',
          txOrientationCode: '',
          idLessonActivity: 0,
          idLessonActivityOrientation: 0,
          lessonActivitySupportReference: [
            {
              txTitle: '',
              coSupportReference: '',
              idLessonActivityOrientation: 0,
              idMediaInformation: 0,
              idLessonActivitySupportReference: 0,
              mediaInformation: {
                txAbsoluteUrl: '',
                txName: '',
                coMediaLocator: '',
                inPublic: false,
                nuSize: 0,
                txPath: '',
                txContentType: '',
              },
            },
          ],
        },
      ],
      lessonMomentActivity: [
        {
          lessonActivityDocumentation: [],
          idLessonActivity: 0,
          idLessonMoment: 0,
          idLessonMomentActivity: 0,
          lessonMomentGroup: [
            {
              idLessonMomentActivity: 0,
              idLessonMomentGroup: 0,
              inAttendance: false,
              assessment: {
                coAssessment: '',
                txAssessment: '',
              },
              studentClass: {
                idClass: 0,
                student: {
                  txName: '',
                  idStudent: 0,
                  idUserStudent: 0,
                  txImagePath: '',
                  inDeleted: false,
                },
              },
            },
          ],
        },
      ],
    },
  ],
  lessonStepEvidence: [
    {
      evidenceGrade: {
        evidence: {
          txName: '',
          idEvidence: 0,
          ability: {
            txName: '',
            txImagePath: '',
            txBgPrimaryColor: '',
            txBgSecondaryColor: '',
            competence: {
              idCompetence: 0,
              txName: '',
            },
          },
        },
      },
    },
  ],
}

export const dataLessonDocumentationActivity: IGetLessonDocumentationActivity = {
  value: [
    {
      idLessonActivity: 0,
      dtInserted: '',
      idLessonDocumentation: 0,
      txMomentNotes: '',
      lessonActivity: {
        idLessonDocumentation: 0,
        dtInserted: '',
        idLessonActivity: 0,
        student: [],
        lessonMomentActivity: [],
        idLessonMomentActivity: 0,
        coGrade: '',
        idEvidence: 0,
        txTitle: '',
        idLessonStep: 0,
        lessonActivityOrientation: [],
        lessonActivityEvidence: [],
        nuOrder: 0,
        txChallenge: '',
        dtLastUpdate: '',
        idCategory: 0,
      },
      idLessonMomentActivity: 0,
      lessonEvidenceDocumentation: [],
      idLessonMoment: 0,
      lessonStudentDocumentation: [
        {
          idLessonStudentDocumentation: 0,
          dtInserted: '',
          idStudent: 0,
          txName: '',
          idNetwork: 0,
          idSchool: 0,
          idUser: 0,
          idUserStudent: 0,
          inDeleted: false,
          idLessonDocumentation: 0,
          txImagePath: '',
        },
      ],
      lessonDocumentationMedia: [
        {
          idLessonDocumentation: 0,
          idLessonDocumentationMedia: 0,
          coMediaLocator: '',
          idMediaInformation: 0,
          dtInserted: '',
          inPublic: false,
          nuSize: 0,
          txAbsoluteUrl: '',
          txName: '',
          txContentType: '',
        },
      ],
    },
  ],
}

export const dataLessonDocumentation: IGetLessonDocumentation = {
  value: [
    {
      idLessonDocumentation: 0,
      dtInserted: '',
      lessonActivity: undefined,
      lessonStep: undefined,
      lessonEvidenceDocumentationList: [],
      txMomentNotes: '',
      lessonStudentDocumentationList: [
        {
          idLessonStudentDocumentation: 0,
          dtInserted: '',
          idStudent: 0,
          student: [],
          txName: '',
          idNetwork: 0,
          idSchool: 0,
          idUser: 0,
          idUserStudent: 0,
          inDeleted: false,
        },
      ],
      lessonDocumentationMediaList: [
        {
          idLessonDocumentation: 0,
          idLessonDocumentationMedia: 0,
          coMediaLocator: '',
          idMediaInformation: 0,
          dtInserted: '',
          inPublic: false,
          nuSize: 0,
          txAbsoluteUrl: '',
          txName: '',
          txContentType: '',
        },
      ],
    },
  ],
}

export const initialDataStudentsGroup: IGetStudentsGroupLesson = [
  {
    idLessonMomentGroup: 0,
    inAttendance: false,
    idLessonMomentActivity: 0,
    idProjectMomentGroup: 0,
    idProjectMomentStage: 0,
    studentClass: {
      idClass: 0,
      idStudent: 0,
      dtInserted: new Date(),
      lessonMomentGroup: [],
      projectMomentGroup: [],
      lessonStudentDocumentation: [],
      projectStudentDocumentation: [],
      student: {
        txName: '',
        inDeleted: false,
        student: [],
        txImagePath: '',
        idUserStudent: 0,
        idStudent: 0,
        coGrade: '',
        dtInserted: new Date(),
        idNetwork: 0,
        idSchool: 0,
      },
    },
    assessment: {
      nuScore: 0,
      dtInserted: new Date(),
      coAssessment: '',
      txAssessment: '',
      lessonMomentGroup: [],
      projectMomentGroup: [],
    },
  },
]
