import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const DropdownSkillContainer = styledMUI(Box)<{ open: boolean }>(({ open }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '32px',
  width: open ? '350px' : 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: '8px 16px',
  backgroundColor: '#E8F6FF',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'all 0.5s',

  '&:hover': {
    boxShadow: theme.shadows.level3,
  },

  '@media (max-width: 450px)': {
    width: '244px',
  },
}))

export const ContentPreview = styledMUI(Box)(() => ({
  height: '32px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none',
  gap: '8px',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#0095FF',
}))

export const ContentDescriptions = styledMUI(Box)<{ open: boolean }>(({ open }) => ({
  width: '350px',
  height: 'fit-content',
  flexDirection: 'column',
  padding: '6px 16px 8px 16px',
  position: 'absolute',
  top: '30px',
  left: '0',
  gap: '8px',
  zIndex: 10,
  overflow: 'hidden',
  animationName: `${open ? 'dropdownOpen' : 'dropdownClose'}`,
  animationDuration: '0.2s',
  animationDirection: 'normal',
  backgroundColor: '#E8F6FF',
  transformOrigin: '0 0',
  animationFillMode: 'forwards',
  '@media (max-width: 450px)': {
    width: '244px',
  },

  '@keyframes dropdownOpen': {
    '0%': { transform: 'scale(0.5, 0)', display: 'none' },
    '100%': { transform: 'scale(1, 1)', display: 'flex' },
  },

  '@keyframes dropdownClose': {
    '0%': { transform: 'scale(1, 1)', display: 'flex' },
    '100%': { transform: 'scale(0.5, 0)', display: 'none' },
  },
}))

export const TextParagraph = styledMUI(Typography)(() => ({
  textAlign: 'left',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  color: '#000',
}))

export const TextCode = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  color: '#E14983',
}))

export const ContentCompetency = styledMUI(Box)(() => ({
  fontStyle: 'normal',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '150%',
  color: '#000',
}))
