import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

import theme from '~/styles/theme'

interface ICardContainerProps {
  isMobile: boolean
}

export const ContentList = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: theme.colors.highPure,
  padding: '24px',
}))

export const ContainerScroll = styled.div`
  overflow-y: auto;
  height: 100vh;
`
export const StepHeader = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
}))

export const ContentNumber = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ theme }) => theme.colors.midDark};
  z-index: 1;
`

export const StepNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

export const TitleLessonStep = styledMUI(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '120%',
  maxWidth: '95%',
  color: `${theme.colors.lowPure}`,
}))

export const Arrow = styledMUI(Box)<{ enabled: boolean }>(({ enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: enabled ? theme.colors.highDark : theme.colors.accentPure,
  width: '24px',
  height: '24px',
  color: theme.colors.highPure,
  borderRadius: '50%',
}))

export const PutStart = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  paddingBottom: '20px',
}))

export const PutEnd = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '20px',
}))

export const ContainerCardsList = styled.ul<{ displayMode: boolean }>`
  width: 100%;
  max-width: 100%;
  gap: 8px;
  padding: 16px 0;

  ${({ displayMode }) =>
    !displayMode
      ? css`
          display: flex;
          overflow: hidden;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        `}
`

export const CardProject = styled.li`
  min-width: 260px;
  max-width: 260px;
  min-height: 366px;
  max-height: 366px;
  padding: 16px;
  list-style: none;
  border-radius: 8px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.highPure};

  .divider {
    margin: 16px 0;
  }
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardIndex = styled.div`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.highPure};
  display: flex;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.midPure};
`

export const InfoContainer = styled.div`
  display: grid;
  gap: 16px;
`

export const ContainerLesson = styled.div`
  padding: 24px;
  display: grid;
  gap: 16px;
  width: 100%;
`

export const Label = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: theme.colors.lowPure,
}))

export const CardProjectTitle = styled.h2`
  color: ${({ theme }) => theme.colors.lowPure};
  font-weight: 700;
  font-size: 20px;
  min-height: 67px;
`
export const CardDescription = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};
`

export const Description = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: left;
`
export const BtnAction = styled.button`
  margin-left: 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.actionPure};
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.actionDark};
  }
`

export const CardProjectDescription = styled.p`
  display: -webkit-box;
  font-size: 14px;
  line-height: 150%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const CardSliderResumeContainer = styled.div<ICardContainerProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`
