import { Link } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { Span } from '~/containers/private/Fund2/Trails/Header/Header.styles'

import { colorsStepsActivedVariant, colorsStepsDisabled } from '~/validations'

import theme from '~/styles/theme'

export const bgBox = styledMUI(Box)(() => ({
  backgroundColor: `${theme.colors.midLight}`,
  borderRadius: '8px',
  padding: '18px',
  boxShadow: 'none',

  animation: 'fadeIn 0.4s ease-out forwards',

  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

export const BoxTitle = styledMUI(Box)(() => ({
  gap: '8px',
  display: 'flex',
  alignContent: 'center',
  textAlign: 'center',
  boxShadow: 'none',
}))

export const BoxEM = styledMUI(Box)(() => ({
  gap: '8px',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  boxShadow: 'none',
}))

export const TextMain = styledMUI(Typography)<{
  type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep'
  weight?: number
}>(({ type, weight }) => ({
  fontStyle: 'normal',
  fontWeight: weight || 700,
  fontSize: '20px',
  lineHeight: '120%',
  color: colorsStepsActivedVariant(type),
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: '#000',
  flex: 1,
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  marginBottom: '12px',
  marginTop: '16px',
  textAlign: 'justify',
  alignItems: 'center',
}))

export const TitleWrapper = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  marginBottom: '12px',
  marginTop: '16px',
  textAlign: 'justify',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const HeaderBox = styledMUI(Box)(() => ({
  display: 'grid',
  gap: '24px',
  padding: '8px',
}))

export const BoxSkill = styledMUI(Box)(() => ({
  display: 'column',
  gap: '8px',
  width: '100%',
}))

export const RowTitle = styledMUI(Box)(() => ({
  display: 'flex',
  marginLeft: '16px',
  gap: '4px',
  justifyContent: 'start',
}))

export const textLesson = styledMUI(Span)<{
  weight?: number
  type?: string
}>(({ weight, type }) => ({
  color: '#0095FF',
  fontWeight: weight || 400,
}))

export const Row = styledMUI(Box)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(({ type }) => ({
  display: 'flex',
  marginLeft: '16px',
  alignContent: 'center',
  width: '100%',
  gap: '6px',
  color: colorsStepsActivedVariant(type),
}))

export const TextStepDescriptionActivity = styledMUI(Typography)<{ color: string }>(({ variant }) => ({
  fontSize: '14px',
  border: 'none',
  gap: '4px',
  display: 'flex',
  color: variant,
  justifyContent: 'center',
  lineHeight: '120%',
  '& span:first-child': {
    fontWeight: 400,
  },
  '& span': {
    fontWeight: 700,
    maxWidth: '90%',
  },
}))

export const TextLabel = styledMUI(Typography)<{ color: string }>(({ color }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  marginRight: '5px',
  fontSize: '16px',
  lineHeight: '120%',
  color: color,
}))

export const AccordionContainer = styledMUI(Accordion)(() => ({
  backgroundColor: `${theme.colors.highPure}`,
  borderRadius: '8px',
  width: '100%',
  boxShadow: 'none',
  marginTop: '5px',

  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

export const AccordionSummaryContent = styledMUI(AccordionSummary)(() => ({
  padding: '0px 16px',
  margin: '0px',
  width: '100%',
}))

export const Title = styledMUI(Typography)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '120%',
    color: colorsStepsActivedVariant(type),
  }),
)

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50%',
}))

export const BtnBox = styledMUI(Box)(() => ({
  width: '260px',
}))

export const AccordionDetailsContent = styledMUI(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  padding: '0px 16px 16px',
  margin: '0px',
}))

export const ContentParagraphs = styledMUI(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const Paragraph = styledMUI(ListItem)(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
}))

export const ContentPointer = styledMUI(Box)(() => ({
  padding: '6px 0',
}))

export const Pointer = styledMUI(Box)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    backgroundColor: colorsStepsActivedVariant(type),
  }),
)

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '8px',
}))

export const CardLink = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

export const Card = styledMUI(ListItem)<{
  type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep'
  maxHeight: number
}>(({ type, maxHeight }) => ({
  minHeight: '108px',
  height: maxHeight ? `${maxHeight}px` : 'none',
  width: '192px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorsStepsDisabled(type),
  borderRadius: '8px',
  gap: '8px',
}))

export const Loading = styledMUI(Backdrop)(() => ({}))

export const TextCard = styledMUI(Typography)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    color: colorsStepsActivedVariant(type),
    textAlign: 'center',
  }),
)
