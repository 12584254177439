import { createContext, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetBnccRelationsByLessonIdQuery } from '~/queries/autogenerate/hooks'
import parse from 'html-react-parser'

import { graphqlRequestClient } from '~/server/GraphQLClient'

import { IBNCCRelationsContextProps, IBNCCRelationsPageProviderProps } from './interfaces'

const LessonBNCCRelationsPageContext = createContext<IBNCCRelationsContextProps>({
  isLoading: false,
  BNCCText: '',
  status: '',
  idClass: 0,
})

const LessonBNCCRelationsPageProvider = ({ children }: IBNCCRelationsPageProviderProps) => {
  const { idLessonMoment } = useParams()
  const [BNCCText, setBNCCText] = useState<string | JSX.Element | JSX.Element[]>('')
  const [status, setStatus] = useState<string>('')

  const { isLoading, data } = useGetBnccRelationsByLessonIdQuery(graphqlRequestClient('moment'), {
    lessonId: Number(idLessonMoment),
  })

  const idClass = data?.lessonMomentById?.[0]?.class?.idClass

  const lessonBNCCRelationsPageProviderValues = useMemo(() => {
    if (data) {
      const text = parse(
        String(data?.lessonMomentById?.[0]?.lessonTrackGroup?.lessonTrack?.grade?.lesson?.[0]?.txGuidanceBncc),
      )
      if (data?.lessonMomentById?.[0]?.momentStatus) {
        setStatus(data.lessonMomentById[0].momentStatus.coMomentStatus as string)
      }
      setBNCCText(text)
    }

    return { isLoading, BNCCText, status, idClass }
    // eslint-disable-next-line
  }, [data, isLoading, status, idClass])

  return (
    <LessonBNCCRelationsPageContext.Provider value={lessonBNCCRelationsPageProviderValues}>
      {children}
    </LessonBNCCRelationsPageContext.Provider>
  )
}

const useLessonBNCCRelationsPageContext = () => useContext(LessonBNCCRelationsPageContext)

export { LessonBNCCRelationsPageProvider, useLessonBNCCRelationsPageContext }
