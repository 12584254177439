import { AvatarComponent } from '~/components'

import { IAvatarGroupComponentProps } from './AvatarGroup.interfaces'
import * as S from './AvatarGroup.styles'

export const AvatarGroupComponent = ({
  inAttendance = false,
  images,
  max,
  size = 'medium',
}: IAvatarGroupComponentProps) => {
  if (!inAttendance) {
    return (
      <S.AvatarGroupContainer component="ul" max={max} size={size}>
        {images?.map((image, index: number) => {
          return (
            <AvatarComponent
              key={index}
              component="li"
              size={size}
              label={image ? image?.studentClass?.student?.txName || image?.txName : '' || image.label}
              photoUrl={image?.studentClass?.student?.txImagePath || image?.txImagePath || image?.url}
            />
          )
        })}
      </S.AvatarGroupContainer>
    )
  } else {
    return (
      <S.AvatarGroupContainer component="ul" max={max} size={size}>
        {images
          ?.filter((i) => i.inAttendance)
          ?.map((image, index: number) => {
            return (
              <AvatarComponent
                key={index}
                component="li"
                size={size}
                label={image ? image?.studentClass?.student?.txName : ''}
                photoUrl={image?.studentClass?.student?.txImagePath}
              />
            )
          })}
      </S.AvatarGroupContainer>
    )
  }
}
