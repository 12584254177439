import { useCallback, useState } from 'react'

import { UilArrowLeft, UilArrowDown, UilArrowRight, UilArrowUp } from '@iconscout/react-unicons'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import { ButtonComponent } from '~/components'

import { formatDayOfWeek } from '~/validations/typeWeek'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  children,
  selectedDay,
  actionSetSelectedDay,
  monthFormatted,
  changeMonth,
}: IHeaderComponentProps) => {
  const changeDatePicker = useCallback(
    (date: Date) => {
      if (!isNaN(Date.parse(String(date)))) {
        actionSetSelectedDay(date)
        setOpenDatePicker(false)
      }
    },
    [actionSetSelectedDay],
  )

  const handleSelectPrev = useCallback(() => {
    const newSelectedDay = dayjs(selectedDay).subtract(1, 'month').toDate()
    actionSetSelectedDay(newSelectedDay)
  }, [selectedDay, actionSetSelectedDay])

  const handleSelectNext = useCallback(() => {
    const newSelectedDay = dayjs(selectedDay).add(1, 'month').toDate()
    actionSetSelectedDay(newSelectedDay)
  }, [selectedDay, actionSetSelectedDay])

  const handleSelectToday = useCallback(() => {
    actionSetSelectedDay(new Date())
  }, [actionSetSelectedDay])

  const [openDatePicker, setOpenDatePicker] = useState(false)

  return (
    <S.HeaderContainer component="header">
      <S.Wrapper>
        <S.ContentSelectDate>
          <S.RowTitle>
            <S.Title onClick={() => setOpenDatePicker(!openDatePicker)} variant="h1">
              {monthFormatted}
            </S.Title>

            {openDatePicker ? (
              <UilArrowDown onClick={() => setOpenDatePicker(!openDatePicker)} size={40} />
            ) : (
              <UilArrowUp onClick={() => setOpenDatePicker(!openDatePicker)} size={40} />
            )}
          </S.RowTitle>

          <S.ContentSelectDay>
            {openDatePicker && (
              <S.ButtonIcon onClick={() => changeMonth('previous')}>
                <UilArrowLeft size={22} color="#0095FF" />
              </S.ButtonIcon>
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
              <S.DatePicker
                inputFormat={'DD/MM/YYYY'}
                showDaysOutsideCurrentMonth
                open={openDatePicker}
                value={selectedDay}
                showToolbar={false}
                components={{
                  RightArrowIcon: () => null,
                  LeftArrowButton: () => null,
                }}
                dayOfWeekFormatter={formatDayOfWeek}
                onChange={(date: { $d: Date } | null) => date?.$d && changeDatePicker(date.$d)}
                renderInput={(params) => (
                  <div>
                    <ButtonComponent text="Hoje" variant="outline" size="small" onClick={() => handleSelectToday()} />
                  </div>
                )}
                PaperProps={{
                  sx: {
                    marginTop: '90px',
                    width: '420px',
                    height: '100%',
                    backgroundColor: '#fffff',
                    boxShadow: '0px 4px 2px -2px rgba(0, 0, 0, 0.2)',
                    '& .MuiDayPicker-monthContainer': {
                      alignSelf: 'center',
                      flex: '0 0 auto',
                      margin: 0,
                      height: '100%',
                      fontSize: '16px',
                      backgroundColor: '#FFFFFF',
                    },
                    '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                      fontSize: '20px',
                    },
                    '& .MuiPickersCalendarHeader-root': {
                      display: 'none',
                    },

                    '& .css-epd502': {
                      width: '100%',
                    },

                    '& .MuiCalendarPicker-root': {
                      borderRadius: '25px',
                      width: '100%',
                    },

                    '& .MuiDayPicker-header': {
                      width: '100%',
                      display: 'flex',
                      gap: '6px',
                      flexWrap: 'wrap',
                    },
                    '& .MuiPickersDay-root': {
                      flex: '0 0 auto',
                      width: '40px',
                      fontSize: '14px',
                      margin: '0 4px 2px',

                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '50%',
                      '&.Mui-selected': {
                        backgroundColor: '#0095ff',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#0077cc',
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
            {openDatePicker && (
              <S.ButtonIcon onClick={() => changeMonth('next')}>
                <UilArrowRight size={22} color="#0095FF" />
              </S.ButtonIcon>
            )}
          </S.ContentSelectDay>
        </S.ContentSelectDate>
      </S.Wrapper>

      {children}
    </S.HeaderContainer>
  )
}
