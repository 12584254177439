export const renderStatusText = (status: string) => {
  switch (status) {
    case 'AGEN':
      return 'Agendada'
    case 'AUPL':
      return 'Para Realizar e Documentar'
    case 'AGUA':
      return 'Para Realizar e Documentar'
    case 'FINA':
      return 'Finalizada'
    case 'AVPE':
      return 'Para Avaliar'
    case 'PEND':
      return 'Para Planejar'
    case 'AUPE':
      return 'Para Atualizar'
    case 'INIC':
      return 'Para Realizar e Documentar'
    case 'CANC':
      return 'Cancelada'
    default:
      'INIC'
      break
  }
}

export const renderLabelBtn = (status: string, isProject: boolean) => {
  if (isProject === true) {
    switch (status) {
      case 'AVPE':
        return 'Avaliar etapa'
      case 'PEND':
        return 'Planejar etapa'
      case 'AUPE':
        return 'Atualizar etapa'
      default:
        'INIC'
        break
    }
  } else {
    switch (status) {
      case 'AVPE':
        return 'Avaliar aula'
      case 'PEND':
        return 'Planejar aula'
      case 'AUPE':
        return 'Atualizar aula'
      default:
        'INIC'
        break
    }
  }
}
