import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { renderStatusText } from '~/validations/renderStatusText'

import * as S from './TooltipStatus.styles'

export const TooltipStatusComponent = ({ status, children }: TooltipStatusComponentProps) => {
  return (
    <S.TooltipContainerHover>
      <S.TooltipContainer>
        <DSTooltip text={renderStatusText(status) as string} position="right">
          {children}
        </DSTooltip>
      </S.TooltipContainer>
    </S.TooltipContainerHover>
  )
}

export default TooltipStatusComponent
