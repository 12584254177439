import { useTheme } from 'styled-components'

import { renderStatusText } from '~/validations/renderStatusText'
import { statusIcon } from '~/validations/statusIcon'

import { IStatusTextComponentProps } from './StatusText.interfaces'
import * as S from './StatusText.styles'

export const StatusTextComponent = ({
  statusCode,
  statusText,
  size,
  type = 'default',
  activeTextOverflow,
}: IStatusTextComponentProps) => {
  const theme = useTheme()
  let color = ''

  switch (statusCode) {
    case 'AGEN':
      color = theme.colors.midDark
      break

    case 'AUPL':
      color = theme.colors.primaryPure
      break

    case 'AVPE':
      color = theme.colors.warningPure
      break

    case 'AUPE':
      color = theme.colors.negativePure
      break

    case 'INIC':
      color = theme.colors.primaryPure
      break

    case 'AGUA':
      color = theme.colors.primaryPure
      break

    case 'FINA':
      color = theme.colors.positivePure
      break

    case 'PEND':
      color = theme.colors.actionPure
      break
    case 'CANC':
      color = theme.colors.lowLight
      break
  }

  return (
    <S.StatusTextContainer>
      {statusIcon(statusCode as string)}

      {type !== 'pointer' && (
        <S.TextStatus size={size} color={color} activeTextOverflow={activeTextOverflow!}>
          {renderStatusText(statusCode as string)}
        </S.TextStatus>
      )}
    </S.StatusTextContainer>
  )
}
