import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AppBarComponent,
  CardTrailComponent,
  ContainerComponent,
  ModalBNCCComponent,
  SkeletonComponent,
  ValidateDataSessionComponent,
} from '~/components'
import CardDetailTrail from '~/components/Cards/CardDetailTrail/CardDetailTrail'
import { ExploreCard } from '~/components/Cards/ExpoloreCard/ExploreCard'
import { ListStepsComponent } from '~/components/Cards/ListSteps/ListSteps'
import LessonSteps from '~/components/Navigation/LessonSteps/LessonSteps'
import TruncatedTextDescription from '~/components/ui/TruncatedTextDescription/TruncatedTextDescription'

import { HeaderComponent } from '~/containers/private/Trails/TrailDetailsClass'

import { useTrailDetailsClassPageContext } from './context'
import { ILessonProps } from './interfaces'
import SecondStep from './SecondStep/SecondStep'
import * as S from './styles'

export const TrailDetailsClassView = () => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const { classId, trailId } = useParams()
  const {
    openModalBNCC,
    handleOpenModalBNCC,
    handleCloseModalBNCC,
    handleSchedule,
    isLoadingTrailsDetails,
    dataTrailsClass,
    coStage,
    txGrade,
    txClassShift,
    track,
    txSchoolGradeName,
    setLesson,
    dataLesson,
    isLoadingLesson,
    setLessonDetail,
    lessonDetail,
    isLoadingDetail,
    dataDetail,
    setLessonDetailStepTwo,
    lessonDetailStepTwo,
    isLoadingDetailStepTwo,
    dataDetailStepTwo,
    handleBack,
  } = useTrailDetailsClassPageContext()

  const idTrackLesson = track?.idTrack
  const [trailIndex, setTrailIndex] = useState<number | null>(null)
  const sortedLessons = track?.lesson?.sort((a, b) => a.lessonTrackGroupNuOrder - b.lessonTrackGroupNuOrder) || []
  const [activeCardsStep, setActiveCardsStep] = useState(true)
  const [activeStep, setActiveStep] = useState(false)
  const [activeStepTwo, setActiveStepTwo] = useState(false)

  const handleActiveCard = (index: number) => {
    setTrailIndex(index)
    setLesson(sortedLessons[index])
    setActiveStep(true)
    setActiveCardsStep(false)
    setActiveStepTwo(false)
    if (lessonDetail) {
      handleBack()
    }
  }
  function handleAllLessons() {
    setTrailIndex(null)
    handleBack()
    setActiveCardsStep(true)
    setActiveStep(false)
    setActiveStepTwo(false)
  }

  const handleOpenDetail = (lesson: any) => {
    if (lesson.nuOrder !== 2) {
      setLessonDetail(lesson)
    } else {
      setLessonDetailStepTwo(lesson)
      setActiveStep(false)
      setActiveStepTwo(true)
    }
  }
  const handleBackStep = () => {
    setActiveStep(true)
    setActiveStepTwo(false)
  }

  const returnVariant = (index: number) => {
    switch (index) {
      case 1:
        return 'one'
      case 2:
        return 'two'
      case 3:
        return 'three'
      default:
        return 'default'
    }
  }

  return (
    <S.ContainerScroll>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          loading={isLoadingTrailsDetails}
          path={`/trails-available/${classId}`}
          position={idTrackLesson}
          title={track?.txTitle as string}
          components={track?.component}
          description={track?.txDescription ? track?.txDescription : 'Sem descrição de trilha.'}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionSelectTrail={handleSchedule}
          coStage={coStage}
          txGrade={txGrade}
          txClassShift={txClassShift}
          txSchoolGradeName={txSchoolGradeName}
          idClass={classId}
        />

        <>
          {isLoadingTrailsDetails && !(dataTrailsClass?.length ?? 0) ? (
            <ValidateDataSessionComponent
              screen="home"
              title="Você não tem nenhuma trilha disponível."
              description="Entre em contato com a coordenação da sua escola ou com o suporte Lekto para mais informações."
            />
          ) : (
            <S.ContentList component="ul">
              {isLoadingTrailsDetails ? (
                <>
                  {Object.keys(new Array(5).fill(null)).map((_, index: number) => (
                    <CardTrailComponent loading={isLoadingTrailsDetails} key={index} index={index} data={null} />
                  ))}
                </>
              ) : (
                <>
                  {!(activeStep || activeStepTwo) && (
                    <TruncatedTextDescription
                      title={'trilha'}
                      txDescription={track?.txDescription}
                      isMobile={false}
                      textLength={470}
                    />
                  )}
                  <LessonSteps
                    data={track}
                    onChangeTrailIndex={trailIndex}
                    handleAllLessons={handleAllLessons}
                    handleActiveCard={(index) => handleActiveCard(index)}
                  />

                  {activeCardsStep && (
                    <S.CardSliderResumeContainer isMobile={Boolean(isMobile)}>
                      {sortedLessons?.map((lesson: ILessonProps, index: number) => (
                        <div key={index}>
                          <CardDetailTrail
                            index={index}
                            dataTrail={lesson}
                            handleActiveCard={() => handleActiveCard(index)}
                            variant="P1"
                            itemsPerPage={sortedLessons.length}
                          />
                        </div>
                      ))}
                    </S.CardSliderResumeContainer>
                  )}
                  {!lessonDetail && activeStep && (
                    <>
                      <S.StepHeader>
                        <S.ContentNumber>
                          <S.StepNumber>{trailIndex != null ? trailIndex + 1 : ''}</S.StepNumber>
                        </S.ContentNumber>
                        <S.TitleLessonStep>
                          {trailIndex !== null && sortedLessons[trailIndex].txTitle}
                        </S.TitleLessonStep>
                      </S.StepHeader>
                      <S.CardDescription>
                        <TruncatedTextDescription
                          title={'aula'}
                          txDescription={trailIndex !== null && sortedLessons[trailIndex].txDescription}
                          isMobile={false}
                          textLength={470}
                        />
                      </S.CardDescription>
                      {isLoadingLesson ? (
                        <Box alignItems={'center'} display="flex" flexDirection="column" gap={2}>
                          <SkeletonComponent variant="rounded" width={'100%'} height={60} />
                          <SkeletonComponent variant="rounded" width={'100%'} height={320} />
                          <SkeletonComponent variant="rounded" width={'100%'} height={60} />
                        </Box>
                      ) : (
                        dataLesson?.lessonSteps.map((lesson, index) => (
                          <ListStepsComponent
                            variant={returnVariant(lesson.nuOrder)}
                            key={index}
                            title={lesson?.txTitle}
                            activities={lesson?.stepActivities}
                            actionExplore={() => handleOpenDetail(lesson)}
                            index={lesson.nuOrder}
                          />
                        ))
                      )}
                    </>
                  )}

                  {lessonDetail && activeStep && (
                    <>
                      <S.StepHeader>
                        <S.ContentNumber>
                          <S.StepNumber>{trailIndex != null ? trailIndex + 1 : ''}</S.StepNumber>
                        </S.ContentNumber>
                        <S.TitleLessonStep>
                          {trailIndex !== null && sortedLessons[trailIndex].txTitle}
                        </S.TitleLessonStep>
                      </S.StepHeader>
                      <ExploreCard
                        isLoading={isLoadingDetail}
                        type={returnVariant(dataDetail?.nuOrder)}
                        evidences={dataDetail?.lessonActivity[0]?.evidences}
                        challenge={dataDetail?.lessonActivity[0]?.txChallenge}
                        handleBack={() => handleBack()}
                        activityOrientations={dataDetail?.lessonActivity[0].activityOrientations}
                        index={dataDetail?.nuOrder}
                        title={dataDetail?.txTitle}
                        variant={'P1'}
                      />
                    </>
                  )}

                  {!isLoadingDetailStepTwo && dataDetailStepTwo && activeStepTwo && (
                    <>
                      <S.StepHeader>
                        <S.ContentNumber>
                          <S.StepNumber>
                            {trailIndex !== null && sortedLessons[trailIndex].lessonTrackGroupNuOrder}
                          </S.StepNumber>
                        </S.ContentNumber>
                        <S.TitleLessonStep>
                          {trailIndex !== null && sortedLessons[trailIndex].txTitle}
                        </S.TitleLessonStep>
                      </S.StepHeader>
                      <SecondStep
                        isLoading={isLoadingDetailStepTwo}
                        handleBackStep={() => handleBackStep()}
                        trailIndex={trailIndex}
                        lessonActivity={dataDetailStepTwo?.lessonActivity}
                        evidences={dataDetailStepTwo?.lessonActivity}
                        index={dataDetailStepTwo?.nuOrder}
                        title={dataDetailStepTwo?.txTitle}
                        description={dataDetailStepTwo?.txGuidance}
                      />
                    </>
                  )}
                </>
              )}
            </S.ContentList>
          )}
        </>
      </ContainerComponent>

      <ModalBNCCComponent
        open={openModalBNCC}
        txGuidanceBNCC={track?.txGuidanceBNCC}
        actionCloseModal={handleCloseModalBNCC}
      />
    </S.ContainerScroll>
  )
}
