import { IAvatarComponentProps } from './Avatar.interfaces'
import * as S from './Avatar.styles'

export const AvatarComponent = ({
  label,
  photoUrl,
  size = 'medium',
  component = 'div',
  isSelected,
}: IAvatarComponentProps) => {
  return (
    <>
      {label?.length ? (
        <S.TooltipLabel
          title={label ? label : ''}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#000',
                '& .MuiTooltip-arrow': {
                  color: 'common.black',
                },
              },
            },
          }}
        >
          <S.AvatarContainer selected={isSelected} component={component} src={photoUrl} size={size} alt="Avatar">
            {label.slice(0, 1)}
          </S.AvatarContainer>
        </S.TooltipLabel>
      ) : (
        <S.AvatarContainer component={component} src={photoUrl} size={size} alt="Avatar" />
      )}
    </>
  )
}
