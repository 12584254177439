import { Box, Card, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export interface IMomentCardComponentProps {
  cardbackgroundcolor?: string
  type: string
  isResponsable?: boolean
}

interface CardType {
  type?: 'default' | 'week' | 'month'
}

export const Container = styledMUI(Card, {
  shouldForwardProp: (prop) => prop !== 'isResponsable',
})<IMomentCardComponentProps>(({ cardbackgroundcolor, type, isResponsable }) => ({
  width: '100%',
  minWidth: '100%',
  display: 'flex',
  flexDirection: type === 'month' ? 'row' : 'column',
  backgroundColor: cardbackgroundcolor ? cardbackgroundcolor : '#F9FAFC',
  padding: type === 'month' ? '4px 8px' : '16px',
  borderRadius: '8px',
  justifyContent: type === 'month' ? 'space-between' : 'initial',
  alignItems: type === 'month' ? 'center' : 'initial',
  gap: type === 'month' ? 0 : '16px',
  boxShadow: 'none',
  cursor: isResponsable ? 'default' : 'pointer',
  transition: 'boxShadow 0.5s easy-out',

  '&:hover': {
    boxShadow: theme.shadows.level3,
  },

  '& button': {
    transition: 'all 0.5s',
  },

  '&:hover button': {
    color: theme.colors.actionDark,
    border: `1px solid ${theme.colors.actionDark}`,
  },
}))

export const ClassSpanInfo = styledMUI(Box)(() => ({
  fontSize: '14px',
  padding: '0 6px',
  color: '#7c8189',
  borderRight: '1px solid #e2e5ec',
  '&:last-child': {
    borderRight: 0,
  },
}))

export const ContainerTrackClass = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #7c8189',
  width: 'fit-content',
  padding: '4px 6px',
  borderRadius: '4px',
}))

export const Wrapper = styledMUI(Box)<CardType>(({ type }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: type === 'month' ? 'flex-start' : 'space-between',
  gap: type === 'month' ? '8px' : 0,
}))

export const WrapperChip = styledMUI(Box)<CardType>(({ type }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
}))

export const ChipDay = styled.span`
  font-size: 12px;
  text-align: end;
  color: #666666;
`

export const ContentTimeStatus = styledMUI(Box)<CardType>(({ type }) => ({
  display: type === 'default' ? 'flex' : 'grid',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'grid',
    width: '100%',
  },
}))

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 8;
  align-items: center;
`

export const Title = styledMUI(Typography)<CardType>(({ type }) => ({
  fontStyle: 'normal',
  fontWeight: type === 'month' ? 400 : 700,
  fontSize: type === 'default' ? '20px' : type === 'week' ? '16px' : '12px',
  lineHeight: '120%',
  color: '#000',
  textOverflow: type === 'month' ? 'ellipsis' : 'unset',
  overflow: type === 'month' ? 'hidden' : 'unset',
  whiteSpace: type === 'month' ? 'nowrap' : 'unset',
}))

export const ContainerAbilities = styled.div`
  padding: 8px;
  background-color: #e7effc;
  border-radius: 8px;
  display: grid;
  gap: 8px;
`

export const Ability = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const TextAbility = styled.p`
  font-size: 12px;
  font-weight: 400;
`

export const StatusAbility = styled.span`
  padding: 2px 4px 2px 4px;
  background-color: ${({ theme }) => theme.colors.midMedium};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.midDark};
  font-size: 12px;
  font-weight: 500;
`

export const TextDescription = styledMUI(Typography)<{ type: string }>(({ type }) => ({
  fontStyle: 'normal',
  fontWeight: type === 'default' ? 700 : 400,
  fontSize: type === 'default' ? '16px' : '12px',
  lineHeight: '120%',
  color: '#666',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: '#E2E5EC',
  padding: 0,
}))

export const ButtonIconMobile = styledMUI(IconButton)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: 'solid 1px #0095FF',
  backgroundColor: 'white',
  padding: 0,
}))
