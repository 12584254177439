import React from 'react'

import { Box } from '@mui/material'

import { SkeletonComponent, StepsTabsComponent } from '~/components'

import { TabFirstStep } from '../components/TabFirstStep'
import { TabSecondStep } from '../components/TabSecondStep'
import { TabThirdStep } from '../components/TabThirdStep'
import * as S from '../styles'

interface LessonFormProps {
  lessonStep: []
  tabActived: number
  handleActiveNewTab: () => object
  mutateAssessment: () => object
  mutateAssessmentBatch: () => object
  status: string
  firstEvidence: any
  idLessonMoment: number
  idClass: number
  isClassInStatus: boolean
}

const LessonForm: React.FC<LessonFormProps> = ({
  lessonStep,
  tabActived,
  handleActiveNewTab,
  status,
  firstEvidence,
  idLessonMoment,
  mutateAssessment,
  mutateAssessmentBatch,
  idClass,
  isClassInStatus,
}) => {
  const handleAssessment = (id: string, coAssessment: string, idEvidence: string) => {
    mutateAssessment({
      body: { idMomentGroup: id, coAssessment, idEvidence },
      idLessonMoment: String(idLessonMoment),
      idClass: String(idClass),
    })
  }

  const handleAssessmentBatch = (body: { idLessonMoment: string }) => {
    body.idLessonMoment = String(idLessonMoment)
    body.idClass = String(idClass)
    mutateAssessmentBatch(body)
  }

  return (
    <>
      <S.Content>
        <StepsTabsComponent
          type={'default'}
          steps={[
            { label: 'Passo 1', labelDescription: '' },
            { label: 'Passo 2', labelDescription: '' },
            { label: 'Passo 3', labelDescription: '' },
          ]}
          activedTab={tabActived}
          actionSetActiveTab={handleActiveNewTab}
        />

        {lessonStep && tabActived === 1 ? (
          <TabSecondStep
            isClassInStatus={isClassInStatus}
            status={status as string}
            lessonStep={lessonStep}
            handleAssessment={handleAssessment}
            handleAssessmentBatch={handleAssessmentBatch}
          />
        ) : lessonStep && tabActived === 0 ? (
          <TabFirstStep
            isClassInStatus={isClassInStatus}
            status={status as string}
            idLessonMomentActivity={
              lessonStep?.lessonActivity?.[0]?.lessonMomentActivity?.[0]?.idLessonMomentActivity as number
            }
            handleAssessment={handleAssessment}
            handleAssessmentBatch={handleAssessmentBatch}
            evidenceDescription={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.txName as string
            }
            compentencyName={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.competence
                ?.txName as string
            }
            txName={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability
                ?.txName as string
            }
            txIconSkill={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability
                ?.txImagePath as string
            }
            txIconCompetency={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.competence
                ?.txImagePath as string
            }
            idEvidence={firstEvidence}
          />
        ) : lessonStep && tabActived === 2 ? (
          <TabThirdStep
            isClassInStatus={isClassInStatus}
            status={status as string}
            idEvidence={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.idEvidence as number
            }
            idLessonMomentActivity={
              lessonStep?.lessonActivity?.[0]?.lessonMomentActivity?.[0]?.idLessonMomentActivity as number
            }
            handleAssessment={handleAssessment}
            handleAssessmentBatch={handleAssessmentBatch}
            evidenceDescription={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.txName as string
            }
            compentencyName={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.competence
                ?.txName as string
            }
            txName={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability
                ?.txName as string
            }
            txIconSkill={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability
                ?.txImagePath as string
            }
            txIconCompetency={
              lessonStep?.lessonActivity?.[0]?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.competence
                ?.txImagePath as string
            }
          />
        ) : (
          <>
            <Box mt={5} p={2} display="flex" flexDirection="column" gap={2}>
              <SkeletonComponent variant="rounded" width={'100%'} height={52} />

              <Box mt={2} display="flex" flexDirection="row" gap={2} justifyItems={'center'}>
                <SkeletonComponent variant="rounded" width={60} height={20} />
                <SkeletonComponent variant="rounded" width={200} height={20} />
                <SkeletonComponent variant="rounded" width={200} height={20} />
              </Box>
              <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
                <SkeletonComponent variant="rounded" width={'100%'} height={20} />
                <SkeletonComponent variant="rounded" width={'30%'} height={20} />
              </Box>

              <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
                <SkeletonComponent variant="rounded" width={'100%'} height={150} />
              </Box>
            </Box>
            <Box display="flex" p={2} flexWrap="wrap" gap={2}>
              {Array.from({ length: 4 }).map((_, index) => (
                <Box key={index} display="flex" flexDirection="column" gap={2} width="49%">
                  <Box>
                    <SkeletonComponent variant="rounded" width={'100%'} height={200} />
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </S.Content>
    </>
  )
}

export default LessonForm
