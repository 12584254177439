import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

export interface IIdenticalLessonMoments {
  idLessonMoment: number
  dtSchedule: string
  coMomentStatus: string
  coGrade: string
  txGrade: string
  txClassName: string
  txStageName: string
}

const getIdenticalLessonMoments = async (idLesson: number) => {
  const { data } = await HTTPClient.get('/lesson-moment/identical/lesson/' + idLesson)
  return data
}

export const useGetIdenticalLessonMoments = (
  idLesson: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<IIdenticalLessonMoments[]>(['getIdenticalLessonMoments'], () => getIdenticalLessonMoments(idLesson), options)
