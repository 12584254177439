import Lottie from 'react-lottie'

import AnimationError from '~/assets/data.json'

import { ButtonComponent } from '~/components'

import { useNotFoundContext } from '~/pages/public/NotFound/context'

import * as S from './styles'

export const NotFoundView = () => {
  const { handleGoToHome } = useNotFoundContext()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationError,
  }

  return (
    <S.Content>
      <Lottie options={defaultOptions} width={800} height={500} />
      <S.Title>Ops, não encontramos essa página</S.Title>
      <S.Text>
        Não foi possível carregar essa página. Caso o problema persista por mais de 10 minutos, por favor, entre em
        contato com suporte!
      </S.Text>
      <S.ActionContainer>
        <ButtonComponent
          type="button"
          onClick={handleGoToHome}
          text={'Voltar ao inicío'}
          variant={'solid'}
          size={'medium'}
        />
      </S.ActionContainer>
    </S.Content>
  )
}
