import { gql } from 'graphql-request'

export const GET_TRAILS_BY_PROJECT_ID_OCUPIED = gql`
  query getProjectByID($trailId: Int!) {
    projectTrack(idClass: $trailId) {
      items {
        txTitle
        txDescription
        txGuidanceBNCC
        component {
          coComponent
          txComponent
        }
        projectTrackStage {
          idProjectTrackStage
          idProjectTrack
          coGrade
          txDescription
          evidenceGrade {
            evidence {
              idEvidence
              txName
              ability {
                idAbility
                txName
                txImagePath
                txPrimaryColor
                txBgSecondaryColor
                txBgPrimaryColor
              }
            }
          }
          projectStageGroup {
            projectStage {
              idProjectStage
              coGrade
              txGuidanceBncc
              txTitle
              txDescription
              nuOrder
              nuDuration
              mediaInformation {
                txAbsoluteUrl
              }
              projectStageOrientation {
                idProjectStage
                txTitle
                idProjectStageOrientation
                txOrientationCode
                projectStageSupportReference {
                  txTitle
                  mediaInformation {
                    idMediaInformation
                    txName
                    txContentType
                    txAbsoluteUrl
                  }
                }
              }
              projectStageSchoolSupply {
                txSupply
                txQuantity
                idProjectStageSchoolSupply
              }
            }
          }
        }
      }
    }
  }
`
