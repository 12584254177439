import { Box, Fab, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { IDayNumberProps } from './CarouselCalendar.interfaces'

export const ContainerSlider = styledMUI(Box)(() => ({
  height: 130,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderBottom: '1px solid #E2E5EC',
  padding: 24,
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: 32,
  width: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #0095FF',
  borderRadius: '50%',
  marginTop: 30,
  padding: 0,

  zIndex: 1,
}))

export const ContentDays = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  '@media (max-width: 600px)': {
    maxWidth: '40px',
  },
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '100%',
  color: '#666',
}))

export const ButtonDay = styledMUI(Fab)<IDayNumberProps>(({ current_day, selected }) => ({
  height: 56,
  width: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Inter',
  backgroundColor: selected ? '#8C22BC' : '#fff',
  color: selected ? '#fff' : current_day ? '#8C22BC' : '#000',
  zIndex: selected ? 1 : 0,
  borderRadius: '50%',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '100%',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: selected ? '#8C22BC' : '#e0e0e0',
  },
}))
