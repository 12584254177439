import { UilArrowRight } from '@iconscout/react-unicons'
import { Switch } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { CardSettingsProps } from './CardSettings.interfaces'
import * as S from './CardSettings.styles'

export const CardSettingsComponent = ({
  type,
  href,
  icon,
  label,
  description,
  action,
  checked,
  setChecked,
  loading,
  isHeaderControl,
}: CardSettingsProps) => {
  const handleToggleOfflineMode = () => {
    if (setChecked && action) {
      setChecked(!checked)
      action()
    }
  }

  return (
    <>
      {href ? (
        <S.LinkCardContainer href={href} target="_blank">
          <S.CardContainer>
            <S.WrapperBetween>
              <S.Wrapper>
                {icon}

                <S.ContentText>
                  <S.TextLabel isHeaderControl={isHeaderControl}>{label}</S.TextLabel>

                  {description && <S.TextDescription>{description}</S.TextDescription>}
                </S.ContentText>
              </S.Wrapper>
              {!isHeaderControl && (
                <S.ContentIcon>
                  <UilArrowRight size={20} color="#7C8189" />
                </S.ContentIcon>
              )}
            </S.WrapperBetween>
          </S.CardContainer>
        </S.LinkCardContainer>
      ) : (
        <S.CardContainer onClick={type !== 'offline' ? action : () => ({})}>
          <S.WrapperBetween>
            <S.Wrapper>
              {icon}

              <S.ContentText>
                <S.TextLabel isHeaderControl={isHeaderControl}>{label}</S.TextLabel>

                {description && <S.TextDescription>{description}</S.TextDescription>}
              </S.ContentText>
            </S.Wrapper>

            {type === 'offline' ? (
              <>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Switch checked={checked} onChange={handleToggleOfflineMode} color="primary" />
                )}
              </>
            ) : (
              <S.ContentIcon>
                <UilArrowRight size={20} color="#7C8189" />
              </S.ContentIcon>
            )}
          </S.WrapperBetween>
        </S.CardContainer>
      )}
    </>
  )
}
