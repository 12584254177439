import { gql } from 'graphql-request'

export const GET_CLASS_INFOS_BY_CLASS_ID = gql`
  query getClassInfosByClassID($classId: Int!, $showDisabledClasses: Boolean!) {
    class(where: { idClass: { eq: $classId } }, showDisabledClasses: $showDisabledClasses) {
      items {
        classShift {
          txClassShift
        }
        idClass
        txName
        inStatus
        schoolGrade {
          grade {
            coGrade
            txGrade

            stage {
              coStage
              txName
            }
          }

          school {
            idSchool
            txName
          }
        }

        studentClass {
          student {
            idStudent

            lektoUser {
              txName
              txImagePath
            }
          }
        }
      }
    }
  }
`
