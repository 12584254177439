import styled, { css } from 'styled-components'

interface ICardProps {
  color: string
}

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Card = styled.div<ICardProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  gap: 16px;

  ${({ color, theme }) => {
    return css`
      color: ${theme.colors[`${color}Pure` as keyof typeof theme.colors]};
      background-color: ${theme.colors[`${color}Light` as keyof typeof theme.colors]};
    `
  }}
`

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
`
export const TittleBefore = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  min-width: fit-content;
`

export const CardArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.midMedium};
`

export const TextParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.lowPure};
`

export const CardInfo = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const Avatars = styled.div`
  justify-self: flex-end;
  align-self: flex-end;
`

export const Subject = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.lowMedium};
  background-color: ${({ theme }) => theme.colors.highPure};
  border-radius: 4px;
  padding: 4px 8px;
`

export const SubjectsDiv = styled.div`
  display: flex;
  gap: 8px;
`
