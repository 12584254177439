import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '240px 24px 24px 24px',
  '@media (max-width: 600px)': {
    padding: '24px',
    marginTop: '100px',
    marginBottom: '100px',
  },
}))

export const ContentToday = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  gap: '8px',
  marginTop: '20px',
  padding: '8px',
}))
export const ContentEmoji = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '300px',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-arround',
  borderBottom: '1px solid #E2E5EC',
  width: '100%',
  '@media (max-width: 600px)': {
    display: 'grid',
    width: '100%',
  },
}))
export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const RowTitle = styledMUI(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
}))

export const BoxChip = styledMUI(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '16px',
  fontWeight: 700,
  fontSize: '10px',
  gap: '0px',
  borderRadius: '4px 0px 0px 0px',
  color: `${theme.colors.primaryPure}`,
  backgroundColor: `${theme.colors.primaryLight}`,
}))

export const ContentSelectDate = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  gap: 16,
}))

export const ContentMessage = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  padding: '16px',
}))

export const DateText = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: `${theme.colors.primaryPure}`,
}))

export const CurrentDayTag = styledMUI(Box)(() => ({
  fontSize: '11px',
  fontWeight: 700,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '30px',
  height: '16px',
  backgroundColor: `${theme.colors.primaryLight}`,
  padding: '4px',
  color: `${theme.colors.primaryPure}`,
}))
