import { useState } from 'react'

import {
  AccordionOrientationsComponent,
  AppBarComponent,
  ContainerComponent,
  QuickNavigationComponent,
} from '~/components'

import { HeaderComponent } from '~/containers/private/Fund2/ProjectStage'

import { useProjectPageContext } from './context'
import * as S from './styles'

export const FirstStageProjectView = () => {
  const { step, status, dataStudentsGroup } = useProjectPageContext()

  const [activedTab, setActivedTab] = useState<number>(0)

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            status={status}
            label="Voltar"
            stepActived="firstStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
            isOffline
          />
        </S.NavContainer>

        <HeaderComponent
          type="firstStep"
          txCategory={step?.projectStage?.project?.projectCategory[0]?.category?.txName}
          icon={step?.projectStage?.project?.projectCategory[0]?.category?.txImagePath}
          txTitleProject={step?.projectStage?.project?.txTitle}
          students={dataStudentsGroup}
          activedTab={activedTab}
          actionSetActiveTab={(newActivedTab: number) => setActivedTab(newActivedTab)}
          components={step?.projectStage?.project?.projectComponent}
          evidenceFixed={step?.projectStage?.evidenceFixed}
          evidenceVariable={step?.projectStage?.evidenceVariable}
          txChallenge={step?.projectStage?.project?.txDescription}
          studentGuidePath={step?.projectStage?.mediaInformation?.txAbsoluteUrl}
          coMomentStatus={status}
          status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
        />

        <S.Content>
          {activedTab === 0 && (
            <S.ContentOrientations>
              {step?.projectStage?.projectStageOrientation?.map((orientation, index: number) => (
                <AccordionOrientationsComponent
                  key={index}
                  type="firstStep"
                  title={orientation?.txTitle}
                  txOrientationCode={orientation?.txOrientationCode}
                  supportReference={orientation?.projectStageSupportReference}
                />
              ))}
            </S.ContentOrientations>
          )}
        </S.Content>
      </ContainerComponent>
    </>
  )
}
