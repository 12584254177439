import { UilEye } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent, IconComponent } from '~/components'

import { LessonActivity } from '~/pages/private/Fund1/Lesson/interfaces'

import { ICardTrailComponentProps } from './ListSteps.interfaces'
import * as S from './ListSteps.styles'

export const ListStepsComponent = ({ variant, activities, actionExplore, index, title }: ICardTrailComponentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 768

  const renderActivity = (activity: LessonActivity) => {
    return (
      <S.CardActivity key={activity.idLessonActivity}>
        <S.ContentActivity>
          <S.ContentLabels>
            <S.Row>
              <IconComponent color="card" fontWeight="normal" code={activity?.category.txImagePath} size="xsmall" />
              <S.TextStepDescriptionActivity color="card">
                <S.textLesson>{activity?.category?.txName}</S.textLesson> -{' '}
                <S.textLesson>{activity?.txTitle}</S.textLesson>
              </S.TextStepDescriptionActivity>
            </S.Row>
          </S.ContentLabels>
        </S.ContentActivity>
        <S.WrapperEvidence>
          <S.Label>Desafio</S.Label>
          <S.TextChallenge>{activity?.txChallenge}</S.TextChallenge>
        </S.WrapperEvidence>
      </S.CardActivity>
    )
  }

  return (
    <S.Container>
      <S.Content>
        <S.ContentLabels>
          <S.RowTitle>
            <S.TextMain weight={500} variant={variant}>
              Passo {index}
            </S.TextMain>
            <S.TextMain weight={700} variant={variant}>
              {title}
            </S.TextMain>
          </S.RowTitle>
        </S.ContentLabels>

        <ButtonComponent
          size="xsmall"
          text="Explorar"
          fontWeight="normal"
          fontSize="small"
          fullWidth={isMobile ? true : false}
          variant="outline"
          iconEnd={<UilEye size={16} />}
          onClick={() => actionExplore()}
        />
      </S.Content>
      {activities && (
        <S.ContentActivities>
          {variant === 'two' && activities?.map((activity) => renderActivity(activity))}
        </S.ContentActivities>
      )}
    </S.Container>
  )
}
