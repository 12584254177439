import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'

import { useAuth } from '~/_context/Auth'

import { useServiceImpersonatedToken } from '~/services/ImpersonatedToken'

import { IAuthThirdPartyPageContextProps, IAuthThirdPartyPageProviderProps } from './interfaces'

const AuthThirdPartyPageContext = createContext<IAuthThirdPartyPageContextProps>({
  loading: true,
})

function useQueryParams() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const AuthThirdPartyPageProvider = ({ children }: IAuthThirdPartyPageProviderProps) => {
  const queryParams = useQueryParams()
  const token = queryParams.get('token')
  const callbackErrorUrl = queryParams.get('callbackErrorUrl')
  const { authenticatedUser, handleLogout } = useAuth()
  useServiceImpersonatedToken(String(token), {
    enabled: !!token,
    onSuccess: (data) => {
      authenticatedUser(data.access_token, true)
      window.location.href = '/home'
    },
    onError: () => {
      if (callbackErrorUrl) {
        window.location.replace(callbackErrorUrl)
      } else {
        window.location.href = '/home'
      }
    },
  })

  useEffect(() => {
    if (token) {
      handleLogout()
    } else {
      window.location.href = '/home'
    }
  }, []) // eslint-disable-line

  const authThirdPartyPageProviderValues = useMemo(() => {
    return {}
  }, [])

  return (
    <AuthThirdPartyPageContext.Provider value={authThirdPartyPageProviderValues}>
      {children}
    </AuthThirdPartyPageContext.Provider>
  )
}
const useAuthThirdPartyPageContext = () => useContext(AuthThirdPartyPageContext)
export { AuthThirdPartyPageProvider, useAuthThirdPartyPageContext }
