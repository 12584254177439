import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export const createSignalRConnection = (): HubConnection => {
  const tokenStorage = localStorage.getItem('@LEKTO:token')
  const env = import.meta.env.VITE_BASE_URL_GRAPHQL
  const connection = new HubConnectionBuilder()
    .withUrl(`${env}hubs/notification`, {
      accessTokenFactory: async () => tokenStorage ?? '',
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build()

  return connection
}
