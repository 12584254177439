import { useParams } from 'react-router-dom'

import {
  AppBarComponent,
  CardTrailComponent,
  ContainerComponent,
  DividerComponent,
  ModalBNCCComponent,
} from '~/components'
import TruncatedTextDescription from '~/components/ui/TruncatedTextDescription/TruncatedTextDescription'

import { TrailsHeaderComponent } from '~/containers/private/Fund2/Trails'

import { useTrailsGeneratedContext } from '~/pages/private/Fund1/TrailsGenerated/context'

import * as S from './styles'

export const TrailsGeneratedView = () => {
  const { trailId, classId } = useParams()
  const {
    openModalBNCC,
    handleCloseModalBNCC,
    handleOpenModalBNCC,
    handleOpenModalSchedule,
    isLoadingTrailsNotGenerated,
    dataClassDetails,
    dataClassInfos,
    isSameUser,
  } = useTrailsGeneratedContext()
  const coStage = dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage
  const txClassShift = dataClassInfos?.class?.items[0]?.classShift?.txClassShift

  const lesson = dataClassDetails?.lesson
  const scheduleMoments = dataClassDetails?.moment

  const MappedCards = lesson?.map((lesson) => ({
    ...lesson,
    lessonTrackGroup: scheduleMoments?.find((group) => group.lessonTrackGroup.idLesson === lesson.idLesson),
  }))

  const mergedArray = MappedCards?.filter((item) => item.idLesson !== null).sort(
    (a: any, b: any) =>
      Number(a.lessonTrackGroup.lessonTrackGroup.nuOrder) - Number(b.lessonTrackGroup.lessonTrackGroup.nuOrder),
  )

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <TrailsHeaderComponent
          isDisabledClasses={dataClassDetails?.class[0].inStatus}
          path={`/trail/${trailId}/occupied/${classId}`}
          txTitle={dataClassDetails?.txTitle}
          component={dataClassDetails?.component}
          professor={dataClassDetails?.professor}
          moment={dataClassDetails?.moment?.sort((a: any, b: any) => new Date(a.dtSchedule) - new Date(b.dtSchedule))}
          loading={isLoadingTrailsNotGenerated}
          lesson={dataClassDetails?.lesson}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionOpenModalSchedule={handleOpenModalSchedule}
          txSchoolGradeName={dataClassDetails?.class[0]?.txName}
          txClassShift={txClassShift}
          txGrade={dataClassDetails?.class[0]?.schoolGrade?.grade?.txGrade}
          coStage={coStage}
          isSameUser={isSameUser}
        />

        <DividerComponent />
        <S.ContainerTrail>
          <S.ContainerAccordion>
            <TruncatedTextDescription
              title={'trilha'}
              txDescription={dataClassDetails?.txDescription || ''}
              isMobile={false}
              textLength={470}
            />
          </S.ContainerAccordion>
          <S.ContainerTitle>
            <S.Title>Aulas da trilha</S.Title>
          </S.ContainerTitle>
          <S.ContainerCardsList>
            {mergedArray?.map((item: any, index: number) => (
              <CardTrailComponent
                key={index}
                idClass={Number(classId)}
                idMoment={item.lessonTrackGroup?.idMoment}
                professor={item.LessonTrackGroup?.professor}
                variant={'generated'}
                index={item.lessonTrackGroupNuOrder}
                description={item.txDescription}
                dtSchedule={item.lessonTrackGroup?.dtSchedule}
                coMomentStatus={item.lessonTrackGroup?.coMomentStatus}
                title={item.txTitle}
                isTrail={true}
              />
            ))}
          </S.ContainerCardsList>
        </S.ContainerTrail>

        <ModalBNCCComponent
          open={openModalBNCC}
          txGuidanceBNCC={dataClassDetails?.txGuidanceBNCC}
          actionCloseModal={handleCloseModalBNCC}
        />
        <DividerComponent />
      </ContainerComponent>
    </>
  )
}
