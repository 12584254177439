import { createContext, useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { INavigationContextProps, INavigationProviderProps } from './Navigation.interfaces'

const NavigationContext = createContext<INavigationContextProps>({
  handleNavigation: () => Object,
})

const NavigationProvider = ({ children }: INavigationProviderProps) => {
  const navigate = useNavigate()

  const handleNavigation = useCallback(
    (coStage: string, idMoment: number, idClass: number, from?: string) => {
      if (['F2', 'EM', 'EMA1', 'M3', 'M4'].includes(coStage)) {
        navigate(`/class/${idClass}/project/${idMoment}`, { state: { from: from } })
      } else {
        navigate(`/class/${idClass}/lesson/${idMoment}`, { state: { from: from } })
      }
    },
    [navigate],
  )

  const navigationProviderValues = useMemo(() => ({ handleNavigation }), [handleNavigation])

  return <NavigationContext.Provider value={navigationProviderValues}>{children}</NavigationContext.Provider>
}
const useNavigation = () => useContext(NavigationContext)
export { NavigationProvider, useNavigation }
