import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { IApiTrailScheduleError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

import { IServiceUpdateTrail } from '~/services/TrailEdit/types'

export const UpdateTrail = async (prop: IServiceUpdateTrail) => {
  const { data } = await HTTPClient.put(`/moment/class/${prop.classId}/track-schedule/${prop.lessonId}`, prop.body)
  return data
}

export const useServiceUpdateTrail = (options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>) =>
  useMutation<any, any, IServiceUpdateTrail>(['UpdateTrailLesson'], UpdateTrail, options)

export const UpdateTrailProject = async (prop: IServiceUpdateTrail) => {
  const { data } = await HTTPClient.put(`/moment/class/${prop.classId}/track-schedule/${prop.lessonId}`, prop.body)

  return data
}

export const useServiceUpdateTrailProject = (options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>) =>
  useMutation<any, IApiTrailScheduleError, any>(['DesengageTrail'], UpdateTrailProject, options)
