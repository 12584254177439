import { createContext, useCallback, useContext, useMemo } from 'react'

import { dbWeb } from '~/infra'
import { useLiveQuery } from 'dexie-react-hooks/src'

import { IOfflineContextProps, IOfflineProviderProps } from '~/_context/Offline/Offline.interfaces'

import { OfflineResponse } from '~/services/Offline/interfaces'

const OfflineContext = createContext<IOfflineContextProps>({
  activeOfflineMode: () => Object,
  getOfflineInformation: undefined,
  disableOfflineMode: () => Object,
})

const OfflineProvider = ({ children }: IOfflineProviderProps) => {
  const activeOfflineMode = useCallback(async (lessonInformation: OfflineResponse) => {
    localStorage.setItem('@LEKTO:offline', 'true')
    try {
      await dbWeb.lessons.clear()
      const lesson = await dbWeb.lessons.where('id').equals(1).first()
      if (lesson) {
        lesson.moments = lessonInformation.moments
        lesson.lessonMomentGroups = lessonInformation.lessonMomentGroups
        lesson.lessonMomentsBncc = lessonInformation.lessonMomentsBncc
        lesson.lessonMomentsByTrack = lessonInformation.lessonMomentsByTrack
        lesson.lessonMomentsDetails = lessonInformation.lessonMomentsDetails
        lesson.lessonSteps = lessonInformation.lessonSteps
        lesson.lessonStepTwoGroups = lessonInformation.lessonStepTwoGroups
        lesson.lessonMomentActivities = lessonInformation.lessonMomentActivities
        lesson.projectMomentGroups = lessonInformation.projectMomentGroups
        lesson.projectMomentsDetails = lessonInformation.projectMomentsDetails
        lesson.projectTracks = lessonInformation.projectTracks
        lesson.projectTracksDetails = lessonInformation.projectTracksDetails
        await dbWeb.lessons.put(lesson)
      } else {
        await dbWeb.lessons.add({
          id: 1,
          moments: lessonInformation.moments,
          lessonMomentGroups: lessonInformation.lessonMomentGroups,
          lessonMomentsBncc: lessonInformation.lessonMomentsBncc,
          lessonMomentsByTrack: lessonInformation.lessonMomentsByTrack,
          lessonMomentsDetails: lessonInformation.lessonMomentsDetails,
          lessonSteps: lessonInformation.lessonSteps,
          lessonStepTwoGroups: lessonInformation.lessonStepTwoGroups,
          lessonMomentActivities: lessonInformation.lessonMomentActivities,
          projectMomentGroups: lessonInformation.projectMomentGroups,
          projectMomentsDetails: lessonInformation.projectMomentsDetails,
          projectTracks: lessonInformation.projectTracks,
          projectTracksDetails: lessonInformation.projectTracksDetails,
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving to IndexedDB:', error)
    }
  }, [])

  const disableOfflineMode = useCallback(() => {
    localStorage.setItem('@LEKTO:offline', 'false')
  }, [])

  const getOfflineInformation = useLiveQuery(async () => {
    return dbWeb.lessons.toArray()
  })

  const offlineProviderValues = useMemo(
    () => ({
      activeOfflineMode,
      getOfflineInformation,
      disableOfflineMode,
    }),
    [disableOfflineMode, getOfflineInformation, activeOfflineMode],
  )

  return <OfflineContext.Provider value={offlineProviderValues}>{children}</OfflineContext.Provider>
}

const useOfflineMode = () => useContext(OfflineContext)

export { OfflineProvider, useOfflineMode }
