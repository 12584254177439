import { IColorsStepsAssessmentProps } from '~/interfaces/ColorsStepsAssessment'

export const colorsStepsAssessment = (step: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed') => {
  const colorStep: IColorsStepsAssessmentProps = {
    toDevelop: '#D44333',
    inEarlyDevelopment: '#FF8A00',
    underDevelopment: '#29CC5F',
    developed: '#0095FF',
  }

  return colorStep[step as keyof IColorsStepsAssessmentProps]
}
