import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Divider,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import { DesktopDatePicker, MobileTimePicker } from '@mui/x-date-pickers'

export const Container = styledMUI(Box)(() => ({
  display: 'block',
  width: '100%',
  padding: '24px',
  '@media (max-width: 600px)': {
    padding: '32px',
  },
}))

export const Content = styledMUI(Box)(() => ({
  height: '100%',
  width: '630px',
  maxHeight: '100%',
  alignItems: 'left',
  justifyContent: 'left',
  '@media (max-width: 600px)': {
    width: '100%',
    justifyContent: 'left',
    padding: '32px',
  },
}))

export const ContentInfo = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '24px',
  height: '661px',
}))
export const ContentIcon = styledMUI(Box)(() => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff7f5',
  borderRadius: '50px',
}))

export const DatePickerStyle = styledMUI(DesktopDatePicker)(() => ({
  width: '108px',
  alignSelf: 'center',
  borderRadius: '5px',
  '@media (max-width: 600px)': {
    width: '260px',
  },
}))

export const TimePickerStyle = styledMUI(MobileTimePicker)(() => ({
  width: '115px',
  height: '40px',
  borderRadius: '5px',
  '@media (max-width: 600px)': {
    width: '156px',
  },
}))

export const TextFieldStyle = styledMUI(TextField)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff7f5',
  borderRadius: '50px',
}))

export const HeaderCardProject = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'grid',
    gap: '8px',
  },
}))

export const TitleCardProject = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  marginBottom: '16px',
  width: '100%',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'flex',
    gap: '8px',
  },
}))

export const ContainerTrackClass = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #7c8189',
  width: 'fit-content',
  padding: '2px 4px',
  borderRadius: '4px',
  marginBottom: '24px',
}))

export const ClassSpanInfo = styledMUI(Box)(() => ({
  fontSize: '14px',
  padding: '0.5px 6px',
  color: '#7c8189',
  borderRight: '1px solid #e2e5ec',
  '&:last-child': {
    borderRight: 0,
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  textAlign: 'left',
  alignContent: 'center',
  '@media (max-width: 600px)': {
    display: 'grid',
    width: '50%',
  },
}))

export const WrapperDivider = styledMUI(Box)(() => ({
  display: 'flex',
  height: '134px',
  gap: '8px',
  textAlign: 'left',
  '@media (max-width: 600px)': {
    display: 'flex',
    width: '100%',
    marginLeft: '0px',
    justifyItems: 'center',
  },
  '@media (max-width: 1400px) and (max-height: 768px)': {
    paddingBottom: '50px',
  },
}))

export const BoxOverflow = styledMUI(Box)(() => ({
  overflowX: 'hidden',
  padding: 4,
  overflowY: 'scroll',
  maxHeight: '100%',
  '@media (max-width: 1400px) and (max-height: 768px)': {
    paddingBottom: '50px',
    height: '73%',
  },
  '@media (max-width: 1600px) and (max-height: 768px)': {
    paddingBottom: '30px',
    height: '73%',
  },
  '@media (max-width: 1700px) ': {
    paddingBottom: '25px',
    height: '73%',
  },
  '@media (max-width: 1800px) ': {
    paddingBottom: '20px',
    height: '73%',
  },
  '@media (max-width: 1900px)': {
    paddingBottom: '15px',
    height: '73%',
  },
}))

export const BoxOverflowMobile = styledMUI(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
  maxHeight: '600px',
}))

export const WrapperTeacher = styledMUI(Box)(() => ({
  gap: '10px',
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
}))

export const WrapperTeacherSelect = styledMUI(Box)(() => ({
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const AvatarDefault = styledMUI(Avatar)(() => ({
  width: '24px',
  height: '24px',
  backgroundColor: '#CCCCCC',
}))

export const WrapperNumber = styledMUI(Box)(() => ({
  marginLeft: '0px',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    display: 'flex',
    width: '100%',
    marginLeft: 0,
    alignItems: 'center',
    gap: '8px',
  },
}))

export const WrapperMobile = styledMUI(Box)(() => ({
  display: 'grid',
  width: '100%',
}))

export const WrapperBtn = styledMUI(Box)(() => ({}))

export const WrapperProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  marginTop: '10px',
  paddingBottom: '5px',
  justifyContent: 'space-between',
}))

export const WrapperText = styledMUI(Box)(() => ({
  marginBottom: '10px',
  display: 'flex',
  marginTop: '10px',
  width: '550px',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'left',
}))

export const SelectTeacher = styledMUI(Box)(() => ({
  width: '250px',
  height: '40px',
  display: 'flex',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const MenuTeacher = styledMUI(MenuItem)(() => ({
  width: '250px',
  height: '40px',
  display: 'flex',
  borderRadius: '4px',
  border: 'solid 1px #666666',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    height: '20px',
  },
}))

export const MenuOtherTeacher = styledMUI(MenuItem)(() => ({
  width: '250px',
  height: '40px',
  display: 'flex',
  borderRadius: '4px',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const SwitchTeacher = styledMUI(Switch)(() => ({}))

export const TextTitleTrilhas = styledMUI(Typography)(() => ({
  width: '90%',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '60px',
  textAlign: 'left',
  color: '#0095FF',
  '@media (max-width: 600px)': {
    heigth: '10px',
    width: '100%',
  },
}))

export const NameProfessor = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '100%',
  marginTop: '3px',
  textAlign: 'left',
}))

export const NameProfessorBold = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  marginRight: '8px',
  marginLeft: '2px',
  marginTop: '3px',
  textAlign: 'left',
}))

export const CardTitle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '16px',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const ChipProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  fontSize: '14px',
  alignItems: 'center',
  fontWeight: 700,
  padding: '3px',
  textAlign: 'center',
  color: '#6e6e6e',
  backgroundColor: '#E0E0E0',
  borderRadius: '5px',
}))

export const BoxHeaderMobile = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}))

export const IconCircle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: '#E8F6FF',
  height: '40px',
  width: '40px',
  borderRadius: '25px',
}))

export const IconExit = styledMUI(Box)(() => ({
  marginTop: '20px',
  display: 'flex',
  height: '40px',
  width: '40px',
}))
export const TextPlaceholder = styledMUI(Typography)(() => ({
  marginLeft: '2px',
  marginRight: '25px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  textAlign: 'left',
  color: '#666',
}))

export const TextClassBolder = styledMUI(Typography)(() => ({
  marginLeft: '4px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '38px',
  textAlign: 'left',
  color: '#0095FF',
}))

export const TextClass = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: ' 120%',
  textAlign: 'left',
  color: 'black',
  '@media (max-width: 600px)': {
    lineHeight: '18px',
    color: 'black',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  width: '85%',
  fontStyle: 'bolder',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'left',
  color: '#000',
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '150%',
  textAlign: 'left',
  color: '#666',
}))

export const TextParagraph = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  textAlign: 'left',
  color: '#000',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  display: 'flex',
  padding: 8,
  marginTop: '8px',
  justifyContent: 'space-between',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'grid',
    gap: '12px',
    paddingBottom: '60px',
  },
}))

export const ContentButtonsMobile = styledMUI(Box)(() => ({
  display: 'flex',
  marginTop: '24px',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'grid',
    gap: '12px',
    paddingBottom: '80px',
  },
}))

export const orderCircle = styledMUI(Box)(() => ({
  display: 'flex',
  borderRadius: '50%',
  width: '15px',
  backgroundColor: 'red',
}))
export const ContentButtonsEnd = styledMUI(Box)(() => ({
  display: 'flex',
  marginTop: '25px',
  justifyContent: 'end',
  gap: '8px',
}))

export const Footer = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  gap: '8px',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const AlertDialog = styledMUI(Alert)(() => ({
  backgroundColor: 'red',
  color: 'white',
}))

export const WarningDialog = styledMUI(Alert)(() => ({
  backgroundColor: '#fcba03',
  color: 'white',
}))

export const SnackbarAlert = styledMUI(Snackbar)(() => ({
  justifySelf: 'center',
  display: 'flex',
}))

export const NumberBox = styledMUI(Box)(() => ({
  justifySelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: '#BDC3CF',
  color: 'white',
  '@media (max-width: 600px)': {
    width: '24px',
    height: '24px',
    padding: 10,
  },
}))

export const StartButton = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  marginRight: '120px',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    gap: '8px',
    justifyContent: 'start',
  },
}))

export const Loading = styledMUI(Backdrop)(() => ({}))

export const DividerLine = styledMUI(Divider)(() => ({
  gap: '12px',
  '@media (max-width: 600px)': {
    width: '100%',
    marginTop: '20px',
  },
}))

export const DividerHeader = styledMUI(Divider)(() => ({
  width: '100%',
  marginBottom: '24px',
}))

export const FinalButton = styledMUI(Box)(() => ({
  display: 'flex',
  alignContent: 'flex-end',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    gap: '8px',
    display: 'grid',
    alignContent: 'center',
  },
}))
