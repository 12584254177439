import { CustomDndProvider } from '~/components'

import { TrailDetailsClassPageProvider } from './context'
import { TrailDetailsClassView } from './view'

export const TrailDetailsClassPage = () => {
  return (
    <TrailDetailsClassPageProvider>
      <CustomDndProvider>
        <TrailDetailsClassView />
      </CustomDndProvider>
    </TrailDetailsClassPageProvider>
  )
}
