import { CustomDndProvider } from '~/components'

import { TrailsGeneratedEMPageProvider } from '~/pages/private/TrailsGenerated/context'
import { TrailsGeneratedEMView } from '~/pages/private/TrailsGenerated/view'

export const TrailsGeneratedPage = () => {
  return (
    <TrailsGeneratedEMPageProvider>
      <CustomDndProvider>
        <TrailsGeneratedEMView />
      </CustomDndProvider>
    </TrailsGeneratedEMPageProvider>
  )
}
