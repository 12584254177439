import { useMutation } from '@tanstack/react-query'
import { IApiError } from '~/types/error'

import { HTTPClient } from '~/server/HTTPClient'

export const manageStudentsAPI = async (prop: any) => {
  const { data } = await HTTPClient.patch(
    `/moment/class/${prop.idClass}/students-attendance/by-moment/${prop.idLessonMoment}`,
    prop.body,
  )
  return data
}

export const useServiceManageStudents = () => useMutation<any, any, IApiError>(['manageStudents'], manageStudentsAPI)

export const manageStudentsProjectAPI = async (prop: any) => {
  const { data } = await HTTPClient.patch(
    `/moment/class/${prop.idClass}/students-attendance/by-moment/${prop.idProjectMoment}`,
    prop.body,
  )

  return data
}

export const useServiceManageStudentsProject = () => useMutation(['manageStudentsProject'], manageStudentsProjectAPI)
