import { useDragLayer } from 'react-dnd'

import { AvatarRandomComponent } from '~/components/Basic/AvatarRandom/AvatarRandom'

import { CustomDiv, Item, Layer } from './CustomDragLayer.styles'

interface Student {
  idProjectMomentGroup: number
  idProjectMomentStage: number
  idProjectStage: number
  idProject: number
  idUserStudent: number
  idStudent: number
  nuSubGroup: number
  inAttendance: boolean
  projectGroupAssessment: ProjectGroupAssessment[]
  studentClass: StudentClass
  isSelected: boolean
}

interface ProjectGroupAssessment {
  idStudent: number
  idUserStudent: number
  idProjectGroupAssessment: number
  idEvidence: number
  coAssessment: string
  txAssessment: string
  nuScore: number
  coEvidenceType: string
}

interface StudentClass {
  idStudent: number
  inStatus: boolean
  student: StudentDetails
}

interface StudentDetails {
  idStudent: number
  idUserStudent: number
  idUser: number
  txName: string
  inDeleted: boolean
}

interface CustomDragLayerProps {
  students: Student[]
}
const CustomDragLayer: React.FC<CustomDragLayerProps> = ({ students }) => {
  const { isDragging, currentOffset } = useDragLayer((monitor) => ({
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  const { x, y } = currentOffset

  const centerX = -50
  const centerY = -50

  const renderAvatars = () => {
    if (students.length > 1) {
      return (
        <CustomDiv>
          <AvatarRandomComponent images={students} size="medium" max={6} />
        </CustomDiv>
      )
    }
  }

  if (!isDragging) {
    return null
  }

  return (
    <Layer>
      <Item x={x} y={y} centerX={centerX} centerY={centerY}>
        {renderAvatars()}
      </Item>
    </Layer>
  )
}

export default CustomDragLayer
