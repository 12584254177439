import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { UilArrowRight, UilBookReader } from '@iconscout/react-unicons'
import { Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarGroupComponent, ButtonComponent, ChipComponent, SkeletonComponent } from '~/components'
import { TrailStatusBar } from '~/components/Bars/TrailStatusBar/TrailStatusBar'
import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { IComponentProps } from '~/interfaces/Component'

import theme from '~/styles/theme'

import { ICardTrailComponentProps, Moment } from './CardTrail.interfaces'
import * as S from './CardTrail.styles'

dayjs.locale('pt-br')

type Content = {
  txComponent: string
  coComponentType?: string
}

export const CardTrailComponent = ({
  actionNavigation,
  loading,
  title,
  subjects,
  moments,
  teacherList,
  teacherName,
  coStage,
}: ICardTrailComponentProps) => {
  const { classId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const lessonTrackGroupId = moments?.[0]?.lessonTrackGroup?.idLesson ?? ''
  const momentId = moments?.[0]?.idMoment ?? ''
  const teacherListName = teacherList?.map((teacher) => teacher.txName)

  const handleBncc = () => {
    localStorage.setItem('@LEKTO:Navigation', location.pathname)
    if (['EM', 'F2', 'M3', 'M4'].includes(coStage ?? '')) {
      navigate(`/class/${classId}/project-bncc-relations/${momentId}`)
    } else {
      navigate(`/class/${classId}/lesson-bncc-relations/${momentId}/lesson/${lessonTrackGroupId}`)
    }
  }

  const [countSecComponents, setCountSecComponents] = useState(0)

  useEffect(() => {
    const hasPriComponents = subjects?.some((subject) => subject.coComponentType === 'PRI')
    const count = subjects?.filter((subject) => subject.coComponentType === 'SEC')?.length || 0
    if (hasPriComponents && count > 1) {
      setCountSecComponents(count)
    } else {
      setCountSecComponents(count)
    }
  }, [subjects])

  const [countTeacher, setCountTeacher] = useState(0)

  useEffect(() => {
    const teacherPresent = teacherList?.some((teacher) => teacher.txName === teacherName)
    const otherTeachersCount = teacherList?.filter((teacher) => teacher.txName !== teacherName)?.length || 0

    setCountTeacher(otherTeachersCount)
    if (teacherPresent && otherTeachersCount > 0) {
      setCountTeacher(otherTeachersCount)
    } else {
      setCountTeacher(otherTeachersCount)
    }
  }, [teacherList, teacherName])

  const TrailTooltip = ({
    subjectsComponents,
    children,
  }: {
    subjectsComponents: any
    children: React.ReactElement
  }) => {
    const renderTrail = () => {
      const contentTooltip = subjectsComponents.map(
        (item: IComponentProps): Content => ({
          coComponentType: item.coComponentType,
          txComponent: item.txComponent ?? '',
        }),
      )
      return (
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <S.TitleTooltip>Componentes dessa aula:</S.TitleTooltip>
          <S.ContentTooltip>
            {contentTooltip?.map((item: Content, index: number) => (
              <>
                <S.TextTooltip
                  key={index}
                  className={index === contentTooltip?.length - 1 ? 'last-item' : ' e '}
                  popoverContentType={item.coComponentType}
                >
                  {index === 0 ? '' : index === contentTooltip?.length - 1 ? ' e ' : ', '}
                  {item.txComponent}
                </S.TextTooltip>
              </>
            ))}
          </S.ContentTooltip>
          {isMobile ? (
            <DSTooltip text="Acessar Integração com o Currículo">
              <S.ButtonIcon onClick={() => handleBncc()}>
                <UilBookReader size={16} color="#0095ff" />
              </S.ButtonIcon>
            </DSTooltip>
          ) : (
            <ButtonComponent
              text="Acessar Integração com o Currículo"
              variant="outline"
              size="small"
              iconEnd={<UilArrowRight size="18" />}
              fontWeight="normal"
              onClick={(e) => {
                e.stopPropagation()
                handleBncc()
              }}
            />
          )}
        </Box>
      )
    }
    return (
      <S.TooltipContainerHover>
        <S.TooltipContainer>
          <DSTooltip
            title={renderTrail()}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '14px',
                  padding: '16px',
                  fontWeight: '400',
                  color: theme.colors.lowPure,
                  backgroundColor: theme.colors.highPure,
                  width: '334px',
                  top: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
                  zIndex: '9999',
                },
              },
            }}
            placement={'bottom-start'}
          >
            {children}
          </DSTooltip>
        </S.TooltipContainer>
      </S.TooltipContainerHover>
    )
  }

  const [isTooltipOpen, setTooltipOpen] = useState(false)

  const handleTouch = () => {
    setTooltipOpen(!isTooltipOpen)
  }

  const getClosestFutureMoment = (moments: Moment[] | undefined): number => {
    const now = dayjs()

    const futureMoment = moments!.reduce((acc, moment) => {
      const momentDate = moment.dtSchedule ? dayjs(moment.dtSchedule) : Infinity
      const accDate = acc ? dayjs(acc.dtSchedule) : Infinity

      if ((now.isBefore(momentDate, 'day') || now.isSame(momentDate, 'day')) && momentDate < accDate) {
        return moment
      }
      return acc
    }, null as Moment | null)

    return futureMoment ? futureMoment.nuTrackOrder : 0
  }

  return (
    <S.CardContainer onClick={actionNavigation}>
      <S.WrapperBetween>
        <S.WrapperTitle>
          {loading ? (
            <SkeletonComponent width={400} height={36} />
          ) : (
            <>
              <S.TooltipContainer
                onTouchStart={handleTouch}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
              >
                {isMobile ? (
                  <Tooltip title={title} placement="top" open={isTooltipOpen}>
                    <S.Title>{title}</S.Title>
                  </Tooltip>
                ) : (
                  <Tooltip title={title} placement="top" open={isTooltipOpen}>
                    <S.Title>{title}</S.Title>
                  </Tooltip>
                )}
              </S.TooltipContainer>
            </>
          )}
        </S.WrapperTitle>

        {loading ? (
          <SkeletonComponent variant="rounded" height={24} width={125} />
        ) : (
          <S.ButtonIcon onClick={actionNavigation} isMobile={Boolean(isMobile)}>
            {!isMobile && <S.TextButton>Acessar trilha</S.TextButton>}
            <UilArrowRight size={16} />
          </S.ButtonIcon>
        )}
      </S.WrapperBetween>

      <S.Content>
        <S.ContentInfos>
          <S.WrapperChip className="styled-scroll">
            {loading ? (
              <SkeletonComponent variant="rounded" height={26} width={200} />
            ) : (
              <>
                {subjects
                  ?.filter((subject: IComponentProps) => subject.coComponentType === 'PRI')
                  ?.map((subject: IComponentProps, index: number) => (
                    <TrailTooltip key={index} subjectsComponents={subjects}>
                      <S.ContainerDivider>
                        <ChipComponent
                          type="normal"
                          label={subject.txComponent}
                          count={countSecComponents}
                          isSametag={true}
                          isThin={true}
                        />
                      </S.ContainerDivider>
                    </TrailTooltip>
                  ))}
              </>
            )}
            <S.Wrapper>
              {loading ? (
                <>
                  <SkeletonComponent variant="rounded" height={26} width={200} />
                </>
              ) : (
                <>
                  {teacherName && (
                    <Tooltip
                      title={
                        <ul>
                          {teacherListName?.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      }
                      placement="top"
                    >
                      <S.ConteinerProfessor>
                        <AvatarGroupComponent images={teacherList} size="xsmall" max={4} />
                        <S.NameProfessor>
                          {countTeacher ? `${teacherName} +${countTeacher}` : teacherName}
                        </S.NameProfessor>
                      </S.ConteinerProfessor>
                    </Tooltip>
                  )}
                </>
              )}
            </S.Wrapper>
          </S.WrapperChip>
        </S.ContentInfos>
      </S.Content>

      {loading ? (
        <SkeletonComponent variant="rounded" height={32} />
      ) : (
        moments && <TrailStatusBar moments={moments} momentActive={moments ? getClosestFutureMoment(moments) : 0} />
      )}
    </S.CardContainer>
  )
}
