import { gql } from 'graphql-request'

export const GET_COMPETENCES = gql`
  query getCompetences {
    competences(take: 200) {
      items {
        idCompetence
        txName
        txImagePath
        txPrimaryColor
      }
    }
  }
`

export const GET_STUDENT = gql`
  query getStudent($idUser: Int!) {
    userById(idUser: $idUser) {
      items {
        idNetwork
        idUser

        txName
        txImagePath
        txEmail
        txPhone
        txCpf
        dtBirthdate

        student(where: { studentClass: { some: { inStatus: { eq: true } } } }) {
          idStudent
          idSchool
          studentClass {
            inStatus
            class {
              idClass
              txName
              schoolGrade {
                grade {
                  txGrade
                  coGrade
                }
              }
            }
          }
          schoolGrade {
            school {
              txName
              idSchool
            }
          }
        }
      }
    }
  }
`
