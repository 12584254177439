import { UilBolt, UilTimes } from '@iconscout/react-unicons'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { IconComponent } from '~/components'

import * as S from './MultiSelect.styles'

interface IOptions {
  evidenceId: any
  txName: string
}

interface IMultiSelectProps {
  placeHolder: string
  values: any[]
  defaultValue?: any
  handleChange: (value: any) => void
  handleDelete: (value: any) => void
  options: string[] | IOptions[]
  disabled?: boolean
}

export const MultiSelectComponent = ({
  values,
  handleChange,
  handleDelete,
  placeHolder,
  defaultValue,
  options,
  disabled = false,
}: IMultiSelectProps) => {
  if (options?.length && typeof options[0] === 'object') {
    return (
      <Select
        multiple
        defaultValue={defaultValue}
        autoWidth={true}
        value={values}
        onChange={handleChange}
        sx={{ width: '100%', minHeight: '48px' }}
        displayEmpty={true}
        disabled={disabled}
        renderValue={(selected) => {
          if (selected.length > 0)
            return (
              <S.Chips>
                {(selected as IOptions[]).map((value) => {
                  const object = options?.filter((i) => Number(i?.evidenceId) === Number(value))
                  return (
                    <S.Chip key={value}>
                      {object[0]?.txImagePath ? (
                        <IconComponent color="#FFF" fontWeight="normal" code={object[0]?.txImagePath} size="xxsmall" />
                      ) : (
                        <UilBolt size="14" />
                      )}

                      <S.ChipSpan>{object[0]?.txName}</S.ChipSpan>

                      <UilTimes
                        size="20"
                        onMouseDown={(e: any) => {
                          e.stopPropagation()
                          handleDelete(value)
                        }}
                      />
                    </S.Chip>
                  )
                })}
              </S.Chips>
            )
          else return <S.PlaceHolder>{placeHolder}</S.PlaceHolder>
        }}
      >
        {options?.map((option: IOptions) => {
          return (
            <MenuItem key={option?.evidenceId} value={option?.evidenceId}>
              <Checkbox checked={values?.includes(option?.evidenceId)} />

              <ListItemText primary={option?.txName} />
            </MenuItem>
          )
        })}
      </Select>
    )
  } else {
    return (
      <Select
        multiple
        autoWidth={true}
        value={values}
        onChange={handleChange}
        sx={{ width: '100%', height: '48px' }}
        displayEmpty={true}
        renderValue={(selected) => {
          if (selected.length > 0)
            return (
              <S.Chips>
                {(selected as string[]).map((value) => (
                  <S.Chip key={value}>
                    <UilBolt size="14" />

                    <S.ChipSpan>{value}</S.ChipSpan>

                    <UilTimes
                      size="20"
                      onMouseDown={(e: any) => {
                        e.stopPropagation()
                        handleDelete(value)
                      }}
                    />
                  </S.Chip>
                ))}
              </S.Chips>
            )
          else return <S.PlaceHolder>{placeHolder}</S.PlaceHolder>
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={values?.includes(option)} />

            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    )
  }
}
