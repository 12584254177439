import { CustomDndProvider } from '~/components'

import { FirstStepPageProvider } from './context'
import { FirstStepView } from './view'

export const FirstStepPageOffline = () => {
  return (
    <FirstStepPageProvider>
      <CustomDndProvider>
        <FirstStepView />
      </CustomDndProvider>
    </FirstStepPageProvider>
  )
}
