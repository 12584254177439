export const labelFormat = (status: string): string => {
  switch (status) {
    case 'AGEN':
      return 'Aula Agendada'
    case 'AUPL':
      return 'Confirmar realização da aula'
    case 'AGUA':
      return 'Iniciar Aula'
    case 'FINA':
      return 'Finalizado'
    case 'AVPE':
      return 'Confirmar Avaliação'
    case 'PEND':
      return 'Confirmar planejamento da aula'
    case 'AUPE':
      return 'Atualizar Aula'
    case 'INIC':
      return 'Encerrar Aula'
    default:
      return 'INIC'
  }
}
