import { Box, InputAdornment, Menu, TextField, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  padding: '94px 24px 24px',
  marginTop: '-70px',
  zIndex: 10,
  gap: '16px',
  borderBottom: '1px solid #E2E5EC',
  backgroundColor: '#fff',
}))

export const BoxContainer = styledMUI(Box)<{ width?: string }>(({ width }) => ({
  width: width ? width : 'auto',
}))

export const ButtonSelectClass = styledMUI(Menu)(() => ({
  margin: '14px 0 0 0',
  '.MuiPaper-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
  '.styled-scroll-s': {
    '::-webkit-scrollbar': {
      width: '5px',
    },

    '::-webkit-scrollbar-track': {
      borderRadius: '8px',
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: '#CCCCCC',
      border: '1px solid #CCCCCC',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
      width: '4px',
    },
    Maxheight: '350px',
  },
}))

export const BoxOverflow = styledMUI(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
  maxHeight: '500px',
  padding: '8px 8px',
  '::-webkit-scrollbar': {
    width: '5px',
  },

  '::-webkit-scrollbar-track': {
    borderRadius: '8px',
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#CCCCCC',
    border: '1px solid #CCCCCC',
  },

  '::-webkit-scrollbar-thumb:hover': {
    background: '#aaa',
    width: '4px',
  },
}))

export const WrapperMenuItemFilter = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  span: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '100%',
    color: '#666666',
  },
}))

export const LabelMenuItemFilter = styledMUI(Box)(() => ({
  margin: '0 0 8px 12px',
  width: '240px',
  display: 'grid',
  alignItems: 'start',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '100%',
}))

export const LabelCategoryGrade = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #a3a3a3;
`

export const LabelMenuItemOrder = styledMUI(Typography)(() => ({
  margin: '0 0 8px 12px',
  width: '124px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '100%',
}))

export const ButtonOrder = styledMUI(Menu)(() => ({
  margin: '10px 0 0 0',
}))

export const HeaderBtns = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
}))

export const Wrapper = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const InputText = styledMUI(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    height: '40px',
    width: '200px',
    color: '#666',

    '& fieldset': {
      borderColor: '#666',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#666',
    },
  },
}))

export const InputAdornmentIcon = styledMUI(InputAdornment)(() => ({}))
