import * as S from './TagClass.styles'
interface TagClassProps {
  texts: string[]
  variant?: 'default' | 'underline' | 'inactive'
}

const TagClass: React.FC<TagClassProps> = ({ texts, variant = 'default' }) => {
  return (
    <S.ContainerTrackClass variant={variant}>
      {texts?.map((text, index) => (
        <S.ClassSpanInfo key={index} isFirst={index === 0} variant={variant}>
          {text}
        </S.ClassSpanInfo>
      ))}
    </S.ContainerTrackClass>
  )
}

export default TagClass
