import styled from 'styled-components'

export const DocumentBtnFloater = styled.div`
  border-radius: 50px;
  padding: 0px;
  position: fixed;
  top: 80%;
  right: 35%;
  left: 81%;
  border: transparent;
  @media (max-width: 600px) {
    right: 11px;
    left: auto;
  }
`

export const DocumentBtnSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

export const DocumentBtnFixed = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  max-width: 100%;
`

export const ModalWrapper = styled.div`
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TextTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.actionPure};
`

export const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.lowMedium};
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 8px;
`

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

export const SelectInput = styled.select`
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.lowMedium};
  padding-left: 8px;
`

export const TextInput = styled.textarea`
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  padding-left: 8px;
  height: 140px;
  resize: none;

  &:focus {
    outline: none;
  }
`

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`

export const FileInputDiv = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  gap: 10px;
`

export const ModalBtns = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`

export const ContentLoad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
`

export const LinearProgressContainer = styled.div`
  width: 60%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SpanLoad = styled.span`
  display: flex;
  margin-left: 40%;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.lowMedium};
`

export const Form = styled.form`
  -webkit-user-select: none;
  user-select: none;
`

export const FilesDisplay = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  color: black;
  margin-bottom: 24px;
  gap: 8px;
`

export const File = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.actionLight};
  border-radius: 16px;
  padding: 8px;
  gap: 8px;
`

export const FileName = styled.span`
  color: ${({ theme }) => theme.colors.actionPure};
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`

export const RemoveFileBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.negativePure};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.negativePure};
`

export const AddFileSpan = styled.span`
  color: ${({ theme }) => theme.colors.lowMedium};
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`

export const FileSizeSpan = styled.span`
  color: ${({ theme }) => theme.colors.lowMedium};
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`

export const FileSizeExceedSpan = styled.span`
  color: ${({ theme }) => theme.colors.quaternaryDark};
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
`

export const FileUploadInput = styled.input.attrs({ type: 'file' })`
  width: 0;
  display: none;
`

export const FileUpload = styled.label<{ isClassInStatus?: boolean }>`
  display: flex;
  background: transparent;
  align-items: center;
  gap: 4px;
  color: ${({ theme, isClassInStatus }) => (isClassInStatus ? theme.colors.actionPure : theme.colors.highDark)};
  border-radius: 15px;
  border: 1px solid
    ${({ theme, isClassInStatus }) => (isClassInStatus ? theme.colors.actionPure : theme.colors.highDark)};
  padding: 4px 8px;
  cursor: ${({ isClassInStatus }) => (isClassInStatus ? 'pointer' : 'default')};
`
