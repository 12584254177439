import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const TooltipContainer = styledMUI(Box)({
  position: 'relative',
})

export const TooltipContainerHover = styledMUI(Box)({
  '&:hover $customPaper': {
    display: 'block',
  },
})

export const TooltipContent = styledMUI(Box)({
  display: 'flex',
  fontWeight: 700,
  fontSize: '14px',
  alignItems: 'center',
  gap: 5,
})
