import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 32px;
  text-align: center;
  gap: 24px;
  justify-items: center;
  align-items: center;

  h2 {
    font-size: 20px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }
`

export const Logo = styled.img``
