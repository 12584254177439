import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AccordionOrientationsComponent,
  AppBarComponent,
  ContainerComponent,
  QuickNavigationComponent,
} from '~/components'

import { HeaderStepComponent } from '~/containers/private/Fund1/SecondStep'

import { useSecondStepPageContext } from './context'
import * as S from './styles'

export const SecondStepView = () => {
  const { idLessonActivity } = useParams()

  const { filteredActivityGroup, lessonSteps, secondStepGroup } = useSecondStepPageContext()

  const [activedTab, setActivedTab] = useState<number>(0)

  const step = lessonSteps

  const status = lessonSteps?.momentStatus.coMomentStatus || ''

  const idLessonMomentActivity = lessonSteps?.lessonActivity?.[0]?.lessonMomentActivity?.find(
    (item) => item.idLessonActivity === Number(idLessonActivity),
  )?.idLessonMomentActivity

  const avatars = secondStepGroup?.lessonActivity?.find((item) => item.idLessonActivity === Number(idLessonActivity))

  const lessonActivity = filteredActivityGroup

  const txTitle = lessonSteps?.txTitle

  const handleEvidence = (coEvidenceType: 'FIX' | 'VAR') => {
    return step?.lessonActivity
      .find((item) => item.idLessonActivity === Number(idLessonActivity))
      ?.lessonActivityEvidence?.filter((ev: any) => ev.coEvidenceType === coEvidenceType)?.[0]?.evidenceGrade?.evidence
  }

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            isOffline
            idLessonActivity={Number(idLessonActivity)}
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
          />
        </S.NavContainer>

        <HeaderStepComponent
          activedTab={activedTab}
          txTitle={lessonActivity?.txTitle as string}
          avatars={avatars?.lessonMomentActivity[0].lessonMomentGroup}
          nuOrder={lessonActivity?.nuOrder as number}
          fixedSkill={handleEvidence('FIX')}
          variableSkill={handleEvidence('VAR')}
          txContext={lessonActivity?.category?.txName}
          iconContext={lessonActivity?.category?.txImagePath}
          actionSetActiveTab={(newActivedTab: number) => setActivedTab(newActivedTab)}
          txDescription={lessonActivity?.txChallenge}
          status={['AVPE', 'FINA', 'INIC', 'AUPL', 'AUPE'].includes(status)}
          title={'Realizar'}
        />

        <S.Content>
          {activedTab === 0 && (
            <S.ContentOrientations>
              {filteredActivityGroup?.lessonActivityOrientation?.map((orientation, index: number) => (
                <AccordionOrientationsComponent
                  key={index}
                  type="secondStep"
                  title={orientation.txTitle}
                  txOrientationCode={orientation.txOrientationCode}
                  supportReference={orientation?.lessonActivitySupportReference}
                />
              ))}
            </S.ContentOrientations>
          )}
        </S.Content>
      </ContainerComponent>
    </>
  )
}
