import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;

  h3 {
    padding: 4px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px;
  }
`

export const Tooltip = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.highPure};
  box-shadow: 6px 7px 10px 0 rgba(0, 0, 0, 0.5);
  display: grid;
  gap: 8px;
`

export const TooltipMonth = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.midDark};
`

export const TooltipTotalClasses = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lowPure};
`

export const StatusTooltip = styled.div`
  padding: 2px 4px 2px 4px;
  background-color: ${({ theme }) => theme.colors.midMedium};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.midDark};
  font-size: 12px;
  max-width: fit-content;
  font-weight: 500;
`
