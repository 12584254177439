import { Box } from '@mui/material'
import dayjs from 'dayjs'

import { useNavigation } from '~/_context/Navigation'

import { AppBarComponent, ModalScheduleList, MomentCardComponent, SkeletonComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Schedule'

import { titleByNuOrder } from '~/pages/private/Schedule/view'
import { useScheduleByMonthPageContext } from '~/pages/private/ScheduleByMonth/context'

import * as S from './styles'

export const ScheduleByMonthView = () => {
  const { handleNavigation } = useNavigation()

  const {
    formattedMonthWithUppercase,
    daysArray,
    daysOfWeekEN,
    itemsByDay,
    changeCurrentDay,
    isLoading,
    isRefetching,
    selectedDay,
    changeSelectedDay,
  } = useScheduleByMonthPageContext()

  const weekArray = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

  const tableRows = []
  const itemsPerRow = 7

  for (let i = 0; i < daysArray.length; i += itemsPerRow) {
    const rowItems = daysArray.slice(i, i + itemsPerRow)

    const row = (
      <tr key={`row_${i}`}>
        {rowItems.map((date) => {
          if (date.className === 'empty') {
            return (
              <td key={date.formattedDay} className={'empty-container'}>
                <span className={'day empty'}>{date.formattedDay}</span>
              </td>
            )
          }

          const momentItems = itemsByDay.find((item) => Number(item.day) === Number(date.formattedDay))?.items

          const firstFourMoments = momentItems?.slice(0, 4)
          const remainingMoments = momentItems?.slice(4)
          return (
            <td key={date.formattedDay}>
              <div className={'day'}>
                <span className={`${date.className}`}>{date.formattedDay}</span>
              </div>
              <Box display={'grid'} gap={1}>
                {firstFourMoments?.map((scheduleMoment) => (
                  <MomentCardComponent
                    key={scheduleMoment?.idMoment}
                    type={'month'}
                    totalClass={Number(scheduleMoment?.nuTrackStages)}
                    momentStatusCode={scheduleMoment?.coMomentStatus}
                    momentStatusText={scheduleMoment?.txMomentStatus}
                    momentTitle={
                      scheduleMoment?.class?.schoolGrade?.grade?.coStage === 'F2'
                        ? (titleByNuOrder(scheduleMoment?.nuOrder) as string)
                        : (scheduleMoment?.txTitle as string)
                    }
                    momentClassInfo={`${scheduleMoment?.class?.schoolGrade?.grade?.txGrade} - ${scheduleMoment?.class?.txName}`}
                    action={() =>
                      handleNavigation(
                        scheduleMoment?.class?.schoolGrade?.grade?.coStage,
                        scheduleMoment?.idMoment,
                        scheduleMoment?.class?.idClass,
                        '/schedule/month',
                      )
                    }
                  />
                ))}
                {remainingMoments && remainingMoments.length > 0 && (
                  <ModalScheduleList
                    momentTime={dayjs(remainingMoments[0]?.dtSchedule).format('DD/MM/YYYY')}
                    remainingMoments={remainingMoments}
                  />
                )}
              </Box>
            </td>
          )
        })}
      </tr>
    )

    tableRows.push(row)
  }

  const SkeletonRows = (
    <tr>
      {daysOfWeekEN.map((index) => (
        <td key={index}>
          <ul>
            <SkeletonComponent width={'100%'} height={150} variant={'rounded'} />
          </ul>
        </td>
      ))}
    </tr>
  )

  return (
    <>
      <AppBarComponent />
      <S.Container>
        <HeaderComponent
          actionSetCurrentDay={changeCurrentDay}
          selectedDay={selectedDay}
          initialOptions={'month'}
          actionSetSelectedDay={changeSelectedDay}
        />
        <S.Content>
          <S.ScheduleTitle>{formattedMonthWithUppercase}</S.ScheduleTitle>
          <S.ScheduleTable>
            <thead>
              {weekArray.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </thead>
            <tbody> {isLoading || isRefetching ? SkeletonRows : tableRows}</tbody>
          </S.ScheduleTable>
        </S.Content>
      </S.Container>
    </>
  )
}
