import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { OfflineResponse } from '~/services/Offline/interfaces'

const offlineInfomartion = async (idUserProfessor: number) => {
  const { data } = await HTTPClient.get(`/moment/offline-data?idUserProfessor=${idUserProfessor}`)

  return data
}

export const useGetOfflineInfomartion = (idUserProfessor: number) =>
  useQuery<any, any, OfflineResponse, any>(['GetOfflineInformation'], () => offlineInfomartion(idUserProfessor), {
    enabled: false,
  })
