import { CircularProgress } from '@mui/material'

import * as S from './styles'

export const AuthThirdPartyView = () => {
  return (
    <S.Content>
      <S.ImageMan />

      <S.Progress>
        <CircularProgress color="primary" />
      </S.Progress>

      <S.Text>Por favor, aguarde enquanto processamos a sua requisição!</S.Text>
    </S.Content>
  )
}
