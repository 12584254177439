import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useServiceAssessment, useServiceAssessmentBatch } from '~/services/Assessment'
import { useGetDocumentation } from '~/services/Documentation'
import { useServiceGetLesson } from '~/services/Lesson'
import { useLessonStepInformation } from '~/services/LessonSteps'
import { useGetStudentsGroupLesson, useGetStudentsGroupLessonL3 } from '~/services/Students'

import { dataLessonDocumentation, initialDataState } from '~/pages/private/Fund1/default.state'

import { defaultDataState } from './default.states'
import { IThirdStepPageContextProps, IThirdStepPageProviderProps } from './interfaces'

const ThirdStepPageContext = createContext<IThirdStepPageContextProps>({
  activedTab: 0,
  handleActiveNewTab: () => Object,
  isLoading: false,
  data: initialDataState,
  dataDocumentation: dataLessonDocumentation,
  dataDocumentationFiltered: defaultDataState,
  isLoadingDocumentation: false,
  mutateAssessment: () => Object,
  idLesson: 0,
  idLessonMoment: 0,
  isLoadingAssessment: false,
  dataStudents: undefined,
  dataModal: defaultDataState,
  refetch: () => Object,
  isSameUser: false,
})

const ThirdStepPageProvider = ({ children }: IThirdStepPageProviderProps) => {
  const { idLessonMoment, idLesson, idClass } = useParams()
  const { user } = useAuth()

  const [activedTab, setActivedTab] = useState<number>(0)

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useLessonStepInformation(Number(idLessonMoment), Number(idLesson), 3)
  const { data: dataModal, refetch, remove } = useServiceGetLesson(Number(idLessonMoment))
  const idUserProfessor = dataModal?.idUserProfessor
  const isSameUser = user?.id_user === idUserProfessor
  const isClassInStatus = dataModal?.class?.inStatus

  const { data: dataDocumentation, isLoading: isLoadingDocumentation } = useGetDocumentation(
    Number(idClass),
    Number(idLessonMoment),
  )

  const dataDocumentationFiltered = dataDocumentation?.data
    ?.filter((item) => item?.lessonStep?.nuOrder === 3)
    .sort(function (a, b) {
      return new Date(b.dtInserted).getDate() - new Date(a.dtInserted).getDate()
    })

  const handleActiveNewTab = useCallback((tab: number) => setActivedTab(tab), [])

  const { mutate: mutateAssessment, isLoading: isLoadingAssessment } = useServiceAssessment()
  const { mutate: mutateAssessmentBatch, isLoading: isLoadingAssessmentBatch } = useServiceAssessmentBatch()

  const { data: dataStudents, refetch: refetchStudents } = useGetStudentsGroupLesson(Number(idLessonMoment), Number(3))
  const { data: dataStudentsL2, refetch: refetchL2 } = useGetStudentsGroupLessonL3(Number(idLessonMoment), Number(2))

  useEffect(() => {
    refetchStudents().then()
  }, [refetchStudents, activedTab])

  useEffect(() => {
    refetchData().then()
    // eslint-disable-next-line
  }, [])

  const thirdStepPageProviderValues = useMemo(() => {
    return {
      refetch,
      activedTab,
      isSameUser,
      handleActiveNewTab,
      isLoading,
      data: data || initialDataState,
      dataDocumentation,
      isLoadingDocumentation,
      dataDocumentationFiltered,
      idLessonMoment: Number(idLessonMoment),
      idLesson: Number(idLesson),
      mutateAssessment,
      mutateAssessmentBatch,
      isLoadingAssessment,
      isLoadingAssessmentBatch,
      dataStudents,
      dataModal,
      refetchStudents,
      dataStudentsL2,
      refetchL2,
      idClass,
      isClassInStatus,
    }
  }, [
    refetch,
    isSameUser,
    idLessonMoment,
    isLoadingAssessment,
    dataStudents,
    activedTab,
    handleActiveNewTab,
    isLoading,
    data,
    dataDocumentation,
    isLoadingDocumentation,
    dataDocumentationFiltered,
    idLesson,
    mutateAssessment,
    mutateAssessmentBatch,
    isLoadingAssessmentBatch,
    dataModal,
    refetchStudents,
    dataStudentsL2,
    refetchL2,
    idClass,
    isClassInStatus,
  ])

  return <ThirdStepPageContext.Provider value={thirdStepPageProviderValues}>{children}</ThirdStepPageContext.Provider>
}

const useThirdStepPageContext = () => useContext(ThirdStepPageContext)

export { ThirdStepPageProvider, useThirdStepPageContext }
