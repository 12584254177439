import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

export interface IIdenticalProjectMoments {
  idProjectMoment: number
  dtSchedule: string
  coMomentStatus: string
  coGrade: string
  txGrade: string
  txClassName: string
  txStageName: string
}

const getIdenticalProjectMoments = async (idProject: number) => {
  const { data } = await HTTPClient.get('/project-moment/identical/project/' + idProject)
  return data
}

export const useGetIdenticalProjectMoments = (
  idProject: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<IIdenticalProjectMoments[]>(
    ['getIdenticalProjectMoments'],
    () => getIdenticalProjectMoments(idProject),
    options,
  )
