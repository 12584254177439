import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '176px',
  gap: '16px',
  borderRadius: '8px',
  backgroundColor: theme.colors.midLight,
  padding: '16px',
}))

export const ContainerHeader = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}))

export const Content = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const DescriptionHeader = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '100%',
  textAlign: 'center',
  color: '#7C8189',
}))

export const LessonDescription = styledMUI(Typography)(() => ({
  overflow: 'hidden',
  color: '#000',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',

  // textAlign: 'center',
}))

export const ContainerTrackClass = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #7c8189',
  width: 'fit-content',
  padding: '4px 6px',
  borderRadius: '4px',
}))

export const ClassSpanInfo = styledMUI(Box)(() => ({
  fontSize: '14px',
  padding: '0 6px',
  color: '#7c8189',
  borderRight: '1px solid #e2e5ec',
  '&:last-child': {
    borderRight: 0,
  },
}))

export const ContainerBtns = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '16px',
}))
