import { Box, Skeleton } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

export const ContentDocumentation = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: '24px',
  gap: '24px',
  marginTop: '220px',
  '@media (max-width: 600px)': {
    marginTop: '290px',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const StyledSkeletonCard = styledMUI(Skeleton)`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const ContentSkeletonInfinite = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  align-items: flex-start;
  flex: none;
  flex-grow: 1;
  gap: 8px;
  width: 100%;
  max-width: 48.59%;
  margin-left: 25px;
  min-height: 230px;
  height: max-content;
  order: 0;
  border-radius: 8px;
`

export const ContentSkeleton = styled.div`
  margin-top: 270px;
  margin-bottom: 50px;
  gap: 10px;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 300px 300px;
  justify-items: center;
  align-items: center;
`

export const Container = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px;
  gap: 8px;
  width: 306px;
  min-height: 230px;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.midLight};
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 8px;
`

export const CardHeader = styled.header`
  display: flex;
  width: 100%;
  gap: 1rem;
  height: 35px;
  border-bottom: 1px solid #e2e5ec;
`

export const AbilitydHeader = styled.div`
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 6px;
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  border-bottom: 1px solid #e2e5ec;
`

export const WrapperEditIcons = styled.div`
  width: 30%;
  display: flex;
  align-self: flex-end;
`

export const WrapperChip = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const EditIcons = styled.div`
  color: ${({ theme }) => theme.colors.actionPure};
  cursor: pointer;
  margin-left: 16px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``

export const Chip = styled.span<{ variant: 'light' | 'primary' }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border-radius: 4px;
  width: max-content;

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.colors.actionPure};
      background-color: ${({ theme }) => theme.colors.actionLight};
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.colors.quaternaryPure};
      background-color: ${({ theme }) => theme.colors.quaternaryLight};
    `}
`

export const TextParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.lowPure};
  align-self: stretch;
  margin-bottom: 0.5rem;
`

export const NoDocContainer = styled.div`
  margin-top: 20%;
  @media (max-width: 600px) {
    margin-top: 90%;
  }
`
