import { DocumentationsPageProvider } from './context'
import { DocumentationsView } from './view'

export const ProjectDocumentationsPage = () => {
  return (
    <DocumentationsPageProvider>
      <DocumentationsView />
    </DocumentationsPageProvider>
  )
}
