export const labelDefault = (status: string) => {
  switch (status) {
    case 'AGEN':
      return 'AGENDADA'
    case 'AUPL':
      return 'PLANEJADA'
    case 'AUPE':
      return 'PLANEJADA'
    case 'AGUA':
      return 'AGUARDANDO INICIO'
    case 'FINA':
      return 'FINALIZADO'
    case 'AVPE':
      return 'com AVALIAÇÃO PENDENTE'
    case 'PEND':
      return 'PENDENTE'
    case 'INIC':
      return 'INICIADA'
    default:
      'INIC'
      break
  }
}
