import React from 'react'

import { UilEye } from '@iconscout/react-unicons'

import {
  ButtonComponent,
  CardDescription,
  DropdownSkillComponent,
  IconComponent,
  SkeletonComponent,
} from '~/components'

import { truncateText } from '~/validations/truncateText'

import * as S from './CardInfo.styles'
export const CardInfoProject = ({ title, nuOrder, description, components, handleExplore, loading }) => {
  return (
    <S.CardContainer>
      <S.TitleRow>
        <S.NuOrder>{nuOrder}</S.NuOrder>
        <S.Title>{title}</S.Title>
      </S.TitleRow>
      <CardDescription description={description} />
      <S.Content>
        {loading ? (
          <SkeletonComponent width={300} />
        ) : (
          components?.map((component, index) => (
            <S.CardBox key={index}>
              <S.CardInfo>
                {component.icon}
                {component.categories[0].txImagePath && (
                  <IconComponent
                    color="#0095FF"
                    fontWeight="normal"
                    code={component.categories[0].txImagePath}
                    size="xsmall"
                  />
                )}

                <S.RowCard>
                  <S.TitleCard>
                    {component.categories[0].txCategoryName} <b>{truncateText(component.txProjectTitle, 50)}</b>
                  </S.TitleCard>
                  <ButtonComponent
                    size="xsmall"
                    text="Explorar"
                    fontWeight="normal"
                    fontSize="small"
                    variant="outline"
                    iconEnd={<UilEye size={16} />}
                    onClick={() => handleExplore(component, index + 1)}
                  />
                </S.RowCard>
              </S.CardInfo>

              {component.evidences
                ?.filter((evidence) => evidence.coEvidenceType === 'FIX')
                .map((evidence, evidenceIndex) => (
                  <React.Fragment key={evidenceIndex}>
                    <DropdownSkillComponent
                      txSkillName={evidence.txAbilityName}
                      txSkillDescription={evidence.txEvidenceName}
                      txCompetency={evidence.txCompetenceName}
                      iconSkill={evidence.txAbilityImagePath}
                      iconCompetency={evidence.txCompetenceImagePath}
                    />
                  </React.Fragment>
                ))}
              <S.TextParagraph>
                <span>
                  <strong> Desafio da etapa: </strong>
                  {component.txDescription}
                </span>
              </S.TextParagraph>
            </S.CardBox>
          ))
        )}
      </S.Content>
    </S.CardContainer>
  )
}
