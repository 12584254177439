import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { HTTPClient } from '~/server/HTTPClient'

import { IBodyLoginProps } from './Login.interfaces'

const loginAPI = async (body: IBodyLoginProps) => {
  const { data } = await HTTPClient.post('/user/token', body)

  return data
}

interface ILoginError {
  error: string
  error_description: string
  required_actions: string[]
}

export const useServiceLogin = () => useMutation<any, AxiosError<ILoginError>, any>(['login'], loginAPI)
