import { Box, Link, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const LinkCardContainer = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

export const CardContainer = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '15px',
  gap: '16px',
  padding: '16px',
  cursor: 'pointer',
  backgroundColor: '#F9FAFC',
}))

export const WrapperBetween = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}))

export const ContentText = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const TextLabel = styledMUI(Typography)<{ isHeaderControl: boolean | undefined }>(({ isHeaderControl }) => ({
  fontStyle: 'normal',
  fontWeight: isHeaderControl ? 450 : 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const TextDescription = styledMUI(Typography)(() => ({
  maxWidth: '232px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#666',
}))

export const ContentIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#E2E5EC',
  padding: 0,
  borderRadius: '50px',
}))
