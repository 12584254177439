import dayjs from 'dayjs'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { HistoricalEvolution } from '~/services/Dashboard/types'

import { formatLabel } from '~/containers/private/StudentDashboard/OverviewByCompetence/OverviewByCompetence'

import * as S from './HistoricEvolutionChart.styles'

const formatMonth = (value: number) => {
  return dayjs()
    .locale('pt-br')
    .month(value - 1)
    .format('MMM')
    .toUpperCase()
}
const formatMonthExtended = (value: number) => {
  const month = dayjs()
    .locale('pt-br')
    .month(value - 1)
    .format('MMMM')
  return month[0].toUpperCase() + month.slice(1)
}

type HistoricEvolutionChartProps = {
  data: HistoricalEvolution[]
  color: string
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <S.Tooltip>
        <S.TooltipMonth>{formatMonthExtended(label)}</S.TooltipMonth>
        {/*<S.TooltipTotalClasses>
          {payload[0].payload.totalClasses}{' '}
          {payload[0].payload.totalClasses === 1 ? 'aula realizada' : 'aulas realizadas'}
        </S.TooltipTotalClasses>*/}
        <S.StatusTooltip>{formatLabel(payload[0].value)}</S.StatusTooltip>
      </S.Tooltip>
    )
  }

  return null
}

export const HistoricEvolutionChart = ({ data, color }: HistoricEvolutionChartProps) => {
  const { width } = useWindowDimensions()

  const isMobile = width && width <= 768

  const processedData = data.map((item) => ({
    ...item,
    assessmentAverage:
      item.keyNumber !== undefined && item.assessmentAverage === undefined ? 0 : item.assessmentAverage,
  }))

  return (
    <S.Container>
      <h3>Evolução Histórica</h3>
      <ResponsiveContainer width={isMobile ? 280 : 800} height={200}>
        <LineChart
          width={isMobile ? 280 : 800}
          height={200}
          data={processedData}
          margin={{
            top: 10,
            right: 30,
            left: 30,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            tickFormatter={formatMonth}
            dataKey="keyNumber"
            fontSize={12}
            angle={-90}
            textAnchor="end"
            axisLine={false}
            tickMargin={10}
            type={'number'}
            tickCount={12}
            interval="preserveStartEnd"
            domain={[1, 12]}
            tick={{ strokeWidth: 0, stroke: '#000' }}
            padding={{ left: 5 }}
            tickLine={false}
          />
          <YAxis
            tickFormatter={formatLabel}
            tickLine={false}
            fontSize={12}
            fontWeight={700}
            type={'number'}
            domain={[0, 100]}
            axisLine={false}
            padding={{ bottom: 0 }}
            width={80}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            connectNulls
            type="linear"
            strokeWidth={2}
            dataKey="assessmentAverage"
            stroke="#BDC3CF"
            fill={`#${color}`}
            dot={{ strokeWidth: 2, stroke: `#${color}` }}
          />
        </LineChart>
      </ResponsiveContainer>
    </S.Container>
  )
}
