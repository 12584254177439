import React, { useState } from 'react'

import { UilArrowLeft, UilArrowRight, UilTimes } from '@iconscout/react-unicons'
import { Box } from '@mui/material'

import { AccordionTextComponent, ModalComponent } from '~/components'
import { MediaProps } from '~/components/ui/MediaInformation/MediaInformation'
import * as S from '~/components/ui/MediaInformation/MediaInformation.styles'

interface IModalMediaListMobile {
  modalVisible: boolean
  mediaImageAndVideoList: MediaProps[]
  handleImageClick: (imageSrc: string) => void
  exitFullscreen: () => void
  txMomentNotes?: string | undefined
}

export const ModalMediaListMobile = ({
  modalVisible,
  mediaImageAndVideoList,
  exitFullscreen,
  txMomentNotes,
}: IModalMediaListMobile) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const length = mediaImageAndVideoList.length

  const goToNextIndex = (): void => {
    const nextIndex = (currentIndex + 1) % length
    setCurrentIndex(nextIndex)
  }

  const goToPrevMedia = (): void => {
    const prevIndex = (currentIndex - 1 + length) % length
    setCurrentIndex(prevIndex)
  }

  const handleImageClick = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <>
      <ModalComponent
        open={modalVisible}
        actionCloseModal={exitFullscreen}
        height={'365px'}
        width={'328px'}
        padding={'0px'}
      >
        <>
          <Box position={'relative'} display={'grid'}>
            {mediaImageAndVideoList.length > 0 && (
              <>
                <S.ModalCarouselMobile>
                  {mediaImageAndVideoList.map((media, index) => (
                    <React.Fragment key={media.srcMedia}>
                      {index === currentIndex &&
                        (media?.mediaType?.includes('video') ? (
                          <S.ContainerVideo key={media.srcMedia}>
                            <S.Video
                              controls
                              controlsList="nodownload noplaybackrate"
                              src={media.srcMedia}
                              key={media.srcMedia}
                              onClick={() => handleImageClick(index)}
                            />
                          </S.ContainerVideo>
                        ) : (
                          <S.ModalImage src={media?.srcMedia} key={media.srcMedia}></S.ModalImage>
                        ))}
                    </React.Fragment>
                  ))}
                  <AccordionTextComponent paragraph={txMomentNotes || '...'} limit={90} />
                </S.ModalCarouselMobile>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'4px'}>
                  {mediaImageAndVideoList?.map((media, index) => (
                    <S.ThumbnailImage
                      src={media?.srcMedia}
                      key={media.srcMedia}
                      selected={currentIndex === index}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
          <S.ContainerButtons>
            <S.Button onClick={goToPrevMedia} enabled={currentIndex !== 0}>
              <UilArrowLeft size={32} />
            </S.Button>
            <S.ButtonLeave onClick={exitFullscreen}>
              <UilTimes size={32} />
            </S.ButtonLeave>
            <S.Button onClick={goToNextIndex} enabled={currentIndex !== length - 1}>
              <UilArrowRight size={32} />
            </S.Button>
          </S.ContainerButtons>
        </>
      </ModalComponent>
    </>
  )
}
