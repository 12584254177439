import { ScheduleModalPageProvider } from './ScheduleContext'
import { ScheduleView } from './ScheduleModal'
import { IScheduleComponentProps } from './ScheduleModal.interfaces'

export const ScheduleModalComponent = (props: IScheduleComponentProps) => {
  return (
    <ScheduleModalPageProvider {...props}>
      <ScheduleView {...props} />
    </ScheduleModalPageProvider>
  )
}
