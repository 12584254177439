import { useState } from 'react'
import { ConnectDropTarget } from 'react-dnd'

import { Group } from '~/services/Students/types'

import { getTypeSpecificId } from '~/components/Forms/Assessment/Assessment'

import { AvatarStudentComponent } from '~/containers/private/Fund1/LessonAssessment'

import * as S from './Assessment.styles'

type AssessmentStepProps = {
  stepAssessment: 'toDevelop' | 'inEarlyDevelopment' | 'underDevelopment' | 'developed'
  labelText: string
  dropRef: ConnectDropTarget | null
  type: 'lesson' | 'project'
  students: Group[]
  updateStudents: any
  isDragging?: boolean
  group: unknown
}

export const AssessmentStep = ({
  stepAssessment,
  labelText,
  dropRef,
  students,
  type,
  updateStudents,
  isDragging,
  group,
}: AssessmentStepProps) => {
  const [draggedStudentId, setDraggedStudentId] = useState<number>(0)
  const [studentsState, setStudents] = useState(null)

  const handleSelectStudent = (idStudent: number) => {
    const updatedStudents = students.map((student) => {
      if (student.idStudent === idStudent) {
        return { ...student, isSelected: !student.isSelected }
      }
      setStudents(students)
      return students
    })

    updateStudents(updatedStudents)
  }

  return (
    <S.CardStepAssessment stepAssessment={stepAssessment}>
      <S.TextLabel>{labelText}</S.TextLabel>

      <S.ContentDrop ref={dropRef} stepAssessment={stepAssessment} avatars={students.length > 0}>
        {students.length ? (
          <>
            {students.map((student) => (
              <>
                <div
                  key={student.idMomentGroup}
                  onClick={() => handleSelectStudent(student.idStudent)}
                  onMouseOver={() => setDraggedStudentId(student.idStudent)}
                  onFocus={() => setDraggedStudentId(student.idStudent)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSelectStudent(student.idStudent)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <AvatarStudentComponent
                    isSelected={student?.isSelected}
                    object={student}
                    key={getTypeSpecificId(student, type)}
                    id={getTypeSpecificId(student, type)}
                    group={students.filter((student) => student.isSelected)}
                    isDragging={isDragging}
                    idBatch={draggedStudentId}
                    txName={student?.studentClass?.student?.txName}
                    photoUrl={student?.studentClass?.student?.txImagePath}
                  />
                </div>
              </>
            ))}
          </>
        ) : (
          <S.TextDescriptionDrop stepAssessment={stepAssessment}>Arraste um aluno para cá</S.TextDescriptionDrop>
        )}
      </S.ContentDrop>
    </S.CardStepAssessment>
  )
}
