import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { IProjectPageContextProps, IProjectPageProviderProps } from './interfaces'

const ProjectPageContext = createContext<IProjectPageContextProps>({
  project: undefined,
  idProjectMoment: 0,
  projectMomentsDetails: undefined,
  projectTracks: undefined,
  projectStage: undefined,
  stageOne: undefined,
  txCompetency: '',
  classBefore: undefined,
  stageThree: undefined,
  stageTwo: undefined,
  projectMomentGroup: undefined,
  dtSchedule: '',
  idClass: 0,
  titleNuOrder: 0,
  txAbility: '',
  txClassName: '',
  txEvidence: '',
  txGrade: '',
  txIconCompetency: '',
  txIconSkill: '',
  nuProjectTrackStages: undefined,
  nuOrder: 0,
})

const ProjectPageProvider = ({ children }: IProjectPageProviderProps) => {
  const { projectId } = useParams()
  const { getOfflineInformation } = useOfflineMode()

  const project = getOfflineInformation?.[0]?.moments.find((moment) => moment.idMoment === Number(projectId))
  const projectStage = getOfflineInformation?.[0]?.projectMomentsDetails?.[0]?.projectMomentStage
  const projectMomentsDetails = getOfflineInformation?.[0]?.projectMomentsDetails.find(
    (moment) => moment.idProjectMoment === Number(projectId),
  )
  const projectMomentGroup = getOfflineInformation?.[0]?.projectMomentGroups?.[0]?.group

  const projectTracks = getOfflineInformation?.[0]?.projectTracks

  const moment = getOfflineInformation?.[0]?.moments.find((trackDetails) => trackDetails.idMoment === Number(projectId))

  const titleNuOrder =
    getOfflineInformation?.[0]?.projectTracksDetails.find((trackDetails) => trackDetails.idMoment === Number(projectId))
      ?.nuOrder || 0

  const classBefore = getOfflineInformation?.[0]?.projectTracksDetails.filter(
    (trackDetails) => trackDetails.idMoment === Number(projectId),
  )

  const dtSchedule = moment?.dtSchedule || ''
  const txClassName = moment?.class.txName || ''
  const nuProjectTrackStages = moment?.nuTrackStages || 0
  const nuOrder = moment?.nuOrder || 0
  const txGrade = moment?.class?.schoolGrade?.grade?.txGrade || ''
  const idClass = moment?.class?.idClass || 0
  const nuTrackStages = moment?.nuTrackStages

  const txAbility = projectMomentsDetails?.projectMomentStage[0].projectStage.evidenceFixed?.ability?.txName || ''

  const txEvidence = projectMomentsDetails?.projectMomentStage[0].projectStage?.evidenceFixed?.txName || ''

  const txIconCompetency =
    projectMomentsDetails?.projectMomentStage[0].projectStage?.evidenceFixed?.ability?.txImagePath || ''

  const txIconSkill =
    projectMomentsDetails?.projectMomentStage[0].projectStage?.evidenceFixed?.ability?.competence?.txImagePath || ''

  const txCompetency =
    projectMomentsDetails?.projectMomentStage[0].projectStage?.evidenceFixed?.ability?.competence?.txName || ''

  const stageOne = projectStage?.[0]?.projectStage
  const stageTwo = projectStage?.[1]?.projectStage
  const stageThree = projectStage?.[2]?.projectStage

  const projectPageProviderValues = useMemo(() => {
    return {
      project,
      idProjectMoment: projectStage?.[0]?.projectStage.idProjectStage || 0,
      projectMomentsDetails,
      projectTracks,
      projectStage,
      stageOne,
      txCompetency,
      classBefore,
      stageThree,
      stageTwo,
      dtSchedule,
      idClass,
      titleNuOrder,
      txAbility,
      txClassName,
      txEvidence,
      txGrade,
      txIconCompetency,
      txIconSkill,
      projectMomentGroup,
      nuProjectTrackStages,
      nuTrackStages,
      nuOrder,
    }
  }, [
    project,
    projectStage,
    projectMomentsDetails,
    projectTracks,
    stageOne,
    txCompetency,
    classBefore,
    stageThree,
    stageTwo,
    dtSchedule,
    idClass,
    titleNuOrder,
    txAbility,
    txClassName,
    txEvidence,
    txGrade,
    txIconCompetency,
    txIconSkill,
    projectMomentGroup,
    nuProjectTrackStages,
    nuTrackStages,
    nuOrder,
  ])

  return <ProjectPageContext.Provider value={projectPageProviderValues}>{children}</ProjectPageContext.Provider>
}

const useProjectPageContext = () => useContext(ProjectPageContext)

export { ProjectPageProvider, useProjectPageContext }
