import { UilBookmark } from '@iconscout/react-unicons'

import { ButtonComponent, ModalComponent } from '~/components'

import { IModalInfoComponentProps } from './ModalInfo.interfaces'
import * as S from './ModalInfo.styles'

export const ModalInfoComponent = ({
  open,
  actionCloseModal,
  txGuidanceBNCC,
  text,
  textH1,
  btnText,
  icon,
}: IModalInfoComponentProps) => {
  return (
    <ModalComponent open={open} actionCloseModal={actionCloseModal}>
      <S.Container>
        <S.IconBox>
          <S.Icon>
            <UilBookmark size={35}></UilBookmark>
          </S.Icon>
        </S.IconBox>

        <S.WrapperTitle>
          <S.Title variant="h1">{text}</S.Title>
        </S.WrapperTitle>

        <S.Content>
          <S.Topic>1. Você pode selecionar qualquer trilha disponível.</S.Topic>
          <S.Description variant="caption">
            Lorem Ipsum is simply dummy text of the printing and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into{' '}
          </S.Description>
        </S.Content>

        <S.Content>
          <S.Topic>2. Todas as trilhas estão relacionadas a habilidades da BNCC.</S.Topic>
          <S.Description variant="caption">
            Lorem Ipsum is simply dummy text of the printing and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into{' '}
          </S.Description>
        </S.Content>

        <S.Content>
          <S.Topic>3. Lorem ipsum sit amet.</S.Topic>
          <S.Description variant="caption">
            Lorem Ipsum is simply dummy text of the printing and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into{' '}
          </S.Description>
        </S.Content>
        <S.FinalButton>
          <ButtonComponent
            text={'Começar a selecionar trilhas'}
            variant="solid"
            size="large"
            onClick={actionCloseModal}
          />
        </S.FinalButton>
      </S.Container>
    </ModalComponent>
  )
}
