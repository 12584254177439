import { Box, Modal } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const ContainerModal = styledMUI(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
export const ContentModal = styledMUI(Box)(() => ({
  width: '1312px',
  maxHeight: '1010px',
  backgroundColor: theme.colors.highPure,
  borderRadius: 16,
  display: 'flex',
  outline: 'none',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: theme.colors.highDark,
    border: `1px solid ${theme.colors.highDark}`,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.colors.lowLight,
    width: '4px',
  },
  // Media query para ajustar o estilo em resoluções maiores
  '@media (min-width: 1280px)': {
    width: '1312px',
    maxHeight: '98%',
  },
  // Media query para ajustar o estilo em resoluções menores
  '@media (max-width: 1280px)': {
    width: '90%',
    maxHeight: '90%',
  },
}))
