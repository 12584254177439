import classImage from '../../../../assets/images/history.png'
import historyImage from '../../../../assets/images/history.png'
import home from '../../../../assets/images/home.png'
import schedule from '../../../../assets/images/schedule.png'
import students from '../../../../assets/images/students.png'
import trails from '../../../../assets/images/trails.png'

export const images = {
  home: home,
  trails: trails,
  schedule: schedule,
  class: classImage,
  material: classImage,
  students: students,
  documentation: trails,
  history: historyImage,
}
