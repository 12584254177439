import { Box, Button, Typography, Divider } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

interface WrapperContainerProps {
  isMobile: boolean
}

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  padding: 94px 24px 24px;
  margin-top: -70px;
  gap: 16px;
  border-bottom: 1px solid #e2e5ec;
  background-color: #fff;
  width: 923px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ContainerTrackClass = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #7c8189;
  padding: 2.5px 6px;
  border-radius: 4px;

  span:last-child {
    border-right: none;
  }
`

export const ComponentContentPopover = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const DividerComponent = styledMUI(Divider)(() => ({
  width: '105.5%',
  marginLeft: '-24px',
}))

export const ClassSpanInfo = styled.span`
  font-size: 14px;
  padding: 0 6px;
  color: #7c8189;
  border-right: 1px solid #e2e5ec;
  min-width: fit-content;

  &:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: auto;
    max-width: 100px;
  }
`

export const WrapperBetween = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const WrapperContainer = styled.div<WrapperContainerProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;
  gap: 8px;
  align-items: flex-start;
`
export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const CircleNumber = styledMUI(Box)(() => ({
  height: '40px',
  width: '40px',
  minWidth: '40px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F9FAFC',
  borderRadius: '50px',
}))

export const TextNumber = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: '#666',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
  '@media (max-width: 768px)': {
    fontSize: '20px',
  },
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContainerPopover = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  gap: '8px',
  width: '334px',
  marginTop: '26px',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: `${theme.colors.highPure}`,
  boxShadow: `0px 4px 4px ${theme.shadows.level4}`,
  transition: '0.8',
  zIndex: 9999,
}))

export const ContentTooltip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const TextTooltip = styled.span<{ popoverContentType?: string }>`
  flex-direction: row-reverse;
  gap: 8px;
  display: flex;
  font-size: 14px;
  font-weight: ${({ popoverContentType }) => (popoverContentType === 'PRI' ? 700 : 400)};
  &.last-item {
    margin-left: 5px;
  }
`
export const TitleTooltip = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.lowLight};
`

export const ButtonIcon = styledMUI(Button)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  backgroundColor: `${theme.colors.highPure}`,
  padding: 0,
}))
