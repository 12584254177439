import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import dayjs from 'dayjs'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { defaultDataState } from './default.states'
import { IDataProps, ISchedulePageContextProps, ISchedulePageProviderProps } from './interfaces'
import { GET_CLASSES_TODAY } from './schemas.queries'

const SchedulePageContext = createContext<ISchedulePageContextProps>({
  changeCurrentDay: () => Object,
  selectedDay: new Date(),
  changeSelectedDay: () => Object,
  isLoading: false,
  data: defaultDataState,
  currentDay: new Date(),
  isRefetching: false,
  extensiveSelectedDay: '',
  monthFormatted: '',
  changeMonth: () => Object,
  itemsByMonth: '',
  monthRefs: '',
})

const SchedulePageProvider = ({ children }: ISchedulePageProviderProps) => {
  const [selectedDay, setSelectedDay] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
  const [dateRange, setDateRange] = useState({
    dateStart: dayjs().startOf('year').format('YYYY-MM-DD'),
    dateEnd: dayjs().endOf('year').format('YYYY-MM-DD'),
  })
  const [currentMonth, setCurrentMonth] = useState<number>(dayjs().month() || 0)
  const { isLoading, data, isRefetching, refetch } = useQueryGraphQLClient(
    'moment',
    'GET_CLASSES_TODAY',
    GET_CLASSES_TODAY,
    {
      dateStart: dayjs(dateRange.dateStart).format('YYYY-MM-DD'),
      dateEnd: dayjs(dateRange.dateEnd).format('YYYY-MM-DD'),
      showScheduledClasses: true,
    },
  )

  const changeCurrentDay = useCallback((date) => {
    const monthName = capitalizeFirstLetter(dayjs(date).format('MMMM').toLowerCase())
    setSelectedDay(date)

    if (monthRefs.current[monthName] !== null && monthRefs.current[monthName] !== undefined) {
      monthRefs.current[monthName]?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const changeSelectedDay = useCallback((date) => {
    const monthName = capitalizeFirstLetter(dayjs(date).format('MMMM').toLowerCase())
    setSelectedDay(date)

    setTimeout(() => {
      if (monthRefs.current[monthName]) {
        monthRefs.current[monthName].scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }, [])

  const changeMonth = useCallback((direction) => {
    setSelectedDay((prevDate) => {
      const newDate =
        direction === 'next' ? dayjs(prevDate).add(1, 'month').toDate() : dayjs(prevDate).subtract(1, 'month').toDate()

      const monthName = capitalizeFirstLetter(dayjs(newDate).format('MMMM').toLowerCase())

      setCurrentMonth(dayjs(newDate).month())

      setTimeout(() => {
        if (monthRefs.current[monthName]) {
          monthRefs.current[monthName].scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)

      return newDate
    })
  }, [])

  const monthRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const topRef = useRef<HTMLDivElement | null>(null)
  const bottomRef = useRef<HTMLDivElement | null>(null)

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  const [itemsByMonth, setItemsByMonth] = useState({})

  useEffect(() => {
    if (data?.scheduledMomentByProfessor?.items) {
      const filteredItems = data.scheduledMomentByProfessor.items.filter((item: IDataProps) =>
        dayjs(item.dtSchedule).isBetween(dateRange.dateStart, dateRange.dateEnd, null, '[]'),
      )

      const sortedItems = [...filteredItems].sort((a, b) => dayjs(a.dtSchedule).diff(dayjs(b.dtSchedule)))

      const allMonths = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]

      const itemsMap = allMonths.reduce((acc, month) => {
        acc[month] = []
        return acc
      }, {})

      sortedItems.forEach((scheduleMoment) => {
        const month = dayjs(scheduleMoment.dtSchedule).format('MMMM')
        const formattedMonth = capitalizeFirstLetter(month.toLowerCase())
        const formattedDate = capitalizeFirstLetter(dayjs(scheduleMoment.dtSchedule).format('dddd, D'))
        const isToday = dayjs().isSame(dayjs(scheduleMoment.dtSchedule), 'day')

        if (
          !itemsMap[formattedMonth].length ||
          itemsMap[formattedMonth][itemsMap[formattedMonth].length - 1].date !== formattedDate
        ) {
          itemsMap[formattedMonth].push({ type: 'header', date: formattedDate, items: [], isToday })
        }

        itemsMap[formattedMonth].find((item) => item.date === formattedDate).items.push(scheduleMoment)
      })

      setItemsByMonth(itemsMap)
    }
  }, [data, refetch, dateRange])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentMonth(entry.target.getAttribute('data-month'))
          }
        })
      },
      { threshold: 0.1 },
    )

    const refs = Object.values(monthRefs.current)
    refs.forEach((ref) => {
      if (ref) observer.observe(ref)
    })

    return () => {
      refs.forEach((ref) => {
        if (ref) observer.unobserve(ref)
      })
    }
  }, [itemsByMonth])

  const currentMonthName = capitalizeFirstLetter(dayjs(selectedDay).format('MMMM').toLowerCase())

  useEffect(() => {
    setTimeout(() => {
      if (monthRefs.current[currentMonthName]) {
        monthRefs.current[currentMonthName].scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }, [itemsByMonth, currentMonthName, selectedDay])

  const monthFormatted = capitalizeFirstLetter(dayjs().month(currentMonth).format('MMMM'))

  const schedulePageProviderValues = useMemo(() => {
    return {
      changeCurrentDay,
      selectedDay,
      changeSelectedDay,
      isLoading,
      data,
      isRefetching,
      monthFormatted,
      topRef,
      bottomRef,
      itemsByMonth,
      monthRefs,
      changeMonth,
    }
  }, [
    changeCurrentDay,
    selectedDay,
    changeSelectedDay,
    isLoading,
    data,
    isRefetching,
    monthFormatted,
    topRef,
    bottomRef,
    itemsByMonth,
    monthRefs,
    changeMonth,
  ])

  return <SchedulePageContext.Provider value={schedulePageProviderValues}>{children}</SchedulePageContext.Provider>
}

const useSchedulePageContext = () => useContext(SchedulePageContext)

export { SchedulePageProvider, useSchedulePageContext }
