import { useState, useMemo, useCallback, createContext, useContext } from 'react'

import { defaultSnackbarState } from './Snackbar.default.states'
import { ISnackbarProviderProps, ISnackbarProps, ISnackbarContextProps } from './Snackbar.interfaces'

const SnackbarContext = createContext<ISnackbarContextProps>({
  snackbar: defaultSnackbarState,
  showSnackbarSuccess: () => Object,
  showSnackbarError: () => Object,
  closeSnackbar: () => Object,
})

const SnackbarProvider = ({ children }: ISnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState<ISnackbarProps>(defaultSnackbarState)

  const showSnackbarSuccess = useCallback((message: string) => {
    setSnackbar({
      show: true,
      type: 'success',
      message,
      color: '#0BB07B',
    })
  }, [])

  const showSnackbarError = useCallback((message: string) => {
    setSnackbar({
      show: true,
      type: 'error',
      message,
      color: '#F03D3D',
    })
  }, [])

  const closeSnackbar = useCallback(() => {
    setSnackbar({
      ...snackbar,
      show: false,
      message: '',
    })
  }, [snackbar])

  const snackbarProviderValues = useMemo(
    () => ({ snackbar, showSnackbarSuccess, showSnackbarError, closeSnackbar }),
    [snackbar, showSnackbarSuccess, showSnackbarError, closeSnackbar],
  )

  return <SnackbarContext.Provider value={snackbarProviderValues}>{children}</SnackbarContext.Provider>
}

const useSnackbar = () => useContext(SnackbarContext)

export { SnackbarProvider, useSnackbar }
