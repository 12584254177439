import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px',
  gap: '24px',
}))

export const ButtonSeeMore = styled.button`
  background-color: #f9fafc;
  border-radius: 4px;
  border: none;
  color: #666666;
  padding: 4px;
  cursor: pointer;
`

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
}))

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const Content = styledMUI(Box)(() => ({
  background: '#F9FAFC',
  overflowY: 'auto',
  maxHeight: '100vh',
  borderRadius: '8px',
  padding: '16px',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: '1px solid #0095ff',
  backgroundColor: '#fff',
  padding: 0,
}))
