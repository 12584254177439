import { LoadingButton } from '@mui/lab'
import { IconButton } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.primaryDark};
`

export const ContainerLogin = styled.div`
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  background-color: ${theme.colors.primaryDark};
`

export const ContentLogin = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.primaryDark};
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  align-items: center;

  @media (max-width: 1100px) {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    gap: 56px;
    flex-direction: column-reverse;
  }

  @media (max-width: 1100px) {
    height: 100vh;
    width: 100vw;
  }
`

export const ContainerImage = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 24px;
  width: 49%;

  img {
    border-radius: 24px;
  }

  @media (max-width: 1100px) {
    margin-bottom: -170px;
    img {
      height: 354px;
      border-radius: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .image-fade {
    animation: fadeIn 1s ease-in-out;
  }
`
export const Image = styled.img``

export const Credentials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 49%;
`

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #fff;
`

export const ForgotInputLabel = styled.span`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #666666;
`

export const Input = styled.input`
  background-color: #fff;
  text-decoration: none;
  border: 0;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  min-width: 90%;
  height: 56px;
`

export const ForgotInput = styled.input`
  margin-top: 4px;
  background-color: transparent;
  text-decoration: none;
  border: 1px solid #c1c4c1;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  height: 56px;
  min-width: 100%;
`

export const ForgotFormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Logo = styled.img``

export const ActionButton = styled.button`
  height: 48px;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  background-color: #0095ff;
  color: #fff;
  margin-top: 20px;
  min-width: 300px;

  @media (max-width: 350px) {
    min-width: 270px;
  }
`

export const LoadingActionButton = styledMUI(LoadingButton)(() => ({
  height: '48px',
  fontSize: '18px',
  fontWeight: 'bold',
  borderRadius: '50px',
  cursor: 'pointer',
  userSelect: 'none',
  marginTop: '20px',
  minWidth: '300px',

  '@media (max-width: 350px)': {
    minWidth: '270px',
  },
}))

export const ErrorFeedback = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
`

export const GreyErrorFeedback = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #fbffff;
`

export const SignButton = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50px;
  border: 0;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-family: 'Inter';
  background-color: #f7c327;
  color: #000;
`

export const Password = styled.div`
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding-right: 8px;

  *:focus {
    outline: none;
  }

  @media (max-width: 400px) {
    width: 275px;
  }
`

export const ForgotPassword = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
  align-self: start;
  color: #fff;
  text-decoration: underline;
  font-family: 'Inter';
  font-weight: 400;
  line-height: 14px;
  font-size: 14px;
  align-self: flex-end;
  margin-top: -14px;
`

export const ForgotModal = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ForgotTitle = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  color: #0095ff;
  text-align: left;
`

export const ButtonCloseModal = styled(IconButton)`
  cursor: pointer;
`

export const ForgotDescription = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
