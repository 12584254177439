import { useEffect, useRef, useState } from 'react'

import { UilArrowLeft, UilCheck } from '@iconscout/react-unicons'
import { Box, Tooltip } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent, SkeletonComponent } from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'

import { translateCoStage } from '~/validations/translateCoStage'

import theme from '~/styles/theme'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const TrailHeader = ({
  actionSchedule,
  actionDesengage,
  loadingSchedule,
  canDesengage,
  classInfo,
  title,
  isLoading,
  canSchedule,
  handleBack,
}: IHeaderComponentProps) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const titleRef = useRef(null)
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  useEffect(() => {
    if (titleRef.current) {
      setIsTruncated(titleRef.current.scrollWidth > titleRef.current.offsetWidth)
    }
  }, [title])

  if (isLoading)
    return (
      <S.HeaderContainer component="header">
        <S.Wrapper>
          <Box width={'100%'}>
            <SkeletonComponent width={'100%'} height={100} variant={'rounded'} />
          </Box>
        </S.Wrapper>
      </S.HeaderContainer>
    )
  return (
    <S.HeaderContainer component="header">
      {isMobile && (
        <S.ButtonIconMobile data-testid="btn-back-date">
          <UilArrowLeft
            data-testid="trail-back-button"
            size={18}
            onClick={() => handleBack()}
            color={theme.colors.actionPure}
          />
          Voltar
        </S.ButtonIconMobile>
      )}
      <S.Wrapper>
        <S.ContentTitle>
          {!isMobile && (
            <S.ButtonIcon data-testid="btn-back-date">
              <UilArrowLeft
                data-testid="trail-back-button"
                size={18}
                onClick={() => handleBack()}
                color={theme.colors.actionPure}
              />
            </S.ButtonIcon>
          )}
          <S.ContentSelectDay>
            {!isMobile ? (
              <Tooltip title={isTruncated ? title : ''} placement="top">
                <S.Title ref={titleRef} variant="h1">
                  {title}
                </S.Title>
              </Tooltip>
            ) : (
              <S.TitleMobile>{title}</S.TitleMobile>
            )}
            <TagClass
              texts={[translateCoStage(classInfo?.coStage), classInfo?.classShift?.txClassShift, classInfo?.txName]}
              variant="default"
            />
          </S.ContentSelectDay>
        </S.ContentTitle>
        {!isMobile && (
          <S.ContentSelectDate>
            <ButtonComponent
              text={'Cancelar'}
              fontColor={'black'}
              fontSize="medium"
              fontWeight={'normal'}
              data-testid="trail-back-button"
              onClick={() => handleBack()}
              variant="text"
              size="medium"
            />
            {canDesengage && (
              <ButtonComponent
                text={'Desocupar Trilha'}
                fontSize="medium"
                data-testid="trail-desengage-button"
                fontWeight={'normal'}
                color="error"
                variant="outline"
                onClick={actionDesengage}
                sizeLoading={16}
                size="medium"
              />
            )}

            <ButtonComponent
              text={'Salvar datas da Trilha'}
              variant="solid"
              fontWeight="bold"
              size="small-header"
              data-testid="trail-save-button"
              color={!canSchedule ? 'disabled' : 'action'}
              disabled={!canSchedule}
              loading={loadingSchedule}
              sizeLoading={16}
              onClick={actionSchedule}
              iconEnd={<UilCheck size={18} />}
            />
          </S.ContentSelectDate>
        )}
      </S.Wrapper>
    </S.HeaderContainer>
  )
}
