import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const CardStudent = styledMUI(Box)(() => ({
  borderRadius: '16px',
  padding: '16px',
  gap: '16px',
  background: '#F9FAFC',
  width: '48.4%',
  height: '88px',
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const StudentName = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const ClassName = styledMUI(Typography)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '120%',
  color: '#666',
}))

export const CardWrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const TextWrapper = styledMUI(Box)(() => ({
  width: '100%',
  flexDirection: 'column',
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50px',
  padding: 0,
}))
