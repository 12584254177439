import { useMutation } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IBodyForgotPasswordProps } from './ForgotPassword.interfaces'

export const forgotPasswordAPI = async (body: IBodyForgotPasswordProps) => {
  const { data } = await HTTPClient.post('/user/forgot-password', body)

  return data
}

export const useServiceForgotPassword = () => useMutation(['forgotPassword'], forgotPasswordAPI)
