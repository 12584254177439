import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { dateOptions, defaultDataState } from './default.states'
import { ISchedulePageContextProps, ISchedulePageProviderProps } from './interfaces'
import { GET_CLASSES_TODAY } from './schemas.queries'

const SchedulePageContext = createContext<ISchedulePageContextProps>({
  currentDay: new Date(),
  changeCurrentDay: () => Object,
  selectedDay: new Date(),
  changeSelectedDay: () => Object,
  isLoading: false,
  data: defaultDataState,
  isRefetching: false,
  extensiveSelectedDay: '',
})

const SchedulePageProvider = ({ children }: ISchedulePageProviderProps) => {
  const [currentDay, setCurrentDay] = useState<Date>(new Date())
  const [selectedDay, setSelectedDay] = useState<Date>(new Date())

  const { isLoading, data, isRefetching, refetch } = useQueryGraphQLClient(
    'moment',
    'GET_CLASSES_TODAY',
    GET_CLASSES_TODAY,
    { date: dayjs(selectedDay).format('YYYY-MM-DD'), showScheduledClasses: true },
  )

  const changeCurrentDay = useCallback((date: Date) => setCurrentDay(date), [])

  const changeSelectedDay = useCallback((date: Date) => setSelectedDay(date), [])

  const upperCaseFirstLetter = useCallback((date: string) => date.charAt(0).toUpperCase() + date.slice(1), [])

  const extensiveSelectedDay = upperCaseFirstLetter(selectedDay.toLocaleDateString('pt-br', dateOptions))

  useEffect(() => {
    refetch().then()
  }, [selectedDay, refetch])

  const schedulePageProviderValues = useMemo(() => {
    return {
      currentDay,
      changeCurrentDay,
      selectedDay,
      changeSelectedDay,
      isLoading,
      data,
      isRefetching,
      extensiveSelectedDay,
    }
  }, [
    currentDay,
    changeCurrentDay,
    selectedDay,
    changeSelectedDay,
    isLoading,
    data,
    isRefetching,
    extensiveSelectedDay,
  ])

  return <SchedulePageContext.Provider value={schedulePageProviderValues}>{children}</SchedulePageContext.Provider>
}

const useSchedulePageContext = () => useContext(SchedulePageContext)

export { SchedulePageProvider, useSchedulePageContext }
