import { DragPreviewImage, useDrag } from 'react-dnd'

import { AvatarComponent, AvatarGroupComponent } from '~/components'

import { IAvatarStudentComponentProps } from './AvatarStudent.interfaces'
import * as S from './AvatarStudent.styles'

export const AvatarStudentComponent = ({
  id,
  photoUrl,
  txName,
  object,
  isSelected,
  group,
}: IAvatarStudentComponentProps) => {
  const [isDragging, drag, preview] = useDrag(() => {
    return {
      type: 'CARD',
      item: { id, photoUrl, txName, object: object },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }
  })

  const shouldChangeAppearance = isDragging && group.length > 1

  return (
    <>
      {shouldChangeAppearance && (
        <DragPreviewImage
          connect={preview}
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='red'/%3E%3C/svg%3E"
        />
      )}
      <S.Container ref={drag}>
        <S.ContainerSelect selected={isSelected} color="primary">
          <AvatarComponent isSelected={isSelected} photoUrl={photoUrl} label={txName} size="medium" />
        </S.ContainerSelect>
      </S.Container>
    </>
  )
}
