import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import { RequestInit } from 'graphql-request/dist/types.dom'

import * as Types from './operations'

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers'],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    })
}

export const GetLessonMomentDocumentationsByLessonMomentIdDocument = `
    query getLessonMomentDocumentationsByLessonMomentID($idLessonMoment: Int!) {
  lessonMomentDocumentationsByLessonMomentId(idLessonMoment: $idLessonMoment) {
    lessonMomentDocumentation {
      idLessonStep
      nuOrder
      idMediaInformation
      evidence {
        idEvidence
        txName
        ability {
          txName
          txImagePath
        }
      }
      txTitle
      idLessonDocumentation
      txMomentNotes
      student {
        txName
      }
    }
  }
}
    `
export const useGetLessonMomentDocumentationsByLessonMomentIdQuery = <
  TData = Types.GetLessonMomentDocumentationsByLessonMomentIdQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: Types.GetLessonMomentDocumentationsByLessonMomentIdQueryVariables,
  options?: UseQueryOptions<Types.GetLessonMomentDocumentationsByLessonMomentIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.GetLessonMomentDocumentationsByLessonMomentIdQuery, TError, TData>(
    ['getLessonMomentDocumentationsByLessonMomentID', variables],
    fetcher<
      Types.GetLessonMomentDocumentationsByLessonMomentIdQuery,
      Types.GetLessonMomentDocumentationsByLessonMomentIdQueryVariables
    >(client, GetLessonMomentDocumentationsByLessonMomentIdDocument, variables, headers),
    options,
  )
export const GetLessonByIdDocument = `
    query getLessonByID($lessonId: Int!) {
  lessonMomentById(idLessonMoment: $lessonId) {
    idLessonMoment
    idUserProfessor
    momentStatus {
      coMomentStatus
      txMomentStatus
    }
    lessonTrackGroup {
      lessonTrack {
        grade {
          lesson {
            idLesson
            txTitle
            lessonStep {
              idLessonStep
              txTitle
              nuOrder
              lessonActivity {
                idLessonActivity
                txTitle
                category {
                  txName
                  txImagePath
                }
                lessonMomentActivity {
                  idLessonMomentActivity
                }
                lessonActivityEvidence {
                  coEvidenceType
                  evidenceGrade {
                    idEvidence
                    evidence {
                      txName
                      ability {
                        txName
                        txImagePath
                        competence {
                          txName
                          txImagePath
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useGetLessonByIdQuery = <TData = Types.GetLessonByIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.GetLessonByIdQueryVariables,
  options?: UseQueryOptions<Types.GetLessonByIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.GetLessonByIdQuery, TError, TData>(
    ['getLessonByID', variables],
    fetcher<Types.GetLessonByIdQuery, Types.GetLessonByIdQueryVariables>(
      client,
      GetLessonByIdDocument,
      variables,
      headers,
    ),
    options,
  )
export const GetBnccRelationsByLessonIdDocument = `
    query getBNCCRelationsByLessonID($lessonId: Int!) {
  lessonMomentById(idLessonMoment: $lessonId) {
    momentStatus {
      txMomentStatus
      coMomentStatus
    }
    class {
      idClass
    }
    lessonTrackGroup {
      lessonTrack {
        grade {
          lesson {
            txTitle
            txGuidanceBncc
          }
        }
      }
    }
  }
}
    `
export const useGetBnccRelationsByLessonIdQuery = <TData = Types.GetBnccRelationsByLessonIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.GetBnccRelationsByLessonIdQueryVariables,
  options?: UseQueryOptions<Types.GetBnccRelationsByLessonIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.GetBnccRelationsByLessonIdQuery, TError, TData>(
    ['getBNCCRelationsByLessonID', variables],
    fetcher<Types.GetBnccRelationsByLessonIdQuery, Types.GetBnccRelationsByLessonIdQueryVariables>(
      client,
      GetBnccRelationsByLessonIdDocument,
      variables,
      headers,
    ),
    options,
  )
export const LessonByIdLessonMomentDocument = `
    query lessonByIdLessonMoment($idLesson: Int!, $idLessonMoment: Int!) {
  lessonById(lessonId: $idLesson) {
    idLesson
    txGuidanceBncc
    txGuidance
    txTitle
    lessonSchoolSupply {
      txSupply
      txQuantity
    }
    lessonStep {
      idLessonStep
      nuOrder
      txGuidance
      lessonActivity(
        where: {lessonMomentActivity: {some: {idLessonMoment: {eq: $idLessonMoment}}}}
      ) {
        txTitle
        txChallenge
        idLessonActivity
        nuOrder
        categoryGrade {
          category {
            txName
            txImagePath
          }
        }
        lessonActivityOrientation {
          txTitle
          txOrientationCode
          lessonActivitySupportReference {
            idLessonActivitySupportReference
            mediaInformation {
              txName
              txPath
              txContentType
              txAbsoluteUrl
            }
          }
        }
        lessonActivityEvidence {
          coEvidenceType
          evidenceGrade {
            idEvidence
            evidence {
              txName
              ability {
                txName
                txImagePath
                competence {
                  txName
                  txImagePath
                }
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useLessonByIdLessonMomentQuery = <TData = Types.LessonByIdLessonMomentQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.LessonByIdLessonMomentQueryVariables,
  options?: UseQueryOptions<Types.LessonByIdLessonMomentQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.LessonByIdLessonMomentQuery, TError, TData>(
    ['lessonByIdLessonMoment', variables],
    fetcher<Types.LessonByIdLessonMomentQuery, Types.LessonByIdLessonMomentQueryVariables>(
      client,
      LessonByIdLessonMomentDocument,
      variables,
      headers,
    ),
    options,
  )
export const LessonByIdPendingDocument = `
    query lessonByIdPending($idLesson: Int!) {
  lessonById(lessonId: $idLesson) {
    idLesson
    txGuidanceBncc
    txGuidance
    txTitle
    lessonSchoolSupply {
      txSupply
      txQuantity
    }
    lessonStep {
      idLessonStep
      nuOrder
      txGuidance
      lessonActivity {
        txTitle
        txChallenge
        idLessonActivity
        nuOrder
        categoryGrade {
          category {
            txName
            txImagePath
          }
        }
        lessonActivityOrientation {
          txTitle
          txOrientationCode
          lessonActivitySupportReference {
            idLessonActivitySupportReference
            mediaInformation {
              txName
              txPath
              txContentType
              txAbsoluteUrl
            }
          }
        }
        lessonActivityEvidence {
          coEvidenceType
          evidenceGrade {
            idEvidence
            evidence {
              txName
              ability {
                txName
                txImagePath
                competence {
                  txName
                  txImagePath
                }
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useLessonByIdPendingQuery = <TData = Types.LessonByIdPendingQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.LessonByIdPendingQueryVariables,
  options?: UseQueryOptions<Types.LessonByIdPendingQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.LessonByIdPendingQuery, TError, TData>(
    ['lessonByIdPending', variables],
    fetcher<Types.LessonByIdPendingQuery, Types.LessonByIdPendingQueryVariables>(
      client,
      LessonByIdPendingDocument,
      variables,
      headers,
    ),
    options,
  )
export const LessonActivityEvidencesByIdDocument = `
    query lessonActivityEvidencesById($idLessonActivity: Int!) {
  lessonActivityById(idLessonActivity: $idLessonActivity) {
    idLessonActivity
    txTitle
    lessonStep {
      idLessonStep
      nuOrder
      lessonActivity {
        idLessonActivity
        lessonActivityEvidence {
          coEvidenceType
          evidenceGrade {
            idEvidence
            evidence {
              txName
              ability {
                txImagePath
                txName
                competence {
                  txImagePath
                  txName
                }
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useLessonActivityEvidencesByIdQuery = <TData = Types.LessonActivityEvidencesByIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.LessonActivityEvidencesByIdQueryVariables,
  options?: UseQueryOptions<Types.LessonActivityEvidencesByIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.LessonActivityEvidencesByIdQuery, TError, TData>(
    ['lessonActivityEvidencesById', variables],
    fetcher<Types.LessonActivityEvidencesByIdQuery, Types.LessonActivityEvidencesByIdQueryVariables>(
      client,
      LessonActivityEvidencesByIdDocument,
      variables,
      headers,
    ),
    options,
  )
export const GetProjectByIdDocument = `
    query getProjectByID($projectId: Int!) {
  projectMomentById(idProjectMoment: $projectId) {
    idProjectMoment
    dtSchedule
    dtSchedule
    momentStatus {
      coMomentStatus
      txMomentStatus
    }
    class {
      idClass
      txName
      schoolGrade {
        grade {
          txGrade
          coStage
        }
      }
      student {
        idStudent
        txName
        txImagePath
      }
    }
    projectTrackStage {
      nuOrder
      txDescription
      idProjectTrack
      txGuidanceCode
      projectTrackStageFile {
        idMediaInformation
        mediaInformation {
          coMediaLocator
          idMediaInformation
          txContentType
          txPath
          txAbsoluteUrl
          txName
        }
      }
      evidence {
        idEvidence
        txName
        ability {
          txName
          txImagePath
          competence {
            txName
            txImagePath
            ability {
              txName
            }
          }
        }
      }
    }
    projectMomentStage {
      idProjectMomentStage
      projectStage {
        txDescription
        txGuidanceBncc
        idProjectStage
        project {
          txTitle
          txDescription
          projectComponent {
            component {
              txComponent
            }
            coComponentType
          }
          projectCategory {
            category {
              txName
              txImagePath
            }
          }
        }
        evidenceFixed {
          idEvidence
          txName
          ability {
            txName
            txImagePath
            competence {
              txName
              txImagePath
            }
          }
        }
        evidenceVariable {
          idEvidence
          txName
          ability {
            txName
            txImagePath
            competence {
              txName
              txImagePath
            }
          }
        }
      }
      projectMomentGroup {
        idProjectMomentGroup
        inAttendance
        studentClass {
          student {
            txName
            idStudent
            txImagePath
          }
        }
      }
    }
  }
}
    `
export const useGetProjectByIdQuery = <TData = Types.GetProjectByIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.GetProjectByIdQueryVariables,
  options?: UseQueryOptions<Types.GetProjectByIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.GetProjectByIdQuery, TError, TData>(
    ['getProjectByID', variables],
    fetcher<Types.GetProjectByIdQuery, Types.GetProjectByIdQueryVariables>(
      client,
      GetProjectByIdDocument,
      variables,
      headers,
    ),
    options,
  )
export const GetTrailsByClassIdDocument = `
    query getTrailsByClassID($trackId: Int!) {
  projectTrack(where: {idProjectTrack: {eq: $trackId}}) {
    items {
      idProjectTrack
      projectTrackStage {
        idProjectTrackStage
        nuOrder
        projectMoment {
          idProjectMoment
          dtSchedule
          coMomentStatus
        }
      }
    }
  }
}
    `
export const useGetTrailsByClassIdQuery = <TData = Types.GetTrailsByClassIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.GetTrailsByClassIdQueryVariables,
  options?: UseQueryOptions<Types.GetTrailsByClassIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.GetTrailsByClassIdQuery, TError, TData>(
    ['getTrailsByClassID', variables],
    fetcher<Types.GetTrailsByClassIdQuery, Types.GetTrailsByClassIdQueryVariables>(
      client,
      GetTrailsByClassIdDocument,
      variables,
      headers,
    ),
    options,
  )
export const GetTrailsDetailsDocument = `
    query getTrailsDetails($trackId: Int!, $idClass: Int!) {
  scheduledProjectMomentByClassAndTrackId(
    idClass: $idClass
    idProjectTrack: $trackId
  ) {
    items {
      idMoment
      coMomentStatus
      txMomentStatus
      dtProcessed
      dtSchedule
      txTitle
      coGrade
      nuOrder
      dtSchedule
    }
  }
}
    `
export const useGetTrailsDetailsQuery = <TData = Types.GetTrailsDetailsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.GetTrailsDetailsQueryVariables,
  options?: UseQueryOptions<Types.GetTrailsDetailsQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.GetTrailsDetailsQuery, TError, TData>(
    ['getTrailsDetails', variables],
    fetcher<Types.GetTrailsDetailsQuery, Types.GetTrailsDetailsQueryVariables>(
      client,
      GetTrailsDetailsDocument,
      variables,
      headers,
    ),
    options,
  )
export const LessonByIdDocument = `
    query lessonById($idLesson: Int!) {
  lessonById(lessonId: $idLesson) {
    idLesson
    lessonStep {
      idLessonStep
      nuOrder
      lessonActivity {
        idLessonActivity
        txTitle
        categoryGrade {
          category {
            txName
          }
        }
        lessonActivityEvidence {
          coEvidenceType
          evidenceGrade {
            idEvidence
            evidence {
              txName
              ability {
                txName
                competence {
                  txName
                }
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useLessonByIdQuery = <TData = Types.LessonByIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: Types.LessonByIdQueryVariables,
  options?: UseQueryOptions<Types.LessonByIdQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<Types.LessonByIdQuery, TError, TData>(
    ['lessonById', variables],
    fetcher<Types.LessonByIdQuery, Types.LessonByIdQueryVariables>(client, LessonByIdDocument, variables, headers),
    options,
  )
