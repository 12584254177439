import { UilQuestion, UilSignout } from '@iconscout/react-unicons'

import 'dayjs/locale/pt-br'

import { ModalActionComponent } from '~/components'

import FooterModal from './Footer/footer'
import FormEF from './Form/FormEF/FormEF'
import FormEM from './Form/FormEM/FormEM'
import { useScheduModalContext } from './ScheduleContext'
import { IScheduleComponentProps } from './ScheduleModal.interfaces'
import * as S from './ScheduleModal.styles'

export const ScheduleView = ({ open, dataClassInfos, title, type }: IScheduleComponentProps) => {
  const {
    dates,
    handleDateChange,
    disabledDate,
    handleSelectFormChange,
    datesFormatted,
    validateMoment,
    isEM,
    setDates,
    handleCloseModal,
    buttonEnabled,
    message,
    handleConfirmDesengage,
    handleConfirm,
    buttonEnabledDesengage,
    handleEngageTrail,
    confirmModal,
    loadingUpdateTrailProject,
    loadingOccupyTrail,
    loadingData,
    loadingRequestSchedule,
    loadingRequestScheduleProject,
    handleCloseConfirm,
    handleCloseSuccess,
    handleDesengageTrail,
    openModalSucess,
    confirmModalDesengage,
    handleCloseConfirmDesengage,
    loadingDataLeaveProject,
    loadingDataLeave,
    openModalSucessDesengage,
    handleCloseSuccessDesengage,
    messageDesengage,
  } = useScheduModalContext()
  return (
    <>
      <S.ContainerModal open={open} onClose={handleCloseModal}>
        <S.ContentModal>
          <S.ModalBody>
            {['EM-edit', 'EM-schedule'].includes(type) && dates && (
              <FormEM
                dates={dates}
                title={title}
                dataClassInfos={dataClassInfos}
                handleDateChange={handleDateChange}
                disabledDate={disabledDate}
                handleSelectFormChange={handleSelectFormChange}
                datesFormatted={datesFormatted}
                type={type}
                validateMoment={validateMoment}
              />
            )}
            {['edit', 'schedule'].includes(type) && dates && (
              <FormEF
                dates={dates}
                title={title}
                dataClassInfos={dataClassInfos}
                handleDateChange={handleDateChange}
                disabledDate={disabledDate}
                datesFormatted={datesFormatted}
                type={type}
                validateMoment={validateMoment}
                setDates={setDates}
              />
            )}
          </S.ModalBody>
          <S.ModalFooter>
            <FooterModal
              messageDesengage={messageDesengage}
              buttonEnabled={buttonEnabled}
              message={message}
              type={type}
              handleCloseModal={handleCloseModal}
              handleConfirm={handleConfirm}
              handleDesengageTrail={handleConfirmDesengage}
              disabled={isEM ? !buttonEnabledDesengage : false}
            />
          </S.ModalFooter>
        </S.ContentModal>
      </S.ContainerModal>

      <ModalActionComponent
        actionCofirm={() => handleEngageTrail()}
        icon={<UilQuestion size={25} color="#FF8A00" />}
        title="Tem certeza que deseja realizar as alterações?"
        open={confirmModal}
        loading={
          loadingUpdateTrailProject ||
          loadingOccupyTrail ||
          loadingData ||
          loadingRequestSchedule ||
          loadingRequestScheduleProject
        }
        returnBtnText={'Cancelar'}
        description={'Essa ação não poderá ser desfeita.'}
        actionCloseModal={() => handleCloseConfirm()}
        confirmCancelText="Salvar Alterações"
        infoWidth={500}
        isFullModal={true}
        isWarning={true}
      />

      <ModalActionComponent
        title="Alterações realizadas com sucesso"
        open={openModalSucess}
        actionCloseModal={() => handleCloseSuccess()}
        infoWidth={700}
        isFullModal={false}
      />

      <ModalActionComponent
        actionCofirm={() => handleDesengageTrail()}
        icon={<UilQuestion size={25} color="#FF8A00" />}
        title="Tem certeza que deseja realizar as alterações?"
        open={confirmModalDesengage}
        loading={loadingDataLeaveProject || loadingDataLeave}
        returnBtnText={'Cancelar'}
        description={'Essa ação não poderá ser desfeita.'}
        actionCloseModal={() => handleCloseConfirmDesengage()}
        confirmCancelText="Salvar Alterações"
        infoWidth={500}
        isFullModal={true}
        isWarning={true}
      />

      <ModalActionComponent
        title="Trilha Desocupada com Sucesso!"
        icon={<UilSignout size={25} color="#0095FF" />}
        open={openModalSucessDesengage}
        actionCloseModal={() => handleCloseSuccessDesengage()}
        returnBtnText={'x'}
        infoWidth={700}
        isFullModal={false}
      />
    </>
  )
}
