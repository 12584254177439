import { UilClock } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AvatarGroupComponent,
  ChipComponent,
  DropdownSkillComponent,
  SkeletonComponent,
  StepsTabsComponent,
} from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  loading,
  duration,
  activedTab,
  actionSetActiveTab,
  avatars,
  evidence,
  status,
  openModal,
  title,
}: IHeaderComponentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 600
  return (
    <S.HeaderContainer component="header">
      <S.ContentHeader>
        <S.Wrapper>
          <S.TitleLight variant="h1">Passo 1</S.TitleLight>

          <S.TitleBold variant="h1">{title}</S.TitleBold>
        </S.Wrapper>
        {loading ? (
          <SkeletonComponent variant="rounded" width={70} />
        ) : (
          <>{duration && <ChipComponent type="light" icon={<UilClock size={20} />} label={duration + ' minutos'} />}</>
        )}
        <S.Wrapper>
          {loading ? (
            <SkeletonComponent variant="rounded" width={100} />
          ) : (
            <S.TextLabel color="#666" variant="caption">
              Turma inteira
            </S.TextLabel>
          )}

          {loading ? (
            <SkeletonComponent variant="circular" width={20} />
          ) : (
            <S.WrapperStudents onClick={openModal}>
              <AvatarGroupComponent images={avatars} size="small" max={4} />
            </S.WrapperStudents>
          )}
        </S.Wrapper>
      </S.ContentHeader>

      <S.ContentFixedSkill>
        <S.Wrapper>
          <S.TextLabel color="#000" variant="caption">
            Habilidade Fixa
          </S.TextLabel>
          {loading ? (
            <SkeletonComponent variant="rounded" width={150} />
          ) : (
            <DropdownSkillComponent
              txSkillName={evidence?.ability?.txName}
              txSkillDescription={evidence?.txName}
              txCompetency={evidence?.ability?.competence?.txName}
              iconCompetency={evidence?.ability?.competence?.txImagePath}
              iconSkill={evidence?.ability?.txImagePath}
            />
          )}
        </S.Wrapper>
      </S.ContentFixedSkill>

      <S.ContentTabs>
        <StepsTabsComponent
          status={status}
          type={'firstStep'}
          mobile={isMobile}
          activedTab={activedTab}
          actionSetActiveTab={(newActivedTab: number) => actionSetActiveTab(newActivedTab)}
        />
      </S.ContentTabs>
    </S.HeaderContainer>
  )
}
