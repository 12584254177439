import theme from '~/styles/theme'

export const statusColor = (status: string) => {
  switch (status) {
    case 'AGEN':
      return {
        textColor: theme.colors.midDark,
        bgColor: theme.colors.highLight,
        indexColor: theme.colors.midPure,
        titleColor: theme.colors.lowPure,
      }
    case 'AGUA':
      return {
        textColor: theme.colors.primaryPure,
        bgColor: theme.colors.primaryLight,
        indexColor: theme.colors.primaryPure,
        titleColor: theme.colors.primaryDark,
      }
    case 'AUPL':
      return {
        textColor: theme.colors.primaryPure,
        bgColor: theme.colors.primaryLight,
        indexColor: theme.colors.primaryPure,
        titleColor: theme.colors.primaryDark,
      }
    case 'FINA':
      return {
        textColor: theme.colors.positivePure,
        bgColor: theme.colors.positiveLight,
        indexColor: theme.colors.positivePure,
        titleColor: theme.colors.positiveDark,
      }
    case 'AVPE':
      return {
        textColor: theme.colors.warningPure,
        bgColor: theme.colors.warningLight,
        indexColor: theme.colors.warningPure,
        titleColor: theme.colors.warningDark,
      }
    case 'AUPE':
      return {
        textColor: theme.colors.negativePure,
        bgColor: theme.colors.negativeLight,
        indexColor: theme.colors.negativePure,
        titleColor: theme.colors.negativeDark,
      }
    case 'INIC':
      return {
        textColor: theme.colors.primaryPure,
        bgColor: theme.colors.primaryLight,
        indexColor: theme.colors.primaryPure,
        titleColor: theme.colors.primaryDark,
      }
    case 'PEND':
      return {
        textColor: theme.colors.actionPure,
        bgColor: theme.colors.actionLight,
        indexColor: theme.colors.actionPure,
        titleColor: theme.colors.actionDark,
      }
    default:
      return {
        textColor: theme.colors.midDark,
        bgColor: theme.colors.highLight,
        indexColor: theme.colors.midPure,
        titleColor: theme.colors.lowPure,
      }
  }
}
