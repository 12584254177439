import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'
export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '70px 0 0 0',
  marginTop: '-70px',
  backgroundColor: theme.colors.highPure,
  zIndex: '999',
  '@media (max-width: 600px)': {
    position: 'initial',
    padding: '0',
    marginTop: '0',
    width: '100%',
  },
}))

export const Chips = styled.ul`
  display: grid;
`

export const Chip = styled.div`
  background-color: ${({ theme }) => theme.colors.actionPure};
  border-radius: 4px;
  color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ContentSelectSchedule = styled.div``

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px',
  borderBottom: `1px solid ${theme.colors.midMedium}`,
  '@media (max-width: 600px)': {
    marginTop: '0',
    gap: '16px',
    padding: '24px',
    display: 'grid',
    width: '100%',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  fontWeight: theme.typography.headings.headingLarge.fontWeight,
  fontSize: theme.typography.headings.headingLarge.fontSize,
  lineHeight: theme.typography.lineHeights.sm,
  color: theme.colors.lowPure,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
export const TitleMobile = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  color: theme.colors.lowPure,
}))
export const ContentSelectDate = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 16,
}))

export const ContentTitle = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  gap: 16,
}))

export const ContentSelectDay = styledMUI(Box)(() => ({
  display: 'grid',
  alignItems: 'center',
  gap: 8,
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.colors.actionPure}`,
  borderRadius: '50%',
  padding: 0,
}))

export const ButtonIconMobile = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '101px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.colors.actionPure}`,
  color: theme.colors.actionPure,
  gap: '8px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '100%',
  borderRadius: '50px',
  padding: '8px',
  marginTop: '24px',
  marginLeft: '24px',
}))

export const ContentDatePicker = styledMUI(Box)(() => ({
  width: 155,
}))
