import { useEffect, useState } from 'react'

import { UilMultiply } from '@iconscout/react-unicons'
import { Checkbox } from '@mui/material'

import { ButtonComponent, ModalComponent } from '~/components'

import * as S from './ModalUpdateList.styles'

export const ModalUpdateList = ({
  type,
  isOpen,
  handleCloseModal,
  checkMarkValue,
}: {
  type?: string
  isOpen: boolean
  handleCloseModal: any
  checkMarkValue: boolean
}) => {
  const [cantShowMessage, setCantShowMessage] = useState(false)

  useEffect(() => {
    if (checkMarkValue) {
      setCantShowMessage(checkMarkValue.value)
    }
  }, [checkMarkValue, type])

  const handleCantShowMessage = () => {
    setCantShowMessage(!cantShowMessage)
  }

  const handleChanged = () => {
    const change = checkMarkValue.value !== cantShowMessage
    handleCloseModal(change)
  }

  return (
    /* eslint-disable no-inline-styles/no-inline-styles */
    <>
      <ModalComponent open={isOpen} actionCloseModal={handleChanged} maxHeight={'700px'}>
        <S.Container>
          <S.WrapperTitle>
            <S.Title variant="h1">Painel do Aluno</S.Title>

            <S.ButtonClose onClick={handleChanged}>{<UilMultiply size={15} color="#000" />}</S.ButtonClose>
          </S.WrapperTitle>
          <S.Content className="styled-scroll-s">
            <S.Description variant="caption">
              <span style={{ fontWeight: 600 }}>Professor(a),</span>
              <br />
              <br />
              Sabemos que a avaliação envolve sempre um ato de julgamento e interpretação de dados de monitoramento para
              determinar o progresso do(a) aluno(a) em alcançar as expectativas gerais de aprendizagem identificadas
              para sua etapa de aprendizagem e desenvolvimento. Para apoiá-los(as) neste desafio, a Lekto elaborou uma
              Matriz de direitos, competências e habilidades que consideram as 10 competências gerais propostas no
              documento BNCC, com foco nas aprendizagens socioemocionais. Na Matriz, indicamos um conjunto de
              habilidades e, para cada habilidade, por ciclo etário, apresentamos expectativas de aprendizagem. A Matriz
              Lekto, envolvendo todos esses elementos, oportuniza que você, professor(a), possa garantir um alinhamento
              entre o que é ensinado, o que é aprendido, o que é avaliado e o que é relatado.
              <br />
              <br />O processo de monitoramento e avaliação realizada por meio do seu julgamento profissional, oferece
              condições para que, com o apoio da Plataforma Lekto e da sua ferramenta Painel do Aluno, você possa criar
              oportunidades para que todos os(as) alunos(as) possam consolidar ou ampliar suas aprendizagens, a partir
              da identificação das necessidades de apoio cada um(a).
              <br />
              <br />
              São diversas as variáveis que podem influenciar o desempenho dos alunos em diferentes situações, por isso
              é preciso considerar diferentes etapas e ferramentas de avaliação que ajudem a conhecer melhor cada
              aluno(a). A ferramenta Painel do Aluno proposta aqui, é uma delas.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                A partir dos dados que são registrados e das diferentes formas de analisá-los, o Painel do Aluno apóia
                você, professor(a), a:
              </span>
              <br />
              <br />
              <ul>
                <li>
                  <span style={{ fontWeight: 600 }}>Identificar o nível de desenvolvimento </span> de cada aluno em
                  relação às suas competências e habilidades socioemocionais.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>Monitorar, ao longo do tempo, o processo de aprendizagem</span> de
                  cada estudante identificando suas características e as melhores práticas e estratégias para apoiar
                  suas aprendizagens contínuas.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    Compreender quais experiências de aprendizagem criam melhores condições para que cada aluno(a) de
                    sua turma possa progredir
                  </span>{' '}
                  em suas habilidades, conhecimentos, valores e atitudes com foco nas competências e habilidades
                  socioemocionais.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    Realizar análises considerando o desenvolvimento de cada aluno
                  </span>{' '}
                  em relação à turma, as aulas e atividades realizadas.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    {' '}
                    Comparar dados de nível de desenvolvimento das habilidades socioemocionais entre diferentes turmas,
                  </span>{' '}
                  identificando suas trajetórias de aprendizagem e necessidades de apoio para aprender e se desenvolver.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    {' '}
                    Acompanhar e realizar análises em relação às aprendizagens socioemocionais de cada aluno e das
                    diferentes turmas,
                  </span>{' '}
                  favorecendo tomadas de decisões que apoiem a escola na garantia de um ambiente positivo de
                  aprendizagem.
                </li>
              </ul>
              <br />O Painel do Aluno indicará sempre um retrato atual, sabemos que alguns resultados podem ser
              rapidamente modificados, por isso, o monitoramento contínuo oferecido pela Plataforma Lekto e pela
              ferramenta Painel do Aluno irá ajudá-lo(a) a rever e aprimorar o seu processo avaliativo.
              <br />
              Boa avaliação!
            </S.Description>
          </S.Content>
          <S.Footer>
            <ButtonComponent
              size={'medium'}
              text={'Entendi'}
              variant={'solid'}
              type={'button'}
              onClick={handleChanged}
            />

            {!checkMarkValue.value && (
              <S.CheckContainer>
                <Checkbox onClick={handleCantShowMessage} checked={cantShowMessage} value={cantShowMessage} />
                <span>Não mostrar essa mensagem novamente</span>
              </S.CheckContainer>
            )}
          </S.Footer>
        </S.Container>
      </ModalComponent>
    </>
  )
  /* eslint-enable no-inline-styles/no-inline-styles */
}
