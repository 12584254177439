import { UilArrowRight } from '@iconscout/react-unicons'

import { ButtonComponent } from '~/components'

import { images } from './src/DataEmojis'
import { IValidateDataSessionComponentProps, ImageKeys } from './ValidateDataSession.interfaces'
import * as S from './ValidateDataSession.styles'

export const ValidateDataSessionComponent = ({
  emoji,
  title,
  description,
  space,
  height,
  textAction,
  action,
  screen,
}: IValidateDataSessionComponentProps) => {
  const imageUrl = images[screen as keyof ImageKeys]

  return (
    <S.Container height={height ? height : 100}>
      <S.Content space={space}>
        <S.TextEmoji variant="h1">{emoji}</S.TextEmoji>

        {screen && <img src={imageUrl} alt="Emoji" width="30" height="30"></img>}

        <S.Title variant="h2">{title}</S.Title>

        <S.Description variant="caption">{description}</S.Description>

        {textAction && (
          <ButtonComponent
            size="small"
            variant="solid"
            text={textAction}
            data-testid="validate-session-button"
            fontWeight={'normal'}
            iconEnd={<UilArrowRight size={20} color="#fff" />}
            onClick={action}
          />
        )}
      </S.Content>
    </S.Container>
  )
}
