import { Box, Card, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContainerHistory = styledMUI(Box)(() => ({
  padding: '24px',
  backgroundColor: '#FFF',
}))

export const ContentHistory = styledMUI(Box)(() => ({
  borderRadius: '8px',
  padding: '16px',
  width: '100%',
  gap: '16px',
  justifyItems: 'center',
  backgroundColor: '#F9FAFC',
  display: 'flex',
  flexDirection: 'column',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '16px',
  color: '#000',
}))

export const LoaderCardContainer = styledMUI(Card)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  padding: '16px',
  borderRadius: '8px',
  gap: '16px',
  boxShadow: 'none',
  cursor: 'pointer',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const LoaderCardContent = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))
