import { Link } from 'react-router-dom'

import {
  AppBar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const AppBarContainer = styledMUI(AppBar)(() => ({
  height: '72px',
  marginTop: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  padding: '16px 24px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
  backgroundColor: '#fff',
}))

export const Content = styledMUI(Container)(() => ({}))

Content.defaultProps = {
  maxWidth: '100%',
}

export const ToolbarContainer = styledMUI(Toolbar)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const BoxLogo = styledMUI(Box)(() => ({
  position: 'relative',
  top: 6,
}))

ToolbarContainer.defaultProps = {
  disableGutters: true,
}

export const BoxContainer = styledMUI(Box)<{ width?: string }>(({ width }) => ({
  width: width ? width : 'auto',
  alignItems: 'center',
  gap: '16px',
}))

export const WrapperUserInfo = styledMUI(Box)<{ actived: string }>(({ actived }) => ({
  cursor: 'pointer',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  borderRadius: '50px',
  border: actived === 'true' ? '2px solid #8C22BC' : '1px solid #A3A3A3',
  transition: 'border 0.2s ease-in-out',
}))

export const IconContainer = styledMUI(Box)<{ isOpen: boolean }>(({ isOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  color: isOpen ? '#8C22BC' : '#A3A3A3',
  transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
  opacity: isOpen ? 1 : 0.9,
  transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
}))

export const UserName = styledMUI(Typography)<{ actived: string }>(({ actived }) => ({
  fontStyle: 'normal',
  fontWeight: actived === 'true' ? 700 : 400,
  lineHeight: '100%',
  fontSize: '14px',
  color: actived === 'true' ? '#8C22BC' : '#a3a3a3',
}))

export const UserFullName = styledMUI(Typography)(() => ({
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
}))

export const CountNotifications = styledMUI(Typography)(() => ({
  color: '#A3A3A3',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%',
  marginLeft: '-10px',
  marginBottom: '8px',
}))

export const ContentSettings = styledMUI(Box)(() => ({
  width: '400px',
  height: '83vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '8px',
}))

export const WrapperDivider = styledMUI(Box)(() => ({
  padding: '8px 0',
}))

export const ContentLogoMobile = styledMUI(Box)(() => ({
  width: '60%',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ContentNav = styledMUI(Box)(() => ({
  marginTop: '6px',
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  '@media (max-width: 900px)': {
    gap: '14px',
  },
  '@media (max-width: 760px)': {
    gap: '10px',
    marginLeft: '-20px',
  },
  '@media (max-width: 720px)': {
    marginLeft: '-30px',
  },
}))

export const ContentNavSupport = styledMUI(Box)(() => ({
  marginTop: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  width: '100%',
  gap: '24px',
  '@media (max-width: 900px)': {
    gap: '14px',
  },
  '@media (max-width: 760px)': {
    gap: '10px',
    marginLeft: '-20px',
  },
  '@media (max-width: 720px)': {
    marginLeft: '-30px',
  },
}))

export const SupportButton = styledMUI(Button)<{ disable: boolean }>(({ disable }) => ({
  marginTop: '-4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  maxWidth: '180px',
  padding: '8px 16px',
  color: disable ? '#A3A3A3' : '#1976d2',
}))

ContentNav.defaultProps = {
  component: 'nav',
}

export const ContentLink = styledMUI(Link)<{ actived: string }>(({ actived }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  textDecoration: 'none',
  padding: '0 8px 8px 8px',
  width: 'max-content',
  borderBottom: actived === 'true' ? '2px solid #8C22BC' : '',
  '@media (max-width: 700px)': {
    display: 'inline',
    padding: '0px',
  },
}))

export const ContentSupport = styledMUI(Box)<{ actived: string }>(({ actived }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  textDecoration: 'none',
  padding: '0 8px 8px 8px',
  borderBottom: actived === 'true' ? '2px solid #8C22BC' : '',
  '@media (max-width: 700px)': {
    display: 'inline',
    padding: '0px',
  },
}))

export const ContentNotications = styledMUI(Box)<{ actived: string }>(({ actived }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginTop: 6,
  cursor: 'pointer',
  textDecoration: 'none',
  padding: '0 8px 8px 8px',
  borderBottom: actived === 'true' ? '2px solid #8C22BC' : '',
  '@media (max-width: 700px)': {
    display: 'inline',
    padding: '0px',
  },
}))

export const BottomNavLink = styledMUI(Link)<{ actived: string }>(({ actived }) => ({
  borderBottom: actived === 'true' ? '2px solid #8C22BC' : '',
}))

export const BottomNavigationBox = styledMUI(BottomNavigation)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const BottomNavAction = styledMUI(BottomNavigationAction)(() => ({
  '@media (max-width: 400px)': {
    marginLeft: '-14px',
    marginRight: '-14px',
  },
}))

export const TextLink = styledMUI(Typography)<{ actived: string }>(({ actived }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '100%',
  color: actived === 'true' ? '#8C22BC' : '#a3a3a3',
}))

export const TextSupport = styledMUI(Typography)<{ actived: string }>(({ actived }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  marginTop: '2px',
  fontSize: '14px',
  lineHeight: '100%',
  color: actived === 'true' ? '#8C22BC' : '#a3a3a3',
}))

export const TextVersion = styledMUI(Typography)(() => ({
  opacity: '0.5',
  marginLeft: '20px',
}))

export const BadgeNotifications = styledMUI(Badge)(() => ({}))

export const ContentMenu = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-end',
  '@media (max-width: 900px)': {
    marginLeft: '-10px',
  },
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({}))

export const MenuContainer = styledMUI(Menu)(() => ({
  margin: '50px 0 0 0',

  '.MuiPaper-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
  maxHeight: '350px',
}))

MenuContainer.defaultProps = {
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  keepMounted: true,
}

export const ButtonMenuItem = styledMUI(MenuItem)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
}))

export const SupportLink = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

export const InfoContainer = styledMUI(Box)(() => ({
  height: '40px',
  bottom: '0',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  padding: '16px 24px',
  backgroundColor: '#8C22BC',
  gap: '8px',
  zIndex: '9999',
  '@media (max-width: 600px)': {
    position: 'sticky',
    top: '0',
  },
}))

export const TextInfo = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#fff',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const VersionText = styledMUI(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '120%',
  color: '#666666',
}))
