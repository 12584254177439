import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const HeaderContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '50px',
  padding: '24px',
  '@media (max-width: 768px)': {
    marginTop: '70px',
  },
}))

export const ContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderBottom: '1px solid #E2E5EC',
  padding: '24px 0',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const TitleLight = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#7C8189',
}))

export const TitleBold = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#E14983',
}))

export const TextLabel = styledMUI(Typography)<{ color: string }>(({ color }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: color,
}))

export const ContentAvatar = styledMUI(Box)(() => ({
  display: 'flex',
  cursor: 'pointer',
}))

export const ContentFixedSkill = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  paddingLeft: '0',
  borderBottom: '1px solid #E2E5EC',
}))

export const ContentTabs = styledMUI(Box)(() => ({
  borderBottom: '1px solid #E2E5EC',
  padding: '24px 0',
}))

export const ContentChallenge = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
  borderBottom: '1px solid #E2E5EC',
}))

export const StepBack = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  background-color: #f9fafc;
  padding: 8px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  border-radius: 8px;
  cursor: pointer;
`

export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    display: grid;
    gap: 18px;
  }
`

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`

export const Context = styled.span`
  color: #e14983;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
`

export const ChallengeLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: black;
`
