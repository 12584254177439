import styled from 'styled-components'

export const BtnAction = styled.button`
  margin-left: 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.actionPure};
  transition: all 0.5s;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.actionDark};
  }
`

export const Description = styled.span`
  font-family: 'Inter';
  font-size: '16px';
  font-weight: 400;
  line-height: '150%';
  text-align: left;
`

export const CardDescription = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.midLight};
`
