import { useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import {
  IGetActivityInformation,
  IGetLessonInformationGroup,
  ILessonStepInformation,
} from '~/services/LessonSteps/types'

export const getLessonStepInformation = async (idLessonMoment: number, idLesson: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment/${idLessonMoment}/lesson/${idLesson}?nuOrder=${nuOrder}`)

  return data
}

export const useLessonStepInformation = (idLessonMoment: number, idLesson: number, nuOrder: number) =>
  useQuery<ILessonStepInformation>(['getLessonStepInformation'], () =>
    getLessonStepInformation(idLessonMoment, idLesson, nuOrder),
  )

export const getLessonStepInformationGroup = async (idLessonMoment: number, idLesson: number) => {
  const { data } = await HTTPClient.get(
    `/lesson-moment-group/lesson-moment/${idLessonMoment}/groups/lesson/${idLesson}`,
  )

  return data
}

export const useLessonStepInformationGroup = (idLessonMoment: number, idLesson: number) =>
  useQuery<IGetLessonInformationGroup>(['getLessonStepInformationGroup'], () =>
    getLessonStepInformationGroup(idLessonMoment, idLesson),
  )

export const getLessonStepActivityInformation = async (idLessonMoment: number, activity: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment/${idLessonMoment}/activity/${activity}`)

  return data
}

export const useLessonStepActivityInformation = (idLessonMoment: number, activity: number) =>
  useQuery<IGetActivityInformation>(['getLessonStepActivityInformation'], () =>
    getLessonStepActivityInformation(idLessonMoment, activity),
  )
