import { IDataProps } from './interfaces'

export const defaultDataState: IDataProps = {
  scheduledMomentByProfessor: {
    items: [
      {
        idMoment: 0,
        coMomentStatus: '',
        txMomentStatus: '',
        dtSchedule: '',
        txTitle: '',
        class: {
          txName: '',
          schoolGrade: {
            grade: {
              txGrade: '',
              coStage: '',
            },
          },
        },
        student: {
          txImagePath: '',
          txName: '',
        },
      },
    ],
  },
}

export const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
