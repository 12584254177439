import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent } from '~/components'

import { ICardResponsableStudentComponentProps } from './CardResponsableStudent.interfaces'
import * as S from './CardResponsableStudent.styles'

export const CardResponsableStudentComponent = ({ student, onClickCard }: ICardResponsableStudentComponentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 600

  const lengthStudentConcat = isMobile ? 29 : 17

  const txNameStudentConcat =
    student?.txName?.length > lengthStudentConcat
      ? student?.txName?.slice(0, lengthStudentConcat).concat('...')
      : student?.txName

  return (
    <S.CardStudent onClick={onClickCard}>
      <S.CardWrapper>
        <AvatarComponent label={student?.txName} photoUrl={student?.txImagePath} size="mediumLarge" />

        <S.TextWrapper>
          <S.StudentName>{txNameStudentConcat}</S.StudentName>

          {student.student[0] && (
            <S.ClassName>
              {student.student[0].studentClass[0].class.schoolGrade.grade.txGrade} -{' '}
              {student.student[0].studentClass[0].class.txName}
            </S.ClassName>
          )}
        </S.TextWrapper>
      </S.CardWrapper>
    </S.CardStudent>
  )
}
