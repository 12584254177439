import {
  UilCalendarSlash,
  UilCalender,
  UilCheck,
  UilFileCheck,
  UilFileEditAlt,
  UilFileUploadAlt,
  UilTimesCircle,
} from '@iconscout/react-unicons'

import theme from '~/styles/theme'

export const statusIcon = (status: string, size?: number, color?: string) => {
  const sizeState = size || 20
  switch (status) {
    case 'AGEN':
      return <UilCalender size={sizeState} color={color || theme.colors.midDark} />
    case 'AGUA':
      return <UilFileCheck size={sizeState} color={color || theme.colors.primaryPure} />
    case 'AUPL':
      return <UilFileCheck size={sizeState} color={color || theme.colors.primaryPure} />
    case 'FINA':
      return <UilCheck size={sizeState} color={color || theme.colors.positivePure} />
    case 'AVPE':
      return <UilFileUploadAlt size={sizeState} color={color || theme.colors.warningPure} />
    case 'INIC':
      return <UilFileCheck size={sizeState} color={color || theme.colors.primaryPure} />
    case 'PEND':
      return <UilFileEditAlt size={sizeState} color={color || theme.colors.actionPure} />
    case 'AUPE':
      return <UilCalendarSlash size={sizeState} color={color || theme.colors.negativePure} />
    case 'CANC':
      return <UilTimesCircle size={sizeState} color={color || theme.colors.lowLight} />
    default:
      return <UilCalender size={sizeState} color={color || theme.colors.midDark} />
  }
}
