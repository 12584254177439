import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  minHeight: '250px',
  maxHeight: '720px',
  maxWidth: '768px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))

export const WrapperIcon = styledMUI(Box)(() => ({
  padding: '8px',
  borderRadius: '50%',
  lineHeight: '0',
  backgroundColor: `${theme.colors.actionLight}`,
}))

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 200%;
    left: -50%;
    bottom: 0;
    border-bottom: 1px solid ${theme.colors.midMedium};
  }
`

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const ModalContentContainer = styledMUI(Box)(() => ({
  display: 'flex',
  backgroundColor: '#fff',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
}))

export const ContentSection = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const ContentTitle = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: 1.2,
}))

export const SectionTitle = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: 1.2,
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const Content = styledMUI(Box)(() => ({
  background: '#F9FAFC',
  overflowY: 'auto',
  maxHeight: '100vh',
  borderRadius: '8px',

  '&::-webkit-scrollbar': {
    width: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#CCCCCC',
    border: '1px solid #CCCCCC',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#aaa',
    width: '4px',
  },
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: '1px solid #0095ff',
  backgroundColor: '#fff',
  padding: 0,
}))
