import { IMutableContext, UnleashClient } from 'unleash-proxy-client'

import packageJson from '../../../package.json'

export const unleashConfig = {
  url: 'https://unleash.lekto.com.br/api/frontend',
  appName: import.meta.env.VITE_UNLEASH_USERNAME,
  clientKey: import.meta.env.VITE_UNLEASH_CLIENTKEY,
  refreshInterval: 30,
  environment: import.meta.env.VITE_UNLEASH_ENVIRONMENT,
  context: {
    properties: {
      version: packageJson.version,
    },
  },
}

export const unleash = new UnleashClient(unleashConfig)

export const isSentryEnabled = (): boolean => {
  return unleash.isEnabled('PlatformSentryEnabled')
}

export const updateUnleashContext = async (context: IMutableContext) => {
  await unleash.updateContext(context)
}
