import { Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 768px) {
    height: 24px;
  }

  .planning {
    border-radius: 50px 0px 0px 50px;
  }

  .assessment {
    border-radius: 0px 50px 50px 0px;
  }
`

export const StepItem = styled.div<{ variant: 'checked' | 'onStep' | 'disable' }>`
  text-align: center;
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  ${({ variant }) =>
    variant === 'checked' &&
    css`
      color: ${({ theme }) => theme.colors.actionPure};
      background-color: ${({ theme }) => theme.colors.actionLight};
    `}

  ${({ variant }) =>
    variant === 'onStep' &&
    css`
      color: ${({ theme }) => theme.colors.highPure};
      background-color: ${({ theme }) => theme.colors.actionPure};
      font-weight: 700;
    `}
  ${({ variant }) =>
    variant === 'disable' &&
    css`
      color: ${({ theme }) => theme.colors.lowLight};
      background-color: ${({ theme }) => theme.colors.highMedium};
    `}
`

export const ArrowOne = styled.div`
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.highPure};
  align-items: center;
  display: flex;
  left: 32%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

export const ArrowTwo = styled.div`
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.highPure};
  align-items: center;
  display: flex;
  left: 65.5%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`
export const StepLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '120%',
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const Chip = styled.div<{ variant: 'checked' | 'onStep' | 'disable' }>`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  width: max-content;
  color: ${({ theme }) => theme.colors.actionPure};
  background-color: ${({ theme }) => theme.colors.actionLight};

  ${({ variant }) =>
    variant === 'checked' &&
    css`
      color: ${({ theme }) => theme.colors.actionPure};
      background-color: ${({ theme }) => theme.colors.actionLight};
    `}

  ${({ variant }) =>
    variant === 'onStep' &&
    css`
      color: ${({ theme }) => theme.colors.highPure};
      background-color: ${({ theme }) => theme.colors.actionPure};
    `}
  ${({ variant }) =>
    variant === 'disable' &&
    css`
      color: ${({ theme }) => theme.colors.lowLight};
      background-color: ${({ theme }) => theme.colors.highMedium};
    `}
`
