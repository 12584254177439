import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useNavigation } from '~/_context/Navigation'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AppBarComponent, MomentCardComponent, SkeletonComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Schedule'

import { titleByNuOrder } from '~/pages/private/Schedule/view'
import { useScheduleByWeekPageContext } from '~/pages/private/ScheduleByWeek/context'

import * as S from './styles'

export const ScheduleByWeekView = () => {
  const { handleNavigation } = useNavigation()
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 600
  const navigate = useNavigate()

  useEffect(() => {
    if (isMobile) {
      navigate('/schedule/mobile')
    }
  }, [isMobile, navigate])

  const {
    formattedMonthWithUppercase,
    weekArray,
    daysOfWeekEN,
    itemsByDay,
    changeCurrentDay,
    isLoading,
    isRefetching,
    selectedDay,
    changeSelectedDay,
  } = useScheduleByWeekPageContext()

  const tableRows = (
    <tr>
      {daysOfWeekEN.map((day, index) => (
        <td key={index}>
          <ul>
            {itemsByDay[day]?.map((scheduleMoment) => (
              <MomentCardComponent
                key={scheduleMoment?.idMoment}
                type={'week'}
                momentStatusCode={scheduleMoment?.coMomentStatus}
                totalClass={Number(scheduleMoment?.nuTrackStages)}
                momentStatusText={scheduleMoment?.txMomentStatus}
                momentTitle={
                  scheduleMoment?.class?.schoolGrade?.grade?.coStage === 'F2'
                    ? (titleByNuOrder(scheduleMoment?.nuOrder) as string)
                    : (scheduleMoment?.txTitle as string)
                }
                momentClassInfo={`${scheduleMoment?.class?.schoolGrade?.grade?.txGrade} - ${scheduleMoment?.class?.txName}`}
                action={() =>
                  handleNavigation(
                    scheduleMoment?.class?.schoolGrade?.grade?.coStage,
                    scheduleMoment?.idMoment,
                    scheduleMoment?.class?.idClass,
                    '/schedule/week',
                  )
                }
              />
            ))}
          </ul>
        </td>
      ))}
    </tr>
  )

  const SkeletonRows = (
    <tr>
      {daysOfWeekEN.map((index) => (
        <td key={index}>
          <ul>
            <SkeletonComponent width={'100%'} height={150} variant={'rounded'} />
          </ul>
        </td>
      ))}
    </tr>
  )

  return (
    <>
      <AppBarComponent />
      <S.Container>
        <HeaderComponent
          actionSetCurrentDay={changeCurrentDay}
          selectedDay={selectedDay}
          initialOptions={'week'}
          actionSetSelectedDay={changeSelectedDay}
        />
        <S.Content>
          <S.ScheduleTitle>{formattedMonthWithUppercase}</S.ScheduleTitle>
          <S.ScheduleTable>
            <thead>
              {weekArray.map((item) => (
                <th key={item.formattedDay} className={item.className}>
                  {item.formattedDay}
                </th>
              ))}
            </thead>
            <tbody>{isLoading || isRefetching ? SkeletonRows : tableRows}</tbody>
          </S.ScheduleTable>
        </S.Content>
      </S.Container>
    </>
  )
}
