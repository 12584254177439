import styled from 'styled-components'

interface ITrackTimeProps {
  status: string
}

export const TrackTimes = styled.div`
  background-color: ${({ theme }) => theme.colors.midLight};
  display: flex;
  border-radius: 8px;
  gap: 8px;
  padding: 8px;
  flex-direction: column;
  margin-right: 8px;
  height: 180px;
`

export const TrackTimeTitle = styled.span`
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.lowPure};
  gap: 6px;
`

export const TrackTime = styled.span<ITrackTimeProps>`
  text-align: center;
  background-color: ${({ theme, status }) =>
    status == 'FINA'
      ? theme.colors.actionLight
      : status == 'PEND'
      ? theme.colors.negativeLight
      : status == 'AVPE'
      ? theme.colors.warningLight
      : status == 'NOTP'
      ? theme.colors.midMedium
      : theme.colors.positiveLight};
  color: ${({ theme, status }) =>
    status == 'FINA'
      ? theme.colors.actionPure
      : status == 'PEND'
      ? theme.colors.negativePure
      : status == 'AVPE'
      ? theme.colors.warningPure
      : status == 'NOTP'
      ? theme.colors.lowMedium
      : theme.colors.positivePure};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  border-radius: 4px;
`

export const EditTracks = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.actionPure};
  align-self: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
`
