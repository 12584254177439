export const mapClassShift = (classShift: string): string => {
  switch (classShift) {
    case 'MAT':
      return 'Matutino'
    case 'VES':
      return 'Vespertino'
    case 'NOT':
      return 'Noturno'
    case 'INT':
      return 'Integral'
    default:
      return classShift
  }
}
