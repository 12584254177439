import dayjs from 'dayjs'

export const getFormattedLabelWithDay = (dtSchedule: string): string => {
  const scheduleDate = dayjs(dtSchedule)

  if (!scheduleDate.isValid()) {
    return ''
  }

  const currentDate = dayjs()

  if (scheduleDate.isSame(currentDate, 'day')) {
    return 'Hoje'
  }

  if (scheduleDate.isSame(currentDate.add(1, 'day'), 'day')) {
    return 'Amanhã'
  }

  const [datePart, dayPart] = scheduleDate.format('DD/MM - dddd').replace('-feira', '').split(' - ')

  const capitalizedDayPart = dayPart?.charAt(0)?.toUpperCase() + dayPart?.slice(1)

  const formattedDate = `${datePart} - ${capitalizedDayPart}`

  return formattedDate
}
