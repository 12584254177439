import { Snackbar, Alert } from '@mui/material'

import { useSnackbar } from '~/_context/Snackbar'

import * as S from './Snackbar.styles'

export const SnackbarComponent = () => {
  const { snackbar, closeSnackbar } = useSnackbar()
  const { show, type, message, color } = snackbar

  return (
    <S.Container color={color}>
      <Snackbar
        open={show}
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeSnackbar}
      >
        <Alert
          severity={type}
          variant="outlined"
          onClose={closeSnackbar}
          sx={{
            backgroundColor: color,
            color: '#fff',
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </S.Container>
  )
}
