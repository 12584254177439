import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import packageJson from 'package.json'

const API_URL = (service: 'network' | 'track' | 'moment' | 'user') =>
  `${import.meta.env.VITE_BASE_URL_GRAPHQL}${service}/graphql`

export const useQueryGraphQLClient = <T>(
  service: 'network' | 'track' | 'moment' | 'user',
  key: string,
  query: string,
  variables?: any,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
): UseQueryResult<T> => {
  const tokenStorage = localStorage.getItem('@LEKTO:token')

  const graphQLClient = new GraphQLClient(API_URL(service), {
    headers: {
      Authorization: `Bearer ${tokenStorage}`,
      'x-version': packageJson.version,
    },
  })

  return useQuery<T>(
    [key],
    async () => {
      const response = await graphQLClient.request<T>(query, variables)
      return response
    },
    options,
  )
}

export const graphqlRequestClient = (service: 'network' | 'track' | 'moment') => {
  const tokenStorage = localStorage.getItem('@LEKTO:token')

  const graphQLClient = new GraphQLClient(API_URL(service), {
    headers: {
      Authorization: `Bearer ${tokenStorage}`,
      'x-version': packageJson.version,
    },
  })

  return graphQLClient
}
