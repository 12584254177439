import { useEffect, useState } from 'react'

import { QuickNavigationComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({ statusBlock, status }: IHeaderComponentProps) => {
  const offlineMode = localStorage.getItem('@LEKTO:offline') || 'false'
  const [isOfflineMode, setIsOfflineMode] = useState<boolean>(false)

  useEffect(() => {
    setIsOfflineMode(JSON.parse(offlineMode) === true)
  }, [offlineMode])
  return (
    <>
      {!isOfflineMode && (
        <S.HeaderContainer component="header">
          <QuickNavigationComponent stepActived="BNCCRelations" statusBlock={statusBlock} status={status} />

          <S.Title variant="h1">Integração com o currículo</S.Title>

          <S.TextLabel variant="caption">Consulte aqui a relação das Referências da BNCC para essa aula.</S.TextLabel>
        </S.HeaderContainer>
      )}

      {isOfflineMode && (
        <S.HeaderContainer component="header">
          <QuickNavigationComponent stepActived="BNCCRelations" statusBlock={statusBlock} isOffline />

          <S.Title variant="h1">Integração com o currículo</S.Title>

          <S.TextLabel variant="caption">Consulte aqui a relação das Referências da BNCC para essa aula.</S.TextLabel>
        </S.HeaderContainer>
      )}
    </>
  )
}
