import { LoginPageProvider } from './context'
import { LoginView } from './view'

export const LoginPage = () => {
  return (
    <LoginPageProvider>
      <LoginView />
    </LoginPageProvider>
  )
}
