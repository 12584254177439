import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'

import { useGQLServiceGetGradeList } from '~/graphql/Classes/queries/getClassInfosByClassID'
import { Grade } from '~/graphql/Classes/queries/interface'

import { useGetClasses } from '~/services/Class'

import { GroupedClasses, IListClassesPageContextProps, IListClassesPageProviderProps } from './interfaces'

type Action = { type: 'SET_PARAMS'; payload: Partial<typeof initialState> }

export const initialState = {
  coGradeSelected: [] as string[],
  txName: '',
  showDisableClasses: false,
  showPreviousYears: false,
}

type GroupedGrades = {
  [key: string]: Grade[]
}

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'SET_PARAMS':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const ListClassesPageContext = createContext<IListClassesPageContextProps>({
  isLoading: false,
  data: undefined,
  params: initialState,
  handleGetClassesProps: () => null,
  groupedClasses: undefined,
  hasInactive: false,
  groupedGradeListFilter: undefined,
  handleChangeClassesSelected: () => null,
})

const ListClassesPageProvider = ({ children }: IListClassesPageProviderProps) => {
  const [params, dispatch] = useReducer(reducer, initialState)

  const handleGetClassesProps = useCallback((newParams: Partial<typeof initialState>) => {
    dispatch({ type: 'SET_PARAMS', payload: newParams })
  }, [])

  const handleChangeClassesSelected = useCallback(
    (value: string) => {
      if (value === 'all') {
        handleGetClassesProps({ coGradeSelected: [] })
      } else {
        const { coGradeSelected } = params
        const newCoGradeSelected = coGradeSelected.includes(value)
          ? coGradeSelected.filter((item) => item !== value)
          : [...coGradeSelected, value]

        handleGetClassesProps({ coGradeSelected: newCoGradeSelected })
      }
    },
    [params, handleGetClassesProps],
  )
  const { data: gradeList } = useGQLServiceGetGradeList()

  const groupedByCoStage = gradeList?.grades?.items?.reduce((acc: GroupedGrades, item) => {
    if (!acc[item.coStage]) {
      acc[item.coStage] = []
    }
    acc[item.coStage].push(item)
    return acc
  }, {})

  const groupedGradeListFilter =
    groupedByCoStage &&
    Object.keys(groupedByCoStage).map((coStage) => ({
      coStage,
      items: groupedByCoStage[coStage],
    }))

  const {
    data,
    isLoading: isLoadingState,
    refetch,
    isRefetching,
  } = useGetClasses(params.coGradeSelected, params.txName, params.showDisableClasses, params.showPreviousYears)

  const isLoading = isLoadingState || isRefetching

  const groupedClasses = data?.reduce<GroupedClasses>((acc, current) => {
    const grade = current.txGrade
    if (!acc[grade]) {
      acc[grade] = []
    }
    acc[grade].push(current)
    return acc
  }, {})

  const hasInactive = data?.some((value) => value.inStatus === false)

  useEffect(() => {
    refetch().then()
  }, [params, refetch])

  const listClassesPageProviderValues = useMemo(() => {
    return {
      data,
      isLoading,
      params,
      handleGetClassesProps,
      groupedClasses,
      groupedGradeListFilter,
      handleChangeClassesSelected,
      hasInactive,
    }
  }, [
    data,
    isLoading,
    params,
    handleGetClassesProps,
    groupedClasses,
    groupedGradeListFilter,
    handleChangeClassesSelected,
    hasInactive,
  ])

  return (
    <ListClassesPageContext.Provider value={listClassesPageProviderValues}>{children}</ListClassesPageContext.Provider>
  )
}

const useListClassesPageContext = () => useContext(ListClassesPageContext)

export { ListClassesPageProvider, useListClassesPageContext }
