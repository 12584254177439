import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styledMUI(Box)(() => ({
  minHeight: '250px',
  minWidth: '350px',
  maxWidth: '700px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px',
  gap: '24px',
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
  textAlign: 'left',
  ul: {
    paddingLeft: '24px',
    display: 'grid',
    gap: '8px',
    textAlign: 'left',
  },
}))

export const Content = styledMUI(Box)(() => ({
  background: '#F9FAFC',
  overflowY: 'auto',
  maxHeight: '100vh',
  borderRadius: '8px',
  padding: '16px',
  textAlign: 'left',
}))

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  width: 100%;
`

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: '1px solid #0095ff',
  backgroundColor: '#fff',
  padding: 0,
}))
