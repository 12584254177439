import ReactDOM from 'react-dom/client'

import * as Sentry from '@sentry/react'
import FlagProvider from '@unleash/proxy-client-react'

import { unleash, unleashConfig } from '~/services/Unleash'

import App from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Sentry.ErrorBoundary>
    <FlagProvider config={unleashConfig} unleashClient={unleash}>
      <App />
    </FlagProvider>
  </Sentry.ErrorBoundary>,
)
