import { ListClassesPageProvider } from './context'
import { ListClassesView } from './view'

export const ListClassesPage = () => {
  return (
    <ListClassesPageProvider>
      <ListClassesView />
    </ListClassesPageProvider>
  )
}
