import { pdfjs } from 'react-pdf'

import { Document, Page, Text, View, Link, Image } from '@react-pdf/renderer'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
import LogoLekto from './Fonts/logo.png'
import { IPDFDocumentProps, LessonActivity, LessonStep } from './PDFDocument.interfaces'
import * as S from './PDFDocument.styles'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const PDFDocument = ({
  guidance,
  steps,
  txTitleLesson,
  fixedEvidences,
  evidencesByActivityOnStepTwo,
}: IPDFDocumentProps) => {
  const Logo = () => <Image style={S.styles.logo} src={LogoLekto} />
  const getColorForIndex = (index: number) => {
    const colors = ['#8c22bc', '#e14983', '#e66251']
    const colorIndex = index % colors.length
    return colors[colorIndex]
  }
  const removeTagsAndFormatBR = (htmlString: string): string => {
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const text = div.textContent || div.innerText || ''

    const formattedText = text.replace(/<br\s*\/?>/gi, '\n')
    return formattedText
  }
  return (
    <Document>
      <Page style={S.styles.page}>
        <>
          <Logo />
          {/* Título */}
          <Text style={S.styles.title}>Planejamento de Aula</Text>
          {/* Subtítulo */}
          <Text style={S.styles.subtitle}>{txTitleLesson}</Text>
          <View style={S.styles.divider} />
          <View style={[S.styles.row]}>
            <Text style={[S.styles.sectionTitleThin, { color: 'black' }]}>Habilidades Variáveis</Text>
            <Text style={[S.styles.sectionTitleBold]}>Passo 2</Text>
          </View>
          {evidencesByActivityOnStepTwo &&
            evidencesByActivityOnStepTwo?.length > 0 &&
            evidencesByActivityOnStepTwo?.map((evidences: LessonActivity, index: number) => (
              <>
                <View key={index} style={S.styles.listItemMap}>
                  <Text style={[S.styles.sectionTitleThin]}>{evidences?.categoryGrade?.category?.txName} -</Text>
                  <Text style={[S.styles.sectionTitleBold]}> {evidences?.txTitle}</Text>
                </View>
                <View style={S.styles.box}>
                  <View style={S.styles.listItemBox}>
                    <Text style={S.styles.titleBox}>
                      {evidences?.lessonActivityEvidence[0]?.evidenceGrade?.evidence?.ability?.txName}
                    </Text>
                  </View>

                  <Text style={S.styles.paragraphBox}>
                    {' '}
                    {evidences?.lessonActivityEvidence[0]?.evidenceGrade?.evidence?.txName}
                  </Text>
                  <View style={S.styles.divider} />
                  <Text style={S.styles.titleBoxCompetence}>Competência</Text>
                  <View style={S.styles.listItemBox}>
                    <Text style={S.styles.titleBox}>
                      {evidences?.lessonActivityEvidence[0]?.evidenceGrade?.evidence?.ability?.competence?.txName}
                    </Text>
                  </View>
                </View>
              </>
            ))}
          <View style={[S.styles.row]}>
            <Text style={[S.styles.sectionTitle, { color: 'black' }]}>Habilidades Fixas</Text>
          </View>
          {fixedEvidences &&
            fixedEvidences?.length &&
            fixedEvidences?.map((fixed: LessonActivity, index: number) => (
              <>
                <View style={[S.styles.row]}>
                  <Text style={[S.styles.sectionTitle, { color: getColorForIndex(index) }]}>Passo {index + 1}</Text>
                </View>
                <View style={S.styles.box}>
                  <View style={S.styles.listItemBox}>
                    <Text style={S.styles.titleBox}>{fixed.lessonActivityEvidence.evidence?.ability?.txName}</Text>
                  </View>

                  <Text style={S.styles.paragraphBox}> {fixed.lessonActivityEvidence.evidence?.txName}</Text>
                  <View style={S.styles.divider} />
                  <Text style={S.styles.titleBoxCompetence}>Competência</Text>
                  <View style={S.styles.listItemBox}>
                    <Text style={S.styles.titleBox}>
                      {fixed?.lessonActivityEvidence?.evidence?.ability?.competence?.txName}
                    </Text>
                  </View>
                </View>
              </>
            ))}
          <View style={S.styles.divider} />
          {/* Tópico "Orientações Gerais" */}
          <Text style={S.styles.sectionTitle}>Orientações Gerais </Text>
          <Text style={S.styles.paragraph}>{removeTagsAndFormatBR(guidance)}</Text>
          <View style={S.styles.divider} />
          {steps &&
            steps?.length &&
            steps?.map((step: LessonStep, stepIndex: number) => {
              return (
                <>
                  <View style={[S.styles.row, { color: getColorForIndex(stepIndex) }]}>
                    <Text style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                      Passo {stepIndex + 1}
                    </Text>
                    <View style={S.styles.divider} />
                  </View>

                  {step?.lessonActivity?.map((item: LessonActivity, index: number) => (
                    <>
                      {item?.categoryGrade?.category?.txName && (
                        <>
                          <View style={[S.styles.row, { color: getColorForIndex(stepIndex) }]}>
                            <Text key={index} style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                              {item?.categoryGrade?.category?.txName} - {item?.txTitle}
                            </Text>
                          </View>
                          <View style={S.styles.divider} />
                        </>
                      )}
                      {item?.lessonActivityOrientation?.map((orientation, index: number) => (
                        <>
                          <Text key={index} style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                            {orientation.txTitle}
                          </Text>
                          <Text key={index} style={S.styles.paragraph}>
                            {removeTagsAndFormatBR(orientation?.txOrientationCode)}
                          </Text>

                          {orientation?.lessonActivitySupportReference.length ? (
                            <>
                              <Text style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                                Referências de Apoio
                              </Text>
                              {orientation?.lessonActivitySupportReference?.map((reference, index: number) => (
                                <>
                                  <View key={index} style={S.styles.listItem}>
                                    <Text style={S.styles.bullet}>-</Text>
                                    <Link src={reference?.mediaInformation?.txAbsoluteUrl} target="_blank">
                                      <Text style={S.styles.linkText}>{reference?.mediaInformation?.txName}</Text>
                                    </Link>
                                  </View>
                                </>
                              ))}
                            </>
                          ) : (
                            <View style={S.styles.divider} />
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </>
              )
            })}
        </>
      </Page>
    </Document>
  )
}
