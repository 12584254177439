import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 5,
  },
  logo: {
    width: 70,
    height: 30,
    margin: 'auto',
  },
  title: {
    fontSize: 20,
    color: '#0095ff',
    textAlign: 'center',
    marginTop: 5,
    fontWeight: 900,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 900,
    textAlign: 'center',
  },
  cell: {
    padding: 5,
    borderBottom: '1px solid black',
  },
  sectionTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 10,
    color: '#0095ff',
  },
  sectionTitleThin: {
    fontSize: 12,
    fontWeight: 'light',
    marginTop: 10,
    color: '#e14983',
  },
  sectionTitleBold: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    color: '#e14983',
    flexShrink: 1,
    flexWrap: 'wrap',
    width: '100%',
  },
  Icon: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 10,
    color: '#0095ff',
  },
  IconBold: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    color: '#e14983',
  },
  IconBox: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#0095ff',
  },
  paragraph: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'justify',
  },
  divider: {
    borderBottom: '1px solid lightgray',
    marginTop: 10,
    marginBottom: 10,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 15,
  },
  listItemMap: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  listItemBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bullet: {
    fontSize: 10,
    marginRight: 5,
  },
  linkText: {
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    gap: 10,
  },
  box: {
    backgroundColor: '#e8f6ff',
    borderRadius: 5,
    padding: 15,
  },
  titleBox: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#0095ff',
  },
  titleBoxCompetence: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
  },
  paragraphBox: {
    fontSize: 12,
    textAlign: 'justify',
  },
})
