import { useMutation, useQuery } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

export const getMessageByKey = async (key: string) => {
  const { data } = await HTTPClient.get(`/message/by-key/${key}`)

  return data
}

export const useMessageByKey = (key: string) => useQuery(['MessageByKey'], () => getMessageByKey(key))

export const getUserOptInMessage = async (key: string) => {
  const { data } = await HTTPClient.get(`/message/opt-in/by-key/${key}`)

  return data
}

export const useUserOptInMessage = (key: string) => useQuery(['UserOptInMessage'], () => getUserOptInMessage(key))

export const patchMarkUserMessage = async (key: string) => {
  const { data } = await HTTPClient.patch(`/message/opt-in/by-key/${key}`)

  return data
}

export const useMarkUserMessage = () => useMutation(['MarkUserMessage'], patchMarkUserMessage)
