import { ISkeletonComponentProps } from './Skeleton.interfaces'
import * as S from './Skeleton.styles'

export const SkeletonComponent = ({ children, variant = 'text', height, width }: ISkeletonComponentProps) => {
  return (
    <S.SkeletonContainer animation="pulse" variant={variant} height={height} width={width}>
      {children}
    </S.SkeletonContainer>
  )
}
